// Logique qui fait la gestion des bannières personnalisées. Un appel ajax est fait pour recevoir 
// les informations nécessaires à la création de la bannière personnalisée dépendamment du type de composant ou de la section.

let BanniereCustom = function() {

	const self = this;

	var components = null;

	const setBanniereCustom =  function() {	
		self.components = $('[data-type-banniere="custom"]');
		if( $('.zoneGauche .imageTracked[data-type-composant="bigBox"]').length ) incrementGaCategoryOnBanners();
		getDataAttributes(self.components);
	}

	const getDataAttributes = function(selection) {
		if (selection.length !== 0) {
			selection.each(function(index, element) {
				if (!$(element).hasClass('is_initialized')) {
					var typeJeu = $(element).attr('data-type-jeu');
					var typeComposant = $(element).attr('data-type-composant');
					if (typeJeu !== null && typeComposant !== null) {
						getInfoJeu(typeJeu, typeComposant, index);
					}
				}
			});
		}
	}

	const getInfoJeu = function(typeJeu, typeComposant, index) {
		var arr = [];
		
		if (typeJeu === 'favori') {
			arr = JSON.parse(window.localStorage.getItem('EJFavoris'));
		} else if (typeJeu === 'recent') {
			arr = JSON.parse(window.localStorage.getItem('EJRecents'));
		}
		
		if (arr !== null && arr.length !== 0) { 
			var idJeu = arr[arr.length - 1];
			
			var params = 'idJeu=' + idJeu + '&typeComposant=' + typeComposant;
			
			$.ajax({
				url: '/json-cache/iframes/zoneFavRecent?' + params,
				dataType: 'json',
				success: function(response) {
					redirectData(response, typeComposant, index);
				}
			});
		}
	}

	const redirectData = function(response, typeComposant, index) {
		if (typeComposant === 'bigBox') {
			if (response.infoJeu.imageAssetLink !== "") {
				$(self.components[index]).find('img[class^=" "]').attr('src', response.infoJeu.imageAssetLink);
				setTypeLancement($(self.components[index]).find('[data-link="bigBox"]'), response.infoJeu);
			}
		} else if (typeComposant === 'carrouselAccueil') {
			toggleLazyLoadClass(self.components[index], response.infoJeu.imageAssetLink);
			$(self.components[index]).find('[data-link="carrouselAccueil"]').find('a').attr('href', response.infoJeu.lien);
		} else if (typeComposant === 'carrouselSection') {
			if (response.infoJeu.imageAssetLink !== "") {
				$(self.components[index]).find('img[class^=" "]').attr('src', response.infoJeu.imageAssetLink);
				setTypeLancement($(self.components[index]).find('[data-link="carrouselSection"]'), response.infoJeu);
			}
		} else if (typeComposant === 'pev') {
			toggleLazyLoadClass(self.components[index], response.infoJeu.imageAssetLink);
			$(self.components[index]).closest('[data-link="pev"]').attr('href', response.infoJeu.lien);
		} else if (typeComposant === 'aDecouvrir') {
			toggleLazyLoadClass(self.components[index], response.infoJeu.imageAssetLink);
			$(self.components[index]).closest('[data-link="aDecouvrir"]').attr('href', response.infoJeu.lien);
		}
		
		$(self.components[index]).addClass('is_initialized');
	}

	const setSlickSlideBkgdImg = function(element, imageSrc) {
		var nbTours = 0;
		
		$('.ejCarrouselBannieres').on('init',  function(event, slick) {
			$(element).find('img[class^=" "]').attr('src', imageSrc);
		});
		
		$('.ejCarrouselBannieres').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			if (nextSlide !== 0 && (nbTours < 1)) {
				$(element).find('img[class^=" "]').attr('src', imageSrc);
			}
			if (nextSlide === 0) {
				nbTours++;
			}
		});
	}

	const toggleLazyLoadClass = function(element, bgImg) {
		if (bgImg !== "") {
			$(element).removeClass('lazyload bg-img');
			$(element).attr('data-bgset', bgImg);
			$(element).addClass('lazyload bg-img');
		}
	}

	const setTypeLancement = function(element, infoJeu) {
		var lancerJeu = $(element).attr('data-type-lancement');
		
		if (lancerJeu === 'auto') {
			$(element).attr('href', infoJeu.lien_game_and_gaq);
		} else if (lancerJeu === 'manual') {
			$(element).attr('href', infoJeu.lien);
		}
	}

	const incrementGaCategoryOnBanners = () => {
		var imagesTrackedBigBox = $('.zoneGauche .imageTracked');
		if(imagesTrackedBigBox.length){
			let intervalId = setInterval( () => {
				// adding index to tracking banner images BigBox
				let isReady = $('body').hasClass('banners-zone-gauche-ready');
				if(isReady){
					let catIndex = 1;
					imagesTrackedBigBox.each(function(index, imgtracked){
						if($(imgtracked).is(':visible')){ 
							let gaCategoryText = $(imgtracked).attr('data-gacategory') + '-' + catIndex;
							$(imgtracked).attr('data-gacategory', gaCategoryText);
							catIndex++;
						}
					});
					$('body').removeClass('banners-zone-gauche-ready'); 
					clearInterval(intervalId);
				}
			}, 200);
		}	
	}

	self.init = function() {
		setBanniereCustom();
	}
}

BanniereCustom = new BanniereCustom();

export default BanniereCustom;