<template>
  <div :data-component-name="$options.name" class="container space_under_text">
    <b-row>
      <b-col class="col-12">
        <img v-lazy="getImageSrcVariation(getImageByScreenSize(contenu))" class="image" alt="" />
      </b-col>
      <b-col class="col-12">
        <!-- <p>{{contenu.surTitre}}</p> -->
        <p style="margin-bottom: 1rem;">
          <span class="titre graphik_bold" v-html="contenu.titre"></span>
        </p>
        <template v-if="contenu.sousTitre != null">
          <p v-html="contenu.sousTitre"></p>
        </template>
        <div class="h-50 d-inline-block texte" v-html="contenu.texte">
        </div>
      </b-col>
    </b-row>
    </div>

</template>

<script>
export default {
  name: "MediaImageImg50tit10txt40",
  data() {
    return {
    };
  },
  props: ["contenu"],
  methods: {},
  computed: {
    classObject: function () {
      return {
        rounded: this.contenu.coinArrondi
      }
    },
    styleObject: function () {
      return {
        'background-color': this.contenu.secBgThemeColor
      }
    },
  },
  created: function() {}
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.container{
  text-align:center; 
  color:black;
}
.image{
  width:60%; 
}

@media screen and (max-width: 768px){
  .space_under_text{
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 375px) and (max-width: 500px) {
  .image{
    width:60%;
  }
}


.titre{  
  @include ms-respond(font-size, 2);
}

.texte{  
  @include ms-respond(font-size, 0);
}


</style>