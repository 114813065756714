// Originally in ej-commmun-portail.js

import EspacejeuxPortail_BarrePortail from "./espacejeux-portail-barre-portail";
import EspacejeuxPortail_Dialogue from "./espacejeux-portail-dialogue";
import EspacejeuxPortail_FilAriane from "./espacejeux-portail-fil-ariane";

/**********************************/
/*** OBJECT EspacejeuxPortail  ***/
/********************************/

/* NAME : EspacejeuxPortail : espacejeux
 * PARM : no
 *
 * DESC : Object for all JS
 */

window.EspacejeuxPortail_def = function() {
  /**********************/
  /*** INIT CONSTANT ***/
  /********************/
	this.initialisationsParticulieres = [];

    this.NOM_COOKIE_LANGUE = "cust_xl";
    this.NBR_JOURS_DELAI_COOKIE_LANGUE = 400;
    this.LANGUE = $("html").attr("lang");
    this.URL_AUTRE_LANGUE = "";
    this.DELAI_UPDATE_DATE_HEURE = 1000;
    this.handleIntervalleDateHeure = null;

    this.setLanguage = function() {

        if(typeof this.LANGUE === "undefined") {
            this.LANGUE = EspacejeuxPortail_Cookie.lireCookie(this.NOM_COOKIE_LANGUE); 
            if(this.LANGUE === null){
                this.LANGUE = "fr"; //default lang
            }        
        }
        EspacejeuxPortail_Cookie.ecrireCookie(this.NOM_COOKIE_LANGUE, this.LANGUE, 400);
        
        if(!$("body").hasClass( "fr" ) &&  
           !$("body").hasClass( "en" )) {        	
        	$("body").addClass(this.LANGUE);       	        	
        }

    };
    
    this.init = function () {

    	$('#printRef').bind('click', function(){
            $('.conditions-iframe').load(function(){
                this.contentWindow.focus();
                this.contentWindow.print();
                $(this).unbind('load');
            }).attr('src', $('.conditions-iframe').attr('src'));
            return false;
        });

    // START
    this.start();
    
/***** TODO À SUPPRIMER CAR NE SERT PLUS
    // LOOP initialisationsParticulieres
    if (0 !== this.initialisationsParticulieres.length) {
      jQuery.each(this.initialisationsParticulieres, function() {
        this.init();
      });
    }
*****/
    // END
    this.end();
  };

  this.getPromoParam = function() {
    var promo = EspacejeuxPortail.getParameterByName("promo");
    var promoParam = "";
    if (promo != null && promo != "") {
      promoParam = "?promo=" + promo;
    }
    return promoParam;
  };
  this.choisirActionClick = function(event) {
    if (ejConnect.etatClient == "cl") {
      // || fopt
      //if(fopt == false){
      window.location.href =
        $("a.inscription-complete-btn").attr("href") + EspacejeuxPortail.getPromoParam();
      //}
    }
  };

  this.start = function() {
    EspacejeuxPortail_BarrePortail.init();

    // ajouterTousLesEvenements
    this.ajouterTousLesEvenements();

    // ajouterOverExplication
    //this.ajouterOverExplication();   ***
    // ajouterBookmark
    this.ajouterBookmark();
    // focusSurPremierElement
    this.focusSurPremierElement();
  };

  this.ouvertureLoginModaleOpl = function() {
    var openLoginLightBox = ejApi.getParameterByName("opl");

    if ("1" == openLoginLightBox) {
      //ejConnect.masquerDivResumeCompte();
      $(".ouverture-session-reg-comp-lib").addClass("d-none");
      $(".ouverture-session-lib").removeClass("d-none");
      $("#formulaireAuthentificationModale").modal("show");
      $(".modal-backdrop.in").hide();
    }
  };

  this.end = function() {
    /* Afficher le fil d'ariane */
    EspacejeuxPortail_FilAriane.afficherFilAriane();
  };

  /* EVENT submit on : Header Login */
  /*TODO 2020-01-10 À SUPPRIMER CAR APPELLE LOGINNAV PLUSIEURS FOIS
     this.addLoginSubmitEvent = function(){
        $("#formulaireAuthentificationModale").unbind();
        $("#formulaireAuthentificationModale").submit(function(){
                ejConnect.formulaireAuthentificationModaleSubmit();
                return false;
       });
     };
     */

  /*****************/
  /*** FUNCTION ***/
  /***************/

  /* NAME : ajouterTousLesEvenements
   * PARM : no
   *
   * DESC : add all event on selector
   */

  this.ajouterTousLesEvenements = function() {
    var paramContexte = EspacejeuxPortail.getParameterByName("contexte");
    if (!paramContexte == "") {
      $("a").click(function() {
        $(this).attr("href", function(index, value) {
          return value.indexOf("#") > -1 ? value : value + "?contexte=" + paramContexte;
        });
      });
    }

    /* EVENT click collapse in registration */
    jQuery.each($(".btn-collapse-limite"), function(index) {
      var elm = $("ul.box-collapse-limite")[index];
      $(this).click(function() {
        $(elm).slideToggle(500);
        $(this)
          .find("span")
          .eq(0)
          .toggleClass("icon-arrow-down icon-arrow-up");
      });
    });

    /* EVENT click collapse in footer */
    jQuery.each($(".btn-collapse-footer"), function(index) {
      var elm = $("ul.box-collapse-footer")[index];
      $(this).click(function() {
        $(elm).slideToggle(500);
        $(this)
          .find("span")
          .eq(1)
          .toggleClass("icon-arrow-down icon-arrow-up");
      });
    });

    //2018-11-21 Simon T. : SERT ENCORE?
    //Utiliser dans les pages openbet
    $("a#lienAfficherPauseJeuSimple").bind("click", function() {
      EspacejeuxPortail.afficherPauseJeu("PauseJeuSimple");
    });
    $("a#lienAfficherPauseJeuPeriodique").bind("click", function() {
      EspacejeuxPortail.afficherPauseJeu("PauseJeuPeriodique");
    });

    var hrefDoc = self.location.href;
    if (/fr\/poker/i.test(hrefDoc) || /en\/poker/i.test(hrefDoc)) {
      $("#jsddm a.poker").addClass("menu_on");
    } else if (/fr\/casino/i.test(hrefDoc) || /en\/casino/i.test(hrefDoc)) {
      $("#jsddm a.casino").addClass("menu_on");
    } else if (
      /fr\/casino\/machines-a-sous/i.test(hrefDoc) ||
      /en\/casino\/slot-machines/i.test(hrefDoc)
    ) {
      $("#jsddm a.casino").addClass("menu_on");
      $("#jsddm a.MAS").addClass("menu_on");
      $("div#zonePiedZoneTexteMAS").css("display", "block");
    } else if (
      /fr\/casino\/jeux-de-table/i.test(hrefDoc) ||
      /en\/casino\/table-games/i.test(hrefDoc)
    ) {
      $("#jsddm a.casino").addClass("menu_on");
      $("#jsddm a.jeuxdetable").addClass("menu_on");
      $("div#zonePiedZoneTexteJeuxTable").css("display", "block");
    } else if (/fr\/bingo/i.test(hrefDoc) || /en\/bingo/i.test(hrefDoc)) {
      $("#jsddm a.bingo").addClass("menu_on");
    } else if (/miseojeu.espacejeux/i.test(hrefDoc)) {
      $("#jsddm a.parisportif").addClass("menu_on");
      $("div#zonePiedZoneTextePariSportif").css("display", "block");
    } else if (/loteries.espacejeux/i.test(hrefDoc)) {
      $("#jsddm a.loteries").addClass("menu_on");
      $("div#zonePiedZoneTexteLoteries").css("display", "block");
    } else if (/fr\/instants/i.test(hrefDoc) || /en\/instants/i.test(hrefDoc)) {
      $("#jsddm a.instants").addClass("menu_on");
      $("div#zonePiedZoneTexteLoteries").css("display", "block");
    }
  };

  this.printMe = function() {
    window.print();
  };

  this.afficherDialogue = function(destination) {
    $(destination).dialog("open");
  };

  this.fermerDialogue = function(destination) {
    $(destination).dialog("close");
  };

  this.ouvrirLogin = function() {
    $(".ouverture-session-reg-comp-lib").addClass("d-none");
    $(".ouverture-session-lib").removeClass("d-none");
    $("#formulaireAuthentificationModale").modal("show");
    $(".modal-backdrop.in").hide();
  };

  this.fermerModaleLogin = function() {
    $("div#formulaireAuthentificationModale").modal("hide");
  };

  this.ouvrirLoginRegComplete = function() {
    $(".ouverture-session-reg-comp-lib").removeClass("d-none");
    $(".ouverture-session-lib").addClass("d-none");
    $("#formulaireAuthentificationModale").modal("show");
    //    	$(".modal-backdrop.in").hide();
  };

  this.ouvrirInvite = function() {
    if (ejConnect.etatClient == "cl") {
      $("div#contenue-invite-reg-comp-nw").dialog("open");
    } else if (EspacejeuxConnect.etatClient == "ci") {
      $("div#contenue-invite-reg-comp-g").dialog("open");
    }
  };

  this.afficherPauseJeu = function(nomPauseJeu) {
    $("div.zonePauseJeu").css("display", "none");
    $("ul#listeLiensAfficherPauseJeu li a").removeClass("ouvert");
    $("div#zone" + nomPauseJeu).css("display", "block");
    $("a#lienAfficher" + nomPauseJeu).addClass("ouvert");
    if ("PauseJeuSimple" === nomPauseJeu) {
      $("#ckEncadrerMonJeuPauseSimple").attr("checked", "checked");
      $("#ckEncadrerMonJeuPausePeriodique").attr("checked", "");
    } else {
      $("#ckEncadrerMonJeuPauseSimple").attr("checked", "");
      $("#ckEncadrerMonJeuPausePeriodique").attr("checked", "checked");
    }
    // fillerSousMenuGauche
    this.fillerSousMenuGauche();
  };

  this.ajouterOverExplication = function() {
    if (0 !== $("img.imgAccueilTousLesJeux").length) {
      // display on over
      jQuery.each($("img.imgAccueilTousLesJeux"), function(index) {
        $(this).mouseover(function() {
          $(this)
            .prev()
            .css("display", "block");
        });
      });

      // hide on over
      jQuery.each($("div.explication"), function(index) {
        $(this).mouseleave(function() {
          $(this).css("display", "none");
        });
      });
    }
  };

  this.ajouterBookmark = function() {
    // add a "rel" attrib if Opera 7+
    if (window.opera) {
      if ($("a.jqbookmark").attr("rel") !== "") {
        // don't overwrite the rel attrib if already set
        $("a.jqbookmark").attr("rel", "sidebar");
      }
    }

    $("a.jqbookmark").click(function(event) {
      event.preventDefault(); // prevent the anchor tag from sending the user off to the link
      var url = this.href,
        title = this.title;

      if (window.sidebar) {
        // Mozilla Firefox Bookmark
        window.sidebar.addPanel(title, url, "");
      } else if (window.external) {
        // IE Favorite
        window.external.AddFavorite(url, title);
      } else if (window.opera) {
        // Opera 7+
        return false; // do nothing - the rel="sidebar" should do the trick
      }
    });
  };

  this.focusSurPremierElement = function() {
    // foucs on first input
    var firstInput = $(
      "div#zoneCorps :input:not(input[type=button],input[type=submit],input[type=image],button):visible:first"
    );
    if (0 !== firstInput.length) {
      firstInput.focus();
    }
  };

  this.fillerSousMenuGauche = function() {
    if (0 !== $(".zoneCorpsGauche").length) {
      // Reinit show zoneCorpsGaucheFill
      $(".zoneCorpsGaucheFill").css("display", "block");

      // Reinit height of all div then recalcul
      $(".zoneCorpsGaucheFillCorps").css("height", "auto");
      $(".zoneCorpsGaucheFillCorpsGradien").css("height", "auto");
      $(".zoneCorpsDroitFill:last").css("height", "auto");

      var margin_bottom = parseInt(
        $(".zoneCorpsGaucheMenu")
          .css("margin-bottom")
          .replace("px", "")
      );
      var fill_box_height_total =
        $(".zoneCorpsGaucheFillEntete").height() +
        $(".zoneCorpsGaucheFillCorps").height() +
        $(".zoneCorpsGaucheFillPied").height() +
        margin_bottom;

      // Get left height
      var zoneCorpsGaucheMenu_height = $(".zoneCorpsGaucheMenu").height() + 1;

      // Get right side
      var zoneCorpsDroitContenu_height = $(".zoneCorpsDroitContenu").height();

      // Get last block height
      var last_height = $(".zoneCorpsDroitFill:last").height();

      // Get left side finale
      var zoneCorpsGaucheFillCorps_height = $(".zoneCorpsGaucheFillCorps").height();

      // if left bigger or equal than right
      if (zoneCorpsGaucheMenu_height >= zoneCorpsDroitContenu_height) {
        // Hide zoneCorpsGaucheFill
        $(".zoneCorpsGaucheFill").css("display", "none");

        $(".zoneCorpsDroitFill:last").css(
          "height",
          last_height + (zoneCorpsGaucheMenu_height - zoneCorpsDroitContenu_height)
        );
      }
      // if right bigger than left + fill
      else if (
        zoneCorpsDroitContenu_height >
        zoneCorpsGaucheMenu_height + margin_bottom + fill_box_height_total
      ) {
        $(".zoneCorpsGaucheFillCorps").css(
          "height",
          zoneCorpsDroitContenu_height - zoneCorpsGaucheMenu_height - 28
        );
      }
      // if right bigger than left
      else if (zoneCorpsDroitContenu_height > zoneCorpsGaucheMenu_height) {
        $(".zoneCorpsDroitFill:last").css(
          "height",
          last_height -
            (zoneCorpsDroitContenu_height - zoneCorpsGaucheMenu_height) +
            fill_box_height_total
        );
      }

      // Remove zoneCorpsGaucheFillCorpsGradien if exists
      if (0 === $(".zoneCorpsGaucheFillCorpsGradien").length) {
        // Add div to DOM
        $(".zoneCorpsGaucheFillCorps").append(
          "<div class='zoneCorpsGaucheFillCorpsGradien'></div>"
        );
      }

      // Add gradient for background image in Fill if is visible
      if ($(".zoneCorpsGaucheFill").is(":visible")) {
        // Mod height of DOM element
        $(".zoneCorpsGaucheFillCorpsGradien").css("height", zoneCorpsGaucheFillCorps_height);
      }
    }
  };

  this.getParameterByName = function(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);

    if (results == null) return "";
    else return decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  this.isPageAccueil = function() {
    var pageAccueil = false;
    var pathname = $(location).attr("pathname");

    if (
      "/" == pathname ||
      "/fr" == pathname ||
      "/en" == pathname ||
      "/fr/" == pathname ||
      "/en/" == pathname ||
      pathname.match(/^\/$|\/fr.html$|\/en.html$/g) != null
    ) {
      pageAccueil = true;
    }
    return pageAccueil;
  };

  this.afficherBlankDiv = function() {
    $("#blankDiv").css("display", "block");
  };

  this.ouvrirChargement = function() {
    $("#formulaireAuthentificationModale").css("opacity", 0); //#formulaireAuthentificationModaleSubmit
    if ($("#ej-loader").length > 0) {
      $("#ej-loader").modal("show");
    }
  };

  this.fermerChargement = function() {
    $("#ej-loader").modal("hide");
    $("#formulaireAuthentificationModale").css("opacity", 1); //#formulaireAuthentificationModaleSubmit
  };

  this.formatterNombreMonetaireCustom = function(nombre, sepMillier, sepDecimal) {
    var texte = "";
    texte = nombre;
    texte = texte.replace(/,/g, sepMillier);
    texte = texte.replace(/\./g, sepDecimal);
    return texte;
  };

  this.viderChamp = function(obj, valeurDefaut) {
    if (obj) {
      if (obj.value === valeurDefaut) {
        obj.value = "";
      }
    }
    return true;
  };

  this.garderValeurChamp = function(obj, valeurDefaut) {
    if (obj) {
      if (obj.value === "") {
        obj.value = valeurDefaut;
        $(obj).css({ color: "#959595" });
      }
    }
    return true;
  };

  this.garderValeurChampPassword = function(obj, idChampTexte) {
    if (obj) {
      if (obj.value === "") {
        $("input#" + idChampTexte).css("display", "block");
        $(obj).css("display", "none");
      }
    }
  };

  this.partagerPage = function partagerPage() {
    //confirm("Partager : " + self.location.href);
  };

  this.ouvrirJeuResponsable = function() {
    window.open("/quicklink/jeu-responsable.html");
  };

  this.deconnexion = function() {
    EspacejeuxPortail.ouvrirChargement();
    ejApi.fermerSession(ejConnect.onLogoutProcessCompleted, ejConnect.onLoginLogoutProcessFailed);
  };

  this.afficherMessageErreurLogin = function() {
    $("#zoneConteneurMessageErreurLoginModale").css("display", "block");
  };

  this.afficherMessageServeurIndisponible = function() {
    if ("fr" == ejApi.lireLangue()) {
      $("#zoneMessageErreurLogin").html(
        "Espacejeux est inaccessible en raison d'une maintenance des serveurs."
      );
    } else {
      $("#zoneMessageErreurLogin").html("Espacejeux is offline due to server maintenance.");
    }
    EspacejeuxPortail.afficherMessageErreurLogin();

    return false;
  };

  this.getValeurParametreGet = function(nomParametre) {
    var codeRegex = null,
      regex = null,
      resultats = null,
      valeur = null;

    if (nomParametre) {
      nomParametre = nomParametre.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      codeRegex = "[\\?&]" + nomParametre + "=([^&#]*)";
      regex = new RegExp(codeRegex);
      resultats = regex.exec(self.location.href);
      if (resultats !== null) {
        valeur = resultats[1];
      }
    }

    return valeur;
  };

  //TODO !!important que cette fct soit appel après le chargement ejConnect de l'Entete!!
  //trigger par dans l'evennement ejEventSessionUpdate
  this.onItemBannerUpdate = function() {
    if (ejApi.sessionEstOuverte()) {
      if (typeof initPastilleLotprogressif === "function") {
        initPastilleLotprogressif(EspacejeuxPortail.LANGUE);
      }
    }
  };

  this.CurrencyFormatted = function(amount) {
    var i = parseFloat(amount),
      minus = "";
    if (isNaN(i)) {
      i = 0.0;
    }
    if (i < 0) {
      minus = "-";
    }
    i = Math.abs(i);
    i = parseInt((i + 0.005) * 100);
    i = i / 100;
    var s = new String(i);
    if (s.indexOf(".") < 0) {
      s += ".00";
    }
    if (s.indexOf(".") === s.length - 2) {
      s += "0";
    }

    var b = s.substring(s.length - 3, s.length);
    s = s.substring(0, s.length - 3);
    for (i = 0; i < Math.floor((s.length - (1 + i)) / 3); i++) {
      s = s.substring(0, s.length - (4 * i + 3)) + "," + s.substring(s.length - (4 * i + 3));
    }
    s = s + b;

    s = minus + s;
    return s;
  };

  this.compareJour = function(str1, str2, compare) {
    /* compare
     * jour = 86400000 */
    return Math.floor((Date.parse(str1) - Date.parse(str2)) / compare);
  };

  this.loadScript = function(url, callback) {
    // adding the script tag to the head as suggested before
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;

    // then bind the event to the callback function
    // there are several events for cross browser compatibility
    script.onreadystatechange = callback;
    script.onload = callback;

    // fire the loading
    head.appendChild(script);
  };

  this.loadCSS = function(file, media, fileVersion) {
    var head = document.getElementsByTagName("head")[0],
      // Create style
      script = document.createElement("link");
    script.setAttribute("rel", "stylesheet");
    script.setAttribute("type", "text/css");
    script.setAttribute("media", media);
    script.setAttribute("href", file + "?v=" + fileVersion);

    head.appendChild(script);
  };

  this.getXMLnodes = function(data, path) {
    var nodes = false,
      xmlDoc = false;
    if (window.ActiveXObject) {
      // Internet Explorer
      xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
      xmlDoc.async = "false";
      xmlDoc.loadXML(data);
      nodes = xmlDoc.selectNodes(path);
    } else if (window.DOMParser) {
      var parser = new DOMParser();
      xmlDoc = parser.parseFromString(data, "text/xml");
      nodes = xmlDoc.evaluate(path, xmlDoc, null, XPathResult.ANY_TYPE, null);
      nodes = nodes.iterateNext();
    }
    return nodes;
  };

  this.getPosition = function(index) {
    var positions = ["0", "-3px", "-7px", "-11px", "-16px"];
    return positions[index - 1];
  };

  this.verifierFournisseurDateActive = function(game_code) {
    var dateDebut = $("div#" + game_code + " time.dateDebutDesactivationFournisseur").attr(
      "datetime"
    );
    var dateFin = $("div#" + game_code + " time.dateFinDesactivationFournisseur").attr("datetime");
    return this.verifierDateActive(dateDebut, dateFin);
  };

  this.verifierFournisseurAvertissementActive = function(game_code) {
    const dateDebut = $("div#" + game_code + " time.dateDebutDesactivationFournisseur").attr(
      "datetime"
    );

    // On affiche le message d'avertisssement si la maintenance est prévue dans 1 heure ou moins.
    return moment().diff(dateDebut, "minutes") >= -60 && moment().diff(dateDebut, "minutes") <= 0;
  };

  this.verifierJeuDateActive = function(game_code) {
    var dateDebut = $("div#" + game_code + " time.dateDebutDesactivationJeu").attr("datetime");
    var dateFin = $("div#" + game_code + " time.dateFinDesactivationJeu").attr("datetime");
    return this.verifierDateActive(dateDebut, dateFin);
  };

  this.verifierJeuAvertissementActive = function(game_code) {
    const dateDebut = $("div#" + game_code + " time.dateDebutDesactivationJeu").attr("datetime");

    // On affiche le message d'avertisssement si la maintenance est prévue dans 1 heure ou moins.
    return moment().diff(dateDebut, "minutes") >= -60 && moment().diff(dateDebut, "minutes") <= 0;
  };

  this.verifierDateActive = function(dateDebut, dateFin) {
    var _dateDebut = moment(dateDebut);
    var _dateFin = moment(dateFin);
    _dateDebut = _dateDebut.isValid() ? _dateDebut : moment().subtract(1, "y");
    _dateFin = _dateFin.isValid() ? _dateFin : moment().add(1, "y");

    if (moment() >= _dateDebut && moment() < _dateFin) {
      return true;
    } else {
      return false;
    }
  };
};

window.EspacejeuxPortail = new EspacejeuxPortail_def();
