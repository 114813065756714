var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card media-component",
      class: [
        _vm.isBingo ? "carrousel-bingo" : "",
        _vm.contenu.shadow && _vm.contenu.shadow != "false"
          ? "drop_shadow"
          : "",
        _vm.contenu.coinArrondi,
        _vm.contenu.cssClass,
        _vm.classObject,
      ],
      style: [
        { "border-color": _vm.contenu.borderColor },
        { "background-size": _vm.contenu.backgroundType },
        _vm.styleObject,
      ],
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _vm.contenu.formatAffichage
        ? _c(
            "div",
            {
              staticClass: "content ta-left container_media h-100",
              class: [_vm.contenu.formatAffichage + "_container"],
              attrs: {
                "info-format-affichage": _vm.contenu.formatAffichage,
                "ga-img-name": _vm.imageName,
                "data-tag":
                  "MediaImage" +
                  " " +
                  _vm.contenu.GaLabel +
                  " " +
                  _vm.contenu.formatAffichage.charAt(0).toUpperCase() +
                  _vm.contenu.formatAffichage.slice(1) +
                  "-" +
                  _vm.contenu.dataTag,
              },
            },
            [
              _c(
                "MediaImage" +
                  _vm.contenu.formatAffichage.charAt(0).toUpperCase() +
                  _vm.contenu.formatAffichage.slice(1),
                {
                  key: _vm.contenu["@id"],
                  tag: "component",
                  attrs: { contenu: _vm.contenu },
                }
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-sizer" }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }