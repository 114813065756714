<template>
	<form action="/services/upload-doc" 
		  enctype="multipart/form-data" 
		  class="form-televersement pl-sm-3"  
		  data-component="files-sender"  
		  @submit="sendFiles" 
		  :class="classes" 
		  :data-files-count="filesCount"> <!-- -->
		<televersementErrors :error="error" :fileTypesString="fileTypesString"></televersementErrors>
		<slot name="form"
			:error="error"
			:filesCount="filesCount"
			:filesMin="filesMin"
			:filesMax="filesMax"
			:addFile="addFile"
			:resetForm="resetForm"
			:fileTypesString="fileTypesString"
			:siteId="siteId"
			:bankAccount="bankAccount"
			:identityByCCAuthorised="identityByCCAuthorised"
		>
		</slot> <!--  -->
	</form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import mixinAddfile from '../../mixins/mixin-add-file';
import { mixinLangue } from '../../mixins/mixinLangue';
import mixinUserMessages from '../../mixins/mixin-user-messages';
import mixinSendfiles from '../../mixins/mixin-send-files';
import televersementErrors from './televersementErrors.vue';

export default {
	mixins: [mixinAddfile, mixinLangue, mixinUserMessages, mixinSendfiles],
	components: {
		televersementErrors
	},

	data: function () {
		return {
			colorError : 'tamarillo',
			
			dragLeaveEnable : 0,
			dropzoneClass: {
				isActive: 'dropzone-is_active',
				filesMaxReached: 'dropzone-files-max-reached'
			},
			error : {
				filesCount : false,
				filesMultiple : false,
				identicalName : false,
				sending : false,
				size : false,
				type : false,
			},
			fileNameDuplicates : false,
			fileNameLengthMax : this.settings.nameLength,
			fileSizeMax : this.settings.maxTaille,
			fileTypes : this.settings.types,
			files : [],
			filesCount : 0,
			filesNames : [],
			filesWeakmap : new WeakMap(),
			formClass : {
				filesDropped: 'files-sender--files-dropped'
			},
			initialized : false,
		}
	},

	props: {
		classes : String,
		filesMax : {
			default: 1,
			type: Number,
		},
		filesMin : {
			default: 1,
			type: Number,
		},
		formIsActive : Boolean,
		settings: Object,
		uuid: {
			type: String,
			required: true,
		},
		siteId: String,
		bankAccount : Object,
		identityByCCAuthorised: String,
	},

	mounted(){
		this.$el.classList.add('files-sender-is_initialized');
	},

	computed: {
		...mapGetters([
			'getAccountUserMessages',
		]),

		$dropzone() {
			return document.querySelector( 'body' );
		},

		$errors() {
			return this.$el.querySelectorAll( '.televersement__error' );
		},

		$errorsContainer() {
			return this.$el.querySelector( '.televersement__errors' );
		},

		fileTypesString() {
			let fileTypesString = '';
			const vm = this;
			vm.fileTypes = vm.$parent.settings.types;
			if(vm.fileTypes !== undefined) {
								 
				vm.fileTypes.forEach(function(item, index){
					if( ( index + 1 ) === vm.fileTypes.length ) {
						fileTypesString += `${item.split('/')[1]}`;
					} else {
						fileTypesString += `${item.split('/')[1]}, `;
					}
					
				});
			}
			return fileTypesString;
		},
	},

	methods: {
		deleteFile( file ) {
			const vm = this;
			return function() {
				const fileIndex = Array.prototype.indexOf.call( this.closest( '[data-is="container-files"]' ).children, this.parentElement );

				// Remove the element from the Array of names, items and the DOM
				vm.filesNames.splice( fileIndex, 1 );
				const $file = this.closest( '[data-is="file"]' );
				$file.parentElement.removeChild( $file );
				vm.files = vm.files.filter(item =>  {
					return item !== file
				});

				vm.filesCount--;

				// Si des fichiers dupliqués ont été détectés, on vérifie si c'est toujours le cas.
				if( vm.fileNameDuplicates ) {

					vm.$el.querySelectorAll( `.televersement__preview-item-name.color-${vm.colorError}` ).forEach(( item ) => {
						const filesNamesDuplicatesCounter = vm.filesNames.filter( filename => filename ===  vm.filesWeakmap.get( item.closest( '[data-is="file"]' ) ).name );
						if( filesNamesDuplicatesCounter.length === 1 ) {
							item.classList.remove( `color-${vm.colorError}` );
						}
					});

					if ( vm.$el.querySelectorAll( `.televersement__preview-item-name.color-${vm.colorError}` ).length === 0 ) {
						vm.fileNameDuplicates = false;
						vm.error.identicalName = false;
						//vm.$el.querySelector( '.js-televersement__error-identical-name' ).classList.add( 'd-none' );
					}
				}

				vm.filesWeakmap.delete( $file );
			}
		},

		dragOverHandler() {
			event.preventDefault();
			document.querySelector('body').classList.add( 'dropzone-is_dragenter' );
			this.dragLeaveEnable++;
		},

		dragLeaveHandler : function() {
			this.dragLeaveEnable--;

			if( this.dragLeaveEnable === 0 ) {
				this.$dropzone.classList.remove( 'dropzone-is_dragenter' );
			}
		},

		enableDragNDrop() {
			this.$dropzone.classList.add( this.dropzoneClass.isActive );
			document.querySelector('body').addEventListener('dragover', this.dragOverHandler );
			document.querySelector('body').addEventListener('dragleave', this.dragLeaveHandler );
			document.querySelector('body').addEventListener('drop', this.addFile );
		},

		disableDragNDrop() {
			this.$dropzone.removeEventListener('dragover', this.dragOverHandler );
			this.$dropzone.removeEventListener('dragleave', this.dragLeaveHandler );
			this.$dropzone.removeEventListener('drop', this.addFile  );
			this.$dropzone.classList.remove( this.dropzoneClass.isActive );
		},

		errorsReset() {
			const vm = this;

			Object.keys( this.error ).forEach(function( key ) {
				vm.error[key] = false;
			});
		},

		resetForm() {
			this.disableDragNDrop();

			this.$el.querySelector( '[data-is="container-files"]' ).innerHTML = '';
			this.$el.classList.remove( this.formClass.filesDropped );

			this.errorsReset();
			this.files = [];
			this.filesCount = 0;
			this.filesNames = [];
			this.filesWeakmap = new WeakMap();
		},
	},

	watch: {
		filesCount() {
			const $ctaSubmit = this.$submit;

			if( this.filesCount === 1 ) {
				this.$el.classList.add( this.formClass.filesDropped );
			}else if( this.filesCount === 0 ) {
				this.$el.classList.remove( this.formClass.filesDropped );
				this.errorsReset();
			}

			// Si le nombre maximum de fichiers est atteint, on ajoute une CLASS sur la dropzone pour afficher / cacher la dropzone incitative.
			if( this.filesCount === this.filesMax ) {
				this.$dropzone.classList.add( this.dropzoneClass.filesMaxReached );
			} else if ( this.$dropzone.classList !== null && this.$dropzone.classList.contains( this.dropzoneClass.filesMaxReached ) && this.filesCount !== this.filesMax ) {
				this.$dropzone.classList.remove( this.dropzoneClass.filesMaxReached );
			}
		},
		formIsActive: {
			immediate: false,
			handler() {
				if( this.formIsActive ) {
					this.enableDragNDrop();
				} else if( !this.formIsActive ) {
					this.resetForm();
				}
			}
		},
	}
}
</script>