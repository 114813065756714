// Originally in ej-commmun-portail.js

const { default: ExpandCollapse } = require("./expand-collapse");

///////////////////////////////////////////////////////////////////////////////////////////
function hideBan(audCl, optinfo, classElt) {
  var hideItemBan = false;
  if (audCl == "anon") {
    if (optinfo == "notSelected") {
      //normalement le optin doit être forcement notSelected
      if (classElt.contains(audCl) && classElt.contains("infolettre-" + optinfo)) {
        hideItemBan = true;
      }
    }
  } else {
    if (optinfo == "notSelected") {
      if (classElt.contains(audCl) && classElt.contains("infolettre-" + optinfo)) {
        hideItemBan = true;
      }
    } else {
      if (
        classElt.contains(audCl) &&
        (classElt.contains("infolettre-notSelected") || classElt.contains("infolettre-" + optinfo))
      ) {
        hideItemBan = true;
      }
    }
  }
  return !hideItemBan;
}

function ouvrirPanneau(el) {
  if (el) {
    if (el.classList) {
      if (el.classList.contains("panel-collapse")) {
        if (el.parentNode.childNodes) {
          el.parentNode.childNodes.forEach(function (subElem) {
            if (subElem.classList) {
              if (subElem.classList.contains("panel-heading")) {
                if (subElem.childNodes) {
                  subElem.childNodes.forEach(function (elem, index) {
                    if (elem.ariaExpanded == "false") {
                      elem.click();
                      elem.scrollIntoView();
                    }
                  });
                }
              }
            }
          });
        }
      } else if (el.classList.contains("panel-heading")) {
        if (el.childNodes) {
          el.childNodes.forEach(function (elem, index) {
            if (elem.ariaExpanded == "false") {
              elem.click();
              elem.scrollIntoView();
            }
          });
        }
      }
    }
    ouvrirPanneau(el.parentNode);
  }
}

$(document).ready(function () {
  localStorage.setItem("comeFromDecon", "0");

  if (
    window.location.href.indexOf("faq#") > 0 ||
    window.location.href.indexOf("pour-nous-joindre#") > 0 ||
    window.location.href.indexOf("contact-us#") > 0
  ) {
    $(document).on("click", function () {
      var id = window.location.href.split("#")[1];
      if ($("#" + id)) {
        setTimeout(function () {
          var node = document.getElementById(id);
          ouvrirPanneau(node);
          return false;
        }, 10);
      }
    });
  } else if (
    window.location.href.indexOf("ok-poker/comment-jouer/") > 0 ||
    window.location.href.indexOf("ok-poker/how-to-play") > 0
  ) {
    $("li > a").on("click", function (elem) {
      if (elem.currentTarget.hash) {
        var id = elem.currentTarget.hash.replace("#", "");
        if ($("#" + id)) {
          var node = document.getElementById(id);
          setTimeout(function () {
            ouvrirPanneau(node);
            node.scrollIntoView();
          }, 500);
        }
      }
    });
  }
  if ($(".ejCarrouselBannieres")) {
    $(".ejCarrouselBannieres").on("init", function () {
      setTimeout(function () {
        if ($(".ejCarrouselBannieres div[ga-calling-page]")) {
          var indexMod = 1;
          $(".ejCarrouselBannieres div[ga-calling-page]").each(function (index) {
            var tmp = $(this).attr("id");
            if (tmp == "") {
              indexMod--;
            }
            if (tmp.includes("slick-slide")) {
              var slideId = indexMod + index;
              $(this)
                .find("a img,span")
                .each(function () {
                  if ($(this).attr("ga-slideId") == "") {
                    $(this).attr("ga-slideId", slideId);
                  }
                });
            }
          });
        }
      }, 100);
    });
  }

  //deconnexion media>1440px sauvegarde statut connexion à anon à utiliser lors de l'initialisation des bannières images
  $("#btn-deconnexion").on("click", function () {
    localStorage.setItem("audienceClient", "anon");
    localStorage.setItem("optin", "notSelected");
    localStorage.setItem("comeFromDecon", "1");
  });

  //deconnexion media<1440px sauvegarde statut connexion à anon à utiliser lors de l'initialisation des bannières images
  $("#btn-deconnexion2").on("click", function () {
    localStorage.setItem("audienceClient", "anon");
    localStorage.setItem("optin", "notSelected");
    localStorage.setItem("comeFromDecon", "1");
  });
});

//sauvegarde du statut de connexion pour la navigation car ejConnect.etatClient n'est pas à jour à
//l'execution de l'initialisation des bannières images dans les sous sections casinos mais après
window.addEventListener("unload", function () {
  if (localStorage.getItem("comeFromDecon") != "1") {
    var aud = typeof ejConnect === "undefined" ? "anon" : ejConnect.etatClient;
    localStorage.setItem("audienceClient", aud);
    var optinSave = "notSelected";
    if (typeof loginNav !== "undefined") {
      if (loginNav.session_details !== null) {
        if (typeof loginNav.session_details.contact_ok) {
          optinSave = loginNav.session_details.contact_ok;
        }
      }
    }
    if (typeof optinSave === "undefined") {
      optinSave = "notSelected";
    }
    localStorage.setItem("optin", optinSave);
  }
});

class BanniereImgsFiltre_def {
  constructor() {
    const self = this;
    const hideItem = function (banniereImg, dansCaroussel) {
      if (dansCaroussel) {
        banniereImg.classList.remove("anon");
        banniereImg.classList.remove("cl");
        banniereImg.classList.remove("ci");
        banniereImg.classList.remove("cj");
      } else {
        banniereImg.classList.add("d-none");
      }
    };
   
    const hideBanniereImg = function (banImgs, dansCaroussel) {
      const banniereImgsLength = banImgs.length;
      for (let i = 0; i < banniereImgsLength; i++) {
        const banniereImg = banImgs[i];
      }
    };
    this.removeUnauthorizedItems = function () {
      const banniereImgsBB = document.querySelectorAll('[data-type-composant~="bigBox"]');
      hideBanniereImg(banniereImgsBB, false);
      const banniereImgsCS = document.querySelectorAll('[data-type-composant~="carrouselSection"]');
      hideBanniereImg(banniereImgsCS, true);
    };
  }
}

window.initBannieres = function () {
  new BanniereImgsFiltre_def().removeUnauthorizedItems();

  var audience = typeof ejConnect === "undefined" ? "anon" : ejConnect.etatClient;
  if (audience == "anon") {
    audience = localStorage.getItem("audienceClient");
  }
  var optin = "notSelected";

  if (typeof loginNav !== "undefined") {
    if (loginNav.session_details !== null) {
      if (typeof loginNav.session_details.contact_ok) {
        optin = loginNav.session_details.contact_ok;
      }
    } else {
      optin = localStorage.getItem("optin");
    }
  } else {
    optin = localStorage.getItem("optin");
  }

  $(".item-banniere").css("display", "none");
  switch (audience) {
    case "anon":
      switch (optin) {
        case "notSelected":
          $("." + audience + ".infolettre-" + optin).css("display", "block");
          $("body").addClass("banners-zone-gauche-ready");
          break;
        default:
      }
      break;
    default:
      switch (optin) {
        case "notSelected":
          $("." + audience + ".infolettre-" + optin).css("display", "block");
          break;
        default:
          $("." + audience + ".infolettre-notSelected").css("display", "block");
          $("." + audience + ".infolettre-" + optin).css("display", "block");
      }
  }

  var allItemBan = $("div").find(".item-banniere");
  for (let j = 0; j < allItemBan.length; j++) {
    const itemBan = allItemBan[j];
    var classElt = itemBan.classList;
    var audCl = audience;
    var optinfo = optin;
    if (hideBan(audience, optin, classElt)) {
      itemBan.classList.remove("anon");
      itemBan.classList.remove("cl");
      itemBan.classList.remove("ci");
      itemBan.classList.remove("cj");
    } else {
      itemBan.style.display = "block";
    }
  }
};
