// Originally in ej-commmun-portail.js

/*******************************************/
/*** OBJECT EspacejeuxPortail_GrosLots  ***/
/*****************************************/

/* NAME : EspacejeuxPortail_GrosLots
 * PARM : none
 *
 * DESC : Affichage du montant du gros lot avec une image pour chaque chiffre selon un XML/JSON.
 */

const DELAI_BAD_BEAT = 30; // en secondes
const TIMEOUT_BAD_BEAT = 10; // en secondes

function EspacejeuxPortail_GrosLots_def() {

	var that = this;
	this.handle = null;
	this.grosLotsIndex = '';
	this.montant = '';

    /*
     * METHO  : init
     * PARM   : none
     *
     * DESC   : Call, a travers d'un proxy FLASH/AJAX, vers une URL qui retourne le XML/JSON contenant les donnÃ©es du gros lots.
     *
     * RETURN : none
     */
	this.init = function (grosLotsIndex) {

		this.grosLotsIndex = grosLotsIndex;

		switch (this.grosLotsIndex) {

			case 'badbeat':

				if (0 !== $('.zoneMontantBadBeat').length ||
					0 !== $('.zoneMontantMiniBadBeat').length ||
					0 !== $('#zoneMontantBadBeatTextuel').length) {
					this.lancerFlash();
					window.setInterval(this.lancerFlash, DELAI_BAD_BEAT * 1000);
					this.handle = window.setTimeout(this.nonDisponible, TIMEOUT_BAD_BEAT * 1000);
				}

				break;

			case 'caribbean':
				if (typeof URL_PORTAIL !== "undefined" && 0 !== $('.zoneMontantCaribbean').length) {
					$.ajax({
						url: URL_PORTAIL.FEED_JSON_CARIBBEAN_JACKPOT,
						//cache: false,
						type: 'GET',
						dataType: 'text',
						// Le "_" permet un caching de 5 secondes au lieu du paramètre cache: false qui est à la millisecondes
						data: {_: Math.floor(new Date().getTime() / 5000)},
						success: function (data) {
							that.recevoir(data);
						},
						error: function (data, ajaxOptions, thrownError) {
							that.nonDisponible();
						}
					});
				}

				break;
		}
	};

    /*
     * METHO  : recevoir
     * PARM   : data : Code XML/JSON contenant le nombre du gros lots
     *
     * DESC   : Décortication du code XML/JSON recu afin de retrouver le nombre du gros lots
     *
     * RETURN : none
     */
	this.recevoir = function (data) {

		switch (this.grosLotsIndex) {

			case 'badbeat':
				var xmlDoc = null,
					nodes = null,
					result = null,
					path = '/UserCust_GetBadBeatJackpotResult/next',  //badbeatjackpot
					parser = null,
					failed = false;
				this.montant = '';

				window.clearTimeout(this.handle);

				if (data !== null && data.indexOf('<UserCust_GetBadBeatJackpotResult>') === 0) {  //badbeatjackpot
					try {
						if (nodes = EspacejeuxPortail.getXMLnodes(data, path)) {
							if (window.ActiveXObject) { // Internet Explorer
								if (nodes.length > 0) {
									this.montant = nodes[0].childNodes[0].nodeValue;
								}
							} else {
								this.montant = nodes.childNodes[0].nodeValue;
							}
						}
					}
					catch (error) {
						failed = true;
					}

				}
				else {
					failed = true;
				}

				if (failed) {
					this.nonDisponible();
				}
				else {
					this.afficher();
				}

				break;

			case 'caribbean':

				var failed = false,
					response = null;
				this.montant = '';
				eval('response = ' + data);

				if (response !== null && response.games.CaribbeanStudPokerSLHiRoller.jackpot !== 0) {
					this.montant = EspacejeuxPortail.CurrencyFormatted(response.games.CaribbeanStudPokerSLHiRoller.jackpot);
				}
				else {
					failed = true;
				}

				if (failed) {
					this.nonDisponible();
				}
				else {
					this.afficher();
				}

				break;
		}
	};

    /*
     * METHO  : afficher
     * PARM   : none
     *
     * DESC   : Affichage du montant du gros lots dans un div avec animation fadeIn fadeOut et/ou du montant en texte avec Cufon
     *
     * RETURN : none
     */
	this.afficher = function () {
	};

    /*
     * METHO  : getCodeHTMLCaractere
     * PARM   : valeur : ALT de l'image
             petit : Petit chiffre
     *
     * DESC   : Creation du tag HTML Image avec les parametres
     *
     * RETURN : tag HTML image
     */
	this.getCodeHTMLCaractere = function (valeur, petit) {
		var codeHTML = '';
		switch (this.grosLotsIndex) {
			case 'badbeat':
				codeHTML = "<" + "div class=\"montantBadBeat img" + valeur + petit + "\"><" + "/div>";
				break;
			case 'caribbean':
				codeHTML = "<" + "div class=\"montantCaribbean img" + valeur + petit + "\"><" + "/div>";
				break;
		}
		return codeHTML;
	};

    /*
     * METHO  : nonDisponible
     * PARM   : none
     *
     * DESC   : Afficher le gros lots non disponible
     *
     * RETURN : none
     */
	this.nonDisponible = function () {
		switch (this.grosLotsIndex) {
			case 'badbeat':
				// Bad Beat
				$('div.zoneMontantBadBeat:visible').html('&nbsp;');
				$('div.zoneMontantBadBeat:hidden').html('&nbsp;');
				// Mini Bad Beat
				$('.zoneMontantMiniBadBeat:visible').html('&nbsp;');
				$('.zoneMontantMiniBadBeat:hidden').html('&nbsp;');

				$('div.zoneMontantMiniBadBeat:hidden').html(($('body.en')[0] ? "<div class='dollars'></div>" : "") +
					this.htmlMontantNonDisponible($('body.fr')[0] ? "Le lot progressif est offert, mais le système ne peut afficher le montant à gagner en ce moment." : "The progressive jackpot is available, but the system is currently unable to display the amount to be won.") +
					($('body.fr')[0] ? "<div class='dollars'></div>" : ""));

				$('div.zoneMontantMiniBadBeat:visible').fadeOut(500);
				$('div.zoneMontantMiniBadBeat:hidden').fadeIn(500);

				$("div.tooltip-item-progressif").tooltip();
				//$('div#zoneBadBeat').addClass('nonDisponible');
				//$('div#zoneMiniBadBeat').addClass('nonDisponible');
				break;
			case 'caribbean':
				$('div.zoneMontantCaribbean:visible').html('&nbsp;');
				$('div.zoneMontantCaribbean:hidden').html('&nbsp;');

				$('div#zoneCaribbean').addClass('nonDisponible');
				break;
		}
	};





	/******************************/
	/* FUNCTION UNIQUE POUR TOUS */
	/****************************/

    /*
     * METHO  : getCodeHTMLMontant
     * PARM   : type : Type d'image besoin (mini = petit chiffre)
     *
     * DESC   : Compose le code HTML (Image) pour l'affichage du nombre (Selon chaque chiffre, point, virgule, espace,...)
     *
     * RETURN : code HTML (Image) du nombre
     */
	this.getCodeHTMLMontant = function (type) {

		var codeHTML = '',
			car = '',
			texte = '',
			petit = '',
			newCar = null,
			index = 0;

		if (texte = this.getTexteMontantSelonLangue()) {

			if ('mini' === type) {
				texte = 'd' + texte;
			}
			for (index = 0; index < texte.length; index++) {
				newCar = null;
				car = texte.charAt(index);

				if ('d' === car) {
					petit = 's';
				} else {
					car = ('$' === car) ? 'Piastre' : car;
					car = (' ' === car) ? 'Espace' : car;
					car = ('.' === car) ? 'Point' : car;
					car = (',' === car) ? 'Virgule' : car;

					// Piastre always big in big BadBeat
					petit = ('big' === type && 'Piastre' === car && 's' === petit) ? '' : petit;

					newCar = this.getCodeHTMLCaractere(car, petit);
				}
				if (newCar !== null) {
					codeHTML = codeHTML + newCar;
				}
			}
		}

		return codeHTML;
	};

    /*
     * METHO  : getTexteMontantSelonLangue
     * PARM   : none
     *
     * DESC   : Cherche le texte dans la bonne langue
     *
     * RETURN : texte
     */
	this.getTexteMontantSelonLangue = function () {
		var texte = '';

		if (EspacejeuxPortail_Langue.pageEnAnglais()) {
			texte = this.getTexteMontant(',', 'd.');
			texte = (texte.length > 0) ? '$' + texte : texte;
		}
		else {
			texte = this.getTexteMontant(' ', 'd,');
			texte = (texte.length > 0) ? texte + ' $' : texte;
		}
		return texte;
	};

    /*
     * METHO  : getTexteMontant
     * PARM   : sepMillier : Séparateur de millier
                sepDecimal : Séparateur de décimal
     *
     * DESC   : Formatage d'un nombre
     *
     * RETURN : nombre formater
     */
	this.getTexteMontant = function (sepMillier, sepDecimal) {

		// Verif if montant more than 99 Millions
		// if true then delete cents
		var val = this.montant.split('.');

		if (10 < val[0].length) {
			this.montant = val[0];
		}

		return EspacejeuxPortail.formatterNombreMonetaireCustom(this.montant, sepMillier, sepDecimal);
	};



	/******************************************/
	/* FUNCTION UNIQUE POUR CHAQUE GROS LOTS */
	/****************************************/

    /*
     * METHO  : lancerFlash
     * PARM   : none
     *
     * DESC   : Lancer le flash pour récuperer les données du Bad Beat
     *
     * RETURN : none
     */
	this.lancerFlash = function () {

		$('#zoneProxyEspaceJeuxBadBeat').html('');
		$('#zoneProxyEspaceJeuxBadBeat').html('<div id="zoneFlashProxyEspaceJeuxBadBeat">&#160;</div>');

		if (typeof URL_PORTAIL !== "undefined") {
			EspacejeuxPortail_Flash.ecrire({
				config: {
					lienSWF: '/__ressources/swf/proxyEspaceJeux.swf',
					containerId: 'zoneFlashProxyEspaceJeuxBadBeat',
					width: 1,
					height: 1
				},
				params: {
					quality: 'high',
					play: true,
					loop: true,
					scale: 'showall',
					wmode: 'transparent',
					devicefont: 'false',
					bgcolor: '#444444',
					menu: true,
					allowFullScreen: false,
					allowScriptAccess: 'sameDomain'
				},
				flashvars: {
					//mode: 'debug',
					url: URL_PORTAIL.FEED_XML_BAD_BEAT_JACKPOT,
					nomFonctionRetour: 'objBadBeat.recevoir',
					delai: DELAI_BAD_BEAT * 10
				},
				attributes: {
					id: 'swfProxyEspaceJeuxBadBeat',
					name: 'swfProxyEspaceJeuxBadBeat'
				}
			});
		}
	};


	this.htmlMontantDuLot = function (val) {
		var maxDigit = 7,
			txt = "",
			prefix = "",
			car = "",
			value = val.toString();

		var diff = maxDigit - value.length;
		for (var i = 0; i < diff; i++)
			prefix += "X";

		value = prefix + value;

		for (var i = 0; i < value.length; i++) {
			car = value.charAt(i);
			txt += "<div class='zoneChiffre" + (i == 0 || i == 3 ? "_sup" : "") + " chiffre-" + (car == "X" ? "dim" : car) + "'></div>";
		}
		return txt;
	};
	this.htmlMontantNonDisponible = function (msg) {
		var txt = "";
		for (var i = 0; i < 6; i++) {
			txt += "<span class='jackpot-number blank'>&nbsp;</span>";
		}
		//		     txt += "<span class='jackpot-number tooltip-item-progressif'>!<span class='tooltip_description' style='display:none'>"+msg+"</span></span>";
		txt += "<span class='jackpot-number tooltip-item-progressif' data-toggle='tooltip' data-delay='{ \"show\": 500, \"hide\": 100 }' data-placement='right' title='" + msg + "'>!</span>";
		txt += "";
		return txt;
	};

	/**
	 * @param
	 * val : reprensente la valeur du progressif
	 * exacDigit : peut avoir les valeur suivante (8, true ou false)
	 */

	this.htmlMontantDuLotExactDigit = function (val, exactDigit) {
		var txt = "",
			diff = 0,
			value = val.toString(),
			txt = '<span class="jackpot-number-wrapper">',
			nbDigit = 7;

		if (exactDigit === 8) {
			nbDigit = 8;
			exactDigit = false;
		}

		if (!exactDigit) {
			diff = nbDigit - value.length;
			for (var i = 0; i < diff; i++) {
				txt += "<span class='jackpot-number gris'>&nbsp;</span>";
			}
		}

		for (var i = 0; i < value.length; i++) {
			if (/[,]/.test(value.charAt(i)))
				txt += "<span class='jackpot-number number-separator'>" + value.charAt(i) + "</span>";
			else
				txt += "<span class='jackpot-number'>" + value.charAt(i) + "</span>";

		}

		txt += "</span>";
		return txt;
	};


	this.pageJeuxProgressif_filtre = function (nomCookie, nomFiltreProgressif) {

		var cookieContenu = "filtres=filtre-ind" + nomFiltreProgressif + "|filtre-nbLigne-tous;tri=";
		EspacejeuxPortail_Cookie.ecrireCookie(nomCookie, cookieContenu);
		traiterCookie(cookieContenu);
	};
}

const EspacejeuxPortail_GrosLots = new EspacejeuxPortail_GrosLots_def();

export default EspacejeuxPortail_GrosLots;