// TODO: Optimize the code
// TODO: Remove jQuery

const $window = $(window);
const $body = $("body");
const $document = $(document);
const $HTML = $("html");

/**
 * Handle main menu display
 * Apply to all element with a class "fn-component-nav-menu"
 * @param {string|HTMLElement} [limitContext=null] jQuery selector to limit search in. Default = null
 * @function handleMainMenu
 **/
const handleMainMenu = function (limitContext) {
  limitContext = limitContext || null;
  var $menus = $(".fn-component-nav-menu:not(.dummy, .magnolia-editor)", limitContext),
    activeClassName = "active",
    scrollPos = $window.scrollTop(),
    isOpened = false,
    openTimers = [],
    closeTimers = [];

  /* Vérifie si le client est connecté pour afficher icÔne 'log-in' ou 'favoris' */
  if ($(".displayIfLogged").length) {
    // eslint-disable-next-line no-undef
    if (EspacejeuxPortail_Cookie.lireCookie("logged_in") == "1") {
      $(".displayIfLogged").show();
      $(".hideIfLogged").hide();
    } else {
      $(".displayIfLogged").hide();
      $(".hideIfLogged").show();
    }
  }

  // Gestion Menu Gauche Casino (Ouvert/Ferme) au load de la page en fonction du sessionStorage.
  // TODO: Remplacer par une variable globale modifiable sans rebuild
  const maxSunriseATE = 0;
  // eslint-disable-next-line no-undef
  var isMenuProjetATE = false;
  var cookieSunriseATE = EspacejeuxPortail_Cookie.lireCookie("sunriseATE");
  if (cookieSunriseATE != null) {
    var sunriseATE = Number(cookieSunriseATE);
    isMenuProjetATE = !isNaN(sunriseATE) && sunriseATE > 0;
  }

  if (isMagnoliaEditMode()) {
    initMenuGaucheCasino();
    initMenuGaucheATE();
  } else {
    if (isMenuProjetATE) {
      initMenuGaucheATE();
    } else {
      initMenuGaucheCasino();
    }
  }

  // Loop through all main menus. Usually there is only one at top of the page.
  $menus.each(function () {
    var eventNS = ".handleMainMenu",
      $menu = $(this),
      $items = $menu.find(".menu-item-wrapper > li > a, .menu-item-wrapper > li > button"),
      $itemsLi = $items.closest("li"),
      $burger = $menu.find(".burger-menu"),
      // Components MenuGauche Casino
      $menuGauche = $document.find(".menu-bouton"),
      $tiroirCEDPlus = $document.find(".item.sous-titre.ced.plus"),
      $tiroirCEDMoins = $document.find(".item.sous-titre.ced.linkMoins"),
      $tiroirJeuxPlus = $document.find(".item.sous-titre.jeux.plus"),
      $tiroirJeuxMoins = $document.find(".item.sous-titre.jeux.linkMoins"),
      $tiroirMASPlus = $document.find(".item.sous-titre.mas.plus"),
      $tiroirMASMoins = $document.find(".item.sous-titre.mas.linkMoins"),
      // Components MenuGauche Casino (Projet ATE)
      $menuGaucheATE = $document.find(".menu-bouton-ate"),
      $ateTiroirCEDPlus = $document.find(".item.sous-titre-ate.ced.plus"),
      $ateTiroirCEDMoins = $document.find(".item.sous-titre-ate.ced.linkMoins"),
      $ateTiroirJeuxPlus = $document.find(".item.sous-titre-ate.jeux.plus"),
      $ateTiroirJeuxMoins = $document.find(".item.sous-titre-ate.jeux.linkMoins"),
      $ateTiroirMASPlus = $document.find(".item.sous-titre-ate.mas.plus"),
      $ateTiroirMASMoins = $document.find(".item.sous-titre-ate.mas.linkMoins"),
      $container = $menu.find(".menu-container"),
      $navContent = $menu.find(".nav-content-container"),
      $secMenu = $menu.find(".sec-menu-wrapper"),
      showDelay = $menu.data("show-delay") || 60,
      liChildrenTargetSelector = "> *:not(button) > *",
      $subMenu = $menu.find(".submenu-wrapper"),
      windowPreviousScrollPos = $window.scrollTop(),
      scrollTimer = null;

    // Add/Remove opaque-bg class on menu base on open status and scroll position. if scroll is not 0 on isOpened is true, add the class otherwise, remove it.
    var setBgColorClass = function () {
      $menu[(isOpened || scrollPos ? "add" : "remove") + "Class"]("opaque-bg");
    };

    var clearTimers = function (timerSet) {
      while (timerSet.length) {
        window.clearTimeout(timerSet.shift());
      }
    };

    var closeMenu = function () {
      if (isOpened) {
        //$menu.off('mouseleave');
        clearTimers(openTimers);

        $itemsLi
          .filter(".active")
          .find(liChildrenTargetSelector)
          .removeClass("fadeInExpand")
          .addClass("fadeOutCollapse")
          .delay(100)
          .css({ visibility: "hidden" });
        isOpened = false;
        closeTimers.push(
          window.setTimeout(function () {
            $itemsLi.removeClass("active");
            if (!$menu.data("is-mobile-open")) {
              $menu.removeClass("active");
              setBgColorClass();
            } else {
              $container.css("min-height", 0);
            }
          }, showDelay)
        );
      }
    };

    var openMenu = function ($li, $subItems) {
      clearTimers(closeTimers);

      if (isOpened) {
        clearTimers(openTimers);
        $itemsLi
          .filter(".active")
          .find(liChildrenTargetSelector)
          .removeClass("fadeInExpand")
          .addClass("fadeOutCollapse")
          .css({ visibility: "hidden" }); //delay(100)
      }

      isOpened = true;
      // active menu and current item and remove active class from item siblings
      //$menu.addClass(activeClassName);
      $li.addClass(activeClassName).siblings().removeClass(activeClassName);

      handlePromoVisibility($li.find(".submenu-wrapper"));

      // if sub menu items
      if ($subItems && $subItems.length) {
        // loop through all sub items and show them one after the other
        $subItems.stop(true, true).each(function (idx) {
          var $el = $(this);
          openTimers.push(
            window.setTimeout(function () {
              //$el.fadeIn(showDuration);
              $el.css({ visibility: "visible" }).addClass("fadeInExpand");

              if ($menu.data("is-mobile-open")) {
                $container.css(
                  "min-height",
                  $window.height() -
                    $secMenu.outerHeight() -
                    $container.offset().top -
                    $window.scrollTop()
                ); // + $window.scrollTop()  - $container.offset().top
              }
            }, showDelay * (idx + 1))
          );
        });
      }

      $menu.off("mouseleave");
      window.setTimeout(function () {
        $menu.one("mouseleave", function () {
          closeMenu();
          setBgColorClass();
        });
      }, 100);
    };

    var toggleMobileMenu = function () {
      var opened = $menus.data("is-mobile-open");

      if (!opened) {
        $menus.data("is-mobile-open", true);
        $window.off("scroll" + eventNS);
        if (scrollTimer) {
          window.clearTimeout(scrollTimer);
        }
        $itemsLi
          .removeClass("active")
          .find(liChildrenTargetSelector)
          .removeClass("fadeInExpand")
          .addClass("fadeOutCollapse")
          .css({ visibility: "hidden" });
        $menu.addClass("active opaque-bg");
        $(".img-close").removeClass("visually-hidden");
      } else {
        $menus.data("is-mobile-open", false);
        $menu.removeClass("active opaque-bg");
        $(".img-close").addClass("visually-hidden");
        $container.css("min-height", 0);
        $itemsLi.removeClass("active");
      }
    };

    var handlePromoVisibility = function ($subMenuWrapper) {
      var $lists = $subMenuWrapper.find("> ul"),
        listCount = $lists.length,
        $promoCtn = $subMenuWrapper.next(".offers-component"), //'.promo-container'),
        nbItems = $promoCtn.find("> div").length, //$promoCtn.find('> .promo').length, // Math.min($promoCtn.data('nbitems'), (3 - listCount + 1)),
        $lastUl,
        $firstPromo;

      // Set nbItems to the actual number of items in the container (all promo visible)
      $promoCtn.attr("data-nbitems", nbItems);
      if ($promoCtn.length && listCount && nbItems > 0) {
        $lastUl = $lists.last();
        $firstPromo = $promoCtn.find("> div").first(); //$promoCtn.find('> .promo').first();
        // Calculate how many promo items to displayed base on position of the first visible item
        while (
          $lastUl.offset().left + $lastUl.outerWidth() + 10 > $firstPromo.offset().left &&
          nbItems > 0
        ) {
          // Use of attr here is needed for CSS base on this attribute to update
          $promoCtn.attr("data-nbitems", --nbItems);
        }
      }
    };

    // hide all sub-menu items (except link (a))
    //$itemsLi.find(liChildrenTargetSelector).fadeOut(0).css({'visibility': 'visible'}).hide();
    $itemsLi
      .find(liChildrenTargetSelector)
      .removeClass("fadeInExpand")
      .addClass("fadeOutCollapse")
      .css({ visibility: "hidden" });

    // prevent promo from overlapping sub menus
    $subMenu.each(function () {
      handlePromoVisibility($(this));
    });

    // Main menu link click
    $items
      .off("click" + eventNS + " mouseenter" + eventNS)
      .on("click" + eventNS + " mouseenter" + eventNS, function (e) {
        var $self = $(this),
          $itemLi = $itemsLi.eq($items.index($self)),
          $subItems = $itemLi.find(liChildrenTargetSelector);

        if ($subItems.length) {
          // If is the active link, remove active class and collapse the menu
          if ($itemLi.is(".active")) {
            if (e.type != "mouseenter") {
              closeMenu();
            }
          } else if (!(e.type == "mouseenter" && $menu.data("is-mobile-open"))) {
            openMenu($itemLi, $subItems);
          }
          setBgColorClass();
        }
      });

    $burger.on("click", function () {
      toggleMobileMenu();
    });

    // Gestion Menu gauche Casino
    $menuGauche.on("click", function (e) {
      e.preventDefault();
      var toggleMenuGaucheOuvert = window.sessionStorage.getItem("toggleMenuGaucheOuvert");
      var opened = false;

      if (toggleMenuGaucheOuvert && toggleMenuGaucheOuvert == "true") {
        opened = true;
      }

      if (!opened) {
        // Ouvre Menu Gauche
        openMenuGauche();
      } else {
        // Ferme Menu Gauche
        closeMenuGauche();
      }
      createAndDispatchEvent("resize");
    });

    // Gestion Menu gauche Casino projet ATE
    $menuGaucheATE.on("click", function (e) {
      e.preventDefault();
      var isMenuGaucheOuvert = window.sessionStorage.getItem("isMenuGaucheOuvert");
      var opened = false;

      if (isMenuGaucheOuvert && isMenuGaucheOuvert == "true") {
        opened = true;
      }
      if (!opened) {
        // Ouvre Menu Gauche
        openMenuGaucheATE();
      } else {
        // Ferme Menu Gauche
        if (window.innerWidth < 1718) {
          closeMenuGaucheATE();
        }
      }
    });

    // Menu Gauche CASINO : gestion Tiroir Casino en Direct
    $tiroirCEDPlus.on("click", function () {
      // Ouvre Tiroir CED
      openTiroirCED();
      window.sessionStorage.setItem("toggleCEDPlusMoins", "true");
    });
    $tiroirCEDMoins.on("click", function () {
      // Ferme Tiroir CED
      closeTiroirCED();
      window.sessionStorage.setItem("toggleCEDPlusMoins", "false");
    });

    // Menu Gauche CASINO : gestion Tiroir Jeux
    $tiroirJeuxPlus.on("click", function () {
      // Ouvre Tiroir Jeux
      openTiroirJeux();
      window.sessionStorage.setItem("toggleJeuxPlusMoins", "true");
    });
    $tiroirJeuxMoins.on("click", function () {
      // Ferme Tiroir Jeux
      closeTiroirJeux();
      window.sessionStorage.setItem("toggleJeuxPlusMoins", "false");
    });

    // Menu Gauche CASINO : gestion Tiroir Machines à sous
    $tiroirMASPlus.on("click", function () {
      // Ouvre Tiroir  Machines à sous
      openTiroirMAS();
      window.sessionStorage.setItem("toggleMASPlusMoins", "true");
    });
    $tiroirMASMoins.on("click", function () {
      // Ferme Tiroir  Machines à sous
      closeTiroirMAS();
      window.sessionStorage.setItem("toggleMASPlusMoins", "false");
    });

    // Gestion des titoirs Menu Gauche CASINO pour Projet ATE
    // Menu Gauche CASINO : gestion Tiroir Casino en Direct
    $ateTiroirCEDPlus.on("click", function () {
      // Ouvre Tiroir CED
      openTiroirCEDATE();
      window.sessionStorage.setItem("toggleCEDPlusMoins", "true");
    });
    $ateTiroirCEDMoins.on("click", function () {
      // Ferme Tiroir CED
      closeTiroirCEDATE();
      window.sessionStorage.setItem("toggleCEDPlusMoins", "false");
    });

    // Menu Gauche CASINO : gestion Tiroir Jeux
    $ateTiroirJeuxPlus.on("click", function () {
      // Ouvre Tiroir Jeux
      openTiroirJeuxATE();
      window.sessionStorage.setItem("toggleJeuxPlusMoins", "true");
    });
    $ateTiroirJeuxMoins.on("click", function () {
      // Ferme Tiroir Jeux
      closeTiroirJeuxATE();
      window.sessionStorage.setItem("toggleJeuxPlusMoins", "false");
    });

    // Menu Gauche CASINO : gestion Tiroir Machines à sous
    $ateTiroirMASPlus.on("click", function () {
      // Ouvre Tiroir  Machines à sous
      openTiroirMASATE();
      window.sessionStorage.setItem("toggleMASPlusMoins", "true");
    });
    $ateTiroirMASMoins.on("click", function () {
      // Ferme Tiroir  Machines à sous
      closeTiroirMASATE();
      window.sessionStorage.setItem("toggleMASPlusMoins", "false");
    });

    $(".list-group-item").on("click", function (evt) {
      if (isMenuProjetATE && $(this).attr("href") && window.innerWidth < 1718) {
        closeMenuGaucheATE();
      }
    });
    $("#main-casino").on("click", function (evt) {
      if (isMenuProjetATE && window.innerWidth < 1718) {
        closeMenuGaucheATE();
      }
    });

    $window.on("resize" + eventNS + " orientationchange" + eventNS, function (e) {
      // Gestion Menu Gauche Casino (Ouvert/Ferm/) au load de la page en fonction du sessionStorage.
      var toggleMenuGaucheOuvert = window.sessionStorage.getItem("toggleMenuGaucheOuvert");
      // var isMenuGaucheOuvert = window.sessionStorage.getItem("isMenuGaucheOuvert");
      // TODO: Remplacer par une variable globale modifiable sans rebuild
      // const maxSunriseATE = 0;
      var isMenuProjetATE = false;
      // eslint-disable-next-line no-undef
      var cookieSunriseATE = EspacejeuxPortail_Cookie.lireCookie("sunriseATE");
      if (cookieSunriseATE != null) {
        var sunriseATE = Number(cookieSunriseATE);
        isMenuProjetATE = !isNaN(sunriseATE) && sunriseATE > 0;
      }

      if (isMenuProjetATE) {
        if (window.innerWidth < 1718) {
          if (window.innerWidth >= 1024) {
            closeMenuGaucheATE();
          } else {
            $("#main-casino").removeClass("inactive");
          }
        } else {
          openMenuGaucheDesktopATE();
        }
      } else {
        if (
          toggleMenuGaucheOuvert === "false" ||
          (!toggleMenuGaucheOuvert && window.innerWidth < 1400)
        ) {
          closeMenuGauche();
        } else if (
          toggleMenuGaucheOuvert === "true" ||
          (!toggleMenuGaucheOuvert && window.innerWidth >= 1400)
        ) {
          openMenuGauche();
        }
      }

      if (e.type == "resize" && $menu.data("is-mobile-open")) {
        return;
      }

      if ($menu.data("is-mobile-open")) {
        $itemsLi
          .find(liChildrenTargetSelector)
          .removeClass("fadeInExpand")
          .addClass("fadeOutCollapse")
          .css({ visibility: "hidden" });
      } else {
        closeMenu();
        setBgColorClass();
      }
    });

    var closeMenuGauche = function () {
      window.sessionStorage.setItem("toggleMenuGaucheOuvert", false);
      $("#main-casino").removeClass("ouvert");
      $("#main-casino").addClass("ferme");
      $("#menu-gauche").removeClass("menu-ouvert");
      $("#menu-gauche").addClass("menu-ferme");
      $(".item.sous-titre").addClass("visually-hidden");
      $(".item.sous-titre.ced.moins").addClass("d-none");
      $(".item.sous-titre.ced.linkMoins").addClass("d-none");
      $(".item.sous-titre.jeux.moins").addClass("d-none");
      $(".item.sous-titre.jeux.linkMoins").addClass("d-none");
      $(".item.sous-titre.mas.moins").addClass("d-none");
      $(".item.sous-titre.mas.linkMoins").addClass("d-none");
    };

    var openMenuGauche = function () {
      window.sessionStorage.setItem("toggleMenuGaucheOuvert", true);
      gestionTiroirMenuGauche();
      $("#main-casino").removeClass("ferme");
      $("#menu-gauche").removeClass("menu-ferme");
      $("#main-casino").addClass("ouvert");
      $("#menu-gauche").addClass("menu-ouvert");
      $(".item.sous-titre").removeClass("visually-hidden");
    };

    var gestionTiroirMenuGauche = function () {
      var toggleTiroirCED = window.sessionStorage.getItem("toggleCEDPlusMoins");
      var toggleTiroirJeux = window.sessionStorage.getItem("toggleJeuxPlusMoins");
      var toggleTiroirMAS = window.sessionStorage.getItem("toggleMASPlusMoins");

      if (toggleTiroirCED && toggleTiroirCED === "true") {
        openTiroirCED();
      } else {
        closeTiroirCED();
      }
      if (toggleTiroirJeux && toggleTiroirJeux === "true") {
        openTiroirJeux();
      } else {
        closeTiroirJeux();
      }
      if (toggleTiroirMAS && toggleTiroirMAS === "true") {
        openTiroirMAS();
      } else {
        closeTiroirMAS();
      }
    };

    var openTiroirCED = function () {
      // Ouvre Tiroir CED
      $(".item.sous-titre.ced.moins").removeClass("d-none");
      $(".item.sous-titre.ced.moins").removeClass("visually-hidden");
      $(".item.sous-titre.ced.linkMoins").removeClass("d-none");
      $(".item.sous-titre.ced.linkMoins").removeClass("visually-hidden");
      $(".item.sous-titre.ced.plus").addClass("d-none");
      $(".item.sous-titre.ced.plus").addClass("visually-hidden");
    };
    var closeTiroirCED = function () {
      // Ferme Tiroir CED
      $(".item.sous-titre.ced.moins").addClass("d-none");
      $(".item.sous-titre.ced.moins").addClass("visually-hidden");
      $(".item.sous-titre.ced.linkMoins").addClass("d-none");
      $(".item.sous-titre.ced.linkMoins").addClass("visually-hidden");
      $(".item.sous-titre.ced.plus").removeClass("d-none");
      $(".item.sous-titre.ced.plus").removeClass("visually-hidden");
    };

    var openTiroirJeux = function () {
      // Ouvre Tiroir Jeux
      $(".item.sous-titre.jeux.moins").removeClass("d-none");
      $(".item.sous-titre.jeux.moins").removeClass("visually-hidden");
      $(".item.sous-titre.jeux.linkMoins").removeClass("d-none");
      $(".item.sous-titre.jeux.linkMoins").removeClass("visually-hidden");
      $(".item.sous-titre.jeux.plus").addClass("d-none");
      $(".item.sous-titre.jeux.plus").addClass("visually-hidden");
    };

    var closeTiroirJeux = function () {
      // Ferme Tiroir Jeux
      $(".item.sous-titre.jeux.moins").addClass("d-none");
      $(".item.sous-titre.jeux.moins").addClass("visually-hidden");
      $(".item.sous-titre.jeux.linkMoins").addClass("d-none");
      $(".item.sous-titre.jeux.linkMoins").addClass("visually-hidden");
      $(".item.sous-titre.jeux.plus").removeClass("d-none");
      $(".item.sous-titre.jeux.plus").removeClass("visually-hidden");
    };

    var openTiroirMAS = function () {
      // Ouvre Tiroir Jeux
      $(".item.sous-titre.mas.moins").removeClass("d-none");
      $(".item.sous-titre.mas.moins").removeClass("visually-hidden");
      $(".item.sous-titre.mas.linkMoins").removeClass("d-none");
      $(".item.sous-titre.mas.linkMoins").removeClass("visually-hidden");
      $(".item.sous-titre.mas.plus").addClass("d-none");
      $(".item.sous-titre.mas.plus").addClass("visually-hidden");
    };

    var closeTiroirMAS = function () {
      // Ferme Tiroir Jeux
      $(".item.sous-titre.mas.moins").addClass("d-none");
      $(".item.sous-titre.mas.moins").addClass("visually-hidden");
      $(".item.sous-titre.mas.linkMoins").addClass("d-none");
      $(".item.sous-titre.mas.linkMoins").addClass("visually-hidden");
      $(".item.sous-titre.mas.plus").removeClass("d-none");
      $(".item.sous-titre.mas.plus").removeClass("visually-hidden");
    };

    var closeMenuGaucheATE = function () {
      window.sessionStorage.setItem("isMenuGaucheOuvert", false);
      // Masque les sous-titre du Menu de gauche
      $(".item.sous-titre-ate").addClass("visually-hidden");
      $(".item.sous-titre-ate.ced.moins").addClass("d-none");
      $(".item.sous-titre-ate.ced.linkMoins").addClass("d-none");
      $(".item.sous-titre-ate.jeux.moins").addClass("d-none");
      $(".item.sous-titre-ate.jeux.linkMoins").addClass("d-none");
      $(".item.sous-titre-ate.mas.moins").addClass("d-none");
      $(".item.sous-titre-ate.mas.linkMoins").addClass("d-none");
      // Masque les titre du Menu de gauche
      $(".item.menu-titre-ate").addClass("d-none");
      // Affiche le menu gauche fermé
      $("#main-casino").removeClass("ouvert");
      $("#menu-gauche-ate").removeClass("menu-ouvert");
      $("#main-casino").addClass("ferme");
      $("#menu-gauche-ate").addClass("menu-ferme");
      // Réactive main-casino si menu-gauche-ate est fermé
      $("#main-casino").removeClass("inactive");
    };

    var openMenuGaucheATE = function () {
      window.sessionStorage.setItem("isMenuGaucheOuvert", true);
      gestionTiroirMenuGaucheATE();
      $("#menu-gauche-ate").removeClass("menu-ferme");
      $("#main-casino").removeClass("ferme");
      $("#menu-gauche-ate").addClass("menu-ouvert");
      $("#main-casino").addClass("ouvert");
      $(".item.sous-titre-ate").removeClass("visually-hidden");
      $(".item.menu-titre-ate").removeClass("d-none");
      // Désactive main-casino si menu-gauche-ate est ouvert
      $("#main-casino").addClass("inactive");
    };

    var gestionTiroirMenuGaucheATE = function () {
      var toggleTiroirCED = window.sessionStorage.getItem("toggleCEDPlusMoins");
      var toggleTiroirJeux = window.sessionStorage.getItem("toggleJeuxPlusMoins");
      var toggleTiroirMAS = window.sessionStorage.getItem("toggleMASPlusMoins");

      if (toggleTiroirCED && toggleTiroirCED === "true") {
        openTiroirCEDATE();
      } else {
        closeTiroirCEDATE();
      }
      if (toggleTiroirJeux && toggleTiroirJeux === "true") {
        openTiroirJeuxATE();
      } else {
        closeTiroirJeuxATE();
      }
      if (toggleTiroirMAS && toggleTiroirMAS === "true") {
        openTiroirMASATE();
      } else {
        closeTiroirMASATE();
      }
    };

    var openTiroirCEDATE = function () {
      // Ouvre Tiroir CED
      $(".item.sous-titre-ate.ced.moins").removeClass("d-none");
      $(".item.sous-titre-ate.ced.moins").removeClass("visually-hidden");
      $(".item.sous-titre-ate.ced.linkMoins").removeClass("d-none");
      $(".item.sous-titre-ate.ced.linkMoins").removeClass("visually-hidden");
      $(".item.sous-titre-ate.ced.plus").addClass("d-none");
      $(".item.sous-titre-ate.ced.plus").addClass("visually-hidden");
    };
    var closeTiroirCEDATE = function () {
      // Ferme Tiroir CED
      $(".item.sous-titre-ate.ced.moins").addClass("d-none");
      $(".item.sous-titre-ate.ced.moins").addClass("visually-hidden");
      $(".item.sous-titre-ate.ced.linkMoins").addClass("d-none");
      $(".item.sous-titre-ate.ced.linkMoins").addClass("visually-hidden");
      $(".item.sous-titre-ate.ced.plus").removeClass("d-none");
      $(".item.sous-titre-ate.ced.plus").removeClass("visually-hidden");
    };

    var openTiroirJeuxATE = function () {
      // Ouvre Tiroir Jeux
      $(".item.sous-titre-ate.jeux.moins").removeClass("d-none");
      $(".item.sous-titre-ate.jeux.moins").removeClass("visually-hidden");
      $(".item.sous-titre-ate.jeux.linkMoins").removeClass("d-none");
      $(".item.sous-titre-ate.jeux.linkMoins").removeClass("visually-hidden");
      $(".item.sous-titre-ate.jeux.plus").addClass("d-none");
      $(".item.sous-titre-ate.jeux.plus").addClass("visually-hidden");
    };

    var closeTiroirJeuxATE = function () {
      // Ferme Tiroir Jeux
      $(".item.sous-titre-ate.jeux.moins").addClass("d-none");
      $(".item.sous-titre-ate.jeux.moins").addClass("visually-hidden");
      $(".item.sous-titre-ate.jeux.linkMoins").addClass("d-none");
      $(".item.sous-titre-ate.jeux.linkMoins").addClass("visually-hidden");
      $(".item.sous-titre-ate.jeux.plus").removeClass("d-none");
      $(".item.sous-titre-ate.jeux.plus").removeClass("visually-hidden");
    };

    var openTiroirMASATE = function () {
      // Ouvre Tiroir Jeux
      $(".item.sous-titre-ate.mas.moins").removeClass("d-none");
      $(".item.sous-titre-ate.mas.moins").removeClass("visually-hidden");
      $(".item.sous-titre-ate.mas.linkMoins").removeClass("d-none");
      $(".item.sous-titre-ate.mas.linkMoins").removeClass("visually-hidden");
      $(".item.sous-titre-ate.mas.plus").addClass("d-none");
      $(".item.sous-titre-ate.mas.plus").addClass("visually-hidden");
    };

    var closeTiroirMASATE = function () {
      // Ferme Tiroir Jeux
      $(".item.sous-titre-ate.mas.moins").addClass("d-none");
      $(".item.sous-titre-ate.mas.moins").addClass("visually-hidden");
      $(".item.sous-titre-ate.mas.linkMoins").addClass("d-none");
      $(".item.sous-titre-ate.mas.linkMoins").addClass("visually-hidden");
      $(".item.sous-titre-ate.mas.plus").removeClass("d-none");
      $(".item.sous-titre-ate.mas.plus").removeClass("visually-hidden");
    };

    var createAndDispatchEvent = function (eventName) {
      var event;
      if (typeof Event === "function") {
        event = new Event(eventName);
        event.initEvent(eventName, true, false);
      } else {
        /*  Fallback for Internet Explorer 11 */
        event = document.createEvent("Event");
        event.initEvent(eventName, true, true);
      }
      document.dispatchEvent(event);
    };

    $document.on("keyup", function (e) {
      if (e.keyCode === 27) {
        if ($menu.data("is-mobile-open")) {
        } else {
          closeMenu();
          setBgColorClass();
        }
      }
    });

    // Always close the menu when mouse leave the navigation

    setBgColorClass();

    $menu.data("instanceApi", {
      close: function () {
        toggleMobileMenu();
      },
    });
  });
};

const closeMenuGauche = function () {
  window.sessionStorage.setItem("toggleMenuGaucheOuvert", false);
  $("#main-casino").removeClass("ouvert");
  $("#main-casino").addClass("ferme");
  $("#menu-gauche").removeClass("menu-ouvert");
  $("#menu-gauche").addClass("menu-ferme");
  $(".item.sous-titre").addClass("visually-hidden");
  $(".item.sous-titre.ced.moins").addClass("d-none");
  $(".item.sous-titre.ced.linkMoins").addClass("d-none");
  $(".item.sous-titre.jeux.moins").addClass("d-none");
  $(".item.sous-titre.jeux.linkMoins").addClass("d-none");
};

const openMenuGauche = function () {
  window.sessionStorage.setItem("toggleMenuGaucheOuvert", true);
  gestionTiroirMenuGauche();
  $("#main-casino").removeClass("ferme");
  $("#main-casino").addClass("ouvert");
  $("#menu-gauche").removeClass("menu-ferme");
  $("#menu-gauche").addClass("menu-ouvert");
  $(".item.sous-titre").removeClass("visually-hidden");
};

const gestionTiroirMenuGauche = function () {
  const toggleTiroirCED = window.sessionStorage.getItem("toggleCEDPlusMoins");
  const toggleTiroirJeux = window.sessionStorage.getItem("toggleJeuxPlusMoins");
  const toggleTiroirMAS = window.sessionStorage.getItem("toggleMASPlusMoins");

  if (toggleTiroirCED && toggleTiroirCED === "true") {
    openTiroirCED();
  } else {
    closeTiroirCED();
  }
  if (toggleTiroirJeux && toggleTiroirJeux === "true") {
    openTiroirJeux();
  } else {
    closeTiroirJeux();
  }
  if (toggleTiroirMAS && toggleTiroirMAS === "true") {
    openTiroirMAS();
  } else {
    closeTiroirMAS();
  }
};

const initMenuGaucheCasino = function () {
  const isMenuGaucheOuvert = window.sessionStorage.getItem("isMenuGaucheOuvert");
  const toggleMenuGaucheOuvert = window.sessionStorage.getItem("toggleMenuGaucheOuvert");

  $("#main-casino").removeClass("container-fluid-casino-ate");
  $("#main-casino").addClass("container-fluid-casino");

  $("#navbar-gauche-ate").addClass("d-none");
  $("#navbar-gauche").addClass("d-lg-flex");

  $(".navbar-bas-ate").addClass("d-none");
  $(".navbar-bas").addClass("d-lg-none");
  $(".navbar-bas").removeClass("d-none");

  if (isMagnoliaEditMode()) {
    closeMenuGauche();
  } else {
    if (
      toggleMenuGaucheOuvert === "false" ||
      (!toggleMenuGaucheOuvert &&
        (!isMenuGaucheOuvert || isMenuGaucheOuvert === "false") &&
        window.innerWidth < 1400)
    ) {
      closeMenuGauche();
    } else if (
      toggleMenuGaucheOuvert === "true" ||
      (!toggleMenuGaucheOuvert &&
        (!isMenuGaucheOuvert || isMenuGaucheOuvert === "true") &&
        window.innerWidth >= 1400)
    ) {
      openMenuGauche();
    }
  }
};

const initMenuGaucheATE = function () {
  $("#main-casino").removeClass("container-fluid-casino");
  $("#main-casino").addClass("container-fluid-casino-ate");

  $("#navbar-gauche-ate").removeClass("d-none");
  $("#navbar-gauche").removeClass("d-lg-flex");

  $(".navbar-bas-ate").removeClass("d-none");
  $(".navbar-bas").removeClass("d-lg-none");
  $(".navbar-bas").addClass("d-none");

  if (isMagnoliaEditMode()) {
    closeMenuGaucheATE();
  } else {
    if (window.innerWidth >= 1718) {
      openMenuGaucheDesktopATE();
    } else {
      if (window.innerWidth >= 1024) {
        closeMenuGaucheATE();
      }
    }
  }
};

const openMenuGaucheDesktopATE = function () {
  $("#main-casino").removeClass("inactive");
  $("#main-casino").removeClass("ferme");
  $("#main-casino").addClass("ouvert");
  $("#menu-gauche-ate").removeClass("menu-ferme");
  $("#menu-gauche-ate").addClass("menu-ouvert");
  $(".item.menu-titre-ate").removeClass("d-none");
  $(".item.sous-titre-ate").removeClass("visually-hidden");
};

const closeMenuGaucheATE = function () {
  window.sessionStorage.setItem("isMenuGaucheOuvert", false);
  // Masque les sous-titre du Menu de gauche
  $(".item.sous-titre-ate").addClass("visually-hidden");
  $(".item.sous-titre-ate.ced.moins").addClass("d-none");
  $(".item.sous-titre-ate.ced.linkMoins").addClass("d-none");
  $(".item.sous-titre-ate.jeux.moins").addClass("d-none");
  $(".item.sous-titre-ate.jeux.linkMoins").addClass("d-none");
  $(".item.sous-titre-ate.mas.moins").addClass("d-none");
  $(".item.sous-titre-ate.mas.linkMoins").addClass("d-none");
  // Masque les titre du Menu de gauche
  $(".item.menu-titre-ate").addClass("d-none");
  // Affiche le menu gauche fermé
  $("#main-casino").removeClass("ouvert");
  $("#menu-gauche-ate").removeClass("menu-ouvert");
  $("#main-casino").addClass("ferme");
  $("#menu-gauche-ate").addClass("menu-ferme");
  // Réactive main-casino si menu-gauche-ate est fermé
  $("#main-casino").removeClass("inactive");
};

const closeAllMobileMenu = function () {
  $(".fn-component-nav-menu").each(function () {
    if ($(this).data().isMobileOpen) {
      $(this).data().instanceApi.close();
    }
  });
};

const openTiroirCED = function () {
  // Ouvre Tiroir CED
  $(".item.sous-titre.ced.moins").removeClass("d-none");
  $(".item.sous-titre.ced.moins").removeClass("visually-hidden");
  $(".item.sous-titre.ced.linkMoins").removeClass("d-none");
  $(".item.sous-titre.ced.linkMoins").removeClass("visually-hidden");
  $(".item.sous-titre.ced.plus").addClass("d-none");
  $(".item.sous-titre.ced.plus").addClass("visually-hidden");
};
const closeTiroirCED = function () {
  // Ferme Tiroir CED
  $(".item.sous-titre.ced.moins").addClass("d-none");
  $(".item.sous-titre.ced.moins").addClass("visually-hidden");
  $(".item.sous-titre.ced.linkMoins").addClass("d-none");
  $(".item.sous-titre.ced.linkMoins").addClass("visually-hidden");
  $(".item.sous-titre.ced.plus").removeClass("d-none");
  $(".item.sous-titre.ced.plus").removeClass("visually-hidden");
};

const openTiroirJeux = function () {
  // Ouvre Tiroir Jeux
  $(".item.sous-titre.jeux.moins").removeClass("d-none");
  $(".item.sous-titre.jeux.moins").removeClass("visually-hidden");
  $(".item.sous-titre.jeux.linkMoins").removeClass("d-none");
  $(".item.sous-titre.jeux.linkMoins").removeClass("visually-hidden");
  $(".item.sous-titre.jeux.plus").addClass("d-none");
  $(".item.sous-titre.jeux.plus").addClass("visually-hidden");
};

const closeTiroirJeux = function () {
  // Ferme Tiroir Jeux
  $(".item.sous-titre.jeux.moins").addClass("d-none");
  $(".item.sous-titre.jeux.moins").addClass("visually-hidden");
  $(".item.sous-titre.jeux.linkMoins").addClass("d-none");
  $(".item.sous-titre.jeux.linkMoins").addClass("visually-hidden");
  $(".item.sous-titre.jeux.plus").removeClass("d-none");
  $(".item.sous-titre.jeux.plus").removeClass("visually-hidden");
};

const openTiroirMAS = function () {
  // Ouvre Tiroir Jeux
  $(".item.sous-titre.mas.moins").removeClass("d-none");
  $(".item.sous-titre.mas.moins").removeClass("visually-hidden");
  $(".item.sous-titre.mas.linkMoins").removeClass("d-none");
  $(".item.sous-titre.mas.linkMoins").removeClass("visually-hidden");
  $(".item.sous-titre.mas.plus").addClass("d-none");
  $(".item.sous-titre.mas.plus").addClass("visually-hidden");
};

const closeTiroirMAS = function () {
  // Ferme Tiroir Jeux
  $(".item.sous-titre.mas.moins").addClass("d-none");
  $(".item.sous-titre.mas.moins").addClass("visually-hidden");
  $(".item.sous-titre.mas.linkMoins").addClass("d-none");
  $(".item.sous-titre.mas.linkMoins").addClass("visually-hidden");
  $(".item.sous-titre.mas.plus").removeClass("d-none");
  $(".item.sous-titre.mas.plus").removeClass("visually-hidden");
};

const openTiroirCEDATE = function () {
  // Ouvre Tiroir CED
  $(".item.sous-titre-ate.ced.moins").removeClass("d-none");
  $(".item.sous-titre-ate.ced.moins").removeClass("visually-hidden");
  $(".item.sous-titre-ate.ced.linkMoins").removeClass("d-none");
  $(".item.sous-titre-ate.ced.linkMoins").removeClass("visually-hidden");
  $(".item.sous-titre-ate.ced.plus").addClass("d-none");
  $(".item.sous-titre-ate.ced.plus").addClass("visually-hidden");
};
const closeTiroirCEDATE = function () {
  // Ferme Tiroir CED
  $(".item.sous-titre-ate.ced.moins").addClass("d-none");
  $(".item.sous-titre-ate.ced.moins").addClass("visually-hidden");
  $(".item.sous-titre-ate.ced.linkMoins").addClass("d-none");
  $(".item.sous-titre-ate.ced.linkMoins").addClass("visually-hidden");
  $(".item.sous-titre-ate.ced.plus").removeClass("d-none");
  $(".item.sous-titre-ate.ced.plus").removeClass("visually-hidden");
};

const openTiroirJeuxATE = function () {
  // Ouvre Tiroir Jeux
  $(".item.sous-titre-ate.jeux.moins").removeClass("d-none");
  $(".item.sous-titre-ate.jeux.moins").removeClass("visually-hidden");
  $(".item.sous-titre-ate.jeux.linkMoins").removeClass("d-none");
  $(".item.sous-titre-ate.jeux.linkMoins").removeClass("visually-hidden");
  $(".item.sous-titre-ate.jeux.plus").addClass("d-none");
  $(".item.sous-titre-ate.jeux.plus").addClass("visually-hidden");
};

const closeTiroirJeuxATE = function () {
  // Ferme Tiroir Jeux
  $(".item.sous-titre-ate.jeux.moins").addClass("d-none");
  $(".item.sous-titre-ate.jeux.moins").addClass("visually-hidden");
  $(".item.sous-titre-ate.jeux.linkMoins").addClass("d-none");
  $(".item.sous-titre-ate.jeux.linkMoins").addClass("visually-hidden");
  $(".item.sous-titre-ate.jeux.plus").removeClass("d-none");
  $(".item.sous-titre-ate.jeux.plus").removeClass("visually-hidden");
};

const openTiroirMASATE = function () {
  // Ouvre Tiroir Jeux
  $(".item.sous-titre-ate.mas.moins").removeClass("d-none");
  $(".item.sous-titre-ate.mas.moins").removeClass("visually-hidden");
  $(".item.sous-titre-ate.mas.linkMoins").removeClass("d-none");
  $(".item.sous-titre-ate.mas.linkMoins").removeClass("visually-hidden");
  $(".item.sous-titre-ate.mas.plus").addClass("d-none");
  $(".item.sous-titre-ate.mas.plus").addClass("visually-hidden");
};

const closeTiroirMASATE = function () {
  // Ferme Tiroir Jeux
  $(".item.sous-titre-ate.mas.moins").addClass("d-none");
  $(".item.sous-titre-ate.mas.moins").addClass("visually-hidden");
  $(".item.sous-titre-ate.mas.linkMoins").addClass("d-none");
  $(".item.sous-titre-ate.mas.linkMoins").addClass("visually-hidden");
  $(".item.sous-titre-ate.mas.plus").removeClass("d-none");
  $(".item.sous-titre-ate.mas.plus").removeClass("visually-hidden");
};

const isMagnoliaEditMode = function () {
  return document.querySelector('[href*="magnolia-overrides.css"]') !== null;
};

export { handleMainMenu, closeAllMobileMenu };
