import axios from 'axios'
import { WebServicesAccess } from '../../config';

export default {
    getJsonInterne (url) {
        return axios
        .get(WebServicesAccess.MAGNOLIA_PATH + url, {
          params: {
          }
        })
        .then(response => {
          return response.data;
        })
    },

}