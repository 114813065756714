
import { mapMutations } from 'vuex';

export default {
	data: function () {
		return {
			userMessages: {},
		}
	},

	mounted() {
		this.setContentUserMessage();
	},

	methods: {
		...mapMutations([
			'updateAccountUserMessages',
		]),

		getGlobalMessage(key) {

			let dlem = {};
			dlem.title = this.$t(key+'.titre');
			dlem.details = this.$t(key+'.detail');
			return dlem;

		},


		setContentUserMessage(fct) {
			const vm = this;
			let headers = new Headers();			
			headers.append('cache-control', 'max-age=36000');
			fetch( `/json-cache/content/messages?lang=${vm.mixinLangue_langue}&pattern=MSG&uuid=${vm.uuid}`, {
				method: 'GET',
				headers: headers,
			}).then(function( response ){
				return response.json();
			}).then(function( data ){
				data.MSG01 = vm.getGlobalMessage('monCompte.erreur.MSG01');	
				data.MSG02 = vm.getGlobalMessage('monCompte.erreur.MSG02');	
				data.MSG03 = vm.getGlobalMessage('monCompte.erreur.MSG03');									
				vm.updateAccountUserMessages( data );
				if(fct !== undefined){
					fct();
				}
			}).catch(function( error ) {
				console.log( 'Error setContentUserMessage:' + error );
			});
		}
	}
}