$.fn.replaceWithPush = function(a) {
    var $a = $(a);
    this.replaceWith($a);
    return $a;
};
$.fn.premiereVue = function () {
    return this.each(function () {
        var thisElem$ = $(this);
        var body$ = $('body');
        var premiereVueData = body$.data('premiereVue');

        function bindScroll() {
            var collection = $('body').data().premiereVue.elemsToWatch;
            var remCollections = [];
            if (collection.length) {
                $(collection).each(function () {
                    if($(collection[0]).offset().top + $(collection[0]).height() - $(window).height() - $(window).scrollTop() <= 0){
                        localStorage.setItem($(this).attr('watch'), true);
                    }
                });
            }
            $(window).one('scroll', function () {
                setTimeout(bindScroll, 1000);
            })
        }
        if (!premiereVueData) {
            body$.data('premiereVue', {
                elemsToWatch: []
            });

            setTimeout(bindScroll, 2000);
        }
        body$.data().premiereVue.elemsToWatch.push(thisElem$);
    });
}

$(document).ready(function () {
    $('[watch]').premiereVue();
});