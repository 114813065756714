(function( $ ) {
	$.fn.addJeuxRecentsToLocalStorage = function(idJeu) {
		var maxLength = 20;
		var arrRecents = JSON.parse(localStorage.getItem("EJRecents"));
		
	    if (arrRecents instanceof Array) {
	        var idx = arrRecents.indexOf(idJeu);
	        
	        if (idx == -1) {
	        	arrRecents.unshift(idJeu);
	        } else {
	        	arrRecents.unshift(arrRecents.splice(idx, 1)[0]);
	        }

	        if (arrRecents.length > maxLength) {
	        	arrRecents = arrRecents.slice(0, maxLength);
	        }
	    	    
	    } else {
	    	arrRecents = [idJeu];
	    }

	    localStorage.setItem("EJRecents", JSON.stringify(arrRecents));
	    
	    if (window.location.hostname.split('.')[0] !== 'm') {
	    	sendLocalStorageToIframes('EJRecents', JSON.stringify(arrRecents));
	    }
	};
	
	function sendLocalStorageToIframes(key, value) {
		const firstDomain = document.getElementById('iframe-domain-1');
      	firstDomain.contentWindow.postMessage({
            action: 'save',
            key: key,
            value: value
      	}, $('#iframe-domain-1').attr('src'));
      
      	const secondDomain = document.getElementById('iframe-domain-2');
      	secondDomain.contentWindow.postMessage({
            action: 'save',
            key: key,
            value: value
      	}, $('#iframe-domain-2').attr('src'));
	}
	
}( jQuery ));
