// Originally in ej-commmun-portail.js

/***********************************************/
/*** OBJECT EspacejeuxPortail_BarrePortail  ***/
/*********************************************/

/* NAME : EspacejeuxPortail_BarrePortail
 * PARM : no
 *
 * DESC : Object for BarrePortail
 */

function EspacejeuxPortail_BarrePortail_def() {

	this.init = function () {
		//cette fonction sert a initiliser le comportement des banniers selon le type du client
		if (ejApi.sessionEstOuverte()) {



			if (typeof initPastilleLotprogressif === "function") {
				initPastilleLotprogressif(EspacejeuxPortail.LANGUE);
			}

		}

	};
}
const EspacejeuxPortail_BarrePortail = new EspacejeuxPortail_BarrePortail_def();

export default EspacejeuxPortail_BarrePortail;