import Vue from 'vue';
import DetectBrowser from '../js/components/detectBrowser';

Vue.prototype.$bus = new Vue({
	computed:{
		flashSupported: function(){
			if ('undefined' !== typeof swfobject && !swfobject.hasFlashPlayerVersion('10.2.152.26')) {
				return false;
			} else {
				return true;
			}
		},
	},
});

Vue.prototype.$isDomainMobile = false;

document.addEventListener('detectBrowser_is-initialized', function(){
	Vue.prototype.$isDomainMobile = DetectBrowser.isDomainMobile;
});

import './app/app';