window.iframeAnimation = function(event, classActive) {

    if( event.target.querySelector( `.${classActive}` ) === null ) {
        return;
    }

    const target = event.target.querySelector( `.${classActive}` ).querySelector( '[data-src]' );

    if( target !== null ) {
        const targetSrc = target.getAttribute( 'data-src' );
        target.setAttribute( 'src', targetSrc );
    }
};