var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col-12 col-lg-12",
      class: { "filtres--mobile": _vm.isDomainMobile },
      attrs: { id: "filtres" },
    },
    [
      _c("div", { attrs: { id: "boite-recherche" } }, [
        _c("div", { staticClass: "search-wrapper" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.recherche,
                expression: "recherche",
              },
            ],
            staticClass: "search",
            attrs: {
              id: "search",
              type: "text",
              autocomplete: "off",
              "data-list": ".liste-jeux",
              name: "search",
              placeholder: _vm.$t("recherche"),
              value: "",
            },
            domProps: { value: _vm.recherche },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.recherche = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "fright icomoon icon-search" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-down" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filters-list-wrapper" },
        [
          _vm._t("categories", null, {
            filtres: _vm.filtres,
            changeFiltres: _vm.changeFiltres,
            effacerFiltres: _vm.effacerFiltres,
          }),
          _vm._v(" "),
          _vm._t("type", null, {
            filtres: _vm.filtres,
            changeFiltres: _vm.changeFiltres,
            effacerFiltres: _vm.effacerFiltres,
          }),
          _vm._v(" "),
          _vm._t("nbligne", null, {
            filtres: _vm.filtres,
            changeFiltres: _vm.changeFiltres,
            effacerFiltres: _vm.effacerFiltres,
          }),
          _vm._v(" "),
          _vm._t("mntgroslot", null, {
            filtres: _vm.filtres,
            changeFiltres: _vm.changeFiltres,
            effacerFiltres: _vm.effacerFiltres,
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }