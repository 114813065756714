<template>
  <div :data-component-name="$options.name" class="">
    <div  class="w-100 h-100 div_general">
      <b-row class="w-100 h-100 d-flex align-items-center">
        <b-col  class=" col-12 col-md-4">
          <div v-if="contenu.petiteNote" class="boite_petite_note">
            <span class="petite_note" v-html="contenu.petiteNote">

            </span> 
          </div>
          <div v-if="contenu.surTitre"  v-html="contenu.surTitre" >

          </div>
          <div class="d-flex justify-content-center">
            <img v-lazy="getImageSrcVariation(getImageByScreenSize(contenu))" class="w-100 h-100 drop-shadow" alt="" />
          </div>
        </b-col> 
        <b-col  class="d-flex flex-column align-items-start col-12 col-md-8">
        <div>
          <h3 class="titre graphik_bold" v-html="contenu.titre">

          </h3>
        </div>
        <div class="sousTitre" v-html="contenu.sousTitre">

        </div>
        <div class="promo_texte" v-html="contenu.texte"> </div>
        <div class="d-flex w-100 justify-content-center justify-content-md-start">
          <a v-if="getMediaImageUrl(contenu)" :href="getMediaImageUrl(contenu)">
            <button class="btn graphik_bold btn_bingo_theme disableThemeHover" :style="[{'background-color':contenu.colorBtn},{'border-color':contenu.borderColor}]"> 
              {{contenu.texteBouton}} 
            </button> 
          </a>
        </div>
      </b-col>
      </b-row>
    </div>

    
  </div>
</template>

<script>
export default {
  name: "MediaImageGagnantPromo",
  data() {
    return {
    };
  },
  props: ["contenu"],
  methods: {},
  computed: {
    classObject: function () {
      return {
        rounded: this.contenu.coinArrondi
      }
    },
    styleObject: function () {
      return {
        'background-color': this.contenu.secBgThemeColor
      }
    },
  },
  created: function() {}
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px !important;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}
.div_general{
display:flex; 
flex-direction: column;
align-items: flex-start; 
}

.petite_note{
  @include ms-respond(font-size, 1);
  color:white;
  font-weight: bold;
  line-height: 1;
  padding: 1.5rem;
}

.boite_petite_note{
background-color:rgba(0,0,0,.65);; 
width:fit-content; 
border-radius: ms(-1);
display:flex; 
justify-content: center;
align-items:center;
margin-left:-4%;
margin-top: 3%;
}

.titre{  
  @include ms-respond(font-size, 1);
  color: black; 
  margin:0;

  @include media-breakpoint-down(sm)  { 
    padding-top: 0.75rem;
  }
}

.btn{
  margin-bottom:12%;
  text-transform: uppercase; 
  color: black;
  @include ms-respond(font-size, -1);

  @include media-breakpoint-down(sm)  { 
      padding-top: 0.5rem ;
      padding-bottom: 0.5rem ;
      padding-right: 1.25rem ;
      padding-left: 1.25rem ;
      border-radius: 20px;
  }
}


.sousTitre{
  color:black; 
  @include ms-respond(font-size, 0);
  margin:0;
  margin-top: 3%;

  @include media-breakpoint-down(sm)  { 
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

.promo_texte{
  @include ms-respond(font-size, 0);
}

.h-380px{
  height: 380px !important;
  @include media-breakpoint-down(sm)  { 
    height: 320px !important;
  }
}

.barreDessousMobile{
  background-color: #efefef; 
  height: 30%;
  @include media-breakpoint-down(sm)  { 
    height: 35%;
  }
}

.hauteurImageMobile{
  height: 70%;
  @include media-breakpoint-down(sm)  { 
    height: 65%;
  }
}
</style>