//Utilitaire de manipulation object json
const isEmptyJsonObj = {
  methods: {
    isEmptyJson: function(obj) {
      return null === obj || Object.keys(obj).length === 0;
    }
  }
};

const isEqualObj = {
  methods: {
    isDeepEqualObj: function(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects =
          val1 != null && typeof val1 === "object" && val2 != null && typeof val2 === "object";
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
          return false;
        }
      }
      return true;
    }
  }
};

const urlParamsValues = {
  methods: {
    getUrlParameter: function(name) {
      const queryString = window.location.search;
      if ("URLSearchParams" in window) {
        const urlParams = new URLSearchParams(queryString);
        let exist = urlParams && urlParams.has(name);
        return exist ? urlParams.get(name) : null;
      } else {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        let results = regex.exec(queryString);
        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
      }
    },
    addParamsToLocalUri: function(paramName, paramValue) {
      let queryStr = window.location.href.split("?")[1];
      let hash = window.location.hash ? window.location.hash : "";

      let uri =
        window.location.protocol + "//" + window.location.hostname + window.location.pathname;
      if (queryStr !== undefined) {
        queryStr = hash ? queryStr.split(hash)[0] : queryStr;
        uri += "?" + queryStr + "&" + paramName + "=" + paramValue + hash;
      } else {
        uri += "?" + paramName + "=" + paramValue + hash;
      }
      return uri;
    },
    appendObjParamsToUri: function(uri, obj) {
      let queryStr = window.location.href.split("?")[1];
      let hash = window.location.hash ? window.location.hash : "";

      if (queryStr !== undefined) {
        queryStr = hash ? queryStr.split(hash)[0] : queryStr;
        uri += "?" + queryStr + (obj ? "&" + encodeURI(obj) : "") + hash;
      } else {
        uri += (obj ? "?" + encodeURI(obj) : "") + hash;
      }
      return uri;
    },
    removeParams: function(sourceURL, keys) {
      var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          for (var j = keys.length - 1; j >= 0; j -= 1) {
            if (param === keys[j]) {
              params_arr.splice(i, 1);
            }
          }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
      }
      return rtn;
    }
  }
};

const formatCurrency = {
  methods: {
    formatMontant: function(str) {
      if (typeof str === "undefined") {
        return;
      }
      let montant = str;
      if (typeof str === "string") {
        str = str.replace(",", ".");
        str = str.replace(/[^0-9\\.]/gi, "");
        montant = Number(str);
      }
      let lang = this.mixinLangue_langue == "en" ? "en" : "fr";
      return montant.toLocaleString(lang + "-CA", {
        style: "currency",
        currency: "CAD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  }
};

const protectedCTA = {
  methods: {
    isAuthorisedCTA: function() {
      document.querySelectorAll(".js-account-session button").forEach((item) => {
        item.addEventListener("click", (event) => {
          if (!ejApi.sessionEstOuverte()) {
            event.preventDefault();
            this.returnToHome();
          }
        });
      });
    }
  }
};

const protectedPage = {
  methods: {
    returnToHome: function(path) {
      if (path) window.location.href = path;
      //TODO variable global
      else window.location.href = this.mixinLangue_langue === "en" ? "/en/home" : "/fr/accueil";
    }
  }
};

const detectVbrowser = {
  methods: {
    isIE11: function() {
      return !!window.MSInputMethodContext && !!document.documentMode;
    },
    isSafari: function() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        return ua.indexOf("chrome") <= 0;
      } else {
        return false;
      }
    }
  }
};

export {
  isEmptyJsonObj,
  urlParamsValues,
  formatCurrency,
  protectedCTA,
  protectedPage,
  isEqualObj,
  detectVbrowser
};
