import { render, staticRenderFns } from "./MediaImageImg60txt40.vue?vue&type=template&id=052b3bd7&scoped=true"
import script from "./MediaImageImg60txt40.vue?vue&type=script&lang=js"
export * from "./MediaImageImg60txt40.vue?vue&type=script&lang=js"
import style0 from "./MediaImageImg60txt40.vue?vue&type=style&index=0&id=052b3bd7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "052b3bd7",
  null
  
)

export default component.exports