var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "div_container",
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _c(
        "div",
        { staticClass: "titre" },
        [
          _c("b-card-img-lazy", {
            staticClass: "image",
            attrs: {
              src: _vm.getImageSrcVariation(
                _vm.getImageByScreenSize(_vm.contenu)
              ),
              alt: _vm.contenu.title,
            },
          }),
          _vm._v("\n    " + _vm._s(_vm.contenu.title) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.contenu.texte) } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }