// Originally in ej-commmun-portail.js

import EspacejeuxPortail_Dialogue from './espacejeux-portail-dialogue';

function getChromeVersion() {
	var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
	return raw ? parseInt(raw[2], 10) : false;
}

/****************************************/
/*** OBJECT EspacejeuxPortail_Flash  ***/
/**************************************/

/* NAME : EspacejeuxPortail_Flash
 * PARM : no
 *
 * DESC : Object for Flash
 */

function EspacejeuxPortail_Flash_def() {

	this.VERSION_MINIMALE_FLASH = "10.0.0"; //9.0.0
	this.VERSION_EXCEPTION_TUTORIEL_FLASH = "11.1.102";
	this.VERSION_MINIMALE_FLASH_FOR_GAMES = {
		"HiLoBonusTrailSL": "10.2.152.26"
	};

	this.init = function () {

		/* Flash Object Calculatrice */
		this.calculatrice = {};

		this.calculatrice.config = {
			lienSWF: '',
			containerId: 'calculatrice',
			width: 304,
			height: 253
		};
		this.calculatrice.flashvars = {
		};
		this.calculatrice.params = {
			quality: 'high',
			play: true,
			loop: true,
			scale: 'showall',
			wmode: 'transparent',
			devicefont: 'false',
			bgcolor: '#000000',
			menu: true,
			allowFullScreen: false,
			allowScriptAccess: 'sameDomain'
		};
		this.calculatrice.attributes = {
			id: 'swfCalculatrice',
			name: 'swfCalculatrice'
		};

		/* Flash Object promoTutoriel */
		this.promoTutoriel = {};

		this.promoTutoriel.config = {
			lienSWF: '',
			containerId: 'tutorielEspacejeux',
			width: 300,
			height: 250
		};
		this.promoTutoriel.flashvars = {
		};
		this.promoTutoriel.params = {
			quality: 'high',
			play: true,
			loop: true,
			scale: 'showall',
			wmode: 'transparent',
			devicefont: 'false',
			bgcolor: '#000000',
			menu: true,
			allowFullScreen: false,
			allowScriptAccess: 'sameDomain'
		};
		this.promoTutoriel.attributes = {
			id: 'swfTutorielEspacejeux',
			name: 'swfTutorielEspacejeux'
		};
		this.nav = {};
		this.nav.flashvars = {
			section: ""
		};
	};

	this.ecrire = function (obj) {
		// if swfobject exist
		if ('undefined' !== typeof swfobject) {
			swfobject.embedSWF(obj.config.lienSWF, obj.config.containerId, obj.config.width, obj.config.height, this.VERSION_MINIMALE_FLASH, false, obj.flashvars, obj.params, obj.attributes);
		}
	};

	this.getFlashVersionForGame = function (gameName) {
		var r = true;
		if (this.VERSION_MINIMALE_FLASH_FOR_GAMES[gameName]) {
			if (!swfobject.hasFlashPlayerVersion(this.VERSION_MINIMALE_FLASH_FOR_GAMES[gameName])) {
				Espacejeux_AfficherMessageErreurFlash(this.VERSION_MINIMALE_FLASH_FOR_GAMES[gameName]);
				r = false;
			}
		}
		else if (!swfobject.hasFlashPlayerVersion(this.VERSION_MINIMALE_FLASH)) {
			Espacejeux_AfficherMessageErreurFlash(this.VERSION_MINIMALE_FLASH);
			r = false;
		}
		return r;
	};

	this.afficherMessageErreurFlash = function (ver) {
		var text = [];

		var chromeVersion = getChromeVersion();

		var isChrome = (chromeVersion != false && chromeVersion > 54);

		if (EspacejeuxPortail_Langue.pageEnAnglais()) {
			text['title'] = 'Update your Flash Player';
			text['text'] = '<p class="msgFlashVersionForGame">Get the most out of lotoquebec.com by downloading the latest version* of <a class="lienBleu" href="http://www.adobe.com/go/getflashplayer" target="_blank">Adobe Flash Player&reg;</a>.</p><p><a class="lienBleu" href="http://www.adobe.com/go/getflashplayer" target="_blank"><img width="160" height="41" alt="Get Adobe Shockwave Player web Logo" src="/dam/espacejeux/m4/resources/images/ui/160x41_get_flashplayer.gif" border="0"></a></p><p class="petitTexte10">*&#160;Earliest version supported&#160;: <span id="numeroVersionMinimaleFlash">' + ver + '</span></p>';
			if (isChrome) {
				text['text'] += '<br><p class="msgFlashVersionForGame">If the plugin is already installed <a class="lienBleu" href="https://helpx.adobe.com/flash-player/kb/enabling-flash-player-chrome.html" target="_blank">activate it</a>.</p>';
			}
		} else {
			text['title'] = 'Mise &agrave; jour version Flash';
			text['text'] = '<p class="msgFlashVersionForGame">Pour profiter pleinement du site lotoquebec.com, installez la version la plus r&eacute;cente* du plugiciel <a class="lienBleu" href="http://get.adobe.com/fr/flashplayer/" target="_blank">Adobe Flash Player&reg;</a>.</p><p><a class="lienBleu" href="http://www.adobe.com/go/getflashplayer" target="_blank"><img src="/dam/espacejeux/m4/resources/images/ui/160x41_get_flashplayer.gif" width="160" height="41" alt="T&eacute;l&eacute;charger Adobe Flash Player" border="0" /></a></p><p class="petitTexte10">*&#160;version minimale support&eacute;e&#160;: <span id="numeroVersionMinimaleFlash">' + ver + '</span></p>';
			if (isChrome) {
				text['text'] += '<br><p class="msgFlashVersionForGame">Si le plugiciel est déjà installé <a class="lienBleu" href="https://helpx.adobe.com/fr/flash-player/kb/enable-system-flash-player-google-chrome.html" target="_blank">activez-le</a>.</p>';
			}
		}

		EspacejeuxPortail_Dialogue.afficher({
			optionsEJ: {
				type: "",
				title: text['title'],
				innerHTML: text['text']
			},
			optionsJQueryUI: {
				width: 600,
				modal: true,
				resizable: false,
				draggable: true
			}
		});
	};

}
window.EspacejeuxPortail_Flash = new EspacejeuxPortail_Flash_def();

EspacejeuxPortail_Flash.init();