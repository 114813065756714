// Originally in ej-commmun-portail.js



const CarouselSlick = function() {

    var carroussel$ = $('.ejCarrouselBannieres');
    
    carroussel$.on('init', function(event){

        $(this).css({
            display: 'block',
            visibility: 'visible'
        });

        iframeAnimation(event,'slick-active');
    });

    carroussel$.slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        lazyload: 'progressive'
    });

    carroussel$.on('afterChange', function(event){
        iframeAnimation(event,'slick-active');
    });
    
    carroussel$.slick('slickUnfilter');
    carroussel$.slick('slickFilter',  function(){
	var audienceCl = (typeof ejConnect === 'undefined') ? 'anon' : ejConnect.etatClient;
		if(audienceCl=='anon'){
			audienceCl = localStorage.getItem("audienceClient");
		}
		if(audienceCl=="anon")  {
			return ( $(this)).hasClass(ejConnect.etatClient);
		}else
		{
			return ( $(this)).hasClass(audienceCl);
		}
    });

    
    var marginLeft = carroussel$.width()/2 - $(".slick-dots").width()/2;
    $(".slick-dots").css("left", marginLeft);

    carroussel$.on('mouseleave', function(){
        carroussel$.slick('slickPlay');
    });
}

const CarouselSlickInit = () => {
    if($(".ejCarrouselBannieres").length){
        let intervalSlick = setInterval( () => {
            if(typeof $.fn.slick !== "undefined" && typeof ejConnect !== "undefined"){
                CarouselSlick();
                clearInterval(intervalSlick);
            }
        }, 100);
    }
}

export default CarouselSlickInit;