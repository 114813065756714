"use strict";
/* ************************************************************************************ */
/* ************************************************************************************ */
// -------------------------------------------------------------------------------------------------------
// Objet utilitaire
// -------------------------------------------------------------------------------------------------------
function EspacejeuxPortail_Util_def() {

    this.getURLParam = function(name) {
        name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
        var regexS = "[\\?&][a-zA-z0-9]*"+name+"[a-zA-z0-9]*=([^&#]*)";
        var regex = new RegExp(regexS);
        var hrefLowerCase = (window.location.href).toLowerCase();
        var results = regex.exec(hrefLowerCase);
        if (results == null) {
            return null;
        } else {
            return results[1];
        }
    }

    this.getUrlVars = function() {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    this.getUrlVar = function(name) {
        return this.getUrlVars()[name];
    }

    this.getUrlFullParamsString = function() {
        var fullParamsString = null;
        var href = window.location.href;
        var idx = href.indexOf('?');
        var paramsStringLength = href.substring(idx).length;
        if ((idx >= 0) && (paramsStringLength > 1))  {
            fullParamsString = href.substring(idx+1);
        }
        return fullParamsString;
    }
}
var EspacejeuxPortail_Util = new EspacejeuxPortail_Util_def();    
// -------------------------------------------------------------------------------------------------------

//Fonction vide pour fin de compatibilité avec les appels de fourniseur comme Openbet
window.custom_trackEvent = function (category, action, label, value){}

//Fonction vide pour fin de compatibilité avec les appels de fourniseur comme Openbet
function custom_trackPageview(pagename){}


//var utm_gametype = '<'' ou poker ou blackjack ou roulette ou tablegames ou ezgames>';
// lorsque document.tabActive est défini, nous pourrions réutiliser cette valeur, sinon mettre ''.

window._gaq = window._gaq || [];

function EspacejeuxPortail_GoogleAnalytics_def() {
    //Fonction compatible avec les appels de fourniseur comme Openbet   
    this.enregistrerVisite = function(urlVirtuelGoogleAnalytics) {};
    this.enregistrerEvenement = function (category, action, label, value){};

    //-------------------------------------------------------------------------
    //Tracking des events sur banner-item et background
    //
    //Fonction compatible avec les appels de fourniseur comme Openbet
    this.push_gaData = function (trackedElement) { };
    

}

window.EspacejeuxPortail_GoogleAnalytics = new EspacejeuxPortail_GoogleAnalytics_def();


