var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cedTablePremiereOuverte" },
    [
      _vm.idTable
        ? _c("ced-table", {
            attrs: {
              famille: _vm.familleJeu,
              "id-table-app": _vm.idTable,
              "is-table-premiere-ouverte": true,
              "table-live-premiere-ouverte": _vm.tableLive,
              "table-live-info-premiere-ouverte": _vm.tableLiveInfo,
              appareil: _vm.appareil,
              "prefixe-url": _vm.prefixeUrl,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }