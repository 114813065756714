import Vue from 'vue';
import VueRouter from 'vue-router';

import Page from '../views/Page.vue';
import PageErreur from "../views/PageErreur.vue";

Vue.use(VueRouter)

const routes = [

  {
    path: "/(magnoliaAuthor|magnoliaPublic)/espacejeux/:langue(fr|en)/:chemin(.*)\.html",
    redirect: "/:langue/:chemin"
  },
  {
    path: "/(magnoliaAuthor|magnoliaPublic)/espacejeux/:chemin(.*)\.html",
    redirect: "/fr/:chemin"
  },


  {
    path: "/headless/err/:code.html", // utile quand magnolia ne répond pas
    name: "/headless1",
    props: true,
    component: PageErreur
  },
  {
    path: "/headless/:langue/:chemin(.*)",
    name: "/headless2",
    props: true,
    component: Page
  },
  {
    path: "/:langue(fr|en)/:chemin(.*)",
    name: "/magnolia1",
    props: true,
    component: Page
  },
  {
    path: "/:chemin(.*)", // utile pour attrapper les 404
    name: "/magnolia2",
    props: true,
    component: Page
  },


  // catch all redirects
  {
    path: '*',
    redirect: to => {
      return { name: window.location.href.substring(window.location.href.lastIndexOf('/') + 1).replace('.html','') }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    // hack to allow for forward slashes in path ids
    if (to.fullPath.includes('%2F')) {
        next(to.fullPath.replace('%2F', '/'));
    }
    next();
});

export default router
