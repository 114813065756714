var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "steps-form" }, [
    _c("span", { staticClass: "steps-form__summary" }, [
      _c("span", { staticClass: "steps-form__step-label" }, [
        _vm._v(
          "Étapes " +
            _vm._s(_vm.steps.activeIndex) +
            " de " +
            _vm._s(_vm.steps.total)
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "steps-form__triangle" }),
    ]),
    _vm._v(" "),
    _c("ol", { staticClass: "steps-form__steps" }, [
      [_vm.CS_TYPE_PREMIER_DEPOT, _vm.CS_TYPE_IDENTITE].includes(_vm.type)
        ? _c("li", { staticClass: "steps-form__step" }, [
            _c("span", { staticClass: "steps-form__step-label" }, [
              _c("span", { staticClass: "steps-form__step-number" }, [
                _vm._v("1"),
              ]),
              _vm._v(
                " \n\t\t\t\t" +
                  _vm._s(_vm.$t("monCompte.situationProfessionnelle")) +
                  "\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "steps-form__triangle" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      [_vm.CS_TYPE_PREMIER_DEPOT, _vm.CS_TYPE_IDENTITE].includes(_vm.type)
        ? _c(
            "li",
            {
              class:
                "steps-form__step" +
                (_vm.type === "Identite" ? " is_active" : ""),
            },
            [
              _c("span", { staticClass: "steps-form__step-label" }, [
                _c("span", { staticClass: "steps-form__step-number" }, [
                  _vm._v("2"),
                ]),
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.$t("monCompte.verificationIdentite")) +
                    "\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "steps-form__triangle" }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      [_vm.CS_TYPE_PREMIER_DEPOT, _vm.CS_TYPE_IDENTITE].includes(_vm.type)
        ? _c("li", { staticClass: "steps-form__step" }, [
            _c("span", { staticClass: "steps-form__step-label" }, [
              _c("span", { staticClass: "steps-form__step-number" }, [
                _vm._v("3"),
              ]),
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("monCompte.encadrerMonJeu")) +
                  "\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "steps-form__triangle" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      [
        _vm.CS_TYPE_PREMIER_DEPOT,
        _vm.CS_TYPE_DEPOT,
        _vm.CS_TYPE_IDENTITE,
      ].includes(_vm.type)
        ? _c(
            "li",
            {
              class:
                "steps-form__step" +
                (_vm.type != "Identite" ? " is_active" : ""),
            },
            [
              _c("span", { staticClass: "steps-form__step-label" }, [
                _c("span", { staticClass: "steps-form__step-number" }, [
                  _vm._v("4"),
                ]),
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.$t("monCompte.depotDansLeCompte")) +
                    "\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "steps-form__triangle" }),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }