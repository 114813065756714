<template>
    <div class="recent-numbers-container" data-role="roulette-statistics-container">
        <div class="box" :result="result" :key="index" v-for="(result, index) in results">
            <div v-if="isCaseDouble(result)" :class="getClass(result)">
                <div :class="getCouleur(splitValeur(result)[0])" >{{ splitValeur(result)[0] }}</div>
                <div v-if="!isJackpot(result)" :class="getCouleur(splitValeur(result)[1])" >{{ splitValeur(result)[1] }}</div>
            </div>
            <div v-else class="game-result" :class="[getCouleur(result),{'lucky':getLuckyNumber(index)}]" >
            	{{ result }}
            	<div v-if="getLuckyNumber(index)" class="multiplier" data-role="multiplier">{{ getLuckyNumber(index) }}x</div>
            </div>
        </div>
    </div>

</template>

<script>
export default {

	props : {
		results : {
			type : Array,
			required : true
		},
		luckyNumbers : {
			type : Array,
			required : false
		},
		appareil : {
			type : String,
			required : true
		},
		prefixeUrl : {
			type : String,
			required : true
		},
	},

	data : function() {
		return {
			isJeuLoaded : false,
		}
	},

	computed:{
		CHAR_SEPARATION: () => '|',
	},
	
	methods: {
		// Split la valeur
		splitValeur: function(valeur) {
			return valeur.split(this.CHAR_SEPARATION);
		},

		// Vérifie si c'est une valeur de roulette double
		isCaseDouble: function(valeur) {
			return valeur.indexOf(this.CHAR_SEPARATION) != -1;
		},

		// Vérifie si le résultat est un jackpot (deux valeurs égales)
		isJackpot: function(valeur) {
			
			var valeurs = valeur.split(this.CHAR_SEPARATION);

			return valeurs[0] == valeurs[1];
		},

		getLuckyNumber: function(index) {
			var foundLuckyNumber = null;
			
			if (this.luckyNumbers) {
				foundLuckyNumber = this.luckyNumbers.find((luckyNumber) => luckyNumber.index == index);
			}
			return foundLuckyNumber ? foundLuckyNumber.luckyNumber : null;
		},


		// Retourne la classe d'un résultat
		getClass: function(valeur){

			if (this.isCaseDouble(valeur)) {

				var classe = "double-ball-result ";

				if(this.isJackpot(valeur)){
					return classe + "jackpot";
				}
				return classe;
			}
			return "game-result";
		},

		/*
		 * Retourne la couleur du nombre sur une table donnee 
		 * nombre : Un nombre dont on cherche sa couleur sur la roulette
		 */
		getCouleur: function(nombre) {
			const COULEUR_VERT = 'green';
			const COULEUR_ROUGE = 'red';
			const COULEUR_NOIR = 'black';

			/* Explication de nombreRouge
			 * Les numéros dont la somme des chiffres est paire sont noirs, 
			 * sauf que le 10 (1 + 0 = 1, impair) et le 29 (2 + 9 = 11).
			 * Les numéros dont la somme des chiffres est impaire sont rouges, 
			 * sauf que le 19 (1 + 9 = 10, pair).
			 * Donc, implémente l'algo pour les rouges.
			 */
			const nombreRouge = [ 1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23,
				25, 27, 30, 32, 34, 36 ];

			// Variable de retour
			var couleur = COULEUR_NOIR;

			if (nombre == 0) {
				couleur = COULEUR_VERT;
			} else {
				// Fix pour la comparaison de meme type
				nombre = parseInt(nombre);

				if (nombreRouge.includes(nombre)) {
					couleur = COULEUR_ROUGE;
				}
			}

			return couleur;
		},
	},
}
</script>