var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "form-televersement pl-sm-3",
      class: _vm.classes,
      attrs: {
        action: "/services/upload-doc",
        enctype: "multipart/form-data",
        "data-component": "files-sender",
        "data-files-count": _vm.filesCount,
      },
      on: { submit: _vm.sendFiles },
    },
    [
      _c("televersementErrors", {
        attrs: { error: _vm.error, fileTypesString: _vm.fileTypesString },
      }),
      _vm._v(" "),
      _vm._t("form", null, {
        error: _vm.error,
        filesCount: _vm.filesCount,
        filesMin: _vm.filesMin,
        filesMax: _vm.filesMax,
        addFile: _vm.addFile,
        resetForm: _vm.resetForm,
        fileTypesString: _vm.fileTypesString,
        siteId: _vm.siteId,
        bankAccount: _vm.bankAccount,
        identityByCCAuthorised: _vm.identityByCCAuthorised,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }