(function ($) {
    $.fn.inlineMediaQuery = function (options) {

        var settings = $.extend({
            sm: 768,
            md: 992,
            lg: 1200
        }, options);

        if(!$('#inlineMediaQuery').length){
            $('<style type="text/css"/>')
                .appendTo('head')
                .attr('id','inlineMediaQuery');
        }

        var style = $('#inlineMediaQuery').text();

        function uid() {
            if($('body').data('styleID')){
                $('body').data().styleID = $('body').data('styleID')+1
            } else {
                $('body').data().styleID = 1;
            }
            return 'styleID-'+ $('body').data('styleID');
        }

        return this.each(function () {

            var styleId = uid();
            $(this).attr('id', styleId);

            var style = $('#inlineMediaQuery').text();
            var mQueryXs = $(this).attr('m-query-xs');
            var mQuerySm = $(this).attr('m-query-sm');
            var mQueryMd = $(this).attr('m-query-md');
            var mQueryLg = $(this).attr('m-query-lg');

            if(mQueryXs){
                style += "#"+styleId+"{"+ mQueryXs +"}"
            }

            if(mQuerySm){
                var qStyle = "#"+styleId+" { "+ mQuerySm +" } ";
                style += " @media screen and (min-width: "+settings.sm+"px){ "+ qStyle +" }"
            }

            if(mQueryMd){
                var qStyle = "#"+styleId+" { "+ mQueryMd +" } ";
                style += " @media screen and (min-width: "+settings.md+"px){ "+ qStyle +" }"
            }

            if(mQueryLg){
                var qStyle = "#"+styleId+" { "+ mQueryLg +" } ";
                style += " @media screen and (min-width: "+settings.lg+"px){ "+ qStyle +" }"
            }
            $('#inlineMediaQuery').text(style);
        });
    }
})(jQuery);

(function () {
    $(document).ready(function () {
        var elems$ = $('[m-query]');
        if(elems$.length){
            elems$.inlineMediaQuery();
        }
    });
})();

