const Lang = document.querySelector( 'html' ).getAttribute( 'lang' );

/**
 * Take an element object.data() and try to map properties with a base object.
 * This will not extend the base object but return a new object with only matching properties.
 * !!! WARNING !!! data convert camelCase attribute to lower case and hyphen attribute to camelCase.
 * This mean data-myProperty is convert to "myproperty" in data object and "my-property" is convert to "myProperty" in data object.
 * @param objData data set ojbect return by $jQueryElement.data()
 * @param objMap Base object with properties to match on
 * @returns {{}} new object with only matching properties
 */
const MapDataToObjectKey = function (objData, objMap) {
	var ret = {},
		/**
		 * Original keys from objMap
		 * @type {Array}
		 */
		keys = Object.keys(objMap),
		/**
		 * Convert all keys to lower case to match data object key when original data attribute are in camelCase
		 * @type {Array}
		 */
		lKeys = keys.join('(|)').toLowerCase().split('(|)'),
		/**
		 * refer to key in objData in the for in loop
		 * @type {string}
		 */
		key,
		/**
		 * Preserve
		 * @type {number}
		 */
		keyIdx = 1;

	// Loop through all properties in objData
	for(key in objData) {
		if(objData.hasOwnProperty(key)) {
			keyIdx = lKeys.indexOf(key);
			if(keyIdx === -1) {
				keyIdx = keys.indexOf(key);
			}
			if(keyIdx !== -1) {
				// Key found in objData keys, add the new key to ret
				ret[keys[keyIdx]] = objData[key];
			}
		}
	}
	return ret;
}

/**
 * Test a data attribute and return it if the attribute is set, otherwise, return defaultValue
 * @param {object} $el jQueryElement to test the data property
 * @param {string} prop Date property name to test
 * @param {*} defaultValue Default value to return if property is undefined
 * @returns {*}
 */
const GetDataValue = function($el, prop, defaultValue) {
	var test = $el.data(prop);
	return typeof test === "undefined" ? defaultValue : test;
}

const GetOSPlatform  = function() {
	
		var os = 'pasSupporte';

		if (platform.os.family === 'OS X') {
			os = 'mac';
		} else if (platform.os.family === 'iOS') {
			os = 'ios';
		} else if (platform.os.family.toLocaleLowerCase().indexOf('windows') > -1) {
			os = 'windows';
		} else if (platform.os.family === 'Android') {
			os = 'android';
		}

		return os;
	
}

/**
 * Build a unique ID.
 * @param {String} [prefix=''] Optional string to prefix the generated id
 * @returns {String}.
 * @function uId
 */
const UId = function(prefix) {
	prefix = prefix || '';
	return prefix + (Math.round((+new Date()) + (Math.random() * 1000)));
};

const Trx = document.querySelector('html').getAttribute('data-trx') !== null ? JSON.parse( document.querySelector('html').getAttribute('data-trx') ) : false;

const VerifierDate = function(dateDebut, dateFin){
			
	var _dateDebut = moment(parseInt(dateDebut));
	var _dateFin = moment(parseInt(dateFin));
	_dateDebut =  _dateDebut.isValid() ? _dateDebut : moment().subtract(1,'y');
	_dateFin =  _dateFin.isValid() ? _dateFin : moment().add(1,'y');
	
	if(moment() >= _dateDebut && moment() < _dateFin){
		return true;
	} 
	else return false;
};
		
export { GetDataValue, GetOSPlatform, Lang, MapDataToObjectKey, UId, Trx, VerifierDate };