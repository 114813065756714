<template>
  <div :data-component-name="$options.name" class="div_container" >
      <b-row class="m-0">
        <div v-if="contenu.titre" class="titre graphik_bold" v-bind:class="[contenu.image ? 'titre-image' : 'titre-no-image']" :style="{'background-color':contenu.couleurFondTitre}">
          <b-card-img-lazy v-if="contenu.image"
            :src="getImageSrcVariation(getImageByScreenSize(contenu))"
            :alt="contenu.titre"
            class="image ml-1">
          </b-card-img-lazy>
          <span v-bind:class="[contenu.image ? 'ml-1' : 'ml-5']" v-html="contenu.titre">

          </span>
        </div>
        <div class="text-container"><span v-html="contenu.texte" class="texte"></span></div>
      </b-row>
  </div>
</template> 

<script> 
  export default {
    name:"MediaImageIcoTitTxt",
    data(){
        return{
        }
    },
    props: ["contenu"],
    methods:{},
    created: function () {
	}
  }
</script>

<style lang="scss" scoped>
	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
	}

  .titre{
    color:black; 
    @include ms-respond(font-size, 1);
    border-top-left-radius: ms(2);
    border-bottom-right-radius:ms(2);
    margin-left:-7%;
    margin-top: -1rem; 
    padding:1%;
    display:flex; 
    justify-content: flex-start;
    align-items:center;
    margin-bottom:3%;
    background-color: transparent;
    text-align: left;    
  }

  .titre-no-image{
    height: ms(7);
    width: 50%;
  }

  .titre-image{
    height: 7rem;
    width: 70%;
  }

  .image{
    height: 100%;
    width: auto;
  }

  .div_container{
    padding-left:5%;
    padding-right: 5%;
  }

  .text-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .texte{
    color:black; 
    @include ms-respond(font-size, 0);
  }

  @media screen and (max-width: 992px) {
    .titre {
      width: 90%;
      margin-left: -9%;
    }

    .text-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

</style>