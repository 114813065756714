// Originally in ej-commmun-portail.js
import WaitForClientConnectionInfo from './wait-for-clients-connection-info';
import CatfishInit from './catfish-init';

import EspacejeuxPortail_ServeurPub from './espacejeux-portail-serveur-pub';

const WatchHeader = function(enable, find) {
    var _timeout = 200;
    var connectionDisplayed = $('.mel-conn-bar').css('display') === 'flex';
    var deconecteDisplayed = $('.mel-conn-info-bar-wrapper').css('display') === 'flex';

    if((connectionDisplayed && !deconecteDisplayed) || (!connectionDisplayed && deconecteDisplayed)){
        find = true;

        if(find && !enable){

            if(connectionDisplayed){

                initBannieres();
                CatfishInit();

            } else {

                setTimeout(function () {
                    WaitForClientConnectionInfo();
                }, _timeout);
            }
        }
    }

    if (enable){
        
        if (find) enable = false;
        setTimeout(function () {
            WatchHeader(enable, find);
        }, _timeout);
    }

}

export default WatchHeader;