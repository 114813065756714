import Cookies from './components/cookies.js';
import DetectBrowser from './components/detectBrowser.js';
import { GetOSPlatform } from './components/utilities.js';

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js', {
        updateViaCache: 'none'
    })
        .then(function (registration) {
            // console.log('Registration successful, scope is:', registration.scope);
        })
        .catch(function (error) {
            // console.log('Service worker registration failed, error:', error);
        });
}


const showAddToHomescreen = function(event){
    let deferredPrompt;
	const ctaAdd = document.querySelector('[data-open="a2hs"]');
	const container = document.querySelector('[data-container="a2hs"]');

    const cookieDateCurrent = Cookies.get('lastVisitAndCounter').split('_')[0];
	const cookieCounter = Cookies.get('lastVisitAndCounter').split('_')[1];
	const cookieA2hsContent = Cookies.get('a2hs') || '';

    if( DetectBrowser.isAndroid && ( ctaAdd === null || !['5', '10', '20'].includes( cookieCounter ) || [`dismissedDate_${cookieDateCurrent}`].includes( cookieA2hsContent ) || cookieA2hsContent === 'dismissed' ) ) {
        return;
    } else if( DetectBrowser.isIos && ( ctaAdd === null || !['5'].includes( cookieCounter ) || cookieA2hsContent.includes( `dismissedDate_` ) ) ) { // On affiche seulement la bannière après 5 visites sur iOS. Car on ne peut pas détecter si l'app a été installé pour l'instant.
		return;
	}

    // Stash the event so it can be triggered later.
    deferredPrompt = event;

    container.classList.remove('d-none');

    ctaAdd.addEventListener('click', (e) => {
        // hide our user interface that shows our A2HS button
		ctaAdd.style.display = 'none';
		
		if( DetectBrowser.isAndroid ) {
			// Show the prompt
			deferredPrompt.prompt();
			// Wait for the user to respond to the prompt
			deferredPrompt.userChoice
			.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					Cookies.set('a2hs', 'accepted');
				} else {
					Cookies.set('a2hs', 'dismissed');
				}
				deferredPrompt = null;
			});

		} else if( DetectBrowser.isIos ) {
			document.querySelector('.add-homescreen-preauthorization__ios').classList.remove('d-none');
		}
    });

    document.querySelector('[data-close="a2hs"]').addEventListener('click', function(){
        container.classList.add('d-none');

        Cookies.set('a2hs', `dismissedDate_${cookieDateCurrent}`);
    });
};

document.addEventListener('DOMContentLoaded', function () {
    if( GetOSPlatform() === 'android' ) {
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            showAddToHomescreen(event);
        });
    } else if( DetectBrowser.isIos && !window.navigator.standalone ){
        showAddToHomescreen();
    }
   
});
