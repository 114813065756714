<template>
  <div v-if="isListeLoaded">
    <casino-jeu
      v-for="(jeu, index) in getJeuxListeFiltree"
      v-show="montrerCasinoJeu(jeu)"
      :key="jeu.idJeu"
      :jeu="jeu"
      :prefixe-url="prefixeUrl"
    ></casino-jeu>

    <div v-show="$store.state.casino.jeux.recherche.length < nbColumn" class="row col-12">
      <div class="col-12 text-center jeuListeNb">
        {{ jeuListeNb }}
        <span v-if="mixinLangue_langue == 'en'"> game<span v-show="jeuListeNb > 1">s</span> </span>
        <span v-else> jeu<span v-show="jeuListeNb > 1">x</span> </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mixinLangue } from "../../mixins/mixinLangue";
import CasinoJeu from "../casino-jeu/CasinoJeu.vue";

// TODO: Cacher ou non les jeux flash si le client n'a pas flash installé/* ##### casino-jeu-liste ##### */
export default {
  components: {
    CasinoJeu,
  },

  mixins: [mixinLangue],

  props: {
    nbColumn: {
      required: false,
    },
    categorie: {
      type: String,
      required: false,
    },
    prefixeUrl: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      jeuListe: [],
      indCacherFlash: false,
      isListeLoaded: false,
    };
  },

  computed: {
    ...mapGetters(["getFiltres", "getJeuxListeFiltree", "getTri"]),
    filtresActifs: function () {
      const vm = this;
      var ret = vm.getFiltres.categories.slice(0); // Clone
      if (vm.getFiltres.type.length > 0) ret.push(vm.getFiltres.type);
      if (vm.getFiltres.nbligne.length > 0) ret.push(vm.getFiltres.nbligne);
      if (vm.getFiltres.mntgroslot.length > 0) ret.push(vm.getFiltres.mntgroslot);
      if (vm.getFiltres.custom.length > 0) ret = ret.concat(vm.getFiltres.custom.slice(0));

      return ret;
    },
    callPath: function () {
      /* if (window.location.href.indexOf("casino/casino-en-direct/machines-a-sous") > 0) {
        return "/json-cache/" + "casino-en-direct/cedJeuListe";
      } else {*/
      return "/json-cache/" + "casino/jeu-liste";
      //}
    },
    isFiltreFavoriActif: function () {
      return this.filtresActifs.indexOf("indFavoris") != -1;
    },
    jeuListeNb: function () {
      return this.getJeuxListeFiltree.length;
    },
  },

  methods: {
    ...mapMutations(["updateJeuxListeFiltree"]),

    chargerListe() {
      var vm = this;
      $.ajax({
        url: vm.prefixeUrl + "/" + vm.mixinLangue_langue + vm.callPath,
        data: {
          categorie:
            window.location.href.indexOf("casino/casino-en-direct/machines-a-sous") > 0 ||
            window.location.href.indexOf("/casino/live-casino/slot-machines") > 0
              ? "machines-a-sous"
              : vm.categorie,
          filtres:
            window.location.href.indexOf("casino/casino-en-direct/machines-a-sous") > 0 ||
            window.location.href.indexOf("/casino/live-casino/slot-machines") > 0
              ? "indCedMas"
              : vm.$isDomainMobile
              ? "indMobile"
              : "indInternet",
          /*'filtres': (vm.$isDomainMobile ? 'indMobile' : 'indInternet')*/
        },
        dataType: "json",
        success: function (response, text, jqXHR) {
          var arrFavoris = vm.getFavorisLocalStorage();

          // On ajoute les indFavoris pour les jeux courants
          arrFavoris.forEach(function (element) {
            // Il est possible que le favori le fasse pas partie de la liste de jeux (mobile vs desktop par exemple)
            var elementAvecPrefix = (vm.$isDomainMobile ? "m-" : "i-") + element;

            if (response[elementAvecPrefix]) {
              response[elementAvecPrefix].filtres.push("indFavoris");
            }
          });

          vm.jeuListe = response;

          if (
            window.initPastilleLotprogressifResult === undefined ||
            window.initPastilleLotprogressifResult === {}
          ) {
            initPastilleLotprogressif(EspacejeuxPortail.LANGUE);
          }

          vm.isListeLoaded = true;
        },
        error: function (jqXHR, text, error) {
          console.log(text, error);
        },
      });
    },

    montrerCasinoJeu: function (jeu) {
      var rech = this.$store.state.casino.jeux.recherche.toLowerCase(); //TODO changer cet appel
      return (
        rech.length == 0 ||
        jeu.titre[this.mixinLangue_langue].toLowerCase().indexOf(rech) != -1 ||
        (jeu.texteMKT &&
          jeu.texteMKT[this.mixinLangue_langue] &&
          jeu.texteMKT[this.mixinLangue_langue].toLowerCase().indexOf(rech) != -1)
      );
    },

    getFavorisLocalStorage: function () {
      var arrFavoris = JSON.parse(localStorage.getItem("EJFavoris"));

      if (!(arrFavoris instanceof Array)) {
        localStorage.removeItem("EJFavoris");
        return [];
      }

      return arrFavoris;
    },
  },

  watch: {
    getFiltres: {
      handler() {
        this.chargerListe();
      },
      deep: true,
      immediate: true,
    },

    getTri() {
      this.chargerListe();
    },

    jeuListe: function () {
      // On a une liste vide au tout début, on sort rapidement
      if (this.jeuListe.length == 0 || !this.isListeLoaded) {
        return [];
      }

      var vm = this;

      // TODO: Optimisation -> en attandant de recevoir dans Array
      var arr = Object.keys(vm.jeuListe).map(function (k) {
        return vm.jeuListe[k];
      });
      var jeuListeFiltre = arr.filter(function (jeu) {
        return vm.filtresActifs.every(function (elem) {
          return jeu.filtres.indexOf(elem) > -1;
        });
        // TODO: Cacher ou non les jeux flash si le client n'a pas flash installé
        //&& (!vm.indCacherFlash || jeu.format.indexOf('Flash') == -1)
      });

      var champDesactiver = vm.$isDomainMobile ? "desactiverMobile" : "desactiverDesktop";
      var aDesactiver;
      var bDesactiver;
      var maintenant = moment().format("YYYY-MM-DDTHH:mm:ss.000Z");

      // Trier
      // 1. Les jeux désactivés se retrouvent à la fin
      // 2. Trier en fonction du tri par défaut/asc/des
      jeuListeFiltre.sort(function (a, b) {
        // Trier par jeux activés puis désactivés
        aDesactiver =
          a[champDesactiver] &&
          (!a.desactiverDateDebut || maintenant >= a.desactiverDateDebut) &&
          (!a.desactiverDateFin || maintenant <= a.desactiverDateFin);
        bDesactiver =
          b[champDesactiver] &&
          (!b.desactiverDateDebut || maintenant >= b.desactiverDateDebut) &&
          (!b.desactiverDateFin || maintenant <= b.desactiverDateFin);
        if (!aDesactiver && bDesactiver) return -1; // On descend un jeu inactif
        if (aDesactiver && !bDesactiver) return 1; // On monte un jeu actif

        // Trier asc/des/defaut
        if (vm.getTri === "asc") {
          return a.titre[vm.mixinLangue_langue] > b.titre[vm.mixinLangue_langue]
            ? 1
            : b.titre[vm.mixinLangue_langue] > a.titre[vm.mixinLangue_langue]
            ? -1
            : 0;
        } else if (vm.getTri === "des") {
          return a.titre[vm.mixinLangue_langue] < b.titre[vm.mixinLangue_langue]
            ? 1
            : b.titre[vm.mixinLangue_langue] < a.titre[vm.mixinLangue_langue]
            ? -1
            : 0;
        }

        return 0; // Tri par défaut
      });

      vm.updateJeuxListeFiltree(jeuListeFiltre);
    },
  },
};
</script>
