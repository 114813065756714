var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { attrs: { for: "tri" } }, [
      _vm._v(_vm._s(_vm.$t("navigation.libTrierPar"))),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.tri,
            expression: "tri",
          },
        ],
        staticClass: "tri form-control",
        attrs: { id: "tri" },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.tri = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            function ($event) {
              return _vm.updateTri(_vm.tri)
            },
          ],
        },
      },
      [
        _c("option", { attrs: { value: "ini" } }, [
          _vm._v(_vm._s(_vm.$t("navigation.libOrdreInitial"))),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "asc" } }, [
          _vm._v(_vm._s(_vm.$t("navigation.libAscendant"))),
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "des" } }, [
          _vm._v(_vm._s(_vm.$t("navigation.libDescendant"))),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }