"use strict";

/********************************************/
/*** OBJECT EspacejeuxPortail_QuickLink  ***/
/******************************************/

/* NAME : EspacejeuxPortail_QuickLink 
 * AUTH : Jean-Francois.Samson@loto-quebec.com, Sylvain.Hovington@loto-quebec.com
 * PARM : no
 *
 * DESC : Object for all QuickLink
 */

function EspacejeuxPortail_QuickLink_def() {

    this.lancer = function (url_fr, url_en) {

        var token = EspacejeuxPortail.getValeurParametreGet("token");
        var fn;
        eval("fn = function(){EspacejeuxPortail_QuickLink.apresLogin('" + url_fr + "', '" + url_en + "')}");

        if (token !== null && token !== "") {
            loginWithToken(token, fn, fn);
        }
        else {
            fn();
        }
    };

    this.apresLogin = function (url_fr, url_en) {

        var url      = "";
        var langue   = EspacejeuxPortail_Cookie.lireCookie('cust_xl');
        var langueG2 = EspacejeuxPortail.getValeurParametreGet("language");

        url = url_fr;

        //si on vient de poker et que la langue est passé en paramètre elle est priorisée
        if (langueG2) {
            if (langueG2.toLowerCase() === "en") {
                url = url_en;
            }
        } else if (langue) {
            if (langue.toLowerCase() === "en") {
                url = url_en;
            }
        }

        self.location.replace(url);
    };
}

var EspacejeuxPortail_QuickLink = new EspacejeuxPortail_QuickLink_def();