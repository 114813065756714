var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    {
      class: [
        _vm.contenu.colXxs,
        _vm.contenu.colXs,
        _vm.contenu.colSm,
        _vm.contenu.colMd,
        _vm.contenu.colLg,
        _vm.contenu.colXl,
        _vm.contenu.colXxl,
        _vm.contenu.colXxxl,
        _vm.contenu.displayAt,
        _vm.contenu.hideUntil,
        _vm.contenu.margin,
        _vm.contenu.cssClass,
        "col-xs-no-padding",
      ],
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _vm._l(_vm.contenu.column["@nodes"], function (aNode) {
        return [
          _vm.contenu.column[aNode].typeComposante
            ? _c(_vm.contenu.column[aNode].typeComposante, {
                key: _vm.contenu.column[aNode]["@id"],
                tag: "component",
                attrs: { contenu: _vm.contenu.column[aNode] },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }