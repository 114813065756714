let UpdateNoticeYear = function() {
	const self = this;
	const selector = '[data-component="updateNoticeYear"]';
	
	self.init = function(){
			$(selector).ready( function(){
				var notice = $(selector).text();
				$(selector).text( notice.replace(/[0-9]+/g, new Date().getFullYear()) );
			});
	}
}

UpdateNoticeYear = new UpdateNoticeYear();

export default UpdateNoticeYear;