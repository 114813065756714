// Originally in ej-commmun-portail.js

/********************************************/
/*** OBJECT EspacejeuxPortail_ServeurPub  ***/
/********************************************/

/* NAME : EspacejeuxPortail_ServeurPub
 */
function EspacejeuxPortail_ServeurPub_def() {
	this.timerId = -1;

	this.init = function (mainDiv) {
		$(mainDiv).children().first().addClass('active');
		$(mainDiv).children().css({"visibility": "visible"});
		$(mainDiv).children().css({"display": "block"});
	}

	this.slideSwitch = function (mainDiv) {
		var mainDivId = $(mainDiv).attr('id');

		var $zonesPub = $(mainDiv).find('div.bb-zone').find('div.bb-zone-pub');


		if ($zonesPub.length < 2) {
			if (this.timerId != -1) clearInterval(this.timerId);
			return;
		}

		var $active = $('div#' + mainDivId + ' > div.bb-zone.active');

		if ($active.length == 0) {
			$active = $('div#' + mainDivId + ' > div.bb-zone:last');
		}

		var $next = $active.next().length ? $active.next() : $('div#' + mainDivId + ' > div.bb-zone:first');
		$active.addClass('last-active');

		$next.css({"opacity": "0.0"})
			.addClass('active')
			.animate({opacity: 1.0}, 1000, function () {
				$active.removeClass('active last-active');
			});
	};

	this.randomBanner = function ($bannieres) {

		$bannieres.hide();
		var totalWeight = 0, cummulativeWeight = 0, i;

		$bannieres = $bannieres.filter('.' + ejConnect.etatClient);


		// Add up the weights.
		$bannieres.each(function () {
			totalWeight += parseInt($(this).data("banner-weight"));
		});
		var random = Math.floor(Math.random() * totalWeight);

		// Find which bucket the random value is in.
		$bannieres.each(function () {
			cummulativeWeight += parseInt($(this).data("banner-weight"));
			if (random < cummulativeWeight) {
				$(this).show();
				return false;
			}

		});
	}
}

const EspacejeuxPortail_ServeurPub = new EspacejeuxPortail_ServeurPub_def();

export default EspacejeuxPortail_ServeurPub;