// Was in lq.utils.tabs.js

import { GetOSPlatform } from './utilities';

/**
 * Manage tabs component tab content display.
 * Apply to all element with a class "fn-component-tabs"
 * Use option attibute 'data-same-width="true"' to set all tabs the same width by the scipt
 * Stucture is:
 * <div class="fn-component-tabs">
 * 	 <nav>
 * 	    <a href="#tab-content1">Content 1</a>
 * 	    <a href="#tab-content2">Content 2</a>
 * 	    <a href="#tab-content3">Content 3</a>
 * 	    <a href="#tab-content4">Content 4</a>
 * 	 </nav>
 * 	 <!-- tabs use unique ID so they can be anywhere in the page ;) -->
 * 	 <div id="#tab-content1">
 * 	    Tab Conent 1 here !
 * 	 </div>
 * 	 <div id="#tab-content2">
 * 	    Tab Conent 2 here !
 * 	 </div>
 * 	 <div id="#tab-content3">
 * 	    Tab Conent 3 here !
 * 	 </div>
 * 	 <div id="#tab-content4">
 * 	    Tab Conent 4 here !
 * 	 </div>
 * </div>
 * @param {string|HTMLElement} [limitContext=null] jQuery selector to limit search in. Default = null
 * @function handleComponentTabs
 */
const Tabs = function( $tabsSet = $('.fn-component-tabs') ) {
	const $window = $(window);

	// for each tabs set in the page
	$tabsSet.each(function () {
		var $self = $(this), // Current tabs set					
			$mainNav = $('nav.menu:first'),
			$navParent = $self.find('nav:first'),
			$nav = $navParent.find('.link-lists > a'), // Tabs link in <nav> tag
			$externalLinks = $('a.fn-tab-trigger'),
			$tabs = [], // Will received a list of ID's and end with corresponding jQuery elements
			$hashTabs = [],
			$tabsLink = null, // Will received all external link where hash match with a tab id
			$mobileTab = $self.find('nav > button.mobile-tab'),
			selectedIdx = Math.max($nav.index($nav.filter('.selected:first')), 0), // On init selected tab index, or 0 for the first
			sameWidth = $self.data('same-width') || false, // Script should set all tabs the same width ?
			width = ($nav.parent().width()/$nav.length),
			scrollOnLoad = false,
			triggeredTabOnClick = false,
			onLoadIdx = -1,
			$accessibilityNav;

		//console.log($externalLinks);
		// ID are store in href attribute of links for accessibility, so loop and get all ID's
		$nav.each(function() {
			$hashTabs.push(this.hash);
			if($tabsLink) {
				$tabsLink = $tabsLink.add($externalLinks.filter('[href="' + this.hash + '"]'));
			} else {
				$tabsLink = $externalLinks.filter('[href="' + this.hash + '"]');
			}
		});

		// If URL hash contain a valid tab ID, use this ID index
		var updateSelectedTabFromCurrentHash = function(){
			if(window.location.hash) {
				onLoadIdx = $hashTabs.indexOf(window.location.hash);
				selectedIdx = Math.max(onLoadIdx, 0) || selectedIdx;
				scrollOnLoad = onLoadIdx !== -1;
			}
		};
		updateSelectedTabFromCurrentHash();

		// Get all DOM element link to each ID's
		$tabs = $($hashTabs.join(','), $self);

		// If same-width option is use, set all tabs the same size
		if(sameWidth) {
			// Set navigation link's size base on number of elements in the navigation
			$nav.css('width', ~~width);
			// Prevent rendering bugs where a single pixel is left empty, for example 718/3 = 239.3333 will end with an empty pixel at the end.
			if (~~width !== width) {
				$nav.last().css('width', ~~width + 1);
			}
		}

		// Select tab function
		var selectTab = function($el) {
			//console.log(selectedIdx, $tabs.eq(selectedIdx).data('video'));
			selectedIdx = isNaN($el) ? $nav.index($el) : $el;
			if(selectedIdx > -1) {

				$nav.removeClass('selected').eq(selectedIdx).addClass('selected');
				$mobileTab.attr('data-caption', $nav.eq(selectedIdx).text());
				$mobileTab.attr('title', $nav.eq(selectedIdx).text());

				// Toggle tabs content visibility base on selection
				$tabs.not($tabs.eq(selectedIdx).show()).hide();

				$self.removeClass('show-mobile-tabs');
			}

			triggeredTabOnClick = false;
			$window.trigger('resize');
			return false;
		};

		$nav.on('click', function(e) {
			e.preventDefault();
			triggeredTabOnClick = true;

			var el = $(this);
			var hashVal = el.attr('href');
			history.pushState(null, null, hashVal);

			return selectTab(el);
		});

		if($tabsLink){
			$tabsLink.on('click', function(e) {
				e.preventDefault();
				triggeredTabOnClick = true;
				return selectTab($nav.filter('[href="' + this.hash + '"]'));
			});
		}

		// add accessibility links for next sections and search for youtube video
		$accessibilityNav = $nav.clone();
		$tabs.each(function(idx) {
			var $tab = $(this),
				$lnk;

			if(idx+1 < $accessibilityNav.length) {
				$lnk = $accessibilityNav.eq(idx+1).addClass('sr-only').appendTo(this);
				$lnk.on('click', function (e) {
					e.preventDefault();
					triggeredTabOnClick = true;
					return selectTab($nav.eq(idx+1));
				});
			}
		});

		$mobileTab.on('click', function(e) {
			e.preventDefault();
			triggeredTabOnClick = true;
			$self.toggleClass('show-mobile-tabs');
			return false;
		});

		$window.on('click', function() {
			$self.removeClass('show-mobile-tabs');
		});

		$window.on('popstate', function(e) {
			if(!triggeredTabOnClick){
				updateSelectedTabFromCurrentHash();

				if(window.location.hash != '' && window.location.hash != null){
					selectTab($nav.filter('[href="' + window.location.hash + '"]'));
				} else {
					selectTab($nav.eq(0));
				}
			}
		});

		selectTab((scrollOnLoad ? $nav.eq(selectedIdx) : selectedIdx));
		
		
		var detecterOngletPlatform = function($tab) {                	
				return $tab.each(function () {

				var anchor = "",
					idx = window.location.href.indexOf("#");
					
				if(idx > -1) {                		
					var hash = window.location.href.substring(idx),
						$arrAnchor = [];
						$(this).find('.link-lists a[href^="#"]').each(function(){                     				                     				
							$arrAnchor.push( $(this).attr('href') );                     			
						}); 
					
					if ($.inArray(hash, $arrAnchor) >= 0) {             			
						anchor = hash;                      			
					}                     		
				}
				
				if(anchor === "") {
					anchor = GetOSPlatform() !== 'android' ? '#'+ GetOSPlatform() : (function () {
						var ua = new MobileDetect(window.navigator.userAgent);
						return !!ua.tablet() ? '#android-tablet' : '#android-mobile';
					})();
				}
				var $elem =  $(this).find('.link-lists > a[href="'+ anchor +'"]');                 		    		     		
				selectTab($elem);
				});
		};
		
		detecterOngletPlatform($tabsSet);
		
		
		
	});
}

export default Tabs;