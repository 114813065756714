    $.fn.conteneurRangeeCarrouselMobile = function () {
        return this.each(function () {

            if($(window).width() > 640) return;

            const row$ = $(this).find('.row');
    
            row$.addClass('owl-carousel');
            row$.find('.table').addClass('item');
            row$.find('.bgEspaceur').remove();
  
            row$.owlCarousel({
                stagePadding: 30,
                margin:10,
                responsive: {
                    0:{
                        items:1
                    }
                },
                nestedItemSelector: 'item'
            });

        });
    }

