<template>
  <div id="filtres" class="col-12 col-lg-12" :class="{ 'filtres--mobile': isDomainMobile }">
    <div id="boite-recherche">
      <div class="search-wrapper">
        <input
          id="search"
          v-model="recherche"
          type="text"
          class="search"
          autocomplete="off"
          data-list=".liste-jeux"
          name="search"
          :placeholder="$t('recherche')"
          value=""
        />
        <span class="fright icomoon icon-search"></span>
      </div>
      <div class="arrow-down"></div>
    </div>

    <div class="filters-list-wrapper">
      <slot
        name="categories"
        :filtres="filtres"
        :changeFiltres="changeFiltres"
        :effacerFiltres="effacerFiltres"
      />
      <slot
        name="type"
        :filtres="filtres"
        :changeFiltres="changeFiltres"
        :effacerFiltres="effacerFiltres"
      />
      <slot
        name="nbligne"
        :filtres="filtres"
        :changeFiltres="changeFiltres"
        :effacerFiltres="effacerFiltres"
      />
      <slot
        name="mntgroslot"
        :filtres="filtres"
        :changeFiltres="changeFiltres"
        :effacerFiltres="effacerFiltres"
      />
    </div>
  </div>
</template>

<script>
import { mixinLangue } from "../../mixins/mixinLangue";
import { mapGetters, mapMutations } from "vuex";

import DetectBrowser from "../../../js/components/detectBrowser";

export default {
  mixins: [mixinLangue],

  data: function () {
    return {
      filtres: [],
      localStoragePrefixe: null,
      recherche: "",
      indCacheFlash: false,
      isDomainMobile: false,
    };
  },

  created: function () {
    const vm = this;
    this.filtres = this.getFiltres;
    // Nécessaire pour que les tooltips fonctionnent en VueJs
    $('[data-toggle="tooltip"]').tooltip();
    vm.localStoragePrefixe = vm.getLocalStoragePrefixe;

    this.initFiltre();
  },

  watch: {
    recherche: debounce(function (value) {
      const vm = this;
      if (value.length >= 3) {
        vm.updateRecherche(value);
      } else {
        vm.updateRecherche("");
      }
    }, 400),
  },

  mounted: function () {
    var vm = this;
    vm.isDomainMobile = DetectBrowser.isDomainMobile ? true : false;
  },

  methods: {
    ...mapMutations(["updateFiltres", "updateRecherche"]),
    initFiltre: function () {
      const vm = this;
      // Le filtre en paramètre gagne sur le filtre en local storage
      var sFiltres = EspacejeuxPortail.getParameterByName("filtres");

      if (sFiltres.length != 0) {
        localStorage.setItem(this.localStoragePrefixe + "FiltresCategories", sFiltres);
      }

      var sFiltres = localStorage.getItem(this.localStoragePrefixe + "FiltresCategories");

      if (sFiltres != null && sFiltres.length > 0) {
        vm.getFiltres.categories = sFiltres.split("@");
      }

      vm.getFiltres.type = localStorage.getItem(vm.localStoragePrefixe + "FiltresType") || "";
      vm.getFiltres.nbLigne = localStorage.getItem(vm.localStoragePrefixe + "FiltresNbLigne") || "";
      vm.getFiltres.mntGrosLot =
        localStorage.getItem(vm.localStoragePrefixe + "FiltresMntGrosLot") || "";
      vm.getFiltres.custom = localStorage.getItem(vm.localStoragePrefixe + "FiltresCustom") || [];

      vm.updateFiltres(vm.filtres);
      vm.filtres = vm.getFiltres;
    },
    changeFiltres: debounce(function (typeFiltre, superType, value) {
      const vm = this;
      //Pour Google Analytics
      var checked = document.getElementById("filtre-" + value).checked;
    

      GA_action = this.localStoragePrefixe + ":filtre-" + value;
      if ("checkbox" === superType) {
        if (checked) GA_label = "coche";
        else GA_label = "decoche";
      } else if ("radio" == superType && checked) {
        GA_label = "selectionne";
      }

      var filtersUpdatedValue =
        superType == "checkbox" ? vm.getFiltres[typeFiltre].join("@") : vm.getFiltres[typeFiltre];
      var capitalizeTypeFiltre = typeFiltre.charAt(0).toUpperCase() + typeFiltre.slice(1);

      localStorage.setItem(
        this.localStoragePrefixe + "Filtres" + capitalizeTypeFiltre,
        filtersUpdatedValue
      );

      vm.updateFiltres(vm.filtres);
    }, 500),
    effacerFiltres: function (typeFiltre) {
      const vm = this;

      vm.filtres[typeFiltre] = [];
      var capitalizeTypeFiltre = typeFiltre.charAt(0).toUpperCase() + typeFiltre.slice(1);
      localStorage.removeItem(this.localStoragePrefixe + "Filtres" + capitalizeTypeFiltre);

      vm.updateFiltres(vm.filtres);
    },
  },

  computed: {
    ...mapGetters(["getFiltres"]),
    getLocalStoragePrefixe: function () {
      var path = location.pathname;
      var localStoragePrefixe = "ejDefaut"; // Par défaut

      if (
        (path.indexOf("casino-en-direct") > -1 &&
          path.indexOf("casino-en-direct/machines-a-sous") < 1) ||
        (path.indexOf("live-casino") > -1 && path.indexOf("live-casino/slot-machines") < 1)
      ) {
        localStoragePrefixe = "ejCED";
      } else if (
        path.indexOf("casino-en-direct/machines-a-sous") > -1 ||
        path.indexOf("live-casino/slot-machines") > -1
      ) {
        localStoragePrefixe = "ejMAS";
      } else if (path.indexOf("machines-a-sous") > -1 || path.indexOf("slot-machines") > -1) {
        localStoragePrefixe = "ejMAS";
      } else if (path.indexOf("jeux-de-table") > -1 || path.indexOf("table-games") > -1) {
        localStoragePrefixe = "ejJT";
      } else if (path.indexOf("keno") > -1) {
        localStoragePrefixe = "ejKeno";
      } else if (path.indexOf("sports-virtuels") > -1 || path.indexOf("virtual-sports") > -1) {
        localStoragePrefixe = "ejSV";
      } else if (path.indexOf("instants") > -1) {
        localStoragePrefixe = "ejInstants";
      }
      return localStoragePrefixe;
    },
  },
};
</script>
