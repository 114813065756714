var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "contenu_html_taille_defaut",
    class: _vm.contenu.cssClass,
    domProps: { innerHTML: _vm._s(_vm.contenu.texteHTML) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }