$.fn.addToCalendar = function (attrs) {

    let TPL_BUTTON = `<div class="add-to-calendar">
            <div class="add-to-calendar__dropdown dropdown">
                <button class="add-to-calendar__btn btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                  <span class="add-to-calendar__btn-icon fa fa-lg fa-calendar"></span> 
                  ${attrs.messages.addToCalendar}
                </button>
                <ul class="add-to-calendar__menu dropdown-menu">
                    <li>
                        <a class="add-to-calendar__link" href="${attrs.iCal}" download>
                            <span class="add-to-calendar__link-icon fa fa-lg fa-apple"></span> 
                            ${attrs.messages.apple}
                        </a>
                    </li>
                    <li>
                        <a class="add-to-calendar__link" href="${attrs.googleCal}" target="_blank">
                            <span class="add-to-calendar__link-icon fa fa-lg fa-google"></span> 
                            ${attrs.messages.google}
                        </a>
                    </li>
                    <li>
                        <a class="add-to-calendar__link" href="${attrs.iCal}" download>
                            <span class="add-to-calendar__link-icon fa fa-lg fa-windows"></span> 
                            ${attrs.messages.outlook}
                        </a>
                    </li>
                    <li>
                        <a class="add-to-calendar__link" href="${attrs.yahooCal}" target="_blank">
                            <span class="add-to-calendar__link-icon fa fa-lg fa-yahoo"></span> 
                            ${attrs.messages.yahoo}
                        </a>
                    </li>
                </ul>
            </div>
        </div>`;

    return this.each(function () {
        $(this).html(TPL_BUTTON);
    });
};