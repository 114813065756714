<template>

  <section :data-component-name="$options.name" :data-page="getNomPageCourante"
    class="section-sansTheme section-page gs_section row"
    :class="[contenu.coinArrondi, contenu.cssClass, (contenu.marginTop && contenu.marginTop == 'false') ? 'mt-0 pt-0' : '']"
    :style="[styleObject($mq)]"
  >
    <div class="contenu container" :class="[contenu.cssClassContenu]">
      <h1 
      v-if="contenu.title && !hideTitleIfMobile(contenu.titreAfficherMobile)"
      class="gs_titre graphik_bold"
      :class="[contenu.titleOverBackground?'gs_titre_top_neg  ':'']" :style="{'color':contenu.titleColor}"
      v-html="contenu.title">
      </h1>
      <p v-if="contenu.title2"
      class="gs_sous_titre">
        {{contenu.title2}}
      </p>

      <template v-for="aNode in contenu.grandeSection['@nodes']">
        <component
          v-if="contenu.grandeSection[aNode].typeComposante"
          v-bind:is="contenu.grandeSection[aNode].typeComposante"
          :key="contenu.grandeSection[aNode]['@id']"
          :contenu="contenu.grandeSection[aNode]"
        >{{contenu.grandeSection[aNode]['@id']}}</component>
      </template>
    </div>
    
  </section>

</template> 

<script>
// Trier Alpha
import GestionFiltre from "../filtres/GestionFiltre.vue";
import Row from "../container/grid/Row.vue";
import Tableau from "../base/Tableau.vue";

export default {
  name: "GrandeSection",
  components: {
    GestionFiltre,
    Row,
    Tableau,
  },
  props: ["contenu"],
  computed:{
    getNomPageCourante: function(){
      return this.getNomRouteCourante();
    }
  },
  methods: {
    isItMobile(){
        if (navigator.userAgent.match(/Android/i) 
            || navigator.userAgent.match(/webOS/i) 
            || navigator.userAgent.match(/iPhone/i)  
            || navigator.userAgent.match(/iPad/i)  
            || navigator.userAgent.match(/iPod/i) 
            || navigator.userAgent.match(/BlackBerry/i) 
            || navigator.userAgent.match(/Windows Phone/i)) { 
            return true;
        } else { 
            return false;
        } 
    },
    hideTitleIfMobile(titreAfficherMobile){
        return (this.isItMobile() && titreAfficherMobile != null && titreAfficherMobile == 'false')
    },
    hideBackgroundIfMobile(backgroundAfficherMobile){
        return (this.isItMobile() && backgroundAfficherMobile != null && backgroundAfficherMobile == 'false')
    },    
    styleObject(mq) {
      return {
          'background-color': this.contenu.fondCouleur != 'defaut' ? this.contenu.secBgThemeColor : null,
          'background-image': this.hideBackgroundIfMobile(this.contenu.backgroundAfficherMobile) ? null : 'url(' + this.getImageSrcVariation(this.contenu.image) + ')',
          'background-repeat': 'no-repeat',
          'background-position': 'center top',
          'background-size': 'cover',
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.contenu {
  background-position: center;
  background-repeat: no-repeat;
}
.contenu.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.gs_section{
  overflow:visible; 
  padding-bottom: 1%;
  padding-top: 1%;
}
.gs_titre {
  text-align: left; 
  @include ms-respond(font-size, 6);
  position:relative;
  line-height: 1.25;
 
  @include media-breakpoint-down(sm)  { 
      color : #808080 !important;
  }
}

.gs_sous_titre {
  text-align: left;
  @include ms-respond(font-size, 2);
  margin-top: -1.5rem;
  color: #000000;
  // margin-bottom: 55px;
  // margin-left: 15%;
}
.gs_titre_top_neg{
  top:-9rem !important;
  margin-bottom: -6rem !important;
 
  @include media-breakpoint-down(sm)  { 
    top:-7.5rem !important;
  }
}

// lié a contenu.typeCoin
.coin_haut{
  border-top-left-radius: ms(3); 
  border-top-right-radius: ms(3);
}

.coin_bas{
  border-bottom-left-radius: ms(3); 
  border-bottom-right-radius: ms(3);
}

.quatreCoin{
  border-radius: ms(3); 
}

.margin_top_entre_section{
  margin-top: 10rem;
}

.margin_bottom_entre_section{
  margin-bottom: 10rem;
}


@media screen and (max-width: 765px) {
  .gs_titre {
    text-align: center;
    margin-left:0; 
    font-size: 42px;
  }

  .gs_titre_top_neg{
    top:-6rem !important;
    margin-bottom: 0rem;
  }

  .gs_sous_titre {
    margin-left: 0;
    text-align: center;
  }
}
</style>
