<template>
		<div class="lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1" data-role="lobby-table-game-specific-wrapper">
			<div class="RecentResults--3Im4X" data-role="recent-results">
				<svg v-for="(result,index) in tableauResultat" :key="index" class="item--1_3sq" viewBox="0 0 56 53" data-role="recent-result">
					<defs>
						<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hgdzfroyju">
							<stop stop-color="#F1E07F" offset="0%"></stop>
							<stop stop-color="#BA8433" offset="100%"></stop>
						</linearGradient>
						<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="92szj2o70m">
							<stop stop-color="#504E49" offset="0%"></stop>
							<stop stop-color="#312F28" offset="100%"></stop>
						</linearGradient>
					</defs>
					<g transform="translate(2 12)">
						<rect :fill-opacity="(result.multiplier)?'0.8':(index == 0)?'0.12':'0.04'" :fill="(result.multiplier)?'url(#hgdzfroyju)':'#FFFFFF'" width="52" height="40" rx="2" :stroke-width="(index==0)?'2':''" :stroke="(index==0)?'#FFFFFF':''"></rect>
						<text font-family="Inter, Arial, sans-serif" font-size="24" :font-weight="(index == 0)? 'bold' :'400'" fill="#FFF" text-anchor="middle">
							<tspan x="26" y="27">{{getTotalResultat(result.value)}}</tspan>
						</text>
					</g>
					<g v-if="result.multiplier" transform="translate(0 -1)">
						<rect stroke="#BAA258" fill="url(#92szj2o70m)" fill-rule="nonzero" x="6" y="1.5" width="42" height="16" rx="8"></rect>
						<text font-family="Inter, Arial, sans-serif" font-size="14" font-weight="bold" fill="#D9D9D8" text-anchor="middle">
							<tspan x="27" y="14">{{result.multiplier}}x</tspan>
						</text>
					</g>
				</svg>
			</div>
		</div>
</template>

<script>
export default {
    props : {
		results : {
			type : Array,
			required : true
		},
		appareil : {
			type : String,
			required : true
		},
		prefixeUrl : {
			type : String,
			required : true
		},
	},
	computed:{
		//Adaptation des résultats obtenus pour que les valeurs des dés soient en tableau
        tableauResultat:function(){
			var results = this.results;
            var resultats = [];
            for(var i = 0 ; i < results.length ; i++){
				var value = results[i].value.split("|");
				var multiplier = this.results[i].multiplier;
				var resultat = {value: value, multiplier:multiplier};
                resultats.push(resultat);
            }
            return resultats;        
        }
	},
	methods:{
		//Retourne du total des dés donnés en paramêtre
        getTotalResultat:function(resultat){
            return parseInt(resultat[0])+parseInt(resultat[1])+parseInt(resultat[2]);
        },
	}
}
</script>

<style>
.TableLogo--3RFNG.desktop--1Txr1 {
    z-index: 4
}

.TableLogo--3RFNG.desktop--1Txr1.doubleBall--2sPYM {
    bottom: 2.5em
}

.lobbyTableGameSpecificWrapper--3fAEk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    min-height: 3.35rem;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1em;
    z-index: 4
}

.lobbyTableGameSpecificWrapper--3fAEk.emptyGameHistoryStatistic--Uu-Gi {
    min-height: auto!important
}

.lobby-table-block.occupied .lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .lobbyTableGameSpecificWrapper--3fAEk {
    left: 1px;
    right: 1px
}

.lobbyTableGameSpecificWrapper--3fAEk .lobby-table-block.table-is-full-behind {
    display: none
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk.desktop--1Txr1,.sicbo .lobbyTableGameSpecificWrapper--3fAEk.desktop--1Txr1 {
    font-size: .7em
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk.phone--3PmoR [data-role=recent-results],.sicbo .lobbyTableGameSpecificWrapper--3fAEk.phone--3PmoR [data-role=recent-results] {
    padding: 0
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-results],.sicbo .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-results] {
    box-sizing: border-box;
    width: 100%;
    padding: .2em
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-result],.sicbo .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-result] {
    margin-top: 0
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 3.35rem;
    background-color: rgba(0,0,0,.5)
}

.desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 1.9em;
    z-index: 4
}

.lightningdice .desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk,.sicbo .desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    z-index: 4
}

.lightningdice .desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 2.7em
}

.desktop--1Txr1 .lobbyTableGameSpecificData--3whkv>* {
    z-index: 4
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    box-sizing: border-box;
    bottom: -1px;
    min-height: 3em;
    padding: 0 .3em .4em
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk.portrait--w-M_r {
    min-height: 2.4em
}

.lobby-table-block.not-operational.table-details-loaded .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    display: none
}

.lobby-table-block.occupied .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    left: 0;
    right: 0
}

.lightningdice .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 3em
}

.lightningdice .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk.portrait--w-M_r {
    min-height: 2.4em;
    padding-top: .2em
}

.RecentResults--3Im4X {
    font-size: 1em;
    margin-top: -.6em;
    z-index: 5;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    opacity: 1;
    transition: opacity .1s linear
}

.item--1_3sq {
    display: inline-block;
    width: 3.8em;
    height: 3.6em;
    pointer-events: none
}
</style>