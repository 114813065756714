<template>
    <nav class="pagination-games">
        <span class="pagination-games__label">Page(s)</span>
        <ul class="pagination-games__list">
            <li> 
                <button type="button" class="pagination__nav pagination__nav-previous"
                    :disabled="pageCurrent === 1"
                    @click="navigateTo('previous')">
                    <span class="icomoon icon-prev" aria-hidden="true"></span>
                </button>
            </li>
            <li class="pagination-games__item"
                v-for="(page, index) in pages"
                v-bind:page="page"
                v-bind:key="index"
                :class="{is_displayed: pageDisplayed(page)}">
                <a class="pagination-games__link" :href="'#page-' + page" @click="pageCurrent = page">{{ page }}</a>
            </li>
            <li>
                <button type="button" class="pagination__nav pagination__nav-next"
                    :disabled="pageCurrent === pages"
                    @click="navigateTo('next')">
                    <span class="icomoon icon-next" aria-hidden="true"></span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import DetectBrowser from '../../../js/components/detectBrowser';

export default {
	name: 'Pagination',

	data: function () {
		return {
			containerListe: {},
			pageCurrent: 1,
			pages: 0,
			pagesDisplayedMax: 3,
			pagesDisplayedMin: 0,
			pagesRange: 3,
			perPage: 15,
			stickyContainerHeight: null,
			triggers: {}
		}
	},

	computed: {
		...mapGetters([
		'getJeuxListeFiltree',
		]),
	},

	methods: {
		// Destroy DOM elements to avoid duplicates when the list is filtered
		destroy: function () {
			const pageAnchors = document.querySelectorAll('.page-anchor');

			for (let i = 0; i < pageAnchors.length; i++) {
				this.containerListe.removeChild(pageAnchors[i]);
			}

			document.removeEventListener('scroll', this.setActiveItem);
		},

		// Previous / Next
		navigateTo: function (direction) {
			const pageNew = direction === 'previous' ? this.pageCurrent - 1 : this.pageCurrent + 1;
			this.triggers[pageNew - 1].click();
		},

		// Hide pagination item if out of range
		pageDisplayed: function (page) {
			return page > this.pagesDisplayedMin && page <= this.pagesDisplayedMax;
		},

		// On scroll, find the closest anchor based on the top of the window
		setActiveItem: function () {
			const vm = this;
			const itemsOffsetTop = [];
			const classActive = 'is_active';
			const pageBreak = ( window.innerHeight / 2 ) + vm.stickyContainerHeight;

			for (let i = 0; i < this.triggers.length; i++) {
				this.triggers[i].classList.remove(classActive);
				vm.triggers[i].parentElement.classList.remove(classActive);
				const elementTop = document.querySelector(this.triggers[i].getAttribute('href')).getBoundingClientRect().top;
				itemsOffsetTop.push(elementTop);
			}

			var closestValue = itemsOffsetTop.reduce(function (previousValue, currentValue) {
				return currentValue > previousValue && currentValue < ( pageBreak )  ? currentValue : previousValue;
			});

			const closestItem = itemsOffsetTop.indexOf(closestValue);

			vm.triggers[closestItem].classList.add(classActive);
			vm.triggers[closestItem].parentElement.classList.add(classActive);
			
			vm.pageCurrent = closestItem + 1;
		},
		
	},

	watch: {
		getJeuxListeFiltree() {
			const vm = this;
			// When the Games list is modified, display the pagination

			const jeuListeLength = Object.keys(vm.getJeuxListeFiltree).length;

			if( jeuListeLength <= 0 ) {
				return;
			}

			vm.pages = Math.ceil( jeuListeLength / vm.perPage );

			vm.$nextTick(function () {

				// Updates 
				vm.pagesRange = DetectBrowser.isDomainMobile ? 3 : 5;
				vm.pagesDisplayedMax = vm.pagesRange;
				vm.containerListe = document.getElementById('blocs-jeux');
				vm.stickyContainerHeight = document.querySelector('.entete--sticky').offsetHeight;

				vm.destroy();

				vm.triggers = document.querySelectorAll('.pagination-games__link');

				for (let i = 0; i < vm.pages; i++) {
					const pageAnchor = document.createElement('div');
					pageAnchor.classList.add('page-anchor');
					pageAnchor.setAttribute('id', `page-${i + 1}`);
					vm.containerListe.insertBefore(pageAnchor, document.querySelectorAll('[data-element="jeu"]')[Math.abs(Math.ceil((vm.perPage * i)))]);

					const containerScroll = ( DetectBrowser.isEdge ) ? $('body') : $('html');
					vm.triggers[i].addEventListener('click', function (event) {
					event.preventDefault();
					const scrollValue = $(this.getAttribute('href')).offset().top - vm.stickyContainerHeight;
					const speedAnimation = 300;

					containerScroll.animate({
						scrollTop: scrollValue
					}, speedAnimation);
					} );
				}

				vm.setActiveItem();
				document.addEventListener('scroll', debounce(this.setActiveItem, 150));
			});

		},
		pageCurrent: function () {
			if (this.pageCurrent <= this.pagesDisplayedMin) {
				this.pagesDisplayedMin = this.pagesDisplayedMin - this.pagesRange;
				this.pagesDisplayedMax = this.pagesDisplayedMax - this.pagesRange;
			} else if (this.pageCurrent > this.pagesDisplayedMax) {
				this.pagesDisplayedMin = this.pagesDisplayedMin + this.pagesRange;
				this.pagesDisplayedMax = this.pagesDisplayedMax + this.pagesRange;
			}
		}
	},
}
</script>