<template>
	<ul class="televersement__errors">

		<li class="televersement__error js-televersement__error-filesCount" v-show="error.filesCount" v-html="getAccountUserMessages['MSG02-2-80'].details"></li>

		<li class="televersement__error js-televersement__error-filesMultiple" v-show="error.filesMultiple" v-html="getAccountUserMessages['MSG02-2-10'].details"></li>

		<li class="televersement__error js-televersement__error-type" v-show="error.type" v-html="getAccountUserMessages['MSG02-2-40'].details"></li>

		<li class="televersement__error js-televersement__error-size" v-show="error.size" v-html="getAccountUserMessages['MSG02-2-30'].details"></li>

		<li class="televersement__error js-televersement__error-sending" v-show="error.sending" v-html="getAccountUserMessages['MSG02-2-70'].details"></li>

		<li class="televersement__error js-televersement__error-identical-name" v-show="error.identicalName" v-html="getAccountUserMessages['MSG02-2-50'].details"></li>
	
	</ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		error: Object,
		fileTypesString: String
	},

	computed: {
		...mapGetters([
			'getAccountUserMessages',
		]),	


	},

}
</script>