//import BingoApi from "../../services/api/Bingo";
import i18n from "../../i18n";

const state = {
  isSallesLoaded: false,
  salles: null,
  sallesLive: null,

  isAnimateursLoaded: false,
  animateurs: null,

  isGagnantsMajeursLoaded: false,
  gagnantsMajeurs: null,

  isGagnantsRecentsLoaded: false,
  gagnantsRecents: null,
};

const getters = {
  // Si idSalle n'est pas spécifié, on retourne toutes les salles (dont lobbyLink et msgDesactivation)
  getSalle: (state) => (idSalle) => {
    if (idSalle) {
      return state.salles ? state.salles[idSalle] : null;
    } else {
      return state.salles;
    }
  },
  // Parcourir toutes les salles et retournée celle qui correspond au idSalleNyx
  getSalleByIdSalleNyx: (state) => (idSalleNyx) => {
    var idSalleTrouvee = state.salles["@nodes"].find(
      (idSalle) => state.salles[idSalle].idSalleNyx == idSalleNyx
    );
    return state.salles[idSalleTrouvee];
  },
  getSalleLive: (state) => (idSalle) => {
    return state.sallesLive ? state.sallesLive[idSalle] : null;
  },
  getAnimateur: (state) => (idAnimateur) => {
    return state.animateurs ? state.animateurs[idAnimateur] : null;
  },
  getAnimateurs: (state) => () => {
    return state.animateurs;
  },
  getGagnantsMajeurs: (state) => () => {
    return state.gagnantsMajeurs;
  },
  getGagnantsRecents: (state) => () => {
    return state.gagnantsRecents;
  },
};

const actions = {
  initSalles(context) {
    if (!state.isSallesLoaded) {
      context.commit("setSallesLoaded", true);
      /*BingoApi.getSalles(i18n.locale)
        .then(function (salles) {
          context.commit("setSalles", salles);
        })
        .catch((error) => console.log(error));*/
      context.dispatch("refreshSallesLive");
    }
    // Si la connexion Auth0 est active
    if (document.querySelector("#btn-ciam-auth0")) {
     /* BingoApi.launchBingoGame(i18n.locale);*/
    }
  },
  refreshSallesLive(context) {
    // console.log("isIdle:", context.rootState.idleVue.isIdle);
    // Corrige problème appel récurrent par le service-worker
    if (window.location.href.indexOf("/bingo/") > -1 && !context.rootState.idleVue.isIdle) {
      /*BingoApi.getSallesLive(i18n.locale)
        .then(function (sallesLive) {
          context.commit("setSallesLive", sallesLive);
        })
        .catch((error) => console.log(error));*/
    }
    // Délai de raffaichissement: 30 sec en PO, 10 sec en DEV. 2 x cache busting de api/Bingo.js
    var timerSallesLive = setTimeout(
      function () {
        context.dispatch("refreshSallesLive");
      },
      process.env.NODE_ENV == "production" ? 30000 : 10000
    );
  },

  initAnimateurs(context) {
    if (!state.isAnimateursLoaded) {
      context.commit("setAnimateursLoaded", true);
     /* BingoApi.getAnimateurs(i18n.locale)
        .then(function (animateurs) {
          context.commit("setAnimateurs", animateurs);
        })
        .catch((error) => console.log(error));*/
    }
  },

  initGagnantMajeurs(context) {
    if (!state.isGagnantsMajeursLoaded) {
      context.commit("setGagnantsMajeursLoaded", true);
      /*BingoApi.getGagnants(BingoApi.MAJEUR)
        .then(function (gagnantMajeurs) {
          context.commit("setGagnantsMajeurs", gagnantMajeurs);
        })
        .catch((error) => console.log(error));*/
    }
  },
  initGagnantRecents(context) {
    if (!state.isGagnantsRecentsLoaded) {
      context.commit("setGagnantsRecentsLoaded", true);
     /* BingoApi.getGagnants(BingoApi.RECENT)
        .then(function (gagnantRecents) {
          context.commit("setGagnantsRecents", gagnantRecents);
        })
        .catch((error) => console.log(error));*/
    }
  },
};

const mutations = {
  setSallesLoaded(state, isSallesLoaded) {
    state.isSallesLoaded = isSallesLoaded;
  },
  setSalles(state, salles) {
    state.salles = salles;
  },
  setSallesLive(state, sallesLive) {
    state.sallesLive = sallesLive;
  },

  setAnimateursLoaded(state, isAnimateursLoaded) {
    state.isAnimateursLoaded = isAnimateursLoaded;
  },
  setAnimateurs(state, animateurs) {
    state.animateurs = animateurs;
  },

  setGagnantsMajeursLoaded(state, isGagnantsMajeursLoaded) {
    state.isGagnantsMajeursLoaded = isGagnantsMajeursLoaded;
  },
  setGagnantsMajeurs(state, gagnantsMajeurs) {
    state.gagnantsMajeurs = gagnantsMajeurs;
  },
  setGagnantsRecentsLoaded(state, isGagnantsRecentsLoaded) {
    state.isGagnantsRecentsLoaded = isGagnantsRecentsLoaded;
  },
  setGagnantsRecents(state, gagnantsRecents) {
    state.gagnantsRecents = gagnantsRecents;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
