// Originally in ej-commmun-portail.js

const OSDetect = function() {
    return searchString(dataOS) || "-";
}

const searchString = function (data) {
    for (var i = 0; i < data.length; i++) {
        if (navigator.userAgent) {
            if (navigator.userAgent.indexOf(data[i].navCode) != -1) {
                var identity = data[i].identity;
                if (data[i].type == "Win") {
                    if (navigator.userAgent.indexOf("Win64") != -1 || navigator.userAgent.indexOf("WOW64") != -1) {
                        identity += " (64 bits)";
                    }
                }
                return identity;
            }
        }
    }
};

const dataOS = [
    {
        navCode: "Windows NT 6.1",
        type: "Win",
        identity: "Windows 7"
    },
    {
        navCode: "Windows NT 6.0",
        type: "Win",
        identity: "Windows Vista"
    },
    {
        navCode: "Windows NT 5.2",
        type: "Win",
        identity: "Windows Server 2003; Windows XP x64 Edition"
    },
    {
        navCode: "Windows NT 5.1",
        type: "Win",
        identity: "Windows XP"
    },
    {
        navCode: "Windows NT 5.01",
        type: "Win",
        identity: "Windows 2000(SP1)"
    },
    {
        navCode: "Windows NT 5.0",
        type: "Win",
        identity: "Windows 2000"
    },
    {
        navCode: "Windows NT 4.0",
        type: "Win",
        identity: "Windows NT 4.0"
    },
    {
        navCode: "Windows 98; Win 9x 4.90",
        type: "Win",
        identity: "Windows Millennium Edition"
    },
    {
        navCode: "Windows 98",
        type: "Win",
        identity: "Windows 98"
    },
    {
        navCode: "Windows 95",
        type: "Win",
        identity: "Windows 95"
    },
    {
        navCode: "Windows CE",
        type: "Win",
        identity: "Windows CE"
    },
    {
        navCode: "iPhone",
        type: "iPhone",
        identity: "iPhone/iPod"
    },
    {
        navCode: "Mac",
        type: "Mac",
        identity: "Mac"
    },
    {
        navCode: "Linux",
        type: "Linux",
        identity: "Linux"
    }
];

export default OSDetect;