// Déterminer l'environnement courant
var magnoliaPath;
var damPath;
var isPortailLeger = false;
var hostname = window.location.hostname;

if (hostname == 'localhost') {
    damPath = window.location.href.indexOf('magnoliaPublic') == -1 ? '/magnoliaAuthor' : '/magnoliaPublic';
    magnoliaPath = damPath + '/espacejeux';
} else {
    if (['www', 'm', 'portail'].includes(hostname.split('.')[0])) {
        damPath =  '';
        magnoliaPath =  '';
    } else {
        damPath =  '/magnoliaAuthor';
        magnoliaPath =  '/magnoliaAuthor/espacejeux';
    }
}

//console.log('damPath=' + damPath);
//console.log('magnoliaPath=' + magnoliaPath);

//var magnoliaPath =  '/magnoliaPublic';
//var damPath =  '/magnoliaPublic';

export const WebServicesAccess = {
    //TODO: Faire la sélection automatiquement

    MAGNOLIA_PATH: magnoliaPath,
    DAM_PATH: damPath,
    IS_PORTAIL_LEGER: isPortailLeger,

    //MAGNOLIA_PATH: '/magnoliaPublic',
    //DAM_PATH: '/magnoliaPublic',

    //MAGNOLIA_PATH: '/magnoliaAuthor/espacejeux',
    //DAM_PATH: '/magnoliaAuthor',
}