
import Vue from 'vue';
import Vuex from 'vuex';

import monCompte from './modules/mon-compte';
import casino from './modules/casino';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
  modules: {
	  monCompte,
  	casino,
  },
  strict: debug,
})

