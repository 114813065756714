var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.composantHerite
    ? _c(
        "div",
        { attrs: { "data-component-name": _vm.$options.name } },
        [
          _vm.composantHerite.typeComposante
            ? _c(_vm.composantHerite.typeComposante, {
                key: _vm.composantHerite["@id"],
                tag: "component",
                attrs: { contenu: _vm.composantHerite },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }