var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    {
      staticClass: "conteneur-carrousel p-0",
      class: _vm.isBingo ? "carrousel-bingo" : "",
      staticStyle: { "max-width": "none !important" },
      attrs: {
        "data-component-name": _vm.$options.name,
        fluid: _vm.contenu && _vm.contenu.fluid === "true",
      },
    },
    [
      !Array.isArray(_vm.contenu)
        ? _c(
            "b-carousel",
            {
              ref: "dynamicCarrousel",
              attrs: {
                id: "dynamicCarrousel",
                interval: _vm.isPaused
                  ? 0
                  : parseInt(_vm.contenu.carrouselTimer),
                indicators: _vm.withIndicators(_vm.isItDesktop()),
                controls: _vm.withControls(
                  _vm.isItDesktop(),
                  _vm.$mq,
                  "dynamic"
                ),
                "no-animation":
                  _vm.contenu.carrouselNoAnimation == "true" ? true : false,
              },
            },
            [
              (_vm.isItDesktop(_vm.$mq) ? _vm.contenu.carrouselPause : false)
                ? _c(
                    "div",
                    {
                      staticClass: "pause-carousel",
                      on: {
                        click: _vm.doPause,
                        mouseover: function ($event) {
                          return _vm.setPauseIcone(true)
                        },
                        mouseleave: function ($event) {
                          return _vm.setPauseIcone(false)
                        },
                      },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.pauseIconeSrc,
                            expression: "pauseIconeSrc",
                          },
                        ],
                        staticClass: "carousel_image_pause",
                        attrs: { alt: "" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.carrouselArray, function (aNode, index) {
                return _c(
                  "div",
                  {
                    key: aNode.id,
                    staticClass: "carousel-item",
                    class:
                      !_vm.isItDesktop() &&
                      _vm.carrouselArray[0].column[0].formatAffichage ==
                        "tit25img50txt25"
                        ? "mobile-carrousel"
                        : null,
                  },
                  [
                    _vm.isFullWidth
                      ? _c(
                          "div",
                          { staticClass: "row flex-nowrap" },
                          [
                            [
                              _vm.carrouselArray[index].typeComposante
                                ? _c(_vm.carrouselArray[index].typeComposante, {
                                    key: _vm.carrouselArray[
                                      index % _vm.carrouselArray.length
                                    ]["@id"],
                                    tag: "component",
                                    class: _vm.isBingo ? "carrousel-bingo" : "",
                                    attrs: {
                                      contenu:
                                        _vm.carrouselArray[
                                          index % _vm.carrouselArray.length
                                        ],
                                      "data-component-type":
                                        _vm.carrouselArray[index]
                                          .typeComposante,
                                    },
                                  })
                                : _vm._e(),
                            ],
                          ],
                          2
                        )
                      : _c(
                          "div",
                          { staticClass: "row flex-nowrap" },
                          [
                            _vm._l(_vm.carrouselArray, function (aNode, idx) {
                              return [
                                _vm.carrouselArray[idx].typeComposante
                                  ? _c(_vm.carrouselArray[idx].typeComposante, {
                                      key: _vm.carrouselArray[
                                        (index + idx) %
                                          _vm.carrouselArray.length
                                      ]["@id"],
                                      tag: "component",
                                      class: _vm.isBingo
                                        ? "carrousel-bingo"
                                        : "",
                                      attrs: {
                                        contenu:
                                          _vm.carrouselArray[
                                            (index + idx) %
                                              _vm.carrouselArray.length
                                          ],
                                        "data-component-type":
                                          _vm.carrouselArray[idx]
                                            .typeComposante,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }