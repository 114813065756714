import Vue from "vue";
import { mapGetters } from "vuex";
import { i18n, mixinLangue } from "../../mixins/mixinLangue";
import mixinUserMessages from "../../mixins/mixin-user-messages";
import mixinProductSource from "../../mixins/mixin-product-source";
import {
  isEmptyJsonObj,
  urlParamsValues,
  protectedCTA,
  protectedPage,
  detectVbrowser
} from "../../mixins/mixin-utils";
import { lqBetween } from "../../mixins/mixin-value-validation";

import Loading from "../../components/loading/Loading.vue";
import MonCompteFilAriane from "../../components/mon-compte/MonCompteFilAriane.vue";
import { store } from "../../store/store";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import Cookies from '../../../js/components/cookies';

import "whatwg-fetch";

document.addEventListener("DOMContentLoaded", function() {
  if (document.getElementById("vueMonCompteDepotApp")) {
    new Vue({
      el: "#vueMonCompteDepotApp",
      i18n,
      directives: { mask },
      components: {
        Loading,
        MonCompteFilAriane
      },
      mixins: [
        mixinLangue,
        mixinUserMessages,
        validationMixin,
        isEmptyJsonObj,
        urlParamsValues,
        mixinProductSource,
        protectedCTA,
        protectedPage,
        detectVbrowser
      ],
      store: store,
      data: function() {
        return {
          activeTab: 0,
          amount: null,
          isBlurAmount: false,
          payIframe: null,
          depositInfo: null,
          isMobile: null,
          isDropDownShown: null,
          isLoading: false,
          messages: {
            choose: this.$t("monCompte.choixMethodeDepot"),
            amount: {
              title: "",
              details: ""
            },
            voucher: {
              title: "",
              details: ""
            },
            billPayment: {
              title: "",
              details: ""
            }
          },
          paymentType: null,
          showAmountForm: true,
          showMenu: true,
          uuid: null,
          uuidPage: null,
          showResponse: {
            forCredit: false,
            forVoucher: false,
            forInterac: false,
            forBillPayment: false
          },
          desactiveForm: {
            forAmount: false,
            forVoucher: false
          },
          voucherHasError: false,
          globalError: false,
          showVoucherForm: false,
          tabs: [],
          voucher: null,
          isBlurVoucher: false,
          voucherMask: null,
          productSource: null,
          isExistingReferrer: false,
          isFirstDeposit: false,
          billPaymentAccountNumber: null,
          webcashWeeklyLimitAmount: null,
          min: 0,
          max: 0
        };
      },

      validations() {
        return {
          amount: {
            required,
            minLength: minLength(2),
            //lqDecimal: lqDecimal(this),
            lqBetween: lqBetween(this.min, this.max),
            isValideAmount: () => {
              if (this.paymentType == "Interac") {
                if (['MA_LT', 'MA_MJ', 'MA_CA'].indexOf(this.productSource) >= 0) {
                  return false;
                }
                if (
                  this.desactiveForm.forAmount &&
                  this.depositInfo.maximumDepositAmount > 0 &&
                  this.depositInfo.cardAllowDeposit
                ) {
                  if (this.isCreditCardAttemptsReached()) {
                    return true;
                  }
                }
              }
              return !this.desactiveForm.forAmount;
            }
          },
          voucher: {
            required,
            minLength: minLength(21),
            format: (voucher) =>
              /^([0]|[2-7A-Z]){4}-([0]|[2-7A-Z]){4}-([0]|[2-7A-Z]){4}-([0]|[2-7A-Z]){4}-([0]|[2-7A-Z])$/.test(
                voucher
              ),
            isValideVoucher: () => !this.desactiveForm.forVoucher
          }
        };
      },
      created: function() {
        this.tabs = [
          {
            id: "credit",
            label: "monCompte.credit",
            paymentType: "Cards",
            showAmountForm: true,
            showVoucherForm: false
          },
          {
            id: "interac",
            label: "monCompte.interac",
            paymentType: "Interac",
            showAmountForm: true,
            showVoucherForm: false
          },
          {
            id: "argentWeb",
            label: "monCompte.argentWeb",
            paymentType: "Webcash",
            showAmountForm: false,
            showVoucherForm: true
          },
          {
            id: "facture",
            label: "monCompte.facture",
            paymentType: "billPayment",
            showAmountForm: false,
            showVoucherForm: false
          }
        ];
        const md = new MobileDetect(navigator.userAgent);
        this.isMobile = md.phone();
        this.isDropDownShown = window.matchMedia("(max-width: 1200px)").matches;
        if (this.isDropDownShown) {
          this.activeTab = -1;
          this.showAmountForm = false;
          this.showVoucherForm = false;
          this.showMenu = false;
        } else {
          this.paymentType = "Cards";
        }
        this.voucherMask = {
          mask: "VVVV-VVVV-VVVV-VVVV-V",
          tokens: {
            V: {
              pattern: /[0-9a-zA-Z]/,
              transform: function(v) {
                return v.toLocaleUpperCase();
              }
            }
          }
        };
      },
      computed: {
        ...mapGetters(["getAccountUserMessages", "getProductSourceInfo"])
      },

      watch: {
        isDropDownShown(newState, oldState) {
          if (newState !== oldState) {
            //Reinitinlisation de l'index de la tab dans le cas d'un ecran desktop (dropdown invisible)
            if (!newState && this.activeTab < 0) {
              this.activeTab = 0;
            }
            this.onTabClick(this.activeTab > -1 ? this.tabs[this.activeTab] : undefined);
          }
        }
      },

      beforeMount() {
        this.uuid = this.$el.attributes["data-uuid"].value;
        this.uuidPage = this.$el.attributes["data-uuid-page"].value;
        let editMode = this.$el.attributes["data-edit"].value === "true";
        if (!editMode) {
          this.productSource = this.getUrlParameter("product_source");
          this.isFirstDeposit = this.getUrlParameter("first") !== null;
          this.paymentProfile();
          this.isExistingReferrer = document.referrer !== "";
        }
      },

      mounted: function() {
        const vm = this;
        vm.payIframe = document.getElementById("iframePaysafe");
        vm.voucherMobileAppCheck();
        // Enregistrement de l'evennement click sur tous les boutons sauf pour les mobiles App
        // a cause de l'authentification par un autre chemin que loginNav
        vm.isAuthorisedCTA(); //vm.productSource == 'MA_LT'
        // vm.isLoading = false;
        vm.$nextTick(() => {
          window.addEventListener("resize", vm.onResize);
        });
        const domainIframe = vm.$el.attributes["data-domain-iframe"].value;
        // Enregistrement du post message
        window.addEventListener("message", function(event) {
          if (event.origin === domainIframe) {
            if (event.data) {
              vm.isLoading = false;
              switch (event.data.etat) {
                case "Error":
                  // $('#modalPaysafe').modal('hide');
                  vm.showORClearTabMessage(true);
                  vm.removePayIframe(event.data);
                  vm.messages.amount.details = vm.getAccountUserMessages["MSG02"].details;
                  console.log("Error-code :" + JSON.stringify(event.data));
                  break;
                case "BeforePayment":
                case "DuringPayment":
                  $("#modalPaysafe").modal("hide");
                  vm.clearForms();
                  // vm.viewCardsNotification(event.data.options);
                  break;
                case "AfterPayment":
                  $("#modalPaysafe").modal("hide");
                  vm.isLoading = true;
                  vm.clearForms();
                  vm.showORClearTabMessage(false);

                  if (vm.isExistingLinkForProductSource()) {
                    console.log(
                      "getProductSourceInfo ==",
                      vm.getProductSourceInfo[vm.productSource]
                    );
                    let qr = "";

                    if (['MA_LT', 'MA_MJ', 'MA_CA'].indexOf(vm.productSource) >= 0) {
                      qr = "?type=" + vm.paymentType.toLowerCase();
                    }
                    window.location.href = vm.getProductSourceInfo[vm.productSource] + qr;
                  } else {
                    // Mettre à jour le solde
                    //LQ_updateBalance();
                    vm.isLoading = false;
                  }
                  break;
                default:
                  vm.processDeposit(event.data);
              }
            }
          }
        });
      },
      methods: {
        clearForms: function() {
          const vm = this;
          console.log("clearForms");
          vm.amount = "";
          vm.voucher = "";
        },
        onTabClick: function(tab) {
          if (tab !== undefined) {
            this.setPaymentType(tab.paymentType);
            this.toggleForms(tab);
            this.setDropdownLabel(tab.label);
            this.toggleMenu();
            this.clearForms();
            this.showORClearTabMessage(false);
            this.activeTab = this.tabs.indexOf(tab);
          }
        },
        ejPostMessage: function(message) {
          if (!document.getElementById("iframePaysafe")) {
            this.payIframe.onload = function() {
              console.log("postMessage | message: ", message);
              let ifr = document.getElementById("iframePaysafe");
              ifr.contentWindow.postMessage(message, ifr.src);
            };
            document.getElementById("modalPaysafe").appendChild(this.payIframe);
          } else {
            console.log("postMessage | message: ", message);
            this.payIframe.contentWindow.postMessage(message, this.payIframe.src);
          }
          $("#modalPaysafe").modal("show");
        },
        removePayIframe: function(data) {
          //Fermer proprement l'instance paysafe
          data.status = "INSTANCETOCLOSE";
          this.ejPostMessage(data);
          $("#modalPaysafe").modal("hide");
          //detruire l'instance
          this.payIframe.parentNode.removeChild(this.payIframe);
        },
        processDeposit: function(edata) {
          const vm = this;
          console.log("processDeposit");
          let myheaders = new Headers();
          myheaders.append('pragma', 'no-cache');
          myheaders.append('cache-control', 'no-cache');
          myheaders.append('JEL-XTOKEN', Cookies.get("Jel-csrf"));
          let ajaxData = new FormData();
          ajaxData.append("defaultCard", edata.setInstrumentAsDefault || false);
          ajaxData.append("paymentType", edata.paymentMethod);
          ajaxData.append("saveCard", edata.saveInstrument || false);
          ajaxData.append("sut", edata.token);
          ajaxData.append("amount", edata.amount);
          ajaxData.append("channel", vm.isMobile ? "M" : "I");
          fetch("/services/depot", {
            method: "POST",
            headers: myheaders,
            body: ajaxData
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              console.log("processDeposit | response ", data);
              vm.isLoading = false;
              if (data.status === "SUCCESS") {
                data.messages = vm.getAccountUserMessages["MSG04-06"];
                console.log(data.messages);
                
                vm.updateDepositInfo(data.depositInfo);
                vm.ejPostMessage(data);
                vm.clearForms();
              } else if (data.status === "error") {
                if (data.depositInfo) {
                  vm.updateDepositInfo(data.depositInfo);
                }
                // Spécifique au dépot a cause du mobile-app
                if (data.connexion === "invalide") {
                  vm.returnToHome(vm.resolveReturningPage());
                } else if (vm.isCreditCardAttemptsReached()) {
                  vm.removePayIframe(data);
                } else {
                  data.messages = vm.getAccountUserMessages[data.code];
                  vm.ejPostMessage(data);
                }
              }
            })
            .catch((error) => {
              console.log("processDeposit | fail ", error);
              vm.isLoading = false;
              vm.ejPostMessage({ messages: vm.getAccountUserMessages["MSG01"] });
            });
        },
        setDropdownLabel: function(label) {
          console.log("setDropdownLabel: ", label);
          this.messages.choose = this.$t(label);
        },
        setPaymentType: function(type) {
          console.log("setPaymentType: ", type);
          this.paymentType = type;
        },
        initPaysafeFrame: function() {
          const vm = this;
          vm.isLoading = true;
          vm.showORClearTabMessage(false);
          console.log("initPaysafeFrame");
          let myheaders = new Headers();
          myheaders.append('pragma', 'no-cache');
          myheaders.append('cache-control', 'no-cache');
          myheaders.append('JEL-XTOKEN', Cookies.get("Jel-csrf"));
          let ajaxData = new FormData();
          ajaxData.append("paymentType", vm.paymentType);
          ajaxData.append("amount", vm.amount);          
          fetch("/services/depot/init", {
            method: "POST",
            headers: myheaders,
            body: ajaxData
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              vm.isLoading = false;
              console.log("initPaysafeFrame | response ", data);
              if (data.status == "INITCARD" || data.status == "INITINTERAC") {
                data.payMethod = [vm.paymentType];
                vm.ejPostMessage(data);
              } else if (data.status == "global-error") {
                vm.globalError = true;
                vm.messages.amount.details = vm.getAccountUserMessages[data.code].details;
              } else {
                if (data.connexion == "invalide") {
                  //Specifique au dépot a cause du mobile-app
                  vm.returnToHome(vm.resolveReturningPage());
                } else {
                  vm.showORClearTabMessage(true);
                  vm.messages.amount.details = vm.getAccountUserMessages[data.code].details;
                }
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              console.log("initPaysafeFrame | fail ", error);
              vm.showORClearTabMessage(true);
              vm.messages.amount.details = vm.getAccountUserMessages["MSG02"].details;
            });
        },
        submitAmount: function() {
          const vm = this;
          const amount = vm.$v.amount;
          console.log("submitAmount");
          console.log("invalid amount: ", amount.$invalid);
          if (amount.$invalid) {
            vm.showORClearTabMessage(true); //vm.showResponse.forCredit = true;
            vm.messages.amount.details = this.userMessages["MSG04-11"].details;
            return;
          } else {
            vm.initPaysafeFrame();
          }
        },
        submitVoucher: function() {
          const vm = this;
          const voucher = vm.$v.voucher;
          vm.showResponse.forVoucher = false;
          if (voucher.$invalid) {
            return;
          } else {
            vm.processVoucher();
          }
        },
        processVoucher: function() {
          const vm = this;
          console.log("processVoucher");
          vm.isLoading = true;
          vm.showORClearTabMessage(false);
          let myheaders = new Headers();
          myheaders.append('pragma', 'no-cache');
          myheaders.append('cache-control', 'no-cache');
          myheaders.append('JEL-XTOKEN', Cookies.get("Jel-csrf"));
          let ajaxData = new FormData();
          ajaxData.append("webcashToken", vm.voucher);
          ajaxData.append("lang", vm.mixinLangue_langue);
          ajaxData.append("channel", vm.isMobile ? "M" : "I");
          fetch("/services/depot/argent-web", {
            method: "POST",
            headers: myheaders,
            body: ajaxData
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              console.log("processVoucher | response ", data);
              vm.isLoading = false;
              vm.updateDepositInfo(data.depositInfo);
              if (data && data.status == "SUCCESS") {
                if (['MA_LT', 'MA_MJ', 'MA_CA'].indexOf(vm.productSource) >= 0) {
                  console.log("getProductSourceInfo ==", vm.getProductSourceInfo[vm.productSource]);
                  window.location.href =
                    vm.getProductSourceInfo[vm.productSource] +
                    "?type=" +
                    vm.paymentType.toLowerCase();
                } else {
                  vm.messages.voucher.details = vm.getAccountUserMessages["MSG04-06"].details;
                  vm.showResponse.forVoucher = true;
                  vm.voucherHasError = false;
                  vm.clearForms();
                  //Mise à jour solde
                  //LQ_updateBalance();
                  // setTimeout(() => {
                  // 	vm.updateDepositInfo(data.depositInfo);
                  // }, 6000);
                }
              } else if (data && data.connexion == "invalide") {
                // Specifique au dépot a cause du mobile-app
                vm.returnToHome(vm.resolveReturningPage());
              } else if (data && data.status == "global-error") {
                vm.globalError = true;
                vm.messages.amount.details = vm.getAccountUserMessages[data.code].details;
              } else {
                // vm.updateDepositInfo(data.depositInfo);
                if (data.code == "MSG04-30" || data.code == "MSG04-3-15") {
                  vm.messages.voucher.details = vm.replaceMessageVariable(
                    data.code,
                    "webcashWeeklyLimitAmount",
                    vm.depositInfo.webcashWeeklyLimitAmount
                  );
                } else {
                  vm.messages.voucher.details = vm.getAccountUserMessages[data.code].details;
                }
                vm.showResponse.forVoucher = true;
                vm.voucherHasError = true;
              }
            })
            .catch((error) => {
              console.log("processDeposit | fail ", error);
              vm.isLoading = false;
              vm.voucherHasError = true;
              vm.messages.voucher.details = vm.getAccountUserMessages["MSG01"].details;
              vm.showResponse.forVoucher = true;
            });
        },
        viewCardsNotification: function(opts) {
          if (opts && opts.displayPaymentMethods) {
            if (opts.displayPaymentMethods.includes("Interac")) return;
          }
          console.log("viewCardsNotification");
          let myheaders = new Headers();
          myheaders.append('pragma', 'no-cache');
          myheaders.append('cache-control', 'no-cache');
          myheaders.append('JEL-XTOKEN', Cookies.get("Jel-csrf"));
          let ajaxData = new FormData();
          ajaxData.append("paymentToken", opts.singleUseProfileToken);
          fetch("/services/depot/notifications", {
            method: "POST",
            headers: myheaders,
            body: ajaxData
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              console.log("viewCardsNotification | response ", data);
            })
            .catch((error) => {
              console.log("processDeposit | fail ", error);
            });
        },

        voucherMobileAppCheck: function() {
          // Utilisé pour les appels mobile App
          const vm = this;
          if (['MA_LT', 'MA_MJ', 'MA_CA'].indexOf(vm.productSource) >= 0) {
            let valueVoucher = vm.getUrlParameter("voucher");
            if (valueVoucher) {
              const link = document.getElementById("argentWeb-tab");
              link.click();
              // vm.onTabClick(vm.tabs[2]); //Positionnement sur l'onglet webcash
              vm.voucher = valueVoucher;
              vm.submitVoucher();
            }
          }
        },
        toggleForms: function(obj) {
          const vm = this;
          console.log("toggleForms");
          vm.showAmountForm = obj.showAmountForm;
          vm.showVoucherForm = obj.showVoucherForm;
        },
        toggleMenu: function() {
          const vm = this;
          console.log("toggleMenu");
          if (vm.isDropDownShown) {
            vm.showMenu = false; // !vm.showMenu;
          } else {
            vm.showMenu = true;
          }
        },
        returnToOrigin: function() {
          if (this.isExistingLinkForProductSource()) {
            window.location.href = this.getProductSourceInfo[this.productSource];
          } else if (document.referrer !== "") {
            window.location.href = document.referrer;
          }
        },
        isExistingLinkForProductSource: function() {
          return this.productSource && this.getProductSourceInfo[this.productSource];
        },
        updateDepositInfo: function(depInfo) {
          if (depInfo) {
            this.depositInfo = depInfo;
            this.min = depInfo.cardMinimumAmount;
            this.max = depInfo.maximumDepositAmount;
            this.webcashWeeklyLimitAmount = depInfo.webcashWeeklyLimitAmount;
            this.billPaymentAccountNumber = depInfo.billPaymentAccountNumber;
          }
          this.showMessageToUser();
        },
        isCreditCardAttemptsReached: function() {
          return (
            this.depositInfo.creditCardFailedAttempts >=
              this.depositInfo.creditCardFailedAttemptsThreshold &&
            moment().isBefore(moment(this.depositInfo.creditCardLockDate))
          );
        },
        isWebCashAttemptsReached: function() {
          return (
            this.depositInfo.webcashFailedAttempts >=
              this.depositInfo.webcashFailedAttemptsThreshold &&
            moment().isBefore(moment(this.depositInfo.webcashLastAttemptDate).add(1, "days"))
          );
        },
        showMessageToUser: function() {
          this.isLoading = false;
          // Traitement commun entre CC/IO et webcash
          if (this.isEmptyJson(this.depositInfo)) {
            this.globalError = true;
            this.messages.amount.details = this.getAccountUserMessages["MSG01"].details;
          } else if (this.depositInfo.isBlocked) {
            this.globalError = true;
            console.log("msg :" + this.getAccountUserMessages["MSG04-50"].details);
            this.messages.amount.details = this.getAccountUserMessages["MSG04-50"].details;
          } else {
            // Traitement CC
            if (this.depositInfo.maximumDepositAmount == 0) {
              this.showResponse.forCredit = true;
              this.showResponse.forInterac = true;
              this.showResponse.forVoucher = true;
              this.voucherHasError = true;
              this.showResponse.forBillPayment = true;
              this.desactiveForm.forAmount = true;
              this.messages.amount.details = this.getAccountUserMessages["MSG04-35"].details;
              this.messages.billPayment.details = this.getAccountUserMessages["MSG04-35"].details;
            } else {
              if (!this.depositInfo.cardAllowDeposit) {
                this.showResponse.forCredit = true;
                this.showResponse.forInterac = true;
                this.desactiveForm.forAmount = true;
                this.messages.amount.details = this.getAccountUserMessages["MSG04-50"].details;
              } else if (this.isCreditCardAttemptsReached()) {
                this.showResponse.forCredit = true;
                this.desactiveForm.forAmount = true;
                this.messages.amount.details = this.getAccountUserMessages["MSG04-20"].details;
              } else if (this.depositInfo.maximumDepositAmount < this.min) {
                this.showResponse.forCredit = true;
                this.showResponse.forInterac = true;
                this.desactiveForm.forAmount = true;
                this.messages.amount.details = this.getAccountUserMessages["MSG04-35"].details;
              }
            }
            // Traitement webcash
            // la méthode webcash doit être présente pour verifier les autorisations
            // le 1er dépot webcash n'a pas methode, on le laisse passé
            if (this.depositInfo.webcashPayMethodId) {
              if (!this.depositInfo.webcashAllowDeposit) {
                this.showResponse.forVoucher = true;
                this.voucherHasError = true;
                this.desactiveForm.forVoucher = true;
                this.messages.voucher.details = this.replaceMessageVariable(
                  "MSG04-30",
                  "webcashWeeklyLimitAmount",
                  this.depositInfo.webcashWeeklyLimitAmount
                );
              }
              if (this.depositInfo.webcashMaximumAmount == 0) {
                this.showResponse.forVoucher = true;
                this.voucherHasError = true;
                this.desactiveForm.forVoucher = true;
                if (this.depositInfo.maximumDepositAmount == 0) {
                  this.messages.voucher.details = this.getAccountUserMessages["MSG04-35"].details;
                } else {
                  this.messages.voucher.details = this.replaceMessageVariable(
                    "MSG04-30",
                    "webcashWeeklyLimitAmount",
                    this.depositInfo.webcashWeeklyLimitAmount
                  );
                }
              } else if (this.isWebCashAttemptsReached()) {
                this.showResponse.forVoucher = true;
                this.voucherHasError = true;
                this.desactiveForm.forVoucher = true;
                this.messages.voucher.details = this.getAccountUserMessages["MSG04-20"].details;
              }
            }
          }
          // HACK Pour safari
          if (this.isSafari()) {
            document.getElementById("account__deposit_id").focus();
            document.getElementById("account__deposit_id").blur();
          }
        },

        replaceMessageVariable: function(code, varName, varValue) {
          let leMessage = "";
          if (this.getAccountUserMessages[code].details) {
            leMessage = this.getAccountUserMessages[code].details.replace(
              "{{" + varName + "}}",
              varValue
            );
          }
          return leMessage;
        },

        showORClearTabMessage: function(value) {
          if (this.paymentType == "Cards" && !this.desactiveForm.forAmount) {
            this.showResponse.forCredit = value;
          } else if (this.paymentType == "Interac" && !this.desactiveForm.forAmount) {
            this.showResponse.forInterac = value;
          } else if (this.paymentType == "Webcash" && !this.desactiveForm.forVoucher) {
            this.showResponse.forVoucher = value;
          }
        },
        resolveReturningPage: function() {
          return (['MA_LT', 'MA_MJ', 'MA_CA'].indexOf(vm.productSource) >= 0) ? this.getProductSourceInfo[this.productSource] : "";
        },
        onResize: function() {
          this.isDropDownShown = window.matchMedia("(max-width: 1200px)").matches;
        },
        paymentProfile: function() {
          const vm = this;
          if (!vm.isSafari()) {
            vm.isLoading = true;
          }
          console.log("paymentProfile");
          fetch("/services/profile?isDeposit=true&isWithdrawal=false")
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              console.log("paymentProfile | response ", data);
              if (data.status === "success") {
                if (data.PLAYER_STATUS === "PLAYER") {
                  vm.isLoading = false;
                  vm.updateDepositInfo(data.depositInfo);
                } else if (data.PLAYER_STATUS === "GUEST") {
                  window.location.href = vm.appendObjParamsToUri(
                    vm.$t("navUrl.VALIDATION_IDENTITE")
                  );
                } else if (data.PLAYER_STATUS === "DEPOSITLIMIT") {
                  window.location.href = vm.appendObjParamsToUri(
                    vm.$t("navUrl.LIMIT_DEPOT"),
                    "first=1"
                  );
                } else if (data.PLAYER_STATUS === "NONWAGER") {
                  window.location.href = vm.$t("navUrl.ACCUEIL");
                } else {
                  console.log(vm.$t("navUrl.ACCUEIL"));
                  window.location.href = vm.$t("navUrl.ACCUEIL");
                }
              } else if (data.status === "error") {
                window.location.href = vm.$t("navUrl.ACCUEIL");
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              console.log("initPaysafeFrame | fail ", error);
              window.location.href = vm.$t("navUrl.ACCUEIL");
            });
        }
      }
    });
  }
});
