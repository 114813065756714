<template>
	<b-col :data-component-name="$options.name"
         :class="[contenu.colXxs,contenu.colXs,contenu.colSm,contenu.colMd,contenu.colLg,contenu.colXl,contenu.colXxl,contenu.colXxxl,contenu.displayAt,contenu.hideUntil, contenu.margin, contenu.cssClass, 'col-xs-no-padding']">
    <template v-for="aNode in contenu.column['@nodes']">
			<component v-if="contenu.column[aNode].typeComposante"
                 v-bind:is="contenu.column[aNode].typeComposante"
                 :key="contenu.column[aNode]['@id']"
                 :contenu="contenu.column[aNode]"></component>
    </template>
	</b-col>
</template> 

<script>
  import {WebServicesAccess} from '../../../config';

  // Trier Alpha
  import Alerte from "../../base/Alerte.vue";
  import GestionFiltre from "../../filtres/GestionFiltre.vue";
  import MediaImage from "../../media/image/MediaImage.vue";
  import MediaOffre from "../../media/MediaOffre.vue";
  import MediaTexte from "../../media/MediaTexte.vue";
  import MediaVideo from "../../media/MediaVideo.vue";
  import Tableau from "../../base/Tableau.vue";
  import TexteHTML from "../../base/TexteHTML.vue";

  export default {
    name:"Column",
    components: {
        Alerte,
        GestionFiltre,
        MediaImage,
        MediaOffre,
        MediaTexte,
        MediaVideo,
        Tableau,
        TexteHTML,
    },
    data(){
        return{
        }
    },
    props: ["contenu"],
    methods:{
    },
    created: function () {
	}
  }
</script>

<style lang="scss" scoped>
	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
  }

  .element-margin > div {
    margin-bottom: 3rem;
  }
  
   @media screen and (max-width: 768px) {
    .col-sm-no-padding 
      {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }  
  }

  @media screen and (max-width: 576px) {
    .col-xs-no-padding 
      {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }  
  }
</style>