<template>
  <div :data-component-name="$options.name" class="h-100 margins">
    <div :style="styleObject" class="general h-100">
      <div class="div_general">
        <div class="space-between">
          <span
            v-if="contenu.idJeu != null"
            :class="['progressif ' + contenu.tailleProgressif + ' ' + contenu.idJeu]"
          ></span>
          <div>
            <div
              class="surTitre graphik_bold"
              :style="[{ color: contenu.couleurTitre }]"
              v-html="contenu.surTitre"
            ></div>
            <div
              class="titre_mediaImage graphik_bold"
              :style="[{ color: contenu.couleurTitre }]"
              v-html="contenu.titre"
            ></div>
          </div>
          <a
            :href="getMediaImageUrl(contenu)"
            class="stretched-link publicite-en-vedette"
            :ga-img-name="imageName"
            :data-tag="
              getNomRouteCourante() +
              ' - ' +
              contenu.surTitre +
              ' - ' +
              contenu.titre.replace(/&nbsp;/g, ' ') +
              ' - ' +
              contenu.dataTag
            "
          >
            <div v-if="contenu.texteBouton">
              <button
                class="btn mt-2 graphik_semibold texteBouton"
                :style="[
                  { 'border-color': contenu.borderColorBtn },
                  { 'background-color': contenu.colorBtn },
                ]"
              >
                {{ contenu.texteBouton }}
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaImageTit25img50txt25",
  props: ["contenu"],
  data() {
    return {};
  },
  computed: {
    classObject: function () {
      return {
        rounded: this.contenu.coinArrondi,
      };
    },
    imageName: function () {
      if (this.contenu.bgImage && this.contenu.bgImage.indexOf("jcr:") > 0) {
        return this.contenu.bgImage.substring(
          parseInt(this.contenu.bgImage.lastIndexOf("/") + 1),
          this.contenu.bgImage.length
        );
      } else {
        return " ";
      }
    },
    styleObject: function () {
      return {
        "background-color": this.contenu.secBgThemeColor,
      };
    },
  },
  created: function () {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.div_general {
  color: white;
  padding-left: 2%;
  //padding-top:25%;
  margin: -2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.space-between {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 37%;
}

.surTitre {
  font-size: ms(-3);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.titre_mediaImage {
  text-transform: uppercase;
  margin-bottom: 0;
  @include ms-respond(font-size, 0);
}

.btn {
  color: black;
  border-radius: ms(6);
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 0.5rem !important;
  font-size: ms(-1);
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-bottom: 1.5rem !important;
}

.btn:hover {
  opacity: 1 !important;
}

.h3 {
  margin-bottom: 0;
}

.coinsArrondis {
  border-radius: ms(1);
}

.margins {
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.texteBouton {
  margin-top: 0px;
  @include ms-respond(font-size, -1);
}
</style>
