var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        anon: _vm.indAudienceAnonyme,
        cl: _vm.indAudienceClientLeger,
        ci: _vm.indAudienceClientInvite,
        cj: _vm.indAudienceClientJoueur,
        [_vm.dnone]: _vm.cacherContenu,
      },
    },
    [
      _vm._l(_vm.contenu.filtres["@nodes"], function (aNode) {
        return [
          _vm.contenu.filtres[aNode].typeComposante
            ? _c(_vm.contenu.filtres[aNode].typeComposante, {
                key: _vm.contenu.filtres[aNode]["@id"],
                tag: "component",
                attrs: { contenu: _vm.contenu.filtres[aNode] },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }