
import Vue from 'vue'
import VueI18n from 'vue-i18n';

var mixinLangue = {
	computed : {
		mixinLangue_langue : function() {
			return ( document.querySelector('html').getAttribute('lang') === 'en' ) ? 'en' : 'fr';
		}
	},
}

function loadLocaleMessages () {
	const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
	  const matched = key.match(/([A-Za-z0-9-_]+)\./i)
	  if (matched && matched.length > 1) {
		const locale = matched[1]
		messages[locale] = locales(key)
	  }
	})
	return messages
  }

Vue.use(VueI18n)

// Vue I18n is internationalization plugin of Vue.js.
const i18n = new VueI18n({
	// TODO : Trouver une façon pour appeler la fonction mixinLangue_langue
	locale : ( document.querySelector('html').getAttribute('lang') === 'en' ) ? 'en' : 'fr', // set locale
	fallbackLocale : 'fr',
	messages : loadLocaleMessages(), // set locale messages
})

export { mixinLangue, i18n };