
// Ajout du fichier dans le DOM du formulaire
export default {
	methods: {
		addFile() {
			let files = {};
			const eventType = event.type;
			const vm = this;
			
			if (eventType === 'drop') {
				event.preventDefault();
				vm.$dropzone.classList.remove('dropzone-is_dragenter');
				files = event.dataTransfer.files;
			} else {
				files = event.target.files;
			}
			
			// À chaque ajout de fichier, on retire les messages d'erruers génériques.
			vm.error.filesMultiple = false;
			vm.error.sending = false;
			vm.error.size = false;
			vm.error.type = false;

			// On bloque les ajouts multiples
			if (files.length > 1) {
				vm.error.filesMultiple = true;
				return;
			}

			Array.from(files).forEach(function (file) {
				// Validation Front des fichiers
				if (vm.filesCount >= vm.filesMax) { // Si nombre maximum de fichiers est atteint
					vm.error.filesCount = true;
					return;
				} else
					if (vm.fileTypes.indexOf(file.type) === -1) { // Si le format n'est pas supporté
						vm.error.type = true;
						return;
					} else if (file.size > vm.fileSizeMax) { // Si le poids du fichiers est trop lourd
						vm.error.size = true;
						return;
					}

				let fileClasses = [];
				const fileType = file.type;

				// Si le fichier courant a le même nom qu'un fichier déjà ajouté, on averti l'utilisateur.
				const items = vm.$el.querySelectorAll('.televersement__preview-item-name');

				for (let i = 0; i < items.length; i++) {
					if (vm.filesNames[i] === file.name) { // Si le même fichier a déjà été ajouté, on affiche un avertissement.
						if (vm.fileNameDuplicates === false) {
							vm.fileNameDuplicates = true;
						}

						items[i].classList.add(`color-${vm.colorError}`);
						fileClasses.push(`color-${vm.colorError}`);
						vm.error.identicalName = true;
						break;
					}
				};

				const reader = new FileReader();

				vm.files.push(file);
				vm.filesNames.push(file.name);


				reader.readAsDataURL(file);

				reader.onloadend = function () {
					const $newItem = document.createElement('div');
					const imgSrc = fileType === 'application/pdf' ? '/.resources/ej-template-theme/img/icons/icon-pdf.jpg' : reader.result;
					const fileName = file.name.length > vm.fileNameLengthMax ? file.name.substring(0, vm.fileNameLengthMax) + '...' : file.name;

					$newItem.innerHTML = `
					<button type="button" class="televersement__preview-cta-delete" data-action="deleteFile">x</button>
					<img class="televersement__preview-image" src="${imgSrc}" alt="" />
					<span class="televersement__preview-item-name ${fileClasses}">${fileName}</span>
					
				`;

					$newItem.className = ('televersement__preview-item animated zoomIn');
					$newItem.setAttribute('data-is', 'file');
					vm.$el.querySelector('.televersement__preview-area').appendChild($newItem);
					$newItem.querySelector('[data-action="deleteFile"]').addEventListener('click', vm.deleteFile( file ));

					vm.filesWeakmap.set($newItem, {name: file.name}); // On ajoute une référence de l'élément avec son nom original dans le cas où l'on doit le tronquer.
				}

				vm.filesCount++;

				if (eventType === 'change') {
					vm.$el.querySelector('.input-file').value = '';
				}

			});
		}
	}
}
