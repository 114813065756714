var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.seats > _vm.seatsTaken.length
    ? _c(
        "div",
        { staticClass: "BlackJackSeats--1ClkO" },
        _vm._l(_vm.seats, function (seat, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "BlackJackSeatWrap--30yC6",
              attrs: { "data-role": "bj-direct-seat", seat: seat },
            },
            [
              _c("div", { staticClass: "BlackJackSeat--i2NtA" }, [
                _c(
                  "svg",
                  {
                    staticClass: "BlackJackSeatIcon--1xNAH",
                    class: _vm.seatsTaken.includes(seat - 1)
                      ? "occupied--3_9li"
                      : "free--3woOZ",
                    attrs: {
                      hover: "{color: 'white', backgroundColor:'green'}",
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 32 32",
                    },
                  },
                  [
                    _c("use", { attrs: { href: "#playersOnlineIcon" } }, [
                      _c("g", { attrs: { id: "playersOnlineIcon" } }, [
                        _c("circle", {
                          attrs: { cx: "16", cy: "7.2", r: "5.2" },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M19.3 13.5c-2.1.9-4.6.9-6.7 0-4.8 1.9-7.8 5.6-7.8 11.5 0 1.1.1 1.7.3 2.7.4 0 .8.1 1.2.2.3.1.7.2 1 .2.4-7.2 1.4-7.7 1.5-7.7l.5-.7.7 9c.6.1 1.2.3 1.8.5 1 .4 2.3.8 4.1.8 1.8 0 3-.4 4.1-.8.7-.2 1.2-.5 1.9-.5l.7-9 .5.7c0 .1 1.1.5 1.5 7.7.3 0 .7-.1 1-.2.4-.1.8-.2 1.2-.2.2-1 .3-1.6.3-2.7.1-5.9-2.9-9.6-7.8-11.5z",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      )
    : _c(
        "div",
        {
          staticClass: "TableFull--PcX1W",
          attrs: { "data-role": "table-full" },
        },
        [
          _c("div", { staticClass: "TableFullTextWrap--1FglZ" }, [
            _c(
              "div",
              {
                staticClass: "tableComplete",
                class: _vm.isTablePremiereOuverte ? "tableCompleteAccueil" : "",
              },
              [
                _vm.betBehind
                  ? _c(
                      "span",
                      {
                        staticClass: "BetBehindText--I86eo betBehindText",
                        attrs: { "data-role": "table-bet-behind-text" },
                      },
                      [_vm._v(_vm._s(_vm.$t("ced.miseArriere")))]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }