import Vue from 'vue';
import VueI18n from 'vue-i18n';
//import moment from 'moment';

function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
          const locale = matched[1]
          messages[locale] = locales(key)
        }
    })
    return messages
}

Vue.use(VueI18n);

// if you are using moment in your project, now is a good time
// to set its locale also
//moment.locale(locale);

const i18n = new VueI18n({
  locale: (document.querySelector('html').getAttribute('lang') === 'en') ? 'en' : 'fr',
  fallbackLocale: 'fr',
  messages: loadLocaleMessages(), // set locale messages
});

export default i18n;