var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-component-name": _vm.$options.name } }, [
    _c(
      "a",
      {
        staticClass: "sans_underline row",
        attrs: {
          href: _vm.getMediaImageUrl(_vm.contenu),
          "ga-img-name": _vm.imageName,
        },
      },
      [
        _c("b-col", { staticClass: "col-6 col-md-12" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.getImageSrcVariation(
                  _vm.getImageByScreenSize(_vm.contenu)
                ),
                expression:
                  "getImageSrcVariation(getImageByScreenSize(contenu))",
              },
            ],
            staticClass: "w-100 drop-shadow a-decouvrir",
            attrs: {
              "data-tag":
                _vm.getNomRouteCourante() +
                " - " +
                _vm.contenu.surTitre +
                " - " +
                _vm.contenu.titre.replace(/&nbsp;/g, " ") +
                " - " +
                _vm.contenu.dataTag,
            },
          }),
        ]),
        _vm._v(" "),
        _c("b-col", { staticClass: "col-6 col-md-12 ad-center-vert" }, [
          _c("p", {
            staticClass: "mb-0 ad-surtitre",
            domProps: { innerHTML: _vm._s(_vm.contenu.surTitre) },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "graphik_medium ad-titre" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.contenu.titre) } }),
          ]),
          _vm._v(" "),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.contenu.sousTitre) } }),
          _vm._v(" "),
          _c("div", {
            staticClass: "h-50",
            domProps: { innerHTML: _vm._s(_vm.contenu.texte) },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }