import Vue from "vue";
import { i18n, mixinLangue } from "../../mixins/mixinLangue";
import { store } from "../../store/store";
import { mapGetters } from "vuex";
import mixinUserMessages from "../../mixins/mixin-user-messages";
import {
  isEmptyJsonObj,
  urlParamsValues,
  protectedCTA,
  protectedPage,
  detectVbrowser
} from "../../mixins/mixin-utils";

import Loading from "../../components/loading/Loading.vue";
import MonCompteFilAriane from "../../components/mon-compte/MonCompteFilAriane.vue";
import FilesSender from "../../components/FilesSender/FilesSender.vue";
import ChangementAdresse from "../../components/changement-adresse/ChangementAdresse.vue";
import Cookies from '../../../js/components/cookies';
import "whatwg-fetch";

document.addEventListener("DOMContentLoaded", function() {
  if (document.getElementById("vueMonCompteIdentiteApp")) {
    new Vue({
      el: "#vueMonCompteIdentiteApp",
      i18n,
      store: store,

      components: {
        Loading,
        MonCompteFilAriane
      }, //IE11 compatibility
      mixins: [
        mixinLangue,
        mixinUserMessages,
        urlParamsValues,
        isEmptyJsonObj,
        protectedCTA,
        protectedPage,
        detectVbrowser
      ],
      data: function() {
        return {
          componentFilesSender: "",
          componentChangementAdresse: "",
          isLoading: false,
          isMobile: null,
          payIframe: null,
          uuid: null,
          uriRef: null,
          productSource: null,
          messages: {
            title: "",
            details: ""
          },
          paymentType: null,
          code: null,
          actSection: null,
          isBlockedStatus: false,
          isRetryStatus: false,
          identityByCCAuthorised: false,
          section_isActive: {
            containerDocuments: true,
            formDocuments: false,
            btnTeleversement: false,
            principal: true,
            televersement: false,
            changementAdresse: false,
            fatal: false
          },
          settings: {
            nameLength: 30,
            maxTaille: 0,
            types: []
          }
        };
      },

      created: function() {
        const md = new MobileDetect(navigator.userAgent);
        this.isMobile = md.phone();
        this.isLoading = true;
      },

      computed: {
        ...mapGetters(["getAccountUserMessages"])
      },

      watch: {
        getAccountUserMessages() {
          this.showMessageToUser();
        }
      },

      beforeMount() {
        this.uuid = this.$el.attributes["data-uuid"].value;
        let editMode = this.$el.attributes["data-edit"].value === "true";
        this.productSource = this.getUrlParameter("product_source");
        this.code = this.getUrlParameter("code");
        let uuidDp = this.$el.attributes["data-uuid-dp"].value;
        let uuidRe = this.$el.attributes["data-uuid-re"].value;
        let uuiRefer = this.getUrlParameter("uref");
        if (uuiRefer === uuidDp) {
          this.uriRef = this.$t("navUrl.DEPOT");
        } else if (uuiRefer === uuidRe) {
          this.uriRef = this.$t("navUrl.RETRAIT");
        } else if (!editMode) {
          this.returnToHome();
        }
      },

      mounted: function() {
        const vm = this;
        const domainIframe = this.$el.attributes["data-domain-iframe"].value;
        vm.payIframe = document.getElementById("iframePaysafe");
        vm.initActiveSection();
        vm.isAuthorisedCTA();

        window.addEventListener("message", function(event) {
          if (event.origin !== domainIframe) {
            return;
          }
          if (event.data) {
            switch (event.data.etat) {
              case "Error":
                //$('#modalPaysafe').modal('hide');
                vm.section_isActive.fatal = true;
                vm.messages = vm.getAccountUserMessages["MSG01"];
                vm.removePayIframe(event.data);
                vm.console.log("Error-code :" + JSON.stringify(event.data));
                break;
              case "BeforePayment":
              case "DuringPayment":
                $("#modalPaysafe").modal("hide");
                break;
              case "AfterPayment":
                $("#modalPaysafe").modal("hide");
                vm.returnToLimit();
                vm.isLoading = true;
                break;
              default:
                vm.identityValidation(event.data);
            }
          }
        });
      },

      methods: {
        initActiveSection() {
          this.actSection = this.getUrlParameter("act_section");
          const blkStatus = this.getUrlParameter("blk_status");
          const actBtn = this.getUrlParameter("act_btn");

          if (this.isEmptyPlayerInfo()) {
            this.section_isActive.principal = false;
            this.isLoading = false;
          } else {
            this.isBlockedStatus = blkStatus === "true";
            this.section_isActive.btnTeleversement = actBtn === "true";

            this.section_isActive.principal = this.actSection === "principal";
            this.section_isActive.televersement = this.actSection === "televersement";
            this.section_isActive.fatal = this.actSection === "fatal";
            this.section_isActive.changementAdresse = this.actSection === "changementAdresse";
            //init composant
            if (this.actSection === "televersement") {
              this.loadUploadComponent();
            } else if (this.actSection === "changementAdresse") {
              this.componentChangementAdresse = ChangementAdresse;
              this.isRetryStatus = true;
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
          }
        },

        showMessageToUser() {
          if (this.code) {
            this.messages = this.getAccountUserMessages[this.code];
          }
        },

        isEmptyPlayerInfo() {
          return this.actSection === undefined || this.actSection === null;
        },

        returnToPrincipalSection() {
          this.Loading = true;
          //document.location.reload();
          let stripedUri = this.removeParams(this.uriRef, [
            "uref",
            "act_section",
            "blk_status",
            "act_btn",
            "code"
          ]);
          window.location.href = stripedUri;
        },
        activateFormDocument() {
          this.section_isActive.formDocuments = true;
        },
        desactivateFormDocument() {
          this.section_isActive.formDocuments = false;
        },
        loadUploadComponent() {
          const vm = this;
          vm.isLoading = true;
          let prheaders = new Headers({
            "cache-control": "no-cache",
            pragma: "no-cache"
          });
          fetch("/services/upload-doc", {
            method: "GET",
            headers: prheaders
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              vm.isLoading = false;
              if (['MA_LT', 'MA_MJ', 'MA_CA'].indexOf(vm.productSource) >= 0) {
                document
                  .querySelector(".televersement__message-appMobile")
                  .classList.remove("d-none");
                vm.hideAllSections();
              } else if (data && data.connexion == "invalide") {
                vm.returnToHome();
              } else if (data && data.flag === "false") {
                vm.settings = data;
                vm.componentFilesSender = FilesSender;
                vm.hideAllSections();
                vm.section_isActive.televersement = true;
              } else {
                document
                  .querySelector(".televersement__message-uploadid")
                  .classList.remove("d-none");
                vm.identityByCCAuthorised = data.identityByCCAuthorised === "true";
                vm.hideAllSections();
              }
            })
            .catch((error) => {
              document.querySelector(".televersement-non-disponible").classList.remove("d-none");
              vm.hideAllSections();
              vm.section_isActive.televersement = true;
              vm.isLoading = false;
              console.log(error);
            });
        },

        hideAllSections() {
          this.section_isActive.principal = false;
          this.section_isActive.changementAdresse = false;
          this.section_isActive.btnTeleversement = false;
          this.section_isActive.televersement = false;
        },

        ejPostMessage: function(message) {
          if (!document.getElementById("iframePaysafe")) {
            this.payIframe.onload = function() {
              console.log("postMessage | message: ", message);
              let ifr = document.getElementById("iframePaysafe");
              ifr.contentWindow.postMessage(message, ifr.src);
            };
            document.getElementById("modalPaysafe").appendChild(this.payIframe);
          } else {
            console.log("postMessage | message: ", message);
            this.payIframe.contentWindow.postMessage(message, this.payIframe.src);
          }
          $("#modalPaysafe").modal("show");
        },

        evLoading(val) {
          this.isLoading = val;
        },

        removePayIframe: function(data) {
          //Fermer proprement l'instance paysafe
          data.status = "INSTANCETOCLOSE";
          this.ejPostMessage(data);
          $("#modalPaysafe").modal("hide");
          //detruire l'instance
          this.payIframe.parentNode.removeChild(this.payIframe);
        },

        identityValidation: function(oData) {
          const vm = this;
          console.log("identityValidation");
          let myheaders = new Headers();
          myheaders.append('pragma', 'no-cache');
          myheaders.append('cache-control', 'no-cache');
          myheaders.append('JEL-XTOKEN', Cookies.get('Jel-csrf'));
          let ajaxData = new FormData();
          ajaxData.append("sut", oData.token);
          ajaxData.append("saveCard", oData.saveInstrument || false);
          //Si saveCard et defaultCard doivent avoir la meme valeur dans VI
          ajaxData.append("defaultCard", oData.saveInstrument || false);
          //ajaxData.append('channel', vm.isMobile ? 'M':'I');
          fetch("/services/validation-identite", {
            method: "POST",
            headers: myheaders,
            body: ajaxData
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              console.log("identityValidation | response ", data);
              if (data && (data.status === "OB_OK" || data.status === "INVALIDCARD")) {
                data.messages = vm.getAccountUserMessages[data.code];
                vm.ejPostMessage(data);
              } else if (
                data &&
                (data.status === "ERROREXTERNALSERVICE" ||
                  data.status === "PAYSAFE_PROFILE_CREATION_FAILED")
              ) {
                vm.section_isActive.fatal = true;
                vm.messages = vm.getAccountUserMessages[data.code];
                vm.removePayIframe(data);
              } else if (
                data &&
                (data.status === "CARDEXFAILURE" || data.status === "URNFAILURE")
              ) {
                ejApi.fermerSession();
                vm.returnToHome();
              } else if (data && data.connexion == "invalide") {
                vm.returnToHome();
              } else if (data && data.PLAYER_STATUS === "PLAYER") {
                vm.removePayIframe(data);
                vm.returnToLimit();
              } else {
                vm.removePayIframe(data);
                //recharger la page afin d'etre rediriger vers la bonne section selon le status du client
                vm.isLoading = true;
                window.location.href = vm.removeParams(vm.uriRef, [
                  "uref",
                  "act_section",
                  "blk_status",
                  "act_btn",
                  "code"
                ]);
              }
            })
            .catch((error) => {
              console.log("identityValidation | fail ", error);
              vm.ejPostMessage({ messages: vm.getAccountUserMessages["MSG01"] });
            });
        },
        initIdentityValidation: function() {
          const vm = this;
          console.log("initIdentityValidation");
          vm.isLoading = true;
          let prheaders = new Headers({
            "cache-control": "no-cache",
            pragma: "no-cache"
          });
          fetch("/services/validation-identite/init", {
            method: "GET",
            headers: prheaders
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              vm.isLoading = false;
              console.log("initIdentityValidation | response ", data);
              if (data && data.status == "INITVALIDATE") {
                vm.section_isActive.fatal = false;
                data.payMethod = [data.paymentType];
                vm.ejPostMessage(data);
              } else if (data && data.connexion == "invalide") {
                vm.returnToHome();
              } else {
                vm.section_isActive.fatal = true;
                vm.messages = vm.getAccountUserMessages[data.code];
              }
            })
            .catch((error) => {
              console.log("initIdentityValidation | fail ", error);
              vm.isLoading = false;
              vm.section_isActive.fatal = true;
              vm.messages = vm.getAccountUserMessages["MSG02"];
            });
        },
        returnToLimit: function() {
          let paras = "first=1";
          if (['MA_LT', 'MA_MJ', 'MA_CA'].indexOf(this.productSource) >= 0) {
            paras += "&product_source=" + this.productSource;
          }
          let uriReturn = this.appendObjParamsToUri(this.$t("navUrl.LIMIT_DEPOT"), paras);
          window.location.href = this.removeParams(uriReturn, [
            "uref",
            "act_section",
            "blk_status",
            "act_btn",
            "code"
          ]);
        }
      }
    });
  }
});
