<template>
  <img :src="getUrlGifCourant(image)" />
</template>  

<script>
export default {
  name: "MediaImageGif",
  props: ["contenu"],
  data() {
    return {
      image: this.contenu.image,
    };
  },
  methods: {
    getUrlGifCourant: function (url) {
      if (!url?.length > 0) return "";
      const urlSplit = url.split("/");
      if (!urlSplit?.length > 0) return "";
      return `/magnoliaPublic/dam/espacejeux/${urlSplit[urlSplit.length - 1]}`;
    },
  },
};
</script>

