/*!
 * Utilisé pour gérer les téléchargements
 */

(function ($) {

    $.fn.telechargerPokerLien = function () {
        return this.each(function () {
            $(this).on('click', function () {

                var $parentElem = $(this).parents('.app-ok-poker'),
                    urlTelechargement = $parentElem.data('app-uri'),
                    platform = $parentElem.attr('id');

                if (typeof(urlTelechargement) !== undefined &&
                    typeof(platform) !== undefined) {

                    var nomCookie = "okPokerDejaTelecharge",
                        valeurCookieOkPokerDejaTelecharge = platform,
                        nbrJoursDureeVieCookie = 600,
                        pokerReturnUtmPageName = document.location.pathname + document.location.search + (document.location.search.length > 0 ? '&' : '?') + 'goal=download-poker-return-' + platform,
                        valeurCookie = EspacejeuxPortail_Cookie.lireCookie(nomCookie);

                    if (valeurCookieOkPokerDejaTelecharge === valeurCookie) {
                        EspacejeuxPortail_GoogleAnalytics.enregistrerEvenement('download', 'try', 'ok-poker-' + platform);
                        $("#lightbox-telecharger .btn-cancel").attr("onclick", "EspacejeuxPortail_GoogleAnalytics.enregistrerEvenement('download', 'cancel', 'ok-poker-" + platform + "');");
                        $("#lightbox-telecharger .btn-confirm").attr("onclick", "window.open('" + urlTelechargement + "');EspacejeuxPortail_GoogleAnalytics.enregistrerVisite('" + pokerReturnUtmPageName + "');");
                        $("#lightbox-telecharger").modal();
                    }
                    else {
                        window.open(urlTelechargement);
                        var utm_pagename = document.location.pathname + document.location.search + (document.location.search.length > 0 ? '&' : '?') + 'goal=download-poker-new-' + platform;
                        EspacejeuxPortail_GoogleAnalytics.enregistrerVisite(utm_pagename);
                    }

                    EspacejeuxPortail_Cookie.ecrireCookie(nomCookie, valeurCookieOkPokerDejaTelecharge, nbrJoursDureeVieCookie);
                }
                else {
                    console.log("Erreur lors télechargement du ok-poker");
                }
            });

        });
    }
}(jQuery));


$(document).ready(function () {
    $('.telecharger').telechargerPokerLien();
});