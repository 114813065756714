<template>
<div :class="[getGutter(contenu.gutters), getWidth(contenu.carrouselSize),contenu.margin,contenu.displayAt,contenu.hideUntil]"
       class="container">
	<b-row :data-component-name="$options.name" 
         :class="['gutters-'+[contenu.gutters], contenu.formatAffichage == 'carrousel'? 'ml-0 mr-0': null]">
    <template v-if="contenu.formatAffichage == 'carrousel'">
        <component v-bind:is="'Carrousel'"
                 :key="contenu.row['@id']"
                 :contenu="contenu"
                 :data-component-type="'Carrousel'"
                 :data-carrousel-size="getWidth(contenu.carrouselSize)"></component>
    </template>

    <template v-else v-for="aNode in contenu.row['@nodes']">
			<component v-if="contenu.row[aNode].typeComposante"
                 v-bind:is="contenu.row[aNode].typeComposante"
                 :key="contenu.row[aNode]['@id']"
                 :contenu="contenu.row[aNode]"
                 :data-component-type="contenu.row[aNode].typeComposante"></component>
    </template>
	</b-row>
</div>
</template> 

<script>
  import {WebServicesAccess} from '../../../config';
  import Column from "./Column.vue";
  import Carrousel from "../carrousel/Carrousel.vue";
 
  export default {
    name:"Row",
    components: {
        Column,
        Carrousel
    },
    data(){
        return{
        }
    },
    props: ["contenu"],
    methods:{      
      getGutter(gutter){
        
        var result;
        switch(gutter){
          case "xsmall":
            result= "px-1";
            break; 
          case "small":
            result= "px-2";
            break; 
          case "medium":
            result= "px-3"; 
            break;
          case "large":
            result= "px-4";
            break; 
          case "xLarge":
            result="px-6";
            break; 
          case "xxLarge":
            result="px-8";
            break; 
          case "xxxLarge":
            result="px-10";
            break; 
          default: 
            result= "px-0"; 
        }
        return result;
      },      
      getWidth(carrouselSize){
        var size;

        if(carrouselSize == "FullScreen"){
          size = "takeFullWidth";
        } else {
          size = "";
        }
        return size
      }
    },
    created: function () {
	  }
  }
</script>

<style lang="scss" scoped>
	.contenu {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.contenu.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
  }
</style>