import CatfishInit from './catfish-init';
var retryNb = 0;
var maxRetries = 20;

// Originally in ej-commmun-portail.js
const WaitForClientConnectionInfo = function() {
	if (typeof ejConnect === 'undefined' || ejConnect.etatClient === 'anon') {
		if(retryNb < maxRetries){
			setTimeout(function () {
				WaitForClientConnectionInfo();
			}, 200);
		}else{
	        initBannieres();
	        CatfishInit();
	    }
		retryNb++;
	}else{
        initBannieres();
        CatfishInit();
    }
}

export default WaitForClientConnectionInfo;