<template >

  <div :data-component-name="$options.name" class="container h-100 d-flex" :class="{'saviez_vous_que': isSaviezVous}">
    <img v-if="contenu.image" v-lazy="getImageSrcVariation(getImageByScreenSize(contenu))" class="w-100 image" alt="" />
    <!-- si je mets juste (contenu.cssClass.includes('padding_media_image') sans vérifier qu'il y aille quelque chose dans cssClass avant les mediatexte
    image avec aucune classe spécial ne s'affiche pas-->
    <div class="div_container" :class="[contenu.cssClass? (contenu.cssClass.includes('padding_media_image')?'padding_media_image':'') : '', isSaviezVous ? 'saviez_vous_ctn':'']">
      <div class="mb-1 mt-2 titre_media graphik_bold" :style="{'color': contenu.couleurTitre}" v-html="contenu.titre"></div>
      <div class="texte" v-html="contenu.texte"></div>
      <a v-if="getMediaImageUrl(contenu)" :href="getMediaImageUrl(contenu)" class="stretched-link"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaImageTexteSurImage",
  data() {
    return {
    };
  },
  props: ["contenu"],
  methods: {},
  computed: {
    classObject: function() {
      return {
       //rounded: this.contenu.typeCoin
      }
    },
    styleObject: function() {
      return {
        "background-color": this.contenu.secBgThemeColor
      }
    },
    isSaviezVous: function(){ //Fixe rapide pour gerer les borders des saviez-vous-que
      if(this.contenu.cssClass && this.contenu.cssClass.includes('saviez_vous_que')){
        return true;
      }
      return false;
    }
  },
  created: function() {}
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.image {
  //position: absolute;
  //opacity: 0.5;
  //left: 0;
  height: 100%;
}

.saviez_vous_que{
  border: 2px dashed rgb(13, 203, 143);
  border-radius: ms(2);  
  min-height: 23rem;;
}

.div_container.saviez_vous_ctn{
  padding-left: 5%;
  padding-right: 5%; 
}

.div_container{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  padding-left:15%;
  padding-right: 15%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index:1;
  align-items: center; 
  color:black;
  text-align: center;
  top: 0;
}

.texte{
  @include ms-respond(font-size, 0);
  padding: 0px 5% 0px 5%;  
  line-height: 150%;
}
.titre_media{
  @include ms-respond(font-size, 1);  
}

.titre_bas .div_container{
  justify-content: flex-end;

  .titre_media{
    @include ms-respond(font-size, 2);
  }
}

</style>