var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "recent-numbers-container",
      attrs: { "data-role": "roulette-statistics-container" },
    },
    _vm._l(_vm.results, function (result, index) {
      return _c(
        "div",
        { key: index, staticClass: "box", attrs: { result: result } },
        [
          _vm.isCaseDouble(result)
            ? _c("div", { class: _vm.getClass(result) }, [
                _c(
                  "div",
                  { class: _vm.getCouleur(_vm.splitValeur(result)[0]) },
                  [_vm._v(_vm._s(_vm.splitValeur(result)[0]))]
                ),
                _vm._v(" "),
                !_vm.isJackpot(result)
                  ? _c(
                      "div",
                      { class: _vm.getCouleur(_vm.splitValeur(result)[1]) },
                      [_vm._v(_vm._s(_vm.splitValeur(result)[1]))]
                    )
                  : _vm._e(),
              ])
            : _c(
                "div",
                {
                  staticClass: "game-result",
                  class: [
                    _vm.getCouleur(result),
                    { lucky: _vm.getLuckyNumber(index) },
                  ],
                },
                [
                  _vm._v("\n        \t" + _vm._s(result) + "\n        \t"),
                  _vm.getLuckyNumber(index)
                    ? _c(
                        "div",
                        {
                          staticClass: "multiplier",
                          attrs: { "data-role": "multiplier" },
                        },
                        [_vm._v(_vm._s(_vm.getLuckyNumber(index)) + "x")]
                      )
                    : _vm._e(),
                ]
              ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }