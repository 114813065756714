import moment from "moment";
import "moment-timezone";

export var mixinCED = {
  data: function() {
    return {
      mixinCED_table: null,
      mixinCED_tableLive: null,
      mixinCED_refreshCount: null,
      mixinCED_familles: {"blackjack": ["blackjack", "scalableblackjack", "freebet", "powerscalableblackjack", "lightningscalablebj"],
      						"roulette": ["roulette", "americanroulette", "instantroulette"],
							"baccara": ["baccarat", "dragontiger", "sicbo", "bacbo", "fantan"],
							"poker": ["uth", "holdem", "eth", "csp", "dhp", "thb", "trp", "tcp", "sidebetcity", "teenpatti"],
							"des": ["lightningdice", "sicbo", "craps", "topdice"],
							"jeuxEnDirect": ["moneywheel", "monopoly", "dealnodeal", "topcard", "megaball", "crazytime","lightningdice", "cashorcrash", 
                                        "andarbahar", "crazycoinflip", "deadoralivesaloon", "gonzotreasurehunt", "monopolybigballer", "fantan", "topdice"]}
    };
  },

  computed: {
    CS_CHARGEMENT: () => 0,
    CS_OUVERT: () => 1,
    CS_FERME: () => 2,
    CS_DESACTIVE: () => 3,
    CS_TABLE_INEXISTANTE: () => "-1",
    GRILLE_PETIT: () => 6,
    GRILLE_MOYEN: () => 4,
    GRILLE_GRAND: () => 3,

    mixinCED_etatTableAppRefresh: function() {
      // Forcer le rafraîchissement à chaque appel au service (table-live)
      this.mixinCED_refreshCount;

      return this.mixinCED_getEtatTableApp(this.mixinCED_table);
    },

    mixinCED_etatTableServiceRefresh: function() {
      // Forcer le rafraîchissement à chaque appel au service (table-live)
      this.mixinCED_refreshCount;

      return this.mixinCED_getEtatTableService(this.mixinCED_tableLive);
    }
  },

  methods: {
    // Gestion de l'état de la table + refresh
    mixinCED_getEtatTableRefresh: function(table, tableLive, refreshCount) {
      // Initialisation
      this.mixinCED_table = table;
      this.mixinCED_tableLive = tableLive;
      this.mixinCED_refreshCount = refreshCount;

      // Vérifie si on est dans une période de chargement
      if (!table || refreshCount == 0) {
        return this.CS_CHARGEMENT;
      }

      const etatTableApp = this.mixinCED_etatTableAppRefresh;
      const etatTableService = this.mixinCED_etatTableServiceRefresh;

      return this.mixinCED_combinerEtatTable(etatTableApp, etatTableService);
    },

    // Utilisé par cedTableListe
    mixinCED_getEtatTable: function(table, tableLive) {
      const etatTableApp = this.mixinCED_getEtatTableApp(table);
      const etatTableService = this.mixinCED_getEtatTableService(tableLive);

      return this.mixinCED_combinerEtatTable(etatTableApp, etatTableService);
    },

    /*
    0.	Gestion de l'état de la table
    
    Si statut Magnolia Désactivé, indépendamment du statut EVO : 
      La table est affichée désactivée
    
    Sinon Si toutes les tables d’une famille ont le statut EVO Désactivé:
      La table est affichée désactivée (dans la page d’accueil et dans la page de la famille)
    
    Sinon Si statut Magnolia Fermé ou statut EVO n’est pas ouvert :
      La table est affichée fermée en spécifiant l’heure d’ouverture :
    Si Page accueil :
      Heure de la famille définie dans Magnolia
    Sinon (page famille) en ordre de préséance :
      Heure de la table définie dans Magnolia
      La plus tardive entre :
        Heure retournée par le service     
        Heure de la famille
    Sinon 
      La table est affichée ouverte

    Si statut Magnolia Désactivé, indépendamment du statut EVO : 
		  La table est affichée désactivée
    Sinon Si statut Magnolia Fermé ou statut EVO n’est pas ouvert) :
      La table est affichée fermée en spécifiant l’heure d’ouverture en ordre de précédence :
        Heure de la table définie dans Magnolia uniquement si page famille
        Heure de la table reçue par le service
        Heure de la famille définie dans Magnolia
     Sinon 
		  La table est affichée ouverte
	 */
    mixinCED_combinerEtatTable: function(etatTableApp, etatTableService) {
      try {
        if (etatTableApp == this.CS_DESACTIVE || etatTableService == this.CS_DESACTIVE) {
          return this.CS_DESACTIVE;
        }

        if (etatTableApp == this.CS_FERME || etatTableService != this.CS_OUVERT) {
          return this.CS_FERME; // Ouverture bientôt
        }
      } catch (error) {
        return this.CS_DESACTIVE;
      }

      return this.CS_OUVERT;
    },

    /*
    1.	Validation selon les données dans Magnolia :
    Si la table est active dans l’application Magnolia :
      Si la famille de produits est ouverte selon la période saisie dans l’application Magnolia ET
        la table est ouverte selon la période saisie dans l’application Magnolia :
        Le statut Magnolia de la table = ouvert
      Sinon
        Le statut Magnolia de la table = fermé
    Sinon
      Le statut Magnolia de la table = désactivé
	  */
    mixinCED_getEtatTableApp: function(table) {
      // Si la table est activée dans application
      if (this._mixinCED_isTableActifApp(table)) {
        // Si la table est ouverte dans application Famille
        if (
          this.mixinCED_isEnHeureOuverture(
            table.heureOuvertureFamille,
            table.heureFermetureFamille,
            "America/Toronto"
          )
        ) {
          return this.CS_OUVERT;
        } else {
          return this.CS_FERME; // Ouverture bientôt
        }
      } else {
        return this.CS_DESACTIVE;
      }
    },

    /*
	  2.	Validation selon les données retournées par l’API d’Evolution :
		Si la table est reçue dans le service
      Si elle est active (open = true)
        Le statut EVO de la table = ouvert 
        (indépendamment des heures d'ouverture dans API)
      Sinon :
        Le statut EVO de la table = fermé
    Sinon :
			Le statut EVO de la table = désactivée
	  */
    mixinCED_getEtatTableService: function(tableLive) {
      // S'il y a erreur dans la réponse du service
      if (tableLive != undefined) {
        // Si le statut de la table est « open »
        if (tableLive.open) {
          return this.CS_OUVERT;
        } else if (tableLive.operationHours.type == "Bounded"){
          return this.CS_FERME; // Ouverture bientôt
        }else{
			return this.CS_DESACTIVE; // Fermée
		}
      } else {
        return this.CS_DESACTIVE;
      }
    },

    mixinCED_dateHeuresOuverture: function(table, tableLive) {
      // Date et Heure ouverture de la table
      try {
        if (table.heureOuverture != undefined) {
          return this.mixinCED_getProchaineDateHeure(table.heureOuverture, "America/Toronto");
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}

      // Essayer d'initialiser l'heure de la famille
      var ouvertureAppFamille;
      try {
        // eslint-disable-next-line no-undef
        ouvertureAppFamille = moment.tz(table.heureOuvertureFamille, "HH:mm", "America/Toronto");
        // eslint-disable-next-line no-empty
      } catch (error) {}

      // Prendre l'heure la plus éloignée dans le temps (entre famille et service)
      if (ouvertureAppFamille && ouvertureAppFamille.isValid()) {
        // Essayer d'initialiser l'heure d'ouverture du service
        var ouvertureService;
        try {
          ouvertureService = moment.tz(tableLive.operationHours.value.startTime, "HH:mm", "UTC");
          // eslint-disable-next-line no-empty
        } catch (error) {}

        // Prendre l'heure la plus éloignée dans le temps
        if (ouvertureService != undefined && ouvertureService.isSameOrAfter(ouvertureAppFamille)) {
          return this.mixinCED_getProchaineDateHeure(
            tableLive.operationHours.value.startTime,
            "UTC"
          );
        }

        return this.mixinCED_getProchaineDateHeure(table.heureOuvertureFamille, "America/Toronto");
      }

      // Retourner l'heure d'ouverture du service
      try {
        return this.mixinCED_getProchaineDateHeure(tableLive.operationHours.value.startTime, "UTC");
        // eslint-disable-next-line no-empty
      } catch (error) {}

      return null;
    },

    mixinCED_heuresOuverture: function(table, tableLive) {
      return this.mixinCED_formatHeure(
        this.mixinCED_dateHeuresOuverture(table, tableLive),
        "America/Toronto"
      );
    },

    mixinCED_sortByHeuresOuvertures: function(tableListe, tableListeLive, appareil) {
      var self = this;
      return tableListe.sort(function(table1, table2, appareil) {
        const findTableLive1 = self.mixinCED_findTableLive(table1, tableListeLive, appareil);
        const findTableLive2 = self.mixinCED_findTableLive(table2, tableListeLive, appareil);
        var tableLive1 = findTableLive1.tableLive;
        var tableLive2 = findTableLive2.tableLive;
        var heuresOuverture1 = self.mixinCED_dateHeuresOuverture(table1, tableLive1);
        var heuresOuverture2 = self.mixinCED_dateHeuresOuverture(table2, tableLive2);

        if (heuresOuverture1 < heuresOuverture2) return -1;
        if (heuresOuverture1 > heuresOuverture2) return 1;
      });
    },

    // Retourne tableLive et tableLiveInfo
    // Tient compte de sources multiples (Canada, Europe, etc...)
    mixinCED_findTableLive: function(table, tableListeLive, appareil) {
      if (tableListeLive && table) {
        const idTable = appareil == this.CS_MOBILE ? table.idTableMobile : table.idTableDesktop;
        
        // Chercher dans quelle source (Canada, Europe, etc...)
        var tableListeLiveSource = tableListeLive.find((tableListeLiveSource) => {
          // Si une source est inactive, on a un noeud à null
          return (
            tableListeLiveSource &&
            tableListeLiveSource.tables &&
            tableListeLiveSource.tables[idTable] != undefined
          );
        });

        if (tableListeLiveSource) {
          return {
            tableLive: tableListeLiveSource.tables[idTable],
            tableLiveInfo: {
              source: tableListeLiveSource.source,
              evolutionPublicDomain: tableListeLiveSource.publicUrl,
              evolutionChannelDesktop: tableListeLiveSource.channelDesktop,
              evolutionChannelMobile: tableListeLiveSource.channelMobile
            }
          };
        }
      }

      return { tableLive: null, tableLiveInfo: null };
    },

    // Retourne la prochaine date/heure vs l'heure actuelle
    mixinCED_getProchaineDateHeure: function(heure, timeZone) {
      var momentDateHeure = moment.tz(heure, "HH:mm", timeZone);

      // Si l'heure est invalide, ne retournez rien
      if (!momentDateHeure.isValid()) {
        return null;
      }

      var maintenant = moment();
      if (maintenant.isAfter(momentDateHeure)) {
        return moment(momentDateHeure).add(1, "d");
      }

      return momentDateHeure;
    },

    mixinCED_formatHeure: function(heure, timeZone) {
      var momentHeure = moment.tz(heure, "HH:mm", timeZone);

      // Si l'heure est invalide, ne retournez rien
      if (!momentHeure.isValid()) {
        return "";
      }

      // Conversion d’heure d'un fuseau horaire en heure client
      if (this.mixinLangue_langue == "en") {
        var periode = momentHeure.tz(moment.tz.guess()).format("a") == "am" ? " a.m." : " p.m.";
        return momentHeure.tz(moment.tz.guess()).format("h:mm") + periode;
      }
      return momentHeure.format("m") == 0
        ? momentHeure.tz(moment.tz.guess()).format("H") + " h "
        : momentHeure.tz(moment.tz.guess()).format("H") + " h " + momentHeure.format("mm");
    },

    // Si la table est dans ses heures d'ouverture retourne vrai.
    mixinCED_isEnHeureOuverture: function(debut, fin, timeZone) {
      var d = new Date(),
        datetext = d.toTimeString().split(" ")[0],
        maintenant = moment.tz(datetext, "HH:mm", moment.tz.guess()),
        ouverture = null,
        fermeture = null;

      if (debut) {
        ouverture = moment.tz(debut, "HH:mm", timeZone);
      }

      if (fin) {
        fermeture = moment.tz(fin, "HH:mm", timeZone);
      }

      // S'il n'y a pas de période d'ouverture ou de fermeture donc ouvert à tout moment
      // Sinon on est dans une période d'ouverture
      if (ouverture == null && fermeture == null) {
        return true;
      }

      // Si on est entre l'ouverture et la fermeture
      return this._mixinCED_isBetweenHours(maintenant, ouverture, fermeture);
    },

    // Determine si la table est active/en opération dans l'App
    _mixinCED_isTableActifApp: function(table) {
      // Gestion désactivation
      if (table) {
        // Si toutes les tables sont désactivées
        if (table.idTableDesktop == undefined) {
          return false;
        }
        var maintenant = moment();
        var debut = null;
        var fin = null;

        if (table.dateDebutDesactivation != undefined) {
          debut = moment(table.dateDebutDesactivation);
        }

        if (table.dateFinDesactivation != undefined) {
          fin = moment(table.dateFinDesactivation);
        }

        if (debut == null && (fin == null || maintenant.isAfter(fin))) {
          return true;
        } else if (fin == null && (debut == null || maintenant.isBefore(debut))) {
          return true;
        } else if (maintenant.isBefore(debut) || maintenant.isAfter(fin)) {
          return true;
        } else {
          return false;
        }
      }
      // Par défaut la table est fermée. (S'il y a une erreur et que table retourne aucune information)
      return false;
    },

    // Gère le problème si on veut savoir si 3pm est entre 12pm et 1am
    // Une version modifiée : Prendre pour acquis que si fin est après minuit c'est le lendemain
    // https://github.com/moment/moment/issues/1199#issuecomment-258647695
    // https://jsfiddle.net/rfossella/66wjtnvk/1/
    _mixinCED_isBetweenHours: function(maintenant, debut, fin) {
      var etatOuverture = false;
      //date overture/fermeture sur 2 jours
      if (fin.hour() < debut.hour()) {
        etatOuverture = !maintenant.isBetween(fin, debut);
      }
      //date overture/fermeture sur le meme jour
      else {
        etatOuverture = maintenant.isBetween(debut, fin);
      }

      return etatOuverture;
    }
  }
};
