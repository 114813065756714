import Vue from 'vue';
import {i18n} from '../../mixins/mixinLangue';
import { store } from '../../store/store';

import CasinoJeuListe from '../../components/casino-jeu-liste/CasinoJeuListe.vue';
import Pagination from '../../components/pagination/Pagination.vue';
import CasinoFiltres from '../../components/casino-filtres/CasinoFiltres.vue';
import CasinoTri from '../../components/casino-tri/CasinoTri.vue';

document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById("vueCasinoApp")) {
        new Vue({
            el: '#vueCasinoApp',
			store,
            i18n,
            components: {
                CasinoJeuListe,
                Pagination,
                CasinoFiltres,
                CasinoTri,
            },
        })
    }
});