var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      class: [
        _vm.getGutter(_vm.contenu.gutters),
        _vm.getWidth(_vm.contenu.carrouselSize),
        _vm.contenu.margin,
        _vm.contenu.displayAt,
        _vm.contenu.hideUntil,
      ],
    },
    [
      _c(
        "b-row",
        {
          class: [
            "gutters-" + [_vm.contenu.gutters],
            _vm.contenu.formatAffichage == "carrousel" ? "ml-0 mr-0" : null,
          ],
          attrs: { "data-component-name": _vm.$options.name },
        },
        [
          _vm.contenu.formatAffichage == "carrousel"
            ? [
                _c("Carrousel", {
                  key: _vm.contenu.row["@id"],
                  tag: "component",
                  attrs: {
                    contenu: _vm.contenu,
                    "data-component-type": "Carrousel",
                    "data-carrousel-size": _vm.getWidth(
                      _vm.contenu.carrouselSize
                    ),
                  },
                }),
              ]
            : _vm._l(_vm.contenu.row["@nodes"], function (aNode) {
                return [
                  _vm.contenu.row[aNode].typeComposante
                    ? _c(_vm.contenu.row[aNode].typeComposante, {
                        key: _vm.contenu.row[aNode]["@id"],
                        tag: "component",
                        attrs: {
                          contenu: _vm.contenu.row[aNode],
                          "data-component-type":
                            _vm.contenu.row[aNode].typeComposante,
                        },
                      })
                    : _vm._e(),
                ]
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }