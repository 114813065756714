<template>
  <div
    :class="{
      anon: indAudienceAnonyme,
      cl: indAudienceClientLeger,
      ci: indAudienceClientInvite,
      cj: indAudienceClientJoueur,
      [dnone]: cacherContenu,
    }"
  >
    <template v-for="aNode in contenu.filtres['@nodes']">
      <component
        :is="contenu.filtres[aNode].typeComposante"
        v-if="contenu.filtres[aNode].typeComposante"
        :key="contenu.filtres[aNode]['@id']"
        :contenu="contenu.filtres[aNode]"
      ></component>
    </template>
  </div>
</template>

<script>
import Alerte from "../base/Alerte.vue";
import MediaImage from "../media/image/MediaImage.vue";
import MediaOffre from "../media/MediaOffre.vue";
import MediaTexte from "../media/MediaTexte.vue";
import MediaVideo from "../media/MediaVideo.vue";
import Tableau from "../base/Tableau.vue";
import TexteHTML from "../base/TexteHTML.vue";
import moment from "moment";

export default {
  name: "GestionFiltre",
  components: {
    Alerte,
    MediaImage,
    MediaOffre,
    MediaTexte,
    MediaVideo,
    Tableau,
    TexteHTML,
  },
  props: ["contenu"],
  data: function () {
    const audienceClient = localStorage.getItem("audienceClient") || "";
    const optin = localStorage.getItem("optin") || "";
    const {
      dateDebutAffichage,
      dateFinAffichage,
      infolettre,
      indAudienceAnonyme,
      indAudienceClientInvite,
      indAudienceClientJoueur,
      indAudienceClientLeger,
    } = this.contenu;

    return {
      audienceClient: audienceClient,
      cacherContenu: this.cacherContenu || false,
      dateDebutAffichage: this.getDate(dateDebutAffichage),
      dateFinAffichage: this.getDate(dateFinAffichage),
      dnone: "d-none",
      optin: optin,
      infolettre: infolettre || "notSelected",
      indAudienceAnonyme: indAudienceAnonyme == "true",
      indAudienceClientInvite: indAudienceClientInvite == "true",
      indAudienceClientJoueur: indAudienceClientJoueur == "true",
      indAudienceClientLeger: indAudienceClientLeger == "true",
    };
  },
  created: function () {
    if (
      /*this.cacherContenuCasino() ||*/ this.cacherContenuAudience() ||
      this.verifierIntervalDate()
    ) {
      this.cacherContenu = true;
    }
  },
  methods: {
    cacherContenuAudience: function () {
      const classList = this.getListeClasse();
      if (
        !classList.includes("anon") &&
        !classList.includes("ci") &&
        !classList.includes("cl") &&
        !classList.includes("cj")
      ) {
        return false;
      } else if (this.audienceClient == "anon") {
        if (this.optin == "notSelected") {
          if (
            classList.includes(this.audienceClient) &&
            classList.includes("infolettre-notSelected")
          ) {
            return false;
          }
        }
      } else {
        if (this.optin == "notSelected") {
          if (
            classList.includes(this.audienceClient) &&
            classList.includes("infolettre-notSelected")
          ) {
            return false;
          }
        } else {
          if (
            classList.includes(this.audienceClient) &&
            (classList.includes("infolettre-notSelected") ||
              classList.includes("infolettre-" + this.optin))
          ) {
            return false;
          }
        }
      }
      return true;
    },
    getListeClasse: function () {
      const classList = [];
      this.indAudienceAnonyme ? classList.push("anon") : null;
      this.indAudienceClientLeger ? classList.push("cl") : null;
      this.indAudienceClientInvite ? classList.push("ci") : null;
      this.indAudienceClientJoueur ? classList.push("cj") : null;
      this.infolettre ? classList.push(`infolettre-${this.infolettre}`) : null;
      return classList;
    },
    getDate: function (date) {
      if (!date) return null;
      return new Date(date);
    },
    verifierIntervalDate: function () {
      let dateDebut = moment(parseInt(this.dateDebutAffichage?.getTime()));
      let dateFin = moment(parseInt(this.dateFinAffichage?.getTime()));
      dateDebut = dateDebut.isValid() ? dateDebut : moment().subtract(1, "y");
      dateFin = dateFin.isValid() ? dateFin : moment().add(1, "y");
      return !(moment() >= dateDebut && moment() < dateFin);
    },
  },
};
</script>
