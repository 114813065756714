var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1",
      attrs: { "data-role": "lobby-table-game-specific-wrapper" },
    },
    [
      _c(
        "ol",
        {
          staticClass: "TableGameMegaball--3FxHn",
          attrs: { "data-role": "megaball-history" },
        },
        [
          _vm._l(_vm.resultsSlice, function (result, index) {
            return [
              result.length == 1
                ? _c(
                    "li",
                    {
                      key: index,
                      staticClass: "Multiplier--3fr5O",
                      style:
                        "background: " +
                        _vm.getCouleurFill(index, result[0].multiplier) +
                        "; border: 1px solid " +
                        _vm.getCouleurStroke(result[0].multiplier) +
                        ";",
                    },
                    [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c("div", { staticClass: "Text--1WC8k" }, [
                        _vm._v(_vm._s(result[0].multiplier) + "x"),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    { key: index, staticClass: "Doubleball--22hor" },
                    _vm._l(result, function (resultDouble, index2) {
                      return _c(
                        "li",
                        {
                          key: index2,
                          staticClass: "Multiplier--3fr5O",
                          style:
                            "background: " +
                            _vm.getCouleurFill(index, resultDouble.multiplier) +
                            "; border: 1px solid " +
                            _vm.getCouleurStroke(resultDouble.multiplier) +
                            ";",
                        },
                        [
                          _vm._m(1, true),
                          _vm._v(" "),
                          _c("div", { staticClass: "Text--1WC8k" }, [
                            _vm._v(_vm._s(resultDouble.multiplier) + "x"),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ShadowContainer--1cONb" }, [
      _c("div", { staticClass: "Shadow--1rJqK" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ShadowContainer--1cONb" }, [
      _c("div", { staticClass: "Shadow--1rJqK" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }