<template>
<div class="lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1" data-role="lobby-table-game-specific-wrapper">
	<div class="lobbyTableGameSpecificData--3whkv TableHistoryStatisticTopCard--QsycZ desktop--2gB3k w-100">
		<div class="historyItem--2gz2u isDesktop--2BuPY largeMobileIcon--3hpO8 isLobby--3wLXA" style="color: rgb(255, 255, 255);" :key="index" v-for="(result, index) in historySlice">
			<svg width="68" height="36" viewBox="0 0 68 36">
				<g>
					<path fill="black" fill-opacity="0.4" d="M3.78526 0.5H52.2521C55.4556 0.5 58.4178 2.12691 60.0151 4.7727L67.0687 16.4636C67.3559 16.9398 67.5 17.4698 67.5 18C67.5 18.5302 67.3559 19.0602 67.0688 19.5363L60.0152 31.2272C58.4178 33.8731 55.4556 35.5 52.2521 35.5H3.78526C2.52401 35.5 1.4946 34.8549 0.94085 33.9432C0.659118 33.4793 0.500719 32.9471 0.500002 32.3988C0.499332 31.8861 0.637189 31.3603 0.936906 30.8637L7.45968 20.0528C7.84332 19.4166 8.03459 18.7082 8.03459 18C8.03459 17.2918 7.84332 16.5834 7.45961 15.9471L0.936865 5.13623C0.637189 4.63972 0.499332 4.11388 0.500002 3.60122C0.500719 3.05292 0.659118 2.52069 0.94085 2.05683C1.4946 1.14509 2.52401 0.5 3.78526 0.5Z"></path>
					<path v-if="isGagnantActif(index,result)" :fill="getCouleur(result, index)" :class="getCouleur(result, index)" fill-opacity="0.8" d="M3.78526 0.5H52.2521C55.4556 0.5 58.4178 2.12691 60.0151 4.7727L67.0687 16.4636C67.3559 16.9398 67.5 17.4698 67.5 18C67.5 18.5302 67.3559 19.0602 67.0688 19.5363L60.0152 31.2272C58.4178 33.8731 55.4556 35.5 52.2521 35.5H3.78526C2.52401 35.5 1.4946 34.8549 0.94085 33.9432C0.659118 33.4793 0.500719 32.9471 0.500002 32.3988C0.499332 31.8861 0.637189 31.3603 0.936906 30.8637L7.45968 20.0528C7.84332 19.4166 8.03459 18.7082 8.03459 18C8.03459 17.2918 7.84332 16.5834 7.45961 15.9471L0.936865 5.13623C0.637189 4.63972 0.499332 4.11388 0.500002 3.60122C0.500719 3.05292 0.659118 2.52069 0.94085 2.05683C1.4946 1.14509 2.52401 0.5 3.78526 0.5Z"></path>
					<text x="23"  y="26" :fill="(isGagnantActif(index,result)) ? '#fff' : getCouleur(result, index)" font-size="23">{{getAffichageResultat(result)}}</text>
				</g>
			</svg>
		</div>
	</div>
</div>
</template>

<script>
const CS_COULEUR_BLEU = 'rgb(14,94,255)';
const CS_COULEUR_ROUGE = 'rgb(255,0,0)';
const CS_COULEUR_JAUNE = 'rgb(228,196,132)';

export default {
	props : {
		history : {
			type : Array,
			required : true
		},
		appareil : {
			type : String,
			required : true
		},
		prefixeUrl : {
			type : String,
			required : true
		}
	},
	computed:{
        //Retourne la partie du tableau de l'historique désiré
        historySlice:function(){
            return this.history.slice(0,8);
        },
        //Obtention du nombre d'égalité au début du tableau
		indexDepart: function(){
			var history = this.historySlice;
			var index = 0;

			while(history[index] == "T"){
				index++;
			}

			return index;
        },
        //Obtention du nombre de gagnant et d'égalité au début du tableauu
        indexSuite: function(){
			var history = this.historySlice;
			var incremente = true;
			var tBuffer = 0;
			var index = this.indexDepart;

			for(var i = index + 1; i < history.length; i++){
				if(incremente){
					if(history[i] == history[index]){
						index = index + tBuffer + 1;
						tBuffer = 0;
					}else if(history[i] == "T"){
						tBuffer++;
					}else if(history[i] != history[index]){
						incremente = false;
					}
				}
			}

			return index;
		}
	},
	methods: {
        //retourne vrai si c'est le gagnant actuel ou T en début
        isGagnantActif: function(index, result){
            return (this.indexSuite >= index && (result != 'T' || index == 0));
        },
        //modification de l'affichage pour indiquer la lettre désirée
		getAffichageResultat: function(resultat) {
			if(resultat=="R"){ 			// Right
				return this.$t('ced.topcard.droite'); // Away
			}else if (resultat=="L"){ 	// Left
				return this.$t('ced.topcard.gauche'); // Home
			}else{
				return this.$t('ced.topcard.egalite'); // Draw
			}
        },
        //Obtention de la couleur selon le résultat
		getCouleur: function(resultat) {
			if(resultat=="R"){
				return CS_COULEUR_BLEU;
			}else if (resultat=="L"){
				return CS_COULEUR_ROUGE;
			}else{
				return CS_COULEUR_JAUNE;
			}
        },
	}
}
</script>

<style>
.TableLogo--3RFNG.desktop--1Txr1 {
    z-index: 4
}

.TableLogo--3RFNG.desktop--1Txr1.roulette--2Wcvy {
    bottom: 1.85em
}

.TableLogo--3RFNG.desktop--1Txr1.doubleBall--2sPYM {
    bottom: 2.5em
}

.lobbyTableGameSpecificWrapper--3fAEk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    min-height: 3.35rem;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1em;
    z-index: 4
}

.lobbyTableGameSpecificWrapper--3fAEk.emptyGameHistoryStatistic--Uu-Gi {
    min-height: auto!important
}

.lobby-table-block.occupied .lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .lobbyTableGameSpecificWrapper--3fAEk {
    left: 1px;
    right: 1px
}

.lobbyTableGameSpecificWrapper--3fAEk .lobby-table-block.table-is-full-behind {
    display: none
}


.lobbyTableGameSpecificData--3whkv:empty {
    display: none
}

.lobby-table-block.info .lobbyTableGameSpecificData--3whkv {
    display: none!important
}

.lobbyTableGameSpecificData--3whkv .lobby-table-block.table-details-loaded.not-operational:not(.info) {
    display: none
}

.desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 1.9em;
    z-index: 4
}

.desktop--1Txr1 .lobbyTableGameSpecificData--3whkv>* {
    z-index: 4
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    box-sizing: border-box;
    bottom: -1px;
    min-height: 3em;
    padding: 0 .3em .4em
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk.portrait--w-M_r {
    min-height: 2.4em
}

.lobby-table-block.not-operational.table-details-loaded .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    display: none
}

.lobby-table-block.occupied .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    left: 0;
    right: 0
}

.TableHistoryStatistic--3az41,.TableHistoryStatisticTopCard--QsycZ {
    padding: 3px .3em
}

.TableHistoryStatistic--3az41.phone--3TQ_p,.TableHistoryStatisticTopCard--QsycZ.phone--3TQ_p {
    padding: 0
}

.TableHistoryStatisticTopCard--QsycZ {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: center;
    align-content: center
}

.TableHistoryStatisticTopCard--QsycZ div[class^=historyItem] {
    width: 11.5%;
    margin: 0 1.25% 9px 0!important
}

.TableHistoryStatisticTopCard--QsycZ div[class^=historyItem]:last-child {
    margin-right: 0!important
}

.TableHistoryStatisticTopCard--QsycZ div[class^=historyItem]>svg {
    overflow: visible
}

.TableHistoryStatisticTopCard--QsycZ.desktop--2gB3k div[class^=historyItem] {
    max-height: 1.9rem
}

.TableHistoryStatisticTopCard--QsycZ.phone--3TQ_p {
    margin-bottom: -.15em
}

.TableHistoryStatisticTopCard--QsycZ.phone--3TQ_p.portrait--2lGzD {
    margin-bottom: -.3em
}

.TableHistoryStatisticTopCard--QsycZ.phone--3TQ_p div[class^=historyItem] {
    width: 13.28571%
}

.historyItem--2gz2u {
    margin-bottom: .6rem;
    margin-right: .8rem;
    font-weight: 600;
    position: relative;
    box-sizing: border-box;
    width: 30px;
    height: 16px;
    font-size: 12px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0
}

.historyItem--2gz2u svg {
    max-width: 100%;
    max-height: 100%
}

.historyItem--2gz2u.largeMobileIcon--3hpO8 {
    width: 47px;
    height: 24px
}

.historyItem--2gz2u.animated--1Y7Cm {
    -webkit-animation: appearBoxMobileKeyframes--2-sdm .5s ease-out;
    animation: appearBoxMobileKeyframes--2-sdm .5s ease-out
}

.historyItem--2gz2u.animated--1Y7Cm.largeMobileIcon--3hpO8 {
    -webkit-animation: appearBoxMobileLargeKeyframes--1wiEC .5s ease-out;
    animation: appearBoxMobileLargeKeyframes--1wiEC .5s ease-out
}

.historyItem--2gz2u.isDesktop--2BuPY {
    width: 5.1rem;
    height: 3.2rem;
    font-size: 2rem;
    margin-top: .6rem;
    margin-bottom: 0
}

.historyItem--2gz2u.isDesktop--2BuPY.classicLayout--1h0Ms {
    margin-right: 1.5rem
}

.historyItem--2gz2u.isDesktop--2BuPY.animated--1Y7Cm {
    -webkit-animation: appearBoxDesktopKeyframes--e8fGT .5s ease-out;
    animation: appearBoxDesktopKeyframes--e8fGT .5s ease-out
}

.historyItem--2gz2u.isDesktop--2BuPY.animated--1Y7Cm.classicLayout--1h0Ms {
    -webkit-animation: appearBoxDesktopClassicKeyframes--24WQI .5s ease-out;
    animation: appearBoxDesktopClassicKeyframes--24WQI .5s ease-out
}

.historyItem--2gz2u.isLobby--3wLXA {
    width: 12%
}
</style>