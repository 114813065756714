import Cookies from "./cookies";
import i18n from "./i18n.js";

const EjNotification = () => {
  //eslint-disable-next-line
  const notifications = $(".ej-notification-popup");

  if (notifications.length) {
    notifications.each((index, notification) => {
      //eslint-disable-next-line
      let $notification = $(notification);
      const key = $notification.attr("data-eltidtag");

      //NEW COOKIE
      const notifCampaignId = $notification.attr("data-campaignId");
      const notifCookieStrategie = $notification.attr("data-cookieStrategie");
      const notifCookieStrategieDuration = $notification.attr("data-cookieStrategieDuration");
      const notifCookieRequired = $notification.attr("data-cookieRequired");
      const notifCookieName = $notification.attr("data-cookieName");

      if (isCookieExpired(notifCampaignId) || notifCookieStrategie == "aucune") {
        const cookieValidation = NotificationDisplayCookie(
          notifCampaignId,
          notifCookieStrategie,
          notifCookieStrategieDuration,
          notifCookieRequired,
          notifCookieName
        );

        if (!cookieValidation) {
          notification.remove();
        } else {
          if (
            NotificationConditionelDate(
              parseInt($notification.attr("data-date-debut")),
              parseInt($notification.attr("data-date-fin"))
            )
          ) {
            NotificationConditionelAudience($notification, key);
          }
        }
      }
    });

    NotificationCloseItem();
  }
};
// Core code here
const NotificationSetup = (notification, key) => {
  if (NotificationIsVideo(notification)) {
    NotificationVideo(notification);
  } else {
    NotificationImage(notification);
  }

  NotificationSetGoogleAnalytics(notification);
};

// Checking for Audience condition.
const NotificationConditionelAudience = (notification, key) => {
  const anon = notification.attr("data-audience-anonyme");
  const clientInvite = notification.attr("data-audience-client-invite");
  const clientJoueur = notification.attr("data-audience-client-joueur");
  const clientLeger = notification.attr("data-audience-client-leger");
  const infolettre = notification.attr("data-infolettre");

  let count = 0;

  let intervalNotification = setInterval(() => {
    count++;
    if (typeof ejConnect !== "undefined" && typeof loginNav !== "undefined") {
      var audienceCl = "anon";
      // le statut ejConnect.etatClient n''est pas toujours disponible quand ce code s'execute mais après,
      // donc on le récupère du local storage où il a été sauvegardé
      //eslint-disable-next-line
      if (ejConnect.etatClient == "anon") {
        audienceCl = localStorage.getItem("audienceClient");
      }
      let optin = "notSelected";
      /*eslint-disable*/
      if (loginNav.session_details !== null) {
        if (typeof loginNav.session_details.contact_ok) {
          optin = loginNav.session_details.contact_ok;
        }
      } else {
        optin = localStorage.getItem("optin");
      }
      /*eslin-enable*/

      switch (audienceCl) {
        case "ci": {
          if (clientInvite === "ci") {
            if (infolettre === "notSelected" || infolettre === optin)
              NotificationSetup(notification, key);
          }
          break;
        }
        case "cj": {
          if (clientJoueur === "cj") {
            if (infolettre === "notSelected" || infolettre === optin)
              NotificationSetup(notification, key);
          }
          break;
        }
        case "cl": {
          if (clientLeger === "cl") {
            if (infolettre === "notSelected" || infolettre === optin)
              NotificationSetup(notification, key);
          }
          break;
        }
        default: {
          if (anon === "anon" && infolettre === "notSelected") {
            NotificationSetup(notification, key);
          }
          break;
        }
      }
      clearInterval(intervalNotification);
    } else if (typeof ejConnect === "undefined" && anon === "anon") {
      NotificationSetup(notification, key);
    }
    if (count > 20) {
      clearInterval(intervalNotification);
    }
  });
};

// Check Date before do anything. If dates no set, it will just go through.
const NotificationConditionelDate = (start, end) => {
  if (isNaN(start) && isNaN(end)) return true;
  const now = new Date();
  const startDate = new Date(start);
  if (isNaN(start) || now >= startDate) {
    const endDate = new Date(end);
    if (!isNaN(end) && now > endDate) return false;
    return true;
  }
  return false;
};

// Check if Action is not none or cat and label aren't empty.
const NotificationSetGoogleAnalytics = (notification) => {
  // Setting up Google Analytics variables.
  const action = notification.attr("data-google-analytics-action");
  const category = notification.attr("data-google-analytics-category");
  const label = notification.attr("data-google-analytics-label");
  if (action !== "none" && category !== "" && label !== "") {
    notification.click(function () {
      EspacejeuxPortail_GoogleAnalytics.enregistrerEvenement(category, action, label, 0);
    });
  }
};
// ONLY IMAGE SETUP
const NotificationImage = (notification) => {
  // Init Display Image when loading and them when scrolling.
  NotificationDisplayImage(notification);
  NotificationImageOnScroll(notification);
};
const NotificationDisplayImage = (notification) => {
  const $document = $(document);
  let $content = $(".ej-container");
  if ($("#page-content").length) {
    $content = $("#page-content");
  }
  const notificationVideo = {
    isAuthorInstance:
      notification
        .find(".notificationVideoHiddenInputForm input[name='isAuthorInstance']")
        .val() === "true",
    isPreviewMode:
      notification.find(".notificationVideoHiddenInputForm input[name='isPreviewMode']").val() ===
      "true",
  };
  if (
    (notificationVideo.isAuthorInstance && notificationVideo.isPreviewMode) ||
    !notificationVideo.isAuthorInstance
  ) {
    let displayInNotification = false;
    const top = $content.offset() !== undefined && typeof $content.offset() !== "undefined" ? $content.offset().top : null;

    if (top != null && $document.scrollTop() < top) {
      notification.removeClass("active");
    } else {
      if (notification.attr("data-hide-on-mobile") === "true") {
        if ($(window).innerWidth() < 768) {
          notification.removeClass("active");
        } else {
          notification.css("display", "block");
          displayInNotification = true;
        }
      } else {
        notification.css("display", "block");
        displayInNotification = true;
      }
    }
    const displayInNotificationAttr = notification.attr("data-on-display-change");
    if (displayInNotification !== displayInNotificationAttr) {
      if (displayInNotification) {
        notification.addClass("active");
        notification.attr("data-on-display-change", true);
      } else {
        notification.removeAttr("style");
        notification.attr("data-on-display-change", false);
      }
    }
  }
};

const NotificationImageOnScroll = (notification) => {
  $(window).scroll(() => {
    NotificationDisplayImage(notification);
  });
};

// ONLY VIDEO SETUP
const NotificationVideo = (notification) => {
  const $notificationVideoObj = {
    contentId: notification.find(".notificationVideoHiddenInputForm input[name='contentId']").val(),
    isAuthorInstance:
      notification
        .find(".notificationVideoHiddenInputForm input[name='isAuthorInstance']")
        .val() === "true",
    isPreviewMode:
      notification.find(".notificationVideoHiddenInputForm input[name='isPreviewMode']").val() ===
      "true",
  };
  // Init Display Video when loading and them when scrolling.
  NotificationDisplayVideoIframe(notification, $notificationVideoObj);
  NotificationVideoOnScroll(notification, $notificationVideoObj);
};

const NotificationVideoOnScroll = (notification, notificationVideo) => {
  $(window).scroll(() => {
    NotificationDisplayVideoIframe(notification, notificationVideo);
  });
};

const NotificationDisplayVideoIframe = (notification, notificationVideo) => {
  const $document = $(document);
  let $content = $(".ej-container");
  if ($("#page-content").length) {
    $content = $("#page-content");
  }
  if (
    (notificationVideo.isAuthorInstance && notificationVideo.isPreviewMode) ||
    !notificationVideo.isAuthorInstance
  ) {
    let autoplay = 1;
    let displayInNotification = false;
    if ($document.scrollTop() < $content.offset().top) {
      notification.removeClass("active");
      autoplay = 0;
    } else {
      if (notification.attr("data-hide-on-mobile") === "true") {
        if ($(window).innerWidth() < 768) {
          notification.removeClass("active");
          autoplay = 0;
        } else {
          notification.css("display", "block");
          displayInNotification = true;
        }
      } else {
        notification.css("display", "block");
        displayInNotification = true;
      }
    }
    const displayInNotificationAttr = notification.attr("data-on-display-change");
    if (displayInNotification !== displayInNotificationAttr) {
      if (displayInNotification) {
        notification.addClass("active");
        notification.attr("data-on-display-change", true);
      } else {
        notification.removeAttr("style");
        notification.attr("data-on-display-change", false);
      }
    }
  }
};

// Close notification
const NotificationCloseItem = () => {
  $(document).on("click", ".ej-notification-popup .btn-close", function (e) {
    e.preventDefault();
    const notification = $(this).parent();
    const key = notification.attr("data-eltidtag");
    notification.removeClass("active");
    notification.find("iframe").remove();

    if (notification.attr("data-cookieStrategie") === "fermeture") {
      let expiration = convertCookieDurationToDate(
        notification.attr("data-cookieStrategieDuration")
      );
      localStorage.setItem("notif-" + notification.attr("data-campaignId"), expiration);
    }

    notification.remove();
  });
};

// Check if the notification is a video or image
const NotificationIsVideo = (notification) => {
  if (notification.attr("data-notification-type") === "video") return true;
  return false;
};

const NotificationDisplayCookie = (
  notifCampaignId,
  notifCookieStrategie,
  notifCookieStrategieDuration,
  notifCookieRequired,
  notifCookieName
) => {
  if (notifCookieStrategie !== "aucune" && localStorage.getItem("notif" + notifCampaignId)) {
    return false;
    /*if (notification) {
            notifications.css('display','none');
        } */
  } else if (notifCookieRequired === "true" && !Cookies.get(notifCookieName)) {
    return false;
    /*
        console.log("COOKIE : " + notifCookieName);
        console.log("COOKIE FILE : " + Cookies.get(notifCookieName));
        notification.remove();*/
    /*if (notification) {
            notifications.css('display','none');
        }*/
  } else if (notifCookieStrategie === "visite") {
    let expiration = convertCookieDurationToDate(notifCookieStrategieDuration);
    localStorage.setItem("notif-" + notifCampaignId, expiration);
  }

  return true;
};

function isCookieExpired(notifCampaignId) {
  const expirationDateInStorage = new Date(localStorage.getItem("notif-" + notifCampaignId));
  const today = new Date();

  if (today < expirationDateInStorage) {
    return false;
  } else {
    return true;
  }
}

function convertCookieDurationToDate(numberOfDays) {
  const today = new Date();
  const expiration = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + Number(numberOfDays)
  );
  return expiration;
}

// Check if value is number.
function isNumeric(value) {
  return /^\d+$/.test(value);
}
export default EjNotification;
