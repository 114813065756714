<template>
	<div class="lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1" data-role="lobby-table-game-specific-wrapper">
		<div class="lobbyTableGameSpecificData--3whkv TableHistoryStatistic--3az41">
			<div class="itemsContainer--uoZQl" style="padding-bottom: 0%;">
				<div class="itemsRow--2Nmj9 skipRowsTransition--QL5CX" style="width: 100%; padding-bottom: 9.45455%; padding-left: 0%; padding-right: 0%; left: 0%;">
					<div class="itemContainer--1x-Md noTransition--1UEsy" :style="'width: 11.625%; padding-bottom: 8.45455%; transform: translateX('+ getTranslateX(index) +'%);'" :key="index" v-for="(result,index) in results.slice(0,8)">
						<div class="itemScale--3KxD1">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 48" :data-sector="result">
                                <defs>
                                    <linearGradient x1="0%" y1="100%" y2="0%" id="monopolyHistoryCardA-2r">
                                        <stop stop-color="#B6B5B5" offset="0%"></stop>
                                        <stop stop-color="#808080" offset="49.81%"></stop>
                                        <stop stop-color="#B6B5B5" offset="100%"></stop>
                                    </linearGradient>
                                    <linearGradient x1="0%" y1="100%" y2="0%" id="monopolyHistoryCardB-2r">
                                        <stop stop-color="#B6B5B5" offset="0%"></stop>
                                        <stop stop-color="#808080" offset="48.61%"></stop>
                                        <stop stop-color="#B6B5B5" offset="100%"></stop>
                                    </linearGradient>
                                    <linearGradient x1="0%" y1="100%" y2="0%" id="monopolyHistoryCardA-4r">
                                        <stop stop-color="#E2C47E" offset="0%"></stop>
                                        <stop stop-color="#C29934" offset="49.81%"></stop>
                                        <stop stop-color="#E2C47E" offset="100%"></stop>
                                    </linearGradient>
                                    <linearGradient x1="0%" y1="100%" y2="0%" id="monopolyHistoryCardB-4r">
                                        <stop stop-color="#E2C47E" offset="0%"></stop>
                                        <stop stop-color="#C29934" offset="48.61%"></stop>
                                        <stop stop-color="#E2C47E" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <g v-if="result == 'ch'" :class="(index == 0)?'h0--P_FsA':''" fill-rule="evenodd" :fill="(index == 0)?'none':'#FA8F1F'">
									<path d="M59 7.06C59 6.47 58.53 6 57.96 6H8.04C7.47 6 7 6.47 7 7.06v33.88c0 .59.47 1.06 1.04 1.06h49.92c.57 0 1.04-.47 1.04-1.06V7.06z" :stroke-opacity="(index == 0)?'1':'.8'" stroke="#FA8F1F" stroke-width="2" :fill-opacity="(index == 0)?'.7':'.3'" fill="#FA8F1F"></path>
									<g class="front--1kHdA">
										<path d="M30.2 13.1a5.46 5.46 0 0 1 5.33-.7 5.6 5.6 0 0 1 2.99 3.1c1.63 3.74-1.25 6.19-3.36 7.97l-.29.24a14.63 14.63 0 0 0-.72.68c-.23.23-.47.46-.72.68l-.2.17c-.4.34-.85.7-1.01 1.17-.17.47-.07 1 .26 1.38.44.42 1.06.58 1.64.4l.43-.17-.07.17-.07.2c-.15.52-.32 1.03-.52 1.53l-.17.37-.17.02a2.94 2.94 0 0 1-2.43-.79 2.7 2.7 0 0 1-.94-1.55c-.57-2.23 1.25-3.78 3.19-5.4 1.89-1.59 3.85-3.22 3.52-5.64a2.9 2.9 0 0 0-.88-1.8 3.34 3.34 0 0 0-3.8-.8 3.44 3.44 0 0 0-2.05 3.24v.18c.02.46.2.9.54 1.21l.23.2.26.22-.1.05-.16.1-.63.44-.21.16c-.25.22-.55.4-.84.53l-.05.03-.09-.11c-.65-.72-1-1.66-1.1-2.87l-.01-.36c.02-1.72.84-3.3 2.2-4.25zm2.38 19.27a1.9 1.9 0 0 1 1.76.1c.47.28.8.76.87 1.31.1.8-.12 1.47-.68 1.86-.6.43-1.38.47-2.03.14-.61-.3-1-.93-1-1.62v-.04c.03-.74.44-1.4 1.08-1.75z" fill="#FFF" fill-rule="nonzero"></path>
									</g>
								</g>
								<g v-else-if="!(result == '2r' || result == '4r')" :class="(index == 0)?'h0--P_FsA':''" fill-rule="evenodd" :fill="(index == 0)?'none':getCouleur(result)">
									<ellipse v-if="index == 0" fill="#000" opacity="0.89" cx="33" cy="24" rx="29" ry="20"></ellipse>
									<ellipse v-if="index == 0" :fill="getCouleur(result)" opacity="0.8" cx="33" cy="24" rx="29" ry="20"></ellipse>
									<ellipse :stroke-opacity="(index == 0)? '1' : '0.4'" :stroke="getCouleur(result)" stroke-width="2" :fill-opacity="(index == 0)?'1':'0.15'" cx="33" cy="24" :rx="(index == 0)?'32':'29'" :ry="(index == 0)?'23':'20'"></ellipse>
									<g class="front--1kHdA">
										<text font-family="Inter" font-weight="500" font-size="26" :fill="(index == 0)?((result == 1) ? '#4D4D4D' : '#FFF'):(getCouleur(result))" text-anchor="middle" x="50%" y="70%">{{result}}</text>
									</g>
								</g>
                                <g v-else :class="(index == 0)?'h0--P_FsA':''" fill-rule="evenodd" :fill="(index == 0)?'none':((result == '2r')?'#B6B5B5':'#E2C47E')">
									<path v-if="index == 0" d="M15-5a3 3 0 0 0-3 3v52a3 3 0 0 0 3 3h36a3 3 0 0 0 3-3V-2a3 3 0 0 0-3-3H15z" :stroke="'url(#monopolyHistoryCardA-'+result+')'" stroke-width="2" fill-opacity=".3" transform="rotate(90 33 24)"></path>
                                    <path d="M16-2h34a1 1 0 0 1 1 1v50a1 1 0 0 1-1 1H16a1 1 0 0 1-1-1V-1a1 1 0 0 1 1-1z" :fill="'url(#monopolyHistoryCardB-'+result+')'" :fill-opacity="(index == 0)?'1':'0.3'" :stroke-width="(index == 0)?'0':'2'" :stroke="'url(#monopolyHistoryCardA-'+result+')'" transform="rotate(90 33 24)"></path>
                                    <g class="front--1kHdA">
                                        <path :fill="(index ==0)?'#FFF':((result == '2r')?'#B6B5B5':'#E2C47E')" d="M40.2 21.71a1.17 1.17 0 0 0-.2-1.84 1.13 1.13 0 0 0-1.55.43c-.32.56-.14 1.27.4 1.59.1.06.22.1.34.13a2.67 2.67 0 0 0-1.39 2.35v2.12l-4.68-2.8a1.58 1.58 0 0 1-.55-2.13l4.13-7.4c.42-.75 1.34-1 2.06-.57l7.16 4.27c.72.43.97 1.38.55 2.13l-.95 1.71h-5.1l-.22.01zm4-3.08a1.13 1.13 0 0 0-1.55.43c-.31.56-.13 1.27.4 1.59.55.32 1.24.12 1.56-.44.31-.56.13-1.26-.4-1.58zm-8.22 2.27a1.13 1.13 0 0 0-1.55.44c-.31.56-.13 1.27.4 1.59.55.31 1.24.12 1.56-.44.31-.56.13-1.27-.4-1.59zM39 15.52a1.13 1.13 0 0 0-1.55.43c-.31.56-.13 1.27.4 1.59.55.31 1.24.12 1.56-.44.31-.56.13-1.27-.4-1.58z"></path><path fill="#FFF" d="M48.65 34.48h-8.23c-.84 0-1.53-.7-1.53-1.56v-8.55c0-.86.69-1.56 1.53-1.56h8.23c.84 0 1.52.7 1.52 1.56v8.55c0 .86-.68 1.56-1.52 1.56zm-1.1-3.89c-.63 0-1.14.52-1.14 1.17 0 .64.5 1.16 1.13 1.16.62 0 1.13-.52 1.13-1.16 0-.65-.5-1.17-1.13-1.17zm-3.02-3.11c-.62 0-1.12.52-1.12 1.17 0 .64.5 1.16 1.12 1.16.63 0 1.13-.52 1.13-1.16 0-.65-.5-1.17-1.13-1.17zm-3-3.11c-.63 0-1.13.52-1.13 1.17 0 .64.5 1.16 1.13 1.16.62 0 1.12-.52 1.12-1.16 0-.65-.5-1.17-1.12-1.17z"></path>
                                        <path v-if="index != 0" :fill="(result == '2r')?'#B6B5B5':'#E2C47E'" d="M48.65 34.48h-8.23c-.84 0-1.53-.7-1.53-1.56v-8.55c0-.86.69-1.56 1.53-1.56h8.23c.84 0 1.52.7 1.52 1.56v8.55c0 .86-.68 1.56-1.52 1.56zm-1.1-3.89c-.63 0-1.14.52-1.14 1.17 0 .64.5 1.16 1.13 1.16.62 0 1.13-.52 1.13-1.16 0-.65-.5-1.17-1.13-1.17zm-3.02-3.11c-.62 0-1.12.52-1.12 1.17 0 .64.5 1.16 1.12 1.16.63 0 1.13-.52 1.13-1.16 0-.65-.5-1.17-1.13-1.17zm-3-3.11c-.63 0-1.13.52-1.13 1.17 0 .64.5 1.16 1.13 1.16.62 0 1.12-.52 1.12-1.16 0-.65-.5-1.17-1.12-1.17z"></path>
                                        <text font-family="Inter" font-weight="500" font-size="26" :fill="(index ==0)?'#FFF':((result == '2r')?'#B6B5B5':'#E2C47E')" text-anchor="middle" x="35%" y="70%">{{result.charAt(0)}}</text>
                                    </g>
								</g>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const CS_COULEUR_BLANC = '#FEFFFF';
const CS_COULEUR_VERT = '#A9E08C';
const CS_COULEUR_ROSE = '#FABBBB';
const CS_COULEUR_BLEU = '#88CDE2';
const CS_COULEUR_ORANGE = '#FA8F1F';

export default {
    props : {
		results : {
			type : Array,
			required : true
		},
		appareil : {
			type : String,
			required : true
		},
		prefixeUrl : {
			type : String,
			required : true
		},
	},

	methods: {
		//retourne l'indentation de chaque affichage
		getTranslateX:function(index){
			return (index*108.602);
		},
		//retourne la couleur selon le résultat
		getCouleur:function(result){
			if(result == "1"){
				return CS_COULEUR_BLANC
			}else if(result == "2"){
				return CS_COULEUR_VERT
			}else if(result == "5"){
				return CS_COULEUR_ROSE
			}else if(result == "10"){
				return CS_COULEUR_BLEU
			}else{ //chance ("ch")
				return CS_COULEUR_ORANGE
			}
		}
	},
}
</script>

<style>
.TableLogo--3RFNG.desktop--1Txr1 {
    z-index: 4
}

.TableLogo--3RFNG.desktop--1Txr1.roulette--2Wcvy {
    bottom: 1.85em
}

.TableLogo--3RFNG.desktop--1Txr1.doubleBall--2sPYM {
    bottom: 2.5em
}

.lobbyTableGameSpecificWrapper--3fAEk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    min-height: 3.35rem;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1em;
    z-index: 4
}

.lobbyTableGameSpecificWrapper--3fAEk.emptyGameHistoryStatistic--Uu-Gi {
    min-height: auto!important
}

.lobby-table-block.occupied .lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .lobbyTableGameSpecificWrapper--3fAEk {
    left: 1px;
    right: 1px
}

.lobbyTableGameSpecificWrapper--3fAEk .lobby-table-block.table-is-full-behind {
    display: none
}

.lobbyTableGameSpecificData--3whkv:empty {
    display: none
}

.lobby-table-block.info .lobbyTableGameSpecificData--3whkv {
    display: none!important
}

.lobbyTableGameSpecificData--3whkv .lobby-table-block.table-details-loaded.not-operational:not(.info) {
    display: none
}

.lobby-table-block.crazytime .lobbyTableGameSpecificData--3whkv,.lobby-table-block.monopoly .lobbyTableGameSpecificData--3whkv {
    margin-bottom: -.15em
}

.lobby-table-block.crazytime .phone--3PmoR .lobbyTableGameSpecificData--3whkv,.lobby-table-block.monopoly .phone--3PmoR .lobbyTableGameSpecificData--3whkv {
    margin-bottom: -.25em
}

.desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 1.9em;
    z-index: 4
}

.desktop--1Txr1 .lobbyTableGameSpecificData--3whkv>* {
    z-index: 4
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    box-sizing: border-box;
    bottom: -1px;
    min-height: 3em;
    padding: 0 .3em .4em
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk.portrait--w-M_r {
    min-height: 2.4em
}

.lobby-table-block.not-operational.table-details-loaded .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    display: none
}

.lobby-table-block.occupied .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    left: 0;
    right: 0
}

.TableHistoryStatistic--3az41 {
    box-sizing: border-box;
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%
}

.TableHistoryStatistic--3az41 div[class^=itemScale]>svg {
    width: 100%;
    position: relative;
    overflow: visible
}

.itemsContainer--uoZQl {
    position: relative;
    width: 100%;
    height: 100%
}

.itemsRow--2Nmj9 {
    transition: opacity .5s linear;
    opacity: 1;
    position: relative;
    direction: ltr
}

.itemsRow--2Nmj9.skipRowsTransition--QL5CX {
    transition: none
}

.itemsRow--2Nmj9.hiddenRow--ENOlV {
    opacity: 0
}

.itemsRow--2Nmj9.fadeElements--34koz {
    overflow: hidden;
    -webkit-mask-image: linear-gradient(90deg,rgba(74,74,74,.3),#4a4a4a 1.2%,#4a4a4a 98.8%,rgba(74,74,74,.3));
    mask-image: linear-gradient(90deg,rgba(74,74,74,.3),#4a4a4a 1.2%,#4a4a4a 98.8%,rgba(74,74,74,.3))
}

.itemsRow--2Nmj9.isScrollable--3zo-M {
    overflow: hidden
}

.itemContainer--1x-Md {
    position: absolute;
    pointer-events: all;
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out,-webkit-transform .25s ease-out
}

.itemContainer--1x-Md.noTransition--1UEsy {
    transition-property: none
}

.itemScale--3KxD1 {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation-duration: .35s;
    animation-duration: .35s;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.h0--P_FsA,.h1--3v3-e {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-transform-origin: center;
    transform-origin: center
}

.h0--P_FsA {
    -webkit-animation-name: h0--P_FsA;
    animation-name: h0--P_FsA
}

.back--2kS-B.animated--11B-E,.front--1kHdA.animated--11B-E {
    -webkit-animation-name: frontFadeKeyframes--1T6F8;
    animation-name: frontFadeKeyframes--1T6F8;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards
}

.front--1kHdA.animated--11B-E {
    -webkit-animation-name: frontFadeKeyframes--1T6F8;
    animation-name: frontFadeKeyframes--1T6F8
}

@-webkit-keyframes h0--P_FsA {
    0% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    75% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes h0--P_FsA {
    0% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    75% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}
</style>