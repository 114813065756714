import Vue from 'vue';

import RoutesApi from '../../services/api/Routes'
import i18n from '../../i18n'
import Page from '../../views/Page.vue'
import router from '../../router';

const state = {
  isRoutesLoaded: false,
  routes: {},
}

const getters = {
}


function traiterRoutes(routes) {
  var routesTraitees = {};
  ajouterRoute(routesTraitees, routes,"","", "", routes['@name']);
  return routesTraitees;
}

function ajouterRoute(routesTraitees, noeud, path, pathLangue, parentNode, rootName) {
  var navURL = (i18n.locale == "en" && "navURL" in noeud) ? "navURL" : "@name";

  if ("@nodes" in noeud && noeud["@nodes"].length > 0) {
    noeud["@nodes"].forEach(element => ajouterRoute(routesTraitees,
                                                    noeud[element],
                                                    path + "/" + noeud['@name'],
                                                    pathLangue + "/" + noeud[navURL],
                                                    noeud,
                                                    rootName));    
  } 
  var fullPathLangue = "/" + i18n.locale + pathLangue + "/" + noeud[navURL];
  //var fullPathLangue = "/:lang" + pathLangue + "/" + noeud[navURL];

  var menuTitle = ("menuTitle" in noeud) ? noeud['menuTitle'] : noeud['title'];

  var parentName = "";
  if(parentNode['@name'] != rootName){      
    parentName = parentNode['navURL'];
  }
  
  //Faire attention la valeur de afficherDansMenu est une string
  var afficherDansMenu = false;
  if(noeud.afficherDansMenu === 'true'){
    afficherDansMenu = true;
  }    
  var uneRoute = {
    afficherDansMenu: afficherDansMenu,
    parent: parentName,
    path: fullPathLangue,
    name: noeud['@name'],
    props: {
      chemin: "/espacejeux" + path + "/" + noeud['@name'],
      menuTitle: menuTitle,
    }
  }
  routesTraitees[fullPathLangue] = uneRoute;      
  
}

const actions = {
    initRoutes (context) {
        if (!state.isRoutesLoaded) {
            context.commit('setRoutesLoaded', true);
            return RoutesApi.getRoutes(i18n.locale)
                .then(function (routes) {  
                    var routesTraitees = traiterRoutes(routes);
                    context.commit('initRoutes', routesTraitees)            
                })
                .catch(error => console.log(error));
        } else {
          //console.log('DANS initRoutes: isRoutesLoaded est vrai');
        }
    },
}

const mutations = {
    setRoutesLoaded (state, isRoutesLoaded) {
        state.isRoutesLoaded = isRoutesLoaded;
    },
    initRoutes (state, routes) {
        //Vue.set(state.routes, route.path, route);
      state.routes = routes;
        //state.routes[route.path] = route;
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}