var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "img90tit10 graphik_bold",
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _c(
        "a",
        {
          staticClass: "offre-speciale",
          attrs: {
            href: _vm.contenu.internalUrl,
            "data-tag":
              _vm.getNomRouteCourante() +
              " - " +
              _vm.contenu.titre.replace(/&nbsp;/g, " ") +
              " - " +
              _vm.contenu.dataTag,
          },
        },
        [
          _c("div", { staticClass: "img90tit10_content_container h-100" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.getImageSrcVariation(
                    _vm.getImageByScreenSize(_vm.contenu)
                  ),
                  expression:
                    "getImageSrcVariation(getImageByScreenSize(contenu))",
                },
              ],
              staticClass: "w-100 image",
              attrs: { alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "img90tit10_text_content" }, [
              _vm.contenu.surTitre
                ? _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.contenu.surTitre) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "img90tit10_titre graphik_bold",
                domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
              }),
              _vm._v(" "),
              _vm.contenu.sousTitre
                ? _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.contenu.sousTitre) },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.contenu.texte) } }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }