<template>
  <div :data-component-name="$options.name" class="h-100">
    <div class="w-100 h-100 div_general">
      <b-row
        class="w-100 h-100 d-flex position-relative offre_special_container hauteurImageMobile"
      >
        <b-col cols="8" class="h-25 mt-2">
          <div v-if="contenu.petiteNote" class="boite_petite_note">
            <span class="petite_note graphik_bold" v-html="contenu.petiteNote"> </span>
          </div>
        </b-col>
        <b-col
          class="d-flex flex-column align-items-center align-items-md-start py-1 py-md-0 py-sm-0 justify-content-center position-static col-12 col-md-4 barreDessousMobile mobile_text_center"
          :style="styleObject"
        >
          <span
            v-if="contenu.idJeu != null"
            :class="['progressif ' + contenu.tailleProgressif + ' ' + contenu.idJeu]"
          ></span>
          <div>
            <div
              v-if="contenu.surTitre"
              class="offre_surtitre graphik_bold"
              v-html="contenu.surTitre"
            ></div>
            <h3 class="titre graphik_bold" v-html="contenu.titre"></h3>
          </div>
          <div class="sousTitre" v-html="contenu.sousTitre"></div>
          <a
            v-if="getMediaImageUrl(contenu)"
            :id="contenu['@id']"
            :href="getMediaImageUrl(contenu)"
            class="stretched-link offre-speciale"
            :ga-img-name="imageName"
            :data-tag="
              getNomRouteCourante() +
              ' - ' +
              contenu.titre.replace(/&nbsp;/g, ' ') +
              ' - ' +
              contenu.sousTitre +
              ' - ' +
              contenu.dataTag
            "
          >
            <button
              class="btn graphik_bold"
              :style="[
                { 'background-color': contenu.colorBtn },
                { 'border-color': contenu.borderColor },
              ]"
            >
              {{ contenu.texteBouton }}
            </button>
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaImageOffreSpecial",
  props: ["contenu"],
  data() {
    return {};
  },
  computed: {
    classObject: function () {
      return {
        rounded: this.contenu.coinArrondi,
      };
    },
    imageName: function () {
      if (this.contenu.bgImage && this.contenu.bgImage.length > 0) {
        return this.contenu.bgImage.substring(
          parseInt(this.contenu.bgImage.lastIndexOf("/") + 1),
          this.contenu.bgImage.lastIndexOf(".")
        );
      } else if (this.contenu.image && this.contenu.image.length > 0) {
        return this.contenu.image.substring(
          parseInt(this.contenu.image.lastIndexOf("/") + 1),
          this.contenu.image.lastIndexOf(".")
        );
      } else {
        return "";
      }
    },
    styleObject: function () {
      var textColor = "black";
      var textMobColor = "black";
      var backgroundColor = "transparent";
      var backgroundMobColor = "#efefef";

      if (this.$mq == "sm" && this.contenu.secBgThemeColor) {
        return {
          "background-color": this.contenu.secBgThemeColor,
          color: textColor,
        };
      }

      if (this.contenu.couleurTitre) {
        textColor = this.contenu.couleurTitre;
      }
      if (this.contenu.mobCouleurTitre) {
        textMobColor = this.contenu.mobCouleurTitre;
      }
      if (this.contenu.couleurFondTitre) {
        backgroundColor = this.contenu.couleurFondTitre;
      }
      if (this.contenu.mobCouleurFondTitre) {
        backgroundMobColor = this.contenu.mobCouleurFondTitre;
      }

      if (this.$mq == "sm") {
        return {
          "background-color": backgroundMobColor,
          color: textMobColor,
        };
      }

      return {
        "background-color": backgroundColor,
        color: textColor,
      };
    },
  },
  created: function () {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px !important;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}
.div_general {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

body.theme-bg-bingo {
  div > * {
    line-height: 1em;
  }
  .boite_petite_note {
    background-color: rgba(0, 0, 0, 0.65);
    width: fit-content;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    padding: 1.5% 3%;
    color: white;

    @include ms-respond(font-size, 0);
  }

  .titre {
    @include ms-respond(font-size, 4);
    margin: 0;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      padding-top: 0.75rem;
    }
  }

  .btn {
    margin-bottom: 12%;
    text-transform: uppercase;
    color: black;
    @include ms-respond(font-size, -1);
    border-radius: 4rem;

    @include media-breakpoint-down(sm) {
      padding: 1rem 2.5rem;
      border-radius: 20px;
    }
  }

  .offre_surtitre {
    @include ms-respond(font-size, 0);
  }

  .sousTitre {
    @include ms-respond(font-size, 0);
    margin: 0;
    margin-top: 2%;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .hauteurImageMobile {
    height: 70%;
    @include media-breakpoint-down(sm) {
      height: 65%;
    }
  }

  @include media-breakpoint-down(sm) {
    .offre_surtitre {
      font-size: 12px;
    }

    .barreDessousMobile {
      height: 50%;
      background-color: #efefef; //couleur background par defaut
    }

    .offre_special_container {
      align-content: space-between;
      align-items: center;
    }

    .mobile_text_center {
      text-align: center;
    }
  }
}
</style>
