var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-100 margins",
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _c("div", { staticClass: "general h-100", style: _vm.styleObject }, [
        _c("div", { staticClass: "div_general" }, [
          _c("div", { staticClass: "space-between" }, [
            _vm.contenu.idJeu != null
              ? _c("span", {
                  class: [
                    "progressif " +
                      _vm.contenu.tailleProgressif +
                      " " +
                      _vm.contenu.idJeu,
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("div", {
                staticClass: "surTitre graphik_bold",
                style: [{ color: _vm.contenu.couleurTitre }],
                domProps: { innerHTML: _vm._s(_vm.contenu.surTitre) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "titre_mediaImage graphik_bold",
                style: [{ color: _vm.contenu.couleurTitre }],
                domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
              }),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "stretched-link publicite-en-vedette",
                attrs: {
                  href: _vm.getMediaImageUrl(_vm.contenu),
                  "ga-img-name": _vm.imageName,
                  "data-tag":
                    _vm.getNomRouteCourante() +
                    " - " +
                    _vm.contenu.surTitre +
                    " - " +
                    _vm.contenu.titre.replace(/&nbsp;/g, " ") +
                    " - " +
                    _vm.contenu.dataTag,
                },
              },
              [
                _vm.contenu.texteBouton
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn mt-2 graphik_semibold texteBouton",
                          style: [
                            { "border-color": _vm.contenu.borderColorBtn },
                            { "background-color": _vm.contenu.colorBtn },
                          ],
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.contenu.texteBouton) +
                              "\n            "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }