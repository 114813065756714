import Cookies from './cookies';

/********************************************
 * Gestion des bannières latérales (catfish)
 *********************************************/
const BanniereLaterale = function() {
    $('.right_slider .handle').each(function () {

        var handler = $(this);
        var obj1 = handler.parent();
        var cookieSetting = {
            strategy : obj1.data('cookie-strategy'),
            id: obj1.data('cookie-id')
        };
        var cookiePrefix= 'banniere-lateral-';

        if(cookieSetting.strategy === 'active'){
            if(Cookies.get(cookiePrefix+cookieSetting.id)){
                return;
            }
        }

        obj1.css({'right': '-' + parseInt(obj1.outerWidth(), 10) + 'px'});
        $(this).css({'bottom': parseInt(handler.position().top, 10) - 5 + 'px'});
        obj1.css('visibility','visible');

        //associe le x de fermeture
        obj1.find('a.close-button').bind("click", function () {
            handler.click();
        });

        //associe le tab de fermeture
        handler.bind("click", function () {

            if(cookieSetting.strategy === 'active'){
                Cookies.get(cookiePrefix+cookieSetting.id, true);
            }

            var obj = handler.parent();
            if (obj.hasClass('open')) {
                obj.animate({right: '-' + parseInt(obj1.outerWidth(), 10) + 'px'}, 300).removeClass('open');
            } else {
                obj.animate({right: '0px'}, 300).addClass('open');
            }
        });
    });
}

export default BanniereLaterale;