import Cookies from "./components/cookies";
import CatfishInit from "./components/catfish-init";

function cacherContenuAudience(audienceClient, optinfo, classList) {
  if (!classList) return false;
  if (
    !classList.contains("anon") &&
    !classList.contains("ci") &&
    !classList.contains("cl") &&
    !classList.contains("cj")
  ) {
    return false;
  } else if (audienceClient == "anon") {
    if (optinfo == "notSelected") {
      if (classList.contains(audienceClient) && classList.contains("infolettre-notSelected")) {
        return false;
      }
    }
  } else {
    if (optinfo == "notSelected") {
      if (classList.contains(audienceClient) && classList.contains("infolettre-notSelected")) {
        return false;
      }
    } else {
      if (
        classList.contains(audienceClient) &&
        (classList.contains("infolettre-notSelected") ||
          classList.contains("infolettre-" + optinfo))
      ) {
        return false;
      }
    }
  }
  return true;
}

(function ($, window, document) {
  $.fn.ejCatfish = function (config) {
    var domaine =
      "." +
      location.hostname.split(".").reverse()[1] +
      "." +
      location.hostname.split(".").reverse()[0];

    var catfishTmpl$ = this;

    if (this.length > 1) {
      if (window.console) {
        console.error("ejCatfishTmpl selector has to be an id");
      }
      return;
    }

    // Some settings only defined in catfish.ftl
    var settings = $.extend(
      {
        full: false,
        theme: "dark",
        campaignId: "xxx",
        hyperlien: false,
        cookieStrategie: "aucune",
        cookieStrategieExpiration: 0,
        topShadow: false,
        background: false,
        cookieRequired: false,
        cookieName: "yyy",
        audienceAnonyme: true,
        audienceClientLeger: true,
        audienceClientInvite: true,
        audienceClientJoueur: true,
      },
      config || {}
    );
    // SI LA STRATEGIE D'AFFICHAGE N'EST PAS SYSTÉMATIQUE
    // ET QUE LA CAMPAGNE DE MARKETING A DÉJÀ PROMU SON CONTENU
    // DANS CETTE INSTANCE DE NAVIGATEUR
    if (settings.cookieStrategie !== "aucune" && Cookies.get("catfish-" + settings.campaignId)) {
      return false;
    }

    // SI L'AFFICHAGE EST CONDITIONÉ PAR UNE PAGE EXTERNE À LA COURANTE
    // ET DÉPENDS D'UNE LOGIQUE PROPRE AU DOMAINE
    // DONT L'ÉDITEUR A INFORMÉ LE SERVEUR ET QUE L'ÉVENEMENT DE LECTURE
    // D'UNE ÉCRITURE PRÉCÉDENTE DE TÉMOIN NOMMÉ RETOURNE RÉALISTEMENT FAUX
    if (settings.cookieRequired && !Cookies.get(settings.cookieName)) {
      return false;
    }
    // SINON EFFECTUER L'AFFICHAGE

    var catfish$ = $(catfishTmpl$.html());

    // AJOUTER L'AUDIENCE SELECTIONNER
    //        if( settings.audienceAnonyme && settings.audienceClientInvite && settings.audienceClientJoueur && settings.audienceClientLeger ) {
    //            catfish$.addClass("all-audience");
    //        }

    if (settings.audienceAnonyme) catfish$.addClass("anon");
    if (settings.audienceClientInvite) catfish$.addClass("ci");
    if (settings.audienceClientJoueur) catfish$.addClass("cj");
    if (settings.audienceClientLeger) catfish$.addClass("cl");
    if (settings.infolettre) catfish$.addClass(`infolettre-${settings.infolettre}`);

    const audienceClient = localStorage.getItem("audienceClient");
    const optin = localStorage.getItem("optin");
    var classList = catfish$.classList || catfish$[0].classList;

    //eslint-disable-next-line
    var hideOptin = cacherContenuAudience(audienceClient, optin, classList);

    if (hideOptin) {
      catfish$.addClass("d-none");
    }

    if (settings.full) {
      catfish$.addClass("full");
    }

    if (settings.background) {
      catfish$.css(
        "background",
        "rgba(" +
          settings.background.R +
          "," +
          settings.background.G +
          "," +
          settings.background.B +
          "," +
          settings.background.a +
          ")"
      );
    }

    catfish$
      .appendTo("body")
      .addClass(settings.theme)
      .addClass(settings.topShadow ? "topShadow" : "")
      .find("span.catfish-close")
      .on("click", function () {
        close(catfish$);
      });

    var close = function (elem$) {
      // SI LA STRATEGIE EST DE CONDITIONNER LES AFFICHAGES SUIVANTS, C'EST D'ICI QU'ORIGINE LE VETO
      if (settings.cookieStrategie === "fermeture") {
        Cookies.set("catfish-" + settings.campaignId, true, settings.cookieStrategieDuration);
      }
      elem$.trigger("beforeClose");

      if ($.ui) {
        elem$.animate(
          {
            height: 0,
          },
          800,
          "easeInOutBack",
          function () {
            elem$.trigger("closed");
          }
        );
      } else {
        elem$.animate(
          {
            height: 0,
          },
          500,
          function () {
            elem$.trigger("closed");
          }
        );
      }
    };

    catfish$.data("catfish", {
      instance: catfish$,
      api: {
        close: close,
      },
      hyperlien: settings.hyperlien,
    });
    /*
        setTimeout(function () {
            //catfish$.trigger('init');
        	//CatfishInit();
        }, 1000);
*/
    const catfishSize = catfish$.length;

    catfish$.each(function (index) {
      catfish$.on("gererCookieStrategie", function () {
        if (
          settings.cookieStrategie !== "aucune" &&
          Cookies.get("catfish-" + settings.campaignId)
        ) {
          catfish$.css("display", "none");
        } else if (settings.cookieRequired && !Cookies.get(settings.cookieName)) {
          catfish$.css("display", "none");
        } else if (settings.cookieStrategie === "visite") {
          Cookies.set(
            "catfish-" + settings.campaignId,
            true,
            settings.cookieStrategieDuration,
            "/",
            domaine
          );
        }
      });
    });
    //CatfishInit();
    return catfish$;
  };

  $.fn.closeCatfish = function () {
    var catfish = this.data("catfish");

    if (catfish) {
      catfish.api.close(catfish.instance);
    } else {
      if (window.console) {
        console.log("closeCatfish is not available on this element");
      }
    }
  };
})(window.jQuery, window, document);

/**
 *
 *  évènements disponibles en format plugiciel
 *
 *
 *	var config = {}
 *
 *	var catfish$ = $('#ejCatfishTmpl').ejCatfish(config)
 *        .on('closed', function () {
 *        })
 *        .on('beforeClose', function () {
 *        })
 *        .on('init', function () {
 *        })
 *        .on('click', function () {
 *            if ($(this).data().catfish.hyperlien) {
 *            }
 *        });
 *
 *
 **/
