import DetectBrowser from './detectBrowser';

$.fn.positionStickyPolyfill = function() {
    return this.each(function () {
        const $self = $(this);
        const selfTop = $self.offset().top;
        let viewportTop = window.pageYOffset;

        function init() {
            $self.css('width', $self.outerWidth());
            setPosition();

            window.addEventListener('scroll', throttle(setPosition, 50));
        }

        function setPosition() {
            viewportTop = window.pageYOffset;
            if( selfTop <= viewportTop ) {
                $self.addClass( 'position-fixed' );
            } else {
                $self.removeClass( 'position-fixed' );
            }
        }

        if( DetectBrowser.isIE11 ) {
            init();
        }

    });
}