import Vue from 'vue';
import VueMq from 'vue-mq';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueLazyload from 'vue-lazyload';
import VShowSlide from 'v-show-slide';
import App from './App.vue';
import Entete from './Entete.vue';
//import EventBus from './eventBus';
import router from '../../router';
import store from '../../store';
import IdleVue from 'idle-vue'
//import jp from "jsonpath";
//import * as Properties from './properties';
import VueMasonry from 'vue-masonry-css';
import VueCurrencyFilter from 'vue-currency-filter';
import MoneyFormat from 'vue-money-format';
import vueAwesomeCountdown from 'vue-awesome-countdown'
import i18n from '../../i18n';

import {WebServicesAccess} from '../../config';

//import BootstrapVue from 'bootstrap-vue';
import {LayoutPlugin, ModalPlugin, CardPlugin, NavbarPlugin, LinkPlugin,EmbedPlugin,ImagePlugin,ButtonPlugin, CarouselPlugin, AlertPlugin, DropdownPlugin,VBTooltipPlugin } from 'bootstrap-vue';

Vue.config.productionTip = false;

//Instances de Bootstrap
//Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(CardPlugin);
Vue.use(NavbarPlugin);
Vue.use(LinkPlugin);
Vue.use(EmbedPlugin);
Vue.use(ImagePlugin);
Vue.use(ButtonPlugin);
Vue.use(CarouselPlugin);
Vue.use(AlertPlugin);
Vue.use(DropdownPlugin);
Vue.use(VBTooltipPlugin);

//Instances de plugins
Vue.use(VueAxios, axios);
Vue.use(VueLazyload);
Vue.use(VShowSlide)
Vue.use(VueMasonry);
Vue.use(vueAwesomeCountdown, 'vac');
Vue.use(MoneyFormat);

//Affichage monétaire
Vue.use(MoneyFormat, { 
  currencyCode: 'CAD',
  subunitValue: true,
  hideSubunits: true
});

//Affichage monétaire
Vue.use(VueCurrencyFilter, { 
  symbol : '$',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
});

Vue.filter('stripSpaces', function (value, round) {
  //Retirer les espaces aux milliers
  //Uniformiser avec le point décimal
  var cleaned = value.replace(/[.|,](\d\d)$/, ".$1").replace(/[,|\s]/g, "");
  if(round) cleaned = Math.round(cleaned);

  return cleaned;
});

Vue.filter('stripDecimals', function (value) {
  //Retirer la décimale
  //Remettre le ($) en français
  return value.replace(/[.|,]\d\d(\s?.?)$/,"$1");
});

//Breaking points programmatiques
Vue.use(VueMq, {
  breakpoints: { 
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1440,
    xxxl: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

//Autres librairies & propriétés globales
//Object.defineProperty(Vue.prototype, '$_', { value: _ });
//Object.defineProperty(Vue.prototype, '$jp', { value: jp });
//Object.defineProperty(Vue.prototype, '$eventBus', { value: EventBus });
//Object.defineProperty(Vue.prototype, '$colors', {value: Properties.colors});
//Object.defineProperty(Vue .prototype, '$currency', {value: Properties.currencies});

Vue.mixin({
  methods: {
    log: msg => console.log(msg),
    getImageSrcVariation: function(image) {
      if(!image) {return null;}
      
      // On s'attend à avoir une image très grande à la source dans le DAM (environ 3000px de large)
      var variation; // Magnolia: config.modules.ej-template.themes.ej-template-theme.imaging.variations
      var vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if      (vw <= 576)  {variation = '576w';}
      else if (vw <= 768)  {variation = '768w';}
      else if (vw <= 992)  {variation = '992w';}
      else if (vw <= 1200) {variation = '1200w';}
      else if (vw <= 1440) {variation = '1440w';}
      else if (vw <= 1920) {variation = '1920w';}
      else                 {variation = 'default';}
	  return this.nettoyerURI(image.replace('default', variation));
    },
    getImageSrc: function(image) {
      if (image.indexOf('/magnolia') == 0 && !WebServicesAccess.IS_PORTAIL_LEGER) {
		return this.nettoyerURI(image);
      } else {
        return this.nettoyerURI(WebServicesAccess.DAM_PATH + image);
      }
    },
    getImageByScreenSize(contenu){
      if(this.$mq == 'sm'){
        if(contenu.imageMobile && contenu.imageMobile != ''){
          return contenu.imageMobile;
        }        
      }
      return contenu.image;
    },
    getMediaImageUrl(contenu){
      if(contenu.internalUrl && contenu.internalUrl != null && contenu.internalUrl != ''){
        return contenu.internalUrl;     
      }
      if(contenu.externalUrl && contenu.externalUrl != null && contenu.externalUrl != ''){
        return contenu.externalUrl;     
      }
      return null;
    },

    nettoyerURI(img) {
      var host = window.location.host;
      // Images de portail.espacejeux.xxx vont pointer sur portail.lotoquebec.xxx
      // Afin d'être mises en cache par CDN/Imperva sur le domaine lotoquebec.com
      var reg = new RegExp(
        "^(m|www|portail).(dev-[0-9]{2}.)?espacejeux(-[0-9]{2})?.(com|dev|org|ta)"
      );
      if (reg.test(host)) {
        var hostModifie = host.replace(/(m|www)/, "portail").replace(/espacejeux/, "lotoquebec");
        return "//" + hostModifie + img;
      }
      return img;
    },
    parseDate(date) {
      const parsed = Date.parse(date);
      if (!isNaN(parsed)) {
        return parsed;
      }

      return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
    },
    isDateAffichageCourante(dateDebut, dateFin){
      let debut = null;
      let fin = null;
      let currentDate = new Date();
      if (dateDebut != undefined) {
        debut = this.parseDate(dateDebut);
      }
      if (dateFin != undefined) {
        fin = this.parseDate(dateFin);
      } 

      if(debut == null && fin == null){
        return true;
      }
      if(debut == null && (currentDate < fin)){
        return true;
      } else if(fin == null && currentDate > debut){
        return true;
      } else if (currentDate > debut && currentDate < fin) {
        return true;
      } else {
        return false;
      }
    },
    //Obtenir le nom de la route courante a titre analytique
    getNomRouteCourante(){
      var currentRouteName = router.currentRoute.path;
      var routes = store.state.routes.routes;
      var currentRoute = routes[currentRouteName];
      return currentRoute.name;
    },
    isItDesktop(){
        if (navigator.userAgent.match(/Android/i) 
            || navigator.userAgent.match(/webOS/i) 
            || navigator.userAgent.match(/iPhone/i)  
            || navigator.userAgent.match(/iPad/i)  
            || navigator.userAgent.match(/iPod/i) 
            || navigator.userAgent.match(/BlackBerry/i) 
            || navigator.userAgent.match(/Windows Phone/i)) { 
            return false;
        } else { 
            return true;
        } 
    }
  }
})

const eventsHub = new Vue();

// Permettre d'arrêter certains appels json en cas d'inactivité du consommateur
Vue.use(IdleVue, {
  store,
  eventEmitter: eventsHub,
  idleTime: (process.env.NODE_ENV == 'production') ? 60000 : 10000, // 60 sec en PO, 10 sec en DEV
  startAtIdle: false
})

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById("container-entete")) {
		new Vue({
		  i18n,
		  router,
		  store,
		  render: h => h(Entete)
		}).$mount("#container-entete");
  }

  if (document.getElementById("container")) {
    new Vue({
		  i18n,
		  router,
		  store,
		  render: h => h(App)
		}).$mount("#container");
  }
});