// Originally in ej-commmun-portail.js

import EspacejeuxPortail_ServeurPub from "./espacejeux-portail-serveur-pub";
import CatfishInit from "./catfish-init";

$(document).on("ejEventLogout ejEventLogin", function (event) {
  //TODO NE SERT PLUS ???
  $('div[id^="zone-rotation-poids"]').each(function () {
    EspacejeuxPortail_ServeurPub.randomBanner($(this).children(".item"));
  });

  if ($.fn.slick) {
    $(".ejCarrouselBannieres").slick("slickUnfilter");
    $(".ejCarrouselBannieres")
      .slick("slickFilter", function () {
        return $(this).hasClass(ejConnect.etatClient);
      })
      .find("img")
      .show();

    var marginLeft = $(".ejCarrouselBannieres").width() / 2 - $(".slick-dots").width() / 2;
    $(".slick-dots").css("left", marginLeft);
  }
  setTimeout(() => {
    if (event.type == "ejEventLogout") {
      if (location.href.match("/fr/mon-compte")) location.href = "/fr/accueil";
      else if (location.href.match("/en/my-account")) location.href = "/en/home";
    }
  }, 100);
});
