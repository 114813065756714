/********************************************/
/*** OBJECT EspacejeuxPortail_Game_mobile ***/
/********************************************/
/* NAME : EspacejeuxPortail_Game_mobile
 * PARM : no
 * DESC : Object for language
 */

function EspacejeuxPortail_game_mobile() {
  this.getGameLaunchUrl = function (
    gameName,
    fournisseur,
    playMode,
    lang,
    referer,
    largeurFenetre,
    hauteurFenetre,
    domainePublicLoteries
  ) {
    ejGameMobile.getGameLaunchUrl(
      gameName,
      fournisseur,
      channel,
      playMode,
      lang,
      referer,
      largeurFenetre,
      hauteurFenetre,
      domainePublicLoteries,
      false,
      false,
      "",
      ""
    );
  };

  //TODO: Contrairement à launch_game_and_gaq (desktop), le consommateur non-connecté doit cliquer 2 fois sur "jouer" pour lancer un jeu.
  this.getGameLaunchUrl = function (
    gameName,
    fournisseur,
    channel,
    playMode,
    lang,
    referer,
    largeurFenetre,
    hauteurFenetre,
    domainePublicLoteries,
    fournisseurDesactive,
    jeuDesactive,
    msgDesactivation,
    tableId
  ) {
    var infoWindowType =
      arguments.length > 13 && arguments[13] !== undefined ? arguments[13] : "none";
    var infoWindowDevices =
      arguments.length > 14 && arguments[14] !== undefined ? arguments[14] : "all";
    var infoWindowBrowsers =
      arguments.length > 15 && arguments[15] !== undefined ? arguments[15] : "";
    var infoWindowOS = arguments.length > 16 && arguments[16] !== undefined ? arguments[16] : "";
    var infoWindowText = arguments.length > 17 && arguments[17] !== undefined ? arguments[17] : "";

    // peut-être temporaire s'il y a un changement de stratégie dans le fichier de produit
    /*        if(new RegExp ('^OGS*').test(fournisseur)){
					fournisseur = 'Openbet';
				}
		*/
    if (isLoggedIn() || ejGameMobile.lireCookie("logged_in") === "1") {
      // Si la connexion Auth0 est active
      if (document.querySelector("#btn-ciam-auth0")) {
        // On vide le sessionStorage pour game_mobile
        window.sessionStorage.removeItem("game_mobile");
      }
      var urlValidationIdentite = "";
      var urlCUS = "";
      var message = "";

      if (lang == "fr") {
        urlValidationIdentite = "/fr/mon-compte/validation-identite";
        urlCUS = "/fr/inscription/legal";
      } else {
        urlValidationIdentite = "/en/my-account/identity-validation";
        urlCUS = "/en/registration/legal";
      }
      if (ejApi.inscriptionEstIncomplete()) {
        $("#ej-error .button-confirm").click(function () {
          $(location).attr("href", urlCUS);
        });
        $("#ej-error").on("show.bs.modal", function (event) {
          $(this).find("#dialog_msg").html(xlmsg.ERROR_LIGHT_CLIENT);
          $("#ej-error .button-cancel").css("display", "block");
        });
        $("#ej-error").modal("show");
      } else {
        // Décommenter ce code lorsqu'on fera la désactivation des jeux mobile

        /** Ghostbusters Triple Slime
         *
         *  est désactivé lorsque le user-agent contient "firefox" et "android"
         *
         * */
        if (gameName == "200-1359-002") {
          var agent = navigator.userAgent.toLowerCase();
          if (agent.indexOf("firefox") >= 0 && agent.indexOf("android") >= 0) {
            var message = msgDesactivation.length > 0 ? msgDesactivation : xlmsg.JEU_DESACTIVE;
            $("#ej-error").on("show.bs.modal", function (event) {
              $(this).find("#dialog_msg").html(message);
            });
            $("#ej-error").modal("show");
            return;
          }
        }
        /*  Lightning Gems
         *
         *  est désactivé car non optimal sur les navigateurs
         *  1. firefox
         *  2. internet explorer
         *
         */
        if (gameName == "OGS_79203") {
          var agent = platform.name;
          agent = agent.toLowerCase();
          if (agent == "firefox" || agent == "ie") {
            var message = msgDesactivation.length > 0 ? msgDesactivation : xlmsg.JEU_DESACTIVE;
            $("#ej-error").on("show.bs.modal", function (event) {
              $(this).find("#dialog_msg").html(message);
            });
            $("#ej-error").modal("show");
            return;
          }
        }

        var fournisseurDesactiveAvertissement = false;
        var jeuDesactiveAvertissement = false;

        if (fournisseurDesactive) {
          fournisseurDesactive = EspacejeuxPortail.verifierFournisseurDateActive(gameName);
          fournisseurDesactiveAvertissement =
            EspacejeuxPortail.verifierFournisseurAvertissementActive(gameName);
          message = xlmsg.JEU_DESACTIVE;
        }

        if (jeuDesactive) {
          jeuDesactive = EspacejeuxPortail.verifierJeuDateActive(gameName);
          jeuDesactiveAvertissement = EspacejeuxPortail.verifierJeuAvertissementActive(gameName);
          if (msgDesactivation.length > 0) {
            message = msgDesactivation;
          } else {
            message = xlmsg.JEU_DESACTIVE;
          }
        }

        var browsers = infoWindowBrowsers.length
          ? infoWindowBrowsers.replace(/[\[\]]+/g, "").split(",")
          : null;
        var os = infoWindowOS.length ? infoWindowOS.replace(/[\[\]]+/g, "").split(",") : null;
        var currentBrowser = platform.name.toLowerCase();
        var currentOS = platform.os.family.toLowerCase();
        var md = new MobileDetect(navigator.userAgent);
        var deviceMatch = true,
          browserMatch = true,
          osMatch = true;

        switch (infoWindowDevices) {
          case "mobile":
            deviceMatch = md.mobile() ? true : false;
            break;
          case "smartphone":
            deviceMatch = md.phone() ? true : false;
            break;
          case "tablet":
            deviceMatch = md.tablet() ? true : false;
            break;
          case "desktop":
            deviceMatch = !md.mobile() ? true : false;
          default:
            deviceMatch = true;
            break;
        }

        //patch pour les appareils mobile apple récent qui retourne "os x" plutôt que "ios"
        //la librairie MobileDetect n'est plus à jour et n'a pas été updaté depuis 2021 sur le git de l'auteur
        if (infoWindowDevices == "mobile" || infoWindowDevices == "tablet") {
          if (currentOS == "os x") {
            //validation que c'est bien un appareil IOS mobile
            const is_ios = ejGameMobile.detectIOS();
            if (is_ios) {
              currentOS = "ios";
              deviceMatch = true;
            }
          }
        }

        if (browsers) {
          browserMatch = browsers.some(function (elem) {
            return currentBrowser == elem;
          });
        }

        if (os) {
          osMatch = os.some(function (elem) {
            return currentOS == elem;
          });
        }

        if (fournisseurDesactive || jeuDesactive) {
          $("#ej-error").on("show.bs.modal", function (event) {
            $(this).find("#dialog_msg").html(message);
          });
          $("#ej-error").modal("show");
        } else if (infoWindowType == "blocker" && deviceMatch && browserMatch && osMatch) {
          $("#ej-error").on("show.bs.modal", function (event) {
            $(this).find("#dialog_msg").html(infoWindowText);
          });

          $("#ej-error").modal("show");
        } else {
          function displayGame() {
            if (fournisseur.toLowerCase() == "agl") {
              if (playMode == "real") {
                playMode = "play";
              }
              var path = lang == "en" ? "en/experience-games" : "fr/jeux-experience";
              var url = domainePublicLoteries + "/lel/" + path + "/" + gameName + "/" + playMode;
              window.open(
                url,
                "_blank",
                "width=" +
                  largeurFenetre +
                  ", height=" +
                  hauteurFenetre +
                  ", resizable=yes, menubar=no, status=no"
              );
            } else if (fournisseur.toLowerCase() == "ogslivecasino") {
              var url =
                "/portal_api/livecasino/launch?gameName=" + gameName + "&channel=" + channel;

              if (typeof tableId !== "undefined") {
                url += "&tableId=" + tableId;
              }

              // Les jeux CED doivent tous s'ouvrir dans la même fenêtre
              window.open(
                url,
                "ogslivecasino",
                "width=" +
                  largeurFenetre +
                  ", height=" +
                  hauteurFenetre +
                  ", resizable=yes, menubar=no, status=no"
              );
            } else {
              $.ajax({
                type: "POST",
                url: "/gcm-ws/gamelaunch/" + gameName,
                data: "channel=M" + "&lang=" + lang + "&playMode=" + playMode,
                dataType: "text",
                beforeSend: function (jqXHR, settings) {
                  $("#ej-loader").modal("show");
                },
                complete: function (jqXHR, textStatus) {
                  $("#ej-loader").modal("hide");
                },
                success: function (data) {
                  ejGameMobile.handleLaunchResponse(data, referer);
                },
                error: function (result) {
                  $("#ej-error").on("show.bs.modal", function (event) {
                    $(this).find("#dialog_msg").html(xlmsg.GAME_LAUNCH_FAIL);
                  });
                  $("#ej-error").modal("show");
                  //console.log(result);
                },
              });
            }
          }

          if (
            infoWindowType == "nonblocker" &&
            deviceMatch &&
            browserMatch &&
            osMatch &&
            !fournisseurDesactiveAvertissement &&
            !jeuDesactiveAvertissement
          ) {
            // Si un message d'avertissement est présent, le message non-bloquant n'est pas affiché
            $("#ej-error").on("show.bs.modal", function (event) {
              $(this).find("#dialog_msg").html(infoWindowText);
            });

            $("#ej-error").on("hidden.bs.modal", function (event) {
              displayGame();
              $("#ej-error").off("hidden.bs.modal");
            });

            $("#ej-error").modal("show");
          } else {
            if (fournisseurDesactiveAvertissement || jeuDesactiveAvertissement) {
              let heureDebutDesactivation;

              if (fournisseurDesactiveAvertissement) {
                heureDebutDesactivation = moment(
                  $("div#" + gameName + " time.dateDebutDesactivationFournisseur").attr("datetime")
                );
              } else {
                heureDebutDesactivation = moment(
                  $("div#" + gameName + " time.dateDebutDesactivationJeu").attr("datetime")
                );
              }

              const messageAvertissement =
                EspacejeuxPortail.LANGUE === "fr"
                  ? "Vous avez jusqu’à " +
                    heureDebutDesactivation.format("HH:mm") +
                    " h pour vous amuser à ce jeu, avant qu’une mise à jour soit effectuée."
                  : "You only have until " +
                    heureDebutDesactivation.format("LT") +
                    " to play this game, as a update is scheduled to begin at that time.";

              document.querySelector("#modalJeuAvertissement .dialog-msg").textContent =
                messageAvertissement;
              $("#modalJeuAvertissement").modal("show");
              $("#modalJeuAvertissement")
                .find(".button-confirm")
                .on("click", function () {
                  displayGame();
                });
            } else {
              displayGame();
            }
          }
        }
      }

      $(this).addJeuxRecentsToLocalStorage(gameName);
    } else {
      // Si la connexion Auth0 est active
      if (document.querySelector('#btn-ciam-auth0')) {
        const game = {
          gameName: gameName,
          fournisseur: fournisseur,
          channel: channel,
          playMode: playMode,
          lang: lang,
          referer: referer,
          largeurFenetre: largeurFenetre,
          hauteurFenetre: hauteurFenetre,
          domainePublicLoteries: domainePublicLoteries,
          fournisseurDesactive: fournisseurDesactive,
          jeuDesactive: jeuDesactive,
          msgDesactivation: msgDesactivation,
          tableId: tableId,
          infoWindowType: infoWindowType,
          infoWindowDevices: infoWindowDevices,
          infoWindowBrowsers: infoWindowBrowsers,
          infoWindowOS: infoWindowOS,
          infoWindowText: infoWindowText
        };

        /**
         * On sauvegarde gameMobile dans le sessionStorage
         * pour le lancement Mobile des jeux après connexion Auth0
         */
        var gameMobile = JSON.stringify(game);
        window.sessionStorage.setItem("game_mobile", gameMobile);
      }
      Espacejeux_OuvrirLogin();
    }
  };

  /*
   *	Function:
   *		lireCookie()
   *	Arguments:
   *		name: nom du cookie
   *  Retour:
   *      La valeur:  si le cookie existe
   *      Null: si le cookie n'existe pas
   *	Synopsis:
   *      Fonction utilitaire permettant de lire la valeur d'un cookie
   */
  this.lireCookie = function (name) {
    if (document.cookie.length == 0) return null;
    var regSepCookie = new RegExp("(; )", "g");
    var cookies = document.cookie.split(regSepCookie);
    for (var i = 0; i < cookies.length; i++) {
      var regInfo = new RegExp("=", "g");
      var infos = cookies[i].split(regInfo);
      if (infos[0] == name) {
        return decodeURI(infos[1]);
      }
    }
    return null;
  };

  this.detectIOS = function () {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    var isAppleDevice = navigator.userAgent.includes("Macintosh");
    var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)
    return isIOS || (isAppleDevice && (isTouchScreen || this.iOS1to12quirk()));
  };

  this.iOS1to12quirk = function () {
    var audio = new Audio(); // temporary Audio object
    audio.volume = 0.5; // has no effect on iOS <= 12
    return audio.volume === 1;
  };

  this.handleLaunchResponse = function (xmlResponse, referer) {
    var xmlParser = new DOMParser();
    var xmlData = xmlParser.parseFromString(xmlResponse, "text/xml");

    var launchURL = xmlData.getElementsByTagName("GameUrl")[0].childNodes[0].nodeValue;

    //pass extra information to common ui: language and referer page (target on back arrow press)
    launchURL = launchURL.replace(
      "gcmParams=",
      "gcmParams=lang%3D" +
        ejApi.lireLangue() +
        "%7Creferer%3D" +
        referer.split("/").join("%2F") +
        "%7C"
    );
    //also pass language to game
    launchURL = launchURL + "&lang=" + ejApi.lireLangue();

    if (launchURL.indexOf("gcmParams") === -1) {
      launchURL = launchURL + "&lobbyurl=" + encodeURIComponent(window.location.origin + referer);
    }

    var urlMethodNode = xmlData.getElementsByTagName("GameUrlMethod")[0];

    if (urlMethodNode && urlMethodNode.childNodes[0].nodeValue == "POST") {
      //extract url post params
      var params = {};
      var postDataNode = xmlData.getElementsByTagName("GamePostData")[0];
      if (postDataNode) {
        var paramNodeList = postDataNode.getElementsByTagName("Param");
        for (var i = 0; i < paramNodeList.length; ++i) {
          if (typeof paramNodeList[i].childNodes[0] !== "undefined") {
            params[paramNodeList[i].getAttribute("name")] =
              paramNodeList[i].childNodes[0].nodeValue;
          }
        }
      }
      ejGameMobile.post_to_url(launchURL, params);
    } else {
      //Redirect to the game URL returned from the gameLaunch web service response
      parent.window.location = launchURL;
    }
  };

  this.post_to_url = function (path, params) {
    var method = "post";

    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };
}

window.ejGameMobile = new EspacejeuxPortail_game_mobile();
