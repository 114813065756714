/********************************
* CASINO
********************************/
function enableResetSelections(enabled) {

  var $elemToHide;
  var $elemToShow;
      
  if (enabled == true) {
    
    $elemToHide = $ej("div.filtres div.lien-filtre-selection-off");
    $elemToShow = $ej("div.filtres div.lien-filtre-selection-on");
  
  } else {
    
    $elemToHide = $ej("div.filtres div.lien-filtre-selection-on");
    $elemToShow = $ej("div.filtres div.lien-filtre-selection-off");
  }

  $elemToHide.css("display", "none");
  $elemToShow.css("display", "block");
}

window.lesFiltresSAppliquent = function(){
	var filterDoesApply = false;
	$ej("input[id|='filtre']:checked").each(function(i, elem){
		if($ej(elem).attr("id").indexOf("-tous") == -1){
			filterDoesApply = true;
		}
	});
	return filterDoesApply;
}

function indicationDossierJeuAccessible(lienDossierJeu){
	$(lienDossierJeu).css("text-decoration","underline");
}
function indicationDossierJeu(lienDossierJeu){
	$(lienDossierJeu).css("text-decoration","none");
}

/** Scroll-to-top button */
$(document).ready(function(){
	$('.pevPromo').click(function(){
		var category = $(this).attr("data-google-analytics-category");
		var action = $(this).attr("data-google-analytics-action");
        var label = $(this).attr("data-google-analytics-label");
		if( action !== "none" && category !== "" && label !== ""){
			EspacejeuxPortail_GoogleAnalytics.enregistrerEvenement(category, action, label, 0);
		}
	});

	if(navigator.userAgent.toLowerCase().indexOf('samsungbrowser') > -1){
		$('#return-to-top').hide();
		$('#return-to-top-casino').hide();
	} else {
		$(window).scroll(function () {
	    if ($(this).scrollTop() >= 500) {
	      $('#return-to-top').fadeIn(200);
	      $('#return-to-top-casino').fadeIn(200);
      } else {
	      $('#return-to-top').fadeOut(200);
	      $('#return-to-top-casino').fadeOut(200);
	    }
		});
		$('#return-to-top').click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
    });
    $('#return-to-top-casino').click(function () {
	    	$('body,html').animate({
	        	scrollTop: 0
	    	}, 500);
		});
	}
});

window.initSectionCasino = function() {
  /* ------------------------------------------------------------------------- */
  /* Promotions gonflables */
  /* ------------------------------------------------------------------------- */
  
  // Gonflage
  $ej(".promos-gonflables-petit").mouseenter(function() {
    //petit sans editMode
    if ($ej(this).hasClass("editMode")) return;
    var idToShow = this.id;
    idToShow = idToShow.replace("-petit", "-grand");
    if($ej("#" + idToShow).length > 0){
       $ej("#" + idToShow).css({"display":"block", "visibility":"visible"});
       $ej("div[class^='promos-gonflables-petit']").css({"display":"none", "visibility":"hidden"});
    }
    
    
  });

  // Dégonflage
  $ej(".promos-gonflables-grand").mouseleave(function() {
   
	  $ej("div[class^='promos-gonflables-petit']").css({"display":"block", "visibility":"visible"});
    
    var idToHide = this.id;
    $ej("#" + idToHide).css({"display":"none", "visibility":"hidden"});
  });


  /* ------------------------------------------------------------------------- */
  /* Mouseover sur le jeu en vedette */
  /* ------------------------------------------------------------------------- */
  
  $ej(".bloc-jeu-vedette" ).mouseenter(function() {
    
    // Affichage des informations complémentaires
    $ej(this).find("div.bloc-jeu-vedette-img").stop().animate({opacity:0.2}, 500);
    $ej(this).find("div.bloc-jeu-vedette-info").stop().css("visibility", "visible").animate({opacity:1}, 500);
    
  }).mouseleave(function() {
    
    // Retour à l'état normal
    $ej(this).find("div.bloc-jeu-vedette-img").stop().animate({opacity:1}, 300);
    $ej(this).find("div.bloc-jeu-vedette-info").stop().css("visibility", "hidden").animate({opacity:0}, 300);
  });

  /* ------------------------------------------------------------------------- */
  /* Mouseover sur les coup de coeurs */
  /* ------------------------------------------------------------------------- */
  $ej(".coupDeCoeurListe .bloc-jeu-main-coupDeCoeur" ).mouseenter(function() {
    // Affichage des informations complémentaires
    $ej(this).find("div.bloc-jeu-img").stop().animate({opacity:0.05}, 500);
    $ej(this).find("div.bloc-jeu-info").stop().css("visibility", "visible").animate({opacity:1}, 500);
  }).mouseleave(function() {
    // Retour à  l'anormal
    $ej(this).find("div.bloc-jeu-img").stop().animate({opacity:1}, 300);
    $ej(this).find("div.bloc-jeu-info").stop().css("visibility", "hidden").animate({opacity:0}, 300);
  });


  $ej("div.tooltip-item").tooltip();
  
  
}

/*******************************************************************************
 * 
 * PAGE DES GAGNANTS
 * 
 * ****************************************************************************/
function formatterGagnant(gagnant, spanDroit){
  var ligneGagnant = '<span class="info-gagnant '+spanDroit+'">' +
  '<span class="inner">' +
       '<span class="jeu">';
       
     if(gagnant.channel !== undefined && gagnant.channel == 'M'){
    ligneGagnant += '<img src="/dam/espacejeux/m4/resources/images/ui/mobile_icon.png" />'+
    '<img src="'+gagnant.lienImagePetit+'" height="40" /></span>';
     } else {
       ligneGagnant += '<img src="'+gagnant.lienImagePetit+'" height="45" /></span>';
     }
     ligneGagnant += '<span class="gagnant" style="text-transform:uppercase;">'+gagnant.first_name;
     
     if(gagnant.last_name != undefined){
       ligneGagnant += ' '+gagnant.last_name.substr(0,1)+'.';
     }
     ligneGagnant += '</span><span class="date">'+gagnant.date+'</span>'+
       '<span class="montant"><b>'+gagnant.total_winnings+'</b></span></span>' +
     '</span>';
     
     return ligneGagnant;
}

window.formatterLigneGagnants = function(gagnant1, gagnant2) {
  var ligne = '<li>' + formatterGagnant(gagnant1, "");
  
  if(gagnant2 !== undefined && 
	 gagnant2.fin === undefined) {  //gagnant2 != null
     ligne += formatterGagnant(gagnant2, "droit");
  }
  return ligne + '</li>';
}
    
window.initCarrousel = function(elem){
  $(elem).jCarouselLite({
      vertical: true,
        hoverPause: false,
        visible: 3,
        auto: 1000,
        speed: 1000
    });
}

function allerVersAncre (options) {
	var regex = options.regularExp; 
    var array = regex.exec(options.uri);
    if ( null != array ) {
       	var gog = $( "#" + array[1] );
       	if(gog.length) {
       		var offs = gog.offset(); 
       		window.scrollTo(0, offs.top);          		
       	}
    }
}