import Vue from "vue";

import { i18n } from "../../mixins/mixinLangue";

import CedTable from "../../components/ced/CedTable.vue";
import CedTableListe from "../../components/ced/CedTableListe.vue";
import CedTableListeLive from "../../components/ced/CedTableListeLive.vue";
import CedTablePremiereOuverte from "../../components/ced/CedTablePremiereOuverte.vue";

document.addEventListener("DOMContentLoaded", function() {
  if (document.getElementById("vueCasinoEnDirect")) {
    new Vue({
      el: "#vueCasinoEnDirect",
      i18n,
      components: {
        "ced-table": CedTable,
        "ced-table-liste": CedTableListe,
        "ced-table-liste-live": CedTableListeLive,
        "ced-table-premiere-ouverte": CedTablePremiereOuverte
      },
      mounted: function() {
        // JS pour pallier au problème d’interférence entre un app VueJS et le plugin jquery Lazyload : cacher les sections avec backgound images lazyloaded jusqu’à ce que l’app Vue soit activée.
    	try {
	        this.$nextTick(() => {
	          // eslint-disable-next-line no-undef
	          $(".lazyload.bg-img").show();
	        });
    	} catch(err) {
			console.log("mounted ced: " + err)
		}
      }
    });
  }
});
