import { breakpointIs } from './viewport-info';

/**
 * Functionality to handle expand/collapse trigger.
 * Optionally use data-target with a jQuery selector to identify the target to open or collapse. Default is the next element
 * Optionally use data-viewport-limit with a viewport-info limit selector to apply functionality only when the viewport match the selector
 * @param {string|HTMLElement} [limitContext=null] jQuery selector to limit search in. Default = null
 * @returns {void}
 * @function expandCollapse
 **/

// Was in utilities.js 

const ExpandCollapse = function (limitContext) {
	limitContext = limitContext || null;
	$('.fn-expand-collapse', limitContext).each(function () {
		var $trigger = $(this),
			$target = $trigger.data('target') ? $($trigger.data('target')) : $trigger.next(),
			vpLimit = $trigger.data('viewport-limit') || false,
			duration = $trigger.data('duration') || 450;

		$trigger.removeClass('fn-expand-collapse');

		const expand = function ($src, $toExpand, dur, force) {
			// Default value for optional params
			dur = dur || duration;
			force = force || false;

			/**
			 * If force - Remove class active and expanded but still slide down
			 * This is use when a data-viewport-limit is use
			 */
			$src[(!force ? 'add' : 'remove') + 'Class']('active');
			$toExpand[(!force ? 'add' : 'remove') + 'Class']('expanded');

			$toExpand.slideDown(dur);
		};

		const collapse = function ($src, $toCollapse, dur) {
			// Default value for optional param
			dur = dur || duration;

			$src.removeClass('active');
			$toCollapse.removeClass('expanded');

			$toCollapse.slideUp(dur);
		};

		$trigger.on('click', function () {
			var $this = $(this),
				isActive = $this.is('.active');

			if ( breakpointIs(vpLimit, 'down') ) {
				if (isActive) {
					collapse($this, $target, duration);
				} else {
					expand($this, $target, duration);
				}
				// Prevent default
				return false;
			}
		});

		// if a limit is use
		if (vpLimit) {
			if (breakpointIs(vpLimit, 'down')) {
				collapse($trigger, $target, 0);
			} else {
				expand($trigger, $target, 0, true);
			}

			// On resize or orientation change
			window.addEventListener('resize orientationchange', function () {
				var isInLimit = breakpointIs(vpLimit, 'down');
				if (isInLimit && !$trigger.is('.active')) {
					collapse($trigger, $target, 0);
				} else {
					expand($trigger, $target, duration, true);
				}
			});
		} else {
			// No limit - Apply in all context
			// Collapse if not active
			if (!$trigger.is('.active')) {
				collapse($trigger, $target, 0);
			} else {
				expand($trigger, $target, 0);
			}
		}
	});
}

export default ExpandCollapse;