<template>
     <div class="form-group">
        <label for="tri">{{ $t('navigation.libTrierPar') }}</label>
        <select class="tri  form-control" id="tri" v-model="tri" @change="updateTri(tri)" >
        
        <option value="ini">{{ $t('navigation.libOrdreInitial') }}</option>
        <option value="asc">{{ $t('navigation.libAscendant') }}</option>
        <option value="des">{{ $t('navigation.libDescendant') }}</option>
        </select>
    </div>    
</template>


<script>
import { mapGetters, mapMutations } from 'vuex';

export default {

	computed: {
		...mapGetters([
			'getTri'
		]),
		tri: {
			get() {
				return this.getTri;
			},
			set( value ) {
				this.updateTri( value );
			}
		}
	},

    methods: {
		...mapMutations([
			'updateTri'
		]),
	},
	
	mounted() {
		if (localStorage.getItem("ejTri") !== null) {
			this.updateTri(localStorage.getItem("ejTri"))
		}
	}

}
</script>