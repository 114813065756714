<template>
    <div :class="'RoadContainer--' + (gameTypeUnified == 'baccarat') ? '2N0mr' : '16iGR'">
        <svg xmlns="http://www.w3.org/2000/svg" :class="'road-1 svg--' + (gameTypeUnified == 'baccarat') ? '1G1Yo' : '2MOwB'" data-role="Big-road" x="0" y="0" width="40" height="6" viewBox="0 0 40 6" style="width: 100%;">
            <svg :class="'svg--' + (gameTypeUnified == 'baccarat') ? 'YDulJ':'1nrnH'" viewBox="0 0 40 6">
                <rect x="0" y="0" :fill="(gameTypeUnified == 'baccarat') ? '#e4e3e2':'#1A1A1A'" width="40" height="6"></rect>
                <line x1="0" x2="40" y1="0.025" y2="0.025" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="0" x2="40" y1="1" y2="1" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="0" x2="40" y1="2" y2="2" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="0" x2="40" y1="3" y2="3" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="0" x2="40" y1="4" y2="4" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="0" x2="40" y1="5" y2="5" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="0" x2="40" y1="5.975" y2="5.975" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="0.025" x2="0.025" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="1" x2="1" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="2" x2="2" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="3" x2="3" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="4" x2="4" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="5" x2="5" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="6" x2="6" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="7" x2="7" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="8" x2="8" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="9" x2="9" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="10" x2="10" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="11" x2="11" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="12" x2="12" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="13" x2="13" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="14" x2="14" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="15" x2="15" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="16" x2="16" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="17" x2="17" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="18" x2="18" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="19" x2="19" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="20" x2="20" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="21" x2="21" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="22" x2="22" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="23" x2="23" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="24" x2="24" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="25" x2="25" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="26" x2="26" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="27" x2="27" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="28" x2="28" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="29" x2="29" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="30" x2="30" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="31" x2="31" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="32" x2="32" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="33" x2="33" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="34" x2="34" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="35" x2="35" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="36" x2="36" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="37" x2="37" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="38" x2="38" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="39" x2="39" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>
                <line x1="39.975" x2="39.975" y1="0" y2="6" :stroke="couleurLigne" style="stroke-width: 0.05;"></line>

                <svg v-bind:item="item" v-bind:key="index" v-for="(item, index) in road" width="0.95" height="0.95" :x="item.location.column + 0.05" :y="item.location.row + 0.05" data-type="coordinates" :data-x="item.location.column" :data-y="item.location.row">
                    <svg xmlns="http://www.w3.org/2000/svg" :class="'svg--'+(gameTypeUnified == 'baccarat') ? '1vtM9':'2DlGu'" viewBox="0 0 80 80" data-type="roadItem" :name="(gameTypeUnified == 'baccarat') ? 'Banker':'Tiger'">
                        <defs>
                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="lightningCircleGradient">
                                <stop stop-color="#956823" offset="0%"></stop>
                                <stop stop-color="#C69844" offset="56%"></stop>
                                <stop stop-color="#F4E584" offset="100%"></stop>
                            </linearGradient>
                        </defs>
                    <svg :width="(gameTypeUnified == 'baccarat') ?'68px':'70px'" :height="(gameTypeUnified == 'baccarat') ?'68px':'70px'" :x="(gameTypeUnified == 'baccarat') ?'6px':'5px'" :y="(gameTypeUnified == 'baccarat') ?'6px':'5px'" viewBox="0 0 80 80">
                        <g v-if="item.color" transform="translate(0.000000,78.000000) scale(0.100000,-0.100000)" :fill="item.color" stroke="none" data-type="roadItemColor">
                        <path d="
                            M275 764 c-103 -37 -194 -110 -228 -184 -81 -174 -56 -342 67 -466
                            154 -153 398 -153 552 0 123 124 148 292 67 466 -21 44 -109 132 -153 153
                            -105 48 -221 60 -305 31z m179 -123 c3 -4 19 -11 36 -15 40 -8 128 -96 136
                            -136 4 -17 11 -33 15 -36 5 -3 9 -32 9 -64 0 -32 -4 -61 -9 -64 -4 -3 -11 -19
                            -15 -36 -8 -40 -96 -128 -136 -136 -17 -4 -33 -11 -36 -15 -3 -5 -32 -9 -64
                            -9 -32 0 -61 4 -64 9 -3 4 -19 11 -36 15 -40 8 -128 96 -136 136 -4 17 -11 33
                            -15 36 -12 7 -12 121 0 128 4 3 11 19 15 36 8 40 96 128 136 136 17 4 33 11
                            36 15 3 5 32 9 64 9 32 0 61 -4 64 -9z
                            " :fill="(item.lightning) ? 'url(#lightningCircleGradient)':item.color"></path>
                                                        
                                <path v-show="item.ties >= 1" d="
                                M260 360 l-255 -255 60 -60 60 -60 410 410 255 255 -60 60 -60 60
                                " fill="#51A548"></path>
                    </g>  
                        <svg height="100px" width="100px">
                            <text v-show="item.ties >= 2" :x="26" y="54" fill="black" font-size="50">{{item.ties}}</text>
                        </svg>  
                        <circle v-show="item.lightning" data-type="lightning-circle" :fill="(item.color == 'Red')?'#C52123':'#185CC6'" cx="39" cy="39" r="22"></circle>
                        <circle v-show="item.bankerPair" cx="10" cy="14" r="15" fill="red" stroke="#e4e3e2" stroke-width="8"></circle>
                        <circle v-show="item.playerPair" cx="60" cy="64" r="15" fill="blue" stroke="#e4e3e2" stroke-width="8"></circle>
                    </svg>
                    </svg>
                </svg>
            </svg>
        </svg>
    </div>

</template>

<script>
export default {

  data: function () {
    return {
    	isJeuLoaded: false,
    }
  },
  
  props: {
      gameTypeUnified:{
          type: String,
          required: true
      },
	  road: {
		  type: Array,
		  required: true
      },
	  appareil: {
		  type: String,
		  required: true
	  },
	  prefixeUrl: {
		  type: String,
		  required: true
	  },
  },
  computed:{
      couleurLigne:function(){
          return (this.gameTypeUnified == 'baccarat') ? "rgb(167, 169, 172)":"#4A4A4A";
      }
  },
}
</script>
