var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-100",
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _c(
        "div",
        { staticClass: "w-100 h-100 div_general" },
        [
          _c(
            "b-row",
            {
              staticClass:
                "w-100 h-100 d-flex position-relative offre_special_container hauteurImageMobile",
            },
            [
              _c("b-col", { staticClass: "h-25 mt-2", attrs: { cols: "8" } }, [
                _vm.contenu.petiteNote
                  ? _c("div", { staticClass: "boite_petite_note" }, [
                      _c("span", {
                        staticClass: "petite_note graphik_bold",
                        domProps: { innerHTML: _vm._s(_vm.contenu.petiteNote) },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex flex-column align-items-center align-items-md-start py-1 py-md-0 py-sm-0 justify-content-center position-static col-12 col-md-4 barreDessousMobile mobile_text_center",
                  style: _vm.styleObject,
                },
                [
                  _vm.contenu.idJeu != null
                    ? _c("span", {
                        class: [
                          "progressif " +
                            _vm.contenu.tailleProgressif +
                            " " +
                            _vm.contenu.idJeu,
                        ],
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _vm.contenu.surTitre
                      ? _c("div", {
                          staticClass: "offre_surtitre graphik_bold",
                          domProps: { innerHTML: _vm._s(_vm.contenu.surTitre) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h3", {
                      staticClass: "titre graphik_bold",
                      domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "sousTitre",
                    domProps: { innerHTML: _vm._s(_vm.contenu.sousTitre) },
                  }),
                  _vm._v(" "),
                  _vm.getMediaImageUrl(_vm.contenu)
                    ? _c(
                        "a",
                        {
                          staticClass: "stretched-link offre-speciale",
                          attrs: {
                            id: _vm.contenu["@id"],
                            href: _vm.getMediaImageUrl(_vm.contenu),
                            "ga-img-name": _vm.imageName,
                            "data-tag":
                              _vm.getNomRouteCourante() +
                              " - " +
                              _vm.contenu.titre.replace(/&nbsp;/g, " ") +
                              " - " +
                              _vm.contenu.sousTitre +
                              " - " +
                              _vm.contenu.dataTag,
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn graphik_bold",
                              style: [
                                { "background-color": _vm.contenu.colorBtn },
                                { "border-color": _vm.contenu.borderColor },
                              ],
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.contenu.texteBouton) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }