<template>
    <div v-html="contenu.texteHTML" :class="contenu.cssClass" class="contenu_html_taille_defaut"></div>
</template> 

<script>
  import {WebServicesAccess} from '../../config';
 
  export default {
    name:"TexteHTML",
    data(){
        return{
        }
    },
    props: ["contenu"],
    methods:{
    },
    created: function () {
	}
  }
</script>

<style lang="scss" scoped>
	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
  }
  
  // Ajouter configuration des bordures au composant TexteHTML dans Magnolia
  .texte_html_border{
      border: solid 2px #efefef;
      border-radius: ms(0);
  }

  .contenu_html_taille_defaut{
    font-size: ms(0);
  }

  ::v-deep .legal-txt{
    font-size: ms(-1);
  }

</style>