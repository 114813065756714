var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1",
      attrs: { "data-role": "lobby-table-game-specific-wrapper" },
    },
    [
      _c(
        "ol",
        {
          staticClass: "RecentResultsBar--3aBzj mt-0 mb-0",
          attrs: { "data-role": "recent-results" },
        },
        _vm._l(_vm.tableauResultat, function (resultat, index) {
          return _c(
            "li",
            {
              key: index,
              class: ["result--3aa_E", _vm.getClass(resultat)],
              attrs: { "data-role": "recent-result" },
            },
            [_vm._v(_vm._s(_vm.getTotalResultat(resultat)))]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }