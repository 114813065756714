(function ($,window, undefined) {
    $.fn.tableau = function (option) {
        return this.each(function () {

            var settings = $.extend(
                {newElemSizeMobile: 5, newElemSizeDesktop: 99, static: true}, option || {}
            );

            var tableau$ = $(this);
            var tableauBoutonPlus$ = $(this).find('.tableauBoutonPlus');

            if(settings.newElemSizeDesktop < $(this).find('.ligne').length){
                $(this).find('.plusWrapDesktop').removeClass('init');
            }
            if(settings.newElemSizeMobile < $(this).find('.ligne').length){
                $(this).find('.plusWrapMobile').removeClass('init');
            }

            var handler = {
                mobile: function () {
                    var button$ = $(this);

                    tableau$.find('.ligne:not(".mobile")')
                        .addClass('mobile');

                    if (!tableau$.find('.ligne:not(".mobile")').length) {
                        button$.parent().remove();
                    }
                },
                desktop: function () {
                    var button$ = $(this);

                    tableau$.find('.ligne:not(".desktop")')
                        .addClass('desktop');

                    if (!tableau$.find('.ligne:not(".desktop")').length) {
                        button$.parent().remove();
                    }
                }
            };

            tableauBoutonPlus$.on('click', function () {
                handler[$(this).data().buttonPlus].call(this);
            });
        });
    };
}(jQuery, window, undefined));