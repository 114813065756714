<template>
  <div :data-component-name="$options.name" class="img90tit10 graphik_bold">
    <a :href="contenu.internalUrl" :data-tag="getNomRouteCourante() + ' - ' + contenu.titre.replace(/&nbsp;/g,' ') + ' - ' + contenu.dataTag" class="offre-speciale">
      <div class="img90tit10_content_container h-100">
        <img v-lazy="getImageSrcVariation(getImageByScreenSize(contenu))" class="w-100 image" alt="" />
        <div class="img90tit10_text_content">
          <p v-if="contenu.surTitre" v-html="contenu.surTitre"></p>
          <div class="img90tit10_titre graphik_bold" v-html="contenu.titre"></div>
          <p v-if="contenu.sousTitre" v-html="contenu.sousTitre"></p>
        </div>     
      </div>
      <div v-html="contenu.texte">
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "MediaImageImg90tit10",
  data() {
    return {
    };
  },
  props: ["contenu"],
  methods: {},
  computed: {
    classObject: function () {
      return {
        rounded: this.contenu.coinArrondi
      }
    },
    styleObject: function () {
      return {
        'background-color': this.contenu.secBgThemeColor
      }
    },
  },
  created: function() {}
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
}
.image{
  border-top-left-radius: ms(2);
  border-top-right-radius:ms(2);
  height: 80%;
  object-fit: cover;
  object-position: center left;
  display: block;
}

.img90tit10{
  height: 220px;//TODO: Voir si cette valeur pourrait etre defini ailleurs
}

.img90tit10_titre{
  text-align:center; 
  margin: 1rem;
  @include ms-respond(font-size, 2);
  color: #303030;
}

.img90tit10_content_container{
  margin:-2px;
}

.img90tit10_text_content{
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.img90tit10 a {
  text-decoration: none;
  color: #2c3e50;
}

body.theme-bg-bingo div .img90tit10_titre{
    line-height: 1em;
}

</style>