var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "itemsContainer--uoZQl",
      staticStyle: { "padding-bottom": "0%" },
    },
    [
      _c(
        "div",
        {
          staticClass: "itemsRow--2Nmj9 skipRowsTransition--QL5CX",
          staticStyle: {
            width: "100%",
            "padding-bottom": "9.45455%",
            "padding-left": "0%",
            "padding-right": "0%",
            left: "0%",
          },
        },
        _vm._l(_vm.results.slice(0, 8), function (result, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "itemContainer--1x-Md noTransition--1UEsy",
              style:
                "width: 11.625%; padding-bottom: 8.45455%; transform: translateX(" +
                _vm.getTranslateX(index) +
                "%);",
            },
            [
              ["ca", "pa", "bo"].includes(result)
                ? _c("img", {
                    attrs: {
                      width:
                        result == "ca"
                          ? "75.75757575757575%"
                          : "72.72727272727273%",
                      height: result == "ca" ? "102.08333333333333%" : "100%",
                      src: "data:image/svg+xml;base64," + _vm.getImage(result),
                    },
                  })
                : ["fl_r", "fl_b"].includes(result)
                ? _c("div", { staticClass: "itemScale--3KxD1" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "69.6969696969697%",
                          height: "95.83333333333334%",
                          viewBox: "0 0 48 48",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c(
                          "defs",
                          [
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "50%",
                                  y1: "101.9568%",
                                  x2: "49.9959%",
                                  y2: "0%",
                                  id: "coinFlipIcon-" + result + "-history-a",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#C3781B",
                                    offset: "0%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#EBBB31",
                                    offset: "19.332%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#F9E382",
                                    offset: "49.351%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#EBBB31",
                                    offset: "76.111%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#C3781B",
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "49.9999%",
                                  y1: "100.0001%",
                                  x2: "49.9999%",
                                  y2: "0%",
                                  id: "coinFlipIcon-" + result + "-history-b",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFF7C0",
                                    offset: "0%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFC034",
                                    offset: "49.027%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFF0C7",
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "49.9543%",
                                  y1: "99.9912%",
                                  x2: "49.9543%",
                                  y2: "-.014%",
                                  id: "coinFlipIcon-" + result + "-history-c",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFF7C0",
                                    offset: "0%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFC034",
                                    offset: "49.027%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFF0C7",
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "41.2707%",
                                  y1: ".7392%",
                                  x2: "58.6379%",
                                  y2: "99.238%",
                                  id: "coinFlipIcon-" + result + "-history-d",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFF0C7",
                                    offset: "0%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#F3C936",
                                    offset: "50.973%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#FFFDCF",
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "49.9543%",
                                  y1: "99.9912%",
                                  x2: "49.9543%",
                                  y2: "-.0141%",
                                  id: "coinFlipIcon-" + result + "-history-e",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#D08D06",
                                    offset: "0%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#924822",
                                    offset: "49.174%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color": "#D08D06",
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "91.9536%",
                                  y1: "27.6295%",
                                  x2: "14.5628%",
                                  y2: "68.9056%",
                                  id: "coinFlipIcon-" + result + "-history-f",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      result == "fl_b" ? "#1C85EC" : "#EC1C31",
                                    offset: "20.033%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      result == "fl_b" ? "#0533B1" : "#B10524",
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "39.5571%",
                                  y1: "37.6575%",
                                  x2: "58.0689%",
                                  y2: "67.5623%",
                                  id: "coinFlipIcon-" + result + "-history-g",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      result == "fl_b" ? "#3C6CFC" : "#EC3749",
                                    "stop-opacity": "0",
                                    offset: "0%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      result == "fl_b" ? "#3C6CFC" : "#EB3648",
                                    "stop-opacity": "0.0732",
                                    offset: "22.505%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      result == "fl_b" ? "#3C6CFC" : "#E93346",
                                    "stop-opacity": "0.2642",
                                    offset: "39.673%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      result == "fl_b" ? "#3C6CFC" : "#E62E42",
                                    "stop-opacity": "0.5693",
                                    offset: "63.41%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      result == "fl_b" ? "#307CFB" : "#F33045",
                                    "stop-opacity": "0.8",
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "coinFlipIcon-" + result + "-history-Fill",
                              fill: "none",
                              "fill-rule": "evenodd",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M29.4202 1.0824C16.763-1.8906 4.0915 5.9562 1.112 18.6117-1.8673 31.2673 5.973 43.943 18.627 46.9287c12.654 2.986 25.3337-4.8478 28.326-17.5003 2.986-12.6691-4.8638-25.36-17.5328-28.346z",
                                fill:
                                  "url(#coinFlipIcon-" + result + "-history-a)",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M29.5226.6496C16.6265-2.4004 3.6996 5.5814.6496 18.4775c-3.05 12.896 4.9319 25.8228 17.828 28.8729 12.896 3.05 25.8228-4.9318 28.8729-17.8279 3.0499-12.896-4.932-25.8228-17.828-28.873zm17.2396 28.7337c-2.164 9.2129-9.6662 16.214-19.0066 17.7368-9.3403 1.5228-18.6779-2.7328-23.656-10.7812-4.9783-8.0485-4.616-18.3037.9177-25.9809C10.551 2.6808 20.1656-.9053 29.3752 1.2728a23.372 23.372 0 0117.387 28.1105z",
                                fill:
                                  "url(#coinFlipIcon-" + result + "-history-b)",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M28.5986 4.556C17.86 2.0275 7.1044 8.681 4.5723 19.4189c-2.532 10.7378 4.118 21.4956 14.855 24.0312 10.737 2.5356 21.497-4.1109 24.0361-14.847C45.999 17.858 39.3439 7.0916 28.5986 4.556zm14.535 23.9691C40.6247 39.0874 30.03 45.6173 19.467 43.112 8.904 40.6065 2.3705 30.014 4.8725 19.4501 7.3745 8.886 17.9649 2.3493 28.5296 4.8479 39.1006 7.3535 45.639 17.954 43.1336 28.525z",
                                fill:
                                  "url(#coinFlipIcon-" + result + "-history-c)",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M28.483 5.0449C18.0144 2.5799 7.5292 9.0662 5.0608 19.534 2.5924 30.002 9.0752 40.4892 19.5422 42.961c10.467 2.4719 20.9566-4.0074 23.4318-14.4736 2.472-10.475-4.016-20.9706-14.491-23.4425zm12.5768 22.9897c-2.237 9.4175-11.6833 15.2397-21.1015 13.0058C10.5402 38.8066 4.715 29.3621 6.9457 19.9432 9.1765 10.5243 18.619 4.696 28.0387 6.9237c9.4252 2.234 15.2549 11.6857 13.021 21.111z",
                                fill:
                                  "url(#coinFlipIcon-" + result + "-history-d)",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M28.5198 4.8895C17.9653 2.4044 7.394 8.9438 4.9055 19.4975 2.417 30.051 8.953 40.6244 19.5057 43.1164c10.5528 2.492 21.1283-4.0403 23.6239-14.5923 2.4921-10.561-4.0489-21.1426-14.6098-23.6347zm14.2856 23.558c-2.4658 10.3811-12.8787 16.799-23.2606 14.3366-10.382-2.4624-16.8033-12.8733-14.3442-23.256C7.6596 9.1454 18.0684 2.7207 28.4519 5.1764 38.8416 7.639 45.268 18.0578 42.8054 28.4475z",
                                fill:
                                  "url(#coinFlipIcon-" + result + "-history-e)",
                              },
                            }),
                            _vm._v(" "),
                            _c("ellipse", {
                              attrs: {
                                fill:
                                  "url(#coinFlipIcon-" + result + "-history-f)",
                                "fill-rule": "nonzero",
                                transform: "rotate(-76.627 24.0002 24)",
                                cx: "24.0001",
                                cy: "24.0001",
                                rx: "17.5544",
                                ry: "17.5298",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M36.3266 11.5083c-5.337-5.2549-13.4211-6.543-20.127-3.207-6.7059 3.336-10.555 10.5606-9.5834 17.9872 12.1273 2.5176 24.4028-3.5892 29.7104-14.7802z",
                                fill:
                                  "url(#coinFlipIcon-" + result + "-history-g)",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M28.0404 6.9169c-6.9093-1.6444-14.1288 1.0366-18.29 6.7923C5.589 19.4648 5.306 27.1607 9.033 33.2065c3.7272 6.0458 10.7301 9.25 17.7416 8.1177 7.0115-1.1322 12.65-6.3779 14.2846-13.2895A17.5421 17.5421 0 0028.0404 6.9169zM40.368 28.2937c-2.1265 8.935-11.0915 14.456-20.0274 12.3337-8.936-2.1222-14.4612-11.0847-12.3431-20.0216 2.118-8.9369 11.078-14.4664 20.0158-12.3525a16.647 16.647 0 0112.3547 20.0404z",
                                fill: "#0A0F8E",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ])
                : result == "fl"
                ? _c("div", { staticClass: "itemScale--3KxD1" }, [
                    _c("div", { staticClass: "container--3vrCq" }, [
                      _c("div", { staticClass: "innerContainer--3I5ER" }, [
                        _c("div", { staticClass: "coin--1JNQ1" }, [
                          _c("div", { staticClass: "front--1KBn9" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "69.6969696969697%",
                                  height: "95.83333333333334%",
                                  viewBox: "0 0 48 48",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c(
                                  "defs",
                                  [
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "50%",
                                          y1: "101.9568%",
                                          x2: "49.9959%",
                                          y2: "0%",
                                          id: "coinFlipIcon-history-front-a",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#C3781B",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#EBBB31",
                                            offset: "19.332%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#F9E382",
                                            offset: "49.351%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#EBBB31",
                                            offset: "76.111%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#C3781B",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "49.9999%",
                                          y1: "100.0001%",
                                          x2: "49.9999%",
                                          y2: "0%",
                                          id: "coinFlipIcon-history-front-b",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF7C0",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFC034",
                                            offset: "49.027%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF0C7",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "49.9543%",
                                          y1: "99.9912%",
                                          x2: "49.9543%",
                                          y2: "-.014%",
                                          id: "coinFlipIcon-history-front-c",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF7C0",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFC034",
                                            offset: "49.027%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF0C7",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "41.2707%",
                                          y1: ".7392%",
                                          x2: "58.6379%",
                                          y2: "99.238%",
                                          id: "coinFlipIcon-history-front-d",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF0C7",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#F3C936",
                                            offset: "50.973%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFFDCF",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "49.9543%",
                                          y1: "99.9912%",
                                          x2: "49.9543%",
                                          y2: "-.0141%",
                                          id: "coinFlipIcon-history-front-e",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#D08D06",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#924822",
                                            offset: "49.174%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#D08D06",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "91.9536%",
                                          y1: "27.6295%",
                                          x2: "14.5628%",
                                          y2: "68.9056%",
                                          id: "coinFlipIcon-history-front-f",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#EC1C31",
                                            offset: "20.033%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#B10524",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "39.5571%",
                                          y1: "37.6575%",
                                          x2: "58.0689%",
                                          y2: "67.5623%",
                                          id: "coinFlipIcon-history-front-g",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#EC3749",
                                            "stop-opacity": "0",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#EB3648",
                                            "stop-opacity": "0.0732",
                                            offset: "22.505%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#E93346",
                                            "stop-opacity": "0.2642",
                                            offset: "39.673%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#E62E42",
                                            "stop-opacity": "0.5693",
                                            offset: "63.41%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#F33045",
                                            "stop-opacity": "0.8",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "coinFlipIcon-history-front-Fill",
                                      fill: "none",
                                      "fill-rule": "evenodd",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M29.4202 1.0824C16.763-1.8906 4.0915 5.9562 1.112 18.6117-1.8673 31.2673 5.973 43.943 18.627 46.9287c12.654 2.986 25.3337-4.8478 28.326-17.5003 2.986-12.6691-4.8638-25.36-17.5328-28.346z",
                                        fill: "url(#coinFlipIcon-history-front-a)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M29.5226.6496C16.6265-2.4004 3.6996 5.5814.6496 18.4775c-3.05 12.896 4.9319 25.8228 17.828 28.8729 12.896 3.05 25.8228-4.9318 28.8729-17.8279 3.0499-12.896-4.932-25.8228-17.828-28.873zm17.2396 28.7337c-2.164 9.2129-9.6662 16.214-19.0066 17.7368-9.3403 1.5228-18.6779-2.7328-23.656-10.7812-4.9783-8.0485-4.616-18.3037.9177-25.9809C10.551 2.6808 20.1656-.9053 29.3752 1.2728a23.372 23.372 0 0117.387 28.1105z",
                                        fill: "url(#coinFlipIcon-history-front-b)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.5986 4.556C17.86 2.0275 7.1044 8.681 4.5723 19.4189c-2.532 10.7378 4.118 21.4956 14.855 24.0312 10.737 2.5356 21.497-4.1109 24.0361-14.847C45.999 17.858 39.3439 7.0916 28.5986 4.556zm14.535 23.9691C40.6247 39.0874 30.03 45.6173 19.467 43.112 8.904 40.6065 2.3705 30.014 4.8725 19.4501 7.3745 8.886 17.9649 2.3493 28.5296 4.8479 39.1006 7.3535 45.639 17.954 43.1336 28.525z",
                                        fill: "url(#coinFlipIcon-history-front-c)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.483 5.0449C18.0144 2.5799 7.5292 9.0662 5.0608 19.534 2.5924 30.002 9.0752 40.4892 19.5422 42.961c10.467 2.4719 20.9566-4.0074 23.4318-14.4736 2.472-10.475-4.016-20.9706-14.491-23.4425zm12.5768 22.9897c-2.237 9.4175-11.6833 15.2397-21.1015 13.0058C10.5402 38.8066 4.715 29.3621 6.9457 19.9432 9.1765 10.5243 18.619 4.696 28.0387 6.9237c9.4252 2.234 15.2549 11.6857 13.021 21.111z",
                                        fill: "url(#coinFlipIcon-history-front-d)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.5198 4.8895C17.9653 2.4044 7.394 8.9438 4.9055 19.4975 2.417 30.051 8.953 40.6244 19.5057 43.1164c10.5528 2.492 21.1283-4.0403 23.6239-14.5923 2.4921-10.561-4.0489-21.1426-14.6098-23.6347zm14.2856 23.558c-2.4658 10.3811-12.8787 16.799-23.2606 14.3366-10.382-2.4624-16.8033-12.8733-14.3442-23.256C7.6596 9.1454 18.0684 2.7207 28.4519 5.1764 38.8416 7.639 45.268 18.0578 42.8054 28.4475z",
                                        fill: "url(#coinFlipIcon-history-front-e)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ellipse", {
                                      attrs: {
                                        fill: "url(#coinFlipIcon-history-front-f)",
                                        "fill-rule": "nonzero",
                                        transform: "rotate(-76.627 24.0002 24)",
                                        cx: "24.0001",
                                        cy: "24.0001",
                                        rx: "17.5544",
                                        ry: "17.5298",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M36.3266 11.5083c-5.337-5.2549-13.4211-6.543-20.127-3.207-6.7059 3.336-10.555 10.5606-9.5834 17.9872 12.1273 2.5176 24.4028-3.5892 29.7104-14.7802z",
                                        fill: "url(#coinFlipIcon-history-front-g)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.0404 6.9169c-6.9093-1.6444-14.1288 1.0366-18.29 6.7923C5.589 19.4648 5.306 27.1607 9.033 33.2065c3.7272 6.0458 10.7301 9.25 17.7416 8.1177 7.0115-1.1322 12.65-6.3779 14.2846-13.2895A17.5421 17.5421 0 0028.0404 6.9169zM40.368 28.2937c-2.1265 8.935-11.0915 14.456-20.0274 12.3337-8.936-2.1222-14.4612-11.0847-12.3431-20.0216 2.118-8.9369 11.078-14.4664 20.0158-12.3525a16.647 16.647 0 0112.3547 20.0404z",
                                        fill: "#8E0A26",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "back--2YB63" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "69.6969696969697%",
                                  height: "95.83333333333334%",
                                  viewBox: "0 0 48 48",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c(
                                  "defs",
                                  [
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "50%",
                                          y1: "101.9568%",
                                          x2: "49.9959%",
                                          y2: "0%",
                                          id: "coinFlipIcon-history-back-a",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#C3781B",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#EBBB31",
                                            offset: "19.332%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#F9E382",
                                            offset: "49.351%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#EBBB31",
                                            offset: "76.111%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#C3781B",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "49.9999%",
                                          y1: "100.0001%",
                                          x2: "49.9999%",
                                          y2: "0%",
                                          id: "coinFlipIcon-history-back-b",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF7C0",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFC034",
                                            offset: "49.027%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF0C7",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "49.9543%",
                                          y1: "99.9912%",
                                          x2: "49.9543%",
                                          y2: "-.014%",
                                          id: "coinFlipIcon-history-back-c",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF7C0",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFC034",
                                            offset: "49.027%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF0C7",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "41.2707%",
                                          y1: ".7392%",
                                          x2: "58.6379%",
                                          y2: "99.238%",
                                          id: "coinFlipIcon-history-back-d",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFF0C7",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#F3C936",
                                            offset: "50.973%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFFDCF",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "49.9543%",
                                          y1: "99.9912%",
                                          x2: "49.9543%",
                                          y2: "-.0141%",
                                          id: "coinFlipIcon-history-back-e",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#D08D06",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#924822",
                                            offset: "49.174%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#D08D06",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "91.9536%",
                                          y1: "27.6295%",
                                          x2: "14.5628%",
                                          y2: "68.9056%",
                                          id: "coinFlipIcon-history-back-f",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#1C85EC",
                                            offset: "20.033%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#0533B1",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          x1: "39.5571%",
                                          y1: "37.6575%",
                                          x2: "58.0689%",
                                          y2: "67.5623%",
                                          id: "coinFlipIcon-history-back-g",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#3C6CFC",
                                            "stop-opacity": "0",
                                            offset: "0%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#3C6CFC",
                                            "stop-opacity": "0.0732",
                                            offset: "22.505%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#3C6CFC",
                                            "stop-opacity": "0.2642",
                                            offset: "39.673%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#3C6CFC",
                                            "stop-opacity": "0.5693",
                                            offset: "63.41%",
                                          },
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#307CFB",
                                            "stop-opacity": "0.8",
                                            offset: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "coinFlipIcon-history-back-Fill",
                                      fill: "none",
                                      "fill-rule": "evenodd",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M29.4202 1.0824C16.763-1.8906 4.0915 5.9562 1.112 18.6117-1.8673 31.2673 5.973 43.943 18.627 46.9287c12.654 2.986 25.3337-4.8478 28.326-17.5003 2.986-12.6691-4.8638-25.36-17.5328-28.346z",
                                        fill: "url(#coinFlipIcon-history-back-a)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M29.5226.6496C16.6265-2.4004 3.6996 5.5814.6496 18.4775c-3.05 12.896 4.9319 25.8228 17.828 28.8729 12.896 3.05 25.8228-4.9318 28.8729-17.8279 3.0499-12.896-4.932-25.8228-17.828-28.873zm17.2396 28.7337c-2.164 9.2129-9.6662 16.214-19.0066 17.7368-9.3403 1.5228-18.6779-2.7328-23.656-10.7812-4.9783-8.0485-4.616-18.3037.9177-25.9809C10.551 2.6808 20.1656-.9053 29.3752 1.2728a23.372 23.372 0 0117.387 28.1105z",
                                        fill: "url(#coinFlipIcon-history-back-b)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.5986 4.556C17.86 2.0275 7.1044 8.681 4.5723 19.4189c-2.532 10.7378 4.118 21.4956 14.855 24.0312 10.737 2.5356 21.497-4.1109 24.0361-14.847C45.999 17.858 39.3439 7.0916 28.5986 4.556zm14.535 23.9691C40.6247 39.0874 30.03 45.6173 19.467 43.112 8.904 40.6065 2.3705 30.014 4.8725 19.4501 7.3745 8.886 17.9649 2.3493 28.5296 4.8479 39.1006 7.3535 45.639 17.954 43.1336 28.525z",
                                        fill: "url(#coinFlipIcon-history-back-c)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.483 5.0449C18.0144 2.5799 7.5292 9.0662 5.0608 19.534 2.5924 30.002 9.0752 40.4892 19.5422 42.961c10.467 2.4719 20.9566-4.0074 23.4318-14.4736 2.472-10.475-4.016-20.9706-14.491-23.4425zm12.5768 22.9897c-2.237 9.4175-11.6833 15.2397-21.1015 13.0058C10.5402 38.8066 4.715 29.3621 6.9457 19.9432 9.1765 10.5243 18.619 4.696 28.0387 6.9237c9.4252 2.234 15.2549 11.6857 13.021 21.111z",
                                        fill: "url(#coinFlipIcon-history-back-d)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.5198 4.8895C17.9653 2.4044 7.394 8.9438 4.9055 19.4975 2.417 30.051 8.953 40.6244 19.5057 43.1164c10.5528 2.492 21.1283-4.0403 23.6239-14.5923 2.4921-10.561-4.0489-21.1426-14.6098-23.6347zm14.2856 23.558c-2.4658 10.3811-12.8787 16.799-23.2606 14.3366-10.382-2.4624-16.8033-12.8733-14.3442-23.256C7.6596 9.1454 18.0684 2.7207 28.4519 5.1764 38.8416 7.639 45.268 18.0578 42.8054 28.4475z",
                                        fill: "url(#coinFlipIcon-history-back-e)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ellipse", {
                                      attrs: {
                                        fill: "url(#coinFlipIcon-history-back-f)",
                                        "fill-rule": "nonzero",
                                        transform: "rotate(-76.627 24.0002 24)",
                                        cx: "24.0001",
                                        cy: "24.0001",
                                        rx: "17.5544",
                                        ry: "17.5298",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M36.3266 11.5083c-5.337-5.2549-13.4211-6.543-20.127-3.207-6.7059 3.336-10.555 10.5606-9.5834 17.9872 12.1273 2.5176 24.4028-3.5892 29.7104-14.7802z",
                                        fill: "url(#coinFlipIcon-history-back-g)",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M28.0404 6.9169c-6.9093-1.6444-14.1288 1.0366-18.29 6.7923C5.589 19.4648 5.306 27.1607 9.033 33.2065c3.7272 6.0458 10.7301 9.25 17.7416 8.1177 7.0115-1.1322 12.65-6.3779 14.2846-13.2895A17.5421 17.5421 0 0028.0404 6.9169zM40.368 28.2937c-2.1265 8.935-11.0915 14.456-20.0274 12.3337-8.936-2.1222-14.4612-11.0847-12.3431-20.0216 2.118-8.9369 11.078-14.4664 20.0158-12.3525a16.647 16.647 0 0112.3547 20.0404z",
                                        fill: "#0A0F8E",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _c("div", { staticClass: "itemScale--3KxD1" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "75.75757575757575%",
                          height: "91.66666666666666%",
                          viewBox: "0 0 52 46",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        },
                      },
                      [
                        _c(
                          "defs",
                          [
                            _c("path", {
                              attrs: {
                                d: "M39.8787 41.587c8.6288-1.1426 9.2154-5.7204 9.6018-7.3204.3284-1.3591 1.9143-16.4272 2.356-19.6471.3995-2.9056 1.215-9.3514-13.5015-10.5507C33.0446 3.6368 29.867 3.0172 26.0306 0c-3.8363 3.0172-7.014 3.6367-12.3044 4.0688C-.9903 5.2681-.2421 11.714.1575 14.6195c.4416 3.2199 2.0553 18.288 2.3837 19.647.3864 1.6 1.0125 6.1779 9.6413 7.3205C23.6431 43.106 25.9428 45.8883 26.0306 46c.0887-.1117 2.3875-2.894 13.848-4.413z",
                                id: "icon-" + result + "-history-a",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "linearGradient",
                              {
                                attrs: {
                                  x1: "50%",
                                  y1: "0%",
                                  x2: "50%",
                                  y2: "100%",
                                  id: "icon-" + result + "-history-b",
                                },
                              },
                              [
                                _c("stop", {
                                  attrs: {
                                    "stop-color": _vm.getCouleur(result),
                                    offset: "0%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      _vm.getCouleurGradiant(result),
                                    offset: "58.1239%",
                                  },
                                }),
                                _vm._v(" "),
                                _c("stop", {
                                  attrs: {
                                    "stop-color":
                                      _vm.getCouleurGradiant(result),
                                    offset: "100%",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "url('#icon-" + result + "-history-b')",
                                d: "M26.0303 41.2935c-3.023-1.574-7.496-2.761-13.324-3.533-5.461-.724-5.911-2.694-6.209-3.996l-.064-.277c-.167-.784-.959-7.698-1.384-11.412l-.033-.283c-.371-3.24-.722-6.3-.897-7.579-.202-1.465-.194-2.29.393-3.023.573-.713 2.65-2.435 9.538-2.997 4.925-.401 8.425-1.059 11.979-3.193 3.554 2.134 7.054 2.792 11.98 3.193 6.855.559 8.916 2.265 9.484 2.972.579.722.583 1.558.379 3.049-.175 1.274-.498 4.123-.937 8.009-.446 3.946-1.186 10.499-1.351 11.273l-.066.282c-.295 1.306-.737 3.263-6.167 3.982-5.826.772-10.298 1.959-13.321 3.533",
                                id: "icon-" + result + "-history-c",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "g",
                          { attrs: { fill: "none", "fill-rule": "evenodd" } },
                          [
                            _c("g", [
                              _c("use", {
                                attrs: {
                                  "fill-opacity": ".65",
                                  fill: "#000000",
                                  "xlink:href":
                                    "#icon-" + result + "-history-a",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  stroke:
                                    "url('#icon-" + result + "-history-b')",
                                  "stroke-width": "2",
                                  d: "M26.0311 1.2571c-3.7359 2.7482-6.9803 3.3801-12.2235 3.8084-5.4309.4426-8.6696 1.593-10.5496 3.0548-2.7147 2.1109-2.3212 4.826-2.1099 6.363.1745 1.2718.5313 4.3855.9197 7.7768.5904 5.1547 1.2494 10.9602 1.506 12.0325.2183.9554.6017 2.7215 2.5473 4.1571 1.2972.9573 3.2495 1.7562 6.1927 2.146 8.677 1.15 12.3149 3.045 13.7161 4.0728 1.3982-1.0256 5.0341-2.922 13.7174-4.0728 2.9354-.3888 4.8801-1.1843 6.1705-2.1384 1.9451-1.438 2.3149-3.2126 2.5907-4.4256.1949-.8066.8308-6.4942 1.4082-11.6036.3905-3.4562.7518-6.652.9291-7.9445.2114-1.5377.6313-4.256-2.0715-6.3682-1.8682-1.4599-5.0959-2.6078-10.5205-3.0499-5.243-.4282-8.4874-1.0601-12.2227-3.8084z",
                                  "stroke-linecap": "square",
                                  "fill-opacity": ".25",
                                  fill: _vm.getCouleur(result),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            index == 0
                              ? _c("g", [
                                  _c("use", {
                                    attrs: {
                                      "fill-opacity": ".5",
                                      fill: "#000",
                                      "xlink:href":
                                        "#icon-" + result + "-history-c",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "#icon-" + result + "-history-c",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "text",
                              {
                                attrs: {
                                  "font-family": "Inter",
                                  "font-size": "25",
                                  "font-weight": "400",
                                  fill:
                                    index == 0
                                      ? "#4A4A4A"
                                      : _vm.getCouleur(result),
                                },
                              },
                              [
                                _c(
                                  "tspan",
                                  {
                                    attrs: {
                                      x: "50%",
                                      y: "55%",
                                      "text-anchor": "middle",
                                      "dominant-baseline": "middle",
                                    },
                                  },
                                  [_vm._v(_vm._s(result))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }