<template>

  <section :data-component-name="$options.name"
    class="row section-sansTheme section-page be_section"
    :class="[contenu.sectionCoinRondTop?'be_coin_rond':'']"
  >
    <hr class="barre_entete">
    <img v-if="contenu.image" v-lazy="getImageSrc(getImageByScreenSize(contenu))" class="flex-shrink-0" alt="" />
    <h1 class="be_titre graphik_bold"
        :style="{'color':contenu.titleColor}">
      {{contenu.title}}
    </h1>
    <p v-if="contenu.title2" class="be_sous_titre">
      {{contenu.title2}}
    </p>

  </section>

</template> 

<script>
  import {WebServicesAccess} from '../../config';
 
  export default {
    name:"BlocEntete",
    props: ["contenu"],
  }
</script>

<style lang="scss" scoped>
.contenu {
  background-position: center;
  background-repeat: no-repeat;
}
.contenu.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.barre_entete{
  border: 1rem solid rgb(13, 203, 143);
  width: 100%;
  margin: 0;
}

.be_section{
  overflow-x: hidden;
  padding-left: 0px;
}
.be_titre {
  position: absolute;
  top: 10%;
  left: 20%;
  @include ms-respond(font-size, 4);
  //line-height: 1;
}

.be_sous_titre {
  position: absolute;
  top: 45%;
  @include ms-respond(font-size, 1);
  margin-left: 25%;
  color: #808080;
}
.be_coin_rond{
  border-top-left-radius: ms(3); 
  border-top-right-radius: ms(3);
}

@include media-breakpoint-only(md) {

.be_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
  }

  .be_sous_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
  }


}


@media screen and (max-width: 765px) {
  .be_section{
    margin-bottom:2rem;
  }

  .be_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
  }

  .be_sous_titre {
    text-align: center;
    margin-left: 0;
    width: 100%;
    left: 0;
    top: 60%;
  }

  .barre_entete{
    border-width: 0.5rem ;
    width: 100%;
    margin: 0;
  }
}
</style>