<template>
    <div v-if="seats > seatsTaken.length" class="BlackJackSeats--1ClkO">
        <div class="BlackJackSeatWrap--30yC6" data-role="bj-direct-seat"
			v-bind:seat="seat"
			v-bind:key="index"
            v-for="(seat, index) in seats">
            <div class="BlackJackSeat--i2NtA">
                <svg hover="{color: 'white', backgroundColor:'green'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                    class="BlackJackSeatIcon--1xNAH" :class="seatsTaken.includes(seat-1) ? 'occupied--3_9li' : 'free--3woOZ'">
                    <use href="#playersOnlineIcon">
                        <g id="playersOnlineIcon">
                        <circle cx="16" cy="7.2" r="5.2"></circle>
                        <path
                            d="M19.3 13.5c-2.1.9-4.6.9-6.7 0-4.8 1.9-7.8 5.6-7.8 11.5 0 1.1.1 1.7.3 2.7.4 0 .8.1 1.2.2.3.1.7.2 1 .2.4-7.2 1.4-7.7 1.5-7.7l.5-.7.7 9c.6.1 1.2.3 1.8.5 1 .4 2.3.8 4.1.8 1.8 0 3-.4 4.1-.8.7-.2 1.2-.5 1.9-.5l.7-9 .5.7c0 .1 1.1.5 1.5 7.7.3 0 .7-.1 1-.2.4-.1.8-.2 1.2-.2.2-1 .3-1.6.3-2.7.1-5.9-2.9-9.6-7.8-11.5z"></path>
                        </g>
                    </use>
                </svg>
            </div>
        </div>
    </div>
    <div v-else class="TableFull--PcX1W" data-role="table-full">
        <div class="TableFullTextWrap--1FglZ">
            <div class="tableComplete" :class="isTablePremiereOuverte ? 'tableCompleteAccueil' : ''">
                <span v-if="betBehind" class="BetBehindText--I86eo betBehindText" data-role="table-bet-behind-text">{{ $t('ced.miseArriere') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {

	data: function () {
		return {
			isJeuLoaded: false,
		}
	},

	props: {
		seats: {
			type: Number,
			required: true 
		},
		betBehind: {
			type: Boolean,
			required: true
		},
		seatsTaken: {
			type: Array,
			required: false // Parfois aucun siège
		},
		isTablePremiereOuverte: {
			type: Boolean,
			required: true,
			default: false
		},
		appareil: {
			type: String,
			required: true
		},
		prefixeUrl: {
			type: String,
			required: true
		},
	},
}
</script>

<style lang="scss">
	.lobby-table-block-cnt {
	svg use {
		fill: unset;
	}
	}

	.tableComplete {
		font-size: calc(11px + 0.5vw);
		font-family:$font-graphik-regular;
		font-weight:600;
		padding-bottom: 0.5vw;

		@media (min-width: 640px) {
			font-size: 2vw;
			padding-bottom: 0;
		}
		@include media-breakpoint-up(md) {
			font-size: 1.8vw;
			padding-bottom: 0;
		}
		@media (min-width: 991px) {
			font-size: 1.2vw;
			padding-bottom: 0;
		}
		@include media-breakpoint-up(xl) {
			font-size: 15px;
			padding-bottom: 0;
		}

		&.tableCompleteAccueil {
			font-size: calc(11px + 0.3vw);
			padding-bottom: 0;

			@include media-breakpoint-up(md) {
				font-size: 1.5vw;
				padding-bottom: 1.3vw;
			}
			@include media-breakpoint-up(xl) {
				font-size: 18px;
				padding-bottom: 15px;
			}
		}

		.TableFullText--1IsTW {
			color: #fff;
		}
	}

	.BetBehindText--I86eo.betBehindText {
		color:  #00A389;
		font-family:$font-graphik-regular;
		font-size:13px;
		font-weight:medium;
		line-height:17px;
	}
	.col-2	.BetBehindText--I86eo.betBehindText {
		@include media-breakpoint-up(xl) {
			font-size:11px;
		}
	}
	.desktop .col-3 .BlackJackSeat--i2NtA {
		height: 2em !important;
	}

</style>