window.ajouterElementFilAriane = function (texte, url, id) {
  if (typeof EspacejeuxPortail_FilAriane !== "undefined") {
    EspacejeuxPortail_FilAriane.ajouterElementFilAriane(texte, url, id);
  }
};

window.afficherFilAriane = function () {
  if (typeof EspacejeuxPortail_FilAriane !== "undefined") {
    EspacejeuxPortail_FilAriane.afficherFilAriane();
  }
};

window.changerLangue = function () {
  if (typeof EspacejeuxPortail_Langue !== "undefined") {
    return EspacejeuxPortail_Langue.changerLangue();
  }
};

window.viderChamp = function (obj, valeurDefaut) {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.viderChamp(obj, valeurDefaut);
  }
};

window.garderValeurChamp = function (obj, valeurDefaut) {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.garderValeurChamp(obj, valeurDefaut);
  }
};

window.garderValeurChampPassword = function (obj, idChampTexte) {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.garderValeurChampPassword(obj, idChampTexte);
  }
};

window.partagerPage = function () {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.partagerPage();
  }
};

window.ouvrirJeuResponsable = function () {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.ouvrirJeuResponsable();
  }
};

//pour fin de compatibilité
window.notifMajInfoSession = function () {
  ejConnect.onSessionDetailsUpdate();
};

//pour fin de compatibilité
window.updateInfoSession = function () {
  ejConnect.onSessionDetailsUpdate();
};

window.updateInfoClient = function () {
  ejConnect.notificationPage(
    ejConnect.onSessionDetailsUpdate,
    ejConnect.onSessionDetailsUpdate,
    ejConnect.apresLoginLogout
  );
};

window.addConnectionWCookie = function () {
  ejConnect.addConnectionWCookie();
};

window.deleteConnectionWCookie = function () {
  ejConnect.deleteConnectionWCookie();
};

window.fillerSousMenuGauche = function () {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.fillerSousMenuGauche();
  }
};

window.ouvrirChargement = function () {
  //ejConnect.ouvrirChargement();
  return true;
};

window.fermerChargement = function () {
  //EspacejeuxPortail.fermerChargement();
  return true;
};

window.fermerShadowBoxFlash = function () {
  if (typeof EspacejeuxPortail_Dialogue !== "undefined") {
    EspacejeuxPortail_Dialogue.fermer();
  }
};

window.enregistrerVisiteGoogleAnalytics = function (urlVirtuelGoogleAnalytics) {
  $(document).ready(function () {
    if (typeof EspacejeuxPortail_GoogleAnalytics !== "undefined") {
      EspacejeuxPortail_GoogleAnalytics.enregistrerVisite(urlVirtuelGoogleAnalytics);
    }
  });
};

window.Espacejeux_GetFlashVersionForGame = function (gameName) {
  if (typeof EspacejeuxPortail_Flash !== "undefined") {
    return EspacejeuxPortail_Flash.getFlashVersionForGame(gameName);
  }
};

window.Espacejeux_AfficherMessageErreurFlash = function (ver) {
  if (typeof EspacejeuxPortail_Flash !== "undefined") {
    EspacejeuxPortail_Flash.afficherMessageErreurFlash(ver);
  }
};

//Modale ouverture de login
window.Espacejeux_OuvrirLogin = function () {
  // Si la connexion Auth0 est active
  if (document.querySelector("#btn-ciam-auth0")) {
    // eslint-disable-next-line no-undef
    lancerConnexionAuth0(ejApi.lireLangue(), window.location.href);
  } else if (typeof ejConnect !== "undefined") {
    // eslint-disable-next-line no-undef
    ejConnect.ouvrirModaleLogin();
  }
};

//Modale fermeture de login
window.Espacejeux_fermerLogin = function () {
  // Si la connexion Auth0 est inactive
  if (typeof ejConnect !== "undefined") {
    // eslint-disable-next-line no-undef
    ejConnect.fermerModaleLogin();
  }
};

window.Espacejeux_ouvrirChargement = function () {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.ouvrirChargement();
  }
};

window.Espacejeux_fermerChargement = function () {
  if (typeof EspacejeuxPortail !== "undefined") {
    EspacejeuxPortail.fermerChargement();
  }
};

window.afficherMessageServeurIndisponible = function () {
  ejConnect.afficherMessageServeurIndisponible();
};

window.onLogout = function () {
  //ejConnect.onLogoutProcessCompleted();
};

window.afficherErreurPortail = function (msg) {
  if (typeof ejConnect !== "undefined") {
    ejConnect.afficherErreurPortail(msg, false);
  }
};

window.masquerErreurPortail = function () {
  if (typeof ejConnect !== "undefined") {
    ejConnect.masquerErreurPortail();
  }
};
