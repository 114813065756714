import Vue from 'vue';
import { i18n, mixinLangue } from '../../mixins/mixinLangue';
import { urlParamsValues, protectedPage } from '../../mixins/mixin-utils';
import Loading from '../../components/loading/Loading.vue';
import Cookies from '../../../js/components/cookies';

import 'whatwg-fetch';

document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById("vueAssignationTransitionApp")) {
        new Vue({
			el: '#vueAssignationTransitionApp',
			i18n,
			mixins: [mixinLangue, urlParamsValues, protectedPage, Cookies],
            components: {
				Loading
			},

			data() {
				return {
					defaulturiToRedirect: null,
				};
			},

			created() {					
                this.defaulturiToRedirect = 						
					(this.mixinLangue_langue == "en" ? "/en/my-account/withdrawals":"/fr/mon-compte/retraits") + "?_msg=MSG05-1-20";					
				this.isLoading = true;
			},

			mounted: function() {
				const vm = this;						
				let payStatus = this.getUrlParameter('status');
				let paySessionId = this.getUrlParameter('sessionId');	
				
				//La redirection 302 de Paysafe après la selection du compte ce fait ici
				if(payStatus && paySessionId && payStatus === 'SUCCESS'){						
						let myheaders = new Headers();
						myheaders.append('pragma', 'no-cache');
						myheaders.append('cache-control', 'no-cache');
					    myheaders.append('JEL-XTOKEN', Cookies.get('Jel-csrf'));
						let ajaxData = new FormData();
						ajaxData.append('sessionId', paySessionId);
						ajaxData.append('paybId', Cookies.get('_paybId'));
						ajaxData.append('status', payStatus);
						ajaxData.append('lang', vm.mixinLangue_langue);
						fetch( '/services/assignation', { 
							method: 'POST',
							headers: myheaders,
							body: ajaxData,
						}).then(function( response ){
							return response.json();
						}).then(function( data ){		
                            vm.isLoading = false;						
                            if (data && data.status === 'SUCCESS') {						
								//Rediriger vers la page de Retraits-avec-compte								
								window.location.href = data.urlToRedirect+"?_"+Date.now();
							}
							else {						
								window.location.href = vm.defaulturiToRedirect;							
							}													

						}).catch(error => {
							vm.isLoading = false;
                            console.log( error );
                            window.location.href = vm.defaulturiToRedirect;						
						});	
					
				}
				else {
                   vm.isLoading = false;
                   window.location.href = vm.defaulturiToRedirect;	
				}
				
			}
		})
    }
});