<template>
  <div id="entete" class="container">

    <div id="nav" class="row">

    <div class="d-lg-flex justify-content-end w-100 d-none pt-2">
      <ul class="d-flex">
        <li class="mr-4 d-flex align-items-center">
          <div class="rounded-circle bingo_cercle mr-1"></div>
          <a :href="$t('messageComponent.promotionsURL')">{{$t('messageComponent.promotions')}}</a>
        </li>
        <li class="mr-4 d-flex align-items-center">
          <div class="rounded-circle bingo_cercle mr-1"></div>
          <a :href="$t('messageComponent.gagnantsURL')">{{$t('messageComponent.gagnants')}}</a>
        </li>
        <li class="d-flex align-items-center">
          <div class="rounded-circle bingo_cercle mr-1"></div>
          <a :href="$t('messageComponent.jeuResponsableURL')">{{$t('messageComponent.jeuResponsable')}}</a>
        </li>
      </ul>
    </div>  

      <br>

      <b-navbar toggleable="lg" class="navbar_overlay w-100 px-0">
        <b-navbar-brand :href="accueilURL" class="ml-3">
          <img :src="getImageSrcVariation('/dam/espacejeux/bingo/logo/bingo.svg')" class="d-inline-block align-top nav_bingo_icon" alt="Logo - Bingo">
          </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" class="float-right text-right toggler_icon" :class="{'d-none' : afficherMenu}">          
        </b-navbar-toggle>
        <div id="navbar-close" :class="!afficherMenu ? 'd-none' : 'pr-1'" @click="afficherMenu = !afficherMenu">
          <img :src="getImageSrcVariation('/dam/espacejeux/bingo/icones/icon_closeLobby.svg')" class="close_menu_icon" alt="close-menu">
        </div>

        <b-collapse id="nav-collapse" v-model="afficherMenu"  class="navbar_content" is-nav >
          <b-navbar-nav class="w-100 navbar_content_titles">
            <div class="btn-group route_btn graphik_semibold px-1" v-for="(routes, index) in actualRoutes" v-bind:key="index">
              
             
              <b-nav-item-dropdown v-if="routes.length > 1" :text="routes[0].parent" size="sm" variant="primary" class="w-100 d-lg-flex align-items-center justify-content-lg-center"
                  :toggle-class="[getParentActive(routes), 'entete_btn']"
                  :ref="'drop-' + routes[0].parent" @mouseover.native="() => showDropdown('drop-' + routes[0].parent, $mq, true)" 
                   @mouseleave.native="() => showDropdown('drop-' + routes[0].parent, $mq, false)"                   
                   >
                <b-dropdown-item v-for="route in routes" v-bind:key="route.name"  :to="route.path" class="dropdown-item" >
                  {{route.props.menuTitle}}
                </b-dropdown-item>            
              </b-nav-item-dropdown>
              
              <b-nav-item v-else class="d-flex align-items-center justify-content-lg-center">
                  <router-link 
                          :to="routes[0].path" >
                  {{routes[0].props.menuTitle}}
                </router-link>
              </b-nav-item>
              
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

  </div>
</template>

<script>
import {WebServicesAccess} from '../../config';
import { store } from '../../store/store';
import { mapState } from 'vuex'

export default {
  name: 'Entete',
  data () {
    return {
      afficherMenu: false,
      currentRoute: this.$router.currentRoute.fullPath
    }
  },
  i18n: {
    messages: {
      fr: {
        messageComponent: {
          accueilURL: "/fr/bingo/accueil",
          promotions: "Promotions",
          promotionsURL: "/fr/casino/promotion",
          gagnants: "Gagnants",
          gagnantsURL: "/fr/gagnants",
          jeuResponsable: "Jeu responsable",
          jeuResponsableURL: "/fr/evenements/jouez-sans-depasser",
        }
      },
      en: {
        messageComponent: {
          accueilURL: "/en/bingo/home",
          promotions: "Promotions",
          promotionsURL: "/en/casino/promotion",
          gagnants: "Winners",
          gagnantsURL: "/en/winners",
          jeuResponsable: "Responsible Gambling",
          jeuResponsableURL: "/en/events/game-without-going-overboard",
        }
      }
    }
  },
  watch:{
    $route (to, from){
        this.afficherMenu = true;
        this.currentRoute = this.$router.currentRoute.fullPath; // Nécessaire pour ajouter la classe active au parent
    }
  },
  created: function() {
    this.$store.dispatch("routes/initRoutes");
  },
  computed: {
    ...mapState('routes', ['routes']),
    //...mapGetters("routes", ["getRoutes"]),
    actualRoutes: function() {
      var parsedRoutes = this.groupRoutes(this.routes);
      return parsedRoutes;
    },
    accueilURL: function() {
      //TODO: Généraliser, pas uniquement pour bingo + prévoir url traduit. Idée: Doit provenir de Magnolia?
      //	  Prévoir URL auteur et public
      var noeud = (this.currentRoute.indexOf('bingoV2') == -1) ? 'bingo' : 'bingoV2';

      return this.$t('messageComponent.accueilURL').replace("bingo", noeud);
    },
  },
  methods: {    
    groupRoutes: function(routes){
      let groupedRoutes = {};

      Object.keys(routes).forEach(function(key,index) {
          var route = routes[key];
          var dictKey = route.name;
          if(route.afficherDansMenu){
            if(route.parent != ""){
            dictKey = route.parent;
            }
            if(!groupedRoutes[dictKey]){
              groupedRoutes[dictKey] = [];
            }
            groupedRoutes[dictKey].push(route);
          }          
        });
      return groupedRoutes;
      },
      showDropdown(ref, mq, isVisible){  
        if(isVisible){
          this.$refs[ref][0].show();
        }else{
          this.$refs[ref][0].hide();
        }        
      },
      getParentActive: function(routes){
        var parentClass = '';

        if(routes !=null && Array.isArray(routes)){
          routes.forEach(route => {
            if(route.path === this.currentRoute){            
              parentClass = 'router-link-exact-active';
            }
          });
        }
        return parentClass;
      },  
  },
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css?family=Graduate&display=swap');

  #entete {    

    ::v-deep .entete_btn{
      margin-top: 0 !important;
    }
  }

  #nav {
    @include ms-respond(font-size, -1);    
    text-transform: uppercase;
    padding: 0;


    a, ::v-deep a.nav-link {
      font-weight: bold;
      color: #2c3e50;
      text-decoration: none;

      &.router-link-exact-active {
        color: #06845c;
      }
      :hover{
        color: #06845c;
      }
    }

  }

  .bingo_cercle{
    height: 1.5rem;
    width: 1.5rem;
    background-color: #004687;
  }

  .nav_bingo_icon{
    @include ms-respond(height, 6);
  }

  .nav-link{
    text-align: center;
  }

  .navbar_content{
    background-color: white;
  }

  .navbar_content_titles{
    justify-content: flex-start;
  }

  .toggler_icon{
    border: none;
  }

  ::v-deep .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    width: 2.5em;
    height: 2.5em; 
    border: none;
  }

  ::v-deep .dropdown-menu{
    background-color: white;

    li{
      a {
        color: #2c3e50;;
        text-decoration: none;

        &.router-link-exact-active {
          color: #06845c;
        }
      }
    }
  }

  ::v-deep .dropdown-item{
    @include ms-respond(font-size, -1);
    padding-left: 0.5rem;
    text-transform: uppercase;
    white-space: normal;
  }

  ::v-deep .dropdown-item:active{
    background-color: unset;
  }

  ::v-deep .dropdown-item a:hover, .nav-item :hover{
    color: #06845c !important;
  }

@include media-breakpoint-up(lg){

  .navbar_content_titles{
    justify-content: space-evenly;
  }


  .route_btn{
    z-index: 98;

  }

  ::v-deep ul.dropdown-menu{
    position: absolute;
    top: 0;
    width: 110%;
    margin-left: -5%;
    margin-top: -5%;
    padding-top: 6rem;
    z-index: -1;
    border-width: 2px;
  }
}
 
@include media-breakpoint-down(md){
  $border-color: #d9d9d9;

  .route_btn{
      border-bottom: 1px solid $border-color;
      padding: 1rem 0;
      margin: 1rem 1rem;
      @include ms-respond(font-size, 1);
  }

  .route_btn:last-child{
      border-bottom: none;
  }

  .navbar_content{
    border: 3px solid $border-color;
    padding: 0 0.5rem;
  }

  .close_menu_icon{
    height: 3rem;
    filter: brightness(0);
  }

  .dropdown-menu li{
    border-bottom: 1px solid $border-color;
  }

  .dropdown-menu li:last-child{
    border-bottom: none;
  }

  ::v-deep ul.dropdown-menu{
    border: none;
    padding: 0 1rem;
    border-top: 2px solid #d9d9d9;
  }

  ::v-deep .dropdown-item{    
    padding: 1rem 0;
    @include ms-respond(font-size, 1);
  }
} 

  .navbar-dark {
    .navbar-nav {
      .nav-link {
        font-weight: bold;
        color: rgba(230, 230, 230, 1);
        margin-left: 10px;
        padding: 25px 0px 25px 0px !important;
        &.router-link-exact-active {
          color: rgba(230, 230, 230, 1);
          border-bottom: 3px solid #FF0000;
        }
      }
      .nav-link:hover {
        color: rgba(230, 230, 230, 1);
      }
    }

  }

  .install-app {
    /*animation: bouton 0.5s alternate infinite;*/
    width: 100%;
  }
  @keyframes bouton {
		from {
			transform: scale(1);
		}
		to {
			transform: scale(2);
		}
	}

</style>