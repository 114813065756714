import moment from "moment";

const CatfishInit = function () {
  //eslint-disable-next-line
  $(".catfish.item-infolettre")
    .css("display", "none")
    .css("visibility", "hidden")
    .addClass("not-to-display");

  const status =
    typeof ejConnect !== "undefined" && typeof loginNav !== "undefined"
      ? //eslint-disable-next-line
	   ejConnect.etatClient
      : "anon";
  switch (status) {
    case "cl":
      displayCatfishDependingOnClientStatus("cl");
      break;
    case "ci":
      displayCatfishDependingOnClientStatus("ci");
      break;
    case "cj":
      displayCatfishDependingOnClientStatus("cj");
      break;
    default:
      //eslint-disable-next-line
      $(".catfish.item-infolettre.anon")
        .removeClass("not-to-display")
        .addClass("ej-catfish-display");
      break;
  }

  //if($('.catfish.all-audience').length) $('.catfish.item-infolettre.all-audience').removeClass('not-to-display').addClass('ej-catfish-display');
  //eslint-disable-next-line
  $(".catfish.item-infolettre.ej-catfish-display").each((index, cat) => {
    //eslint-disable-next-line
    const $cat = $(cat);
    const startDate = $cat.attr("data-start-date");
    const endDate = $cat.attr("data-end-date");
    if (startDate !== "") checkDateAndAdjustDisplay($cat, startDate, new moment());
    //}
    if (endDate !== "") checkDateAndAdjustDisplay($cat, new moment(), endDate);
  });
  //eslint-disable-next-line
  $(".catfish.item-infolettre.ej-catfish-display")
    .first()
    .css("display", "block")
    .css("visibility", "visible")
    .trigger("gererCookieStrategie");
};
// .css('display', 'block').css('visibility', 'visible').trigger('gererCookieStrategie')
const displayCatfishDependingOnClientStatus = (statusClient) => {
  //eslint-disable-next-line
  const catfishElement = $(".catfish.item-infolettre." + statusClient);
  if (catfishElement.hasClass("infolettre-N")) checkContactOkMatch(catfishElement, "N");
  else if (catfishElement.hasClass("infolettre-Y")) checkContactOkMatch(catfishElement, "Y");
  else catfishElement.removeClass("not-to-display").addClass("ej-catfish-display");
  //else catfishElement.removeClass('ej-catfish-display');
};

const checkContactOkMatch = ($catfish, $contactOk) => {
  //eslint-disable-next-line
  if (loginNav.session_details !== null) {
    //eslint-disable-next-line
    if (loginNav.session_details.contact_ok === $contactOk)
      $catfish.removeClass("not-to-display").addClass("ej-catfish-display");
    else $catfish.removeClass("ej-catfish-display").addClass("not-to-display");
  }
};

const parseDatefromdialog = (dateString) => {
  var arrDate = dateString.split(" ");
  var englishMonths = {
    "janv.": "jan",
    "févr.": "feb",
    mars: "mar",
    "avr.": "apr",
    mai: "may",
    juin: "jun",
    "juil.": "jul",
    août: "aug",
    "sept.": "sep",
    "oct.": "oct",
    "nov.": "nov",
    "déc.": "dec",
  };
  arrDate[1].replace(
    /janv.|févr.|mars|avr.|mai|juin|juil.|août|sept.|oct.|nov.|déc./gi,
    function (matched) {
      arrDate[1] = englishMonths[matched];
    }
  );
  return new moment(arrDate.join(" "));
};

const checkDateAndAdjustDisplay = ($catfish, $firstDate, $lastDate) => {
  var firstMoment, lastMoment;
  if (typeof $firstDate == "string") {
    firstMoment = parseDatefromdialog($firstDate);
  } else {
    firstMoment = new moment($firstDate);
  }
  if (typeof $lastDate == "string") {
    lastMoment = parseDatefromdialog($lastDate);
  } else {
    lastMoment = new moment($lastDate);
  }
  /*if (!firstMoment.isValid()) {
    console.log("firstMoment", firstMoment);
    console.log("firstMoment.invalidAt", firstMoment.invalidAt());
  }
  lastMoment = new moment($lastDate);*/
  if (firstMoment.isAfter(lastMoment))
    $catfish.removeClass("ej-catfish-display").addClass("not-to-display");
};

export default CatfishInit;
