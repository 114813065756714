var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-component-name": _vm.$options.name } }, [
    _c(
      "div",
      { staticClass: "w-100 h-100 div_general" },
      [
        _c(
          "b-row",
          { staticClass: "w-100 h-100 d-flex align-items-center" },
          [
            _c("b-col", { staticClass: "col-12 col-md-4" }, [
              _vm.contenu.petiteNote
                ? _c("div", { staticClass: "boite_petite_note" }, [
                    _c("span", {
                      staticClass: "petite_note",
                      domProps: { innerHTML: _vm._s(_vm.contenu.petiteNote) },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.contenu.surTitre
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.contenu.surTitre) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: _vm.getImageSrcVariation(
                        _vm.getImageByScreenSize(_vm.contenu)
                      ),
                      expression:
                        "getImageSrcVariation(getImageByScreenSize(contenu))",
                    },
                  ],
                  staticClass: "w-100 h-100 drop-shadow",
                  attrs: { alt: "" },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "b-col",
              {
                staticClass:
                  "d-flex flex-column align-items-start col-12 col-md-8",
              },
              [
                _c("div", [
                  _c("h3", {
                    staticClass: "titre graphik_bold",
                    domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "sousTitre",
                  domProps: { innerHTML: _vm._s(_vm.contenu.sousTitre) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "promo_texte",
                  domProps: { innerHTML: _vm._s(_vm.contenu.texte) },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 justify-content-center justify-content-md-start",
                  },
                  [
                    _vm.getMediaImageUrl(_vm.contenu)
                      ? _c(
                          "a",
                          {
                            attrs: { href: _vm.getMediaImageUrl(_vm.contenu) },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn graphik_bold btn_bingo_theme disableThemeHover",
                                style: [
                                  { "background-color": _vm.contenu.colorBtn },
                                  { "border-color": _vm.contenu.borderColor },
                                ],
                              },
                              [
                                _vm._v(
                                  " \n            " +
                                    _vm._s(_vm.contenu.texteBouton) +
                                    " \n          "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }