// Originally in ej-commmun-portail.js

/*******************************************/
/*** OBJECT EspacejeuxPortail_GameLaunch  ***/
/*****************************************/

/* NAME : EspacejeuxPortail_GameLaunch
 * PARM : none
 *
 * DESC : validates host location on game launch then creates or removes game data from the cookie
 */

let EspacejeuxPortail_GameLaunch_def = function () {

	var instance = this;
	const domains = {
		LQ: '.lotoquebec.',
		LQDEV: '.lotoquebec-',
		EJ: '.espacejeux.',
		EJDEV: '.espacejeux-'
	};

	this.type = null;
	this.data = null;

	this.init = function () {
		instance._getGameCookie();
	};

	this.validate = function (type, data) {
		if (!instance._isDomainEJ()) {
			instance.type = type;
			instance.data = [].slice.apply(data);

			instance._setGameCookie(function () {
				instance._redirect();
			});
		}
	};

	this._isDomainEJ = function () {
		var href = window.location.href;

		if (href.indexOf(domains.EJ) > 0 || href.indexOf(domains.EJDEV) > 0) {
			return true;
		}
		else {
			return false;
		}
	};

	this._setGameCookie = function (callback) {
		var game = {type: instance.type, data: instance.data};
		var origin = window.location.origin;

		if (origin.indexOf(domains.LQ) > 0) {
			origin = origin.replace(domains.LQ, domains.EJ);
		}

		if (origin.indexOf(domains.LQDEV) > 0) {
			origin = origin.replace(domains.LQDEV, domains.EJDEV);
		}

		$('<img />', {
			'src': origin + '/json/game-cookie?action=setGameCookie&game=' + JSON.stringify(game) + '&_' + Date.now()
		}).ready(callback);
	};

	this._getGameCookie = function () {
		$.ajax({
			url: '/json/game-cookie?action=getGameCookie&_' + Date.now(),
			type: 'GET',
			cache: false,
			async: true,
			dataType: 'text',
			error: function (data) {},
			success: function (data) {
				if (data) {
					var game = JSON.parse(data);

					if (game.type == 'casino') {
						launch_game_and_gaq(null, data);
					}

					instance._removeGameCookie();
				}
			}
		});
	};

	this._removeGameCookie = function () {
		$('<img />').on('load').attr('src', '/json/game-cookie?action=removeGameCookie&_' + Date.now());
	};

	this._redirect = function () {
		var href = window.location.href;

		if (href.indexOf(domains.LQ) > 0) {
			href = href.replace(domains.LQ, domains.EJ);
		}

		if (href.indexOf(domains.LQDEV) > 0) {
			href = href.replace(domains.LQDEV, domains.EJDEV);
		}

		var splitURL = href.split('#'),
			anchor = '';

		href = splitURL[0];

		if (typeof splitURL[1] !== 'undefined') {
			anchor = splitURL[1];
		}

		//href = removeUrlParameter(href, 'deconnect');

		if (anchor.length > 0) {
			href += '#' + anchor;
		}

		window.location.href = href;
	};
}

window.EspacejeuxPortail_GameLaunch = new EspacejeuxPortail_GameLaunch_def();