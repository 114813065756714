// Originally in ej-commmun-portail.js

import DetectBrowser from "./detectBrowser";

var promoDrawWindowSafari = null; // variable globale

function openPromoGameNewWindow(promoGameId, langue) {
  var url = "/__ob/mobile_games/" + promoGameId + "/index.html?lang=" + langue + "&channel=i";
  window.open(url, "_blank");
}

function closePromoDrawWindowOnSafari() {
  if (promoDrawWindowSafari != null) {
    console.log("closePromoDrawWindowOnSafari");
    promoDrawWindowSafari.close();
  }
}

function goToDrawWindowOnSafari(promoGameId, langue) {
  if (promoDrawWindowSafari != null) {
    console.log("promoDrawWindowSafari");
    promoDrawWindowSafari.location.href =
      "/__ob/mobile_games/" + promoGameId + "/index.html?lang=" + langue + "&channel=i";
    promoDrawWindowSafari.focus();
  }
}

window.launch_promo_game = function(promoGameId, langue) {
  if (!isLoggedIn()) {
    Espacejeux_OuvrirLogin();
    return;
  } else if (ejConnect.etatClient == "cl") {
    $("#messageClientLeger").modal("show");
  } else if (ejConnect.etatClient == "ci") {
    $("#messageGuestPromo").modal("show");
  } else {
    if (DetectBrowser.isIos) {
      closePromoDrawWindowOnSafari();
      promoDrawWindowSafari = window.open("about:blank", "");
      goToDrawWindowOnSafari(promoGameId, langue);
    }
    $.ajax({
      url: "/promo-draw-engine/campaigns/current/participants",
      headers: { "x-game-channel": "I" },
      xhrFields: { withCredentials: true },
      method: "POST",
      async: false,
      success: function(data, status, xhr) {
        if (DetectBrowser.isIos) {
          goToDrawWindowOnSafari(promoGameId, langue);
        } else {
          openPromoGameNewWindow(promoGameId, langue);
        }
      },
      error: function(xhr, status, error) {
        var message = xlmsg.PROMO_INTERNAL_SERVER_ERRORS;
        var selector = ".modal-promo-guest-alert-text";
        $(selector).html(message);
        console.log("promo-err");
        if (xhr.status == 401) {
          $(selector).html(xlmsg.PROMO_AUTH_FAILURE);
          if (DetectBrowser.isIos) {
            closePromoDrawWindowOnSafari();
          }
        } else if (xhr.status == 403) {
          $(selector).html(xlmsg.PROMO_GAME_PLAY_COMPLETE);

          if (
            xhr.responseJSON &&
            xhr.responseJSON.errors.gameplay.includes("GAME_PLAY_IN_PROGRESS")
          ) {
            if (DetectBrowser.isIos) {
              goToDrawWindowOnSafari(promoGameId, langue);
            } else {
              openPromoGameNewWindow(promoGameId, langue);
            }
            return;
          }
          if (DetectBrowser.isIos) {
            closePromoDrawWindowOnSafari();
          }
        } else if (xhr.status == 404) {
          $(selector).html(xlmsg.PROMO_NO_CAMPAIGNS_ACTIVE);
          if (DetectBrowser.isIos) {
            closePromoDrawWindowOnSafari();
          }
        } else if (xhr.status == 500 || xhr.status == 503) {
          $(selector).html(xlmsg.PROMO_INTERNAL_SERVER_ERRORS);
          if (DetectBrowser.isIos) {
            closePromoDrawWindowOnSafari();
          }
        } else if (xhr.status == 409) {
          if (DetectBrowser.isIos) {
            goToDrawWindowOnSafari(promoGameId, langue);
          } else {
            openPromoGameNewWindow(promoGameId, langue);
          }
          return;
        } else {
          $(selector).html(xlmsg.PROMO_INTERNAL_SERVER_ERRORS);
          if (DetectBrowser.isIos) {
            closePromoDrawWindowOnSafari();
          }
        }
        $("#messageGuestPromo").modal("show");
      }
    });
  }
};
