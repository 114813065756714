var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "section-sansTheme section-page gs_section row",
      class: [
        _vm.contenu.coinArrondi,
        _vm.contenu.cssClass,
        _vm.contenu.marginTop && _vm.contenu.marginTop == "false"
          ? "mt-0 pt-0"
          : "",
      ],
      style: [_vm.styleObject(_vm.$mq)],
      attrs: {
        "data-component-name": _vm.$options.name,
        "data-page": _vm.getNomPageCourante,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "contenu container",
          class: [_vm.contenu.cssClassContenu],
        },
        [
          _vm.contenu.title &&
          !_vm.hideTitleIfMobile(_vm.contenu.titreAfficherMobile)
            ? _c("h1", {
                staticClass: "gs_titre graphik_bold",
                class: [
                  _vm.contenu.titleOverBackground ? "gs_titre_top_neg  " : "",
                ],
                style: { color: _vm.contenu.titleColor },
                domProps: { innerHTML: _vm._s(_vm.contenu.title) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.contenu.title2
            ? _c("p", { staticClass: "gs_sous_titre" }, [
                _vm._v("\n      " + _vm._s(_vm.contenu.title2) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.contenu.grandeSection["@nodes"], function (aNode) {
            return [
              _vm.contenu.grandeSection[aNode].typeComposante
                ? _c(
                    _vm.contenu.grandeSection[aNode].typeComposante,
                    {
                      key: _vm.contenu.grandeSection[aNode]["@id"],
                      tag: "component",
                      attrs: { contenu: _vm.contenu.grandeSection[aNode] },
                    },
                    [_vm._v(_vm._s(_vm.contenu.grandeSection[aNode]["@id"]))]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }