// Originally in ej-commmun-portail.js

/*************************/
/*** OBJECT EspacejeuxPortail_FilAriane  ***/
/***********************/

/* NAME : EspacejeuxPortail_FilAriane
 * PARM : no
 *
 * DESC : Object for breadcrumbs
 */

function EspacejeuxPortail_FilAriane_def() {

	this.COLONNE_ARRAY_FIL_ARIANE_URL = 0;
	this.COLONNE_ARRAY_FIL_ARIANE_TEXTE = 1;
	this.COLONNE_ARRAY_FIL_ARIANE_ID = 2;
	this.arrayFilAriane = [3];
	this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_URL] = [];
	this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_TEXTE] = [];
	this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_ID] = [];

	this.viderFilAriane = function () {
		this.arrayFilAriane = [3];
		this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_URL] = [];
		this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_TEXTE] = [];
		this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_ID] = [];
	};

	this.ajouterElementFilAriane = function (texte, url, id) {
		this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_TEXTE][this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_TEXTE].length] = texte;
		this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_URL][this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_URL].length] = url;
		this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_ID][this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_ID].length] = id;
	};

	this.afficherFilAriane = function () {

		var index = 0,
			nbr = 0,
			url = null,
			texte = null;
		//id = null;

		nbr = this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_TEXTE].length;

		if (window.location.pathname.match(/^\/$|\/fr\/index.html$|\/en\/index.html$|\/fr\/$|\/en\/$|\/fr$|\/en$/g) != null) {
			$("#filAriane").remove();
		} else {
			$("#filAriane a").remove();
			if (nbr > 1) {
				url = this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_URL][nbr - 2];
				texte = this.arrayFilAriane[this.COLONNE_ARRAY_FIL_ARIANE_TEXTE][nbr - 1];
				$("#filAriane").append('<a href="' + url + '" style="cursor:pointer;"><div class="back-button icomoon icon-arrowleft "></div><div class="current-page">' + texte + '</div></a>');
			}
			else {
				$("#filAriane").append('<a href="/" style="cursor:pointer;"><div class="back-button icomoon icon-arrowleft "></div></a>');
			}
		}
	};
}

const EspacejeuxPortail_FilAriane = new EspacejeuxPortail_FilAriane_def();

export default EspacejeuxPortail_FilAriane;