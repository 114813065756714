// Originally in ej-commmun-portail.js

/******************************************/
/*** OBJECT EspacejeuxPortail_Browser  ***/
/** ************************************* */

/*
 * NAME : EspacejeuxPortail_Browser 
 * PARM : no
 *
 * DESC : Object for Msg Error
 */

function EspacejeuxPortail_Browser_def() {

	this.cookieEnabled = false;
	this.browserData = [];
	this.flash = [false, ''];

	this.init = function () {
		this.detectBrowser();
		this.isCookieEnabled();
		this.getFlash();
	};

	this.IsIE8Browser = function () {
		var rv = -1;
		var ua = navigator.userAgent;
		var re = new RegExp("Trident\/([0-9]{1,}[\.0-9]{0,})");
		if (re.exec(ua) != null) {
			rv = parseFloat(RegExp.$1);
		}
		return (rv == 4);
	};



	this.detectBrowser = function () {
		var userAgent = navigator.userAgent.toLowerCase();

		var browser = get_browser();

		var version = get_browser_version(browser);


		EspacejeuxPortail_Browser.browserData.push(browser, version);

	};

	this.isCookieEnabled = function () {
		// Is cookie enabled
		EspacejeuxPortail_Cookie.ecrireCookie('EJICCookie', 'EJICCookieText', EspacejeuxPortail.NBR_JOURS_DELAI_COOKIE_LANGUE);
		EspacejeuxPortail_Browser.cookieEnabled = (EspacejeuxPortail_Cookie.lireCookie('EJICCookie') == 'EJICCookieText') ? true : false;
		EspacejeuxPortail_Cookie.supprimerCookie('EJICCookie');
	};

	this.getFlash = function () {
		if ('undefined' !== typeof swfobject) {
			var playerVersion = swfobject.getFlashPlayerVersion();
			EspacejeuxPortail_Browser.flash[0] = true;
			EspacejeuxPortail_Browser.flash[1] = playerVersion.major + "." + playerVersion.minor + "." + playerVersion.release;
		}
	};
}



function get_browser() {
	var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return 'IE ' + (tem[1] || '');
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR\/(\d+)/)
		if (tem != null) {return 'Opera ' + tem[1];}
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) {M.splice(1, 1, tem[1]);}
	if (M[0] == 'MSIE') {
		return 'Internet Explorer';
	}
	else {
		return M[0];
	}
}

function get_browser_version(b) {
	var userAgent = navigator.userAgent.toLowerCase();
	var version = '0';
	b = b.toLowerCase();
	if (b == 'msie' || b == 'internet explorer') {
		version = this.IEBrowserVersion();
	}
	// Is this a version of Chrome?
	if (b == 'chrome') {
		userAgent = userAgent.substring(userAgent.indexOf('chrome/') + 7);
		userAgent = userAgent.substring(0, userAgent.indexOf(' '));
		version = userAgent;
	}
	// Is this a version of Safari?
	if (b == 'safari') {
		userAgent = userAgent.substring(userAgent.indexOf('version/') + 8);
		userAgent = userAgent.substring(0, userAgent.indexOf(' '));
		version = userAgent;
	}
	// Is this a version of Mozilla?
	if (b == 'mozilla' || b == 'firefox') {
		// Is it Firefox?
		if (navigator.userAgent.toLowerCase().indexOf('firefox') != -1) {
			userAgent = userAgent.substring(userAgent.indexOf('firefox/') + 8);
			//userAgent = userAgent.substring(0, userAgent.indexOf(' '));
			version = userAgent;
		}
	}
	// Is this a version of Opera?
	if (b == 'opera') {
		userAgent = userAgent.substring(userAgent.indexOf('version/') + 8);
		version = userAgent;
	}
	return version;
}

function IEBrowserVersion() {
	// Set Text
	var text = [];
	text['compatible'] = (EspacejeuxPortail_Langue.pageEnAnglais()) ? 'in mode of compatibility' : 'en mode de compatibilit&eacute;';
	var version;
	// Is IE 6-7
	var msie = navigator.userAgent.toLowerCase();
	var rm = new RegExp("msie ([\.0-9]{0,})");
	var mv = -1;
	if (rm.exec(msie) != null) {
		mv = parseFloat(RegExp.$1);
	}
	// Is IE 8-9
	var compatible = '', rv = -1, ua = navigator.userAgent, re = new RegExp("Trident\/([0-9]{1,}[\.0-9]{0,})");
	if (re.exec(ua) != null) {
		rv = parseFloat(RegExp.$1);
	}
	// Is IE 8-9 compatible IE7
	if (7 === parseFloat(mv) && -1 != rv) {
		compatible = '<br />' + text['compatible'] + ' Internet Explorer 7';
	}
	// Set IE 8-9
	switch (rv) {
		case 4:
			version = '8' + compatible;
			break;
		case 5:
			version = '9' + compatible;
			break;
	}
	return version;
}


const EspacejeuxPortail_Browser = new EspacejeuxPortail_Browser_def();

export default EspacejeuxPortail_Browser;