const Cookies = {
	/**
	 * Permet de lire un cookie
	 * @param {string} name Nom du cookie à charger
	 * @return {String} Valeur du cookie. Null si le name n'est pas une string valide
	 * @function get
	 */
	get: function(name) {
		if (name && name.constructor === String) {
			// Charge le cookie
			var val = decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
			// Retourne la valeur chargé
			return val;
		}
		return null;
	},

	/**
	 * Permet de définir un cookie
	 * @param {string} name Nom du cookie à sauvegarder
	 * @param {string} val String a sauvegarder
	 * @param {Number|String|Date) [days=Infinity] Nombre de jours de vie du cookie, ou date d'expiration en format UTC ou date d'expiration ou string 'session'. Default = Infinity.
	 * @param {string} [path='/'] Chemin d'accès du cookie. Default = '/'.
	 * @param {string} [domain=''] Limit de domain pour le cookie. Default = ''.
	 * @param {boolean} [secure=true] Tous les cookie du jeux en ligne doivent être en mode secure. Default = true.
	 * @param {samesite} [samesite=lax]  Tous les cookies de jeu en ligne doivent être créés avec le flag samesite = lax
	 * @return {boolean}
	 * @function set
	 */
	set: function(name, val, days, path, domain, secure) {
		// If name is a cookie key return
		if (!name || /^(?:expires|max\-age|path|domain|secure)$/i.test(name)) { return false; }
		days = days || Infinity;

		var expDate = new Date(),
			expires = "; expires=";

		switch (days.constructor) {
			case Number:
				expDate.setDate(expDate.getDate()+days);
				expires += (days === Infinity ? "Fri, 31 Dec 9999 23:59:59 GMT" : expDate.toUTCString());
				break;
			case String:
				if(days == 'session') {
					expires = '';
				} else {
					expires = "; expires=" + days;
				}
				break;
			case Date:
				expires = "; expires=" + days.toUTCString();
				break;
		}

		document.cookie = encodeURIComponent(name || "") + "=" + encodeURIComponent(val) + expires + (domain ? '; domain='+domain : '') + "; path=" + (path || '/') + "; secure; SameSite=Lax";
		return true;
	},

	/**
	 * Permet de forcer un cookie a expirer
	 * @param {string} name Nom du cookie qui doit expirer
	 * @param {string} [path='/'] Chemin d'accès du cookie. Default = '/'.
	 * @param {string} [domain=''] Limit de domain pour le cookie. Default = ''.
	 * @return {boolean} Retourne true si le cookie a été trouvé et défini pour expirer. Si non trouvé, retourne false.
	 * @function unset
	 */
	unset: function(name, path, domain) {
		if(Cookies.hasItem(name)) {
			document.cookie = encodeURIComponent(name) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (domain ? "; domain=" + domain : "") + "; path=" + (path || '/');
			return true;
		}
		return false;
	},

	/**
	 * Défini si un cookie existe avec le nom précisé
	 * @param {string} name Nom du cookie à rechercher
	 * @return {boolean} Retourne true si le cookie a été trouvé.
	 * @function hasItem
	 */
	hasItem: function(name) {
		if(name && name.constructor === String) {
			return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
		}
		return false;
	},

	/**
	 * Retourne la liste de tous les nom de cookies trouvé dans le document
	 * @return {Array} Array contenant la liste de tous les noms de cookies dans le document.
	 * @function names
	 */
	keys: function () {
		var names = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
		for (var len = names.length, idx = 0; idx < len; idx++) {
			names[idx] = decodeURIComponent(names[idx]);
		}
		return names;
	}
}

export default Cookies;