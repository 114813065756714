var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "row section-sansTheme section-page be_section",
      class: [_vm.contenu.sectionCoinRondTop ? "be_coin_rond" : ""],
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _c("hr", { staticClass: "barre_entete" }),
      _vm._v(" "),
      _vm.contenu.image
        ? _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.getImageSrc(_vm.getImageByScreenSize(_vm.contenu)),
                expression: "getImageSrc(getImageByScreenSize(contenu))",
              },
            ],
            staticClass: "flex-shrink-0",
            attrs: { alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h1",
        {
          staticClass: "be_titre graphik_bold",
          style: { color: _vm.contenu.titleColor },
        },
        [_vm._v("\n    " + _vm._s(_vm.contenu.title) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.contenu.title2
        ? _c("p", { staticClass: "be_sous_titre" }, [
            _vm._v("\n    " + _vm._s(_vm.contenu.title2) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }