<template>
	<div class="lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1" data-role="lobby-table-game-specific-wrapper">
		<div class="lobbyTableGameSpecificData--3whkv TableHistoryStatistic--3az41">
			<div class="itemsContainer--uoZQl" style="padding-bottom: 0%;">
				<div class="itemsRow--2Nmj9 skipRowsTransition--QL5CX" style="width: 100%; padding-bottom: 8.45455%; padding-left: 0%; padding-right: 0%; left: 0%;">
					<div class="itemContainer--1x-Md noTransition--1UEsy" :style="'width: 11.625%; padding-bottom: 8.45455%; transform: translateX('+ getTranslateX(index) +'%);'" :key="index" v-for="(result,index) in results.slice(0,8)">
						<div class="itemScale--3KxD1">
							<svg class="" viewBox="0 0 42 30" width="100%" height="100%" :data-sector="result">
								<linearGradient id="X2-pill" gradientUnits="userSpaceOnUse" x1="13.02" y1="-8.100000000000001" x2="30.66" y2="36.6">
									<stop offset="0%" stop-color="#E6E7E8"></stop>
									<stop offset="100%" stop-color="#939598"></stop>
								</linearGradient>
								<linearGradient id="X7-pill" gradientUnits="userSpaceOnUse" x1="30.66" y1="36.6" x2="13.02" y2="-8.100000000000001">
									<stop offset="0%" stop-color="#DBB251"></stop>
									<stop offset="28.89%" stop-color="#D6AA4B"></stop>
									<stop offset="48.73%" stop-color="#BE872F"></stop>
									<stop offset="68.43%" stop-color="#EBC963"></stop>
									<stop offset="100%" stop-color="#FFD67D"></stop>
								</linearGradient>
								<ellipse cx="21" cy="15" :rx="(index == 0)?'18.15':'19.65'" :ry="(index == 0)?'12.15':'13.65'" :fill="getCouleur(result)" :fill-opacity="(index == 0)?'0.8':'0.15'" stroke="none"></ellipse>
								<ellipse cx="21" cy="15" :rx="(index == 0)?'20.25':'18.75'" :ry="(index == 0)?'14.25':'12.75'" fill="none" :stroke-opacity="(index == 0)?'1':'0.3'" :stroke="getCouleur(result)" stroke-width="1.5"></ellipse>
								<text data="pill-value" dy="21.29" dx="21" text-anchor="middle" :style="'font-size: 17px; fill: '+ ((index == 0)?'rgb(255, 255, 255)':getCouleur(result))+'; font-family: Inter, Arial, sans-serif; font-weight: 500; opacity: 1;'">
									{{getAffichageResultat(result)}}
								</text>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * https://lotoquebec.atlassian.net/browse/SWSPC-1157
 * 
 * 1 = Jaune
 * 2 = Bleu
 * 5 = Mauve
 * 6 = Vert
 * 20 = Orange
 * 40 = Rose
 * 7x = Jaune
 * 2x =  Gris blanc
 * 
 */

const CS_COULEUR_JAUNE = '#FFC94B';
const CS_COULEUR_BLEU = '#6298FF';
const CS_COULEUR_MAUVE = '#CC85FF';
const CS_COULEUR_VERT = '#59E777';
const CS_COULEUR_ORANGE = '#EE7340';
const CS_COULEUR_ROSE = '#FF4053';
const CS_COULEUR_OR = 'url(#X7-pill)';
const CS_COULEUR_GRIS = 'url(#X2-pill)';

export default {
	props : {
		results : {
			type : Array,
			required : true
		},
		appareil : {
			type : String,
			required : true
		},
		prefixeUrl : {
			type : String,
			required : true
		},
	},

	methods: {
		//retourne l'indentation de chaque affichage
		getTranslateX:function(index){
			return (index*108.602);
		},
		//retourne le résultat avec le bon affichage
		getAffichageResultat: function(resultat) {
			if(resultat.charAt(0)=="0"){
				return resultat.charAt(1);
			}else{
				return resultat;
			}
		},
		//retourne la couleur selon le résultat
		getCouleur:function(result){
			if(result == "01"){
				return CS_COULEUR_JAUNE
			}else if(result == "02"){
				return CS_COULEUR_BLEU
			}else if(result == "05"){
				return CS_COULEUR_MAUVE
			}else if(result == "06"){
				return CS_COULEUR_VERT
			}else if(result == "20"){
				return CS_COULEUR_ORANGE
			}else if(result == "40"){
				return CS_COULEUR_ROSE
			}else if(result == "X7"){
				return CS_COULEUR_OR
			}else{ //X2
				return CS_COULEUR_GRIS
			}
		}
	},
}
</script>

<style>
.TableLogo--3RFNG.desktop--1Txr1 {
    z-index: 4
}

.TableLogo--3RFNG.desktop--1Txr1.roulette--2Wcvy {
    bottom: 1.85em
}

.TableLogo--3RFNG.desktop--1Txr1.doubleBall--2sPYM {
    bottom: 2.5em
}

.lobbyTableGameSpecificWrapper--3fAEk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    min-height: 3.35rem;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1em;
    z-index: 4
}

.lobbyTableGameSpecificWrapper--3fAEk.emptyGameHistoryStatistic--Uu-Gi {
    min-height: auto!important
}

.lobby-table-block.occupied .lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .lobbyTableGameSpecificWrapper--3fAEk {
    left: 1px;
    right: 1px
}

.lobbyTableGameSpecificWrapper--3fAEk .lobby-table-block.table-is-full-behind {
    display: none
}

.lobbyTableGameSpecificData--3whkv:empty {
    display: none
}

.lobby-table-block.info .lobbyTableGameSpecificData--3whkv {
    display: none!important
}

.lobbyTableGameSpecificData--3whkv .lobby-table-block.table-details-loaded.not-operational:not(.info) {
    display: none
}

.lobby-table-block.crazytime .lobbyTableGameSpecificData--3whkv,.lobby-table-block.monopoly .lobbyTableGameSpecificData--3whkv {
    margin-bottom: -.15em
}

.lobby-table-block.crazytime .phone--3PmoR .lobbyTableGameSpecificData--3whkv,.lobby-table-block.monopoly .phone--3PmoR .lobbyTableGameSpecificData--3whkv {
    margin-bottom: -.25em
}

.desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 1.9em;
    z-index: 4
}

.desktop--1Txr1 .lobbyTableGameSpecificData--3whkv>* {
    z-index: 4
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    box-sizing: border-box;
    bottom: -1px;
    min-height: 3em;
    padding: 0 .3em .4em
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk.portrait--w-M_r {
    min-height: 2.4em
}

.lobby-table-block.not-operational.table-details-loaded .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    display: none
}

.lobby-table-block.occupied .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    left: 0;
    right: 0
}

.TableHistoryStatistic--3az41 {
    box-sizing: border-box;
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%
}

.TableHistoryStatistic--3az41 div[class^=itemScale]>svg {
    width: 100%;
    position: relative;
    overflow: visible
}

.itemsContainer--uoZQl {
    position: relative;
    width: 100%;
    height: 100%
}

.itemsRow--2Nmj9 {
    transition: opacity .5s linear;
    opacity: 1;
    position: relative;
    direction: ltr
}

.itemsRow--2Nmj9.skipRowsTransition--QL5CX {
    transition: none
}

.itemsRow--2Nmj9.hiddenRow--ENOlV {
    opacity: 0
}

.itemsRow--2Nmj9.fadeElements--34koz {
    overflow: hidden;
    -webkit-mask-image: linear-gradient(90deg,rgba(74,74,74,.3),#4a4a4a 1.2%,#4a4a4a 98.8%,rgba(74,74,74,.3));
    mask-image: linear-gradient(90deg,rgba(74,74,74,.3),#4a4a4a 1.2%,#4a4a4a 98.8%,rgba(74,74,74,.3))
}

.itemsRow--2Nmj9.isScrollable--3zo-M {
    overflow: hidden
}

.itemContainer--1x-Md {
    position: absolute;
    pointer-events: all;
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out,-webkit-transform .25s ease-out
}

.itemContainer--1x-Md.noTransition--1UEsy {
    transition-property: none
}

.itemScale--3KxD1 {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation-duration: .35s;
    animation-duration: .35s;
    -webkit-transform: scale(1);
    transform: scale(1)
}


</style>