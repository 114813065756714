/*******************************************/
/*** OBJECT EspacejeuxPortail_Dialogue  ***/
/*****************************************/

/* NAME : EspacejeuxPortail_Dialogue
 * PARM : no
 *
 * DESC : Object for Dialog
 */

function EspacejeuxPortail_Dialogue_def() {

	// Default config
	var OPT_DEFAUT = {
		innerHTML: "",
		title: "",
		onload: "",
		type: "",
		width: 200,
		modal: true,
		resizable: true,
		buttons: []
	},
		preparerOptionsEJ = function (optionsEJ) {
			// Set optionEJ values
			optionsEJ.type = setValeur(optionsEJ.type, OPT_DEFAUT.type);
			optionsEJ.title = setValeur(optionsEJ.title, OPT_DEFAUT.title);
			optionsEJ.innerHTML = setValeur(optionsEJ.innerHTML, OPT_DEFAUT.innerHTML);
			optionsEJ.onload = setValeur(optionsEJ.onload, OPT_DEFAUT.onload);
			optionsEJ.buttons = setValeur(optionsEJ.buttons, OPT_DEFAUT.buttons);

			return optionsEJ;
		},
		preparerBoutonsJQuery = function (optionsEJ) {
			// Init var
			var jQueryButtons = {},
				jQueryButtonsCode = "",
				button = null,
				i = 0;

			jQueryButtonsCode = jQueryButtonsCode + "{";

			if (optionsEJ.buttons.length > 0) {
				// Loop Array button
				for (i = 0; i < optionsEJ.buttons.length; i++) {

					button = optionsEJ.buttons[i];

					if (i > 0) {
						jQueryButtonsCode = jQueryButtonsCode + ", ";
					}

					jQueryButtonsCode = jQueryButtonsCode + "\"" + button.label + "\": ";

					// Add button
					if (false === button.autoClose) {
						jQueryButtonsCode = jQueryButtonsCode + "function(){" + button.code + "}";
					}
					else {
						jQueryButtonsCode = jQueryButtonsCode + "function(){$(this).dialog('close');" + button.code + "}";
					}
				}
			}

			jQueryButtonsCode = jQueryButtonsCode + "}";

			eval("jQueryButtons = " + jQueryButtonsCode);

			// Return button
			return jQueryButtons;
		},
		setValeur = function (valeur, defaut) {
			// Check if new option must be set
			if (estDefini(valeur)) {
				return valeur;
			}
			return defaut;
		},
		estDefini = function (obj) {
			// Check if option is pass
			return (typeof (obj) !== "undefined");
		};

	this.afficher = function (msg) {

		if (!$("#ej-modal").length) {
			$('body').append('<div id="ej-modal" class="modal fade" tabIndex="-1" role="dialog" aria-labelledby="errorModalLabel" aria-hidden="false" style= "display:none"> <div class="modal-dialog"> <div class="modal-content"> <div class="modal-header"> <a class="icomoon icon-cross close" data-dismiss="modal"></a> <span id="titre_msg">&nbsp;</span> </div> <div class="modal-body"> <span id="dialog_msg">&nbsp;</span> </div> </div> </div></div>');
		}

		var titreModal = '';
		var msgHtml = '';
		if (msg.optionsEJ) {
			if (msg.optionsEJ.title) {
				titreModal = '<h4 class="modal-title">' + msg.optionsEJ.title + '</h4>';
				$('#ej-modal #titre_msg').html(titreModal);
				msgHtml = msg.optionsEJ.innerHTML;
			}
		}
		else {
			msgHtml = msg;
		}

		$('#ej-modal #dialog_msg').html(msgHtml);
		$('#ej-modal').modal('show');
	};

	this.fermer = function () {
		$('div.tutoriel').modal('hide');
	};

	this.alert = function (msg) {

		EspacejeuxPortail_Dialogue.afficher({
			optionsEJ: {
				type: "",
				title: "",
				innerHTML: msg,
				buttons: [{label: "OK", code: ""}]
			},
			optionsJQueryUI: {
				modal: true,
				resizable: false,
				draggable: false
			}
		});
	};
}

const EspacejeuxPortail_Dialogue = new EspacejeuxPortail_Dialogue_def();

export default EspacejeuxPortail_Dialogue;