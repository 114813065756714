<template>
  <div
    id="stickyContainer"
    sticky-container
    class="sticky-container container rangee lobby3 grid-5 espaceSection mw-100"
  >
    <div
      id="stickyBarre"
      v-sticky="isStickBarre"
      sticky-offset="{top: 0, bottom: 30}"
      sticky-side="both"
      sticky-z-index="20"
      class="sticky"
      :class="[familleJeu.toLowerCase(), isShowSticky ? '' : 'hideSticky']"
    >
      <div class="centrerFiltre">
        <div>
          <ced-barre
            v-cloak
            :famille-jeu="familleJeu"
            :filtre-prix="filtrePrix"
            :filtre-presentateur="filtrePresentateur"
            :appareil="appareil"
          >
          </ced-barre>

          <div
            v-if="isMobile && tableListeOuvert.length > 0"
            class="barSlider"
          >
            <div class="tablesOuverteSlider">
              <span class="nbOuverts">
                {{
                  $tc("ced.tablesOuvertes", tableListeOuvert.length, {
                    count: tableListeOuvert.length
                  })
                }}
              </span>
            </div>
            <vue-slider
              v-model="slider"
              :min="0"
              :max="tableListeOuvert.length - 1"
              :marks="false"
              :hide-label="true"
              :showTooltip="false"
              :tooltip="'none'"
              @change="onChangeSlider"
            ></vue-slider>
          </div>
        </div>
      </div>
    </div>
    <div id="table-liste" class="row gutters-xsmall">
      <!-- ***** TABLES OUVERTES ***** -->
      <div v-show="tableListeOuvert.length > 0" class="col-12">
        <div class="regroupementTables">
          <div
            v-for="(table, index) in tableListeOuvert"
            :id="'tableOuverte' + index"
            :key="table.idTableDesktop"
            v-observe-visibility="{
              callback: (isVisible, entry) => {
              	visibilityChanged(isVisible, entry, index);
              },
              throttle: 0
            }"
            class="colonne col-12 col-md-6 col-lg-4"
            :class="['col-' + 12 / nbTableParLigne, 'col-xl-' + 12 / nbTableParLigne]"
          >
            <ced-table
              :id="'tableOuverte' + index"
              :famille="familleJeu"
              :tableApp="table"
              :tableListeLive="tableListeLive"
              :appareil="appareil"
              :prefixeUrl="prefixeUrl"
            ></ced-table>
          </div>
        </div>
      </div>
      <!-- Tables ouvertes -->

      <!-- ***** TABLES OUVERTURE BIENTÔT ***** -->
      <div
        v-show="tableListeOuvertureBientot.length > 0"
        class="col-12"
      >
        <div
          class="col-12 sectionTables clickable"
          :class="familleJeu.toLowerCase()"
          @click="toggleTableListeOuvertureBientotVisible"
        >
          <div class="splitGauche">
            <div class="nombre">
              {{
                $tc("ced.tables", tableListeOuvertureBientot.length, {
                  count: tableListeOuvertureBientot.length
                })
              }}
            </div>
            <div class="titreSection">{{ $t("ced.ouverture") }} {{ heureProchaineOuverture }}</div>
          </div>
          <div class="splitDroite">
            {{ $t("ced.enSavoirPlus") }}
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 492.004 492.004"
              style="height:15px;width:15px;"
              :style="
                isTableListeOuvertureBientotVisible
                  ? 'transform: rotate(90deg);'
                  : 'transform: rotate(-90deg)'
              "
            >
              <g>
                <g>
                  <path
                    d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
								c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
								v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
								c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
								l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>

        <div class="regroupementTables">
          <div
            v-for="table in tableListeOuvertureBientot"
            :key="table.idTableDesktop"
            class="colonne  col-12 col-md-6 col-lg-4"
            :class="[
              'col-' + 12 / nbTableParLigne,
              'col-xl-' + 12 / nbTableParLigne,
              isTableListeOuvertureBientotVisible ? '' : 'd-none'
            ]"
          >
            <ced-table
              :famille="familleJeu"
              :tableApp="table"
              :tableListeLive="tableListeLive"
              :appareil="appareil"
              :prefixeUrl="prefixeUrl"
            ></ced-table>
          </div>
        </div>
      </div>
      <!-- Tables ouverture bientôt -->

      <!-- ***** TABLES FERMÉES ***** -->
      <div v-show="tableListeFerme.length > 0" class="col-12">
        <div
          class="col-12 sectionTables sectionFerme clickable"
          @click="toggleTableListeFermeVisible"
        >
          <div class="splitGauche">
            <div class="nombre">
              {{ $tc("ced.tables", tableListeFerme.length, { count: tableListeFerme.length }) }}
            </div>
            <div class="titreSection">
              {{ $tc("ced.presentementFermee", tableListeFerme.length) }}
            </div>
          </div>
          <div class="splitDroite">
            {{ $t("ced.enSavoirPlus") }}
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 492.004 492.004"
              style="height:15px;width:15px;"
              :style="
                isTableListeFermeVisible
                  ? 'transform: rotate(-90deg);'
                  : 'transform: rotate(90deg);'
              "
            >
              <g>
                <g>
                  <path
                    d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
								c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
								v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
								c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
								l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="regroupementTables">
          <div
            v-for="table in tableListeFerme"
            :key="table.idTableDesktop"
            class="colonne col-12 col-md-6 col-lg-4"
            :class="[
              'col-xl-' + 12 / nbTableParLigne,
              isTableListeFermeVisible
                ? 'col-' + 12 / nbTableParLigne
                : 'd-none col-' + 12 / nbTableParLigne
            ]"
          >
            <ced-table
              :famille="familleJeu"
              :tableApp="table"
              :tableListeLive="tableListeLive"
              :appareil="appareil"
              :prefixeUrl="prefixeUrl"
            ></ced-table>
          </div>
        </div>
      </div>
      <!-- Tables fermées -->
    </div>
    <!-- 3 regroupements de tables -->
  </div>
  <!-- data-v-sticky-container -->
</template>

<script>
import axios from "axios";

import Sticky from "vue-sticky-directive";
import { ObserveVisibility } from "vue-observe-visibility";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import { mixinCED } from "../../mixins/mixinCED";
import { mixinLangue } from "../../mixins/mixinLangue";

import CedBarre from "./CedBarre.vue";
import CedTable from "./CedTable.vue";

export default {
  directives: {
    Sticky,
    ObserveVisibility
  },
  components: {
    VueSlider,
    "ced-barre": CedBarre,
    "ced-table": CedTable
  },
  mixins: [mixinLangue, mixinCED],

  props: {
    // ej-template/dialogs/components/casino-en-direct/cedTableListeLive.yaml en minuscule
    familleJeu: {
      type: String,
      required: false
    },
    filtrePrix: Array,
    filtrePresentateur: {
      type: Boolean,
      required: false,
      default: false
    },
    delaiRafraichissementSec: {
      type: Number,
      required: false,
      default: 30
    },
    delaiInactiviteMin: {
      type: Number,
      required: false,
      default: 5
    },
    appareil: {
      type: String,
      required: true
    },
    prefixeUrl: {
      type: String,
      required: true
    }
  },

  data: function() {
    return {
      tableListe: [],
      tableListeLive: [],
      tableListeOuvert: [],
      tableListeOuvertVisible: [],
      tableListeFerme: [],
      tableListeOuvertureBientot: [],
      isTableListeOuvertureBientotVisible: false,
      isTableListeFermeVisible: false,
      filtre: [],
      nbTableParLigne: null, // 1 (mobile), 3,4 ou 6 tables par ligne
      slider: 0,
      isStickBarre: false,
      isShowSticky: false
    };
  },

  computed: {
    CS_MOBILE: () => "mobile",
    isMobile() {
      return this.appareil == this.CS_MOBILE;
    },
    heureProchaineOuverture: function() {
      var table = this.tableListeOuvertureBientot[0];
      var findTableLive = this.mixinCED_findTableLive(table, this.tableListeLive, this.appareil);
      var heuresOuverture = this.mixinCED_dateHeuresOuverture(table, findTableLive.tableLive);

      return this.mixinCED_formatHeure(heuresOuverture, "UTC");
    }
  },

  created() {
    var self = this;
    self.nbTableParLigne = self.appareil == self.CS_MOBILE ? 1 : 3;
    this.$bus.$on("table-liste-live-updated", function(tableListeLive) {
      self.tableListeLive = tableListeLive;
      self.chargerListeTable();
    });
    this.$bus.$on("barre-filtre-updated", function(filtre) {
      self.filtre = filtre;
      self.updateTableListeGroup();
    });
    this.$bus.$on("barre-grille-updated", function(nbTableParLigne) {
      self.nbTableParLigne = nbTableParLigne;
    });
    this.$bus.$on("barre-slider-updated", function(slider) {
      self.slider = slider;
    });
    self.isShowSticky = self.showFiltre();
    self.isStickBarre =
      this.appareil == this.CS_MOBILE || (this.appareil != this.CS_MOBILE && this.showFiltre());
  },

  methods: {
    chargerListeTable: function() {
      // Important: S'assurer que "respectDocumentOrder=true" dans
      // \repositories\magnolia\workspaces\ejData car le défaut est "false"
      // Ainsi, l'ordre des noeuds est correctement retourné par cette requête
      axios
        .get(
          this.prefixeUrl +
            "/" +
            this.mixinLangue_langue +
            "/json-cache/casino-en-direct/table-liste",
          {
            params: {
              famille: this.familleJeu
            }
          }
        )
        .catch(function(error) {
          console.log("Erreur table-liste: " + error);
        })
        .then((response) => {
          this.tableListe = response.data;
          this.updateTableListeGroup();
        });
    },

    updateTableListeGroup: function() {
      if (this.tableListeLive) {
        this.tableListeOuvert = this.filtrerTableListe(this.CS_OUVERT);
      }
      this.tableListeOuvertureBientot = this.filtrerTableListe(this.CS_FERME);
      this.tableListeFerme = this.filtrerTableListe(this.CS_DESACTIVE);
      this.$bus.$emit("table-liste-updated", this.tableListeOuvert.length);
    },

    filtrerTableListe: function(etat) {
      var tableListeFiltrer = this.tableListe.filter(table => {
        var findTableLive = this.mixinCED_findTableLive(table, this.tableListeLive, this.appareil);
        var etatTable = this.mixinCED_getEtatTable(table, findTableLive.tableLive);

        if (etatTable == etat) {
          if (etat == this.CS_OUVERT) {
            return this.isShowTable(table, findTableLive.tableLive);
          }
          return true;
        }
        return false;
      });

      // Si ouverture bientôt, trier par heure d'ouverture
      if (etat == this.CS_FERME) {
        tableListeFiltrer = this.mixinCED_sortByHeuresOuvertures(
          tableListeFiltrer,
          this.tableListeLive,
          this.appareil
        );
      }

      return tableListeFiltrer;
    },

    isShowTable: function(table, tableLive) {
      if (!this.filtre || this.filtre.length == 0) return true;

      for (let elt of this.filtre) {
        if (elt.type == "prix") {
          if (tableLive && tableLive.betLimits && tableLive.betLimits.CAD) {
            if (
              Number(tableLive.betLimits.CAD.min) < Number(elt.valeurMin) ||
              Number(tableLive.betLimits.CAD.min) > Number(elt.valeurMax)
            ) {
              return false;
            }
          }
        } else {
          // Filtre présentateur
          if (
            (elt.valeur == "Sans" && !table.descriptionAuto) ||
            (elt.valeur == "Avec" && table.descriptionAuto)
          ) {
            return false;
          }
        }
      }

      return true;
    },

    visibilityChanged: function(isVisible, entry, index) {
      var i = this.tableListeOuvertVisible.indexOf(index);
      if (isVisible) {
        if (i == -1) {
          this.tableListeOuvertVisible.push(index);
          this.updateSlider();
        }
        return true;
      } else {
        if (i != -1) {
          this.tableListeOuvertVisible.splice(i, 1);
          this.updateSlider();
        }
        return false
      }
    },
    updateSlider: function() {
      this.slider = Math.min.apply(null, this.tableListeOuvertVisible);
    },
    onChangeSlider(value) {
      // On ne veut pas affecter l'historique de navigation
      document.location.replace("#tableOuverte" + value);
    },

    toggleTableListeOuvertureBientotVisible: function() {
      this.isTableListeOuvertureBientotVisible = !this.isTableListeOuvertureBientotVisible;
    },
    toggleTableListeFermeVisible: function() {
      this.isTableListeFermeVisible = !this.isTableListeFermeVisible;
    },
    showFiltre: function() {
      return this.filtrePresentateur || this.filtrePrix.length > 0;
    }
  }
};
</script>

<style lang="scss">
.espaceSection .row.gutters-xsmall .sectionTables {
  height: 90px;
  margin-top: 20px;
  font-family: "graphik_regular";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.section-verte .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #0f6d56;
}

.section-rouge .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #bc122e;
}

.section-bleu .espaceSection .row.gutters-xsmall .sectionTables,
.section-bleu-ciel .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #194ea1;
}

.section-bleu-marin .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #3135a6;
}

.section-violet .espaceSection .row.gutters-xsmall .sectionTables {
  border: 3px solid #a51c5d;
}

.clickable {
  cursor: pointer;
}

.sectionTables div {
  &.splitGauche,
  &.splitDroite {
    color: white;
    fill: white;
    line-height: 24px;
    max-width: 50%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: inherit;
  }

  &.splitGauche {
    text-align: left;
    left: 23px;
    @media (max-width: 425px) {
      left: 15px;
    }

    .nombre {
      font-size: 16px;
    }

    .titreSection {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &.splitDroite {
    font-size: 16px;
    right: 23px;
    @media (max-width: 425px) {
      right: 15px;
    }
  }
}

.section-verte .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #00a389;
    fill: #00a389;
  }
}

.section-rouge .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #f03447;
    fill: #f03447;
  }
}

.section-bleu .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #0a71f0;
    fill: #0a71f0;
  }
}

.section-bleu-ciel .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #01aef0;
    fill: #01aef0;
  }
}

.section-bleu-marin .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #4f71f8;
    fill: #4f71f8;
  }
}

.section-violet .sectionFerme div {
  &.splitGauche,
  &.splitDroite {
    color: #d63a93;
    fill: #d63a93;
  }
}

.regroupementTables {
  margin-left: -5px;
  margin-right: -6px;
  margin-top: -2px;
}

.stickyContainer {
  margin-top: 0px;
}
#table-liste {
  max-width: 1290px;
  margin-right: auto;
  margin-left: auto;
}

.theme-bg-ced .section-page .filtre,
.vue-slider.vue-slider-ltr {
  background-color: transparent !important;
  max-width: 1290px;
}
.theme-bg-ced .section-page .filtre {
  margin-right: auto;
  margin-left: auto;
}
.row.gutters-xsmall {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.theme-bg-ced .centrerFiltre {
  margin-bottom: 15px;
  @media (max-width: 1199px) {
    justify-content: center;
    display: flex;
  }
}
#table-liste {
  margin-top: 5px;
}
.sticky.vue-sticky-el {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: transparent;
  left: 0px !important;
  width: 100%;
}
@include media-breakpoint-up(md) {
  .domain-mobile .hideSticky.sticky {
    display: none;
  }
}

.sticky.vue-sticky-el.top-sticky {
  padding-top: 20px;
  padding-bottom: 5px;
  overflow: visible;
  width: 100% !important;
}
.blackjack.sticky.vue-sticky-el.top-sticky {
  background-color: #0e000d;
}
.roulette.sticky.vue-sticky-el.top-sticky {
  background-color: #050941;
}
.baccara.sticky.vue-sticky-el.top-sticky {
  background-color: #3d000e;
}
.jeuxendirect.sticky.vue-sticky-el.top-sticky {
  background-color: #101336;
}
.poker.sticky.vue-sticky-el.top-sticky {
  background-color: #0e000d;
}
.des.sticky.vue-sticky-el.top-sticky {
  background-color: #22011e;
}
.vue-slider.vue-slider-ltr {
  margin-left: 10px;
  padding: 0px 0px !important;
}
.barSlider .vue-slider-rail {
  width: 175px;
  @include media-breakpoint-up(xs) {
    width: 200px;
  }
}
.barreFiltre {
  justify-content: center;
}
.barSlider {
  display: none;
  width: 100%;
  flex-wrap: wrap;
  .tablesOuverteSlider {
    text-align: left;
    font-size: 3vw;
    background-color: transparent;
    align-self: center;
  }
}
.centrerFiltre .filtre .col-12.col-xl-10 {
  padding-right: 0px;
  padding-left: 0px;
}

@media (max-width: 767px) {
  .barSlider {
    display: inline-flex !important;
  }
}
@include media-breakpoint-up(sm) {
  .barSlider .tablesOuverteSlider {
    font-size: 2vw;
  }
}
@include media-breakpoint-up(lg) {
  .barSlider .tablesOuverteSlider {
    font-size: 1.5vw;
  }
}

/* rail style */
.vue-slider-rail {
  background-color: transparent;
  width: calc(100% - 35px);
  border-radius: 15px;
  height: 11px !important;
  border: 2px solid #00a389;
  top: 4px;
}

/* process style */
.vue-slider-process {
  background-color: #00a389;
  border-radius: 15px;
}

/* dot style */
.vue-slider-dot-handle {
  cursor: pointer;
  border-radius: 50%;
  background-color: #205447;
  border: 2px solid #00a389;
  width: 25px;
  height: 17px;
  border-radius: 15px;
  position: relative;
  top: -2px;
}

.roulette {
  .vue-slider-rail {
    border: 2px solid #0a71f0;
  }

  .vue-slider-process {
    background-color: #0a71f0;
  }

  .vue-slider-dot-handle {
    background-color: #163b75;
    border: 2px solid #0a71f0;
  }
}

.jeuxendirect {
  .vue-slider-rail {
    border: 2px solid #01aef0;
  }

  .vue-slider-process {
    background-color: #01aef0;
  }

  .vue-slider-dot-handle {
    background-color: #3a71a9;
    border: 2px solid #01aef0;
  }
}

.baccara {
  .vue-slider-rail {
    border: 2px solid #bc122e;
  }

  .vue-slider-process {
    background-color: #bc122e;
  }

  .vue-slider-dot-handle {
    background-color: #851929;
    border: 2px solid #bc122e;
  }
}

.poker {
  .vue-slider-rail {
    border: 2px solid #3135a6;
  }

  .vue-slider-process {
    background-color: #3135a6;
  }

  .vue-slider-dot-handle {
    background-color: #0d035b;
    border: 2px solid #3135a6;
  }
}

.des {
  .vue-slider-rail {
    border: 2px solid #a51c5d;
  }

  .vue-slider-process {
    background-color: #a51c5d;
  }

  .vue-slider-dot-handle {
    background-color: #730040;
    border: 2px solid #a51c5d;
  }
}
</style>
