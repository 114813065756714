<template>
  <div class="w-100 tableau">
      
    <!-- TODO: Non-testé -->
    <div v-if="contenu.enteteTableau" class="enteteTableau" :style="styleObjectEntetePied">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-8 no-float vertical-top">
            <div v-html="contenu.enteteTableau"></div>
          </div>
        </div>
      </div>
    </div>

    <table class="table-vertical table-striped" :style="contenu.couleurFondEntete && this.$mq == 'sm' ? borderTable: ''">
      <thead>
        <tr class="entete">
          <th v-for="unElement in splitEntete" :key="unElement.id" :style="{'background-color':contenu.couleurFondEntete}">
            <span>
              {{unElement}}
            </span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(uneLigneContenu) in splitTableContent(contenuTransforme)" :key="uneLigneContenu.id" class="ligne mobile desktop">
            <td :class="[splitEntete[index2]]" :data-th="splitEntete[index2]" v-for="(unElement,index2) in uneLigneContenu" :key="unElement.id">
              <span>
                <template v-if="splitFormatColonne[index2] == 'Majuscule'">
                  <span class="majuscule">{{unElement}}</span>
                </template>
                <template v-else-if="splitFormatColonne[index2] == 'DateHeure' ||
                                    splitFormatColonne[index2].startsWith('DateHeure|')">
                  {{formatDateHeure(unElement,splitFormatColonne[index2])}}
                </template>
                <template v-else-if="splitFormatColonne[index2] == 'Argent'">
                  <money-format
                    :value="Number(unElement)"
                    :locale="$i18n.locale+'-CA'"
                    currency-code="CAD"
                    :subunit-value="true"
                  ></money-format>
                </template>
                <template v-else-if="splitFormatColonne[index2] == 'DAM'">
                  <img v-lazy="getImageSrc(unElement)" :alt="'Image' + splitEntete[index2]" />
                </template>
                <template v-else>
                  {{unElement}}
                </template>
              </span>
            </td>
        </tr>
      </tbody>

      <tfoot>
        <tr class="footer" :style="{'background-color':contenu.couleurFondEntete}">
          <td :colspan="splitEntete.length"></td>
        </tr>
      </tfoot>
    </table>

    <!-- TODO: Non-testé -->
    <div v-if="contenu.piedTableau" class="piedTableau" :style="styleObjectEntetePied">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-8 no-float vertical-top">
            <div v-html="contenu.piedTableau"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showMoreResults">
      <button            
            class="btn graphik_bold btn_bingo_theme disableThemeHover voir_plus_btn"            
            v-on:click="showMoreResults =!showMoreResults">
            <!-- TODO: Definir couleur en focntion du theme Bingo -->
            {{ contenu.texteBouton ? contenu.texteBouton : $t("messageComponent.boutonPlus") }}
      </button>
    </div>

  </div>

</template> 

<script>
import {WebServicesAccess} from '../../config';
import i18n from '../../i18n'
import { mapGetters } from "vuex";

import MoneyFormat from "vue-money-format";
import moment from "moment-timezone";

import JsonInterneApi from '../../services/api/JsonInterne'
 
export default {
  name:"Tableau",
  components: {
    "money-format": MoneyFormat
  },
  data(){
    return{
      contenuTransforme: [],
      showMoreResults: false
    }
  },
  props: ["contenu"],
   i18n: {
    messages: {
      fr: {
        messageComponent: {
          boutonPlus: "Plus",
        }
      },
      en: {
        messageComponent: {
          boutonPlus: "More",
        }
      }
    }
  },
 
  created: function () {
    moment.locale(i18n.locale);

    if (this.contenu.type == 'static') {
      this.contenuTransforme = this.splitContenuStatic; 
      this.showMoreResults = true;
    } else if (this.contenu.type == 'jsonInterne') {
      JsonInterneApi.getJsonInterne(this.contenu.lienJson)
        .then(function (contenuJson) {
          this.contenuTransforme = contenuJson;
          this.setShowMoreStatusFromDisplaySize();
        })
        .catch(error => console.log(error));
    } else {
      // poursuitePoker non traité
    }
  },
  computed: {
    splitEntete: function(){
      return this.contenu.entete.split("\n"); 
    },
    splitFormatColonne: function(){
      if (!('formatColonne' in this.contenu) || this.contenu.formatColonne.length == 0) {
        return Array(this.splitEntete.length).fill('Défaut');
      }
      return this.contenu.formatColonne.split("\n"); 
    },
    splitContenuStatic: function()
    {
      var unArray = new Array
      this.contenu.contenuStatic.forEach(unElement => {      
        unArray.push(unElement.split("\n"))
      });

      return unArray
    },
    classObject: function () {
      return {
        
      }
    }, 
    borderTable: function () {
      return {
        'border-top': "1rem solid" + this.contenu.couleurFondEntete,
        'border-bottom': "1rem solid" + this.contenu.couleurFondEntete
      }
    },   
    styleObjectEntetePied: function () {
      return {
          'background-color': this.contenu.fondCouleur != 'defaut' ? this.contenu.secBgThemeColor : null,
      }
    },
  },
  methods:{
    // aFormat: "DateHeure" ou "DateHeure|" + format
    formatDateHeure: function(aDateHeure, aFormat){
      var formatDateHeure = "LL"; // Par défaut, Date longue ex: "31 mars 2020"
      if (aFormat.startsWith('DateHeure|')) {
        formatDateHeure = aFormat.split("|")[1];
        if (formatDateHeure.includes("hh:mm") && i18n.locale == "en") {
          formatDateHeure = formatDateHeure.replace("hh:mm", "h:mm a")
        }
      }
      return moment(aDateHeure).format(formatDateHeure).replace(' am',' a.m.').replace(' pm',' p.m.');
    },
    splitTableContent(content){
      if(!this.showMoreResults){          
        var maxDisplaySize = parseInt(this.contenu.nbElementOrdinateur);                
          if(this.$mq == "sm"){
            maxDisplaySize = parseInt(this.contenu.nbElementMobile);
          }          
          return this.contenuTransforme.slice(0, maxDisplaySize);
        }              
      return this.contenuTransforme;
    },
    setShowMoreStatusFromDisplaySize(){
      //Simplification pour ne pas avoir a gerer une valeur pour mobile et une pour desktop
      var maxDisplaySize = parseInt(this.contenu.nbElementOrdinateur);
      if(maxDisplaySize >= this.contenuTransforme.length){
        this.showMoreResults = true;
      }
    }
  
  },
}

</script>

<style lang="scss" scoped>
  // TODO: Ce qui suit jusqu'à "table-vertical" doit être révisé
	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
	}

  .entete{
    @include ms-respond(font-size, 0);
    height: ms(5);
    font-weight:bold;
  }

  .ligne{
    @include ms-respond(font-size, 0);  
    line-height: 1;
    height: ms(7);
  }

  .footer{
    height: ms(2);
  }

  /* Autre scss dans portail pour accès aux variables */
  /* 
  Generic Styling, for Desktops/Laptops 
  */
  .table-vertical {
    border-collapse: collapse;
    font-size: 1.4rem;
    height: auto;
    margin-bottom: 1.5vw;
    width: 100%;
  }
  
  // TABLEAU
  .tableau {
    table {
      color: #000;
      thead {
        @include media-breakpoint-down(sm) {
          display: none;
        }

        tr {
          th {
            border: none;
            background-color: transparent;
          }
          background-color: #f3efe3;
        }
      }
      tbody {
       .majuscule {
            font-weight: bold;
            text-transform: uppercase;
          }

        td {
          border: none;      
        }

        tr {
          &.boutons {
            td {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    .ligne {
      display: none;
    }

    .ligne {
      &.mobile {
        &.desktop {
          @include media-breakpoint-down(sm) {
            display: block;
            padding: 2%;
          }
          @include media-breakpoint-up(md) {
            display: table-row;
          }
        }
      }
    }

    .ligne {
      &.mobile {
        @include media-breakpoint-down(sm) {
          display: block;
          height: 100%;
        }
      }
    }

    .ligne {
      &.desktop {
        @include media-breakpoint-up(md) {
          display: table-row;
        }
      }
    }

    .plusWrapDesktop {
      text-align: center;
      &.init {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .plusWrapMobile {
      text-align: center;
      &.init {
        display: none;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 0 !important;
      }
    }

    .tableauBoutonPlus {
      display: none;
      font-size: 1.4rem;

      @include media-breakpoint-down(sm) {
        &[data-button-plus='mobile'] {
          display: inline;
        }
      }

      @include media-breakpoint-up(md) {
        &[data-button-plus='desktop'] {
          display: inline;
        }
      }
    }

    &.editMode {
      .tableauBoutonPlus {
        display: none;
      }
      tr.ligne {
        @include media-breakpoint-down(sm) {
          display: block;
        }
        @include media-breakpoint-up(md) {
          display: table-row;
        }
      }
    }

    tbody tr:nth-child(odd) {
      background-color: white;
    }
    tbody tr:nth-child(even) {
      background-color: #EFEFEF; // gris
    }

    a[data-button-plus] {
      border: 1px solid black;
      padding: 5px 40px;
      border-radius: 5px;
      text-decoration: none;
      background-color: white;
    }

    .table-vertical > thead > tr > th,
      .table-vertical > tbody > tr > td {
        /* Behave  like a "row" */
        vertical-align: middle;
        padding-left: 2rem;
      }


    .enteteTableau,
    .piedTableau {
      padding: 69px 60px 69px 60px;
      @include media-breakpoint-down(sm) {
        padding: 15px 10px 15px 10px;
      }
      p,
      h3 {
        text-align: left;
      }

      p {
        font-size: 4.4vw;
        line-height: 1.24;

        @include media-breakpoint-up(md) {
          font-size: 1.6rem;
        }
      }

      ul {
        display: block;
        margin-left: 20px;
        li {
          list-style: disc;
          text-align: left;
        }
      }

      .container {
        display: table;
        width: 100%;

        .row {
          align-items: center;
          height: 100%;
          
          .vertical-center {
            vertical-align: middle;
          }

          .vertical-top {
            vertical-align: top;
          }
        }

        .row .no-float {
          display: table-cell;
          float: none;
        }
      }
    }

    .voir_plus_btn{
      text-transform: uppercase;
      color: black;
      @include ms-respond(font-size, -1);
      background-color: #0DCB8F !important;
    }

    @media (max-width: 767px) {
      /* Force table to not be like tables anymore */
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      .table-vertical thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      .table-vertical > thead > tr > tr,
      .table-vertical > tbody > tr > td {
        /* Behave  like a "row" */
        border: none;
        position: relative;
        padding-left: 40%;
        display: flex;
        align-items: center;
      }

      .table-vertical > tbody > tr > td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: unset;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
      }

      /*
          Label the data
          */
      .table-vertical > tbody > tr > td:before {
        content: attr(data-th);
        white-space: pre-wrap;
      }

      .footer{
        height: 0; //Probleme lié à l'adaptation en mobile qui empeche d'avoir un footer de meme taille
      }
    }
  }

</style>