<template>
  <div :data-component-name="$options.name">
      <a :href="getMediaImageUrl(contenu)" :ga-img-name="imageName" class="sans_underline row" > 
        <b-col class="col-6 col-md-12">
          <img v-lazy="getImageSrcVariation(getImageByScreenSize(contenu))" class="w-100 drop-shadow a-decouvrir"
           :data-tag="getNomRouteCourante() + ' - ' + contenu.surTitre + ' - ' + contenu.titre.replace(/&nbsp;/g,' ') + ' - ' + contenu.dataTag"/>
        </b-col>
        <b-col class="col-6 col-md-12 ad-center-vert">
          <p class="mb-0 ad-surtitre" v-html="contenu.surTitre"></p>
          <p class="graphik_medium ad-titre">
            <span v-html="contenu.titre"></span>
          </p>
          <p v-html="contenu.sousTitre"></p>
          <div class="h-50" v-html="contenu.texte">
          </div>
        </b-col>
      </a>
  </div>
</template>

<script>
export default {
  name: "MediaImageImg70tit30",
  data() {
    return {
    };
  },
  props: ["contenu"],
  methods: {},
  computed: {
    classObject: function () {
      return {
        rounded: this.contenu.coinArrondi
      }
    },
    imageName: function() {
      
      if(this.contenu.bgImage && this.contenu.bgImage.indexOf("jcr:")>0){
        return this.contenu.bgImage.substring(parseInt(this.contenu.bgImage.lastIndexOf("/")+1), this.contenu.bgImage.lastIndexOf('.'));
      } else if( this.contenu.image && this.contenu.image.indexOf("jcr:")>0){
        return this.contenu.image.substring(parseInt(this.contenu.image.lastIndexOf("/")+1), this.contenu.image.lastIndexOf('.'));
      } else {
        return "";
      } 
    },
    styleObject: function () {
      return {
        'background-color': this.contenu.secBgThemeColor
      }
    }
  },
  created: function() {}
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.ad-center-vert {
  margin-bottom: auto;
  margin-top: auto;
  
}
.ad-surtitre {
  color: #000000;
  @include ms-respond(font-size, -1);
}
.ad-titre {
  color: #000000;
  @include ms-respond(font-size, 0);
}
.drop-shadow {
  -webkit-box-shadow: 2px 0px 15px 1px rgba(181,181,181,0.40);
  -moz-box-shadow: 2px 0px 15px 1px rgba(181,181,181,0.40);
  box-shadow: 2px 0px 15px 1px rgba(181,181,181,0.40);
}

</style>