import axios from 'axios'
import { WebServicesAccess } from '../../config';

export default {
  getRoutes (lang) {
	  var path = window.location.pathname;
	  //TODO: Généraliser, pas uniquement pour bingo + prévoir url traduit. Idée: Doit provenir de Magnolia?
	  //	    Prévoir URL auteur et public
	  var page = (path.indexOf('bingoV2') == -1) ? 'bingo' : 'bingoV2';
	  
      return axios
      .get(WebServicesAccess.MAGNOLIA_PATH + "/" + lang + "/json-cache/contenu", {
        params: {
          path: '/espacejeux/' + page,
          nodeType: 'page',
        }
      })
      .then(response => {
        return response.data.results[0];
      })
  },
}