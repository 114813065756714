/* eslint-disable no-inner-declarations */
import Cookies from "./cookies";

// Adaption du code JS de banniereImage.ftl pour ne plus être inline
// TODO optimiser le FTL et JS

let BannerImg = function () {
  const self = this;

  self.init = function () {
    const banners = document.querySelectorAll('[data-component="banniere-image"]');

    for (let i = 0; i < banners.length; i++) {
      const banner = banners[i];
      const bannerId = banner.getAttribute("id");
      const options = document.getElementById(`var${bannerId}`);

      var premierevisite = options.getAttribute("data-premierevisite");
      var cookieName = options.getAttribute("data-cookieName");
      var premiereVue = JSON.parse(options.getAttribute("data-premiereVue"));
      var lsBanniere = `lsBanniere${bannerId}`;
      // eslint-disable-next-line no-undef
      var dateDebut = moment(parseInt(banner.getAttribute("data-dateDebut")));
      // eslint-disable-next-line no-undef
      var dateFin = moment(parseInt(banner.getAttribute("data-dateFin")));
      // eslint-disable-next-line no-undef
      dateDebut = dateDebut.isValid() ? dateDebut : moment().subtract(1, "y");
      // eslint-disable-next-line no-undef
      dateFin = dateFin.isValid() ? dateFin : moment().add(1, "y");

      function appendImage() {
        // eslint-disable-next-line no-undef
        var newElem = $(`#banniereTarget${bannerId}`).replaceWith($(banner).html());

        if (premiereVue) {
          // eslint-disable-next-line no-undef
          $(newElem).attr("watch", lsBanniere);
        }
      }
      function appendImageRemplacement() {
        // eslint-disable-next-line no-undef
        $(`#banniereTarget${bannerId}`).replaceWith($(banner).html());
        localStorage.setItem(lsBanniere, true);
      }

      // eslint-disable-next-line no-undef
      if (moment() >= dateDebut && moment() < dateFin) {
        if (!localStorage.getItem(lsBanniere)) {
          appendImageRemplacement();
        } else if (cookieName !== null) {
          if (Cookies.get(cookieName) !== null) {
            appendImage();
          } else {
            appendImageRemplacement();
          }
        } else if (premiereVue && localStorage.getItem(lsBanniere)) {
          null;
        } else {
          appendImage();
        }
      }
    }
  };
};

BannerImg = new BannerImg();

export default BannerImg;
