var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "div_container",
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _c("b-row", { staticClass: "m-0" }, [
        _vm.contenu.titre
          ? _c(
              "div",
              {
                staticClass: "titre graphik_bold",
                class: [_vm.contenu.image ? "titre-image" : "titre-no-image"],
                style: { "background-color": _vm.contenu.couleurFondTitre },
              },
              [
                _vm.contenu.image
                  ? _c("b-card-img-lazy", {
                      staticClass: "image ml-1",
                      attrs: {
                        src: _vm.getImageSrcVariation(
                          _vm.getImageByScreenSize(_vm.contenu)
                        ),
                        alt: _vm.contenu.titre,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", {
                  class: [_vm.contenu.image ? "ml-1" : "ml-5"],
                  domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "text-container" }, [
          _c("span", {
            staticClass: "texte",
            domProps: { innerHTML: _vm._s(_vm.contenu.texte) },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }