
export default {
	state: {
		accountUserMessages: {},
		productSourceInfo: {},
	},
	getters: {
		getAccountUserMessages: state => {
			return state.accountUserMessages;
		},
		getProductSourceInfo: state => {
			return state.productSourceInfo;
		}

	},
	mutations: {
		updateAccountUserMessages: (state, payload) => {
			state.accountUserMessages = payload;
		},
		updateProductSourceInfo: (state, payload) => {
			state.productSourceInfo = payload;
		},
	},
}