// TODO: update on resize | orientation change

const viewportInfo = {
	orientation: null,
	breakpoint: null,
	breakpointWidth: null
};

const $window = window;

const viewportInfoInit = () => {

	if ("onorientationchange" in window) {
		setOrientation();
	} else {
		if ($window.innerWidth >= $window.innerHeight) {
			viewportInfo.orientation = 'landscape';
		} else {
			viewportInfo.orientation = 'portrait';
		}
	}

	setCurrentBreakpoint();

	$window.addEventListener('resize', debounce(function () {
		if (!"onorientationchange" in window) {
			if ($window.innerWidth >= $window.innerHeight) {
				viewportInfo.orientation = 'landscape';
			} else {
				viewportInfo.orientation = 'portrait';
			}
		}

		setCurrentBreakpoint();
	}, 150));

	$window.addEventListener('orientationchange', function () {
		setOrientation();
	});
}

// The breakpoints should be the same as the CSS breakpoints in bootstrap-variables

const breakpointPrefix = 'breakpoint-';
const breakpoints = {
	'xxs': 0,
	'xs': 360,
	'sm': 576,
	'md': 768,
	'lg': 992,
	'xl': 1200,
	'xxl': 1440,
	'xxxl': 1920
};

// Based on Bootstrap SCSS behaviors.
const breakpointIs = function (breakpoint, size = 'only') {
	const breakpointRequested = breakpoints[breakpoint];

	if (breakpointRequested !== undefined) {
		if (size === 'only') {
			if (breakpoint === viewportInfo.breakpoint) {
				return true;
			} else {
				return false;
			}
		} else if (size === 'up') {
			// From 0 up to the given size
			const breakpointRequestedWidth = breakpointRequested;

			if (viewportInfo.breakpointWidth >= breakpointRequestedWidth) {
				return true;
			} else {
				return false;
			}
		} else if (size === 'down') {
			// Below the given size
			const breakpointRequestedWidth = breakpointRequested;

			if (viewportInfo.breakpointWidth < breakpointRequestedWidth) {
				return true;
			} else {
				return false;
			}
		} else {
			console.warn(`${size} is not a defined size.`);
		}
	} else {
		console.warn(`${breakpoint} is not an official breakpoint.`);
	}
}

const setCurrentBreakpoint = function () {
	var viewportCurrent = Object.keys(breakpoints)[0],
		viewportWidth = window.innerWidth;

	for (const breakpoint in breakpoints) {
		if (breakpoints[breakpoint] <= viewportWidth) {
			viewportCurrent = breakpoint;
		} else {
			break;
		}
	};

	viewportInfo.breakpointWidth = breakpoints[viewportCurrent];
	viewportInfo.breakpoint = viewportCurrent;
}

// 
// DO NOT ADD CSS CLASS FOR ORIENTATION
// Use @media (orientation: landscape) or @media (orientation: portrait)
//

const setOrientation = function (w, h) {
	if (screen.type === 'landscape-primary') {
		viewportInfo.orientation = 'landscape';
	} else {
		viewportInfo.orientation = 'portrait';
	}
}

window.viewportInfo = viewportInfo;
window.viewportInfoInit = viewportInfoInit;
window.breakpointIs = breakpointIs;

export { viewportInfo, viewportInfoInit, breakpointIs };