import { breakpointIs } from "./components/viewport-info.js";
import i18n from "./components/i18n.js";
import EspacejeuxPortail_GrosLots from "./components/espacejeux-portail-gros-lots";

/* ------------------------------------------------------------------------- */
/* Les lots progressifs pour les pastilles des jeux -- Jackpot --            */
/* Cette fonction est declenche seulement si la class  progressif-moyen exist*/
/* ------------------------------------------------------------------------- */
window.initPastilleLotprogressif = function (langue) {
    if ($(".progressif-mock").length > 0) {
        $(".progressif-mock").html(FormatageMontantDuLot("9999999")).css("display", "inline-block");
    } else {
        var hostname = document.location.hostname;
        var newDomain = hostname;

        if (hostname.indexOf(".com") > -1) {
            newDomain = hostname.replace("www.espacejeux", "portail.lotoquebec");
            newDomain = newDomain.replace("m.espacejeux", "portail.lotoquebec");
            newDomain = newDomain.replace("portail.espacejeux", "portail.lotoquebec");
        }

        $.ajax({
            url: "https://" + newDomain + "/json/progressifs-aggregator", ///magnoliaAuthor/espacejeux
            //url: "/json/progressifs-aggregator",  ///magnoliaAuthor/espacejeux
            //cache: false,
            type: "GET",
            dataType: "json",
            // Le "_" permet un caching de 5 secondes au lieu du paramètre cache: false qui est à la millisecondes
            data: { lang: langue },
            async: true, //Important que l'appel soit asyncronne
            success: function (data) {
                affichageLotsProgressifs(data, langue);
                window.initPastilleLotprogressifResult = data;
            },
            error: function (data, ajaxOptions, thrownError) {
                affichageLotsProgressifs({}, langue);
            },
        });
    }
};

window.affichageLotsProgressifs = function (result, langue) {
    if ($(".progressif-bingo").length > 0) {
        traitementLotProgressifPourBingo(result);
    }

    if ($(".progressif").length > 0) {
        traitementLotProgressifSurPastille(result);
    }
    if ($(".zoneMontantMiniBadBeat").length > 0) {
        traitementLotProgressifSurBadBeat(result);
    }
    if ($("#zonePromoGauche").length > 0) {
        traitementLotProgressifSurBigBox(result);
    }
    if ($(".lots-progressifs").length > 0) {
        traitementLotProgressifPortail(result, langue);
    }
    if ($(".remontee-loteries").length > 0) {
        traitementRemonteeLoteries(result, langue);
    }
};

function traitementRemonteeLoteries(result, langue) {
    var dateDiv = ".groslot-info .date",
        i18n = {
            fr: {
                approx: "*Approx.",
                monthsFull: [
                    "janvier",
                    "février",
                    "mars",
                    "avril",
                    "mai",
                    "juin",
                    "juillet",
                    "août",
                    "septembre",
                    "octobre",
                    "novembre",
                    "décembre",
                ],
                weekdaysLong: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
                this: "Ce",
                tonight: "Ce soir",
                guaranteedMillion: "Million garanti",
                guaranteedMillionPlur: "Millions garantis",
                leClassique: "Le classique",
                of: "de",
                frenchDollar: "$",
                prize: "Lot",
                error: "Le montant du prochain gros lot est à venir.",
                lotSupplementaireAlt: "+",
                superTirageLogo: {
                    url: "//gba.lotoquebec.com/avis/lotto-6-49/2022-09-10/images/SuperTirage.png",
                    alt: "Super Tirage",
                },
                bouleOr: "La boule d'or",
                bouleOrLogo: {
                    url: "//gba.lotoquebec.com/avis/lotto-6-49/2022-09-10/images/bouleOr.png",
                    alt: "Lotto 6/49",
                },
                groslotMin: "*ou 1 million",
            },
            en: {
                approx: "*Approx.",
                monthsFull: [
                    "january",
                    "february",
                    "march",
                    "april",
                    "may",
                    "june",
                    "july",
                    "august",
                    "september",
                    "october",
                    "november",
                    "december",
                ],
                weekdaysLong: [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ],
                this: "This",
                tonight: "Tonight",
                guaranteedMillion: "Guaranteed Million",
                guaranteedMillionPlur: "Guaranteed Million",
                leClassique: "The classic",
                of: "of",
                frenchDollar: "",
                prize: "Prize",
                lotSupplementaireAlt: "+",
                error: "The amount of the next jackpot is coming.",
                superTirageLogo: {
                    url: "//gba.lotoquebec.com/avis/lotto-6-49/2022-09-10/images/SuperDraw.png",
                    alt: "Super Draw",
                },
                bouleOr: "The gold ball",
                bouleOrLogo: {
                    url: "//gba.lotoquebec.com/avis/lotto-6-49/2022-09-10/images/bouleOr.png",
                    alt: "Lotto 6/49",
                },
                groslotMin: "*or 1 million",
            },
            get: function (key) {
                return key in i18n[langue] ? i18n[langue][key] : key;
            },
        };

    // faut manipuler le dom pour déplacer la date, parce que les versions téléphone est beaucoup trop différente des autres versions
    if (breakpointIs("md", "up")) {
        $(".date").detach().appendTo(".tirage-info");
        $(".date:last-child").remove();
        dateDiv = ".tirage-info .date";
    }

    var todayDate,
        dateTirage,
        element,
        isToday,
        aujourdhuiTxt,
        thedayTxt,
        thedateTxt,
        mobileNoBR,
        prochainGaranti,
        $prodDiv,
        isSingular,
        approx,
        prochainGrosLotPlusUrl = "/.resources/ej-template-theme/img/portail/lottomax-plus.png",
        isLotto649Blackout = 0,
        isLottoMaxBlackout = 0;

    try {
        // Pour la "date du jour", on prend la date courante (TimeZone client)
        todayDate = new Date();

        $(".tirage").each(function (index, prodDiv) {
            $prodDiv = $(prodDiv);
            element = result.loteriesJackpots[$prodDiv.attr("data-produit")];
            if (typeof element !== "undefined") {
                if ("undefined" !== typeof result.loteriesJackpots.lotto649["EnBlackout"]) {
                    isLotto649Blackout = result.loteriesJackpots.lotto649["EnBlackout"];
                }

                if ("undefined" !== typeof result.loteriesJackpots.lotto649["EnBlackout"]) {
                    isLottoMaxBlackout = result.loteriesJackpots.lottoMax["EnBlackout"];
                }

                $(".groslot-logo .logo img").attr("alt", element.NomProduit);

                try {
                    // On teste si la date est valide: Devrait toujours être valide...
                    var resultat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(element.DateProchainTirage);
                    if (resultat) {
                        var array = element.DateProchainTirage.split(/[^0-9]/);

                        // On créé la date du tirage à partir du feed (Cette date est dans le timeZone client)
                        dateTirage = new Date(array[0], array[1] - 1, array[2], 0, 0, 0);
                        // Compare la portion date uniquement pour savoir si cMest aujourdh'hui
                        isToday = dateTirage.toLocaleDateString() == todayDate.toLocaleDateString();
                        aujourdhuiTxt = i18n.get("tonight");
                        thedayTxt = i18n.get("weekdaysLong")[dateTirage.getDay()];
                        thedayTxt = i18n.get("this") + " " + thedayTxt;

                        thedateTxt =
                            langue === "fr"
                                ? '<span class="strong">' +
                                (dateTirage.getDate() === 1
                                    ? dateTirage.getDate() + "<sup>er</sup>"
                                    : dateTirage.getDate()) +
                                " " +
                                i18n.get("monthsFull")[dateTirage.getMonth()] +
                                " " +
                                dateTirage.getFullYear() +
                                "</span>"
                                : '<span class="strong">' +
                                i18n.get("monthsFull")[dateTirage.getMonth()] +
                                " " +
                                dateTirage.getDate() +
                                ", " +
                                dateTirage.getFullYear() +
                                "</span>";

                        if (breakpointIs("lg", "down")) {
                            if (isLotto649Blackout == 0 && element.NoProduit == 212) {
                                $prodDiv.find(dateDiv).html(thedateTxt);
                            } else if (element.NoProduit == 212) {
                                displayError(element.NoProduit, dateDiv, i18n);
                            }
                            if (isLottoMaxBlackout == 0 && element.NoProduit == 223) {
                                $prodDiv.find(dateDiv).html(thedateTxt);
                            } else if (element.NoProduit == 223) {
                                displayError(element.NoProduit, dateDiv, i18n);
                            }
                        } else {
                            mobileNoBR = "<br>"; // en mobile, le "ce soir" est sur la même ligne
                            if (isLotto649Blackout == 0 && element.NoProduit == 212) {
                                $prodDiv
                                    .find(dateDiv)
                                    .html(
                                        isToday
                                            ? aujourdhuiTxt + mobileNoBR + thedateTxt
                                            : thedayTxt + mobileNoBR + thedateTxt
                                    );
                            } else if (element.NoProduit == 212) {
                                displayError(element.NoProduit, dateDiv, i18n);
                            }
                            if (isLottoMaxBlackout == 0 && element.NoProduit == 223) {
                                $prodDiv
                                    .find(dateDiv)
                                    .html(
                                        isToday
                                            ? aujourdhuiTxt + mobileNoBR + thedateTxt
                                            : thedayTxt + mobileNoBR + thedateTxt
                                    );
                            } else if (element.NoProduit == 223) {
                                displayError(element.NoProduit, dateDiv, i18n);
                            }
                        }

                        $prodDiv.find(dateDiv).show();
                    }

                    prochainGaranti = mobileNoBR;

                    // 649
                    if (element.NoProduit == 212 && isLotto649Blackout == 0) {
                        $prodDiv
                            .find(".groslot-valeur")
                            .html(
                                '<span class="texte"><strong>' +
                                i18n.get("leClassique").toUpperCase() +
                                ' </strong></span><span class="valeur"><strong>' +
                                element.ProchainGrosLotTirageClassique["value"] / 1000000 +
                                " Million" +
                                i18n.get("frenchDollar") +
                                "</strong></span>"
                            );

                        if (element.NbProchainLotsGarantisAutre > 0) {
                            $prodDiv
                                .find(".superTirage")
                                .html(
                                    '<img class="img-superTirage" src="' +
                                    i18n.get("superTirageLogo")["url"] +
                                    '" alt="' +
                                    i18n.get("superTirageLogo")["alt"] +
                                    '">'
                                );
                        }
                        if (element.ProchainGrosLotBouleOr) {
                            $prodDiv
                                .find(".groslot-boule-or")
                                .html(
                                    '<div><img class="bouleOr" src="' +
                                    i18n.get("bouleOrLogo")["url"] +
                                    '" alt="' +
                                    i18n.get("bouleOrLogo")["alt"] +
                                    '"></div><div><span>' +
                                    i18n.get("bouleOr").toUpperCase() +
                                    '</span><br/><span class="valeur"><strong>' +
                                    element.ProchainGrosLotBouleOr["value"] / 1000000 +
                                    " Million" +
                                    i18n.get("frenchDollar") +
                                    "<sup>*</sup></strong></span></div>"
                                );
                        }
                        $prodDiv.find(".groslot-approx").html(i18n.get("groslotMin"));
                    } else if (element.NoProduit == 223 && isLottoMaxBlackout == 0) {
                        // LottoMax

                        $prodDiv
                            .find(".groslot-valeur")
                            .html(
                                element.ProchainGrosLot["value"] / 1000000 +
                                " Million" +
                                i18n.get("frenchDollar") +
                                "<sup>*</sup>"
                            );

                        if (
                            element.NbProchainLotSupplementaire &&
                            element.NbProchainLotSupplementaire["value"] > 0
                        ) {
                            approx = element.NbProchainLotSupplementaire["approximatif"] === 1 ? true : false;
                            isSingular = element.NbProchainLotSupplementaire["value"] === 1;
                            prochainGaranti =
                                '<img class="" src="' +
                                prochainGrosLotPlusUrl +
                                '" alt="' +
                                i18n.get("lotSupplementaireAlt") +
                                '">';
                            prochainGaranti += element.NbProchainLotSupplementaire["value"] + " ";
                            prochainGaranti +=
                                i18n.get("prize") +
                                (isSingular ? "" : "s") +
                                " " +
                                i18n.get("of") +
                                " 1 Million" +
                                (approx ? "<sup>*</sup>" : "");
                            $prodDiv.find(".groslot-plus").html(prochainGaranti);
                        } else {
                            if (
                                element.NbProchainLotSupplementaire &&
                                element.NbProchainLotSupplementaire["value"] === 0
                            ) {
                                $prodDiv.find(".groslot-plus").css("display", "none");

                                // si pas de lot supplémentaire, on ajoute de l'espace en haut du groslot en mobile
                                if (breakpointIs("lg", "down")) {
                                    $prodDiv.find(".groslot-valeur").css("padding-top", "1.7vw");
                                } else {
                                    $prodDiv.find(".groslot-valeur").css("margin-top", "0"); // centré verticalement
                                }
                            }
                        }
                        $prodDiv.find(".groslot-approx").html(i18n.get("approx"));
                    }
                } catch (e) {
                    console.log("displayreeor catch 1:" + e);
                    displayError(element.NoProduit, dateDiv, i18n);
                }
            }
        });
    } catch (e) {
        console.log("displayreeor catch 2:" + e);
        displayError(212, dateDiv, i18n);
        displayError(223, dateDiv, i18n);
    }

    $(".logo-loterie img").css("display", "inline-block");
}

function displayError(prod, dateDiv, i18n) {
    var $prodDiv = $(".prod" + prod);
    $prodDiv.find(dateDiv).html("");
    $prodDiv.find(".groslot-approx").css("display", "none");
    $prodDiv.find(".date").css("display", "none");
    $prodDiv.find(".groslot-plus").css("display", "none");
    $prodDiv.find(".groslot-valeur").css("display", "none");
    $prodDiv.find(".groslot-avenir").html(i18n.get("error"));
    $prodDiv.find(".groslot-avenir").css("display", "block");
    $prodDiv.find(".groslot-info-wrap").css("height", "41%");
}

function traitementLotProgressifPortail(result, langue) {
    var $innerSelf, lotProgressif, jsonData, numlot, thislot, montant;

    var $lotProgressifDivs = $(".lots-progressifs .lot-progressif");

    $lotProgressifDivs.each(function () {
        $innerSelf = $(this);
        numlot = $innerSelf.data("lot") + "";
        thislot = "";

        var sResult = undefined;

        if (result.casinoJackpots !== undefined && result.casinoJackpots[numlot] !== undefined) {
            sResult = result.casinoJackpots[numlot];
        } else if (
            result.badBeatJackpots !== undefined &&
            result.badBeatJackpots[numlot] !== undefined
        ) {
            sResult = result.badBeatJackpots[numlot];
        } else if (result.bingoJackpots !== undefined && result.bingoJackpots[numlot] !== undefined) {
            sResult = result.bingoJackpots[numlot];
        } else if (
            result.loteriesJackpots !== undefined &&
            result.loteriesJackpots[numlot] !== undefined
        ) {
            sResult = result.loteriesJackpots[numlot];
        } else if (
            result.bbjCasinosTerrestres !== undefined &&
            result.bbjCasinosTerrestres[numlot] !== undefined
        ) {
            sResult = result.bbjCasinosTerrestres[numlot];
        }

        if (sResult !== undefined) {
            montant = parseFloat(sResult.jackpot);
            afficherLot(montant, $innerSelf, langue);
            if ("undefined" !== typeof sResult.dateHeure) {
                assignerDateHeure(new Date(sResult.dateHeure), $innerSelf, langue);
            }
            thislot = numlot;
        } else {
            if ("en" === langue) {
                $innerSelf.find("div.error").html("The amount of the next jackpot is coming.");
            } else {
                $innerSelf.find("div.error").html("Le montant du prochain gros lot est à venir.");
            }
        }
    });
}

function assignerDateHeure(date, $theDiv, langue) {
    var $theDiv = $theDiv.find(".date-heure");
    if ($theDiv != undefined) {
        var heureCourante = new datePrepared($theDiv, date, langue);
        heureCourante.div.html(dateInText(heureCourante, langue));
    }
}

function dateInText(thedate, langue) {
    var i18n = {
        fr: {
            monthsFull: [
                "janvier",
                "février",
                "mars",
                "avril",
                "mai",
                "juin",
                "juillet",
                "août",
                "septembre",
                "octobre",
                "novembre",
                "décembre",
            ],
        },
        en: {
            monthsFull: [
                "january",
                "february",
                "march",
                "april",
                "may",
                "june",
                "july",
                "august",
                "september",
                "october",
                "november",
                "december",
            ],
        },
        get: function (key) {
            return key in i18n[langue] ? i18n[langue][key] : key;
        },
    };
    var theDate =
        langue == "fr"
            ? thedate.dateLocalized +
            " " +
            i18n.get("monthsFull")[thedate.date.getMonth()] +
            " " +
            thedate.date.getFullYear() +
            " " +
            thedate.date.getHours() +
            " h " +
            thedate.padMinutes
            : i18n.get("monthsFull")[thedate.date.getMonth()] +
            " " +
            thedate.dateLocalized +
            ", " +
            thedate.date.getFullYear() +
            " " +
            thedate.hours +
            ":" +
            thedate.padMinutes +
            " " +
            thedate.suffix;

    return theDate;
}

function datePrepared($idDiv, date, langue) {
    this.div = $idDiv;
    this.date = date;
    this.minutes = date.getMinutes().toString();
    this.pad = "00";
    this.padMinutes = this.pad.substring(0, this.pad.length - this.minutes.length) + this.minutes;
    this.dateLocalized = formatMyDate(this.date.getDate(), langue);
    this.suffix = this.date.getHours() >= 12 ? "P.M." : "A.M.";
    this.hours = ((this.date.getHours() + 11) % 12) + 1;
}

function formatMyDate(date, lang) {
    var dateFormatted;

    if (lang == "fr") {
        switch (date) {
            case 1:
                dateFormatted = date.toString() + "ier";
                break;
            default:
                dateFormatted = date;
                break;
        }
    } else {
        switch (date) {
            default:
                dateFormatted = date.toString();
                break;
        }
    }
    return dateFormatted;
}

function afficherLot(montant, $innerSelf, langue) {
    var theNumDisplay = formatToCurrency(montant, langue).trim(),
        constrStr = "";

    theNumDisplay.split("").forEach(function (c) {
        if (isNumber(c)) {
            constrStr += '<span class="lot-valeur-num">' + c + "</span>";
        } else {
            if (c.indexOf("$") !== -1) {
                constrStr += '<span class="dollarsign ' + langue + '">' + c + "</span>";
            } else {
                if (c.indexOf(" ") !== -1) {
                    constrStr += '<span class="lot-valeur-num-espace">&nbsp;</span>';
                } else {
                    constrStr += c;
                }
            }
        }
    });
    constrStr += '<span class="approx ' + langue + '"> APPROX.</span>';
    //$(".MontantLot-" + numeroLot).html(constrStr); //BUG si numeroLot contient ":""
    $innerSelf.find("div.amount").html(constrStr);
}

function formatToCurrency(num, langue) {
    if (num == undefined) {
        num = "0";
    }

    num = num.toString().replace(/\$|\,/g, "");

    if (isNaN(num)) {
        num = "0";
    }
    var sign = num == (num = Math.abs(num));
    num = Math.floor(num * 100 + 0.50000000001);
    var cents = num % 100;
    num = Math.floor(num / 100).toString();

    if (cents < 10) cents = "0" + cents;
    if (langue == "fr") {
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
            num =
                num.substring(0, num.length - (4 * i + 3)) + " " + num.substring(num.length - (4 * i + 3));
        }
        // return (((sign) ? '' : '-') + num + ',' + cents + ' $');
        return (sign ? "" : "-") + num + " $";
    } else {
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
            num =
                num.substring(0, num.length - (4 * i + 3)) + "," + num.substring(num.length - (4 * i + 3));
        }
        // return (' $' + ((sign) ? '' : '-') + num + '.' + cents);
        return "$" + (sign ? "" : "-") + num;
    }
}

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/* Ancien Poker */
function traitementLotProgressifSurBadBeat(result) {
    if (result.badBeatJackpots["BadBeat"] !== undefined) {
        var txthtml = EspacejeuxPortail.CurrencyFormatted(result.badBeatJackpots["BadBeat"].jackpot);
        txthtml = $("body.fr")[0]
            ? txthtml.replace(/,/g, " ").replace(/\./g, ",") + " $"
            : "$" + txthtml;
        //Affichage du montant du lot
        $(".zoneMontantMiniBadBeat").html(txthtml).css("display", "inline-block");
    }
}

/* Nouveau Poker */
/* TODO: Poker */
function traitementLotProgressifSurBBJPoker(result) {
    if (result.pokerJackpots["BadBeat"] !== undefined) {
        var txthtml = EspacejeuxPortail.CurrencyFormatted(result.badBeatJackpots["BadBeat"].jackpot);
        txthtml = $("body.fr")[0]
            ? txthtml.replace(/,/g, " ").replace(/\./g, ",") + " $"
            : "$" + txthtml;
        //Affichage du montant du lot
        $(".zoneMontantMiniBadBeat").html(txthtml).css("display", "inline-block");
    }
}

function traitementLotProgressifSurPastille(result) {
    var progressifList;
    var txthtml;
    var jackpot;
    var progressifDiv = $(".progressif");
    var traductionJackpotIndividuel = $("body.fr")[0]
        ? "Progressif Individuel"
        : "Individual Jackpot";

    $.each(progressifDiv, function (index, elem) {
        progressifList = $(elem).attr("class").match(/[^ ]+/g);

        $.each(progressifList, function (ind, clazz) {
            txthtml = "";
            if (
                clazz !== "progressif-moyen" &&
                clazz !== "progressif" &&
                clazz !== "progressif-grand" &&
                clazz !== "info" &&
                clazz !== "progressif-BBJ_TREMBLANT"
            ) {
                if (clazz == "progressif-individuel") {
                    txthtml = "<div class='fleft'>" + traductionJackpotIndividuel + "</div>";
                    $("." + clazz)
                        .html(txthtml)
                        .css("display", "block");
                }
                // Il existe un lot à afficher
                else {
                    //pour alignement du libellé "Actuellement à"
                    var moduloLengthNumber = 0;
                    //lot venant des jeux casino
                    if (result !== undefined && !$.isEmptyObject(result)) {
                        var sResult = undefined,
                            srjackpot = undefined;

                        //Exception pour la pastille de jeu lottoPoker avec id = lotto_poker_instants
                        if (clazz === "lotto_poker_instants" || clazz === "lotto_poker_instants_mob") {
                            clazz = "lottoPoker";
                        }

                        if (result.casinoJackpots[clazz] !== undefined) {
                            sResult = result.casinoJackpots[clazz];
                        } else if (
                            result.badBeatJackpots !== undefined &&
                            result.badBeatJackpots[clazz] !== undefined
                        ) {
                            sResult = result.badBeatJackpots[clazz];
                        } else if (
                            result.bingoJackpots !== undefined &&
                            result.bingoJackpots[clazz] !== undefined
                        ) {
                            sResult = result.bingoJackpots[clazz];
                        } else if (
                            result.loteriesJackpots !== undefined &&
                            result.loteriesJackpots[clazz] !== undefined
                        ) {
                            sResult = result.loteriesJackpots[clazz];
                        } else if (
                            result.bbjCasinosTerrestres !== undefined &&
                            result.bbjCasinosTerrestres[clazz] !== undefined
                        ) {
                            sResult = result.bbjCasinosTerrestres[clazz];
                        }

                        if (sResult !== undefined) {
                            if ($(elem).attr("data-progressif-second")) {
                                if (
                                    sResult.grosLots !== undefined &&
                                    sResult.grosLots !== null &&
                                    sResult.grosLots !== "" &&
                                    sResult.grosLots.length > 0 &&
                                    sResult.grosLots[1] !== undefined &&
                                    sResult.grosLots[1] !== null &&
                                    sResult.grosLots[1] !== ""
                                ) {
                                    srjackpot = sResult.grosLots[1].montant;
                                } // ici, la variable srjackpot pourrait rester "undefined"
                            } else {
                                srjackpot = sResult.jackpot;
                            }
                        }
                        if (sResult !== undefined && srjackpot !== undefined) {
                            // Si OK Poker, on affiche 70% du progressif
                            if (["BAD_BEAT", "HIGH_HAND"].includes(sResult.type)) {
                                txthtml = FormatageMontantDuLot((srjackpot * 70) / 100, true);
                            } else {
                                txthtml = FormatageMontantDuLot(srjackpot, true);
                            }
                            if ($(elem).parents(".pubBody").length > 0) {
                                lotProgressifCarrousel($("." + clazz), srjackpot);
                            }
                            moduloLengthNumber = Math.floor(srjackpot).toString().length % 3;
                        } else {
                            txthtml = FormatageMontantDuLot();
                        }
                    } else {
                        //Sinon on affiche infobulle de non disponibilité pour casino
                        //ou ont cache la bannière du carrousel + promos gonflables
                        txthtml = FormatageMontantDuLot();
                        if ($(elem).parents(".pubBody").length > 0) {
                            lotProgressifCarrousel($("." + clazz));
                        }
                    }

                    //Affichage du montant du lot
                    $(elem).html(txthtml);

                    if (typeof tooltip == "function") {
                        $(".tooltip-item-progressif").tooltip();
                    }

                    /*********************************************************/
                    /* Gymnastique pour aligner le libellé "Actuellement à"  */
                    /* avec le montant du lot progresssif.                   */
                    /*********************************************************/
                    var libelleActuel = $(".progressif-libelle-actuel-" + clazz);
                    if (libelleActuel.length > 0) {
                        var posProgressif = $(elem).position();
                        var leftPos = $("body.fr")[0]
                            ? posProgressif.left + 3 + "px"
                            : posProgressif.left + 43 + "px";
                        if (moduloLengthNumber === 0) {
                            leftPos = $("body.fr")[0]
                                ? posProgressif.left + 10 + "px"
                                : posProgressif.left + 52 + "px";
                        }
                        var libellePresent = $(elem).prev().attr("class").match("/progressif-libelle-actuel/g");
                        if (libellePresent !== undefined && libellePresent !== null && libellePresent !== "") {
                            $(elem)
                                .prev()
                                .css({
                                    position: "absolute",
                                    top: posProgressif.top - 10 + "px",
                                    left: leftPos,
                                })
                                .show();
                        }
                    }
                }
            }
        });
    });
}

function traitementLotProgressifPourBingo(result) {
    //utilisé le json result pour afficher les lots dans les pastilles
    var txthtml = "";
    var progressifList;
    var progressifDiv = $(".progressif-xpetit");

    if (typeof progressifDiv !== "undefined") {
        $.each(progressifDiv, function (index, elem) {
            progressifList = $(elem).attr("class").match(/[^ ]+/g);
            $.each(progressifList, function (ind, clazz) {
                if (clazz !== "progressif-xpetit" && clazz !== "progressif-bingo") {
                    txthtml = FormatageMontantDuLot(result.bingoJackpots[clazz].jackpot);
                    //Affichage du montant du lot
                    $(elem).html(txthtml);
                    //enregistrement de l'evenement s'il y a lieu
                    if (typeof tooltip == "function") {
                        $(".tooltip-item-progressif").tooltip();
                    }
                }
            });
        });
    }
}

window.FormatageMontantDuLot = function (montant, exactDigit, msgNoJackpot) {
    var txthtml = "";
    var msg =
        $("html:lang(fr)").length > 0
            ? "Le lot progressif est offert, mais le système ne peut afficher le montant à gagner en ce moment."
            : "The progressive jackpot is available, but the system is currently unable to display the amount to be won.";

    if (msgNoJackpot != undefined) {
        msg = msgNoJackpot;
    }

    var dollarFr = $("html:lang(fr)").length > 0 ? "<span class='currency'>$</span>" : "";
    var dollarEn = $("html:lang(en)").length > 0 ? "<span class='currency'>$</span>" : "";

    if (typeof montant !== "undefined") {
        var montantFormat =
            $("html:lang(fr)").length > 0
                ? Math.floor(montant)
                : formatageDeviseAnglophone(Math.floor(montant) + "");
        txthtml =
            dollarEn +
            EspacejeuxPortail_GrosLots.htmlMontantDuLotExactDigit(montantFormat, exactDigit) +
            dollarFr;
    } else {
        txthtml = EspacejeuxPortail_GrosLots.htmlMontantNonDisponible(msg);
    }
    return txthtml;
};

function formatageDeviseAnglophone(value) {
    var temps = "";
    //console.log("Montant value : " + value);
    for (var i = value.length - 1; i >= 0; i--) {
        temps += value.charAt(i);
        if ((value.length - i) % 3 == 0 && i > 0) temps += ",";
    }
    //console.log("Montant temps : " + temps);
    return reverseString(temps);
}

function reverseString(str) {
    var stringRev = "";
    for (var i = 0; i < str.length; i++) {
        stringRev = str[i] + stringRev;
    }
    //console.log("Montant reverse string : " + stringRev);
    return stringRev;
}

function traitementLotProgressifSurBigBox(result) {
    var jsonProgressifs = triProgressifDesc(result);

    //si jsonObj vide
    if ($.isEmptyObject(jsonProgressifs) || jsonProgressifs.length == 0) {
        $("div#zoneMontantLotProgressif").css("display", "none");
        $("div#remplacementImageLotsProgressif").css("display", "block");
    } else {
        $("div#remplacementImageLotsProgressif").css("display", "none");
        $("div#zoneMontantLotProgressif").css("display", "block");
        affichageLotBigBox(jsonProgressifs);
    }
}

/****************************
 Tri progressif en ordre décroissant
***************************** */
function triProgressifDesc(result) {
    var jsonObj = [];
    for (var categorieProgressif in result) {
        for (var progressif in result[categorieProgressif]) {
            if (
                result[categorieProgressif][progressif].actif &&
                result[categorieProgressif][progressif].indInternet == "true"
            ) {
                jsonObj.push(result[categorieProgressif][progressif]);
            }
        }
    }

    jsonObj.sort(function (a, b) {
        return b.jackpot - a.jackpot;
    });

    return jsonObj;
}

function affichageLotBigBox(jsonObj) {
    var BLOCK = 3,
        mod = 0,
        divi = 0,
        txt = "";
    var jsTaille = jsonObj.length;
    var nbPages = parseInt(jsTaille / BLOCK) + (jsTaille % BLOCK != 0 ? 1 : 0);
    var iconeProgressif = "",
        titreProgressif = "",
        lienProgressif = "",
        nomCookie = "ejCook";

    var title = $("body.fr")[0] ? "Lot progressif" : "Jackpot";
    //verification de la taille du plus gros progressif
    var exactChiffre = false;
    var cssClass = "";
    if (jsTaille > 0) {
        if (Math.floor(jsonObj[0].jackpot) >= 10000000) {
            exactChiffre = 8;
            cssClass = "groslot"; //"fright";
        }
    }

    for (var i = 0; i < jsTaille; i++) {
        mod = i % BLOCK;
        divi = parseInt(i / BLOCK);
        if (mod == 0) {
            txt +=
                "<div id='zoneBlock" +
                (divi + 1) +
                "'" +
                (i > BLOCK - 1 ? " style='display:none;'" : " ") +
                ">";
        }
        iconeProgressif = jsonObj[i].icone;
        titreProgressif = jsonObj[i].titreComplet;
        lienProgressif = escape(jsonObj[i].lien);

        if (jsonObj[i].iconeProgressif) {
            iconeProgressif = jsonObj[i].iconeProgressif;
            if (jsonObj[i].titreProgressif) {
                titreProgressif = jsonObj[i].titreProgressif;
            } else {
                titreProgressif = jsonObj[i].idProgressifPrincipal;
            }
            if (lienProgressif.substr(-1) == "/") {
                lienProgressif = lienProgressif.substr(0, lienProgressif.length - 1);
            }

            lienProgressif = escape(jsonObj[i].lien.substring(0, lienProgressif.lastIndexOf("/")));

            /* TODO: Optimisation */
            /*
                  //TODO : changer ceci -STEPH
                  nomCookie = getNomCookieFromPath(lienProgressif);
                  */
        }
        txt += "<div class='zoneMontantLot col-12'>";
        if (jsonObj[i].iconeProgressif) {
            txt +=
                "<a href='javascript:EspacejeuxPortail_GrosLots.pageJeuxProgressif_filtre(\"" +
                nomCookie +
                '", "' +
                jsonObj[i].idProgressifPrincipal +
                "\");'>";
        }
        txt +=
            "<div class='zoneIcone'><img class='img-responsive' src='" +
            iconeProgressif +
            "'></div></a>" +
            "<div class='contenu_lot'>" +
            "<div class='lot_nom'>" +
            titreProgressif;

        txt +=
            "</div><div class='lot_montant " +
            cssClass +
            "'>" +
            FormatageMontantDuLot(Math.floor(jsonObj[i].jackpot), exactChiffre) +
            "</div></div></div>";

        if (mod == BLOCK - 1 || i == jsTaille - 1) {
            txt += "</div>";
        }
    }

    if (nbPages > 1) {
        $("div#navPageNext").removeClass("navLotsNext").addClass("navLotsNextActive");
    }
    $("div#navPageLotProg span#nbPages").html(" / " + nbPages);
    $("div#navPageLotProg span#numPages").html("1");

    $("div#montantProgressif").empty().html(txt);
    $("div#zoneMontantLotProgressif").css("display", "inline-block");
    //enregistrement de l'evenement s'il y a lieu
    $ej("div.tooltip-item").tooltip();
    //attacher evenements de navigation pour les lots
    var numPage = $("div#navPageLotProg span#numPages").html();
    $("div#navPageNext").click(function () {
        if (numPage < nbPages) {
            numPage++;
            $("div#zoneBlock" + (numPage - 1)).css("display", "none");
            $("div#zoneBlock" + numPage).css("display", "block");

            $("div#navPageLotProg span#numPages").html(numPage);
            $("div#navPagePrev").removeClass("navLotsPrev").addClass("navLotsPrevActive");

            if (numPage == nbPages) {
                $("div#navPageNext").removeClass("navLotsNextActive").addClass("navLotsNext");
            }
        }
    });
    $("div#navPagePrev").click(function () {
        if (numPage > 1) {
            numPage--;
            $("div#zoneBlock" + (numPage + 1)).css("display", "none");
            $("div#zoneBlock" + numPage).css("display", "block");
            $("div#navPageLotProg span#numPages").html(numPage);
            $("div#navPageNext").removeClass("navLotsNext").addClass("navLotsNextActive");
            if (numPage == 1) {
                $("div#navPagePrev").removeClass("navLotsPrevActive").addClass("navLotsPrev");
            }
        }
    });
    /*if ((typeof singleJackpotType != 'undefined') && (singleJackpotType == true)) {
          Cufon.replace('div.lot_nom', { hover: true, fontFamily: 'Myriad Pro Semibold', color: '#FFFFFF'});
      } else {
          Cufon.replace('div.lot_nom', { hover: true, fontFamily: 'Myriad Pro' });
          Cufon.replace('div.lotsProgressifs', { hover: true, fontFamily: 'Myriad Pro Semibold', color: '#FFFFFF'});
      }
      $('.cufon').css('visibility', 'visible');*/
}
