<template>
  <div :data-component-name="$options.name" class="card media-component"
   :class="[((isBingo)?'carrousel-bingo':''),contenu.shadow&&contenu.shadow!='false'?'drop_shadow':'',contenu.coinArrondi,contenu.cssClass,classObject]" 
   :style="[{'border-color':contenu.borderColor},{'background-size':contenu.backgroundType},styleObject]">
    <div v-if="contenu.formatAffichage" class="content ta-left container_media h-100" :info-format-affichage="contenu.formatAffichage"
    :class="[contenu.formatAffichage + '_container']"
    :ga-img-name="imageName" 
    :data-tag="'MediaImage' +' '+ contenu.GaLabel +' ' + contenu.formatAffichage.charAt(0).toUpperCase() + contenu.formatAffichage.slice(1) + '-' +contenu.dataTag">
        <component v-bind:is="'MediaImage' + contenu.formatAffichage.charAt(0).toUpperCase() + contenu.formatAffichage.slice(1)"                  
                  :key="contenu['@id']"
                  :contenu="contenu">
        </component>
      <div class="text-sizer"></div>
    </div>
  </div>
</template>

<script>
import MediaImageTexteSurImage from "./MediaImageTexteSurImage.vue";
import MediaImageImg60txt40 from "./MediaImageImg60txt40.vue";            // Accueil fun fact
import MediaImageImg50tit10txt40 from "./MediaImageImg50tit10txt40.vue";  // Accueil Pourquoi jouer?
import MediaImageTit25img50txt25 from "./MediaImageTit25img50txt25.vue";  // Offres du moment
import MediaImageImg70tit30 from "./MediaImageImg70tit30.vue";            // À découvrir
import MediaImageOffreSpecial from "./MediaImageOffreSpecial.vue";        // Offre Spéciale (haut)
import MediaImageImg90tit10 from "./MediaImageImg90tit10.vue";            // Offre Spéciale (bas)
import MediaImageIcoTitTxt from "./MediaImageIcoTitTxt.vue";  	  // Jeux
import MediaImageGagnantPromo from "./MediaImageGagnantPromo.vue";        // Gagnant Promo
import MediaImageJouerMaintenant from "./MediaImageJouerMaintenant.vue";  // Jouer Maintenant
import MediaImageGif from "./MediaImageGif.vue";

// Note: Ajouter le composant dans "components" ci-après

export default {
  name: "MediaImage",
  components: {
    MediaImageTexteSurImage,
    MediaImageImg60txt40,
    MediaImageImg50tit10txt40,
    MediaImageTit25img50txt25,
    MediaImageImg70tit30,
    MediaImageOffreSpecial,
    MediaImageImg90tit10,
    MediaImageIcoTitTxt,
    MediaImageGagnantPromo,
    MediaImageJouerMaintenant,
    MediaImageGif,
  },
  data() {
    return {
      isBingo: (window.location.href.indexOf("/bingo/") > 0)
    };
  },
  props: ["contenu"],
  methods:{},
  computed: {
    classObject: function () {
      return {
        //rounded: this.contenu.coinArrondi
      }
    },
    styleObject: function () {
      return {
        'background-color': this.contenu.fondCouleur != 'defaut' ? this.contenu.secBgThemeColor : null,
        'background-image': 'url(' + this.getImageSrcVariation(this.contenu.bgImage) + ')',
        'background-repeat': 'no-repeat',
        'background-position' : 'left center',
        'overflow':'visible', 
      }
    },
    imageName: function () {
        if(this.contenu.bgImage && this.contenu.bgImage.length>0){
          return this.contenu.bgImage.substring(parseInt(this.contenu.bgImage.lastIndexOf("/")+1), this.contenu.bgImage.lastIndexOf('.'));
        }
        else if(this.contenu.image && this.contenu.image.length>0){
          return this.contenu.image.substring(parseInt(this.contenu.image.lastIndexOf("/")+1), this.contenu.image.lastIndexOf('.'));
        }
        else{
          return "";
        }
    },
  },
  created: function() {}
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}

.theme-bg-bingo{
.container_media{
  display: flex; 
  //justify-content: center;
  flex-direction: column;
  //height:100% ;
  overflow:visible;
  justify-content: center;
  // si on veut le centrer par rapport à la div *problème avec l'ouverture de l'entrevue dans la page animateur 
  //align-items:center;
  //height: 100%;
}}
.noBorder{
  border:none; 
}

.coin_haut{
  border-top-left-radius: ms(3); 
  border-top-right-radius: ms(3);
}

.coin_bas{
  border-bottom-left-radius: ms(3); 
  border-bottom-right-radius: ms(3);
}

.quatreCoin{
  border-radius: ms(2); 
}

.theme-bg-bingo{
  .margin_1_mediaImage{
    margin-top:15%;
  }
  .margin_2_mediaImage{
    margin-top:30%;
  }
}
@media screen and (max-width:768px){
  // si on mets des marges pour avoir les components en cascade on mets les marge à 0 içi quand ça tombe en mobile. 
  .theme-bg-bingo{
  .margin_1_mediaImage{
    margin-top:0;
  }
  .margin_2_mediaImage{
    margin-top:0;
  }
}
}


.square_container{
  //defini pour les tit25img50txt25
  width: 95%;
  padding-bottom: 95% ;

}

.tit25img50txt25_container{
  // positionne le contenu pour les composantes suivantes
    position: absolute !important;
    height: 100%;
    /* bottom: 0; */
    left: 0;
    top: 0;
}

.drop_shadow {
  box-shadow: 1px 1px 20px -10px #555
}

.no_border{
  border: 0px !important;
}

.h-98{
  height: 98% !important;
}

.h-340px{
  height: 340px !important;
}

.h-380px{
  height: 380px !important;
  @include media-breakpoint-down(sm)  { 
    height: 300px !important;
  }
}

.card {
  border : none;
}

@include media-breakpoint-down(sm) {
  .no_rounded_corner_sm{
    border-radius: unset;
  }
}


.center_image_offres{
  @media (orientation: portrait ) {
    @include media-breakpoint-down(sm) {
      background-position-y : -75px !important;
      // background-position-x: 20px !important;
    }
    @include media-breakpoint-only(md) {
      background-position-y : -75px !important;
      // background-position-x: 10px !important;
    }
  }

  @media (orientation: landscape ) {
    @include media-breakpoint-down(md) {
      background-position-y : -75px !important;
      // background-position-x: 10px !important;
    }
  }
}


</style>