<template>
  <div :data-component-name="$options.name" class="div_container" >
    <div class="titre">
      <b-card-img-lazy
      :src="getImageSrcVariation(getImageByScreenSize(contenu))"
      :alt="contenu.title"
      class="image"
      ></b-card-img-lazy>
      {{contenu.title}}
    </div>

    <div><span v-html="contenu.texte"></span></div>
  </div>
</template> 

<script>
  import {WebServicesAccess} from '../../config';
 
  export default {
    name:"MediaTexte",
    data(){
        return{
        }
    },
    props: ["contenu"],
    methods:{
    },
    created: function () {
	}
  }
</script>

<style lang="scss" scoped>
	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
	}

  .titre{
    color:black; 
    @include ms-respond(font-size, 0);
    background-color: greenyellow; 
    border-top-left-radius: ms(2);
    border-bottom-right-radius:ms(2);
    display:inline;
  }

  .image{
    height:25%;
    width:25%;
  }
  .div_container{
    background-color: lightgray; 

  }
</style>