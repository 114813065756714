var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isListeLoaded
    ? _c(
        "div",
        [
          _vm._l(_vm.getJeuxListeFiltree, function (jeu, index) {
            return _c("casino-jeu", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.montrerCasinoJeu(jeu),
                  expression: "montrerCasinoJeu(jeu)",
                },
              ],
              key: jeu.idJeu,
              attrs: { jeu: jeu, "prefixe-url": _vm.prefixeUrl },
            })
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.$store.state.casino.jeux.recherche.length <
                    _vm.nbColumn,
                  expression:
                    "$store.state.casino.jeux.recherche.length < nbColumn",
                },
              ],
              staticClass: "row col-12",
            },
            [
              _c("div", { staticClass: "col-12 text-center jeuListeNb" }, [
                _vm._v("\n      " + _vm._s(_vm.jeuListeNb) + "\n      "),
                _vm.mixinLangue_langue == "en"
                  ? _c("span", [
                      _vm._v(" game"),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.jeuListeNb > 1,
                              expression: "jeuListeNb > 1",
                            },
                          ],
                        },
                        [_vm._v("s")]
                      ),
                    ])
                  : _c("span", [
                      _vm._v(" jeu"),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.jeuListeNb > 1,
                              expression: "jeuListeNb > 1",
                            },
                          ],
                        },
                        [_vm._v("x")]
                      ),
                    ]),
              ]),
            ]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }