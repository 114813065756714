<template>
  <div class="cedTablePremiereOuverte">
    <ced-table
      v-if="idTable"
      v-cloak
      :famille="familleJeu"
      :id-table-app="idTable"
      :is-table-premiere-ouverte="true"
      :table-live-premiere-ouverte="tableLive"
      :table-live-info-premiere-ouverte="tableLiveInfo"
      :appareil="appareil"
      :prefixe-url="prefixeUrl"
    >
    </ced-table>
  </div>
</template>

<script>
import axios from "axios";

import { mixinCED } from "../../mixins/mixinCED";
import { mixinLangue } from "../../mixins/mixinLangue";

import CedTable from "./CedTable.vue";

export default {
  components: {
    "ced-table": CedTable
  },
  mixins: [mixinCED, mixinLangue],

  props: {
    familleJeu: {
      type: String,
      required: true
    },
    appareil: {
      type: String,
      required: true
    },
    prefixeUrl: {
      type: String,
      required: true
    }
  },

  data: function() {
    return {
      idTable: null,
      tableListe: null,
      tableLive: null,
      tableLiveInfo: null,
      refreshCount: 0
    };
  },

  created: function() {
    this.chargerTableListe(this.familleJeu);
  },

  mounted: function() {
    var self = this;
    this.$bus.$on("table-liste-live-updated", function(tableListeLive) {
      var idPremiereTable = "-1";
      var idTableFerme;
      var tableLiveFerme;
      var tableLiveInfoFerme;
      try {
        // Pour chaque table dans la famille
        for (var idTable in self.tableListe) {
          var table = self.tableListe[idTable];

          var findTableLive = self.mixinCED_findTableLive(table, tableListeLive, self.appareil);
          var tableLive = findTableLive.tableLive;
          var tableLiveInfo = findTableLive.tableLiveInfo;

          // Garder la première table
          if (!idPremiereTable) {
            idPremiereTable = idTable;
          }

          // Évaluer l'état de la table
          // Le 1 permet de bypasser l'état de chargement
          var etatTable = self.mixinCED_getEtatTableRefresh(table, tableLive, 1);

          // Choisir la table qui est ouverte, seulement s'il y a un présentateur
          if (etatTable == self.CS_OUVERT) {
            self.idTable = idTable;
            self.tableLive = tableLive;
            self.tableLiveInfo = tableLiveInfo;
            break;
          } else if (etatTable == self.CS_FERME) {
            var bPlusTot = false;
            // Possible de ne pas avoir operationHours, value et startTime
            try {
              bPlusTot =
                tableLive.operationHours.value.startTime <
                tableLiveFerme.operationHours.value.startTime;
              // eslint-disable-next-line no-empty
            } catch (error) {}

            if (!idTableFerme || bPlusTot) {
              idTableFerme = idTable;
              tableLiveFerme = tableLive;
              tableLiveInfoFerme = tableLiveInfo;
            }
          }
        }
      } catch (error) {
        self.idTable = null;
        self.tableLive = null;
        self.tableLiveInfo = null;
      }

      // Si aucune table n'est sélectionnée, choisir la première table fermée sinon la table en vedette
      if (!self.idTable) {
        self.idTable = idTableFerme ? idTableFerme : idPremiereTable;
        self.tableLive = tableLiveFerme;
        self.tableLiveInfo = tableLiveInfoFerme;
      }
    });
  },

  methods: {
    chargerTableListe: function(familleJeu) {
      axios
        .get(
          this.prefixeUrl + "/" + this.mixinLangue_langue + "/json-cache/casino-en-direct/table",
          {
            params: {
              famille: familleJeu
            }
          }
        )
        .catch(function(error) {
          console.log("Erreur première table: " + error);
        })
        .then((response) => {
          this.tableListe = response.data;
        });
    }
  }
};
</script>

<style lang="scss">
.ced-table {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 66.64%;

  &-chargement {
    width: 236px;
    height: 120px;
    padding-top: 80px;
    float: left;
    text-align: center;
  }
}
</style>
