import BanniereCustom from './ej-banniere-custom.js';
import { breakpointIs } from './viewport-info.js';
import { MapDataToObjectKey, Trx } from './utilities';


var biggestStagePadding = 0;

// OWL Carousel initialization
const owlCarouselWaitForClientConnectionInfo = function() {
  handleYoutubeConsent("c:youtube-xhBVKfkq");
  if (!Trx) {
    owlCarouselInit();
  } else if (typeof ejConnect === 'undefined') {
    setTimeout(function () {
      owlCarouselWaitForClientConnectionInfo();
    }, 200);
  } else {
    owlCarouselInit();
  }
}

const handleYoutubeConsent = function (vendorId) {
  // Create the "didomiOnReady" listener
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(function (Didomi) {
    // Subscribe to the vendor status : It triggers the listener each time the status is changed for this vendor.
    Didomi.getObservableOnUserConsentStatusForVendor(vendorId).subscribe(function (consentStatus) {
      // Check if the "consentStatus" is true (eg. the user agreed to the vendor & his purposes)
      if (consentStatus === true) {
        playVideoAndHideOverlay();
      } else {
        hidePlayerVideo();
      }
    });
    // An event listener is attached to each button element
    document.querySelectorAll(".video-consent-overlay-accept-button").forEach(function (button) {
      button.addEventListener("click", function () {
        // When the button is clicked, we call the setPositiveConsentStatusForVendor custom function to enable the vendor (Youtube) and all his purposes.
        setPositiveConsentStatusForVendor(vendorId);
      });
    });
  });
}

const playVideoAndHideOverlay = function () {
  document.querySelectorAll(".youtube-container").forEach(function (overlay) {
    $(".youtube-container").css('z-index', '0');
    var iframe = overlay.querySelector("iframe[data-src]");
    if (iframe) {
      var src = iframe.getAttribute("data-src");
      iframe.setAttribute("src", src);
      overlay.querySelector(".video-consent-overlay").classList.add("d-none");
    }
  });
}

const hidePlayerVideo = function () {
  document.querySelectorAll(".youtube-container").forEach(function (overlay) {
    $(".youtube-container").css('z-index', '16');
    var iframe = overlay.querySelector("iframe[src]");
    if (iframe) {
      var src = iframe.getAttribute("src");
      iframe.setAttribute("data-src", src);
      overlay.querySelector(".video-consent-overlay").classList.remove("d-none");
    }
  });
}

const setPositiveConsentStatusForVendor = function (vendorId) {
  // Get all the vendor purposes
  // eslint-disable-next-line no-undef
  var purposes = Didomi.getVendorById(vendorId).purposeIds;
  // Create a "transaction"...
  // eslint-disable-next-line no-undef
  var transaction = Didomi.openTransaction();
  // ... enable the vendor
  transaction.enableVendor(vendorId);
  // ... and all his purposes
  transaction.enablePurposes(...purposes);
  // update the new status using "commit"
  transaction.commit();
}

const owlCarouselInit = function(){
  const initialized = function (event) {
    setTimeout(function () {
      positionArrows(event);
    }, 100);
    
    $(window).resize(function () {
      positionArrows(event);
    });
    
    if ( event.currentTarget.classList.contains('avec-bordures') ) {
      // Besoin d'un delai pour s'assurer que le carousel, incluant les flèches soit tout loadé
      setTimeout(function () {
        maskCarouselEdges(event);
      }, 100);
    } else {
      // pour les carousels standard, on veut quand-même centrer verticalement les flèches
      setTimeout(function () {
        $('.owl-carousel .tile-inner').css('background-image', 'none');
      }, 100);
    }
    
    // iframeAnimation(event,'active');
    
    BanniereCustom.init()
  };
  
  /** 
   * Ajuster verticalement les flèches du carousel en fonction de l'image seulement et pas de l'item entier 
   */
  const positionArrows = function (event) {
    var theheight,
        $navArrows,
        navArrowsHeight,
        imageMarginTop,
        $limitContext = $(event.currentTarget) || null;
    	
    if ($limitContext != null && $limitContext.length > 0) {
      theheight = parseInt($limitContext.find('.tile-inner .bg-img:first').css('height') || $limitContext.find('.tile-inner .media-component:first').css('height'));
      $navArrows = $limitContext.find('.owl-nav.side-arrows');
      imageMarginTop = parseInt($limitContext.find('.media-component.media-image').css('margin-top'));
      navArrowsHeight = parseInt($navArrows.find('button').css('height'));
      
      if (event.currentTarget.className.indexOf('carousel-promotions') != -1) {
        $navArrows.css('margin-top', "-" + (navArrowsHeight / 2).toString() + "px");
      } else { // carousel-gagnants et le big d'en haut (ou tout autre, en fait)
        $navArrows.css('top', (theheight / 2) - (navArrowsHeight / 2) + "px");
      }
      
      matchStagePadding($limitContext);
    }
  };
  
  /** 
   * On va aller chercher un .owl-item du carousel avec bordures dégradées. 
   * On ajoute les classes appropriées et on les place au début de .owl-stage-outer mais à l'extérieur de .owl-stage
   */
  const maskCarouselEdges = function (event) {
    $(event.currentTarget).find('.owl-stage-outer').append('<div class="mask left-arrow"></div><div class="mask right-arrow"></div>')
  };
  
  /**
   * Introduit avec la page Portail (2017). Permet de modifier le padding des offres (médias sociaux / on en parle)
   * en fonction du stage padding du carousel des gagnants, pour que tout soit aligné sur les dernières slides "pleines" de gagnants
   */
  const matchStagePadding = function ($limitContext) {
  
    // get padding between tiles to forward to "on en parle" pour que ça match
    var tilesPadding = parseInt($limitContext.find('.bg-img').css('margin-right')) + parseInt($limitContext.find('.tile-inner').css('margin-right'));
    
    if (biggestStagePadding > 0 && document.querySelector('.carousel-gagnants') !== null) {
      // ajuster les paddings de .on-en-parle (on en parle)
      $('.offers-component.on-en-parle > .columns').css('padding-right', (tilesPadding + 1).toString() + 'px');
      $('.offers-component.on-en-parle > .columns').css('padding-left', (tilesPadding).toString() + 'px');
      if ( breakpointIs('xxl', 'up') ) {
        $('.offers-component.on-en-parle').css('padding-left', '1rem');
        $('.offers-component.on-en-parle').css('padding-right', '1rem');
      } else {
        $('.offers-component.on-en-parle').css('padding-left', biggestStagePadding.toString() + 'px');
        $('.offers-component.on-en-parle').css('padding-right', biggestStagePadding.toString() + 'px');
      }

      // ajuster les paddings de .carousel-promotions
      $('.carousel-promotions .owl-item').css('padding-right', (tilesPadding + 1).toString() + 'px');
      $('.carousel-promotions .owl-item').css('padding-left', (tilesPadding).toString() + 'px');
    }

  };

  const setupCarousel = function(){
    var $carousels = $('.fn-carousel'),
      defaultOptions = $.extend({}, {
        items: 1,
        loop: true,
        margin: 0,
        lazyLoad: true,
        responsiveClass: true,
        video: true,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        dots: true,
        navElement: 'div',
        nav: true,
        nestedItemSelector: 'fn-carousel__item'
      });

    $carousels.each(function () {
      var $carousel = $(this),
        options = $.extend({},
          defaultOptions, // Original options set from OWL plugin
          MapDataToObjectKey($carousel.data(), defaultOptions), // extra option for carousel
          { // Custom data to set option in carousel
            responsive: {
              0: {
                items: ($carousel.data('responsive-items-xsmall') || 1)
              },
              400: {
                items: ($carousel.data('responsive-items-small') || 1)
              },
              768: {
                items: ($carousel.data('responsive-items-medium') || 1)
              },
              1024: {
                items: ($carousel.data('responsive-items-large') || 1)
              },
              1441: {
                items: ($carousel.data('responsive-items-xlarge') || 1)
              }
            },

            stagePadding: function () {
              var thePadding;
              if (breakpointIs('lg', 'down')) {
                thePadding = $carousel.data('stage-padding-small') || 0;
              } else if (breakpointIs('xxl', 'down')) {
                thePadding = $carousel.data('stage-padding-medium') || 0;
              } else {
                thePadding = $carousel.data('stage-padding-large') || 0;
              }
              if (biggestStagePadding < thePadding) { 
                biggestStagePadding = thePadding;
              }
              return thePadding;
            },
            onInitialized: initialized,
            onResized: positionArrows,
						navText: [
              '<button class="navleftbut"><span class="visually-hidden">' + $carousel.data('nav-text-prev') + '</span><svg class="icon arrow-carousel-left"><use xlink:href="#arrow-carousel-left" /></svg></button>',
              '<button class="navrightbut"><span class="visually-hidden">' + $carousel.data('nav-text-next') + '</span><svg class="icon arrow-carousel-right"><use xlink:href="#arrow-carousel-right" /></svg></button>'
            ]
          }
        );

        const $scriptTag = $carousel.find( '[type="text/x-ejtmpl-banniere"]' );
        $scriptTag.each(function(){
          $(this).before( $(this).html() );
          $(this).remove();
        });
        
        $carousel.owlCarousel(options);

        $carousel.on('changed.owl.carousel', function(event) {
          $(this).trigger('stop.owl.autoplay');
          $(this).trigger('play.owl.autoplay', [options.autoplayTimeout, options.autoplaySpeed]);
        });
        
        // $carousel.on('translated.owl.carousel', function(event) {
        // 	iframeAnimation(event,'active');
        // });
        
        // Add a wrapper around owl-controls children
        $carousel.find('.owl-controls').children().wrapAll('<div class="owl-dots-wrapper"/>');
        $carousel.find('.owl-nav').addClass('side-arrows').appendTo($carousel);
        
        // Pour Arthur, ajouté classe numérisée pour différencier les slides
        var slideNum = 1;
        
        if ($carousel.hasClass('carousel-standard')) {
          $carousel.find('.owl-item').not('.cloned').each(function (e) {
            $(this).find('.desktop-content a').attr('id', 'slide-num-' + slideNum.toString());
            $(this).find('.mobile-content a').attr('id', 'slide-num-mobi-' + slideNum.toString());
            slideNum++;
          });
        }

        // handle focusin and out for accessibility
        $carousel.find('.owl-nav').focusin(function () {
          // Pause auto play on focus in
          $carousel.trigger('stop.owl.autoplay');
        }).focusout(function () {
          // restart auto play on focus out
          $carousel.trigger('play.owl.autoplay', [options.autoplayTimeout, options.autoplaySpeed]);
        });
    });
  };

  setupCarousel();
};

export default owlCarouselWaitForClientConnectionInfo;