import Cookies from "../../js/components/cookies";
export default {
	methods: {
		sendFiles() {
			event.preventDefault();			
			const vm = this;
			const $inputFile = vm.$el.querySelector('[type="file"]');
			this.$emit( 'ev-loading', true);
			// On "désactive" les input file, afin de ne pas avoir de fichier en double lors de l'envoi.
			$inputFile.setAttribute('type', '');
			let ajaxData = new FormData();						
			// Ajout de tous les fichiers au formData			
			Array.from(vm.files).forEach(function (file) {
				ajaxData.append('file', file);
			});
			ajaxData.append('site-id', vm.siteId);
			if(undefined !== vm.bankAccount){
				ajaxData.append('bank-account-id', vm.bankAccount.institutionId);
				ajaxData.append('bank-account-num', vm.bankAccount.transitNumber);
				ajaxData.append('bank-account-acc-num', vm.bankAccount.accountNumber);
			}	
			let myheaders = new Headers();
			myheaders.append('pragma', 'no-cache');
			myheaders.append('cache-control', 'no-cache');
			myheaders.append('JEL-XTOKEN', Cookies.get("Jel-csrf"));    
			fetch('/services/upload-doc/', {
				method: 'POST',
				body: ajaxData,
				headers: myheaders
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if( data.length === undefined ) {
					if ( data.error !== undefined ) {
						vm.errorsReset();
						vm.resetForm();
						vm.enableDragNDrop( vm );
						vm.error.sending = true;
						$inputFile.setAttribute('type', 'file');
						// $inputFilePicture.setAttribute('type', 'file');
						this.$emit( 'ev-loading', false);
					} else if( data.connexion === 'invalide' ) {
						// Si la connexion est perdu, on bloque tout action en affichant un message de service non disponible.
						// document.querySelector( '.televersement' ).classList.add( 'd-none' );
						// document.querySelector( '.televersement-non-disponible' ).classList.remove( 'd-none' );
						// this.$emit( 'ev-loading', false);
						window.location.href = "/";
					} else {
						throw `Téléversement : Une erreur s'est produite`;
					}
				} else {
					for( let i = 0; i < data.length; i++ ) {				
						if( data[i].status === 'error' ) {
							vm.errorsReset();
							if( data[i].connexion === 'invalide' ) {
								// Si la connexion est perdu, on bloque tout action en affichant un message de service non disponible.
								document.querySelector( '.televersement' ).classList.add( 'd-none' );
								document.querySelector( '.televersement-non-disponible' ).classList.remove( 'd-none' );
								this.$emit( 'ev-loading', false);
								break;
							} else if( data[i].codeError !== undefined ) { // Si une erreur est détectée pour 1 des fichiers lors de l'envoi, on reset le formulaire. L'utilisateur doit recommencer (décision fonctionnel).
								vm.resetForm();
								vm.enableDragNDrop();
								vm.error.sending = true;
								$inputFile.setAttribute('type', 'file');
								// $inputFilePicture.setAttribute('type', 'file');
								this.$emit( 'ev-loading', false);
								break;
							}
						} else if( ( i + 1 ) === data.length && data[i].status === 'success' ) {
							vm.$parent.identityByCCAuthorised = data[i].identityByCCAuthorised == "true";
							document.querySelector( '.televersement' ).classList.add( 'd-none' );
							document.querySelector( '.televersement__message-success' ).classList.remove( 'd-none' );
							vm.disableDragNDrop();
							this.$emit( 'ev-loading', false);
						}
					}
				}
				
			}).catch(error => {
				console.log( error );
				this.$emit( 'ev-loading', false);
				vm.errorsReset();
				vm.error.sending = true;
				$inputFile.setAttribute('type', 'file');
				// $inputFilePicture.setAttribute('type', 'file');
			});
		}
	}
}