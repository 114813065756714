import Cookies from "./cookies";
/* eslint-disable prettier/prettier */
function addPromo(event) {
    const $this = $(this);
    //eslint-disable-next-line
    const promotion = $(".ej-div-promotion-add");

    let apiHost = window.location.hostname;
    let channel = "I";

    if (apiHost.substring(0, apiHost.indexOf(".") == "m")) {
        channel = "M";
    }

    if (promotion[0].dataset != undefined) {
        //eslint-disable-next-line
        $.ajax({
            url: "/services/promotion-ajout",
            headers: { 'JEL-XTOKEN' : Cookies.get('Jel-csrf') },
            method: "POST",
            data: {
                triggerId: promotion[0].dataset.code,
                channel: channel,
            },
            xhrFields: { withCredentials: true },
            success: (response) => {
                if (response.status === "success") {
                    if (response.GUEST == "true") {
                        //eslint-disable-next-line
                        window.location.href = lienRedirectionInvite;
                    } else if (response.ALREADY_DID == "true") {
                        //eslint-disable-next-line
                        const deja = $(".ej-div-promotion-deja-ajouter");
                        //eslint-disable-next-line
                        $(".usage").text($("#mel-nomComplet").text());
                        promotion.addClass("d-none");
                        deja.removeClass("d-none");
                    } else if (response.Success == "true") {
                        //eslint-disable-next-line
                        const confirm = $(".ej-div-promotion-confirmation");
                        //eslint-disable-next-line
                        $(".usage").text($("#mel-nomComplet").text());
                        promotion.addClass("d-none");
                        confirm.removeClass("d-none");
                    }
                }
            },
            error: (error) => {
                //eslint-disable-next-line
                const erreur = $(".ej-div-promotion-erreur");
                promotion.addClass("d-none");
                erreur.removeClass("d-none");
            },
        });
    }
};

const EjPromotion = () => {
    //eslint-disable-next-line
    $(document).on("click", ".ej-btn-div-promotion", (e) => {
        e.preventDefault();
        if (loginNav == null || loginNav == undefined || !loginNav.isLoggedIn()) {
            window.Espacejeux_OuvrirLogin();
            $(document).on("ejEventLogin", (e) => { addPromo(e); });

        } else {
            //eslint-disable-next-line
            addPromo(e);

        }
    });
};

/*
/ Commentaire au cas ou les analytics sont demandé
/
/	analytics = $('.ej-div-promotion-add').attr('data-analytics').replace(/'/g, '"');
/	addGoogleAnalytics(analytics);
/	const addGoogleAnalytics = analytics => {
/	    if(analytics === '') return false;
/	    analytics = JSON.parse(analytics);
/	    EspacejeuxPortail_GoogleAnalytics.enregistrerEvenement(analytics.category, analytics.action, analytics.label, 0);
/	}
*/
export default EjPromotion;
