var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container space_under_text",
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "col-12" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.getImageSrcVariation(
                    _vm.getImageByScreenSize(_vm.contenu)
                  ),
                  expression:
                    "getImageSrcVariation(getImageByScreenSize(contenu))",
                },
              ],
              staticClass: "image",
              attrs: { alt: "" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-12" },
            [
              _c("p", { staticStyle: { "margin-bottom": "1rem" } }, [
                _c("span", {
                  staticClass: "titre graphik_bold",
                  domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
                }),
              ]),
              _vm._v(" "),
              _vm.contenu.sousTitre != null
                ? [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.contenu.sousTitre) },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "h-50 d-inline-block texte",
                domProps: { innerHTML: _vm._s(_vm.contenu.texte) },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }