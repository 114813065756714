<template>
  <div id="barreFiltre" class="filtre" :class="familleJeu.toLowerCase()">
    <!-- ***** Filtres ***** -->
    <div v-if="showFiltre" class="col-12" :class="isMobile ? 'col-xl-10' : ''">
      <div
        v-for="(filtre, ind) in filtres"
        :key="ind"
        class="filtreBar"
        :class="[filtres.length == 1 ? 'centrer' : 'left']"
      >
        <div class="tablesOuverts radiusGauche">
          <span class="nbOuverts">
            {{ $tc("ced.tablesOuvertes", nbTableOuvertes, { count: nbTableOuvertes }) }}
          </span>
        </div>
        <div>
          <button
            v-for="(option, index) in filtre"
            :key="index"
            type="button"
            class="filtreBarButton"
            :class="indexActif[ind] === index ? 'active' : ''"
            :name="option.name"
            @click="updateFiltre(ind, index, option.name)"
          >
            {{ option.val }}
          </button>
        </div>
      </div>
    </div>

    <!-- ***** Grille ***** -->
    <div class="d-none d-xl-flex" :class="showFiltre ? 'col-2' : 'col-12'">
      <div id="grilleCedTooltip" class="grille">
        <svg
          v-b-tooltip="{
            title: $t('ced.tooltip.grilleGrand'),
            placement: 'top',
            trigger: 'hover',
            customClass: 'grilleTooltip',
            container: 'barreFiltre'
          }"
          version="1.1"
          :class="grilleActif == GRILLE_GRAND ? 'active' : ''"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 24 24"
          xml:space="preserve"
          @click="updateTypeGrille(GRILLE_GRAND)"
        >
          <path
            d="M9.6 0v9.6H0V0h9.6zM24 0v9.54h-9.6V0H24zM0 14.4h9.54V24H0v-9.6zM14.46 14.44h9.5v9.5h-9.5v-9.5z"
          />
        </svg>
        <svg
          v-b-tooltip="{
            title: $t('ced.tooltip.grilleMoyen'),
            placement: 'top',
            trigger: 'hover',
            customClass: 'grilleTooltip',
            container: 'barreFiltre'
          }"
          version="1.1"
          :class="grilleActif == GRILLE_MOYEN ? 'active' : ''"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 23.77 23.77"
          xml:space="preserve"
          @click="updateTypeGrille(GRILLE_MOYEN)"
        >
          <path
            d="M2.36 4.75H.58c-.39 0-.57-.18-.57-.57V.58C.01.18.2 0 .61 0h3.56c.41 0 .59.18.59.59v3.56c0 .43-.17.59-.61.6H2.36zM11.87 4.75h-1.78c-.39 0-.57-.18-.57-.56V.59C9.51.18 9.7 0 10.11 0h3.56c.41 0 .59.18.59.59v3.56c0 .43-.17.59-.61.6h-1.78zM21.37 4.75h-1.78c-.39 0-.57-.18-.57-.56V.59c0-.4.19-.58.6-.58h3.56c.41 0 .59.18.59.59v3.56c0 .43-.17.59-.61.6-.6-.01-1.19-.01-1.79-.01zM2.4 9.5h1.78c.39 0 .57.18.57.57v3.64a.482.482 0 01-.56.55H.55a.478.478 0 01-.56-.38c.01-.06.01-.11.02-.17v-3.68c-.03-.26.15-.5.41-.53.04-.01.09-.01.13 0H2.4zM14.26 11.9v1.78c0 .39-.17.57-.56.57h-3.6c-.41 0-.58-.18-.58-.59V10.1c0-.41.18-.59.59-.59h3.56c.43 0 .59.18.6.61-.01.6-.01 1.19-.01 1.78zM23.77 11.9v1.78c0 .39-.17.57-.56.57h-3.6c-.41 0-.58-.18-.58-.6v-3.56c0-.41.18-.59.59-.59h3.56c.43 0 .59.18.6.62-.01.6-.01 1.19-.01 1.78zM2.36 23.76H.58a.498.498 0 01-.57-.57v-3.6a.498.498 0 01.57-.58h3.64c.27-.04.51.15.55.42.01.05.01.09 0 .14v3.64c0 .38-.18.55-.58.55H2.36zM14.26 21.38v1.78c0 .42-.17.59-.58.59h-3.6a.484.484 0 01-.57-.4.485.485 0 010-.17v-3.6a.498.498 0 01.57-.58h3.64c.27-.04.51.15.55.42.01.04.01.09 0 .14-.01.61-.01 1.22-.01 1.82zM21.42 19.01h1.74c.43 0 .6.17.6.61v3.53c0 .45-.16.61-.63.61h-3.49c-.46 0-.62-.17-.62-.62v-3.49c0-.48.17-.63.65-.63l1.75-.01z"
          />
        </svg>
        <svg
          v-b-tooltip="{
            title: $t('ced.tooltip.grillePetit'),
            placement: 'top',
            trigger: 'hover',
            customClass: 'grilleTooltip',
            container: 'barreFiltre'
          }"
          version="1.1"
          :class="[grilleActif == GRILLE_PETIT ? 'active' : '']"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 23.92 23.92"
          xml:space="preserve"
          @click="updateTypeGrille(GRILLE_PETIT)"
        >
          <path
            d="M0 0h3.42v3.42H0zM6.84 0h3.42v3.42H6.84zM0 6.84h3.42v3.42H0zM6.84 6.84h3.42v3.42H6.84zM13.67 0h3.42v3.42h-3.42zM13.67 6.84h3.42v3.42h-3.42zM20.51 0h3.42v3.42h-3.42zM20.51 6.84h3.42v3.42h-3.42zM0 13.67h3.42v3.42H0zM6.84 13.67h3.42v3.42H6.84zM0 20.51h3.42v3.42H0zM6.84 20.51h3.42v3.42H6.84zM13.67 13.67h3.42v3.42h-3.42zM13.67 20.51h3.42v3.42h-3.42zM20.51 13.67h3.42v3.42h-3.42zM20.51 20.51h3.42v3.42h-3.42z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinCED } from "../../mixins/mixinCED";
import { mixinLangue } from "../../mixins/mixinLangue";
export default {
  mixins: [mixinLangue, mixinCED],
  props: {
    familleJeu: {
      type: String,
      required: false
    },
    filtrePrix: Array,
    filtrePresentateur: {
      type: Boolean,
      required: false,
      default: false
    },
    appareil: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      indexActif: [0, 0], //Correspondant à l'indice du bouton actif pour les filtres prix et présentateur
      nameActif: [],
      grilleActif: null,
      sliderVal: -1, //TO DO
      nbTableOuvertes: 0
    };
  },
  computed: {
    CS_MOBILE: () => "mobile",
    CS_DESKTOP: () => "desktop",

    isMobile() {
      return this.appareil == this.CS_MOBILE;
    },
    filtres() {
      var filtres = [];
      if (this.filtrePrix.length > 0) {
        filtres.push(this.fillFiltres(this.filtrePrix, true));
      }
      if (this.filtrePresentateur) {
        filtres.push(this.fillFiltres(["Avec", "Sans"], false));
      }
      return filtres;
    },
    showFiltre() {
      return this.filtrePrix.length > 0 || this.filtrePresentateur;
    }
  },
  created: function() {
    var self = this;
    this.$bus.$on("table-liste-updated", function(nbTableOuvertes) {
      self.nbTableOuvertes = nbTableOuvertes;
    });
    this.initGrilleActif();
  },

  methods: {
    updateFiltre: function(i, index, name) {
      this.resetPositionTables();
      if (this.indexActif[i] != index) {
        this.$set(this.indexActif, i, index);
        this.nameActif[i] = name;
        this.$bus.$emit("barre-filtre-updated", this.getSelectionFiltres());
      }
    },
    updateTypeGrille: function(typeGr) {
      if (this.grilleActif != typeGr) {
        if (this.showFiltre) {
          this.resetPositionTables();
        }
        this.grilleActif = typeGr;
        localStorage.setItem("formatGrille", typeGr);
        this.$bus.$emit("barre-grille-updated", this.grilleActif);
      }
    },
    updateSlider: function(val) {
      this.sliderVal = val;
      this.$bus.$emit("barre-slider-updated", this.sliderVal);
    },
    fillFiltres: function(filtre, estPrix) {
      var optionsFiltre = [];
      optionsFiltre.push({ val: this.$t("ced.filtreTout"), name: "Tout" });
      for (var i = 0; i < filtre.length; i++) {
        if (estPrix) {
          optionsFiltre.push({
            val: this.format(filtre[i]).formatAffichage,
            name: this.format(filtre[i]).valeurs
          });
        } else {
          optionsFiltre.push({
            val: this.$t("ced.filtrePresentateur")[filtre[i]],
            name: filtre[i]
          });
        }
      }
      return optionsFiltre;
    },
    format: function(valeur) {
      var valFormatter = "";
      var valeurs = valeur.split("-");
      var inf = valeurs[0];
      var sup = valeurs[valeurs.length - 1];
      var valMin = 0;
      var valMax = 0;
      if (valeurs.indexOf("<") != -1) {
        valFormatter = inf + " " + this.formatMise(sup);
        valMin = 0;
        valMax = Number(sup) - 0.01;
      } else if (valeurs.indexOf(">") != -1) {
        valFormatter = "> " + this.formatMise(inf);
        valMin = Number(inf) + 0.01;
        valMax = Infinity;
      } else {
        valFormatter = this.formatMise(inf) + " - " + this.formatMise(sup);
        valMin = inf;
        valMax = sup;
      }
      return { formatAffichage: valFormatter, valeurs: valMin + "-" + valMax };
    },
    formatMise: function(nombre) {
      return parseFloat(nombre)
        .toLocaleString(this.mixinLangue_langue + "-CA", {
          style: "currency",
          currency: "CAD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
        .replace("CA", "")
        .trim();
    },
    initGrilleActif: function() {
      if (this.validerDefaultGrille()) {
        this.grilleActif = localStorage.getItem("formatGrille");
      } else {
        this.grilleActif = this.GRILLE_GRAND;
      }
      this.$bus.$emit("barre-grille-updated", this.grilleActif);
    },
    validerDefaultGrille: function() {
      var valeursValides = [this.GRILLE_PETIT, this.GRILLE_MOYEN, this.GRILLE_GRAND];
      var grilleDefault = localStorage.getItem("formatGrille");
      return grilleDefault && valeursValides.indexOf(parseInt(grilleDefault)) != -1;
    },
    getSelectionFiltres: function() {
      var selectionFiltre = [];
      var valeurPrix;
      if (this.filtres.length > 1) {
        if (this.nameActif[0] && this.nameActif[0] !== "Tout") {
          valeurPrix = this.nameActif[0].split("-");
          selectionFiltre.push({
            type: "prix",
            valeurMin: valeurPrix[0],
            valeurMax: valeurPrix[1]
          });
        }
        if (this.nameActif[1] && this.nameActif[1] !== "Tout") {
          //selectionFiltre.push({"type":"animateur","valeur":this.nameActif[1]});
          selectionFiltre.push({
            type: "animateur",
            valeur: this.nameActif[1]
          });
        }
      } else {
        if (this.nameActif[0] && this.nameActif[0] !== "Tout") {
          if (this.filtrePrix && this.filtrePrix.length > 0) {
            valeurPrix = this.nameActif[0].split("-");
            selectionFiltre.push({
              type: "prix",
              valeurMin: valeurPrix[0],
              valeurMax: valeurPrix[1]
            });
          } else {
            selectionFiltre.push({
              type: "animateur",
              valeur: this.nameActif[0]
            });
          }
        }
      }
      return selectionFiltre;
    },
    /**  Permet de revenir au début du filtre à chaque sélection de filtre
     * après qu'on avoir scroller la page pour voir les tables plus basses */
    resetPositionTables: function() {
      if (
        document
          .getElementById("stickyBarre")
          .classList.toString()
          .indexOf("top-sticky") != -1
      ) {
        var offsetSticky = this.offset(document.getElementById("stickyContainer")) + 1; //1px de plus pour voir le background du sticky
        window.scrollTo(0, offsetSticky);
      }
    },
    offset: function(el) {
      var rect = el.getBoundingClientRect();
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTop;
    }
  }
};
</script>
<style lang="scss">
.grilleTooltip.tooltip {
  &.bs-tooltip-top {
    top: -6px !important;
  }
  &.bs-tooltip-bottom {
    top: 6px !important;
  }
  .tooltip-inner {
    color: black;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    padding: 4px 15px 5px 15px;
    font-family: $font-graphik-regular;
    font-size: 12px;
    font-weight: bold;
    background-color: white;
  }
}

.grilleTooltip .arrow::before {
  border-width: 0.6rem 0.4rem 0 !important;
}
.theme-bg-ced .section-page .filtre {
  display: flex; //pour mettre la grille et le filtre sur la même ligne
  .col-2.d-none.d-xl-flex {
    margin-left: -16.7%;
    justify-content: flex-end;
  }
  .col-12.d-none.d-xl-flex {
    justify-content: flex-end;
  }
}

.theme-bg-ced .section-page .sticky {
  .filtreBar {
    font-family: $font-graphik-regular;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    color: white;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    &:last-child {
      @media (max-width: 767px) {
        margin-bottom: 5px; //mettre de l'espace entre les filtres et le barSlider
      }
    }
    &.centrer {
      @include media-breakpoint-up(md) {
        justify-content: center;
      }
    }
    &.left {
      &:last-child {
        margin-top: 5px; //mettre de l'espace entre les filtres dans le cas de deux
      }
      @include media-breakpoint-up(xl) {
        //grille n'existe qu'à partir de 1200px
        margin-left: 16%;
      }
    }
    .tablesOuverts {
      font-size: 1.1vw;
      height: calc(25px + 0.4vw);
      font-weight: normal;
      margin-right: -4px;
      padding-left: 4vw;
      padding-right: 4vw;
      align-items: center;
      display: flex;
      &.radiusGauche:not(.active) {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
      @include media-breakpoint-up(sm) {
        height: calc(23px + 0.4vw);
        padding-left: 3.25vw;
        padding-right: 3.25vw;
        margin-right: -3px;
      }
      @include media-breakpoint-up(md) {
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }
      @include media-breakpoint-up(xl) {
        font-size: calc(14px + 0.02vw);
      }
      @include media-breakpoint-up(xxl) {
        font-size: calc(14px + 0.02vw);
        padding-right: 2vw;
        padding-left: 2vw;
      }
      @include media-breakpoint-up(xxxl) {
        font-size: calc(14px + 0.02vw);
        padding-right: 30px;
        padding-left: 30px;
      }
    }
    button.filtreBarButton {
      height: calc(25px + 0.4vw);
      padding-left: 4vw;
      padding-right: 4vw;
      margin-right: -4px; //pour avoir une impression de continuité entre les choix du filtre
      margin-left: -4px; //pour avoir une impression de continuité entre les choix du filtre
      font-weight: bold;
      font-size: 1.2vw;
      letter-spacing: 0px;
      border: none;
      color: white;
      @include media-breakpoint-up(sm) {
        height: calc(23px + 0.4vw);
        padding-left: 3.25vw;
        padding-right: 3.25vw;
        margin-right: -3px; //pour avoir une impression de continuité entre les choix du filtre
        margin-left: -3px;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.3vw;
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }
      @include media-breakpoint-up(xl) {
        font-size: calc(14px + 0.027vw);
      }
      @include media-breakpoint-up(xxl) {
        font-size: calc(16px + 0.02vw);
        padding-right: 2vw;
        padding-left: 2vw;
      }
      @include media-breakpoint-up(xxxl) {
        font-size: calc(16px + 0.02vw);
        padding-right: 30px;
        padding-left: 30px;
      }
      &.active {
        color: black;
        position: relative;
        color: black;
        border-radius: 6.2vw;
        -webkit-border-radius: 6.2vw;
        -moz-border-radius: 6.2vw;
        height: calc(30px + 0.5vw);
        font-weight: bold;
        @include media-breakpoint-down(sm) {
          border-radius: 3vw;
          -webkit-border-radius: 3vw;
          -moz-border-radius: 3vw;
        }
        @include media-breakpoint-up(sm) {
          height: calc(35px + 0.5vw);
        }
      }
      &.radiusGauche:not(.active) {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }
    .filtreBarButton:last-child:not(.active) {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  .centrerFiltre .filtre/*.row.col-12*/ {
    &.blackjack,
    &.roulette,
    &.baccara,
    &.des,
    &.poker,
    &.jeuxendirect {
      padding-right: 0px;
    }
  }
  .filtre .grille {
    display: flex;
    align-items: center;
    padding-top: 5px; //semble un peu décalé
  }
  .filtre .col-12 .grille,
  .filtre .col-2 .grille {
    @media (max-width: 1350px) {
      margin-right: 50px;
    }
  }
  .filtre .grille svg {
    height: calc(17px + 0.4vw);
    width: calc(17px + 0.4vw);
    margin-right: 10px;
    fill: white;
    border: 0px;
    outline: none;
  }
  .filtre .grille svg:last-child {
    margin-right: 0px;
  }
  .blackjack {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #00a389 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #00a389;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #00a389 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #205447;
    }
  }
  .roulette {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #0a71f0 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #0a71f0;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #0a71f0 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #163b75;
    }
  }
  .jeuxendirect {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #01aef0 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #01aef0;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #01aef0 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #194ea1;
    }
  }
  .baccara {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #f03447 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #f03447;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #f03447 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #bc122e;
    }
  }
  .poker {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #4f71f8 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #4f71f8;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #4f71f8 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #3135a6;
    }
  }
  .des {
    .grilleTooltip.tooltip .tooltip-inner,
    .filtreBar button.filtreBarButton.active {
      background-color: #d63a93 !important;
    }
    .grille svg.active,
    .grille svg:hover {
      fill: #d63a93;
    }
    .grilleTooltip .arrow::before {
      border-top-color: #d63a93 !important;
    }
    .tablesOuverts,
    .filtreBar button.filtreBarButton {
      background-color: #a51c5d;
    }
  }
}
.domain-mobile .theme-bg-ced .filtreBar .filtreBarButton:first-child:not(.active) {
  @media (max-width: 767px) {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.domain-mobile .theme-bg-ced .filtre {
  text-align: center;
  @media (max-width: 767px) {
    .filtreBar .tablesOuverts {
      display: none;
    }
  }
  .filtreBar {
    justify-content: left;
    .tablesOuverts {
      font-size: 2vw;
      height: calc(25px + 0.4vw);
      padding-left: 4vw;
      padding-right: 4vw;
      height: 5vw;
      @include media-breakpoint-up(md) {
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: 1.5vw;
        height: 3.5vw;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.1vw;
        height: 3vw;
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }
    }
    button.filtreBarButton {
      font-size: 2.2vw;
      padding-left: 4vw;
      padding-right: 4vw;
      height: 5vw;
      @include media-breakpoint-up(xs) {
        padding-left: 4.25vw;
        padding-right: 4.25vw;
        font-size: 2vw;
        height: 4.5vw;
      }
      @include media-breakpoint-up(md) {
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: 1.6vw;
        height: 3.5vw;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.25vw;
        height: 3vw;
        padding-left: 2.7vw;
        padding-right: 2.7vw;
      }

      &.active {
        border-radius: 6.2vw;
        -webkit-border-radius: 6.2vw;
        -moz-border-radius: 6.2vw;
        height: 6.75vw;
        @include media-breakpoint-up(md) {
          height: 5vw;
        }
        @include media-breakpoint-up(lg) {
          height: 4.25vw;
        }
      }
    }
    &.centrer {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
</style>
