import { render, staticRenderFns } from "./Alerte.vue?vue&type=template&id=a21e65ce&scoped=true"
import script from "./Alerte.vue?vue&type=script&lang=js"
export * from "./Alerte.vue?vue&type=script&lang=js"
import style0 from "./Alerte.vue?vue&type=style&index=0&id=a21e65ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a21e65ce",
  null
  
)

export default component.exports