<template>
  <b-container
    :data-component-name="$options.name"
    class="conteneur-carrousel p-0"
    :class="isBingo ? 'carrousel-bingo' : ''"
    style="max-width: none !important"
    :fluid="contenu && contenu.fluid === 'true'"
  >
    <b-carousel
      v-if="!Array.isArray(contenu)"
      id="dynamicCarrousel"
      ref="dynamicCarrousel"
      :interval="isPaused ? 0 : parseInt(contenu.carrouselTimer)"
      :indicators="withIndicators(isItDesktop())"
      :controls="withControls(isItDesktop(), $mq, 'dynamic')"
      :no-animation="contenu.carrouselNoAnimation == 'true' ? true : false"
    >
      <div
        v-if="isItDesktop($mq) ? contenu.carrouselPause : false"
        class="pause-carousel"
        @click="doPause"
        @mouseover="setPauseIcone(true)"
        @mouseleave="setPauseIcone(false)"
      >
        <img v-lazy="pauseIconeSrc" class="carousel_image_pause" alt="" />
      </div>

      <div
        v-for="(aNode, index) in carrouselArray"
        :key="aNode.id"
        class="carousel-item"
        :class="
          !isItDesktop() && carrouselArray[0].column[0].formatAffichage == 'tit25img50txt25'
            ? 'mobile-carrousel'
            : null
        "
      >
        <!-- BOUCLE POUR CARROUSEL PLEINE LARGEUR -->
        <div v-if="isFullWidth" class="row flex-nowrap">
          <template>
            <component
              :is="carrouselArray[index].typeComposante"
              v-if="carrouselArray[index].typeComposante"
              :key="carrouselArray[index % carrouselArray.length]['@id']"
              :class="isBingo ? 'carrousel-bingo' : ''"
              :contenu="carrouselArray[index % carrouselArray.length]"
              :data-component-type="carrouselArray[index].typeComposante"
            ></component>
          </template>
        </div>

        <!-- BOUCLE POUR LE CARROUSEL AVEC COLONNES-->
        <div v-else class="row flex-nowrap">
          <template v-for="(aNode, idx) in carrouselArray">
            <component
              :is="carrouselArray[idx].typeComposante"
              v-if="carrouselArray[idx].typeComposante"
              :key="carrouselArray[(index + idx) % carrouselArray.length]['@id']"
              :class="isBingo ? 'carrousel-bingo' : ''"
              :contenu="carrouselArray[(index + idx) % carrouselArray.length]"
              :data-component-type="carrouselArray[idx].typeComposante"
            ></component>
          </template>
        </div>
      </div>
    </b-carousel>
  </b-container>
</template>
<script>
import Column from "../grid/Column.vue";
export default {
  name: "Carrousel",
  components: {
    Column,
  },
  props: ["contenu"],
  data() {
    var srcIconePause = "/dam/espacejeux/bingo/icones/";
    return {
      isPaused: false,
      isBingo: window.location.href.indexOf("/bingo/") > 0,
      srcIconePause: srcIconePause,
      pauseIconeSrc: this.getImageSrc(srcIconePause + "ic_pause.svg"),
      slide: 0,
      carrouselLength: Array.isArray(this.contenu)
        ? this.contenu[0].length
        : this.contenu.row["@nodes"].length,
      carrouselArray: [],
      mediaQuery: null,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4,
      xxl: 4,
    };
  },
  computed: {
    isFullWidth() {
      if (this.$el.getAttribute("data-carrousel-size") == "takeFullWidth") {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function () {},
  mounted: function () {
    this.hideSlidesToNotDiplay(this.contenu.row);

    if (!Array.isArray(this.contenu) && this.contenu.carrouselControls == "Externe") {
      for (
        var outside = 0;
        outside < this.$refs.dynamicCarrousel.$el.childNodes.length;
        outside++
      ) {
        if (
          this.$refs.dynamicCarrousel.$el.childNodes[outside].className ==
            "carousel-control-prev" &&
          window.innerWidth >= 768
        ) {
          this.$refs.dynamicCarrousel.$el.childNodes[outside].style = "left: -5% !important;";
        } else if (
          this.$refs.dynamicCarrousel.$el.childNodes[outside].className ==
            "carousel-control-next" &&
          window.innerWidth >= 768
        ) {
          this.$refs.dynamicCarrousel.$el.childNodes[outside].style = "right: -4% !important;";
        } else if (
          this.$refs.dynamicCarrousel.$el.childNodes[outside].className ==
            "carousel-control-prev" &&
          window.innerWidth < 768
        ) {
          this.$refs.dynamicCarrousel.$el.childNodes[outside].style = "left: 0% !important;";
        } else if (
          this.$refs.dynamicCarrousel.$el.childNodes[outside].className ==
            "carousel-control-next" &&
          window.innerWidth < 768
        ) {
          this.$refs.dynamicCarrousel.$el.childNodes[outside].style = "right: 0% !important;";
        }
      }
    }

    if (this.contenu.row[0]) {
      if (
        !Array.isArray(this.contenu) &&
        this.contenu.row[0].column[0].formatAffichage == "tit25img50txt25" &&
        !this.isItDesktop()
      ) {
        for (
          var carrouselChildNodes = 0;
          carrouselChildNodes < this.$refs.dynamicCarrousel.$el.childNodes.length;
          carrouselChildNodes++
        ) {
          if (
            this.$refs.dynamicCarrousel.$el.childNodes[carrouselChildNodes].className ==
            "carousel-inner"
          ) {
            this.$refs.dynamicCarrousel.$el.childNodes[carrouselChildNodes].classList.add(
              "takeFullWidth"
            );
          }
        }
      }
    }
  },
  updated: function () {},
  methods: {
    print(text) {
      console.log(text);
    },
    hideSlidesToNotDiplay(contenu) {
      const clone = Object.assign({}, contenu);
      const entries = Object.entries(clone);
      if (contenu) {
        let nodes = contenu["@nodes"];
        for (const [key, value] of entries) {
          if (nodes.includes(key)) {
            var showSlideAudience = true;
            var audienceCl = typeof ejConnect === "undefined" ? "anon" : ejConnect.etatClient;
            if (audienceCl == "anon") {
              audienceCl = localStorage.getItem("audienceClient");
            }

            var optin = localStorage.getItem("optin");

            var infolettre = value.infolettre == undefined ? "notSelected" : value.infolettre;
            var audienceAnon =
              value.indAudienceAnonyme == undefined ? true : value.indAudienceAnonyme; //ne pas oublier de verfier avec IE
            var audienceLeger =
              value.indAudienceClientLeger == undefined ? true : value.indAudienceClientLeger;
            var audienceInvite =
              value.indAudienceClientInvite == undefined ? true : value.indAudienceClientInvite;
            var audienceJoueur =
              value.indAudienceClientJoueur == undefined ? true : value.indAudienceClientJoueur;
            var audienceInfolettre = infolettre == "notSelected" || value.infolettre == optin;

            if (audienceCl == "anon") {
              if (audienceAnon == "false") {
                showSlideAudience = false;
              }
            } else if (audienceCl == "cl") {
              if (audienceLeger == "false") {
                showSlideAudience = false;
              }
            } else if (audienceCl == "ci") {
              if (audienceInvite == "false") {
                showSlideAudience = false;
              }
            } else if (audienceCl == "cj") {
              if (audienceJoueur == "false") {
                showSlideAudience = false;
              }
            }

            if (
              this.isDateAffichageCourante(value.dateDebutAffichage, value.dateFinAffichage) &&
              showSlideAudience &&
              audienceInfolettre
            ) {
              this.carrouselArray.push(value);
            }
          }
        }
      }

      this.carrouselArray.forEach(myFunction);

      function myFunction(item, index, arr) {
        arr[index].column[0]["CarrouselSlide"] = index + 1;
      }
    },
    verifyIfHideControl(slide, mq) {
      var carrouselLength = this.carrouselLength;
      if (mq == "sm") {
        carrouselLength = carrouselLength - 1;
      } else if (mq == "xs" || mq == "md" || mq == "xl" || mq == "xxl") {
        carrouselLength = carrouselLength - 3;
      } else if (mq == "lg" || mq == "xxxl") {
        carrouselLength = carrouselLength - 4;
      }

      if (slide == carrouselLength) {
        document.getElementsByClassName("carousel-control-next")[0].style.visibility = "hidden";
        document.getElementsByClassName("carousel-control-prev")[0].style.visibility = "visible";
      } else if (slide == 0) {
        document.getElementsByClassName("carousel-control-prev")[0].style.visibility = "hidden";
        document.getElementsByClassName("carousel-control-next")[0].style.visibility = "visible";
      } else {
        document.getElementsByClassName("carousel-control-next")[0].style.visibility = "visible";
        document.getElementsByClassName("carousel-control-prev")[0].style.visibility = "visible";
      }
    },
    withIndicators(isItDesktop) {
      if (!Array.isArray(this.contenu)) {
        if (this.contenu.carrouselIndicators == "true" && isItDesktop) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.contenu[1].carrouselIndicators == "true" && isItDesktop) {
          return true;
        } else {
          return false;
        }
      }
    },
    withControls(isItDesktop, mq, carrouselType) {
      var carrouselLength = this.carrouselLength;
      if (this.verifyIfThreeSlides(mq)) {
        carrouselLength = carrouselLength - 2;
      } else {
        carrouselLength = carrouselLength - 3;
      }

      if (carrouselLength <= 0) {
        return false;
      }

      if (isItDesktop) {
        return true;
      } else {
        return false;
      }
    },
    doPause() {
      if (!this.isPaused) {
        this.$refs.dynamicCarrousel.pause();
        this.isPaused = true;
      } else {
        this.$refs.dynamicCarrousel.start();
        this.isPaused = false;
      }
      this.setPauseIcone(true);
    },
    setPauseIcone: function (isHover) {
      this.pauseIconeSrc = this.getPauseIconeSrc(isHover);
    },
    getPauseIconeSrc: function (isHover) {
      //TODO: Mieux gerer ces valeurs
      var imgPath = this.srcIconePause;
      var iconName = "ic_pause.svg";
      if (isHover) {
        iconName = "ic_pause_over.svg";
      }
      if (this.isPaused) {
        iconName = "ic_play.svg";
        if (isHover) {
          iconName = "ic_play_over.svg";
        }
      }
      imgPath += iconName;
      return this.getImageSrc(imgPath);
    },
    verifyIfThreeSlides(mq) {
      if (mq == "xs" || mq == "md" || mq == "xl" || mq == "xxl") {
        return true;
      } else if (mq == "sm" || mq == "lg" || mq == "xxxl") {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

::v-deep .carousel-indicators > li {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  opacity: 1;
  background-color: transparent;
  border: 2px solid #9a9a9a;
  outline: none;
  outline-color: transparent;
}
::v-deep .carousel-indicators li.active {
  background-color: #0dcb8f;
}

::v-deep .pause-carousel svg {
  fill: #9a9a9a;
}

.pause-carousel {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2%;
  margin-bottom: 1rem;
  z-index: 2;
  height: 3rem;
}

.carousel_image_pause {
  height: 100%;
}

.mobile-carrousel {
  @include media-breakpoint-only(xxs) {
    transform: translate3d(-98%, 0px, 0px);
    width: 80%;
  }

  @include media-breakpoint-only(xs) {
    transform: translate3d(-53.5%, 0px, 0px);
  }

  @include media-breakpoint-up(md) {
    transform: translate3d(-24.5%, 0px, 0px);
  }

  @include media-breakpoint-up(lg) {
    transform: translate3d(-29.5%, 0px, 0px);
    width: 70%;
  }
}

::v-deep .carousel-control-next-icon {
  filter: invert(62%) sepia(5%) saturate(7%) hue-rotate(307deg) brightness(157%) saturate(91%);
}
::v-deep .carousel-control-prev-icon {
  filter: invert(62%) sepia(5%) saturate(7%) hue-rotate(307deg) brightness(157%) saturate(91%);
}

::v-deep .carousel-control-next {
  width: 5%;
  opacity: 1;
}
::v-deep .carousel-control-prev {
  width: 5%;
  opacity: 1;
}
</style>
