(function($) {

  var EJPORTAIL_FAVORIS_P1 = "EJFavoris";
  var FILTRE_FAVORI = "label[for|='filtre-indFavoris']";

  $.fn.initialiserFavoris = function (isInstants = false) {
    var favmas_string = localStorage.getItem(EJPORTAIL_FAVORIS_P1);

    var hasConsent = false;
    if (isInstants) {
      if (typeof Didomi === "undefined") {
        // Initialiser les favoris Instants
        localStorage.setItem("EJFavoris", "[]");
        document.querySelectorAll("i.fa.fa-star").forEach(function (item) {
          item.classList.add("inactive");
          item.classList.remove("active");
        });
        document.querySelectorAll(".warning-consent").forEach(function (item) {
          item.classList.remove("d-none");
        });
        document.querySelectorAll(".warning-consent-mobile").forEach(function (item) {
          item.classList.remove("d-none");
        });
        document.querySelectorAll(".warning-consent-jeu-desktop").forEach(function (item) {
          item.classList.remove("d-none");
        });
        document.querySelectorAll(".warning-consent-jeu-mobile").forEach(function (item) {
          item.classList.remove("d-none");
        });
        hasConsent = false;
      }
      // TODO: mettre vendorId preferences dans une variable globale
      hasConsent = registerVendorListenerConsent(
        "c:ceinterpre-YF6CtigG",
        function () {
          document.querySelectorAll("i.fa.fa-star").forEach(function (item) {
            item.classList.remove("inactive");
          });
          document.querySelectorAll(".warning-consent").forEach(function (item) {
            item.classList.add("d-none");
          });
          document.querySelectorAll(".warning-consent-mobile").forEach(function (item) {
            item.classList.add("d-none");
          });
          document.querySelectorAll(".warning-consent-jeu-desktop").forEach(function (item) {
            item.classList.add("d-none");
          });
          document.querySelectorAll(".warning-consent-jeu-mobile").forEach(function (item) {
            item.classList.add("d-none");
          });
          return true;
        },
        function () {
          // Initialiser les favoris Instants
          localStorage.setItem("EJFavoris", "[]");
          document.querySelectorAll("i.fa.fa-star").forEach(function (item) {
            item.classList.add("inactive");
          });
          document.querySelectorAll(".warning-consent").forEach(function (item) {
            item.classList.remove("d-none");
          });
          document.querySelectorAll(".warning-consent-mobile").forEach(function (item) {
            item.classList.remove("d-none");
          });
          document.querySelectorAll(".warning-consent-jeu-desktop").forEach(function (item) {
            item.classList.remove("d-none");
          });
          document.querySelectorAll(".warning-consent-jeu-mobile").forEach(function (item) {
            item.classList.remove("d-none");
          });
          return false;
        }
      );
    }
    if (stringNullOrEmpty(favmas_string)) {
      return;
    } else {
      try {
        var favmas_object = JSON.parse(favmas_string);
      } catch (except) {
        return clearPhase1();
      }
      if (favmas_object === [] || favmas_object === {}) {
        return clearPhase1();
      }
      var gid, gid$;
      for (var i = 0; i < favmas_object.length; i++) {
        gid = favmas_object[i];
        gid$ = $("i[data-favoris='favori_" + gid + "']");

        if (isInstants) {
          gid$.addClass("active");
        } else {
          gid$.removeClass("fa-star-o");
          gid$.addClass("fa-star");
        }
      }
    }
  };

  // Handle Favori Instant Consent
  function registerVendorListenerConsent(vendorId, agreefct, desAgreefct) {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function (Didomi) {
      Didomi.getObservableOnUserConsentStatusForVendor(vendorId).subscribe(function (consentStatus) {
        if (consentStatus === true) {
          agreefct();
        } else {
         desAgreefct();
        }
      });
    });
  }

  function clearPhase1() {
    localStorage.removeItem(EJPORTAIL_FAVORIS_P1);
    return;
  }

  function stringNullOrEmpty(elt) {
    try {
      return elt === null || elt === undefined || elt === "";
    } catch (except) {
      return false;
    }
  }

  $.fn.ajouterAuxFavoris = function (gid, isInstants = false) {
    var leNouveauFavori = gid;
    var leNouvelEnsembleDeFavoris = [];
    var favmas_string = localStorage.getItem(EJPORTAIL_FAVORIS_P1);
    var favmas_object;
    var gid$ = $("i[data-favoris='favori_" + gid + "']");
    if (isInstants && gid$.hasClass("inactive")) {
      return;
    }
    if (stringNullOrEmpty(favmas_string)) {
      /** TODO GDPR conformance, etc. */
      //if(confirm("En cliquant Ok, vous acceptez que des noms de jeux 
      // soit enregistrés sur votre ordinateur
      // dans l'espace prévu à cet effet par votre navigateur");{
      favmas_object = leNouveauFavori;
      /*}*/
    } else {
      var removal_flag = false;
      try {
        favmas_object = JSON.parse(favmas_string);
      } catch (except) {
        favmas_object = [];
      }
      /*etait-ce déjà un favori ?*/
      for (var i = 0; i < favmas_object.length; i++) {
        if (favmas_object[i] === gid) {
          removal_flag = true;
        } else {
          leNouvelEnsembleDeFavoris.push(favmas_object[i]);
        }
      }
      if (removal_flag) {
        //suppression effective, le cas echeant
        if (isInstants) {
          gid$.removeClass("active");
        } else {
          gid$.removeClass("fa-star");
          gid$.addClass("fa-star-o");
        }
        favmas_string = JSON.stringify(leNouvelEnsembleDeFavoris);
        localStorage.setItem(EJPORTAIL_FAVORIS_P1, favmas_string);
        sendLocalStorageToIframes(EJPORTAIL_FAVORIS_P1, favmas_string);
        return false;
      } else {
        favmas_object = leNouvelEnsembleDeFavoris.push(leNouveauFavori);
      }
    }
    favmas_string = JSON.stringify(leNouvelEnsembleDeFavoris);
    localStorage.setItem(EJPORTAIL_FAVORIS_P1, favmas_string);
    if (window.location.hostname.split(".")[0] !== "m") {
      sendLocalStorageToIframes(EJPORTAIL_FAVORIS_P1, favmas_string);
    }

    if (isInstants) {
      gid$.addClass("active");
    } else {
      gid$.removeClass("fa-star-o");
      gid$.addClass("fa-star");
    }
    return false;
  };

  function sendLocalStorageToIframes(key, value) {
    const firstDomain = document.getElementById("iframe-domain-1");
    firstDomain.contentWindow.postMessage(
      {
        action: "save",
        key: key,
        value: value
      },
      $("#iframe-domain-1").attr("src")
    );

    const secondDomain = document.getElementById("iframe-domain-2");
    secondDomain.contentWindow.postMessage(
      {
        action: "save",
        key: key,
        value: value
      },
      $("#iframe-domain-2").attr("src"));
  }
}( jQuery ));
