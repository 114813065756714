<template>
  <b-container fluid v-if="routes && initPage && page">
    <template v-for="aNode in page.main['@nodes']">
      <component
        v-if="page.main[aNode].typeComposante"
        v-bind:is="page.main[aNode].typeComposante"
        :key="page.main[aNode]['@id']"
        :contenu="page.main[aNode]"
      >{{page.main[aNode].typeComposante}}</component>
    </template>
  </b-container>
</template>

<script>
//import { store } from '../store/store';
import { mapState } from "vuex";
import i18n from "../i18n";

import BlocEntete from "../components/bloc/BlocEntete.vue";


//import ejCatfish from "../../js/ej-catfish.js";
import GrandeSection from "../components/container/GrandeSection.vue";
import Heritage from "../components/heritage/Heritage.vue";
import Row from "../components/container/grid/Row.vue";

import { WebServicesAccess } from "../config";
//import { MetaUtil } from "../common/utils";

export default {
  name: "Page",
  components: {
    BlocEntete,
    //ejCatfish,
    GrandeSection,
    Heritage,
    Row,
  },
  data() {
    return {
      page: null,
    };
  },
  props: ["langue", "chemin"],
  created: function () {
    /*      
        if(this.contenu){
          this.page = this.contenu;
          //Enumerer les composants de la page
          MetaUtil.generateMetaPage(this.contenu);
        }
        else {
*/

    this.$store.dispatch("routes/initRoutes").then(
      (valeur) => {},
      (raison) => {}
    );
  },
  computed: {
    ...mapState("routes", ["routes"]),
    initPage: function () {
      var self = this;
      var aRoute = this.routes["/" + this.langue + "/" + this.chemin];

      if (aRoute) {
        var propsChemin = aRoute.props.chemin;

        this.axios
          .get(
            WebServicesAccess.MAGNOLIA_PATH +
              "/" +
              i18n.locale +
              "/json-cache/contenu",
            {
              params: {
                path: propsChemin,
              },
            }
          )
          .then(function (response) {
            self.page = response.data.results[0];
            majLienAutreLangue (self.page.urlAutreLangue);
            //MetaUtil.generateMetaPage(self.page);
          })
          .catch((error) => console.log(error));

        return true;
      }

      return false;
    },
  },
};
</script>