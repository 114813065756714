// Le contenu est mis dans le localStorage afin de pouvoir réinitialiser le contenu. Par exemple, un carousel affichant différentes diapositives lors du login (ajax).

let CacheContent = function(selector){

    const self = this;
    const elements = document.querySelectorAll( selector );
    const elementsLength = elements.length;

    self.init = function() {
        for( let i = 0; i < elementsLength; i++ ){
            const element = elements[i];
            localStorage.setItem( `cacheContent${i}`, element.innerHTML );
        }
    }

    self.refresh = function() {
        for( let i = 0; i < elementsLength; i++ ){
            const element = elements[i];
            element.innerHTML = localStorage.getItem( `cacheContent${i}` );
        }
    }
}

export default CacheContent;