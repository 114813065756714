import Vue from 'vue';
import { i18n, mixinLangue } from '../../mixins/mixinLangue';
import mixinUserMessages from '../../mixins/mixin-user-messages';
import Cookies from '../../../js/components/cookies';
import { isEmptyJsonObj, urlParamsValues, protectedCTA, protectedPage } from '../../mixins/mixin-utils';
import { store } from '../../store/store';
import { mapGetters } from 'vuex';

import FilesSender from '../../components/FilesSender/FilesSender.vue';
import Loading from '../../components/loading/Loading.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import 'whatwg-fetch';



document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById("vueRetraitAssignationApp")) {

        new Vue({
			el: '#vueRetraitAssignationApp',
			i18n,
			mixins: [mixinLangue,
					 mixinUserMessages, 
					 validationMixin, 
					 isEmptyJsonObj, 
					 urlParamsValues, 
					 protectedCTA,
					 protectedPage,					 
					 Cookies],
			store,
            components: {
				Loading,
			},

			data() {
				return {
					componentFilesSender: '',
					accountBank : {
						existing : false,
						pending: false,
					},
					formChqStep1: false,					
					formChqStep2: false,				
					isLoading: false,
					isPhone: null,
					uriRef: null,
					showError: false,
					showSuccess: false,
					showErrorFlinks: false,
					uuid : null,
					settings: {},
					messages: {},
					bank: {
						transitNumber: '',
						institutionId: '',
						accountNumber: '',						
					},					
					isBlurIdBankId: false,
					isBlurBankNum: false,
					isBlurBankAccNum: false,
					ejYoutube : {
						videoId : '',
						yUrl: 'https://www.youtube.com/watch?v=',
						ejDomain: '',
					}, 
					
				};
			},

			validations() {
			  return {
					bank: {
						transitNumber: {
							required,
							exactNumLength: (transitNumber) => /^\d{5}$/.test(transitNumber),						
						},
						institutionId: {
							required,
							exactNumLength: (institutionId) => /^\d{3}$/.test(institutionId),					
						},
						accountNumber: {
							required,
							maxNumLength: (accountNumber) => /^\d{4,12}$/.test(accountNumber),						
						},						
					}
			  }
			},

			created() {				
				const md = new MobileDetect(navigator.userAgent);
				this.isPhone = md.phone();	
				this.isLoading = true;	
				// if(document.referrer.indexOf(this.$t('navUrl.RETRAIT')) < 0 ){
				// 	this.returnToHome();
				// }
			},

			computed: {
				...mapGetters([
					'getAccountUserMessages',
				]),			
			},

			methods: {				
				initRegisterBankAcount: function() {
					const vm = this;					
					console.log('initRegisterBankAcount');
					vm.isLoading = true;
					let myheaders = new Headers();
					myheaders.append('pragma', 'no-cache');
					myheaders.append('cache-control', 'no-cache');
					myheaders.append('JEL-XTOKEN', Cookies.get('Jel-csrf'));
					let ajaxData = new FormData();
					ajaxData.append('lang', vm.mixinLangue_langue);
					ajaxData.append('channel', vm.isPhone ? 'M' : 'I');
					fetch('/services/assignation/init', {
						method: 'POST',
						headers: myheaders,
						body: ajaxData,
					}).then(function( response ){
						return response.json();
					}).then(function( data ){						
						vm.isLoading = false;
						if(data && data.status === 'INITIATED') {	
							console.log(JSON.stringify(data))
							let d = new Date();
            				d.setTime(d.getTime() + (15*60*1000));
							Cookies.set('_paybId', data.id, d)					
							window.location.href = data.redirectUrl;																		
						}
						else if(data && data.connexion === 'invalide') {
							vm.returnToHome();
						}
						else { 																
							vm.showMessageToUser('error', vm.getAccountUserMessages[data.code]);								
						}						
					}).catch(error => {
						vm.isLoading = false;
						vm.showMessageToUser('error', vm.getAccountUserMessages['MSG05-1-20']);						
					});
				},
				showMessageToUser: function(type, msg){
					const vm = this;					
					vm.messages.details = msg !== undefined ? msg.details:undefined;
					vm.showSuccess = vm.showError = false;
					vm.showSuccess = type === 'success';
					vm.showError = type === 'error';
				},
				loadChequeComponent: function() {
					console.log( 'loadChequeComponent' );
					const vm = this;
					vm.isLoading = true;
					let headers = new Headers();
					headers.append('pragma', 'no-cache');
					headers.append('cache-control', 'no-cache');
					let initRequest = { 
						method: 'GET',
						headers: headers
					}
					fetch( '/services/upload-doc?site-id=compte-bancaire&_'+event.timeStamp, initRequest )
							.then(function (response) {
						return response.json();
					}).then(function (data) {
						if( data && data.flag  === 'false' ) {
							vm.isLoading = false;							
							vm.settings = data;
							vm.componentFilesSender = FilesSender;
							if(data.accountNumber) {
								vm.populateBankAccount(data);
							}
						} 
						else if( data && data.connexion  === "invalide" ) {
							vm.returnToHome();
						} 
						else {
							vm.isLoading = false;
							document.querySelector( '.televersement' ).classList.add( 'd-none' );
							document.querySelector( '.televersement__message-uploadid' ).classList.remove( 'd-none' );
						}
					}).catch(error => {
						vm.isLoading = false;
						document.querySelector( '.televersement' ).classList.add( 'd-none' );
						document.querySelector( '.televersement-non-disponible' ).classList.remove( 'd-none' );
						console.log( error );
					});
				},
				evLoading: function(val) {
					this.isLoading = val;
				}, 
				saveCheckInfo: function(){
					this.isloading = true;
					this.$v.$touch()
					if (this.$v.$invalid) {
						vm.showMessageToUser('error', vm.getAccountUserMessages['MSG01']);
					} else {
						this.formChqStep1 = false;
						this.formChqStep2 = !this.formChqStep1;						
						this.isloading = false;
					}						
				},
				nextInputElem: function(nextElem) {					
					if (event.target.value.length == event.target.maxLength) {
						this.$refs[nextElem].focus();				
					}
				},
				initAssignationInfo: function(){
					if(this.getUrlParameter('_msg')) { // && document.referrer.includes("/transition") > 0
						this.showErrorFlinks = true;						
						//vm.showMessageToUser('error', vm.getAccountUserMessages[code]);
					}	
					else {
						const withdrawalAuthStatus = this.getUrlParameter('withdrawalAuthStatus');	
						this.accountBank.existing = withdrawalAuthStatus === 'YES';
						this.accountBank.pending = withdrawalAuthStatus === 'PENDING';
					}
				},
				populateBankAccount(data){
					this.bank.transitNumber= data.transitNumber
					this.bank.institutionId= data.institutionId
					this.bank.accountNumber= data.accountNumber											
				},
				formChqStep1ShowHide(actif) {
					this.formChqStep1 = actif;
				},
				formChqStep2ShowHide(actif){
					this.formChqStep2 = actif;
				}
			},

			beforeMount() {
				this.uuid = this.$el.attributes['data-uuid'].value;	
				let uuidRe = this.$el.attributes['data-uuid-re'].value;	
				let uuiRefer = this.getUrlParameter('uref');
				let editMode = this.$el.attributes['data-edit'].value === 'true';
				if(uuiRefer === uuidRe){
					this.uriRef = this.$t('navUrl.RETRAIT');						
				}else if(!editMode){
					this.returnToHome();						
				}
												
			},

			mounted: function() {
				const vm = this;					
				vm.initAssignationInfo();
				vm.ejYoutube.videoId = this.$el.attributes['data-video-id'].value;
				vm.ejYoutube.ejDomain = this.$el.attributes['data-domain'].value;
				vm.ejYoutube.yUrl = vm.ejYoutube.yUrl + vm.ejYoutube.videoId;			
				vm.isLoading = false;
				//Enregistrement de l'evennement click sur tous les boutons
				vm.isAuthorisedCTA();				
			}
		})
    }
});