<template>
  <div></div>
</template>

<script>
import { mixinCED } from "../../mixins/mixinCED";
import { mixinLangue } from "../../mixins/mixinLangue";

export default {
  mixins: [mixinLangue, mixinCED],

  props: {
    // ej-template/dialogs/components/casino-en-direct/cedTableListeLive.yaml en minuscule
    familleJeu: {
      type: String,
      required: false
    },
    delaiRafraichissementSec: {
      type: Number,
      required: false,
      default: 30
    },
    delaiInactiviteMin: {
      type: Number,
      required: false,
      default: 5
    },
    appareil: {
      type: String,
      required: true
    },
    prefixeUrl: {
      type: String,
      required: true
    }
  },

  data: function() {
    return {
      tableListeLive: [],
      famille: null,
      timer: "",
      isPremierAppelTimer: true,
      idle: null
    };
  },

  computed: {
    CS_OUVERTURE: () => "Ouverture",
    CS_FERMETURE: () => "Fermeture",

    heureOuvertureReel: function() {
      return this.heureReel(this.CS_OUVERTURE);
    },
    heureFermetureReel: function() {
      return this.heureReel(this.CS_FERMETURE);
    }
  },

  created: function() {
    this.chargerFamille(); // Appellera une première fois chargerListeTable()
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    heureReel: function(periode) {
      if (this.familleJeu.toLowerCase() == "toutes") {
        var heure = null;
        for (var keyFamille in this.famille) {
          let aFamille = this.famille[keyFamille];

          if (periode == this.CS_OUVERTURE) {
            // Trouver la plus petite heure d'ouverture
            if (heure == null || aFamille.heureOuvertureFamille < heure) {
              heure = aFamille.heureOuvertureFamille;
            } else {
              return this.famille.heureOuvertureFamille;
            }
          } else {
            // Trouver la plus grande heure d'ouverture
            if (heure == null || aFamille.heureFermetureFamille > heure) {
              heure = aFamille.heureFermetureFamille;
            } else {
              return this.famille.heureFermetureFamille;
            }
          }
        }
        return heure;
      } else {
        return periode == this.CS_OUVERTURE
          ? this.famille.heureOuvertureFamille
          : this.famille.heureFermetureFamille;
      }
    },
    chargerFamille: function() {
      var data = {};
      if (this.familleJeu.toLowerCase() != "toutes") {
        data["famille"] = this.familleJeu;
      }

      var self = this;
      // eslint-disable-next-line no-undef
      $.ajax({
        url:
          this.prefixeUrl + "/" + this.mixinLangue_langue + "/json-cache/casino-en-direct/famille",
        data: data,
        dataType: "json",
        success: function(response) {
          // Si toutes les familles de jeux
          if (response.familles) {
            self.famille = response.tables;
          } else {
            self.famille = response;
          }
          self.startAutoUpdate();
        }
      });
    },
    chargerListeTable: function() {
      var self = this;
      // On force le premier appel pour avoir au moins mises min/max
      if (
        this.isPremierAppelTimer ||
        this.mixinCED_isEnHeureOuverture(
          this.heureOuvertureReel,
          this.heureFermetureReel,
          "America/Toronto"
        )
      ) {
        this.isPremierAppelTimer = false;

		let apiHost = window.location.hostname;
		apiHost = apiHost.replace(apiHost.substring(0, apiHost.indexOf('.')), 'api');
		
        // eslint-disable-next-line no-undef
        $.ajax({
          cache: false,
          url: "https://"+apiHost+"/evo-api/api/v1/salles",
          dataType: "json",
          error: function(ret) {
            self.$bus.$emit("table-liste-live-updated", null);
          },
          success: function(response) {
          	const {can, euro} = response.states;
			
			if(typeof can !== 'unefined'){
				can.source = 'can';
			}
			if(typeof euro !== 'undefined'){
				euro.source = 'euro';
			}
        	
/*        	if (self.familleJeu.toLowerCase() != "toutes") {
          		can.tables = self.chargerListeTableFamille(can, self.familleJeu.toLowerCase());
          		euro.tables = self.chargerListeTableFamille(euro, self.familleJeu.toLowerCase());
        	}
 */       	
            self.tableListeLive = [can, euro];
            self.$bus.$emit("table-liste-live-updated", self.tableListeLive);
          }
        });
      } else {
        self.$bus.$emit("table-liste-live-updated", null);
      }
    },
/*    chargerListeTableFamille: function(data, familleJeu) {
    	return Object.fromEntries(Object.entries(data.tables).filter(([key, value]) => value.gameTypeUnified === familleJeu));
    }, */
    cancelAutoUpdate: function() {
      clearInterval(this.timer);
    },
    startAutoUpdate: function() {
      this.chargerListeTable();
      this.timer = setInterval(this.chargerListeTable, this.delaiRafraichissementSec * 1000); // En secondes
    }
  },
  // De idle-vue
  onIdle() {
    //console.log('onIdle');
    this.cancelAutoUpdate();
  },
  // De idle-vue
  onActive() {
    //console.log('onActive');
    this.startAutoUpdate();
  }
};
</script>
