/****************************************
 * Fonction pour s'assurer que 2 colonnes 
 * adjacentes aient a même longueur
 ****************************************/
function egaliseColonnes(colonnes) {

	var maxSize = colonnes[0].clientHeight;

	$.each(colonnes, function (i) {
		if (maxSize < $(this).height()) {
			maxSize = $(this).height();
		}
	});

	$.each(colonnes, function (i) {
		$(this).height(maxSize);
	});
}

$(document).ready(function() {
	
	if ($(".carteSiteColonne").length) {	
		var colonnes = $("#ejMainContainer").find(".carteSiteColonne");
		egaliseColonnes(colonnes);
	}
});
