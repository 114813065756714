var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-component-name": _vm.$options.name } }, [
    _c("div", { staticClass: "h-50 d-inline-block" }, [
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.getImageSrcVariation(
              _vm.getImageByScreenSize(_vm.contenu)
            ),
            expression: "getImageSrcVariation(getImageByScreenSize(contenu))",
          },
        ],
        staticClass: "w-100",
        attrs: { alt: "" },
      }),
      _vm._v(" "),
      _vm.contenu.surTitre
        ? _c("p", { domProps: { innerHTML: _vm._s(_vm.contenu.surTitre) } })
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.contenu.titre) } }),
      ]),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.contenu.sousTitre) } }),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "h-50 d-inline-block",
      domProps: { innerHTML: _vm._s(_vm.contenu.texte) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }