import EspacejeuxPortail_Browser from './components/espacejeux-portail-browser';
import OSDetect from './components/espacejeux-portail-osdetect';

$(document).ready(function() {

	$("#lienInfoClient").click( function () {
		
		EspacejeuxPortail_Browser.init();
		var flashInfo = '';
		
		var cookieActif = '';
		var flashActif  = '';
		
		if (EspacejeuxPortail_Langue.pageEnAnglais()) {
			$("#navigateur-name").text('Browser');
			if (navigator.language) {
				$("#langue-navigateur").text('Browser language');
			}
			$("#cookies-actif").text('Activated Cookies?');
			$("#version-flash").text('Flash Version');
			$("#version-flash-actif").text('Activated');
			if (EspacejeuxPortail_Browser.flash[0]) {
				flashInfo = 'minimum version required : ';
				$("#version-flash-version").text('Version');
			}
			$("#systeme-exploitation").text('Operating system');
			$("#information-technique").text('Technical info');	
			
			cookieActif = EspacejeuxPortail_Browser.cookieEnabled ? 'Yes': 'No';
			flashActif  = EspacejeuxPortail_Browser.flash[0] ? 'Yes' : 'No';
			
		} else {
			$("#navigateur-name").text('Navigateur');
			if (navigator.language) {
				$("#langue-navigateur").text('Langue du navigateur');
			}
			$("#cookies-actif").text('Cookies activés?');
			$("#version-flash").text('Version Flash');
			$("#version-flash-actif").text('Activée');
			if (EspacejeuxPortail_Browser.flash[0]) {
				flashInfo = 'Version minimum requise : ';
				$("#version-flash-version").text('Version');
			}
			$("#systeme-exploitation").text('Système d\'exploitation');
			$("#information-technique").text('Information technique');
			
			cookieActif = EspacejeuxPortail_Browser.cookieEnabled ? 'Oui': 'Non';
			flashActif  = EspacejeuxPortail_Browser.flash[0] ? 'Oui' : 'Non';
		}
		
		if (EspacejeuxPortail_Browser.browserData[1]) {
			$("#navigateur-name-value").text(EspacejeuxPortail_Browser.browserData[0] + ' ' + EspacejeuxPortail_Browser.browserData[1]);
		} else {
			$("#navigateur-name-value").text(EspacejeuxPortail_Browser.browserData[0]);
		}
		
		if (navigator.language) {
			$("#langue-navigateur-value").text(navigator.language);
        }
		
		$("#cookies-actif-value").text(cookieActif);
		$("#version-flash-actif-value").text(flashActif);	
		
		if ( EspacejeuxPortail_Browser.flash[0]) {
			$("#version-flash-version-value").text(EspacejeuxPortail_Browser.flash[1]  + ' (' +flashInfo + ' ' + EspacejeuxPortail_Flash.VERSION_MINIMALE_FLASH +')');
		}
		$("#systeme-exploitation-value").text(OSDetect);
		$("#information-technique-value").text(navigator.userAgent);
		
		$("#infoClientModal").modal();
	});

});