import Vue from 'vue'
import Vuex from 'vuex'

import bingo from './modules/bingo'
import routes from './modules/routes'

Vue.use(Vuex)

// idle-vue rend disponible state.idleVue à ce niveau

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    bingo,
    routes,
  },
  strict: debug,
})
