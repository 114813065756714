<template>
	<div class="lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1" data-role="lobby-table-game-specific-wrapper">
		<ol class="RecentResultsBar--3aBzj mt-0 mb-0" data-role="recent-results">
			<li v-for="(resultat,index) in tableauResultat" :key="index" :class="['result--3aa_E', getClass(resultat)]" data-role="recent-result">{{getTotalResultat(resultat)}}</li>
		</ol>
	</div>
</template>

<script>
export default {
    props : {
		results : {
			type : Array,
			required : true
		},
		appareil : {
			type : String,
			required : true
		},
		prefixeUrl : {
			type : String,
			required : true
		},
    },
    computed:{
        //Adaptation des résultats obtenus en tableau
        tableauResultat:function(){
            var results = []
            for(var i = 0 ; i < this.results.length ; i++){
                results.push(this.results[i].value.split("|"))
            }
            return results;        
        }
    },
    methods:{
        //Retourne du total des dés donnés en paramêtre
        getTotalResultat:function(resultat){
            return parseInt(resultat[0])+parseInt(resultat[1])+parseInt(resultat[2]);
        },
        //retourne la classe de l'affichage selon le total et les triples
        getClass:function(resultat){
            var total = this.getTotalResultat(resultat)
            if(resultat[0] == resultat[1] && resultat[0] == resultat[2]){
                return "triple--3jlqZ";
            }else if(total > 10){
                return "big--d_7dD";
            }else{ // total <= 10 et n'est pas un triple
                return "small--3mZpM";
            }
        }
    }
}
</script>

<style>
.TableLogo--3RFNG.desktop--1Txr1 {
    z-index: 4
}

.TableLogo--3RFNG.desktop--1Txr1.roulette--2Wcvy {
    bottom: 1.85em
}

.TableLogo--3RFNG.desktop--1Txr1.doubleBall--2sPYM {
    bottom: 2.5em
}

.lobbyTableGameSpecificWrapper--3fAEk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    min-height: 3.35rem;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1em;
    z-index: 4
}

.lobbyTableGameSpecificWrapper--3fAEk.emptyGameHistoryStatistic--Uu-Gi {
    min-height: auto!important
}

.lobby-table-block.occupied .lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .lobbyTableGameSpecificWrapper--3fAEk {
    left: 1px;
    right: 1px
}

.lobbyTableGameSpecificWrapper--3fAEk .lobby-table-block.table-is-full-behind {
    display: none
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk.desktop--1Txr1,.sicbo .lobbyTableGameSpecificWrapper--3fAEk.desktop--1Txr1 {
    font-size: .7em
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk.phone--3PmoR [data-role=recent-results],.sicbo .lobbyTableGameSpecificWrapper--3fAEk.phone--3PmoR [data-role=recent-results] {
    padding: 0
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-results],.sicbo .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-results] {
    box-sizing: border-box;
    width: 100%;
    padding: .2em
}

.lightningdice .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-result],.sicbo .lobbyTableGameSpecificWrapper--3fAEk [data-role=recent-result] {
    margin-top: 0
}

.desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 1.9em;
    z-index: 4
}

.lightningdice .desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk,.sicbo .desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    z-index: 4
}

.desktop--1Txr1 .lobbyTableGameSpecificData--3whkv>* {
    z-index: 4
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    box-sizing: border-box;
    bottom: -1px;
    min-height: 3em;
    padding: 0 .3em .4em
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk.portrait--w-M_r {
    min-height: 2.4em
}

.lobby-table-block.not-operational.table-details-loaded .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    display: none
}

.lobby-table-block.occupied .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    left: 0;
    right: 0
}

.RecentResultsBar--3aBzj {
    display: -webkit-flex;
    display: flex;
    color: #fff;
    font-size: 1.5em;
    width: 100%;
    list-style-type: none;
    line-height: 1.8;
    -webkit-justify-content: space;
    justify-content: space;
    padding-right: .1em;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity .1s linear
}

@media screen and (orientation: landscape) and (max-height:362px) and (min-aspect-ratio:285/181) {
    .RecentResultsBar--3aBzj.gameContext--eZqU6 {
        margin-top:1em;
        left: .2em;
        height: 58%;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-flex-direction: column;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        width: 35px
    }
}

.result--3aa_E {
    width: 1em;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    text-align: center;
    margin: .2em .1em 0;
    color: #e6d6b4;
    background-color: rgba(24,0,0,.69);
    font-weight: 700;
    border-radius: 2px;
    line-height: 2.1em
}

.result--3aa_E.small--3mZpM {
    color: #e6d6b4
}

.result--3aa_E.triple--3jlqZ {
    color: #c80018
}

.result--3aa_E.big--d_7dD {
    color: #8c6d50
}

.result--3aa_E:first-child {
    border: 1px solid #fff;
    font-size: 1.2em;
    line-height: 1.6;
    -webkit-flex-grow: 1.7;
    flex-grow: 1.7;
    margin-top: .167em
}

.result--3aa_E:first-child.small--3mZpM {
    background-color: #46423b
}

.result--3aa_E:first-child.triple--3jlqZ {
    background-color: #3e0b13
}

.result--3aa_E:first-child.big--d_7dD {
    background-color: #311f1c
}

@media (orientation: landscape) {
    .result--3aa_E:nth-child(n+28) {
        display:none
    }
}

@media (orientation: portrait) {
    .result--3aa_E:nth-child(n+16) {
        display:none
    }
}

@media screen and (orientation: landscape) and (max-height:362px) and (min-aspect-ratio:285/181) {
    .gameContext--eZqU6 .result--3aa_E {
        width:100%;
        -webkit-flex-grow: 0;
        flex-grow: 0
    }

    .gameContext--eZqU6 .result--3aa_E:first-child {
        width: auto;
        margin-top: 0;
        -webkit-flex-grow: 0;
        flex-grow: 0
    }
}


</style>