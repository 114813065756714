var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isJeuLoaded
    ? _c(
        "div",
        {
          directives: [
            {
              name: "observe-visibility",
              rawName: "v-observe-visibility",
              value: { callback: _vm.visibilityChanged, throttle: 500 },
              expression: "{ callback: visibilityChanged, throttle: 500 }",
            },
          ],
          staticClass: "casino-jeu-chargement",
          attrs: { "data-element": "jeu" },
        },
        [
          _c("span", { staticClass: "bloc-jeu" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.jeu.titre[_vm.mixinLangue_langue]) +
                "\n    "
            ),
            _c("br"),
            _vm._v("\n    " + _vm._s(_vm.$t("generic.chargement")) + "...\n  "),
          ]),
        ]
      )
    : _vm.$isDomainMobile
    ? _c(
        "div",
        {
          class: _vm.cssCol,
          attrs: { id: "id" + _vm.jeu.idJeu, "data-element": "jeu" },
        },
        [
          _c(
            "div",
            {
              staticClass: "bloc-jeu doc-item",
              class: _vm.renduHTML.blocJeuClasses,
            },
            [
              _c("div", { staticClass: "clearfix" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "featured feature-image favoris-pastille-mobile",
                    staticStyle: { position: "relative" },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm.renduHTML.blocJeuClasses.indexOf("instants") > -1 &&
                    _vm.noConsentFavoriInstants
                      ? _c("div", { staticClass: "warning-consent-mobile" }, [
                          _c("img", {
                            attrs: {
                              src: "/dam/espacejeux/logo/icone-avertissement.svg",
                              alt: "icone avertissement",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tooltip-favoris-text-mobile" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "jeu.instants.favoris.texteConsentement"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    color: "black",
                                    "text-decoration": "underline",
                                  },
                                  attrs: {
                                    href: "javascript:Didomi.reset();Didomi.preferences.show()",
                                    target: "_self",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "jeu.instants.favoris.texteLienConsentement"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fa fa-2x favoris favoris-pastille",
                      class: [
                        _vm.renduHTML.blocJeuClasses.indexOf("instants") > -1
                          ? _vm.hasConsent
                            ? _vm.isFavori
                              ? "fa-star active"
                              : "fa-star"
                            : "fa-star inactive"
                          : _vm.isFavori
                          ? "fa-star"
                          : "fa-star-o",
                      ],
                      attrs: { "data-favoris": "favori_" + _vm.jeu.idJeu },
                      on: {
                        click: function ($event) {
                          return _vm.toggleFavorite()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("a", {
                      attrs: { href: _vm.renduHTML.infoJeuLien },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.renduHTML.bandeaux + _vm.renduHTML.imgContent
                        ),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                !_vm.renduHTML.progressifMasquer
                  ? _c("div", { staticClass: "row-progressif-content" }, [
                      _vm.renduHTML.idProgressifPrincipal !== ""
                        ? _c("div", { staticClass: "text-progressif" }, [
                            _c("span", { staticClass: "fleft info-title" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tc(
                                      "jeu.progressif",
                                      _vm.renduHTML.nbProgressif
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "progressif progressif-moyen",
                              class: [
                                {
                                  "progressif-mock":
                                    _vm.renduHTML.authorInstance,
                                },
                                _vm.renduHTML.progressifClasses,
                              ],
                            }),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "px-15 text-content-detail",
                    staticStyle: { display: "none" },
                  },
                  [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.renduHTML.nbLigne) },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.renduHTML.miseFormat) },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "height-fixe",
                      domProps: { innerHTML: _vm._s(_vm.renduHTML.texteMKT) },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]
      )
    : _c(
        "div",
        {
          class: _vm.cssCol,
          attrs: { id: "id" + _vm.jeu.idJeu, "data-element": "jeu" },
        },
        [
          _c(
            "div",
            {
              staticClass: "bloc-jeu doc-item",
              class: _vm.renduHTML.blocJeuClasses,
              on: {
                mouseover: _vm.loadYoutubePlayer,
                mouseleave: _vm.unloadIframeById,
              },
            },
            [
              _c("div", { attrs: { id: _vm.renduHTML.idJeu } }, [
                _c("time", {
                  staticClass: "dateDebutDesactivationJeu",
                  attrs: { datetime: _vm.renduHTML.dateDebut },
                }),
                _vm._v(" "),
                _c("time", {
                  staticClass: "dateFinDesactivationJeu",
                  attrs: { datetime: _vm.renduHTML.dateFin },
                }),
                _vm._v(" "),
                _c("time", {
                  staticClass: "dateDebutDesactivationFournisseur",
                  attrs: { datetime: _vm.renduHTML.dateDebutFournisseur },
                }),
                _vm._v(" "),
                _c("time", {
                  staticClass: "dateFinDesactivationFournisseur",
                  attrs: { datetime: _vm.renduHTML.dateFinFournisseur },
                }),
              ]),
              _vm._v(" "),
              _vm.renduHTML.imagePreviewSrc.indexOf("jeu-generique") != -1
                ? _c("div", { staticClass: "bloc-jeu-generique" }, [
                    _vm._v("\n      " + _vm._s(_vm.renduHTML.titre)),
                    _c("br"),
                    _vm._v(_vm._s(_vm.renduHTML.idJeu) + "\n    "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "bloc-jeu-main" }, [
                _c("div", { staticClass: "bloc-jeu-info" }, [
                  _c("div", { staticClass: "bloc-jeu-image-container" }, [
                    typeof _vm.renduHTML.lienBoutonJouer !== "undefined" &&
                    _vm.renduHTML.lienBoutonJouer.length > 0
                      ? _c(
                          "a",
                          {
                            staticClass: "bloc-jeu-lien",
                            class: { "ej-block-jeu-video": _vm.videoIsReady },
                            attrs: { href: _vm.renduHTML.lienBoutonJouer },
                          },
                          [
                            _vm.isVideoLoading
                              ? _c("div", { staticClass: "loading-ring" }, [
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "bloc-jeu w-100",
                              attrs: {
                                src: _vm.renduHTML.imagePreviewSrc,
                                alt: _vm.renduHTML.titre,
                              },
                            }),
                            _vm._v(" "),
                            _vm.renduHTML.videoID !== "" && this.loadVideo
                              ? _c(
                                  "div",
                                  { staticClass: "youtube-container" },
                                  [
                                    _c("iframe", {
                                      attrs: {
                                        title:
                                          _vm.jeu.titre[
                                            this.mixinLangue_langue
                                          ],
                                        "video-id": _vm.renduHTML.videoID,
                                        width: "100%",
                                        height: "100%",
                                        "data-src": _vm.lienYoutube,
                                        frameborder: "0",
                                        allowfullscreen: "1",
                                        allow:
                                          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "video-consent-overlay" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "scrollable-section" },
                                          [
                                            _vm._m(1),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "video-consent-overlay-titre",
                                              },
                                              [
                                                _c("h3", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "youtubeVideoConsent.titre"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "video-consent-overlay-text",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "youtubeVideoConsent.texte"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "video-consent-overlay-accept-button",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "youtubeVideoConsent.texteBoutonAccept"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "bloc-jeu-lien",
                            class: { "ej-block-jeu-video": _vm.videoIsReady },
                            on: { click: _vm.launchGame },
                          },
                          [
                            _vm.isVideoLoading
                              ? _c("div", { staticClass: "loading-ring" }, [
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "bloc-jeu w-100",
                              attrs: {
                                src: _vm.imgSrcURI,
                                alt: _vm.renduHTML.titre,
                              },
                            }),
                            _vm._v(" "),
                            _vm.renduHTML.videoID !== "" && this.loadVideo
                              ? _c(
                                  "div",
                                  { staticClass: "youtube-container" },
                                  [
                                    _c("iframe", {
                                      attrs: {
                                        title:
                                          _vm.jeu.titre[
                                            this.mixinLangue_langue
                                          ],
                                        width: "100%",
                                        height: "100%",
                                        "data-src": _vm.lienYoutube,
                                        frameborder: "0",
                                        allow:
                                          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                                        allowfullscreen: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "video-consent-overlay" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "scrollable-section" },
                                          [
                                            _vm._m(2),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "video-consent-overlay-titre",
                                              },
                                              [
                                                _c("h3", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "youtubeVideoConsent.titre"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "video-consent-overlay-text",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "youtubeVideoConsent.texte"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "video-consent-overlay-accept-button",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "youtubeVideoConsent.texteBoutonAccept"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  typeof _vm.renduHTML.lienBoutonJouer !== "undefined" &&
                  _vm.renduHTML.lienBoutonJouer.length > 0
                    ? _c(
                        "a",
                        {
                          staticClass: "bloc-jeu-lien",
                          attrs: { href: _vm.renduHTML.lienBoutonJouer },
                        },
                        [
                          _c("table", {
                            domProps: {
                              innerHTML: _vm._s(_vm.renduHTML.nbLignes),
                            },
                          }),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(_vm.renduHTML.texteMKT),
                            },
                          }),
                          _vm._v(" "),
                          _vm.renduHTML.indEnCasino ||
                          _vm.renduHTML.indOffertSalonJeux
                            ? _c("div", { staticClass: "icone-offert" }, [
                                _c(
                                  "div",
                                  { staticClass: "icone-offert-image" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.renduHTML.imgIconeOffertSrc,
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.renduHTML.indEnCasino &&
                                _vm.renduHTML.indOffertSalonJeux
                                  ? _c(
                                      "div",
                                      { staticClass: "icone-offert-texte" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "white-space": "pre-wrap",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "jeu.indEnCasinoOffertSalonJeux"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.renduHTML.indEnCasino
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "icone-offert-texte",
                                        staticStyle: { "margin-top": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("jeu.indOffertEnCasino")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm.renduHTML.indOffertSalonJeux
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "icone-offert-texte",
                                        staticStyle: { "margin-top": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("jeu.indOffertSalonJeux")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.renduHTML.indMobile
                            ? _c("span", { staticClass: "fa fa-mobile" })
                            : _vm._e(),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "bloc-jeu-lien",
                          on: { click: _vm.launchGame },
                        },
                        [
                          _c("table", {
                            domProps: {
                              innerHTML: _vm._s(_vm.renduHTML.nbLignes),
                            },
                          }),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(_vm.renduHTML.texteMKT),
                            },
                          }),
                          _vm._v(" "),
                          _vm.renduHTML.indEnCasino ||
                          _vm.renduHTML.indOffertSalonJeux
                            ? _c("div", { staticClass: "icone-offert" }, [
                                _c(
                                  "div",
                                  { staticClass: "icone-offert-image" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.renduHTML.imgIconeOffertSrc,
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.renduHTML.indEnCasino &&
                                _vm.renduHTML.indOffertSalonJeux
                                  ? _c(
                                      "div",
                                      { staticClass: "icone-offert-texte" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "white-space": "pre-wrap",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "jeu.indEnCasinoOffertSalonJeux"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.renduHTML.indEnCasino
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "icone-offert-texte",
                                        staticStyle: { "margin-top": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("jeu.indOffertEnCasino")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm.renduHTML.indOffertSalonJeux
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "icone-offert-texte",
                                        staticStyle: { "margin-top": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("jeu.indOffertSalonJeux")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.renduHTML.indMobile
                            ? _c("span", { staticClass: "fa fa-mobile" })
                            : _vm._e(),
                        ]
                      ),
                  _vm._v(" "),
                  _vm.renduHTML.lienStrategieBase !== ""
                    ? _c("div", { staticClass: "progressif-individuel" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "${lqfn.getLink(infoJeu.lienStrategieBase)}",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("jeu.strategieDeBase")))]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                typeof _vm.renduHTML.lienBoutonJouer !== "undefined" &&
                _vm.renduHTML.lienBoutonJouer.length > 0
                  ? _c(
                      "a",
                      {
                        staticClass: "bloc-jeu-lien",
                        attrs: { href: _vm.renduHTML.lienBoutonJouer },
                      },
                      [
                        _c("div", {
                          staticClass: "bloc-jeu-img",
                          domProps: {
                            innerHTML: _vm._s(_vm.renduHTML.blocJeuImg),
                          },
                        }),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "bloc-jeu-lien",
                        on: { click: _vm.launchGame },
                      },
                      [
                        _c("div", {
                          staticClass: "bloc-jeu-img",
                          domProps: {
                            innerHTML: _vm._s(_vm.renduHTML.blocJeuImg),
                          },
                        }),
                      ]
                    ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "bloc-jeu-footer",
                  domProps: { innerHTML: _vm._s(_vm.renduHTML.blocJeuFooter) },
                }),
                _vm._v(" "),
                _vm.noConsentFavoriInstants
                  ? _c("div", { staticClass: "warning-consent" }, [
                      _c("img", {
                        attrs: {
                          src: "/dam/espacejeux/logo/icone-avertissement.svg",
                          alt: "icone avertissement",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tooltip-favoris-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("jeu.instants.favoris.texteConsentement")
                            ) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            staticStyle: {
                              display: "inline-block",
                              color: "black",
                              "text-decoration": "underline",
                            },
                            attrs: {
                              href: "javascript:Didomi.reset();Didomi.preferences.show()",
                              target: "_self",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "jeu.instants.favoris.texteLienConsentement"
                                )
                              )
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "bloc-jeu-boutons" }, [
                  _c("i", {
                    staticClass: "fa fa-2x favoris favoris-pastille",
                    class: [
                      _vm.renduHTML.blocJeuClasses.indexOf("instants") > -1
                        ? _vm.hasConsent
                          ? _vm.isFavori
                            ? "fa-star active"
                            : "fa-star"
                          : "fa-star inactive"
                        : _vm.isFavori
                        ? "fa-star"
                        : "fa-star-o",
                    ],
                    attrs: { "data-favoris": "favori_" + _vm.jeu.idJeu },
                    on: {
                      click: function ($event) {
                        return _vm.toggleFavorite()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.etatBoutonDemo === 1
                    ? _c(
                        "a",
                        {
                          staticClass: "argent-fictif",
                          on: {
                            click: function ($event) {
                              return _vm.launchGame({
                                indDemoInternet: "demoplay",
                              })
                            },
                          },
                        },
                        [
                          _vm.renduHTML.blocJeuClasses.indexOf("instants") > -1
                            ? _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaDemo"))),
                              ])
                            : _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaArgentFictif"))),
                              ]),
                        ]
                      )
                    : _vm.etatBoutonDemo === 2
                    ? _c(
                        "a",
                        {
                          staticClass: "argent-fictif",
                          attrs: { href: _vm.renduHTML.lienBoutonDemo },
                        },
                        [
                          _vm.renduHTML.blocJeuClasses.indexOf("instants") > -1
                            ? _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaDemo"))),
                              ])
                            : _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaArgentFictif"))),
                              ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  typeof _vm.renduHTML.lienBoutonJouer !== "undefined" &&
                  _vm.renduHTML.lienBoutonJouer.length > 0
                    ? _c(
                        "a",
                        {
                          staticClass: "argent-reel",
                          attrs: { href: _vm.renduHTML.lienBoutonJouer },
                        },
                        [
                          _vm.renduHTML.blocJeuClasses.indexOf("instants") > -1
                            ? _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaJouer"))),
                              ])
                            : _vm.jeu.filtres.indexOf("indCedMas") > -1
                            ? _c("strong", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("jeu.ctaJouer")) +
                                    "\n          "
                                ),
                              ])
                            : _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaArgentReel"))),
                              ]),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "argent-reel",
                          on: {
                            click: function ($event) {
                              return _vm.launchGame({
                                indDemoInternet: "realplay",
                              })
                            },
                          },
                        },
                        [
                          _vm.renduHTML.blocJeuClasses.indexOf("instants") > -1
                            ? _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaJouer"))),
                              ])
                            : _vm.jeu.filtres.indexOf("indCedMas") > -1
                            ? _c("strong", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("jeu.ctaJouer")) +
                                    "\n          "
                                ),
                              ])
                            : _c("strong", [
                                _vm._v(_vm._s(_vm.$t("jeu.ctaArgentReel"))),
                              ]),
                        ]
                      ),
                ]),
              ]),
              _vm._v(" "),
              _vm.renduHTML.format === "Flash"
                ? _c(
                    "div",
                    {
                      staticClass: "row divJeuFlash",
                      staticStyle: { display: "none" },
                    },
                    [
                      _c("div", { staticClass: "jeuFlashFlex" }, [
                        _c("div", { staticClass: "jeuFlashFlex--div" }, [
                          _c(
                            "a",
                            {
                              staticClass: "jeuFlashContent",
                              attrs: {
                                "aria-label": "",
                                role: "button",
                                href: "",
                                "data-toggle": "modal",
                                "data-target": "#messageJeuFlash",
                              },
                            },
                            [
                              _c("span", { staticClass: "noDeco" }, [
                                _vm._v(_vm._s(_vm.$t("jeu.flash"))),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "lienPluginFlash" }, [
                                _vm._v(_vm._s(_vm.$t("jeu.pluginNecessaire"))),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chargement" }, [
      _c("img", {
        attrs: {
          src: "/.resources/ej-template-theme/img/ui/loader.gif",
          alt: "chargement",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "video-consent-overlay-logo",
        attrs: {
          src: "/dam/espacejeux/logo/logo-youtube-consent.svg",
          alt: "logo youtube",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "video-consent-overlay-logo",
        attrs: {
          src: "/dam/espacejeux/logo/logo-youtube-consent.svg",
          alt: "logo youtube",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }