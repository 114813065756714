<template>
	<b-alert :data-component-name="$options.name" show :variant="contenu.typeAlerte">
    <span v-html="contenu.texteRich"></span>
  </b-alert>
</template> 

<script>
  import {WebServicesAccess} from '../../config';
 
  export default {
    name:"Alerte",
    data(){
        return{
        }
    },
    props: ["contenu"],
    methods:{
    },
    created: function () {
	}
  }
</script>

<style lang="scss" scoped>
	.row {
		background-position: center;
  		background-repeat: no-repeat;
  	}
	.row.bgcover {
		background-size: cover;
	}
	.container-fluid {
		padding: 0;
	}
</style>