<template>
    <form class="cForm container padding-utils verification-form" @submit.prevent="changementAdresse" data-component="changement-adresse" :uuid="uuid">

		<div v-show="showError" class="generic__error"><div role="alert" v-html="messages.details"></div></div>

        <div class="row gutters-xsmall">
            <div class="col-12">
                <label class="account-label label-margin" for="postcode">{{ $t('monCompte.adresse.postal') }} *</label>
            </div>           
            <div v-show="!afficheInputPostal">
                <autocomplete 
                    :search="search"
                    :debounce-time="300"
                    :get-result-value="getResultValue"            
                    @submit="onSubmit"
                    @blur="handleBlur"></autocomplete>
                    <div v-if="searchPostalInvalide"  class="generic__error"><div role="alert">{{ $t('monCompte.champ.codePostalNonValide') }}</div></div>
            </div>
            <div v-show="afficheInputPostal" class="col-12 form-group">
                <input
                    v-model="$v.address.postcode.$model"
                    type="text"
                    v-mask="'A#A #A#'" 
                    placeholder="___ ___" 
                    class="account__address__input-field mw-80-px"
                    @keydown="switchToSearch($event)"
                    :class="{invalid: $v.address.postcode.$dirty && $v.address.postcode.$invalid}">
                <template v-if="$v.address.postcode.$dirty && $v.address.postcode.$invalid">
                    <div v-if="!$v.address.postcode.valide"  class="generic__error"><div role="alert">{{ $t('monCompte.champ.codePostalNonValide') }}</div></div>
                </template>  
            
            </div> 

        </div>
        <div class="row gutters-xsmall">
            <div class="col-12">
                <label class="account-label label-margin" for="streetNumber">{{ $t('monCompte.adresse.numero')}} *</label>
            </div>
            <div class="col-12 form-group">
                <input
                    ref="inputMunicipal"
                    v-model="$v.address.addressLine1.$model"
                    type="text"
                    class="account__address__input-field mw-80-px"
                    :class="{invalid: $v.address.addressLine1.$dirty && $v.address.addressLine1.$invalid}">  
            <template v-if="$v.address.addressLine1.$dirty && $v.address.addressLine1.$invalid">
                <div v-if="!$v.address.addressLine1.required" class="generic__error"><div role="alert">{{ $t('monCompte.champ.requis') }}</div></div>
                <div v-else-if="!$v.address.addressLine1.isNotSameMunicipalNum" class="generic__error"><div role="alert">{{ $t('monCompte.champ.numeroMunicipalExact', { min:numMunicipalMin}) }}</div></div>
                <div v-else-if="!$v.address.addressLine1.between" class="generic__error"><div role="alert">{{ $t('monCompte.champ.numeroMunicipalMinMax', { min:$v.address.addressLine1.$params.between.min, max:$v.address.addressLine1.$params.between.max }) }}</div></div>
            </template> 
            </div>
           
        </div>
        <div class="row gutters-xsmall">
            <div class="col-12">
                <label class="account-label label-margin" for="streetNumber">{{ $t('monCompte.adresse.rue') }} *</label>
            </div>
            <div class="col-12 form-group">
                 <input
                    v-model="$v.address.addressLine2.$model"
                    type="text"
                    class="account__address__input-field mw-300-px"
                    :class="{invalid: $v.address.addressLine2.$dirty && $v.address.addressLine2.$invalid}">  
            <template v-if="$v.address.addressLine2.$dirty && $v.address.addressLine2.$invalid">
                <div v-if="!$v.address.addressLine2.minLength" class="generic__error"><div role="alert">{{ $t('monCompte.champ.minLength', {min: $v.address.addressLine2.$params.minLength.min} ) }}</div></div>
                <div v-else-if="!$v.address.addressLine2.maxLength" class="generic__error"><div role="alert">{{ $t('monCompte.champ.maxLength', {min: $v.address.addressLine2.$params.maxLength.max} ) }}</div></div>
            </template>                                         
            </div>

         
        </div>
        <div class="row gutters-xsmall">
            <div class="col-12">
                <label class="account-label label-margin" for="streetNumber">{{ $t('monCompte.adresse.appartement') }}</label>
            </div>
            <div class="col-12 ">
                <input class="account__address__input-field mw-80-px" v-model.trim="address.addressLine3" />
            </div>
        </div>

        <div class="row gutters-xsmall">
            <div class="col-12">
                <label class="account-label label-margin" for="streetNumber">{{ $t('monCompte.adresse.ville') }} *</label>
            </div>
            <div class="col-12 form-group">
                 <input
                    v-model="$v.address.city.$model"
                    type="text"
                    class="account__address__input-field mw-300-px"
                    :class="{invalid: $v.address.city.$dirty && $v.address.city.$invalid}">
             <template v-if="$v.address.city.$dirty && $v.address.city.$invalid">
                    <div v-if="!$v.address.city.minLength" class="generic__error"><div role="alert">{{ $t('monCompte.champ.minLength', {min: $v.address.city.$params.minLength.min} ) }}</div></div>
                    <div v-else-if="!$v.address.city.maxLength" class="generic__error"><div role="alert">{{ $t('monCompte.champ.maxLength', {min: $v.address.city.$params.maxLength.max} ) }}</div></div>    
            </template>
        </div>


        </div>
        <input type="hidden" v-model.trim="address.type" />
        <input type="hidden" v-model.trim="address.numeroUnite" />

        <div class="row gutters-xsmall mb-4">
            <div class="col wrapper-province">
                <label class="account-label" for="streetNumber">{{ $t('monCompte.adresse.province') }} :</label>
                 <span id="zoneProvince">Québec</span>
            </div>
        </div>

        <div class="row gutters-small justify-content-start">
            <div class="col-auto">
                <button type="submit" class="account-cta-primary verification-btn gtm-vi-continue" :disabled=" $v.$invalid || isprocessing || srvInitError" >{{ $t('monCompte.bouton.enregistrer') }}</button>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import {i18n} from '../../mixins/mixinLangue';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask'
import { mixinLangue } from '../../mixins/mixinLangue';
import { isEqualObj, detectVbrowser } from '../../mixins/mixin-utils';
import mixinUserMessages from '../../mixins/mixin-user-messages';
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import Cookies from '../../../js/components/cookies';



export default {
	i18n,
    mixins: [mixinLangue, mixinUserMessages, validationMixin, isEqualObj, detectVbrowser],
    directives: {mask},
    components: { Autocomplete },

	data () {
		return {
            showSuccess : false,
            showError: false,
            isprocessing: false,
            srvInitError: false,
            initialAddress: null,
            messages: {					    
                    title: '',
                    details: '',
            },
            numMunicipalMax: null,
            numMunicipalMin: null,
            afficheInputPostal: true,
            searchPostalInvalide: false,
            address : {          
                postcode: null,
                addressLine1: null,  //numero civique
                addressLine2: null,  //rue
                addressLine3: null,  //appartement
                city: null, 
                type: null,
                numeroUnite: null,                
            }
		}
	},

	props: {
		uuid: {
			type: String,
			required: true,
		}
    },
    
    validations() {
		return {
            address : {
                postcode: {
                    required,                
                    valide(postcode){
                        return /^[a-zA-z]\d[a-zA-Z]\s\d[a-zA-Z]\d$/.test(postcode);                    
                    }                         
                },
                addressLine1: {
                    required,
                    between: between(this.numMunicipalMin, this.numMunicipalMax),
                    isNotSameMunicipalNum(value) {
                        if( this.numMunicipalMin == this.numMunicipalMax ){
                            return value == this.numMunicipalMin                      
                         }
                        return true
                    }
                },
                addressLine2: {
                    required,
                    minLength: minLength(3), 
                    maxLength: maxLength(80), 
                },
                city: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(40),  
                },
            }
        }
	},

	computed: {
		...mapGetters([
			'getAccountUserMessages',
        ]),        
    },


	methods: {

        search(input) {
            this.searchPostalInvalide = false
            return new Promise((resolve) => {  
                input = input.replace(/\s/g,'')
                if (input.length < 6) {                    
                    return resolve([])
                }
                else if(!/^[a-zA-z]\d[a-zA-Z]\s*\d[a-zA-Z]\d$/.test(input)) {
                    this.searchPostalInvalide = true
                    return resolve([])
                }                
                fetch(`/services/adresse/recherche?code-postal=${encodeURI(input)}`)
                .then((response) => response.json())
                .then((data) => {
                    if(data){
                        data.forEach(item => {                                    
                            item.municipalRange = 
                                    item.numeroMunicipalMin == item.numeroMunicipalMax ? item.numeroMunicipalMin : item.numeroMunicipalMin + " - " + item.numeroMunicipalMax 
                        });
                    }
                    if (data === undefined || data.length == 0) {
                         this.searchPostalInvalide = true
                    }
                    resolve(data)
                })
            })
        },

        getResultValue(result) {
            return result.municipalRange + ' ' +
                   result.nomRue + ' ' +
                   result.ville + ' '  +
                   result.codePostal + ' ';
        },

        onSubmit(result) {
            console.log(result);
            //Mettre adresse a la sauce OB set tous les input
            this.numMunicipalMax = result.numeroMunicipalMax;
            this.numMunicipalMin = result.numeroMunicipalMin; 
            let adr = {};
            adr.addressLine1 = this.numMunicipalMax == this.numMunicipalMin ?  this.numMunicipalMax : ''; //numero Municipale;            
            adr.addressLine2 = result.nomRue;
            adr.addressLine3 = ''; //numero Unite;
            adr.postcode = result.codePostal;
            adr.city = result.ville;
            adr.type = result.type;                      
            this.populateForm(adr);
            this.$refs.inputMunicipal.focus();  
        },

        handleBlur(){
            this.afficheInputPostal = true;  
        },

        populateForm(address){
            this.address = address; 
            this.afficheInputPostal = true;                          
            this.showError = false;                        
        },

        switchToSearch($event) {
            console.log("-- switch to search --");            
            this.afficheInputPostal = false;
            this.$children[0].value = this.$v.address.postcode.$model;
            this.$el.querySelector('.autocomplete-input').focus()
            $event.preventDefault();
        },
        
        resetForm() {       
            this.address = {
                addressLine1 : '',
                addressLine2 : '',
                addressLine3 : '',
                postcode : '',
                city : '',
            }    
            this.showError = false;
        },



        showMessageToUser(type, msg) {
            const vm = this;
            vm.messages = msg;
            vm.showSuccess = type === 'success';
            vm.showError = type === 'error';
        },	

        InitchangementAdresse: function() {
            var vm = this;    
            if(!vm.isIE11()){
                vm.$emit( 'ev-loading', true);	
            }                    
            vm.isprocessing = true;
            console.log('initialisation-changement-Adresse');
            let prheaders = new Headers({						
                "cache-control": "no-cache",
                "pragma": "no-cache",               
                "content-type":"application/json;charset=UTF-8"
            });
            fetch('/services/adresse?_' + Date.now(), {
                method: 'GET',
                headers: prheaders,
            }).then(function( response ){
                return response.json();
            }).then(function( data ){
                console.log('initchangementAdresse | response ', data);
                vm.$emit( 'ev-loading', false); 
                vm.isprocessing = false;
                if (data && data.postcode){                    
                    vm.populateForm(data);
                    vm.initialAddress = Object.assign({}, data);     
                    vm.$children[0].value = data.postcode;                 
                }
                else {  
                    vm.srvInitError = true;                  
                    if(data && data.connexion == 'invalide') {
                        vm.returnToHome();
                    }
                    else {                 
                        vm.showMessageToUser('error', vm.getAccountUserMessages['MSG01'])
                    }
                                                                
                }                               
            }).catch(error => {
                console.log('initchangementAdresse | fail ', error);
                vm.$emit( 'ev-loading', false);
                vm.isprocessing = false;
                vm.showMessageToUser('error', vm.getAccountUserMessages['MSG01'])	
                vm.srvInitError = true;			
            });

        },


        changementAdresse: function() {
            var vm = this;		
            this.$v.$touch()
            if (vm.$v.$invalid) {
                vm.showMessageToUser('error', vm.getAccountUserMessages['MSG01'])
            }
            else if(vm.checkModifiedDataForm()){
                vm.showMessageToUser('error', vm.getAccountUserMessages['MSG01'])                
            } else {
                vm.$emit( 'ev-loading', true);	
                vm.isprocessing = true;
                console.log('changementAdresse');   
                let myheaders = new Headers();
                myheaders.append('pragma', 'no-cache');
                myheaders.append('cache-control', 'no-cache');
                myheaders.append('JEL-XTOKEN', Cookies.get('Jel-csrf'));                                      
                let ajaxData = new FormData();               
                ajaxData.append('addressLine1',  vm.address.addressLine1);
                ajaxData.append('addressLine2',  vm.address.addressLine2);
                ajaxData.append('addressLine3',  vm.address.addressLine3);
                ajaxData.append('city',  vm.address.city);
                ajaxData.append('postcode', vm.address.postcode);
                fetch('/services/adresse', {
                    method: 'POST',
                    headers: myheaders,
                    body: ajaxData,
                }).then(function( response ){
                    return response.json();
                }).then(function( data ){
                    console.log('changementAdresse | response ', data);                    
                    vm.isprocessing = false;    
                    let aData = JSON.parse(data);
                    if (aData.status == 'success'){                       
                        vm.showError = false;   
                        vm.$emit( 'init-validation');	                     
                        //location.reload(true);
                    }
                    else {
                        vm.$emit('ev-loading', false); 
                        if(aData.connexion == 'invalide') {
                            vm.returnToHome();
                        }
                        else {                 
                            vm.showMessageToUser('error', vm.getAccountUserMessages['MSG01']);
                        }                        
                    }                               
                }).catch(error => {
                    console.log('changementAdresse | fail ', error);
                    vm.$emit( 'ev-loading', false);
                    vm.isprocessing = false;
                    vm.showMessageToUser('error', vm.getAccountUserMessages['MSG01']);			
                });
            }
        },

        checkModifiedDataForm() {
            this.address.postcode = this.address.postcode.replace(/\s/g,'');                      
            return this.isDeepEqualObj(this.address, this.initialAddress);
        },


        
    },
    
    mounted: function() {
        const vm = this
        vm.numMunicipalMin = 0
        vm.numMunicipalMax = Number.MAX_SAFE_INTEGER
        vm.InitchangementAdresse()
    }

}
</script>