// Plugin qui sert à remove des elements dans le dom à partir du lien.
// Just ajouter le nom de l'element, pas besoin d'indicatif id ou class.
// ?ejHide=unElement
// ?ejHide=unElement,deuxElement,troisElement....
const HideElements = () => {
    const list = GetURLParameter("ejHide");
    if( typeof list !== "undefined"){
        const listArr = list.split(",");
        if( listArr.length ){
            listArr.forEach( (e, i) => {
                const idE = $("#"+e);
                const classE = $("."+e);
                if(idE.length) idE.remove();
                if(classE.length) classE.remove();
            });
        }
    }
}

const GetURLParameter = (sParam) => {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++)
    {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam)
        {
            return sParameterName[1];
        }
    }
}

export default HideElements;