var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSpinning(),
            expression: "isSpinning()",
          },
        ],
      },
      [
        _c("div", { staticClass: "js-televersement-initial-loading" }, [
          _c("div", { staticClass: "loading-ring loading-ring--blue" }, [
            _c("div"),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c("div"),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }