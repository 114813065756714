let DetectBrowser = function() {
  const self = this;
  let classArray = [];
  let event;

  self.isDomainMobile = false;

  self.isDomainMobile = false;

  self.isEdge = false;
  self.isIE11 = false; 
  self.isIos = false;
  self.isAndroid = false;

  self.isDomainDesktop = false;
  self.isDomainMobile = false;
  self.isMagnoliaEditMode = false;

  function createNewEvent(eventName) {
    if (typeof Event === "function") {
      event = new Event(eventName);
    } else {
      // Fallback for Internet Explorer 11
      event = document.createEvent("Event");
      event.initEvent(eventName, true, true);
    }
  }

  self.init = function() {
    const ua = navigator.userAgent.toLowerCase();    
    const html = document.querySelector("html");

    createNewEvent("detectBrowser_is-initialized");

    if (ua.indexOf("rv:11") !== -1) {
      classArray.push("internet-explorer-11");
      self.isIE11 = true;
    } else if (ua.indexOf("edge") !== -1) {
      classArray.push("edge");
      self.isEdge = true;
    } else if (
      ua.indexOf("iphone") !== -1 ||
      ua.indexOf("ipad") !== -1 ||
      (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") <= 0) ||
      (ua.indexOf("safari") !== -1 && navigator.maxTouchPoints && navigator.maxTouchPoints > 2 ) 
    ) {
      classArray.push("ios");
      self.isIos = true;
    } else if (ua.indexOf("android") !== -1) {
      classArray.push("android");
      self.isAndroid = true;
    }

    if (window.location.href.indexOf("//m.") !== -1) {
      self.isDomainMobile = true;
      classArray.push("domain-mobile");
    } else if (window.location.href.indexOf("//www.") !== -1) {
      self.isDomainDesktop = true;
      classArray.push("domain-desktop");
    } else if (
      window.location.href.indexOf("/magnoliaAuthor/") !== -1 &&
      (self.isIos || self.isAndroid)
    ) {
      self.isDomainMobile = true;
    } else if (window.location.href.indexOf("/magnoliaAuthor/") !== -1) {
      if (self.isIos || self.isAndroid) {
        self.isDomainMobile = true;
      }
    }

    if (document.querySelector('[href*="magnolia-overrides.css"]') !== null) {
      classArray.push("magnolia-edit-mode");
      self.isMagnoliaEditMode = true;
    }

    for (let i = 0; i < classArray.length; i++) {
      html.classList.add(classArray[i]);
    }

    document.dispatchEvent(event);
  };
};

DetectBrowser = new DetectBrowser();

export default DetectBrowser;
