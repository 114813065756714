import { Trx, VerifierDate } from "./utilities.js";
//import Detectbrowser from "./detectBrowser";

// README
// Logique initialement géré dans ej-owl-carousel. Déplacé pour simplifier la gestion des filtres avec carousel ou autre.
// Voir carousel-area-filter.ftl pour connaitre ce que "settings" contient

let EncapsuleurFiltre = function () {
  const self = this;
  const hideItem = function (encapsuleur) {
    encapsuleur.innerHTML = "";
  };

  const hasRequiredCookie = function (settings) {
    // Valide si l'option "Fichier cookie" a été coché ET que ce cookie existe
    const cookies = document.cookie.split("; ");
    for (var iCookie = 0; iCookie < cookies.length; iCookie++) {
      if (cookies[iCookie].substring(0, cookies[iCookie].indexOf("=")) === settings.nomCookie) {
        return true;
      }
    }
    return false;
  };

  const removeUnauthorizedItems = () => {
    const encapsuleurs = document.querySelectorAll('[data-component~="encapsuleur-filtre"]');
    encapsuleurs.forEach((encapsuleur) => {
      const settings = {
        affichageUnique: JSON.parse(encapsuleur.getAttribute("data-affichage-unique")),
        affichageAudience: encapsuleur.getAttribute("data-affichage-audience"),
        afficherAvecCookie: JSON.parse(encapsuleur.getAttribute("data-afficher-avec-cookie")),
        audience: encapsuleur.getAttribute("data-affichage-audience"),
        carouselContainer: encapsuleur.getAttribute("carousel-container"),
        dateDebut: encapsuleur.getAttribute("data-date-debut"),
        dateFin: encapsuleur.getAttribute("data-date-fin"),
        id: encapsuleur.getAttribute("id"),
        infolettre: encapsuleur.getAttribute("data-infolettre"),
        nomCookie: encapsuleur.getAttribute("data-nom-cookie")
      };

      let user = {
        //eslint-disable-next-line
        audience: typeof ejConnect === "undefined" ? "anon" : ejConnect.etatClient,
        infolettre: null
      };

      // Si dans un envrionnement transactionnel ET dans un état connecté, on récupère la valeur de "contact_ok" (infolettre)
      //eslint-disable-next-line
      if (Trx && loginNav) {
        if (typeof loginNav !== "undefined") {
          //eslint-disable-next-line
          if (loginNav.session_details !== null) {
            //eslint-disable-next-line
            if (typeof loginNav.session_details.contact_ok) {
              //eslint-disable-next-line
              user.infolettre = loginNav.session_details.contact_ok;
            }
          }
        }
      }

      localStorage.setItem(
        "encapsuleurFiltre-content-initial_" + encapsuleur.getAttribute("id"),
        encapsuleur.innerHTML
      );

      var hideContenu = false;
      if (hideContenu) {
        hideItem(encapsuleur);
      } else if (!VerifierDate(settings.dateDebut, settings.dateFin)) {
        hideItem(encapsuleur);
      } else if (settings.affichageUnique &&
        localStorage.getItem("encapsuleurFiltre-affichage-unique_" + settings.id)) {
        // Valide si l'option "une seule fois" a été coché
        hideItem(encapsuleur);
      } else if (settings.afficherLorsqueFichierTemoinPresent &&
        !hasRequiredCookie(settings) &&
        settings.nomCookie !== "") {
        hideItem(encapsuleur);
      } else if (settings.audience.indexOf(user.audience) === -1) {
        hideItem(encapsuleur);
      } else if (user.audience === "anon" && settings.infolettre !== "notSelected") {
        hideItem(encapsuleur);
      } else if (user.audience !== "anon" &&
        settings.infolettre !== "notSelected" &&
        user.infolettre !== settings.infolettre) {
        hideItem(encapsuleur);
      } else {
        encapsuleur.classList.remove("d-none");
      }
      if (settings.affichageUnique &&
        !localStorage.getItem("encapsuleurFiltre-affichage-unique_" + settings.id)) {
        localStorage.setItem("encapsuleurFiltre-affichage-unique_" + settings.id, true);
      }
    });
  };

  self.init = function () {
    removeUnauthorizedItems();
  };

  // Replace current content with initial content
  self.reset = function () {
    const encapsuleurs = document.querySelectorAll('[data-component~="encapsuleur-filtre"]');
    const encapsuleursLength = encapsuleurs.length;

    for (let i = 0; i < encapsuleursLength; i++) {
      const encapsuleur = encapsuleurs[i];
      const content = localStorage.getItem(
        "encapsuleurFiltre-content-initial_" + encapsuleur.getAttribute("id")
      );

      encapsuleur.classList.add("d-none");
      encapsuleur.innerHTML = content;
    }
  };
};

EncapsuleurFiltre = new EncapsuleurFiltre();

export default EncapsuleurFiltre;
