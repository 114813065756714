
export default {
    state: {
        jeux: {
            filtres: {
                categories: [], // Choix multiple (checkbox)
                type: "",		// Choix simple (radio)
                nbligne: "",	// Choix simple (radio)
                mntgroslot: "",	// Choix simple (radio)
                custom: [],		// Choix multiple (checkbox)
            },
            listeFiltree: {},
            listeFavoris: [],
            recherche: '',
            tri: 'ini'
        }
    },
    getters: {
        getFiltres: state => {
            return state.jeux.filtres;
        },
        getJeuxListeFiltree: state => {
            return state.jeux.listeFiltree;
        },
        getTri: state => {
            return state.jeux.tri;
        }
    },
    mutations: {
        updateFiltres: (state, payload) => {
            state.jeux.filtres = payload;
        },
        updateJeuxListeFiltree: (state, payload) => {
            state.jeux.listeFiltree = payload;
        },
        updateRecherche: (state, payload) => {
            state.jeux.recherche = payload;
        },
        updateTri: (state, payload) => {
            localStorage.setItem( "ejTri", payload );
            state.jeux.tri = payload;
        }
    }
}
