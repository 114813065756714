import Cookies from "./cookies";

let count = 0;
const KEYNAME = "newsletterModalId";
const EjNewsletter = () => {
    if ($(".ej-modal-container-edit-modal").length > 0) return false;
    checkEjConnectAndLoginNav().then((resolve) => {
        let newsletters = $(
            ".ej-modal-newsletter:not(.ej-modal-newsletter-confirmation),.ej-div-newsletter"
        );
        if (!resolve)
            newsletters = $(
                '.ej-modal-newsletter[data-audience="anon"]:not(.ej-modal-newsletter-confirmation),.ej-div-newsletter.ej-div-main'
            );
        if (newsletters.hasClass("ej-div-main")) {
            if (loginNav.session_details !== null) {
                $(".ej-div-newsletter-connect").addClass("d-none");
                if (loginNav.session_details.contact_ok == "Y") {
                    $(".ej-div-newsletter-deja-membre").removeClass("d-none");
                } else {
                    $(".ej-div-main").removeClass("d-none");
                    newsletters.each((index, newsletter) => {
                        $(".ej-check-newsletter").removeAttr("disabled");
                        const $newsletter = $(newsletter);
                        let startOn = $newsletter.attr("data-start-on");
                        let analytics = null;
                        if ($newsletter.hasClass("ej-modal-newsletter")) {
                            startOn = $newsletter.attr("data-start-on");
                            analytics = $newsletter.attr("data-analytics").replace(/'/g, '"');
                            if (doNotDisplayNewsletter()) {
                                if (checkConditionDate(startOn)) {
                                    if (checkIfNewsletterIsInCookie($newsletter)) {
                                        setupAudienceDisplay($newsletter);
                                    }
                                    addGoogleAnalytics(analytics);
                                }
                            }
                            closeModal($newsletter);
                        } else if (!$newsletter.hasClass("d-none")) {
                            if ($newsletter.attr("data-analytics")) {
                                analytics = $newsletter.attr("data-analytics").replace(/'/g, '"');
                            }
                            if (checkConditionDate(startOn)) {
                                if (!$newsletter.hasClass("ej-div-footer")) {
                                    $("html, body").scrollTop($newsletter.offset().top);
                                }
                                if (analytics != null) {
                                    addGoogleAnalytics(analytics);
                                }
                                updateContactOk();
                            }
                        }
                    });
                }
            } else {
                $(".ej-check-newsletter").attr("disabled");
            }
        } else {
            if (newsletters.length > 0) {
                newsletters.each((index, newsletter) => {
                    const $newsletter = $(newsletter);
                    let startOn = $newsletter.attr("data-start-on");
                    let analytics = $newsletter.attr("data-analytics").replace(/'/g, '"');
                    if (doNotDisplayNewsletter()) {
                        if (checkConditionDate(startOn)) {
                            if (checkIfNewsletterIsInCookie($newsletter)) {
                                setupAudienceDisplay($newsletter);
                            }
                            addGoogleAnalytics(analytics);
                        }
                    }
                    closeModal($newsletter);
                });
                if ($(".ej-modal-newsletter.show:not(.ej-modal-newsletter-confirmation)").length === 0)
                    $("body").removeClass("ej-newsletter-modal-open");
                // Click Actions...
                updateContactOk();
            }
        }
    });
};

const setupAudienceDisplay = (newsletter) => {
    $("body").addClass("ej-newsletter-modal-open");
    if (Cookies.hasItem("autoexclusion") && Cookies.get("autoexclusion") === "true") {
        if (newsletter.attr("data-audience") === "cae") {
            newsletter.modal("show");
            return;
        }
        $("body").removeClass("ej-newsletter-modal-open");
        return;
    }
    if (ejConnect.etatClient === newsletter.attr("data-audience")) newsletter.modal("show");
};

const doNotDisplayNewsletter = () => {
    if (loginNav === null) return true;
    if (loginNav.session_details !== null && loginNav.session_details.contact_ok !== "Y") return true;
    return false;
};
const checkIfNewsletterIsInCookie = (newsletter) => {
    if (Cookies.hasItem(getKey(newsletter))) return false;
    return true;
};
const closeModal = (newsletter) => {
    newsletter.on("hidden.bs.modal", function (e) {
        const $this = $(this);
        $("body").removeClass("ej-newsletter-modal-open");
        Cookies.set(getKey($this), true, parseInt($this.attr("data-times-to-hide")));
    });
};

const updateContactOk = () => {
    $(document).on(
        "click",
        ".ej-btn-modal-newsletter[data-modal-confirmation],.ej-btn-container[data-div-confirmation],.ej-btn-div-newsletter[data-div-confirmation]",
        function (e) {
            e.preventDefault();

            const $this = $(this);
            const $parent = $this.closest(".ej-newsletter-body-container-bottom");

            if ($parent.find("#message-erreur")) {
                if (!$parent.find("#message-erreur").hasClass("d-none")) {
                    $parent.find("#message-erreur").addClass("d-none");
                }
            }
            if ($parent.hasClass("ej-modal-container-error")) return false;

            if ($this[0].childElementCount == 1) {
                if ($this[0].className == "btn ej-btn-div-newsletter abonne") {
                    $this.append(
                        '<div class="loading-ring abonne ej-modal-button-loading" style="display:none;"><div></div></div></div>'
                    );
                } else {
                    $this.append(
                        '<div class="loading-ring ej-modal-button-loading" style="display:none;"><div></div><div></div><div></div><div></div></div>'
                    );
                }
            }

            $this.width($this.width());
            if ($(".ej-check-container") && $parent.find("#check").length !== 0) {
                if (!$parent.find("#check").is(":checked")) {
                    if ($parent.find("#message-erreur").hasClass("d-none")) {
                        $parent.find("#message-erreur").removeClass("d-none");
                    }
                    return false;
                }
            }

            $this.find("span").fadeOut("slow", () => {
                $this.find(".ej-modal-button-loading").fadeIn("slow", () => {
                    $.ajax({
                        url: "/services/infolettre-modification",
                        headers: { 'JEL-XTOKEN' : Cookies.get('Jel-csrf') },
                        method: "POST",
                        success: (response) => {
                            if (response.status === "SUCCESS") {
                                const modal = $this.closest(".ej-modal-newsletter,.ej-div-newsletter");
                                const modalConfirm = $(
                                    $this.attr("data-modal-confirmation") || $this.attr("data-div-confirmation")
                                );
                                $(".usage").text($("#mel-nomComplet").text());
                                if (modal.hasClass("ej-div-newsletter")) {
                                    modal.addClass("d-none");
                                    modalConfirm.removeClass("d-none");
                                } else if (modal.hasClass("ej-modal-newsletter")) {
                                    modal.modal("hide");
                                    modalConfirm.modal("show");
                                }
                            } else {
                                const modal = $this.closest(".ej-modal-newsletter,.ej-div-newsletter");
                                const modalErreur = $(
                                    $this.attr("data-modal-erreur") || $this.attr("data-div-erreur")
                                );
                                $(".usage").text($("#mel-nomComplet").text());
                                if (modal.hasClass("ej-div-newsletter")) {
                                    modal.addClass("d-none");
                                    modalErreur.removeClass("d-none");
                                } else if (modal.hasClass("ej-modal-newsletter")) {
                                    modal.modal("hide");
                                    modalErreur.modal("show");
                                }
                            }
                        },
                        error: (error) => {
                            console.log("Ajax error", error);
                        },
                    });
                });
            });
        }
    );
};

const getKey = (newsletter) => {
    return KEYNAME + newsletter.attr("id").replace(/-/g, "");
};
const addGoogleAnalytics = (analytics) => {
    if (analytics === "") return false;
    analytics = JSON.parse(analytics);
    EspacejeuxPortail_GoogleAnalytics.enregistrerEvenement(
        analytics.category,
        analytics.action,
        analytics.label,
        0
    );
};
const checkConditionDate = (date) => {
    if (date === "") return true;
    const now = new Date();
    date = new Date(parseInt(date));
    if (date > now) return false;
    return true;
};
const checkEjConnectAndLoginNav = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            if (typeof ejConnect !== "undefined" && typeof loginNav !== "undefined") return resolve(true);
            count++;
            if (count > 20) return resolve(false);
            checkEjConnectAndLoginNav();
        }, 1800);
    });
};

export default EjNewsletter;
