var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container h-100 d-flex",
      class: { saviez_vous_que: _vm.isSaviezVous },
      attrs: { "data-component-name": _vm.$options.name },
    },
    [
      _vm.contenu.image
        ? _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.getImageSrcVariation(
                  _vm.getImageByScreenSize(_vm.contenu)
                ),
                expression:
                  "getImageSrcVariation(getImageByScreenSize(contenu))",
              },
            ],
            staticClass: "w-100 image",
            attrs: { alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "div_container",
          class: [
            _vm.contenu.cssClass
              ? _vm.contenu.cssClass.includes("padding_media_image")
                ? "padding_media_image"
                : ""
              : "",
            _vm.isSaviezVous ? "saviez_vous_ctn" : "",
          ],
        },
        [
          _c("div", {
            staticClass: "mb-1 mt-2 titre_media graphik_bold",
            style: { color: _vm.contenu.couleurTitre },
            domProps: { innerHTML: _vm._s(_vm.contenu.titre) },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "texte",
            domProps: { innerHTML: _vm._s(_vm.contenu.texte) },
          }),
          _vm._v(" "),
          _vm.getMediaImageUrl(_vm.contenu)
            ? _c("a", {
                staticClass: "stretched-link",
                attrs: { href: _vm.getMediaImageUrl(_vm.contenu) },
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }