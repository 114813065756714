var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.routes && _vm.initPage && _vm.page
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _vm._l(_vm.page.main["@nodes"], function (aNode) {
            return [
              _vm.page.main[aNode].typeComposante
                ? _c(
                    _vm.page.main[aNode].typeComposante,
                    {
                      key: _vm.page.main[aNode]["@id"],
                      tag: "component",
                      attrs: { contenu: _vm.page.main[aNode] },
                    },
                    [_vm._v(_vm._s(_vm.page.main[aNode].typeComposante))]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }