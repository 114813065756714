<template>
  <div :data-component-name="$options.name">
    <div class="h-50 d-inline-block" >
      <img v-lazy="getImageSrcVariation(getImageByScreenSize(contenu))" class="w-100" alt="" />

      <p v-if="contenu.surTitre" v-html="contenu.surTitre"></p>
      <h3>
        <span v-html="contenu.titre"></span>
      </h3>
      <p v-html="contenu.sousTitre"></p>
    </div>
    <div class="h-50 d-inline-block" v-html="contenu.texte">
    </div>
  </div>

</template>

<script>
export default {
  name: "MediaImageImg60txt40",
  data() {
    return {
    };
  },
  props: ["contenu"],
  methods: {},
  computed: {
    classObject: function () {
      return {
        rounded: this.contenu.coinArrondi
      }
    },
    styleObject: function () {
      return {
        'background-color': this.contenu.secBgThemeColor
      }
    },
  },
  created: function() {}
};
</script>

<style lang="scss" scoped>
.row {
  background-position: center;
  background-repeat: no-repeat;
}
.row.bgcover {
  background-size: cover;
}
.container-fluid {
  padding: 0;
}
</style>