import { helpers } from 'vuelidate/lib/validators'

const lqBetween = (min, max) => 
  helpers.withParams(
    { type: 'lqBetween', min: min, max: max }, 
        (value) => !helpers.req(value) || 
                  ( 
                    +min <= +value.replace(/\,/g, '.').replace(/\$/g, '').replace(/\s/g, '') && 
                    +max >= +value.replace(/\,/g, '.').replace(/\$/g, '').replace(/\s/g, '')
                  ) //!/\s/.test(value)  && 
        
	)


const lqDecimal = (vm) => 
helpers.withParams(
  { type: 'lqDecimal', vm: vm},
  (value, vm) => {
        //if(vm.mixinLangue_langue == "en"){
        //    return !helpers.req(value) || /^[-]?\d*(\.\d{1,2})?$/.test(value);
        //}
        return !helpers.req(value) || /^[-]?\d*(\,\d{1,2})?$/.test(value)|| /^[-]?\d*(\.\d{1,2})?$/.test(value);
    }
)
   
export {lqBetween, lqDecimal}