import DetectBrowser from './detectBrowser';

window.ShareButton = function () {
	const self = this;
	const fbMessengerURI = "fb-messenger://share?link={0}&app_id={1}";
	var fbShareURI = "https://www.facebook.com/sharer/sharer.php?u={0}&app_id={1}&display=popup";
	const emailShareURI = "mailto:?subject={0}&body={1}";
	let $popover = null;

	self.init = function () {
		$popover = $('[data-toggle="popover"]');

		$popover.popover({
			html: true,
			sanitize: false,
			trigger: 'manual',
			content: function () {
				return $(".share-casino__popover").html();
			}
		}).on("click", function () {
			$popover.popover("show");
		}).on('shown.bs.popover', function() {
			

			document.querySelector('html').addEventListener( 'click', hidePopover );

			document.querySelector('.popover.show [data-sharetofacebook]').addEventListener('click', function(){
				ShareButton.shareToFacebook()
			});
	
			if( document.querySelector('[data-sharetomessenger]') !== null ){
				document.querySelector('.popover.show [data-sharetomessenger]').addEventListener('click', function(){
					ShareButton.shareToMessenger()
				});
			}
	
			document.querySelector('.popover.show [data-sharebyemail]').addEventListener('click', function(){
				ShareButton.shareByEmail()
			});
	
			document.querySelector('.popover.show [data-share-copyclipboard]').addEventListener('click', function(){
				ShareButton.copyToClipboard()
			});
		}).on('hide.bs.popover', function(){
			document.querySelector('html').removeEventListener( 'click', hidePopover );
		});

		ShareButton.adjustDisplay(); 

	}

	const hidePopover = function(e) {
		
		if( $(e.target) !== $(".popover") && $(e.target).closest('.popover').length === 0 ) {
			$popover.popover("hide");
		}
	}

	self.adjustDisplay = function () {
		var newParent = $('.game-info-share');
		var sharebutton = $('.share-casino__wrapper-button');
		if (!DetectBrowser.isDomainMobile) {
			newParent = $('.entete-separateur');
			newParent.addClass('overflow-visible');
		}
		newParent.append(sharebutton);
	};

	self.shareToMessenger = function () {
		const facebookId = document.querySelector('.popover.show [data-sharetomessenger]').getAttribute('data-sharetomessenger');
		var uri = ShareButton.formatString(fbMessengerURI, [encodeURIComponent(window.location), facebookId]);
		window.open(uri);
	}

	self.shareToFacebook = function () {
		const facebookId = document.querySelector('.popover.show [data-sharetofacebook]').getAttribute('data-sharetofacebook');
		var uri = ShareButton.formatString(fbShareURI, [encodeURIComponent(window.location), facebookId]);
		window.open(uri);
	}

	self.showToast = function () {
		$('.share-casino__toast').addClass('share-casino__toast-show');
		setTimeout(function () {$('.share-casino__toast').removeClass('share-casino__toast-show');}, 3000);
	}

	self.shareByEmail = function () {
		const $sharebyemail = document.querySelector('.popover.show [data-sharebyemail]');
		var subject = "";
		var body = "";
		const title = $sharebyemail.getAttribute('data-title');

		if (isLoggedIn()) {
			subject = $sharebyemail.getAttribute('data-emailSubjectConnected');
			body = $sharebyemail.getAttribute('data-emailBodyConnected');
		} else if (ejApi.clientEstInvite()) {
			subject = $sharebyemail.getAttribute('data-emailSubjectGuest');
			body = $sharebyemail.getAttribute('data-emailBodyGuest');
		} else {
			subject = $sharebyemail.getAttribute('data-emailSubjectGuest');
			body = $sharebyemail.getAttribute('data-emailBodyGuest');
		}

		subject = ShareButton.replaceVariables(subject, "$CLIENT_NAME", ejApi.lirePrenomJoueur() + " " + ejApi.lireNomJoueur());
		subject = ShareButton.replaceVariables(subject, "$GAME_NAME", title);
		subject = ShareButton.replaceVariables(subject, "$GAME_URL", window.location);
		subject = ShareButton.cleanUp(subject);

		body = ShareButton.replaceVariables(body, "$CLIENT_NAME", ejApi.lirePrenomJoueur() + " " + ejApi.lireNomJoueur());
		body = ShareButton.replaceVariables(body, "$GAME_NAME", title);
		body = ShareButton.replaceVariables(body, "$GAME_URL", window.location);
		body = ShareButton.cleanUp(body);

		var uri = ShareButton.formatString(emailShareURI, [encodeURIComponent(subject), encodeURIComponent(body)]);
		window.open(uri);
	}

	self.replaceVariables = function (text, variable, value) {
		if( text === null || text == undefined ) {
			return;
		}
		return text.replace(variable, value);
	}

	self.cleanUp = function (text) {
		if( text === null || text == undefined ) {
			return;
		}

		return text.replace("'", "’")
			.replace("&#039;", "’")
			.replace("&amp;", "&")
			.replace("<br/>", "\n")
			.replace("<br>", "\n");
	}

	self.formatString = function (string1, argumentsNew) {
		var regexInstanceNameChar = /{(\d+)}/g;
		var argumentsToReplace = string1.match(regexInstanceNameChar);

		for (var i = 0; i < argumentsToReplace.length && i < argumentsNew.length; i++) {
			string1 = string1.replace(argumentsToReplace[i], argumentsNew[i]);
		}
		return string1;
	}

	self.copyToClipboard = function () {

		var el = document.createElement('textarea');
		el.value = window.location;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		var selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

		var range, selection;

		if (DetectBrowser.isIos) {
			range = document.createRange();
			range.selectNodeContents(el);
			selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
			el.setSelectionRange(0, 999999);
		} else {
			el.select();
		}

		document.execCommand('copy');
		document.body.removeChild(el);
		if (selected) {
			document.getSelection().removeAllRanges();
			document.getSelection().addRange(selected);
		}

		ShareButton.showToast();
	};

}

ShareButton = new ShareButton();

export default ShareButton;