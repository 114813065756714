<template>
  <div
    data-tableid="ejx1a04w4ben0mou"
    :data-game="gameTypeClass"
    class="table lobby-table-block table-details-loaded"
    :class="[gameTypeClass, { isTablePremiereOuverte: isTablePremiereOuverte }]"
  >
    <div data-role="table-view" class="lobby-table-block-cnt">
      <div class="lobby-table-block-body">
        <a @click="lancerTable">
          <div
            v-if="table && table.titre && table.titre.toLowerCase().indexOf('lightning') != -1"
            class="lightning-effect"
          ></div>

          <span v-if="etatTable == CS_OUVERT" class="table-state table-state--opened">{{
            $t("ced.ouvert")
          }}</span>
          <span v-else-if="etatTable == CS_FERME" class="table-state table-state--closed">{{
            $t("ced.ferme")
          }}</span>
          <div v-if="etatTable == CS_OUVERT" class="TableSnapshot--2kWcM">
            <!-- "loading=src" permet d'éviter une image noire momentanément -->
            <img
              v-if="tableLive"
              v-lazy="{
                src: videoSnapshotBG,
                error: imageDefaut,
                loading: refreshCount > 1 ? videoSnapshotBG : null,
              }"
              :alt="table.titre"
              class="Thumbnail--3Sc3e"
            />
          </div>
          <div v-else-if="etatTable == CS_CHARGEMENT" class="chargement"></div>
          <div v-else-if="etatTable != CS_OUVERT" class="TableSnapshot--2kWcM">
            <img
              v-lazy="{ src: imageDefaut, error: imageDefaut, loading: imageDefaut }"
              :alt="table.titre"
              class="Thumbnail--3Sc3e"
            />
          </div>
          <!-- Overlay -->
          <transition v-if="!isTablePremiereOuverte" name="fade">
            <div v-if="isShowOverlay" class="lobby-table-overlay">
              <div class="lobby-table-info">
                <span v-if="tableLive && etatTable == CS_OUVERT">
                  <span>{{ $t("ced.presentateur") }}</span
                  >:
                  <span v-if="table.descriptionAuto" class="valeurChamp">{{ $t("ced.auto") }}</span>
                  <span v-else-if="tableLive.dealer && tableLive.dealer.name" class="valeurChamp">{{
                    tableLive.dealer.name
                  }}</span>
                  <span v-else class="valeurChamp">{{ $t("ced.inconnu") }}</span>
                </span>
                <span v-if="tableLive && tableLive.betLimits">
                  <br /><br /><span>{{ $t("ced.miseMin") }}</span> :
                  <span class="valeurChamp">{{ miseMin }}</span>
                </span>
                <br /><br />
                <p v-if="table.description" v-html="table.description"></p>
              </div>
            </div>
            <div v-else class="LobbyTableOverlay--3_xjP lobby-table-overlay"></div>
          </transition>

          <div v-if="tableLive && !isShowOverlay && etatTable == CS_OUVERT" data-role="limit">
            <span
              class="lobby-table-limits mise"
              :class="isTablePremiereOuverte ? 'miseAccueil' : ''"
            >
              {{ miseMin
              }}<span class="max-value" :class="maxMontantMobileClass"
                ><span class="max-value-text"></span>- {{ miseMax }}</span
              >
            </span>
          </div>
          <div
            v-if="languePresentateur && !isShowOverlay && etatTable == CS_OUVERT && isDualPlay"
            class="langue bulleIcon bordure"
          >
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class="presentateur"
                x="0"
                y="0"
                viewBox="0 0 11.4 15.4"
                xml:space="preserve"
              >
                <g>
                  <path
                    class="st0"
                    d="M3.5 8c.1 0 .1 0 0 0l2.2-1.1L7.8 8H8V5.9s0-.1-.1-.1h-.1l-2.1.9-2.1-.9h-.1s-.1 0-.1.1v2s0 .1.1.1z"
                  />
                  <path
                    class="st0"
                    d="M9.5 6.5l-3.8 4.2-3.8-4.2C.1 7.5 0 10.6 0 13.3c0 .5.4.9.9.9s.9-.4.9-.9c0-1 .2-1.9.6-2.7v2.5c0 1.3 1 2.3 2.3 2.3h1.8c1.3 0 2.3-1 2.3-2.3v-2.2-.3c.4.8.6 1.8.6 2.7 0 .5.4.9.9.9s.9-.4.9-.9c.2-2.7.1-5.8-1.7-6.8zM5.7 5.6c1.6 0 2.8-1.3 2.8-2.8C8.5 1.3 7.3 0 5.7 0 4.2 0 2.9 1.3 2.9 2.8c0 1.6 1.3 2.8 2.8 2.8z"
                  />
                </g>
              </svg>
            </div>
            <div class="bulleMessage">
              <span class="">{{ languePresentateur }}</span>
            </div>
          </div>
          <div v-if="table && table.imageLogo" class="lobby-table-logo">
            <img v-lazy="imageLogo" :alt="'Logo - ' + table.titre" class="lobby-table-logo-image" />
          </div>

          <!-- Gestion table ouverte -->
          <div
            v-if="etatTable == CS_OUVERT && !isShowOverlay"
            class="lobby-table-game-specific-wrapper"
          >
            <div class="lobby-table-game-specific-data">
              <ced-table-blackjack
                v-if="tableLive.gameTypeUnified == 'blackjack'"
                :seats="tableLive.seats"
                :bet-behind="tableLive.betBehind"
                :seats-taken="tableLive.seatsTaken"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
                :is-table-premiere-ouverte="isTablePremiereOuverte"
              >
              </ced-table-blackjack>

              <ced-table-roulette
                v-else-if="['roulette', 'americanroulette'].includes(tableLive.gameTypeUnified)"
                :results="tableLive.results"
                :luckyNumbers="tableLive.luckyNumbers"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-roulette>

              <ced-table-baccarat
                v-else-if="['baccarat', 'dragontiger'].includes(tableLive.gameTypeUnified)"
                :gameTypeUnified="tableLive.gameTypeUnified"
                :road="tableLive.road"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-baccarat>

              <ced-table-lightningdice
                v-else-if="tableLive.gameTypeUnified == 'lightningdice'"
                :results="tableLive.results"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-lightningdice>

              <ced-table-sicbo
                v-else-if="tableLive.gameTypeUnified == 'sicbo'"
                :results="tableLive.results"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-sicbo>

              <ced-table-moneywheel
                v-else-if="tableLive.gameTypeUnified == 'moneywheel'"
                :results="tableLive.results"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-moneywheel>

              <ced-table-monopoly
                v-else-if="tableLive.gameTypeUnified == 'monopoly'"
                :results="tableLive.results"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-monopoly>

              <ced-table-topcard
                v-else-if="tableLive.gameTypeUnified == 'topcard'"
                :history="tableLive.history"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-topcard>

              <ced-table-megaball
                v-else-if="tableLive.gameTypeUnified == 'megaball'"
                :results="tableLive.results"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-megaball>

              <ced-table-crazytime
                v-else-if="tableLive.gameTypeUnified == 'crazytime'"
                :results="tableLive.results"
                :appareil="appareil"
                :prefixe-url="prefixeUrl"
              >
              </ced-table-crazytime>

              <!-- Poker, dealnodeal, craps, etc ne nécessitent pas d'affichage particulier -->
            </div>
          </div>
        </a>
      </div>

      <!-- Footer -->
      <div class="TableFooter--3hms2">
        <div class="tableFooter-ratio">
          <div
            class="tableFooter-ratio-content"
            :class="[famille.toLowerCase(), pin && isTablePremiereOuverte ? 'developper' : '']"
          >
            <!-- Footer table (hors accueil CED) -->
            <div
              v-if="!isTablePremiereOuverte"
              :class="['container produit', etatTable != CS_OUVERT ? 'pasOuvert' : '']"
            >
              <div class="contenu">
                <div class="splitGauche centreVerticalRelative">
                  <!-- eslint-disable-next-line vue-a11y/mouse-events-have-key-events -->
                  <span
                    data-role="info-icon"
                    class="TableInfoButton--3t6Qk TableInfoButtonBase TableInfoButtonI espace"
                    :class="{ infoActive: isShowOverlay }"
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                    @click="toggleOverlay()"
                  ></span>
                </div>
                <div v-if="etatTable == CS_OUVERT" class="nomTableHeures">
                  <div class="splitGauche">
                    <span class="titreProduit">{{ table.titre }}</span>
                  </div>
                  <div
                    v-if="etatJoueurs.etat == 'tableComplete'"
                    class="splitDroite tableComplete notSmallGrille"
                  >
                    <span class="tableComplete centreVerticalRelative">
                      {{ $t("ced.tableComplete") }}
                    </span>
                  </div>
                  <div
                    v-else
                    class="splitDroite splitMoitie notSmallGrille"
                    :class="[etatJoueurs.etat]"
                  >
                    <span
                      v-if="etatJoueurs.libelle"
                      class="splitGauche libelle espace centreVerticalRelative"
                    >
                      {{ etatJoueurs.libelle }}
                    </span>
                    <div
                      class="nbplayersIcon splitDroite"
                      :style="
                        etatJoueurs.libelle && etatJoueurs.joueurs == 1
                          ? 'margin:-1px -1px -1px 0'
                          : ''
                      "
                    >
                      <span class="nbJoueurs centreVerticalRelative">{{
                        etatJoueurs.joueurs
                      }}</span>
                      <div class="espaceNombreJoueurs splitDroite centreVerticalRelative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                          class="iconeJoueurs"
                          :class="{ 'non-operationnel': etatTable != CS_OUVERT }"
                        >
                          <use href="#playersOnlineIcon">
                            <g id="playersOnlineIcon">
                              <circle cx="16" cy="7.2" r="5.2"></circle>
                              <path
                                d="M19.3 13.5c-2.1.9-4.6.9-6.7 0-4.8 1.9-7.8 5.6-7.8 11.5 0 1.1.1 1.7.3 2.7.4 0 .8.1 1.2.2.3.1.7.2 1 .2.4-7.2 1.4-7.7 1.5-7.7l.5-.7.7 9c.6.1 1.2.3 1.8.5 1 .4 2.3.8 4.1.8 1.8 0 3-.4 4.1-.8.7-.2 1.2-.5 1.9-.5l.7-9 .5.7c0 .1 1.1.5 1.5 7.7.3 0 .7-.1 1-.2.4-.1.8-.2 1.2-.2.2-1 .3-1.6.3-2.7.1-5.9-2.9-9.6-7.8-11.5z"
                              ></path>
                            </g>
                          </use>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Gestion table désactivée (ou fermée et ouverte 24 heures) -->
                <div
                  v-else-if="
                    etatTable == CS_DESACTIVE || (etatTable == CS_FERME && heuresOuverture == '')
                  "
                  class="nomTableHeures desactive"
                >
                  <div v-if="table && table.msgDesactivation" class="titreProduit desactive">
                    {{ table.titre }}
                    <div>{{ table.msgDesactivation }}</div>
                  </div>
                  <div v-else class="titreProduit desactive">
                    {{ table.titre }}
                    <div>{{ $t("ced.desactive") }}</div>
                  </div>
                </div>

                <!-- Gestion table fermée -->
                <div v-else-if="etatTable == CS_FERME" class="splitGauche nomTableHeures ferme">
                  <span class="titreProduit">{{ table.titre }}</span>
                  <span class="heuresOuvertureLibelle">
                    <span class="heuresOuvertureLibelleProduit">{{ $t("ced.ouverture") }}</span>
                    <span v-if="etatTableApp == CS_FERME" class="heuresOuvertureProduit">{{
                      heuresOuverture
                    }}</span>
                    <span v-else class="heuresOuvertureProduit">{{ heuresOuverture }}</span>
                  </span>
                </div>
              </div>
              <!--fermerture du contenu -->

              <div :class="['infoEtatTable', isShowInfoTable ? 'left' : 'centrer']">
                <!-- design petites grilles -->
                <div
                  v-if="etatJoueurs.etat == 'tableComplete' && isShowInfoTable"
                  class="tableComplete d-none"
                >
                  <span class="tableComplete centreVerticalRelative">
                    {{ $t("ced.tableComplete") }}
                  </span>
                </div>
                <div
                  v-else-if="isShowInfoTable"
                  :class="[etatJoueurs.etat, 'infoEtatJoueurs d-none']"
                  class="d-none"
                >
                  <div
                    class="nbplayersIcon"
                    :style="
                      etatJoueurs.libelle && etatJoueurs.joueurs == 1
                        ? 'margin:-1px 3px -1px -1px'
                        : ''
                    "
                  >
                    <div class="espaceNombreJoueurs splitDroite centreVerticalRelative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        class="iconeJoueurs"
                        :class="{ 'non-operationnel': etatTable != CS_OUVERT }"
                      >
                        <use href="#playersOnlineIcon">
                          <g id="playersOnlineIcon">
                            <circle cx="16" cy="7.2" r="5.2"></circle>
                            <path
                              d="M19.3 13.5c-2.1.9-4.6.9-6.7 0-4.8 1.9-7.8 5.6-7.8 11.5 0 1.1.1 1.7.3 2.7.4 0 .8.1 1.2.2.3.1.7.2 1 .2.4-7.2 1.4-7.7 1.5-7.7l.5-.7.7 9c.6.1 1.2.3 1.8.5 1 .4 2.3.8 4.1.8 1.8 0 3-.4 4.1-.8.7-.2 1.2-.5 1.9-.5l.7-9 .5.7c0 .1 1.1.5 1.5 7.7.3 0 .7-.1 1-.2.4-.1.8-.2 1.2-.2.2-1 .3-1.6.3-2.7.1-5.9-2.9-9.6-7.8-11.5z"
                            ></path>
                          </g>
                        </use>
                      </svg>
                    </div>
                    <span class="nbJoueurs">{{ etatJoueurs.joueurs }}</span>
                  </div>
                  <span v-if="etatJoueurs.libelle">
                    {{ etatJoueurs.libelle }}
                  </span>
                </div>
                <a
                  class="buttonJouer"
                  :class="{ 'non-operationnel': etatTable != CS_OUVERT }"
                  @click="lancerTable"
                  >{{ $t("ced.jouer") }}</a
                >
              </div>
            </div>
            <!-- fin tables ouvertes -->
            <!-- fin première table footer page famille -->

            <!-- Footer accueil CED compact -->
            <div v-else-if="!isShowOverlay" class="exterieur">
              <div class="centre">
                <div class="container">
                  <div class="contenu">
                    <div class="splitGauche">
                      <div
                        v-if="etatTable == CS_OUVERT"
                        class="TableInfoWrap--jdxy_"
                        data-role="info-icon"
                        @click="toggleFooter()"
                      >
                        <span
                          class="TableInfoButton--3t6Qk TableInfoButtonBase TableInfoButtonChevron"
                          :class="{ 'infoActive infoActiveChevron': isShowOverlay }"
                        >
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="1024"
                            height="1024"
                            viewBox="0 0 1024 1024"
                          >
                            <title></title>
                            <g id="icomoon-ignore"></g>
                            <path
                              fill="#fff"
                              d="M512 22.608c-269.964 0-489.392 219.429-489.392 489.392s219.429 489.392 489.392 489.392c269.964 0 489.392-219.429 489.392-489.392s-219.429-489.392-489.392-489.392zM512 950.857c-242.036 0-438.857-196.821-438.857-438.857s196.821-438.857 438.857-438.857 438.857 196.821 438.857 438.857-196.821 438.857-438.857 438.857z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M265.974 577.164v81.122h3.99l242.036-208.79 242.036 208.79h3.99v-81.122l-246.026-211.449z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <!-- Gestion table ouverte -->
                      <div v-if="etatTable == CS_OUVERT" class="espaceFooterAccueil">
                        <div class="contenu descriptionAccueil">
                          <span>{{ $t("ced.tableSuggeree") }}</span>
                        </div>
                        <div class="espaceVertical sousTitreAccueil">
                          <span class="libelleAccueil">{{ table.titre }}</span>
                        </div>
                      </div>

                      <!-- Gestion table désactivée (ou fermée et ouverte 24 heures) -->
                      <div
                        v-if="
                          etatTable == CS_DESACTIVE ||
                          (etatTable == CS_FERME && heuresOuverture == '')
                        "
                        class="espaceFooterAccueil"
                      >
                        <div
                          v-if="table && table.msgDesactivation && etatTableApp == CS_DESACTIVE"
                          class="heuresOuvertureLibelle"
                        >
                          {{ table.titre }}
                          <div>{{ table.msgDesactivation }}</div>
                        </div>
                        <div v-else class="heuresOuvertureLibelle">
                          {{ table.titre }}
                          <div>{{ $t("ced.desactive") }}</div>
                        </div>
                      </div>

                      <!-- Gestion table fermée -->
                      <div v-else-if="etatTable == CS_FERME" class="espaceFooterAccueil">
                        <div class="heuresOuvertureLibelle">{{ table.titre }}</div>
                        <div class="heuresOuvertureLibelle">
                          <span>{{ $t("ced.ouverture") }}</span
                          ><span class="heuresOuvertureAccueil"> {{ heuresOuverture }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="splitDroite">
                      <a
                        class="buttonJouer buttonJouerAccueil /*centreVertical*/"
                        :class="{ 'non-operationnel': etatTable != CS_OUVERT }"
                        @click="lancerTable"
                        >{{ $t("ced.jouer") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Footer accueil CED étendu -->
            <div v-else class="exterieur etendu container">
              <!-- Bouton Jouer -->
              <div class="tableFooter-ratio wrapperBouton contenu">
                <a
                  class="buttonJouer buttonJouerAccueil centreVertical"
                  :class="{ 'non-operationnel': etatTable != CS_OUVERT }"
                  @click="lancerTable"
                  >{{ $t("ced.jouer") }}</a
                >
              </div>

              <div class="centre">
                <div class="contenu">
                  <!-- Chevron -->
                  <div class="splitGauche">
                    <div v-if="tableLive" data-role="info-icon" @click="toggleOverlay()">
                      <span
                        class="TableInfoButton--3t6Qk TableInfoButtonBase TableInfoButtonChevron"
                        :class="{ 'infoActive infoActiveChevron': isShowOverlay }"
                      >
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="1024"
                          height="1024"
                          viewBox="0 0 1024 1024"
                        >
                          <title></title>
                          <g id="icomoon-ignore"></g>
                          <path
                            fill="#fff"
                            d="M512 22.608c-269.964 0-489.392 219.429-489.392 489.392s219.429 489.392 489.392 489.392c269.964 0 489.392-219.429 489.392-489.392s-219.429-489.392-489.392-489.392zM512 950.857c-242.036 0-438.857-196.821-438.857-438.857s196.821-438.857 438.857-438.857 438.857 196.821 438.857 438.857-196.821 438.857-438.857 438.857z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M265.974 577.164v81.122h3.99l242.036-208.79 242.036 208.79h3.99v-81.122l-246.026-211.449z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <!-- Présentateur -->
                  <div class="splitGauche contenu">
                    <div class="espaceFooterAccueil espaceFooterAccueilEtendu">
                      <div class="contenu descriptionAccueil">
                        <span>{{ $t("ced.tableSuggeree") }}</span>
                      </div>
                      <div class="sousTitreAccueil">
                        <!--<div class="espaceVertical sousTitreAccueil" > -->
                        <span class="libelleAccueil">{{ table.titre }}</span>
                        <!--</div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Description -->
                <div class="soustitre">
                  <div class="contenu">
                    <div class="splitGauche">
                      <p v-if="table && table.descriptionAuto" v-html="table.descriptionAuto"></p>
                      <p v-else-if="tableLive && tableLive.dealer && tableLive.dealer.description">
                        {{ tableLive.dealer.description[mixinLangue_langue] }}
                      </p>
                      <p v-else>
                        {{ $t("defaultDescription") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="container"></div>
              </div>
            </div>
            <!-- Fin Footer accueil -->
          </div>
        </div>
      </div>
      <!-- Fin div table-view -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { mixinCED } from "../../mixins/mixinCED";
import { mixinLangue } from "../../mixins/mixinLangue";

import CedTableBlackjack from "./CedTableBlackjack.vue";
import CedTableRoulette from "./CedTableRoulette.vue";
import CedTableBaccarat from "./CedTableBaccarat.vue";
import CedTableLightningdice from "./CedTableLightningdice.vue";
import CedTableSicbo from "./CedTableSicbo.vue";
import CedTableMoneywheel from "./CedTableMoneywheel.vue";
import CedTableMonopoly from "./CedTableMonopoly.vue";
import CedTableMegaball from "./CedTableMegaball.vue";
import CedTableTopcard from "./CedTableTopcard.vue";
import CedTableCrazytime from "./CedTableCrazytime.vue";

export default {
  components: {
    "ced-table-blackjack": CedTableBlackjack,
    "ced-table-roulette": CedTableRoulette,
    "ced-table-baccarat": CedTableBaccarat,
    "ced-table-lightningdice": CedTableLightningdice,
    "ced-table-sicbo": CedTableSicbo,
    "ced-table-moneywheel": CedTableMoneywheel,
    "ced-table-monopoly": CedTableMonopoly,
    "ced-table-megaball": CedTableMegaball,
    "ced-table-topcard": CedTableTopcard,
    "ced-table-crazytime": CedTableCrazytime,
  },
  i18n: {
    messages: {
      fr: {
        defaultDescription:
          "Prenez place et laissez-vous guider par une équipe de présentateurs qui aiment s’amuser autant que vous. Participez au jeu et faites-le en bonne compagnie!",
      },
      en: {
        defaultDescription:
          "Get ready to have a good time in good company! Just take a seat and let our team of fun-loving hosts guide you through the game.",
      },
    },
  },
  mixins: [mixinLangue, mixinCED],

  props: {
    // idTable (de ftl page CED) OU table (de premiere ouverte)
    famille: {
      type: String,
      required: false,
    },

    // idTableApp (page accueil) OU tableApp+tableListeLive (page famille)
    idTableApp: {
      type: String,
      required: false,
    },
    tableApp: {
      type: Object,
      required: false,
    },
    tableListeLive: {
      type: Array,
      required: false,
    },

    isTablePremiereOuverte: {
      // Affiche la table avec chevron sinon c'est le "i"
      type: Boolean,
      required: false,
      default: false,
    },
    tableLivePremiereOuverte: {
      type: Object,
      required: false,
    },
    tableLiveInfoPremiereOuverte: {
      type: Object,
      required: false,
    },
    appareil: {
      type: String,
      required: true,
    },
    prefixeUrl: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      tableLive: null, // Table provenant de Evolution
      tableLiveInfo: null,
      table: null, // Table provenant du JCR
      isJeuLoaded: false,
      refreshCount: 0,
      hover: false,
      pin: false,
    };
  },

  computed: {
    CS_MOBILE: () => "mobile",
    CS_DESKTOP: () => "desktop",

    idJeu: function () {
      // Si un game id OGS n'est pas spécifié pour la table, on prend celui par défaut de la famille
      return this.table.idJeu ? this.table.idJeu : this.table.idJeuFamille;
    },
    idTable: function () {
      return this.appareil == this.CS_MOBILE ? this.table.idTableMobile : this.table.idTableDesktop;
    },

    gameTypeClass: function () {
      return this.tableLive ? this.tableLive.gameTypeUnified : null;
    },

    videoSnapshotBG: function () {
      if (this.tableLive && this.tableLiveInfo) {
        if (typeof this.tableLive.videoSnapshot.thumbnails !== "undefined") {
          return (
            this.tableLive.videoSnapshot.thumbnails[this.imageSize] +
            "?refreshCount=" +
            this.refreshCount
          );
        } else {
          return (
            this.tableLiveInfo.evolutionPublicDomain +
            this.tableLive.videoSnapshot.links[this.imageSize] +
            "&refreshCount=" +
            this.refreshCount
          );
        }
      }

      // Ne devrait pas arriver
      return this.imageDefaut;
    },

    imageSize: function () {
      // eslint-disable-next-line no-undef
      var winWidth = $(window).width();

      if (winWidth <= 320) return "S";
      if (winWidth <= 640) return "M";
      if (winWidth <= 1024) return "L";
      return "XL";
    },

    notOperationalClass: function () {
      // Gestion d'une table fermée
      if (this.etatTable != this.CS_OUVERT) {
        return "not-operational";
      }
      return "";
    },

    // Gestion de l'état de la table
    etatTable: function () {
      return this.mixinCED_getEtatTableRefresh(this.table, this.tableLive, this.refreshCount);
    },

    etatTableApp: function () {
      return this.mixinCED_etatTableAppRefresh;
    },

    etatTableService: function () {
      return this.mixinCED_etatTableServiceRefresh;
    },

    /*
			Retourner l'heure d’ouverture :
				Si Page accueil :
					Heure de la famille définie dans Magnolia
				Sinon (page famille) en ordre de préséance :
					Heure de la table définie dans Magnolia
					La plus tardive entre :
						Heure retournée par le service     
						Heure de la famille
		*/
    heuresOuverture: function () {
      // Si nous sommes sur la page accueil CED, on prend heure ouverture famille
      if (this.isTablePremiereOuverte) {
        return this.mixinCED_formatHeure(this.table.heureOuvertureFamille, "America/Toronto");
      }
      return this.mixinCED_heuresOuverture(this.table, this.tableLive);
    },

    miseMin: function () {
      return this.formatterMise(
        this.tableLive.betLimits.CAD.min,
        this.tableLive.betLimits.CAD.symbol
      );
    },

    miseMax: function () {
      return this.formatterMise(
        this.tableLive.betLimits.CAD.max,
        this.tableLive.betLimits.CAD.symbol
      );
    },

    maxMontantMobileClass: function () {
      return this.appareil == this.CS_MOBILE ? "maxMontantMobile" : "";
    },

    imageDefaut: function () {
      return this.nettoyerURI(this.table.imageDefaut);
    },
    imageLogo: function () {
      return this.nettoyerURI(this.table.imageLogo);
    },

    /* Permet de déterminer le visuel de l'etat des joueurs sur les tables;
		   Renvoit le libellé, le nombre de joueurs et le css correspondant */
    etatJoueurs: function () {
      var joueurs = 0;
      var etat = null;
      var libelle = null;
      if (this.etatTable == this.CS_OUVERT) {
        if (this.tableLive) {
          if (this.tableLive.seats || this.tableLive.seats == 0) {
            if (this.tableLive.seats > 0) {
              //sièges finis
              if (this.tableLive.gameTypeUnified == "blackjack") {
                if (this.tableLive.seatsTaken) {
                  var placesRestantes = this.tableLive.seats - this.tableLive.seatsTaken.length;
                  if (placesRestantes <= 0) {
                    joueurs = 0;
                    etat = "tableComplete";
                    libelle = this.$t("ced.tableComplete");
                  } else if (placesRestantes == 1) {
                    joueurs = placesRestantes;
                    etat = "dernierSiege";
                    libelle = this.$t("ced.dernierSiege");
                  } else {
                    joueurs = placesRestantes;
                    libelle = this.$t("ced.siegesDisponibles");
                    etat = "placesDisponibles";
                  }
                } else {
                  //sièges assis non définis; on considère que la table est complète
                  etat = "tableComplete";
                  libelle = this.$t("ced.tableComplete");
                }
              }
            } else {
              // sièges infinis
              libelle = "";
              etat = "placesIllimites";
              if (this.tableLive.players) {
                joueurs = this.tableLive.players;
              }
            }
          } else {
            //il n'y a pas toujours de seats dans les results; on considère comme une table illimitée
            libelle = "";
            etat = "placesDisponibles";
            if (this.tableLive.players) {
              joueurs = this.tableLive.players;
            }
          }
        }
      }
      return { joueurs: joueurs.toString(), libelle: libelle, etat: etat };
    },
    languePresentateur: function () {
      var langue = null;
      if (
        this.tableLive &&
        this.tableLive.language &&
        this.tableLive.language != this.mixinLangue_langue
      ) {
        langue = this.tableLive.language.toLowerCase();
        var keys = Object.keys(this.$t("ced.languageMap"));
        if (keys.indexOf(langue) != -1) {
          if (this.$t("ced.languageMap")[langue]) {
            langue = this.$t("ced.languageMap")[langue].toUpperCase();
          }
        }
      }
      return langue;
    },

    isShowOverlay: function () {
      return this.appareil == this.CS_MOBILE ? this.pin : this.hover || this.pin;
    },
    isShowInfoTable: function () {
      return !this.isTablePremiereOuverte && this.etatTable == this.CS_OUVERT;
    },
    isDualPlay: function () {
      return (
        this.tableLive && /*this.tableLive.gameSubType !== 'dualplay' &&*/ !this.table.isDualPlay
      );
    },
    estConnecte: function () {
      return isLoggedIn() || ejApi.lireCookie("logged_in") === "1";
    }
  },

  created: function () {
    this.chargerTable(this.famille, this.idTableApp, this.tableApp, this.tableListeLive);
  },

  mounted: function () {
    var self = this;
    this.$bus.$on("table-liste-live-updated", function (tableListeLive) {
      self.refreshTableLive(tableListeLive);
    });
    // Si provient de première table ouverte
    if (this.isTablePremiereOuverte) {
      this.tableLive = this.tableLivePremiereOuverte;
      this.tableLiveInfo = this.tableLiveInfoPremiereOuverte;
      this.refreshCount = 1;
    }
  },

  methods: {
    refreshTableLive: function (tableListeLive) {
      var findTableLive = this.mixinCED_findTableLive(this.table, tableListeLive, this.appareil);
      this.tableLive = findTableLive.tableLive;
      this.tableLiveInfo = findTableLive.tableLiveInfo;

      this.refreshCount++;
    },

    // Cette fonction est appelée une seule fois pour charger les images
    chargerTable: function (famille, idTableApp, tableApp, tableListeLive) {
      var self = this;
      if (tableApp && tableListeLive) {
        self.table = tableApp;
        self.refreshTableLive(tableListeLive);
      } else if (idTableApp != this.CS_TABLE_INEXISTANTE) {
        axios
          .get(
            this.prefixeUrl + "/" + this.mixinLangue_langue + "/json-cache/casino-en-direct/table",
            {
              params: {
                famille: famille,
                table: idTableApp,
              },
            }
          )
          .catch(function (error) {
            console.log("Erreur table: " + error);
          })
          .then((response) => {
            self.table = response.data;
          });
      } else {
        //TODO: 2021-03-28 ST Est-ce que ce code est vraiment exécuté?
        axios
          .get(
            this.prefixeUrl +
              "/" +
              this.mixinLangue_langue +
              "/json-cache/casino-en-direct/famille",
            {
              params: {
                famille: famille,
              },
            }
          )
          .catch(function (error) {
            console.log("Erreur famille: " + error);
          })
          .then((response) => {
            self.table = response.data;
          });
      }
    },

    lancerTable: function () {
      if (this.etatTable == this.CS_OUVERT) {
        if (this.appareil == this.CS_MOBILE) {
          // eslint-disable-next-line no-undef
          if (isLoggedIn() && ejConnect.etatClient == "ci") {
            // Empêcher un "client guest" de lancer une table
            // eslint-disable-next-line no-undef
            $("#messageGuest").modal("show");
          } else {
            // Sur Mobile
            // eslint-disable-next-line no-undef
            ejGameMobile.getGameLaunchUrl(
              this.idJeu,
              "OGSLiveCasino",
              this.tableLiveInfo.evolutionChannelMobile,
              "real",
              this.mixinLangue_langue,
              "",
              "750",
              "615",
              "",
              false,
              false,
              "",
              this.idTable
            );
          }
        } else {
          // Sur desktop, c'est launch_game_and_gaq qui empêche un "client guest" de lancer une table
          // eslint-disable-next-line no-undef
          launch_game_and_gaq(
            this.idJeu,
            "OGSLiveCasino",
            this.tableLiveInfo.evolutionChannelDesktop,
            "realplay",
            this.mixinLangue_langue,
            "html5",
            "750",
            "615",
            false,
            false,
            this.table.msgDesactivation,
            undefined,
            "",
            "",
            true,
            this.table.titre,
            this.idTable
          );
        }
      }
    },

    toggleOverlay: function () {
      if (this.pin) {
        this.pin = false;
        this.hover = false;
      } else {
        this.pin = true;
      }
    },

    toggleFooter: function () {
      this.pin = !this.pin;
    },

    formatterMise: function (mise, symbol) {
      mise = Number(mise);

      var nbDigits = mise < 1 ? 2 : 0;

      if (this.mixinLangue_langue == "en") {
        return symbol + mise.toLocaleString("en-CA", { minimumFractionDigits: nbDigits });
      }
      return mise.toLocaleString("fr-CA", { minimumFractionDigits: nbDigits }) + " " + symbol;
    },
  },
};
</script>

<style lang="scss">
.cedTablePremiereOuverte {
  .splitGauche {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .TableInfoWrap--jdxy_ {
    overflow: visible;
  }
  .TableFooter--3hms2 {
    .container {
      @media (max-width: 425px) {
        padding-bottom: 6vw;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: 3vw;
        padding-top: 2vw;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 3rem;
        padding-top: 2rem;
      }

      @include media-breakpoint-up(xxl) {
        padding: 2rem 3.2rem;
      }

      .wrapperBouton {
        width: 100%;
        justify-content: left;
        text-align: left;
      }
    }
    .soustitre {
      padding-top: 1.5rem;
    }
  }
}

.dernierSiege {
  color: #e9ec87; //Black jack : Dernier siège;  http://intranetvpcap.loto-quebec.com/_APPROBATION_WEB/produits-projets-2021/02-2021-offshore-guide-styles-graphiques/start.html#id=tgle30&p=6_0_couleurs
  border: solid 2px #e9ec87; //Black jack : Dernier siège
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  font-weight: 600;
  font-family: $font-graphik-regular;
  font-size: calc(8px + 0.1vw);
  line-height: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  .nbplayersIcon {
    background-color: #e9ec87; //Couleurs Blackjack dernier siège
  }
  @include media-breakpoint-up(md) {
    //media > 768px
    font-size: calc(8px + 0.1vw);
  }
  @include media-breakpoint-up(xl) {
    //media > 1200px
    font-size: calc(9.75px + 0.1vw);
  }
  .splitGauche.libelle.espace {
    margin-right: 8px;
    margin-left: 15px;
  }
}

.placesDisponibles {
  font-weight: 600;
  font-family: $font-graphik-regular;
  font-size: 0.8rem;
  line-height: 13px;
  height: 30px;
  display: flex;
  align-items: center;
  .splitGauche.libelle.espace {
    margin-right: 8px;
    margin-left: 15px;
  }
  @include media-breakpoint-up(md) {
    font-size: 0.9rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1rem;
  }
}

.nbplayersIcon {
  width: auto;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  height: 38px;
  display: flex;
  align-items: center;
}

.lobby-table-block-body {
  .langue {
    color: white;
    font-family: $font-graphik-regular;
    font-weight: bold;
    line-height: 14px;
    font-size: 11px;
    background-color: black;
    position: absolute;
    padding: 5px 0px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    font-size: 14px;
    left: 20px;
    top: 60px;
    //min-width: 64px;
    display: flex;
    @include media-breakpoint-up(lg) {
      //@media (min-width: 1024px){
      font-size: 11px;
      left: 20px;
      top: 60px;
    }
  }
  .langue.bulleIcon .presentateur {
    float: left;
    height: 15px;
    width: 15px;
    float: left;
    margin-left: 5px;
    padding: 0px;
    display: block;
    fill: white;
  }
  .bulleIcon.bordure:before {
    //Icone bulle, triangle exterieur de l'icone bulle*/
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 10px;
    right: auto;
    top: auto;
    bottom: -11px;
    border: 5px solid;
  }
  .bulleIcon:after {
    //Icone bulle, triangle interieur de l'icone bulle*/
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 12px;
    right: auto;
    top: auto;
    bottom: -5.5px;
    border: 3px solid;
  }
  .bulleMessage {
    text-align: left;
    margin-right: 12px;
    margin-left: 3px;
    line-height: 14px;
    font-size: 11px;
    position: relative;
    top: 2px;
  }
  .lobby-table-game-specific-data {
    background: linear-gradient(180deg, transparent 45%, rgba(0, 0, 0, 0.85));
  }
  .chargement {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/.resources/ej-template-theme/img/ui/loader.gif");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 32px 32px;
  }
  .lobby-table-limits {
    color: black;
    font-weight: bold;
    .maxMontantMobile {
      overflow: unset;
      max-width: unset;
      transition: unset;
    }
  }

  .lobby-table-limits.mise {
    background: rgba(0, 0, 0, 0.8);
    font-size: calc(11px + 0.5vw);
  }

  .lobby-table-overlay {
    .lobby-table-info {
      display: block;
      text-align: left;
      fill: #fff;
      color: #fff;
      transition-delay: 0.5s;
      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
      padding: 1.5em;

      .valeurChamp {
        font-weight: bold;
      }
    }
  }

  .LobbyTableOverlay--3_xjP {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent 45%, rgba(0, 0, 0, 0.65));
    z-index: 4;
    pointer-events: none;
  }

  .recent-numbers-container {
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .table-state {
    border-radius: 0.29rem;
    color: #fff;
    font-size: 0.72rem;
    line-height: 1;
    padding: 0.36rem;
    position: absolute;
    right: 0.72rem;
    top: 0.72rem;
    z-index: 1;
  }

  .table-state--opened {
    background-color: #00b050;
  }

  .table-state--closed {
    background-color: #c00000;
  }
}
.TableFooter--3hms2 {
  .tableFooter-ratio {
    .developper {
      height: 250%;

      transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);

      .exterieur {
        .centre {
          .soustitre {
            display: block;
            transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

            justify-content: flex-start;

            padding-left: 11.5vw;
            p {
              font-size: calc(12px + 0.1vw);
            }

            @include media-breakpoint-up(md) {
              padding-left: 6vw;
              p {
                font-size: calc(12px + 0.3vw);
                line-height: calc(15px + 0.3vw);
              }
            }

            @include media-breakpoint-up(xl) {
              padding-left: 75px;
              padding-right: 35px;
              p {
                font-size: 16px;
                line-height: 19px;
              }
            }

            p {
              color: white;
            }
          }
        }
      }
    }

    .footerAccueil {
      position: absolute;
      top: 1vw;
    }

    .tableFooter-ratio-content {
      a {
        border-radius: 4.5vw;

        // Bouton jouer page accueil
        &.buttonJouer {
          padding: 2.5vw 4.5vw;
        }
        &.buttonJouerAccueil {
          font-size: 3.34vw;
          right: 4vw;
        }
      }

      .produit {
        @media (max-width: 355px) {
          padding-right: 7px;
          padding-left: 7px;
          margin-top: 0;
        }
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 0;
        padding-top: 10px;
        // Bouton jouer page produit
        .buttonJouer {
          padding: 2vw 5vw;
          font-size: 3.5vw;
          text-transform: capitalize;
          @include media-breakpoint-up(sm) {
            padding: 1vw 2vw;
            font-size: 2vw;
          }
        }
      }

      @include media-breakpoint-up(md) {
        a {
          border-radius: 5vw;

          // Bouton jouer page accueil
          &.buttonJouer {
            padding: 1.27vw 2.34vw;
          }
          &.buttonJouerAccueil {
            padding: 1vw 2.34vw;
            font-size: 1.41vw;
            right: 3vw;
          }
        }
        .produit {
          // Bouton jouer page produit
          .buttonJouer {
            padding: 1.7vw 3.4vw;
            font-size: 2.2vw;
          }
        }
      }

      @media (min-width: 991px) {
        .produit {
          // Bouton jouer page produit
          .buttonJouer {
            padding: 1.2vw 2.5vw;
            font-size: 18px;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        a {
          border-radius: 25px;

          // Bouton jouer page accueil
          &.buttonJouer {
            padding: 17px 30px;
          }
          &.buttonJouerAccueil {
            font-size: 18px;
            right: 32px;
          }
        }

        .produit {
          margin-top: 2px;
          // Bouton jouer page produit
          .buttonJouer {
            padding: 11px 20px;
            font-size: 14px;
            font-family: $font-graphik-regular;
          }
        }
      }
    }

    .buttonJouer {
      text-transform: uppercase;

      background: white;
      color: black;
      text-align: center;
      align-self: center;
      transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

      background: white;
      color: black;
    }

    .buttonJouer:hover {
      color: white;
    }

    .buttonJouerAccueil {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .centre {
      .ajustementHauteur {
        height: 0;
      }
      .soustitre {
        height: unset;
        padding-left: 8vw;
        font-size: 100%;
        font-weight: lighter;
      }
      .ajustementBouton {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .etendu {
    .wrapperBouton {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
    }
    .centre {
      .container {
        height: 33%;
        flex-direction: row;
        align-items: center;
        padding-left: 0;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: space-between;
    padding-bottom: 8vw;
    padding-top: 4vw;
    width: 100%;

    @media (max-width: 425px) {
      padding-bottom: 6vw;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 3vw;
      padding-top: 2vw;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 3rem;
      padding-top: 2rem;
    }

    @include media-breakpoint-up(xxl) {
      padding-bottom: 2rem;
      padding-top: 2rem;
    }

    &.produit {
      height: 100%;
      .nomTableHeures {
        overflow: hidden;
        max-width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .sousTitre {
          font-weight: lighter;
        }
        div.splitGauche {
          max-width: calc(50% - 10px);
        }
        .splitMoitie {
          max-width: 50%;
        }
        @include media-breakpoint-up(sm) {
          height: 40px;
          line-height: 17px;
          padding-top: 0;
        }
        @include media-breakpoint-up(md) {
          height: 43px;
          line-height: 17px;
          padding-top: 2px;
        }
        @include media-breakpoint-up(xl) {
          height: 40px;
          padding-top: 0;
        }
      }
    }
    .nomTableHeures {
      &.splitGauche.desactive {
        overflow: visible;
        max-width: calc(100% - 35px); //100% - marges fixes right et left
        span.titreProduit.desactive {
          max-width: 100%;
        }
      }
    }
    .contenu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      font-family: $font-graphik-regular;

      .centreVertical {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .descriptionAccueil {
        text-transform: uppercase;
        font-weight: lighter;
      }

      .espaceVertical {
        padding-top: 5px;
      }

      .sousTitreAccueil {
        font-size: calc(14px + 1vw);
        padding-top: 10px;
        .dealerName {
          line-height: 4vw;
        }
      }
      .descriptionAccueil {
        font-size: calc(5px + 1vw);
      }
      .heuresOuvertureLibelle {
        font-size: calc(10px + 0.1vw);
        padding-bottom: 3vw;
      }

      .heuresOuvertureLibelleProduit {
        font-size: calc(14px + 0.1vw);
        font-weight: lighter;
      }

      .desactive {
        font-weight: lighter;
        max-width: 90%;
      }

      .titreProduit,
      .tableComplete {
        font-size: calc(11px + 0.1vw);
        line-height: 20px;
        font-family: $font-graphik-regular;
      }

      .sousTitreProduit {
        font-size: calc(10px + 0.1vw);
        font-weight: lighter;
      }

      .heuresOuvertureProduit {
        font-size: calc(15px + 0.1vw);
      }

      .heuresOuvertureAccueil {
        font-size: 7vw;
      }

      @include media-breakpoint-up(sm) {
        .espaceVertical {
          padding-top: 20px;
        }

        .titreProduit,
        .tableComplete {
          font-size: calc(11px + 0.1vw);
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 1.3vw;
        }
      }

      @include media-breakpoint-up(md) {
        .espaceVertical {
          padding-top: 0.3vw;
        }

        .sousTitreAccueil {
          font-size: 2vw;
          .dealerName {
            line-height: 19px;
          }
        }
        .descriptionAccueil {
          font-size: 9px;
          line-height: 12px;
        }
        .heuresOuvertureLibelle {
          font-size: 1.24vw;
          padding-bottom: 1.5vw;
          line-height: 1vw;
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 1.3vw;
        }

        .titreProduit,
        .tableComplete {
          font-size: calc(11px + 0.1vw);
        }

        .heuresOuvertureAccueil {
          font-size: 3.5vw;
        }
      }

      @media (min-width: 991px) {
        .espaceVertical {
          padding-top: 0.6vw;
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 1.2vw;
        }
      }

      @include media-breakpoint-up(xl) {
        .espaceVertical {
          padding-top: 10px;
        }

        .sousTitreAccueil {
          font-size: 25px;
          .dealerName {
            line-height: 10px;
          }
        }
        .descriptionAccueil {
          font-size: 12px;
        }
        .heuresOuvertureLibelle {
          font-size: 13px;
          padding-bottom: 20px;
          line-height: 17px;
        }

        .heuresOuvertureLibelleProduit,
        .heuresOuvertureProduit {
          font-size: 13px;
        }
        .titreProduit,
        .tableComplete {
          font-size: calc(11px + 0.1vw);
        }
        .heuresOuvertureAccueil {
          font-size: 40px;
        }
      }
    }
    /*.splitDroite.tableComplete {
      position: relative;
      top: 50%;
    }*/
    .tableComplete span {
      display: inline-block;
    }
    .sousTitreAccueil {
      .libelleAccueil {
        font-weight: lighter;
      }
    }

    .heuresOuvertureLibelle {
      font-weight: normal;
    }

    .nbJoueurs {
      margin-left: 2.5vw; //28px,
      position: relative;
      margin-right: 1.5vw; //10px; Design original pour le 1920
      margin-top: 1px;
      color: black;
      font-weight: 600;
      font-size: 18px;
      font-family: $font-graphik-regular;
      line-height: 15px;
      display: inline-block;
      padding-bottom: 0px;
      @include media-breakpoint-up(md) {
        margin-left: calc(13px + 0.1em); //28px
        margin-right: calc(7px + 0.1em); //10px
      }
      @include media-breakpoint-up(xl) {
        margin-left: calc(26px + 0.1em); //28px Design 1920px
        margin-right: calc(9px + 0.1em); //10px; Design 1920px
      }
    }
    .iconeJoueurs {
      fill: white;
      background-color: black;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      vertical-align: middle;
      position: relative;
      padding: 3px;
      @include media-breakpoint-up(sm) {
        height: 2.6rem;
        width: 2.6rem;
      }
      @include media-breakpoint-up(md) {
        height: 2.7rem;
        width: 2.7rem;
      }
      @include media-breakpoint-up(xl) {
        height: 2.8rem;
        width: 2.8rem;
        position: relative;
      }
    }
    .dernierSiege,
    .placesDisponibles {
      .iconeJoueurs {
        fill: #60ca4a; //couleurs blackjack personnage
      }
    }

    .infoActive {
      border-color: rgba(255, 255, 255, 0.3);
      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    .TableInfoButtonBase {
      opacity: unset;
      padding: unset;
    }

    .splitGauche {
      .TableInfoButtonChevron {
        border: none;
      }

      .TableInfoButtonChevron {
        height: 9vw;
        width: 9vw;
      }

      @include media-breakpoint-up(md) {
        .TableInfoButtonChevron {
          height: 4vw;
          width: 4vw;
        }
      }

      @include media-breakpoint-up(xl) {
        .TableInfoButtonChevron {
          height: 50px;
          width: 50px;
        }
      }

      .TableInfoButtonChevron::before {
        content: none;
      }
      .infoActiveChevron {
        transform: rotate(180deg);
      }
      .espace {
        margin-right: 5px;
      }
      &.espace {
        margin-right: 6px;
      }
      .TableInfoButtonI {
        border-width: 1.5px;
        height: 6vw;
        width: 6vw;
        font-size: 5vw;
        line-height: 6vw;
        position: relative;

        @include media-breakpoint-up(sm) {
          height: 3vw;
          width: 3vw;
          line-height: 3vw;
          font-size: 2.5vw;
        }
        @include media-breakpoint-up(md) {
          height: calc(12px + 1vw);
          width: calc(12px + 1vw);
          line-height: 2.5vw;
          font-size: 2vw;
        }
        @include media-breakpoint-up(xl) {
          height: 24px;
          width: 24px;
          line-height: 21px;
          font-size: 20px;
        }
      }
      .titreProduit:not(.desactive) {
        position: relative;
        display: inline-block;
      }
    }

    .splitDroite {
      float: right;

      .espace {
        margin-left: 9px;
      }

      .TableInfoButtonI {
        border-width: 1.5px;

        height: 6vw;
        width: 6vw;
        font-size: 5vw;
        line-height: 6vw;

        @include media-breakpoint-up(sm) {
          height: 3vw;
          width: 3vw;
          line-height: 3vw;
          font-size: 2.5vw;
        }
        @include media-breakpoint-up(md) {
          height: calc(12px + 1vw);
          width: calc(12px + 1vw);
          line-height: 2.5vw;
          font-size: 2vw;
        }
        @include media-breakpoint-up(xl) {
          height: 24px;
          width: 24px;
          line-height: 21px;
          font-size: 20px;
        }
      }
    }

    .espaceFooterAccueil.espaceFooterAccueilEtendu {
      width: 100%;
    }

    .espaceFooterAccueil {
      width: 100%;
      padding-left: 2vw;
    }

    @include media-breakpoint-up(md) {
      .espaceFooterAccueil {
        padding-left: 2vw;
      }
    }

    @include media-breakpoint-up(xl) {
      .espaceFooterAccueil {
        max-width: 500px;
        padding-left: 25px;
      }
    }
  }
}

// Couleurs par thèmes (Vlada)
// http://intranetvpcap.loto-quebec.com/_APPROBATION_WEB/produits-projets-2021/02-2021-offshore-guide-styles-graphiques/start.html#id=tgle30&p=6_0_couleurs
.blackjack {
  // Vert
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #205447; // Couleurs par thÃ¨mes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #0f6d56; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}

.roulette {
  // Bleu
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #163b75; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #194ea1; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.jeuxendirect {
  // Bleu ciel
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #3a71a9; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #01aef0; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.baccara {
  // Rouge
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #851929; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #bc122e; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.poker {
  // Bleu marin
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #0d035b; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #3135a6; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}
.des {
  // Violet
  &.tableFooter-ratio-content,
  &.sectionTables.clickable {
    background-color: #730040; // Couleurs par thèmes principals 6.0
  }
  .buttonJouer:hover,
  .TableInfoButtonChevron:hover {
    background-color: #a51c5d; // Couleurs des titres et des lins sur le fond blanc, contour et bouton éteint
  }
}

.theme-bg-ced section.section-page {
  .placesDisponibles .libelle {
    text-align: right;
  }
  &.section-verte {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #00a389;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #00a389;
    }
    .langue {
      border: solid 3px #00a389;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #a7a5aa; //Couleurs personnage blackjack siège infini
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-bleu {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #0a71f0;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #0a71f0;
    }
    .langue {
      border: solid 3px #0a71f0;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #0a71f0;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-bleu-ciel {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #01aef0;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #01aef0;
    }
    .langue {
      border: solid 3px #01aef0;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #01aef0;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-rouge {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #f03447;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #f03447;
    }
    .langue {
      border: solid 3px #f03447;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #f03447;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-bleu-marin {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #4f71f8;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #4f71f8;
    }
    .langue {
      border: solid 3px #4f71f8;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #4f71f8;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
  &.section-violet {
    .lobby-table-limits,
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      background-color: #d63a93;
    }
    .placesIllimites .nbplayersIcon,
    .placesDisponibles .nbplayersIcon {
      border: solid 2px #d63a93;
    }
    .langue {
      border: solid 3px #d63a93;
    }
    .placesIllimites {
      .iconeJoueurs {
        fill: #d63a93;
      }
    }
    .placesDisponibles .libelle {
      color: #fff;
    }
  }
}
.theme-bg-ced section.section-page {
  //Bordure couleur famille de  l'exterieur de l'icône bulle
  &.section-verte .bulleIcon.bordure:before {
    border-color: #00a389 transparent transparent #00a389;
  }
  &.section-bleu .bulleIcon.bordure:before {
    border-color: #0a71f0 transparent transparent #0a71f0;
  }
  &.section-bleu-ciel .bulleIcon.bordure:before {
    border-color: #01aef0 transparent transparent #01aef0;
  }
  &.section-rouge .bulleIcon.bordure:before {
    border-color: #f03447 transparent transparent #f03447;
  }
  &.section-bleu-marin .bulleIcon.bordure:before {
    border-color: #4f71f8 transparent transparent #4f71f8;
  }
  &.section-violet .bulleIcon.bordure:before {
    border-color: #d63a93 transparent transparent #d63a93;
  }
}
.theme-bg-ced section.section-page {
  //Background noir de l'interieur de l'icône bulle
  &.section-verte,
  &.section-bleu,
  &.section-bleu-marin,
  &.section-rouge,
  &.section-bleu-ciel,
  &.section-violet {
    .bulleIcon:after {
      border-color: black transparent transparent black;
    }
  }
}
.lobby-table-block.not-operational {
  .TableFooter--3hms2 {
    opacity: unset;
    pointer-events: none;
  }
}

.lobby-table-block {
  .TableFooter--3hms2 {
    padding: 0;

    .non-operationnel {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.lobby-table-block-cnt {
  .TableFooter--3hms2 {
    .tableFooter-ratio-content {
      bottom: 0;

      .exterieur {
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .centre {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

.owl-carousel .owl-item:after {
  display: none;
}

@include media-breakpoint-up(md) {
  .owl-carousel .owl-item:after {
    display: none;
  }
}

.blackTable {
  font-size: 1.4rem;
  line-height: 1.5;
}

.lightning-effect {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  height: 220px;
  width: 364px;
  background-image: url("../img/casino/lobbylight.53c1c.png");
  -webkit-animation: playh 12s steps(5) infinite, playv 12s steps(10) infinite;
  animation: lightning-effect-playh 12s steps(5) infinite,
    lightning-effect-playv 12s steps(10) infinite;
}

@include media-breakpoint-up(xl) {
  .col-2 .lightning-effect {
    width: 170px;
    height: 80px;
  }
}

@include media-breakpoint-up(xl) {
  .col-3 .lightning-effect {
    height: 140px;
    width: 280px;
  }
}

// lightning-effect: L'éclair apparaît après 1s, pendant 1s, puis disparait pendant 10s.
@-webkit-keyframes lightning-effect-playh {
  0%,
  20% {
    background-position-x: 0px;
  }
  22%,
  100% {
    background-position-x: -1820px;
  }
}
@keyframes lightning-effect-playh {
  0%,
  20% {
    background-position-x: 0px;
  }
  22%,
  100% {
    background-position-x: -1820px;
  }
}

@-webkit-keyframes lightning-effect-playv {
  0%,
  20% {
    background-position-y: 0px;
  }
  30%,
  100% {
    background-position-y: -2220px;
  }
}
@keyframes lightning-effect-playv {
  0%,
  20% {
    background-position-y: 0px;
  }
  30%,
  100% {
    background-position-y: -2220px;
  }
}

.lobby-table-logo {
  position: absolute;
  bottom: 100%;
  position: absolute;
  right: 20px;
  top: 20px;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.lobby-table-logo-image {
  width: 130px;
  position: relative;
  top: -24px;
  left: 25px;
}

@include media-breakpoint-up(xl) {
  .col-2 .lobby-table-logo-image {
    width: 50px;
    top: -15px;
  }
}
@include media-breakpoint-up(xl) {
  .col-3 .lobby-table-logo-image {
    width: 100px;
    top: -16px;
  }
}

@media (min-width: 1025px) {
  .TableFooter--3hms2 .container .contenu .titreProduit,
  .TableFooter--3hms2 .container .contenu .tableComplete {
    font-size: calc(12px + 0.1vw);
    line-height: 17px;
  }
}

@media (max-width: 1024px) {
  .TableFooter--3hms2 {
    height: 140px;
    padding-bottom: 30%;
  }
}

.espaceNombreJoueurs.splitDroite.centreVerticalRelative {
  margin-right: 2px;
}

@media (max-width: 767px) {
  .TableFooter--3hms2 {
    height: 180px;
  }

  .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
    padding: 5vw 8vw;
    font-size: 6.5vw;
    border-radius: 10.5vw;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
    padding: 2.75vw 6vw;
    font-size: 4.5vw;
    border-radius: 10.5vw;
  }
}

@media (max-width: 425px) {
  .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
    padding: 4vw 7vw;
    font-size: 4.5vw;
    border-radius: 10.5vw;
  }

  .TableFooter--3hms2 .container .contenu .titreProduit {
    font-size: calc(12px + 0.1vw);
  }

  .TableFooter--3hms2 {
    height: 140px;
  }
}

.isTablePremiereOuverte {
  .TableFooter--3hms2 {
    height: 100%;
  }
  .lobby-table-block-body {
    .langue {
      @include media-breakpoint-up(xxxl) {
        //media > 1920px
        font-size: 17px;
        line-height: 22px;
        left: 25px;
        top: 83px;
        padding: 5px 8px 5px 5px;
        min-width: 105px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
      }
    }
    .bulleMessage {
      @include media-breakpoint-up(xxxl) {
        //media > 1920px
        line-height: 22px;
        font-size: 17px;
        position: relative;
        top: 6px;
      }
    }
    .langue.bulleIcon .presentateur {
      @include media-breakpoint-up(xxxl) {
        //media > 1920px
        height: 30px;
        width: 30px;
      }
    }
  }
}
.desktop .lobby-table-block.isTablePremiereOuverte .lobby-table-limits {
  @include media-breakpoint-up(xxxl) {
    //media > 1920px
    font-weight: 600;
    font-family: graphik_regular;
    line-height: 18px;
    font-size: 20px;
    left: 20px;
    top: 15px;
    padding-top: 17px;
    padding-bottom: 13px;
    padding-left: 45px;
    padding-right: 45px;
    border-radius: 73px;
    -webkit-border-radius: 73px;
    -moz-border-radius: 73px;
  }
}
.TableFooter--3hms2 .container .isTablePremiereOuverte span {
  font-family: graphik_regular;
}

.TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
  display: inline-block;
}
@media (min-width: 992px) and(max-width:1200px) {
  .tableFooter-ratio {
    height: 0;
    padding-bottom: 35%;
  }
}
.row.gutters-xsmall {
  .colonne.col-2,
  .colonne.col-3,
  .colonne.col-4 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.TableFooter--3hms2 .container .splitGauche .TableInfoButtonI {
  display: flex;
  justify-content: center;
  align-items: center;
}
.espaceNombreJoueurs {
  align-self: center;
}
.TableFooter--3hms2 .container .contenu .heuresOuvertureLibelle {
  padding-bottom: 0px;
}
.TableFooter--3hms2 .container .contenu .espaceFooterAccueil .heuresOuvertureLibelle {
  margin-top: 3px;
}
.TableFooter--3hms2
  .container
  .contenu
  .espaceFooterAccueil
  .heuresOuvertureLibelle
  .heuresOuvertureAccueil {
  font-size: 4vw;
  @include media-breakpoint-up(sm) {
    font-size: 3vw;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.75vw;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }
}
@include media-breakpoint-up(xl) {
  .colonne.col-12.col-md-6.col-lg-4.col-3.col-xl-3 .lobby-table-block:not(.isTablePremiereOuverte) {
    //@include mediaLargeCol-3;
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit {
      padding-right: 7.5px;
      padding-left: 7.5px;
      padding-top: 5px;
    }
    .lobby-table-limits {
      font-size: 10px;
      left: 10px;
      top: 10px;
      padding-top: 6px;
      padding-bottom: 5px;
      padding-left: 25px;
      padding-right: 25px;
    }
    .nbplayersIcon {
      height: 25px;
    }
    .TableFooter--3hms2 .container .splitGauche .TableInfoButtonI {
      height: 18px;
      width: 18px;
      line-height: 15px;
      font-size: 15px;
    }
    .lobby-table-block .lobby-table-info {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
    .lobby-table-block .lobby-table-info p {
      font-size: 1rem;
      line-height: 1.1rem;
    }
    .TableFooter--3hms2 .container .nbJoueurs {
      font-size: 13px;
      margin-left: calc(13px + 0.1rem);
      margin-right: calc(6px + 0.1em);
    }
    .TableFooter--3hms2 .container .iconeJoueurs {
      height: 2rem;
      width: 2rem;
      padding: 2px;
    }
    .TableFooter--3hms2 .container .contenu .titreProduit,
    .TableFooter--3hms2 .container .contenu .tableComplete {
      font-size: calc(10px + 0.1vw);
      line-height: 15px;
    }
    .placesDisponibles {
      font-size: 0.8rem;
      line-height: 11px;
    }
    .dernierSiege {
      font-size: calc(7.5px + 0.1vw);
      line-height: 9px;
      height: 28px;
      text-align: right;
      .nbplayersIcon {
        margin: -1px -1px -1px -1px;
      }
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures .splitMoitie.dernierSiege {
      max-width: 40%;
    }
    .espaceNombreJoueurs.splitDroite.centreVerticalRelative {
      margin-right: 1px;
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures div.splitGauche {
      max-width: calc(50% - 5px);
    }
    .TableFooter--3hms2 .container .splitDroite .espace {
      margin-left: 7px;
    }
    .TableFooter--3hms2 .container .splitGauche .espace {
      margin-right: 3px;
    }
    .TableFooter--3hms2 .container .contenu .desactive {
      max-width: 100%;
    }
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
      padding: 10px 18px;
      font-size: 13px;
    }
    .TableFooter--3hms2 .container .contenu {
      padding-bottom: 10px;
    }
    .TableFooter--3hms2 {
      padding-bottom: 15px;
    }
    .tableFooter-ratio .tableFooter-ratio-content {
      padding-bottom: 10px;
    }
    .lobby-table-block-body .bulleMessage {
      font-size: 9px;
      margin-right: 10px;
    }
    .lobby-table-block-body .langue {
      padding: 3px 0px;
    }
    .lobby-table-block-body .langue.bulleIcon .presentateur {
      margin-left: 5px;
      margin-left: 3px;
    }
    .lobby-table-block-body .bulleIcon.bordure::before {
      left: 9px;
      bottom: -10px;
    }
    .lobby-table-block-body .langue {
      left: 18px;
      top: 45px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .colonne.col-12.col-md-6.col-lg-4.col-2.col-xl-2 .lobby-table-block:not(.isTablePremiereOuverte) {
    //@include mediaLargeCol-2;
    .smallTable {
      display: inline-flex;
      align-items: center;
    }
    .lobby-table-block-body .lobby-table-limits {
      font-size: 10px !important;
      left: 8px !important;
      top: 6px !important;
      padding-top: 4px !important;
      padding-bottom: 3px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .lobby-table-block-body .bulleIcon::after {
      left: 10px;
      bottom: -6.5px;
      border: 3.5px solid;
      border-color: black transparent transparent black;
    }
    .lobby-table-block-body .bulleMessage {
      font-size: 7px;
      margin-right: 7px;
      margin-left: 1px;
      top: 1px;
    }
    .lobby-table-block-body .langue {
      padding: 2px 0px;
    }
    .lobby-table-block-body .langue.bulleIcon .presentateur {
      margin-left: 5px;
      margin-left: 3px;
      height: 12px;
      width: 12px;
    }
    .lobby-table-block-body .bulleIcon.bordure::before {
      left: 9px;
      bottom: -10px;
    }
    .lobby-table-block-body .langue {
      left: 14px;
      top: 37px;
    }
    .nbplayersIcon {
      height: 25px;
    }
    .TableFooter--3hms2 .container .splitGauche .TableInfoButtonI {
      height: 18px;
      width: 18px;
      line-height: 15px;
      font-size: 15px;
    }
    .TableFooter--3hms2 .container .nbJoueurs {
      font-size: 12px;
      margin-left: calc(1px + 0.1rem);
      margin-right: calc(11px + 0.1em);
    }
    .TableFooter--3hms2 .container .iconeJoueurs {
      height: 2rem;
      width: 2rem;
      padding: 2px;
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures.ferme {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
    .heuresOuvertureLibelle {
      flex-direction: row;
    }
    .TableFooter--3hms2 .container .contenu .desactive {
      max-width: 100%;
    }
    .TableFooter--3hms2 .container .contenu .titreProduit,
    .TableFooter--3hms2 .tableComplete {
      font-size: calc(9px + 0.1vw);
      line-height: 14px;
    }
    .TableFooter--3hms2 .container .contenu .heuresOuvertureProduit,
    .TableFooter--3hms2 .container .contenu .heuresOuvertureLibelleProduit {
      font-size: 12px;
    }
    .infoEtatTable {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .pasOuvert .infoEtatTable {
      justify-content: flex-end !important;
    }
    .placesDisponibles {
      font-size: 0.85rem;
      line-height: 11px;
      max-width: 20px;
      text-align: left;
      .nbplayersIcon {
        margin-right: 5px;
      }
    }
    .dernierSiege {
      font-size: 8px;
      line-height: 9px;
      padding: 0px 5px 0px 0px;
      height: 28px;
      text-align: left;
      .nbplayersIcon {
        margin: -1px 4px -1px 0px;
      }
    }
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit .buttonJouer {
      padding: 6px 16px;
      font-size: 10px;
    }
    .TableFooter--3hms2 {
      padding-bottom: 0;
      padding-bottom: 25px;
    }
    .TableFooter--3hms2 .tableFooter-ratio .tableFooter-ratio-content .produit {
      padding-top: 2px;
      margin-top: 0px;
      padding-right: 7px;
      padding-left: 7px;
    }
    .TableFooter--3hms2 .container.produit .nomTableHeures div.splitGauche {
      max-width: calc(100% - 10px);
    }
  }
}

.col-2 .lobby-table-block:not(.isTablePremiereOuverte) .infoEtatTable {
  .tableComplete,
  .infoEtatJoueurs {
    @include media-breakpoint-up(xl) {
      display: inline-flex !important;
    }
  }
  &.centrer {
    .buttonJouer {
      @media (max-width: 1200px) {
        justify-content: center;
      }
    }
  }
}
.col-2 .lobby-table-block:not(.isTablePremiereOuverte) .infoEtatTable.centrer {
  @include media-breakpoint-up(xl) {
    justify-content: center;
  }
}
.col-2 {
  @include media-breakpoint-up(xl) {
    .lobby-table-block .lobby-table-info {
      font-size: 1rem !important;
      line-height: 1rem !important;
    }
    .lobby-table-block .lobby-table-info p {
      font-size: 1.1rem !important;
      line-height: 1.1rem !important;
    }
  }
}
.col-2 .notSmallGrille {
  @media (max-width: 1200px) {
    display: inline-flex !important;
  }
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}
</style>
