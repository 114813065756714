/** copié puis adapté depuis scq-templating `LQ NameSpace | Copyright 2015 Loto Quebec Inc.`
 *  pour Espacejeux (NTER) 2018 
 *  requiert jQuery **/

var onceNotificationSetup = false;

$(document).on("windowLQLoaded", function(){
	if(!onceNotificationSetup){
		var okToProceed = false;
		try{
			if(    (ejApi) 
				&& (undefined !== ejApi)
				&& (null !== ejApi) 
				&& (ejApi.sessionEstOuverte !== null) 
				&& (ejApi.sessionEstOuverte !== undefined) )
			{
				okToProceed = true;
			}
		}catch(Exception1){ }
		if(okToProceed){
			onceNotificationSetup = true;
			setupNotificationOnce();
		}
	}
});

$(document).on("ejEventHeaderLoad",function(){ 
	$(document).on("windowLQLoaded", function(){
		if(!onceNotificationSetup){
			onceNotificationSetup = true;
			setupNotificationOnce();
		}
	});
});

$(document).on("windowLQLoaded",function(){ 
	$(document).on("ejEventHeaderLoad", function(){
		if(!onceNotificationSetup){
			onceNotificationSetup = true;
			setupNotificationOnce();
		}
	});
});


function setupNotificationOnce(){
	
	var scrll = window.showOnScroll||{
		/** DÉBUT: documentation facultative de l'objet showOnScroll  */
		positionToTriggerShowElemOnScroll: "",
		windowInnerHeight: "",
		deactivated: true,
		el: null,
		uuid: "",
		verifyNotificationAvailability: function(){ ; },
		percentageScrollBuffer:0, 
		scrollBuffer: 0
		/**FIN: documentation facultative de l'objet showOnScroll  */
	};

	if($('#page-content').length > 0){
		scrll.positionToTriggerShowElemOnScroll = $('#page-content').offset().top + $('#page-content').height();
	} else if($('.ej-container').length > 0){
		scrll.positionToTriggerShowElemOnScroll = $('.ej-container').offset().top + $('.ej-container').height();
	}
	scrll.windowInnerHeight = window.innerHeight;
	
	scrll.verifyNotificationAvailability = function() {
		var scoped_scrll = window.showOnScroll;
		if(scoped_scrll.deactivated) { return; }
		var currentYScrollPosition = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;
	    var currentBottomViewport = currentYScrollPosition + scoped_scrll.windowInnerHeight;
		if ((currentBottomViewport + scoped_scrll.scrollBuffer) >= scoped_scrll.positionToTriggerShowElemOnScroll) {
			scoped_scrll.el.addClass('active');
			if(scoped_scrll.typeDeConditionel === "ind_cc_PremiereVisite"){
				scoped_scrll.typeDeConditionel = 'visited';
				var storageTag = 'is-fnShowEndpage-debunked-' + scoped_scrll.uuid;
				localStorage.setItem(storageTag,true);
			}
		} else {
			scoped_scrll.el.removeClass('active');
		}
	}
	
	scrll.open = function(){
		var scoped_scrll = window.showOnScroll;
		EspacejeuxPortail_GoogleAnalytics.enregistrerEvenement(scoped_scrll.googcat, scoped_scrll.googaction, scoped_scrll.googlabel, 0);
		return true;
	}
	
	var continuer = $('.fn-show-endpage').length;
	
	var i = -1;
	var element,
		uuid,
		storageTag,
		$btnClose,
		deactivatedNotification,
		dateDebut,
		dateFin,
		electedAudienceList,
		eclipseFoldableIfs = true,
		infolettre,
		user = {
			audience: typeof ejConnect === 'undefined' ? 'anon' : ejConnect.etatClient,
			infolettre: null
		};
	
	while(continuer  && i < ( ($('.fn-show-endpage').length)-1 ) )
	{
		i++;
		deactivatedNotification=false;
		element = $('.fn-show-endpage')[i];
		uuid = $(element).data("eltidtag");
		infolettre = $(element).data("infolettre");
		scrll.typeDeConditionel = $(element).data('type-affichage-conditionel');

		if (typeof loginNav !== 'undefined') {
            if( loginNav.session_details !== null ) {
                if( typeof loginNav.session_details.contact_ok ) {
                    user.infolettre = loginNav.session_details.contact_ok;
                }
            }
        }

        if(eclipseFoldableIfs){ 
		
			//dateDiffusion 
			try{
				dateDebut = moment(parseInt($(element).data('date-debut')));
				dateFin   = moment(parseInt($(element).data('date-fin')));
				
				/*preserving historical potential leap year un-updated datepicker dependencies */
				_dateDebut =  _dateDebut.isValid() ? _dateDebut : moment().subtract(1,'y');
				_dateFin =  _dateFin.isValid() ? _dateFin : moment().add(1,'y');
				
				if( (moment() < _dateDebut) || (moment() > _dateFin) ){
					deactivatedNotification  = true;
				}
			}catch(DatesNonRenseignee){ }
			
		}
		
		if(deactivatedNotification) continue;
		
		if(scrll.typeDeConditionel == 'ind_cc_premiereAction'){
			
			storageTag = 'is-fnShowEndpage-desactivated-' + uuid;
			try{ 
				deactivatedNotification = JSON.parse(localStorage.getItem(storageTag)); 
			}
			catch(Excep){ 
				console.error("err: ", Excep); 
			}
			
		}
		else if(scrll.typeDeConditionel == 'ind_cc_PremiereVisite'){
			
			storageTag = 'is-fnShowEndpage-debunked-' + uuid;
			try{ 
				deactivatedNotification = JSON.parse(localStorage.getItem(storageTag));
			}
			catch(Excep){ 
				console.error("err: ", Excep); 
			}
			
		}
		else if(scrll.typeDeConditionel == 'ind_cc_Cookie'){
			
			deactivatedNotification = true;
			var cookies = document.cookie.split("; ");
			for ( var j = 0; j < cookies.length; j++ ) {
				if(cookies[ j ].substring(0,cookies[ j ].indexOf("=")) === $(element).data("nom-cookie") ) {
					deactivatedNotification = false;
				} 
			}	
		}
		else{
			// add default "none" side effect `sine qua non` code here
		}
		
		if(deactivatedNotification) continue;
		
		if(eclipseFoldableIfs){

			//elected audience is only javascript protected since an unavailable offer is not "secret"
			electedAudienceList = $(element).data('audience-anonyme') 
			 + " " + $(element).data('audience-client-invite') 
			 + " " + $(element).data('audience-client-joueur')
			 + " " + $(element).data('audience-client-leger')
			deactivatedNotification = (electedAudienceList.indexOf(ejConnect.etatClient) == -1);
			
		}
		
		if(deactivatedNotification) continue;

		switch(user.audience) {
            case 'anon':
                switch(infolettre) {
                    case 'notSelected':
                        break;
                    default:
                        deactivatedNotification = true;
                }
                break;
            default:
                switch(infolettre) {
                    case 'notSelected':
                        break;
                    default:
                        if( user.infolettre !== infolettre) {
                            deactivatedNotification  = true;
                        }
                }
        }

        if(deactivatedNotification) continue;
		
		//les conditions d'affichage étant affirmativement rencontrées, procedons
		continuer = false;
		$.extend(scrll,{ 
			deactivated: false, 
			el: $(element), 
			uuid: uuid,
			googcat: $(element).data('google-analytics-category'),
			googaction: $(element).data('google-analytics-action'),
			googlabel: $(element).data('google-analytics-label')
		});
		
		$btnClose = scrll.el.find('.js-btn-fnshowendpage--close');
		scrll.percentageScrollBuffer = scrll.el.data('percentage-scroll-buffer') || 0;
		scrll.scrollBuffer = scrll.percentageScrollBuffer / 100 * scrll.positionToTriggerShowElemOnScroll;
	
		$btnClose.on('click', function(){
			var storageTag = 'is-fnShowEndpage-desactivated-' + window.showOnScroll.uuid;
			try{ localStorage.setItem(storageTag, true); }
			catch(Excep){ console.error("ignore err storage session"); }
			window.showOnScroll.deactivated = true;
			window.showOnScroll.el.removeClass('active');
		});
		
		$(window).on('resize orientationchange', function () {
	        window.showOnScroll.windowInnerHeight = window.innerHeight;
	    });
		
		$(window).on('scroll', scrll.verifyNotificationAvailability);
		
		window.showOnScroll = scrll;
		scrll.verifyNotificationAvailability();
		
	}
}