
import { mapMutations } from 'vuex';

export default {
	data: function () {
		return {
			productsourceInfo: {},
		}
	},

	mounted() {
		this.setProductsourceInfo();
	},

	methods: {
		...mapMutations([
			'updateProductSourceInfo',
		]),

		setProductsourceInfo() {
			const vm = this;
				
			if(vm.productSource) {									
				let headers = new Headers();			
				headers.append('cache-control', 'no-cache');
				fetch( `/json-cache/content/product-source-info?lang=${vm.mixinLangue_langue}&product-source=${vm.productSource}`, {
					method: 'GET',
					headers: headers,
				}).then(function( response ){
					return response.json();
				}).then(function( data ){							
					vm.updateProductSourceInfo( data );
				}).catch(function( error ) {
					console.log( 'Error setProductsourceInfo:' + error );
				});
			}
		}
	}
}