var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "pagination-games" }, [
    _c("span", { staticClass: "pagination-games__label" }, [_vm._v("Page(s)")]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "pagination-games__list" },
      [
        _c("li", [
          _c(
            "button",
            {
              staticClass: "pagination__nav pagination__nav-previous",
              attrs: { type: "button", disabled: _vm.pageCurrent === 1 },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("previous")
                },
              },
            },
            [
              _c("span", {
                staticClass: "icomoon icon-prev",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.pages, function (page, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "pagination-games__item",
              class: { is_displayed: _vm.pageDisplayed(page) },
              attrs: { page: page },
            },
            [
              _c(
                "a",
                {
                  staticClass: "pagination-games__link",
                  attrs: { href: "#page-" + page },
                  on: {
                    click: function ($event) {
                      _vm.pageCurrent = page
                    },
                  },
                },
                [_vm._v(_vm._s(page))]
              ),
            ]
          )
        }),
        _vm._v(" "),
        _c("li", [
          _c(
            "button",
            {
              staticClass: "pagination__nav pagination__nav-next",
              attrs: {
                type: "button",
                disabled: _vm.pageCurrent === _vm.pages,
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("next")
                },
              },
            },
            [
              _c("span", {
                staticClass: "icomoon icon-next",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }