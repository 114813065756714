<template>
  <div
    v-if="!isJeuLoaded"
    v-observe-visibility="{ callback: visibilityChanged, throttle: 500 }"
    class="casino-jeu-chargement"
    data-element="jeu"
  >
    <span class="bloc-jeu">
      {{ jeu.titre[mixinLangue_langue] }}
      <br />
      {{ $t("generic.chargement") }}...
    </span>
  </div>

  <div
    v-else-if="$isDomainMobile"
    :id="'id' + jeu.idJeu"
    :class="cssCol"
    data-element="jeu"
  >
    <div class="bloc-jeu doc-item" :class="renduHTML.blocJeuClasses">
      <div class="clearfix">
        <div
          class="featured feature-image favoris-pastille-mobile"
          style="position: relative"
        >
          <div class="chargement">
            <img
              src="/.resources/ej-template-theme/img/ui/loader.gif"
              alt="chargement"
            />
          </div>

          <div
            v-if="
              renduHTML.blocJeuClasses.indexOf('instants') > -1 &&
              noConsentFavoriInstants
            "
            class="warning-consent-mobile"
          >
            <img
              src="/dam/espacejeux/logo/icone-avertissement.svg"
              alt="icone avertissement"
            />
            <div class="tooltip-favoris-text-mobile">
              {{ $t("jeu.instants.favoris.texteConsentement") }}&nbsp;<a
                href="javascript:Didomi.reset();Didomi.preferences.show()"
                target="_self"
                style="
                  display: inline-block;
                  color: black;
                  text-decoration: underline;
                "
                >{{ $t("jeu.instants.favoris.texteLienConsentement") }}</a
              >
            </div>
          </div>

          <i
            class="fa fa-2x favoris favoris-pastille"
            :class="[
              renduHTML.blocJeuClasses.indexOf('instants') > -1
                ? hasConsent
                  ? isFavori
                    ? 'fa-star active'
                    : 'fa-star'
                  : 'fa-star inactive'
                : isFavori
                ? 'fa-star'
                : 'fa-star-o',
            ]"
            :data-favoris="'favori_' + jeu.idJeu"
            @click="toggleFavorite()"
          ></i>

          <a
            :href="renduHTML.infoJeuLien"
            v-html="renduHTML.bandeaux + renduHTML.imgContent"
          >
          </a>
        </div>
        <div v-if="!renduHTML.progressifMasquer" class="row-progressif-content">
          <div
            v-if="renduHTML.idProgressifPrincipal !== ''"
            class="text-progressif"
          >
            <span class="fleft info-title">
              {{ $tc("jeu.progressif", renduHTML.nbProgressif) }}
            </span>
            <span
              class="progressif progressif-moyen"
              :class="[
                { 'progressif-mock': renduHTML.authorInstance },
                renduHTML.progressifClasses,
              ]"
            ></span>
          </div>
        </div>
        <div class="px-15 text-content-detail" style="display: none">
          <div v-html="renduHTML.nbLigne"></div>
          <div v-html="renduHTML.miseFormat"></div>
          <div class="height-fixe" v-html="renduHTML.texteMKT"></div>
        </div>
      </div>
    </div>
  </div>

  <div v-else :id="'id' + jeu.idJeu" :class="cssCol" data-element="jeu">
    <div
      class="bloc-jeu doc-item"
      :class="renduHTML.blocJeuClasses"
      @mouseover="loadYoutubePlayer"
      @mouseleave="unloadIframeById"
    >
      <div :id="renduHTML.idJeu">
        <time
          class="dateDebutDesactivationJeu"
          :datetime="renduHTML.dateDebut"
        ></time>
        <time
          class="dateFinDesactivationJeu"
          :datetime="renduHTML.dateFin"
        ></time>
        <time
          class="dateDebutDesactivationFournisseur"
          :datetime="renduHTML.dateDebutFournisseur"
        ></time>
        <time
          class="dateFinDesactivationFournisseur"
          :datetime="renduHTML.dateFinFournisseur"
        ></time>
      </div>
      <div
        v-if="renduHTML.imagePreviewSrc.indexOf('jeu-generique') != -1"
        class="bloc-jeu-generique"
      >
        {{ renduHTML.titre }}<br />{{ renduHTML.idJeu }}
      </div>
      <div class="bloc-jeu-main">
        <div class="bloc-jeu-info">
          <div class="bloc-jeu-image-container">
            <a
              v-if="
                typeof renduHTML.lienBoutonJouer !== 'undefined' &&
                renduHTML.lienBoutonJouer.length > 0
              "
              class="bloc-jeu-lien"
              :class="{ 'ej-block-jeu-video': videoIsReady }"
              :href="renduHTML.lienBoutonJouer"
            >
              <div v-if="isVideoLoading" class="loading-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <img
                class="bloc-jeu w-100"
                :src="renduHTML.imagePreviewSrc"
                :alt="renduHTML.titre"
              />
              <div
                v-if="renduHTML.videoID !== '' && this.loadVideo"
                class="youtube-container"
              >
                <iframe
                  :title="jeu.titre[this.mixinLangue_langue]"
                  :video-id="renduHTML.videoID"
                  width="100%"
                  height="100%"
                  :data-src="lienYoutube"
                  frameborder="0"
                  allowfullscreen="1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                >
                </iframe>
                <div class="video-consent-overlay">
                  <div class="scrollable-section">
                    <div class="logo">
                      <img
                        src="/dam/espacejeux/logo/logo-youtube-consent.svg"
                        alt="logo youtube"
                        class="video-consent-overlay-logo"
                      />
                    </div>
                    <div class="video-consent-overlay-titre">
                      <h3>{{ $t("youtubeVideoConsent.titre") }}</h3>
                    </div>
                    <div class="video-consent-overlay-text">
                      <p>{{ $t("youtubeVideoConsent.texte") }}</p>
                    </div>
                  </div>
                  <button class="video-consent-overlay-accept-button">
                    {{ $t("youtubeVideoConsent.texteBoutonAccept") }}
                  </button>
                </div>
              </div>

              <!-- <div
                v-if="renduHTML.videoID !== '' && this.loadVideo"
                class="ej-video-iframe-container"
              >
                <youtube
                  :ref="'youtube-' + renduHTML.idJeu"
                  width="100%"
                  height="100%"
                  :title="jeu.titre[this.mixinLangue_langue]"
                  :video-id="renduHTML.videoID"
                  :player-vars="playerConfig"
                  @playing="playingVideo"
                  @ended="onEndedPlayer"
                />
              </div> -->
            </a>
            <a
              v-else
              class="bloc-jeu-lien"
              :class="{ 'ej-block-jeu-video': videoIsReady }"
              @click="launchGame"
            >
              <div v-if="isVideoLoading" class="loading-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <img
                class="bloc-jeu w-100"
                :src="imgSrcURI"
                :alt="renduHTML.titre"
              />

              <div
                v-if="renduHTML.videoID !== '' && this.loadVideo"
                class="youtube-container"
              >
                <iframe
                  :title="jeu.titre[this.mixinLangue_langue]"
                  width="100%"
                  height="100%"
                  :data-src="lienYoutube"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
                <div class="video-consent-overlay">
                  <div class="scrollable-section">
                    <div class="logo">
                      <img
                        src="/dam/espacejeux/logo/logo-youtube-consent.svg"
                        alt="logo youtube"
                        class="video-consent-overlay-logo"
                      />
                    </div>
                    <div class="video-consent-overlay-titre">
                      <h3>{{ $t("youtubeVideoConsent.titre") }}</h3>
                    </div>
                    <div class="video-consent-overlay-text">
                      <p>{{ $t("youtubeVideoConsent.texte") }}</p>
                    </div>
                  </div>
                  <button class="video-consent-overlay-accept-button">
                    {{ $t("youtubeVideoConsent.texteBoutonAccept") }}
                  </button>
                </div>
              </div>

              <!-- <div
                v-if="renduHTML.videoID !== '' && this.loadVideo"
                class="ej-video-iframe-container"
              >
                <youtube
                  :ref="'youtube-' + renduHTML.idJeu"
                  width="100%"
                  height="100%"
                  :title="jeu.titre[this.mixinLangue_langue]"
                  :video-id="renduHTML.videoID"
                  :player-vars="playerConfig"
                  @playing="playingVideo"
                  @ended="onEndedPlayer"
                />
              </div> -->
            </a>
          </div>
          <a
            v-if="
              typeof renduHTML.lienBoutonJouer !== 'undefined' &&
              renduHTML.lienBoutonJouer.length > 0
            "
            class="bloc-jeu-lien"
            :href="renduHTML.lienBoutonJouer"
          >
            <table v-html="renduHTML.nbLignes"></table>
            <p v-html="renduHTML.texteMKT"></p>

            <div
              v-if="renduHTML.indEnCasino || renduHTML.indOffertSalonJeux"
              class="icone-offert"
            >
              <div class="icone-offert-image">
                <img :src="renduHTML.imgIconeOffertSrc" />
              </div>

              <div
                v-if="renduHTML.indEnCasino && renduHTML.indOffertSalonJeux"
                class="icone-offert-texte"
              >
                <span style="white-space: pre-wrap">{{
                  $t("jeu.indEnCasinoOffertSalonJeux")
                }}</span>
              </div>

              <div
                v-else-if="renduHTML.indEnCasino"
                class="icone-offert-texte"
                style="margin-top: 5px"
              >
                {{ $t("jeu.indOffertEnCasino") }}
              </div>

              <div
                v-else-if="renduHTML.indOffertSalonJeux"
                class="icone-offert-texte"
                style="margin-top: 5px"
              >
                {{ $t("jeu.indOffertSalonJeux") }}
              </div>
            </div>

            <span v-if="renduHTML.indMobile" class="fa fa-mobile"></span>
          </a>
          <a v-else class="bloc-jeu-lien" @click="launchGame">
            <table v-html="renduHTML.nbLignes"></table>
            <p v-html="renduHTML.texteMKT"></p>

            <div
              v-if="renduHTML.indEnCasino || renduHTML.indOffertSalonJeux"
              class="icone-offert"
            >
              <div class="icone-offert-image">
                <img :src="renduHTML.imgIconeOffertSrc" />
              </div>

              <div
                v-if="renduHTML.indEnCasino && renduHTML.indOffertSalonJeux"
                class="icone-offert-texte"
              >
                <span style="white-space: pre-wrap">{{
                  $t("jeu.indEnCasinoOffertSalonJeux")
                }}</span>
              </div>

              <div
                v-else-if="renduHTML.indEnCasino"
                class="icone-offert-texte"
                style="margin-top: 5px"
              >
                {{ $t("jeu.indOffertEnCasino") }}
              </div>

              <div
                v-else-if="renduHTML.indOffertSalonJeux"
                class="icone-offert-texte"
                style="margin-top: 5px"
              >
                {{ $t("jeu.indOffertSalonJeux") }}
              </div>
            </div>

            <span v-if="renduHTML.indMobile" class="fa fa-mobile"></span>
          </a>

          <div
            v-if="renduHTML.lienStrategieBase !== ''"
            class="progressif-individuel"
          >
            <a href="${lqfn.getLink(infoJeu.lienStrategieBase)}">{{
              $t("jeu.strategieDeBase")
            }}</a>
          </div>
        </div>

        <a
          v-if="
            typeof renduHTML.lienBoutonJouer !== 'undefined' &&
            renduHTML.lienBoutonJouer.length > 0
          "
          class="bloc-jeu-lien"
          :href="renduHTML.lienBoutonJouer"
        >
          <div class="bloc-jeu-img" v-html="renduHTML.blocJeuImg"></div>
        </a>
        <a v-else class="bloc-jeu-lien" @click="launchGame">
          <div class="bloc-jeu-img" v-html="renduHTML.blocJeuImg"></div>
        </a>
        <div class="bloc-jeu-footer" v-html="renduHTML.blocJeuFooter"></div>

        <div v-if="noConsentFavoriInstants" class="warning-consent">
          <img
            src="/dam/espacejeux/logo/icone-avertissement.svg"
            alt="icone avertissement"
          />
          <div class="tooltip-favoris-text">
            {{ $t("jeu.instants.favoris.texteConsentement") }}&nbsp;<a
              href="javascript:Didomi.reset();Didomi.preferences.show()"
              target="_self"
              style="
                display: inline-block;
                color: black;
                text-decoration: underline;
              "
              >{{ $t("jeu.instants.favoris.texteLienConsentement") }}</a
            >
          </div>
        </div>
        <div class="bloc-jeu-boutons">
          <i
            class="fa fa-2x favoris favoris-pastille"
            :class="[
              renduHTML.blocJeuClasses.indexOf('instants') > -1
                ? hasConsent
                  ? isFavori
                    ? 'fa-star active'
                    : 'fa-star'
                  : 'fa-star inactive'
                : isFavori
                ? 'fa-star'
                : 'fa-star-o',
            ]"
            :data-favoris="'favori_' + jeu.idJeu"
            @click="toggleFavorite()"
          ></i>

          <a
            v-if="etatBoutonDemo === 1"
            class="argent-fictif"
            @click="launchGame({ indDemoInternet: 'demoplay' })"
          >
            <strong v-if="renduHTML.blocJeuClasses.indexOf('instants') > -1">{{
              $t("jeu.ctaDemo")
            }}</strong>
            <strong v-else>{{ $t("jeu.ctaArgentFictif") }}</strong>
          </a>
          <a
            v-else-if="etatBoutonDemo === 2"
            class="argent-fictif"
            :href="renduHTML.lienBoutonDemo"
          >
            <strong v-if="renduHTML.blocJeuClasses.indexOf('instants') > -1">{{
              $t("jeu.ctaDemo")
            }}</strong>
            <strong v-else>{{ $t("jeu.ctaArgentFictif") }}</strong>
          </a>

          <a
            v-if="
              typeof renduHTML.lienBoutonJouer !== 'undefined' &&
              renduHTML.lienBoutonJouer.length > 0
            "
            class="argent-reel"
            :href="renduHTML.lienBoutonJouer"
          >
            <strong v-if="renduHTML.blocJeuClasses.indexOf('instants') > -1">{{
              $t("jeu.ctaJouer")
            }}</strong>
            <strong v-else-if="jeu.filtres.indexOf('indCedMas') > -1">
              {{ $t("jeu.ctaJouer") }}
            </strong>
            <strong v-else>{{ $t("jeu.ctaArgentReel") }}</strong>
          </a>
          <a
            v-else
            class="argent-reel"
            @click="launchGame({ indDemoInternet: 'realplay' })"
          >
            <strong v-if="renduHTML.blocJeuClasses.indexOf('instants') > -1">{{
              $t("jeu.ctaJouer")
            }}</strong>
            <strong v-else-if="jeu.filtres.indexOf('indCedMas') > -1">
              {{ $t("jeu.ctaJouer") }}
            </strong>
            <strong v-else>{{ $t("jeu.ctaArgentReel") }}</strong>
          </a>
        </div>
      </div>

      <div
        v-if="renduHTML.format === 'Flash'"
        class="row divJeuFlash"
        style="display: none"
      >
        <div class="jeuFlashFlex">
          <div class="jeuFlashFlex--div">
            <a
              class="jeuFlashContent"
              aria-label=""
              role="button"
              href=""
              data-toggle="modal"
              data-target="#messageJeuFlash"
            >
              <span class="noDeco">{{ $t("jeu.flash") }}</span>
              <br />
              <span class="lienPluginFlash">{{
                $t("jeu.pluginNecessaire")
              }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "intersection-observer";
import { mapMutations, mapGetters } from "vuex";
import { ObserveVisibility } from "vue-observe-visibility";
import { mixinLangue } from "../../mixins/mixinLangue";

export default {
  directives: {
    ObserveVisibility,
  },

  mixins: [mixinLangue],

  props: {
    jeu: {
      type: Object,
      required: true,
    },
    prefixeUrl: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      renduHTML: "", // Comme on utilise mixin langue, on doit initialiser dans mounted
      isJeuLoaded: false,
      hasConsent: false,
      loadVideo: 0,
      isVideoLoading: false,
      videoIsReady: false,
      playerConfig: {
        autoplay: 1,
        mute: 1,
        controls: 0,
        modestbranding: 1,
        showinfo: 0,
        disablekb: 1,
        enablejsapi: 1,
        wmode: "transparent",
        rel: 0,
      },
    };
  },

  computed: {
    noConsentFavoriInstants: function () {
      return (
        this.renduHTML.blocJeuClasses.indexOf("instants") > -1 &&
        !this.hasConsent
      );
    },
    lienYoutube: function () {
      return `https://www.youtube.com/embed/${renduHTML.videoID}wmode=transparent&amp;rel=0`;
    },
    callPath: function () {
      /*if (window.location.href.indexOf("casino/casino-en-direct/machines-a-sous") > 0) {
        return "/json-cache/" + "casino-en-direct/jeu";
      } else {*/
      return "/json-cache/" + "casino/jeu";
      //}
    },
    isFavori: function () {
      return this.jeu.filtres.indexOf("indFavoris") != -1;
    },
    cssCol: function () {
      var nbColumn = document
        .getElementById("blocs-jeux")
        .getAttribute("nbColumn");
      if (nbColumn == 1 || nbColumn == 3 || this.$isDomainMobile) {
        return "col-12 col-lg-6 col-xl-4 casinoJeu";
      } else {
        return "col-lg-2-4 col-xl-2-4 casinoJeu";
      }
    },
    location: function () {
      return window.location;
    },

    etatBoutonDemo: function () {
      var etatBouton = 0; //Le bouton ne doit pas s'afficher

      if (this.renduHTML.indDemoInternet === "demoplay") {
        if (
          typeof this.renduHTML.lienBoutonDemo === "undefined" ||
          this.renduHTML.lienBoutonDemo.length == 0
        ) {
          etatBouton = 1; //Le bouton doit s'afficher et appeler le launch_game_and_gaq
        } else {
          etatBouton = 2; //Le bouton doit s'afficher et se comporte comme un simple lien vers une page
        }
      }
      return etatBouton;
    },
    imgSrcURI: function () {
      return this.nettoyerURI(this.renduHTML.imagePreviewSrc);
    },
  },
  mounted() {
    var vm = this;
    // Handle Favori Instant Consent
    // TODO: mettre vendorId preferences dans une variable globale
    vm.registerVendorListenerConsent(
      "c:ceinterpre-YF6CtigG",
      function () {
        vm.hasConsent = true;
      },
      function () {
        // Initialiser les favoris Instants
        localStorage.setItem("EJFavoris", "[]");
        vm.hasConsent = false;
      }
    );
  },

  methods: {
    ...mapMutations(["updateListeFavoris"]),
    launchGame({ indDemoInternet = null }) {
      const vm = this;
      let indDemoOrRealpay =
        indDemoInternet !== null ? indDemoInternet : vm.renduHTML.indRealplay;
        
      // eslint-disable-next-line no-undef
      launch_game_and_gaq(
        vm.renduHTML.idJeu,
        vm.renduHTML.fournisseur,
        undefined,
        indDemoOrRealpay,
        vm.renduHTML.langueInternet,
        vm.renduHTML.format,
        vm.renduHTML.largeurFenetre,
        vm.renduHTML.hauteurFenetre,
        vm.renduHTML.desactiverFournisseurDesktop,
        vm.renduHTML.desactiverCanalDesktop,
        vm.renduHTML.msgDesactivation,
        undefined,
        window.location.href,
        vm.renduHTML.lancementJeuBg,
        vm.renduHTML.desactiverLightbox,
        vm.renduHTML.titre,
        undefined,
        vm.renduHTML.infoWindowType,
        vm.renduHTML.infoWindowDevices,
        vm.renduHTML.infoWindowBrowsers,
        vm.renduHTML.infoWindowOS,
        vm.renduHTML.infoWindowText
      );
    },
    sendLocalStorageToIframes: function (key, value) {
      const firstDomain = document.getElementById("iframe-domain-1");
      firstDomain.contentWindow.postMessage(
        {
          action: "save",
          key: key,
          value: value,
        },
        $("#iframe-domain-1").attr("src")
      );

      const secondDomain = document.getElementById("iframe-domain-2");
      secondDomain.contentWindow.postMessage(
        {
          action: "save",
          key: key,
          value: value,
        },
        $("#iframe-domain-2").attr("src")
      );
    },

    toggleFavorite() {
      const vm = this;

      // Volontairement, on n'enlève pas l'élément indFavoris.
      // L'internaute peut alors sélectionner et desélectionner au lieu de voir le jeu disparaitre
      if (vm.jeu.filtres.indexOf("indFavoris") === -1) {
        vm.jeu.filtres.push("indFavoris");
      } else {
        vm.jeu.filtres = vm.jeu.filtres.filter(function (value) {
          return value !== "indFavoris";
        });
      }

      var arrFavoris = JSON.parse(localStorage.getItem("EJFavoris"));
      if (arrFavoris instanceof Array) {
        var idx = arrFavoris.indexOf(vm.jeu.idJeu);
        if (idx == -1) {
          arrFavoris.push(vm.jeu.idJeu);
        } else {
          arrFavoris.splice(idx, 1);
        }
      } else {
        arrFavoris = [vm.jeu.idJeu];
      }

      localStorage.setItem("EJFavoris", JSON.stringify(arrFavoris));

      if (window.location.hostname.split(".")[0] !== "m") {
        this.sendLocalStorageToIframes("EJFavoris", JSON.stringify(arrFavoris));
      }
    },

    visibilityChanged: function (isVisible, entry) {
      if (isVisible && !this.isJeuLoaded) {
        var self = this,
          params = "idJeu=" + self.jeu.idJeu;

        if (self.jeu.paramPastille !== undefined || self.jeu.paramPastille) {
          params += "&";
          if (self.jeu.paramPastille["bandeau"] !== undefined) {
            params += "param-bandeau=" + self.jeu.paramPastille["bandeau"];
            if (self.jeu.paramPastille["position"] !== undefined) {
              params += "&param-position=" + self.jeu.paramPastille["position"];
            }
          }
          if (self.jeu.paramPastille["etiquetteMasquer"] !== undefined) {
            params +=
              "&param-etiquetteMasquer=" +
              self.jeu.paramPastille["etiquetteMasquer"];
          }
        }

        $.ajax({
          url:
            this.prefixeUrl +
            "/" +
            this.mixinLangue_langue +
            this.callPath +
            "?" +
            params,
          dataType: "json",
          success: function (response) {
            self.renduHTML = response;
            self.isJeuLoaded = true;

            Vue.nextTick(function () {
              if (!self.$bus.flashSupported) {
                $("#id" + self.jeu.idJeu)
                  .find(".divJeuFlash")
                  .css("display", "block");
              }

              if (self.jeu.filtres.indexOf("indProgressif") != -1) {
                // eslint-disable-next-line no-undef
                affichageLotsProgressifs(
                  window.initPastilleLotprogressifResult,
                  // eslint-disable-next-line no-undef
                  EspacejeuxPortail.LANGUE
                );
              }
            });
          },
          error: function (jqXHR, text, error) {
            console.log(text, error);
          },
        });
      }
    },
    // Handle Favori Instant Consent
    registerVendorListenerConsent: function (vendorId, agreefct, desAgreefct) {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function (Didomi) {
        Didomi.getObservableOnUserConsentStatusForVendor(vendorId).subscribe(
          function (consentStatus) {
            if (consentStatus === true) {
              agreefct();
            } else {
              desAgreefct();
            }
          }
        );
      });
    },

    // Début : Youtube Video Methods
    loadYoutubePlayer: function () {
      if (this.renduHTML.videoID) {
        if (!this.loadVideo) this.isVideoLoading = true;
        this.loadVideo = 1;
      }
    },
    playingVideo: function () {
      this.videoIsReady = true;
      this.isVideoLoading = false;
    },
    onEndedPlayer: function () {
      const playerRef = "youtube-" + this.renduHTML.idJeu;
      this.$refs[playerRef].player.playVideo();
    },
    unloadIframeById: function () {
      const playerRef = "youtube-" + this.renduHTML.idJeu;
      if (typeof this.$refs[playerRef] !== "undefined") {
        this.$refs[playerRef].player.stopVideo();
      }
      this.videoIsReady = false;
      this.isVideoLoading = false;
      this.loadVideo = 0;
    },
    // Fin : Youtube Video Methods
  },
};
</script>

<style lang="scss">
.casino-jeu {
  &-chargement {
    align-items: center;
    display: flex;
    min-height: 190px;
    justify-content: center;
    width: 243px;
  }
}

.bloc-jeu-generique {
  color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2 !important;
}

.bloc-jeu-main {
  &:hover {
    z-index: 4;
    .warning-consent {
      z-index: 4;
    }
    .ej-block-jeu-video {
      .bloc-jeu {
        display: none;
      }
      .ej-video-iframe-container {
        position: relative;
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
        }
        iframe {
          width: 100%;
          height: 100%;
          max-width: 100%;
          margin-top: -5px;
        }
      }
    }
  }
  .bloc-jeu-lien {
    position: relative;
  }

  .loading-ring {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    z-index: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    transition: 2s;
    div {
      width: 40px;
      height: 40px;
      border-width: 3px;
      margin: 0;
    }
  }
  .warning-consent {
    position: absolute;
    left: unset;
    bottom: 1em;
    border-radius: 12px;
    padding: 2px 6px 2px 6px;
    z-index: 2;
    &:hover,
    &:focus,
    &:focus-visible {
      .tooltip-favoris-text {
        visibility: visible;
      }
    }
  }
  .tooltip-favoris-text {
    position: absolute;
    display: inline-block;
    padding: 6px;
    left: 0em;
    bottom: 1.95em;
    width: 233px;
    visibility: hidden;
    background-color: #fff9e6;
    border: 1px solid #ffbc06;
    font-family: $font-graphik-regular;
    font-size: 12px;
    color: black;
    line-height: 14px;
    text-align: left;
  }
}
.mobile_device_ej {
  .casino-jeu {
    &-chargement {
      width: 100%;
      min-height: 110px;
      padding-top: 10px;
    }
  }
}

.warning-consent-mobile {
  position: absolute;
  right: 0em;
  bottom: 0.25em;
  border-radius: 12px;
  padding: 7px 16px 12px 6px;
  z-index: 2;
  &:hover,
  &:focus,
  &:focus-visible {
    .tooltip-favoris-text-mobile {
      visibility: visible;
    }
  }
}
.tooltip-favoris-text-mobile {
  position: absolute;
  display: inline-block;
  padding: 6px;
  right: 2em;
  bottom: 0.5em;
  width: 233px;
  visibility: hidden;
  background-color: #fff9e6;
  border: 1px solid #ffbc06;
  font-family: $font-graphik-regular;
  font-size: 12px;
  color: black;
  line-height: 14px;
  text-align: left;
}

.warning-consent-jeu-mobile {
  position: absolute;
  z-index: 4;
  right: 1rem;
  bottom: 1rem;
  border-radius: 12px;
  padding: 7px 16px 12px 6px;
  &:hover,
  &:focus,
  &:focus-visible {
    .tooltip-favoris-text-jeu {
      visibility: visible;
    }
  }
}
.warning-consent-jeu-desktop {
  position: absolute;
  z-index: 4;
  right: 2rem;
  top: 1rem;
  border-radius: 12px;
  padding: 2px 6px 2px 6px;
  &:hover,
  &:focus,
  &:focus-visible {
    .tooltip-favoris-text-jeu {
      visibility: visible;
    }
  }
}
.tooltip-favoris-text-jeu {
  position: absolute;
  display: inline-block;
  padding: 6px;
  bottom: 0.75em;
  right: 2em;
  width: 233px;
  visibility: hidden;
  background-color: #fff9e6;
  border: 1px solid #ffbc06;
  font-family: $font-graphik-regular;
  color: black;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}

.inactive {
  opacity: 0.7 !important;
}
</style>
