// Originally in ej-commmun-portail.js

import Cookies from './cookies';

function EspacejeuxPortail_Cookie_def() {
    /*
     *	Function:
		 *		ecrireCookie avec le flag secure et sameSite=Lax
     *	Arguments:
     *		nom: nom du cookie
     *		valeur: valeur du cookie
     *		nbrJoursDelai: delai de validité en jours
     *  Retour:
     *      Aucun
     *	Synopsis: Fonction utilitaire permettant d'écrire un cookie
  	 */
	this.ecrireCookie = function (nom, valeur, nbrJoursDelai) {
		// Avant de modifier ce cookie, on s'assure de faire le ménage des vieux cookie "legacy", sans domaine
		document.cookie = nom + "=; domain=; path=/; expires=" + new Date(0).toGMTString();

		var codeCookie = nom + "=" + escape(valeur),
			dateExpiration = null;
		if (nbrJoursDelai) {
			dateExpiration = new Date();
			dateExpiration.setTime(dateExpiration.getTime() + nbrJoursDelai * 1000 * 60 * 60 * 24);
			codeCookie = codeCookie + "; expires=" + dateExpiration.toGMTString();
		}
		codeCookie = codeCookie + "; path=/";
		//modification du comportement du sous domaine
		var sharedDomain = window.location.hostname.match(/([a-z0-9\-]+\.[a-z0-9\-]+)$/gi); //[1];
		if (sharedDomain !== null) {
			codeCookie = codeCookie + "; domain=." + sharedDomain;
		}
		document.cookie = codeCookie + "; secure=true; sameSite=Lax";
	};

    /*
     *	Function:
		 *		lireCookie()
     *	Arguments:
     *		nom: nom du cookie
     *  Retour:
     *      La valeur:  si le cookie existe
     *      Null: si le cookie n'existe pas
     *	Synopsis:
     *      Fonction utilitaire permettant de lire la valeur d'un cookie
  	 */
	this.lireCookie = function (nom) {
		if (document.cookie) {
			var arg = nom + "=",
				alen = arg.length,
				clen = document.cookie.length,
				i = 0;
			while (i < clen) {
				var j = i + alen;
				if (document.cookie.substring(i, j) === arg) {return EspacejeuxPortail_Cookie.lireValeurCookie(j);}
				i = document.cookie.indexOf(" ", i) + 1;
				if (i === 0) {break;}
			}
			return null;
		}
	};

    /*
     *	Function:
		 *		lireValeurCookie()
     *	Arguments:
     *		index: L'index du cookie dont on dé lire la valeur
     *  Retour:
     *      Valeur: du cookie situé à l'index demandé
     *	Synopsis:
     *      Permet de lire la valeur du cookie situé à l'index
  	 */
	this.lireValeurCookie = function (index) {
		var endstr = document.cookie.indexOf(";", index);
		if (endstr === -1) {endstr = document.cookie.length;}
		return unescape(document.cookie.substring(index, endstr));
	};


    /*
     *	Function:
		 *		supprimerCookie()
     *	Arguments:
     *		nom: nom du cookie
     *  Retour:
     *      Aucun
     *	Synopsis:
     *      Supprimmer le cookie avec le nom
  	 */
	this.supprimerCookie = function (nom) {
		document.cookie = nom + "=; path=/; expires=" + new Date(0).toGMTString();
	};
}

window.EspacejeuxPortail_Cookie = new EspacejeuxPortail_Cookie_def();