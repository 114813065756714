<template>
	<div class="lobby-table-game-specific-wrapper lobbyTableGameSpecificWrapper--3fAEk desktop--1Txr1" data-role="lobby-table-game-specific-wrapper">
		<ol class="TableGameMegaball--3FxHn" data-role="megaball-history">
            <template v-for="(result,index) in resultsSlice">
            <li v-if="result.length == 1" :key="index" class="Multiplier--3fr5O" :style="'background: ' + getCouleurFill(index,result[0].multiplier) +'; border: 1px solid '+getCouleurStroke(result[0].multiplier) +';'">
                <div class="ShadowContainer--1cONb">
				    <div class="Shadow--1rJqK"></div>
			    </div>
			   <div class="Text--1WC8k">{{result[0].multiplier}}x</div>
			</li>
            <div v-else :key="index" class="Doubleball--22hor">
			    <li v-for="(resultDouble,index2) in result" :key="index2" class="Multiplier--3fr5O" :style="'background: ' + getCouleurFill(index,resultDouble.multiplier) +'; border: 1px solid '+getCouleurStroke(resultDouble.multiplier) +';'">
                    <div class="ShadowContainer--1cONb">
					    <div class="Shadow--1rJqK"></div>
				    </div>
				    <div class="Text--1WC8k">{{resultDouble.multiplier}}x</div>
			    </li>
            </div>
            </template>
		</ol>
	</div>
</template>

<script>
export default {
    /**
     * https://lotoquebec.atlassian.net/browse/SWSPC-1153?atlOrigin=eyJpIjoiNzZiM2UyYzdkMzc5NGZiZDljYWU0NjhiNDU4NzY5YmIiLCJwIjoiaiJ9
     * 
     * 5x = Bleu foncé      rgba(0, 90, 255, 0.6)       rgb(24, 64, 137)
     * 10x =  bleu pâle     rgba(48, 169, 255, 0.6)     rgb(0, 107, 183)
     * 12X = bleu pâle      rgba(48, 169, 255, 0.6)     rgb(0, 107, 183)
     * 15x = mauve          rgba(169, 0, 255, 0.6)      rgb(94, 35, 124)
     * 20x = mauve          rgba(169, 0, 255, 0.6)      rgb(94, 35, 124)
     * 25x = Rose           rgba(224, 4, 177, 0.6)      rgb(147, 24, 121)
     * 50x = Rose           rgba(224, 4, 177, 0.6)      rgb(147, 24, 121)
     * 100x = Rose flash    rgba(255, 0, 143, 0.6)      rgb(200, 7, 115)
     * 2 résultats en cadrés lorsque d’une même partie il tire 2 megaballs
     * 
     */
    props : {
		results : {
			type : Array,
			required : true
		},
		appareil : {
			type : String,
			required : true
		},
		prefixeUrl : {
			type : String,
			required : true
		},
    },
    computed:{
        //Récupère la section nécessaire du tableau des résultats en coupant les sections superflues
        //le HTML s'occupe de tenir compte des balles doubles
        resultsSlice:function(){
            var results = this.results;
            var index = (results.length < 6) ? results.length : 6;

            for(var i = 0; i<index; i++){
                if(results[i].length != 1){
                    index--;
                }
            }
            
            //utilisation d'index +1 puisque slice coupe l'index du dernier argument
            return results.slice(0,index+1);
        }
    },
    methods:{
        //obtenir la couleur de remplissage selon l'index et le résultat
        getCouleurFill:function(index, resultat){
            if(index != 0){
                return "rgba(30, 43, 43, 0.6)";
            }else if(resultat == 5){
                return "rgba(0, 90, 255, 0.6)";
            }else if([10,12].includes(resultat)){
                return "rgba(48, 169, 255, 0.6)";
            }else if([15,20].includes(resultat)){
                return "rgba(169, 0, 255, 0.6)";
            }else if([25,50].includes(resultat)){
                return "rgba(224, 4, 177, 0.6)";
            }else{ //100
                return "rgba(255, 0, 143, 0.6)";
            }
        },
        //obtenir la couleur de contour le résultat
        getCouleurStroke:function(resultat){
            if(resultat == 5){
                return "rgb(24, 64, 137)";
            }else if([10,12].includes(resultat)){
                return "rgb(0, 107, 183)";
            }else if([15,20].includes(resultat)){
                return "rgb(94, 35, 124)";
            }else if([25,50].includes(resultat)){
                return "rgb(147, 24, 121)";
            }else{//100
                return "rgb(200, 7, 115)";
            }
        }
    }
}
</script>

<style>
.TableLogo--3RFNG.desktop--1Txr1 {
    z-index: 4
}

.TableLogo--3RFNG.desktop--1Txr1.doubleBall--2sPYM {
    bottom: 2.5em
}

.lobbyTableGameSpecificWrapper--3fAEk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    min-height: 3.35rem;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1em;
    z-index: 4
}

.lobbyTableGameSpecificWrapper--3fAEk.emptyGameHistoryStatistic--Uu-Gi {
    min-height: auto!important
}

.lobby-table-block.occupied .lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .lobbyTableGameSpecificWrapper--3fAEk {
    left: 1px;
    right: 1px
}

.lobbyTableGameSpecificWrapper--3fAEk .lobby-table-block.table-is-full-behind {
    display: none
}

.desktop--1Txr1.lobbyTableGameSpecificWrapper--3fAEk {
    min-height: 1.9em;
    z-index: 4
}

.desktop--1Txr1 .lobbyTableGameSpecificData--3whkv>* {
    z-index: 4
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    box-sizing: border-box;
    bottom: -1px;
    min-height: 3em;
    padding: 0 .3em .4em
}

.phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk.portrait--w-M_r {
    min-height: 2.4em
}

.lobby-table-block.not-operational.table-details-loaded .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    display: none
}

.lobby-table-block.occupied .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk,.lobby-table-block.private:not(.not-operational) .phone--3PmoR.lobbyTableGameSpecificWrapper--3fAEk {
    left: 0;
    right: 0
}

.TableGameMegaball--3FxHn {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
    margin: .1em .3em;
    padding: 0;
    list-style: none
}

.TableGameMegaball--3FxHn.mobile--LDYs5 {
    margin: 0
}

.Multiplier--3fr5O {
    -webkit-flex-basis: 12.57143%;
    flex-basis: 12.57143%;
    height: 1.5em;
    margin: 0 1%;
    border-radius: 2px;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    color: #e6cb6d;
    font-weight: 700;
    box-sizing: border-box
}

.Multiplier--3fr5O:first-child {
    margin-left: 0
}

.Multiplier--3fr5O:last-child {
    margin-right: 0
}

.Doubleball--22hor {
    -webkit-flex-basis: 25.14286%;
    flex-basis: 25.14286%;
    margin: 0 1%;
    height: 1.5em;
    padding: 11px 1px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e4b862
}

.Doubleball--22hor:first-child {
    margin-left: 0
}

.Doubleball--22hor:last-child {
    margin-right: 0
}

.Doubleball--22hor .Multiplier--3fr5O {
    -webkit-flex-basis: 48%;
    flex-basis: 48%;
    margin-bottom: 0
}

.Doubleball--22hor .Multiplier--3fr5O:first-child {
    margin-right: 2%
}

.Doubleball--22hor .Multiplier--3fr5O:last-child {
    margin-left: 2%
}

.Text--1WC8k {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: .8em;
    line-height: 1.4;
    font-weight: 700;
    text-align: center;
    color: #e6cb6d;
    opacity: .8
}

.Text--1WC8k:before {
    content: attr(data-text);
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-shadow: 0 0 .2em rgba(0,0,0,.75)
}

.mobile--LDYs5 .Text--1WC8k:before {
    text-shadow: 0 0 .5em #000
}

.Text--1WC8k:after {
    content: attr(data-text);
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgBjZCxDcNQCEQ5K3WmyP4TZYnQ2UogHMYISy58xReCxwFf5KbAx/RtHjEEHgKzjKXi5fnCg+Bv1dFWcrIBi2d2BzdFdNMTk8whpXL8pBuwuJvtxWZcpuPYGYdbQ1cgpzvrnrvh7GibxrF9CmpyLs1Cg99Vu8gG1G4R+vyIW/oDvsc/gL44wlsAAAAASUVORK5CYII=");
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.Text--1WC8k.msbrowser--2YwkZ {
    color: #e8c272
}

.Text--1WC8k.msbrowser--2YwkZ:after,.Text--1WC8k.msbrowser--2YwkZ:before {
    background: none
}

.ShadowContainer--1cONb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.ShadowContainer--1cONb .Shadow--1rJqK {
    width: 5em;
    height: 100%;
    background: rgba(0,0,0,.4);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.Multiplier--3fr5O.mobile--LDYs5 {
    -webkit-flex-basis: 15%;
    flex-basis: 15%;
    height: 1.9rem
}

.Multiplier--3fr5O.mobile--LDYs5.portrait--2b1cS {
    height: 1.7rem
}

.Multiplier--3fr5O.mobile--LDYs5 .Text--1WC8k {
    font-size: 1.1em
}

.Doubleball--22hor.mobile--LDYs5 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    height: 1.9rem
}

.Doubleball--22hor.mobile--LDYs5.portrait--2b1cS {
    height: 1.7rem
}

.Doubleball--22hor.mobile--LDYs5 .Text--1WC8k {
    font-size: 1.1em
}

.Doubleball--22hor.mobile--LDYs5 .Multiplier--3fr5O.mobile--LDYs5 {
    -webkit-flex-basis: 48%;
    flex-basis: 48%
}

.TableGameMegaball--3FxHn.tablet--31Jd_ {
    margin: 5px
}

.Doubleball--22hor.tablet--31Jd_,.Multiplier--3fr5O.tablet--31Jd_ {
    height: 2.6em
}

.Doubleball--22hor.tablet--31Jd_ .Text--1WC8k,.Multiplier--3fr5O.tablet--31Jd_ .Text--1WC8k {
    font-size: 1.4em
}
</style>