import Vue from "vue";
import { mixinLangue, i18n } from "../../mixins/mixinLangue";
import mixinUserMessages from "../../mixins/mixin-user-messages";
import {
  isEmptyJsonObj,
  formatCurrency,
  urlParamsValues,
  protectedCTA,
  protectedPage
} from "../../mixins/mixin-utils";
import { store } from "../../store/store";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Loading from "../../components/loading/Loading.vue";
import { lqDecimal, lqBetween } from "../../mixins/mixin-value-validation";
import Cookies from '../../../js/components/cookies';

import "whatwg-fetch";

document.addEventListener("DOMContentLoaded", function() {
  if (document.getElementById("vueRetraitAvecCompteApp")) {
    new Vue({
      el: "#vueRetraitAvecCompteApp",
      i18n, //IE11 compatibility
      components: {
        Loading
      },
      mixins: [
        mixinLangue,
        mixinUserMessages,
        validationMixin,
        isEmptyJsonObj,
        urlParamsValues,
        formatCurrency,
        protectedCTA,
        protectedPage
      ],
      store: store,

      data: function() {
        return {
          summary: {},
          withdrawal: {},
          isMobile: null,
          amount: null,
          uuid: null,
          uuidPage: null,
          isBlurAmount: false,
          isLoading: false,
          showError: false,
          showSuccess: false,
          fatalError: false,
          messages: {},
          min: 0,
          max: 0,
          ejModal: null
        };
      },

      validations() {
        return {
          amount: {
            required,
            //minLength: minLength(2),
            //lqDecimal: lqDecimal(this),
            //lqBetween: lqBetween(this.min, this.max),
            isValidAction: () => !this.fatalError
          }
        };
      },

      created() {
        const md = new MobileDetect(navigator.userAgent);
        this.isMobile = md.phone();
        //this.isLoading = true;
      },
      computed: {
        ...mapGetters(["getAccountUserMessages"])
      },

      beforeMount() {
        this.uuid = this.$el.attributes["data-uuid"].value;
        this.uuidPage = this.$el.attributes["data-uuid-page"].value;
        this.initRetraitInfo();
      },

      mounted: function() {
        //Enregistrement de l'evennement click sur tous les boutons
        this.isAuthorisedCTA();
      },
      methods: {
        submitAmount: function() {
          const vm = this;
          const amount = vm.$v.amount;
          //vm.showResponseError.amount = false;
          console.log("submitAmount");
          console.log("invalid amount: ", amount.$invalid);
          if (amount.$invalid) {
            return;
          } else {
            //besoin affaire to0 croche
            if (amount.$model < vm.min) {
              vm.showMessageToUser("error", vm.getAccountUserMessages["MSG06-15"]);
            } else if (amount.$model > vm.max) {
              vm.showMessageToUser("error", vm.getAccountUserMessages["MSG06-20"]);
            } else {
              vm.processWithdrawal();
            }
          }
        },
        updateAccountSummary: function(data) {
          this.summary = data;
          this.min = data.minimumWithdrawalAmount;
          this.max = data.maximumWithdrawalAmount;
          this.summary.balance = data.balance;
        },
        processWithdrawal: function() {
          const vm = this;
          console.log("processWithdrawal");
          let cacheResp;
          vm.isLoading = true;
          let myheaders = new Headers();
          myheaders.append('pragma', 'no-cache');
          myheaders.append('cache-control', 'no-cache');
          myheaders.append('JEL-XTOKEN', Cookies.get('Jel-csrf'));
          let ajaxData = new FormData();
          ajaxData.append("amount", vm.amount);
          ajaxData.append("channel", vm.isMobile ? "M" : "I");
          fetch("/services/retraits", {
            method: "POST",
            headers: myheaders,
            body: ajaxData
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              cacheResp = data;
              return fetch("/services/retraits/init?_" + Date.now());
            })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              if (cacheResp && cacheResp.status === "HELD") {
                if (data && data.isBlocked) {
                  vm.fatalError = true;
                  vm.showMessageToUser("error", vm.getAccountUserMessages["MSG06-55"]);
                } else if (data && data.withdrawalAuthStatus === "YES") {
                  vm.updateAccountSummary(data);
                  let localMsg = {};
                  localMsg.details = vm.getAccountUserMessages["MSG06-25"].details.replace(
                    "{{montantRetraits}}",
                    vm.formatMontant(cacheResp.amount)
                  );
                  vm.showMessageToUser("success", localMsg);
                  //update solde entete
                  LQ_updateBalance();
                }
                vm.clearForms();
              } else if (cacheResp.connexion == "invalide") {
                vm.returnToHome();
              } else {
                vm.showMessageToUser("error", vm.getAccountUserMessages[cacheResp.code]);
              }
              vm.isLoading = false;
            })
            .catch((error) => {
              vm.isLoading = false;
              vm.showMessageToUser("error", vm.getAccountUserMessages["MSG01"]);
            });
        },
        showMessageToUser(type, msg) {
          const vm = this;
          vm.showSuccess = vm.showError = false;
          vm.messages.details = msg.details;
          vm.showSuccess = type === "success";
          vm.showError = type === "error";
        },
        verifyUnresolvedWithdrawal: function(callbackfn) {
          const vm = this;
          vm.isLoading = true;
          let headers = new Headers();
          headers.append("pragma", "no-cache");
          headers.append("cache-control", "no-cache");
          fetch("/services/retraits", {
            method: "GET",
            headers: headers
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              vm.isLoading = false;
              if (vm.isEmptyJson(data) && typeof callbackfn !== "undefined") {
                callbackfn();
              } else if (data && data.connexion == "invalide") {
                vm.returnToHome();
              } else {
                vm.showMessageToUser("error", vm.getAccountUserMessages["MSG06-65"]);
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              console.log(error);
              vm.showMessageToUser("error", vm.getAccountUserMessages["MSG01"]);
            });
        },
        redirectToModifCpte: function() {
          window.location.href = this.appendObjParamsToUri(
            this.$t("navUrl.ASSIGNATION_COMPTE"),
            "uref=" + this.uuidPage + "&withdrawalAuthStatus=" + this.summary.withdrawalAuthStatus
          );
        },
        showConfirmationBox: function() {
          $("#delete-cpte-bancaire").removeClass("d-none");
        },
        closeConfirmationBox: function() {
          $("#delete-cpte-bancaire").addClass("d-none");
        },
        deleteAccount: function() {
          const vm = this;
          vm.isLoading = true;
          let headers = new Headers();
          headers.append("pragma", "no-cache");
          headers.append("cache-control", "no-cache");
          fetch("/services/assignation", {
            method: "DELETE",
            headers: headers
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              if (data && data.status === "success") {
                vm.isLoading = true;
                location.reload();
              } else if (data && data.connexion == "invalide") {
                vm.returnToHome();
              } else {
                vm.showMessageToUser("error", vm.getAccountUserMessages["MSG01"]);
                vm.closeConfirmationBox();
                vm.isLoading = false;
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              console.log(error);
              vm.showMessageToUser("error", vm.getAccountUserMessages["MSG01"]);
              vm.closeConfirmationBox();
            });
        },
        initRetraitInfo: function() {
          const vm = this;
          vm.isLoading = true;
          let headers = new Headers();
          headers.append("pragma", "no-cache");
          headers.append("cache-control", "no-cache");
          fetch("/services/profile?isDeposit=false&isWithdrawal=true", {
            method: "GET",
            headers: headers
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {
              if (data.status === "success") {
                if (data.PLAYER_STATUS === "PLAYER") {
                  if (data.withdrawalInfo.isBlocked) {
                    vm.isLoading = false;
                    vm.fatalError = true;
                    vm.showMessageToUser("error", vm.getAccountUserMessages["MSG06-55"]);
                  } else if (data.withdrawalInfo.withdrawalAuthStatus === "YES") {
                    vm.isLoading = false;
                    vm.updateAccountSummary(data.withdrawalInfo);
                  } else {
                    window.location.href = vm.appendObjParamsToUri(
                      vm.$t("navUrl.ASSIGNATION_COMPTE"),
                      "uref=" +
                        vm.uuidPage +
                        "&withdrawalAuthStatus=" +
                        data.withdrawalInfo.withdrawalAuthStatus
                    );
                  }
                } else if (data.PLAYER_STATUS === "GUEST") {
                  window.location.href = vm.appendObjParamsToUri(
                    vm.$t("navUrl.VALIDATION_IDENTITE")
                  );
                } else if (data.PLAYER_STATUS === "DEPOSITLIMIT") {
                  window.location.href = vm.appendObjParamsToUri(vm.$t("navUrl.LIMIT_DEPOT"));
                } else if (data.PLAYER_STATUS === "NONWAGER") {
                  window.location.href = vm.$t("navUrl.ACCUEIL");
                } else {
                  //TODO init deposit info
                  console.log(vm.$t("navUrl.ACCUEIL"));
                  window.location.href = vm.$t("navUrl.ACCUEIL");
                }
              } else if (data.status === "error") {
                window.location.href = vm.$t("navUrl.ACCUEIL");
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              console.log(error);
              //TODO Messages erreurs
              vm.showMessageToUser("error", vm.getAccountUserMessages["MSG01"]);
            });
        },
        clearForms: function() {
          const vm = this;
          console.log("clearForms");
          vm.amount = "";
        }
      }
    });
  }
});
