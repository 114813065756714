<template>
  <div :data-component-name="$options.name" v-if="composantHerite">
			<component v-if="composantHerite.typeComposante"
                 v-bind:is="composantHerite.typeComposante"
                 :key="composantHerite['@id']"
                 :contenu="composantHerite"></component>
  </div>
</template>

<script>
  import GrandeSection from "../container/GrandeSection.vue";
  import Row from "../container/grid/Row.vue";

  import {WebServicesAccess} from '../../config';
 
  export default {
    name:"Heritage",
     components: {
      GrandeSection,
      Row,
    },
   data(){
      return{
        composantHerite: null,
      }
    },
    props: ["contenu"],
    methods:{
    },
    created: function () {
      var self=this;
      this.axios
        .get(WebServicesAccess.MAGNOLIA_PATH + '/' + 
        	 ((this.$route.params.langue) ? (this.$route.params.langue) : "fr") +
        	 '/json-cache/contenu', {
          params: {
            path: this.contenu.chemin,
          }
        })
        .then(function (response) {
          self.composantHerite = response.data.results[0];
        })
        .catch(error => console.log(error))    
    },
  }
</script>