var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-alert",
    {
      attrs: {
        "data-component-name": _vm.$options.name,
        show: "",
        variant: _vm.contenu.typeAlerte,
      },
    },
    [_c("span", { domProps: { innerHTML: _vm._s(_vm.contenu.texteRich) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }