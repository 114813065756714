import Cookies from './cookies.js';

const trackVisitByDay = () => {

    const cookieName = "lastVisitAndCounter";
    let dateCurrent = new Date();
    const dayCurrent = String(dateCurrent.getDate()).padStart(2, '0');
    const monthCurrent = String(dateCurrent.getMonth() + 1).padStart(2, '0');
    const yearCurrent = dateCurrent.getFullYear();

    dateCurrent = `${dayCurrent}-${monthCurrent}-${yearCurrent}`;

    if( Cookies.get( cookieName ) === null ) {
        Cookies.set( cookieName, dateCurrent+'_1' );
    } else if( Cookies.get( cookieName ).split( '_' )[0] !== dateCurrent ) {
        const cookieOriginal = Cookies.get( cookieName ).split( '_' );
		var cookieValue = parseInt( cookieOriginal[1] );

		//À la demande des clients (les joueurs réguliers sont sûrement a plus de 20 visites par jour
		// et donc ne verront jamais l’invitation à faire un écran d’accueil. donc on le reset à 40		
		if(cookieValue >= 40) {
			cookieValue = 0;
		}
		cookieValue = cookieValue + 1;

        Cookies.set( cookieName, dateCurrent+'_'+cookieValue );
    }
}

export default trackVisitByDay;