// Originally in ej-commmun-portail.js

/*****************************************/
/*** OBJECT EspacejeuxPortail_Langue  ***/
/***************************************/

/* NAME : EspacejeuxPortail_Langue
 * PARM : no
 *
 * DESC : Object for language
 */

function EspacejeuxPortail_Langue_def() {

    /* API de changement de langue, peut être changé par d'autres interfaces
     */
	this.redirigerVersLangue = function (nouvelleLangue) {
		return true;
	};

	this.changerLangue = function () {
		var nouvelleLangue = "en";
		if (this.pageEnAnglais()) {
			nouvelleLangue = "fr";
		}
		EspacejeuxPortail_Cookie.ecrireLangue(nouvelleLangue);
		return this.redirigerVersLangue(nouvelleLangue);
	};

	this.pageEnAnglais = function () {
		if (EspacejeuxPortail.LANGUE) {
			if (EspacejeuxPortail.LANGUE.toLowerCase() === "en") {
				return true;
			}
		}
		return false;
	};
}

window.EspacejeuxPortail_Langue = new EspacejeuxPortail_Langue_def();